import { useQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO } from '@utils/graphql';

export const useGetUserBasicInfo = userId => {
  const { data } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      userId,
    },
    skip: !userId,
  });

  const userInfo = data?.getUserBasicInfo || {};
  return { userInfo };
};
