import RealEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { DocTienBangChuV2, numberWithDots, usePrice } from '@utils/helper';
import { buildingTypeConfigs } from '@utils/configs/configsLocal';
import moment from 'moment/moment';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import Tooltip from '@mui/material/Tooltip';

const getArrayValue = ({ configs = [], key, item }) => {
  const foundConfigs = item.ops
    .filter(e => e.path.includes(key))
    ?.map(item => item.value);
  if (!foundConfigs?.length) {
    return '-';
  }
  if (foundConfigs[0] instanceof Array && foundConfigs[0].length !== 0) {
    return configs
      .filter(e => foundConfigs[0].includes(e.key))
      .map(item => item.value)
      ?.join(', ');
  } else if (foundConfigs[0]?.length === 0) {
    return '-';
  } else {
    return configs
      .filter(e => foundConfigs.includes(e.key))
      .map(item => item.value)
      .join(', ');
  }
};

const getFieldFrom = (item, fieldName) => {
  return item.ops.find(e => e.path === `/${fieldName}/from`)?.value;
};

const getFieldTo = (item, fieldName) => {
  return item.ops.find(e => e.path === `/${fieldName}/to`)?.value;
};

const fromToRender = (from, to, unit = '') => {
  if (!from && !to) {
    return '-';
  }
  if ((!from || from === 0) && to > 0) {
    return `<= ${to} ${unit}`;
  }
  if (from > 0 && (!to || to === 0)) {
    return `>= ${from} ${unit}`;
  }
  return `${from} -  ${to} ${unit}`;
};

export const ReadPrice = price => {
  const from = price?.from,
    to = price?.to;
  if (from && to) {
    return `${DocTienBangChuV2(from?.toString())} - ${DocTienBangChuV2(
      to?.toString()
    )}`;
  }
  if (from) {
    return `>= ${DocTienBangChuV2(from?.toString())}`;
  }
  if (to) {
    return `<= ${DocTienBangChuV2(to?.toString())}`;
  }
  return '-';
};

export const useConvertDataRealEstateEditHistory = (
  item = {},
  type,
  setShowModal = () => {}
) => {
  const {
    directions,
    fixedUtilities,
    utilities,
    localUtilities,
    villaViewTypes,
    equipmentsAndServices,
    warehouseType,
    postTypes,
  } = RealEstateCommonConfigs;
  const isNeedRealEstate = type === 'demand';
  const { price, priceUnit } = usePrice({
    price: item.ops.find(e => e.path === '/price')?.value,
  });
  function convertItem(field, unit) {
    const addHis = item?.ops?.filter(e => e.op !== 'add');
    const foundValue = item.ops.find(e => e.path === `/${field}`)?.value;
    if (isNeedRealEstate) {
      if (addHis?.length !== 0) {
        const filedFrom = getFieldFrom(item, `${field}`);
        const fieldTo = getFieldTo(item, `${field}`);
        return fromToRender(filedFrom, fieldTo, unit);
      } else
        return foundValue?.from && foundValue?.to
          ? `${foundValue?.from} - ${foundValue?.to} ${unit}`
          : `-`;
    }
    if (!foundValue) {
      return '-';
    }
    return foundValue;
  }
  return [
    {
      key: 'width',
      value: convertItem('width', 'm'),
    },
    {
      key: 'length',
      value: convertItem('length', 'm'),
    },
    {
      key: 'rear',
      value: convertItem('rear', 'm'),
    },
    {
      key: 'area',
      value: convertItem('area', 'm²'),
    },
    {
      key: 'price',
      value: () => {
        const addHis = item?.ops?.filter(e => e.op !== 'add');
        const foundValue = item.ops.find(e => e.path === '/price')?.value;
        if (isNeedRealEstate) {
          if (addHis?.length !== 0) {
            const filedFrom = getFieldFrom(item, 'price');
            const fieldTo = getFieldTo(item, 'price');
            return ReadPrice({ from: filedFrom, to: fieldTo });
          } else return ReadPrice(foundValue);
        }
        if (!foundValue) {
          return '-';
        }
        return `${price} ${priceUnit}`;
      },
    },
    {
      key: 'floorCount',
      value: convertItem('floorCount', 'tầng'),
    },
    {
      key: 'constructionArea',
      value: convertItem('constructionArea', 'm²'),
    },
    {
      key: 'bedroomCount',
      value: convertItem('bedroomCount', 'phòng'),
    },
    {
      key: 'toiletCount',
      value: convertItem('toiletCount', 'phòng'),
    },
    {
      key: 'direction',
      value: () => {
        const foundConfigs = item.ops
          .filter(e => e.path.includes('direction'))
          ?.map(item => item.value)[0];
        if (!foundConfigs?.length) {
          return '-';
        }
        return (
          <Tooltip
            className="mr-2"
            title={directions
              .filter(e => foundConfigs.includes(e.key))
              .map(item => item.value)
              .join(', ')}
          >
            <div>
              {directions
                .filter(e => foundConfigs.includes(e.key))
                .map(item => item.value)
                .join(', ')}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: 'isNewConstruction',
      value: () => {
        const foundValue = item.ops.find(e =>
          ['/isNewConstruction', '/isNewly'].includes(e.path)
        )?.value;
        if (typeof foundValue !== 'boolean') {
          return '-';
        }
        return foundValue ? 'Xây mới' : 'Xây cũ';
      },
    },
    {
      key: 'buildingType',
      value: getArrayValue({
        configs: buildingTypeConfigs,
        item,
        key: 'buildingType',
      }),
    },
    {
      key: 'warehouseType',
      value: getArrayValue({
        configs: warehouseType,
        item,
        key: 'warehouseType',
      }),
    },
    {
      key: 'hotelStar',
      value: item.ops.find(e => e.path === '/hotelStar')?.value,
    },
    {
      key: 'hasMezzanine',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/hasMezzanine'
        )?.value;
        if (typeof foundValue !== 'boolean') {
          return '-';
        }
        return foundValue ? 'Có' : 'Không';
      },
    },
    {
      key: 'postTitle',
      value: (
        <Tooltip
          title={
            item?.ops.find(e => e.path === '/postTitle')?.value
              ? item?.ops.find(e => e.path === '/postTitle')?.value
              : '-'
          }
        >
          <div>
            {item?.ops.find(e => e.path === '/postTitle')?.value
              ? item?.ops.find(e => e.path === '/postTitle')?.value
              : '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'postContent',
      value: (
        <Tooltip
          title={
            item?.ops.find(e => e.path === '/postContent')?.value
              ? item?.ops.find(e => e.path === '/postContent')?.value
              : '-'
          }
        >
          <div>
            {item?.ops.find(e => e.path === '/postContent')?.value
              ? item?.ops.find(e => e.path === '/postContent')?.value
              : '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'privateNote',
      value: (
        <Tooltip
          title={
            item?.ops.find(e => e.path === '/privateNote')?.value
              ? item?.ops.find(e => e.path === '/privateNote')?.value
              : '-'
          }
        >
          <div>
            {item?.ops.find(e => e.path === '/privateNote')?.value
              ? item?.ops.find(e => e.path === '/privateNote')?.value
              : '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'fixedUtilities',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: fixedUtilities,
            item,
            key: 'fixedUtilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: fixedUtilities,
              item,
              key: 'fixedUtilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'utilities',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: utilities,
            item,
            key: 'utilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: utilities,
              item,
              key: 'utilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'localUtilities',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: localUtilities,
            item,
            key: 'localUtilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: localUtilities,
              item,
              key: 'localUtilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'villaView',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: villaViewTypes,
            item,
            key: 'villaView',
          })}
        >
          <div>
            {getArrayValue({
              configs: villaViewTypes,
              item,
              key: 'villaView',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'equipmentsAndServices',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: equipmentsAndServices,
            item,
            key: 'equipmentsAndServices',
          })}
        >
          <div>
            {getArrayValue({
              configs: equipmentsAndServices,
              item,
              key: 'equipmentsAndServices',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'mediaUrls',
      value: () => {
        const foundValue = item.ops.find(e =>
          e.path.includes('/mediaUrls')
        )?.value;
        if (!foundValue?.length) {
          return '-';
        }
        return (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="flex justify-center w-full"
          >
            <img src="/svg/icon_camera.svg" />
          </button>
        );
      },
    },
    {
      key: 'postType',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/postType')?.value;
        if (!foundValue) {
          return '-';
        }
        return postTypes.find(e => e.key === foundValue.toString())?.value;
      },
    },
    {
      key: 'basicAmount',
      value: () => {
        const basicAmount =
          item?.renewDetails?.basicAmount + item?.renewDetails?.vat;
        if (!basicAmount) {
          return '-';
        }
        return `${numberWithDots(basicAmount)} VNĐ`;
      },
    },
    {
      key: 'dueDate',
      value: () => {
        const parseIntStart = parseInt(item?.renewDetails?.startTime, 10);
        const parseIntEnd = parseInt(item?.renewDetails?.endTime, 10);
        if (
          !moment.unix(parseIntStart).isValid() ||
          !moment.unix(parseIntEnd).isValid()
        ) {
          return '-';
        }
        return `${moment.unix(parseIntStart).format('DD/MM/YYYY')} - ${moment
          .unix(parseIntEnd)
          .format('DD/MM/YYYY')}`;
      },
    },
    {
      key: 'updatedAt',
      value: moment(item?.date).format('hh:mm A, DD/MM/YYYY'),
    },
    {
      key: 'logCreator',
      value: () => {
        if (!item?.logCreator) {
          return '-';
        }
        return (
          <MyDropdown
            DropdownComponent={UserInfo}
            dropdownContext={{ userId: item.logCreator.id }}
          >
            <div className="flex space-x-2 items-center">
              <div
                className="flex w-30px h-30px rounded-rd50 border border-gray-300 bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url('${
                    item?.logCreator?.avatar?.originalUrl || '/svg/male.svg'
                  }')`,
                }}
              />
              <span className="text-14 font-text text-blue-main underline">
                {item?.logCreator?.fullName}
              </span>
              <img src="/svg/eye.svg" />
            </div>
          </MyDropdown>
        );
      },
    },
  ];
};
