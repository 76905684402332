import { useMemo, useState } from 'react';
import RadioButton from '@components/radio';
import { useDebounce } from 'react-use';
import Fuse from 'fuse.js';
import MyInput from '@components/myInput';
type Props = {
  close?: Function,
  context?: {
    value?: string,
    setValue?: Function,
    selections?: Array,
    isShowSearchBox?: boolean,
    loading?: boolean,
    getObject?: boolean,
    hasItemRadio?: boolean,
  },
};

type SelectItemProps = {
  textStyle?: string,
  onClick?: Function,
  value?: string,
  hasItemRadio?: boolean,
  isChecked?: boolean,
};

function SelectItem({
  textStyle,
  onClick,
  value,
  hasItemRadio,
  isChecked,
}: SelectItemProps) {
  return !hasItemRadio ? (
    <div onClick={onClick} className={`dropdownSelectItem ${textStyle} h-44px`}>
      {value}
    </div>
  ) : (
    <div onClick={onClick} className="dropdownSelectItemRadio">
      <RadioButton isChecked={isChecked} onPress={onClick} title={value} />
    </div>
  );
}

export default function SelectionComponentFilter(props: Props) {
  const {
    close,
    context: {
      value = '',
      setValue = () => {},
      selections = [],
      isShowSearchBox,
      loading,
      getObject,
      hasItemRadio = true,
    },
  } = props;

  const [search, setSearch] = useState('');
  const [debounce, setDebounce] = useState('');
  useDebounce(
    () => {
      setDebounce(search);
    },
    300,
    [search]
  );

  const options = {
      includeScore: true,
      keys: ['value'],
      threshold: 0.5,
      includeMatches: true,
      ignoreLocation: true,
      distance: 600,
    },
    fuse = new Fuse(selections, options),
    result = fuse.search(debounce);

  const finalSelections = useMemo(() => {
    return debounce !== '' ? result?.map(e => e.item) : [...(selections || [])];
  }, [debounce, result, selections]);
  return (
    <div className="w-300px rounded-lg bg-white flex flex-col border border-blue-main space-y-2 ">
      {isShowSearchBox && (
        <div
          style={{
            zIndex: 1000,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
          className="flex-1 sticky bg-white px-4 pb-2 border-b border-gray-300 mt-4"
        >
          <MyInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Nhập từ khóa để tìm kiếm"
            onDelete={() => setSearch('')}
          />
        </div>
      )}
      <div
        style={{ maxHeight: 300 }}
        className="py-4 flex flex-col overflow-y-auto"
      >
        <div className="w-full">
          {!loading &&
            finalSelections.map((item, index) => {
              const isActive = item.key === value;
              const lastItem = index === finalSelections.length - 1;
              return (
                <SelectItem
                  hasItemRadio={hasItemRadio}
                  isChecked={isActive}
                  key={index}
                  onClick={() => {
                    if (!getObject) {
                      setValue(item.key);
                    } else {
                      setValue(item);
                    }
                    close();
                  }}
                  value={item.value}
                  textStyle={`${
                    isActive ? `text-blue-main font-medium` : 'text-black-main'
                  } ${!lastItem ? 'border-b border-gray-300' : undefined}`}
                />
              );
            })}
        </div>
        {loading && (
          <div className="flex flex-1 flex-row items-center justify-center space-x-1 py-4">
            <div className="text-sm text-blue-main">Đang tải</div>
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
          </div>
        )}
      </div>
    </div>
  );
}
