import gql from 'graphql-tag';

export const GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS = gql`
  query @api(name: b2bRealEstate) {
    getInternalRealEstateConfigurations {
      common {
        projectStatusType {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        realEstateForSaleAndRentStatus {
          key
          value
        }
        postTypes {
          key
          value
          fee
        }
        neededRealEstateTradingStatus {
          key
          value
        }
        fixedUtilities {
          key
          value
        }
        utilities {
          key
          value
        }
        localUtilities {
          key
          value
        }
        equipmentsAndServices {
          key
          value
        }
        forRentRealEstateTypes {
          key
          value
        }
        forSellRealEstateTypes {
          key
          value
        }
        priceUnitsForRent {
          key
          value
        }
        priceUnitsForSell {
          key
          value
        }
        timeForRentUnits {
          key
          value
        }
        legals {
          estateType
          key
          value
        }
        warehouseType {
          key
          value
        }
        projectForSaleTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectForRentTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectSubTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectLegals {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        projectViews {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        collectionTypes {
          key
          value
          fee
          sale
          isForRent
          isForSell
          estateType
        }
        directions {
          key
          value
        }
        realEstateForSaleStatus {
          key
          value
        }
        realEstateForRentStatus {
          key
          value
        }
        villaViewTypes {
          key
          value
        }
      }
      neededRealEstate {
        townhouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
        }
        apartment {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
        }
        villa {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        building {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        hotel {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        warehouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        land {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        shophouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        penthouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
        motel {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
        }
      }
      filter {
        common {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        townhouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        apartment {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        shophouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        villa {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        penthouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        land {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        building {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        hotel {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        warehouse {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        motel {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits {
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
        project {
          area {
            key
            value
            from
            to
            unit
          }
          width {
            key
            value
            from
            to
            unit
          }
          length {
            key
            value
            from
            to
            unit
          }
          priceToBuy {
            key
            value
            from
            to
            unit
          }
          priceToRent {
            key
            value
            from
            to
            unit
          }
          priceToRentByDay {
            key
            value
            from
            to
            unit
          }
          priceToRentByMonth {
            key
            value
            from
            to
            unit
          }
          floorCount {
            key
            value
            from
            to
            unit
          }
          floorPosition {
            key
            value
            from
            to
            unit
          }
          roomCount {
            key
            value
            from
            to
            unit
          }
          toiletCount {
            key
            value
            from
            to
            unit
          }
          bedroomCount {
            key
            value
            from
            to
            unit
          }
          villaView {
            key
            value
            from
            to
            unit
          }
          hotelStar {
            key
            value
            from
            to
            unit
          }
          timeToRent {
            key
            value
            from
            to
            unit
          }
          #          timeForRentUnits{
          #            key
          #            value
          #            from
          #            to
          #            unit
          #          }
        }
      }
    }
  }
`;
