export const headerTableRealEstateEditHistoryConfigs = [
  {
    key: 'width',
    title: 'Ngang (m)',
    className: 'w-124px',
    path: '/width',
    isShow: [true],
  },
  {
    key: 'length',
    title: 'Chiều dài (m)',
    className: 'w-124px',
    path: '/length',
    isShow: [true],
  },
  {
    key: 'rear',
    title: 'Mặt hậu (m)',
    className: 'w-124px',
    path: '/rear',
    isShow: [true],
  },
  {
    key: 'area',
    title: 'Diện tích (m²)',
    className: 'w-124px',
    path: '/area',
    isShow: [true],
  },
  {
    key: 'constructionArea',
    title: 'Diện tích sàn (m²)',
    className: 'w-181px',
    path: '/constructionArea',
    isShow: [true],
  },
  {
    key: 'price',
    title: 'Giá',
    className: 'w-154px',
    path: '/price',
    isShow: [true],
  },
  {
    key: 'commission',
    title: 'Phí hoa hồng',
    className: 'w-154px',
    path: '/commission',
    isShow: [true],
  },
  {
    key: 'hasAlley',
    title: 'Mặt tiền hay hẻm',
    className: 'w-124px',
    path: '/hasAlley',
    isShow: [true],
  },
  {
    key: 'alleyTurnCount',
    title: 'Số lần quẹo hẻm',
    className: 'w-124px',
    path: '/alleyTurnCount',
    isShow: [true],
  },
  {
    key: 'alleyWidth',
    title: 'Độ rộng hẻm trước nhà',
    className: 'w-164px',
    path: '/alleyWidth',
    isShow: [true],
  },
  {
    key: 'floorCount',
    title: 'Số tầng',
    className: 'w-133px',
    path: '/floorCount',
    isShow: [
      'townhouse',
      'villa',
      'building',
      'hotel',
      'warehouse',
      'shophouse',
      'penthouse',
    ],
  },
  {
    key: 'roomCount',
    title: 'Số lượng phòng',
    className: 'w-181px',
    path: '/roomCount',
    isShow: ['hotel'],
  },
  {
    key: 'warehouseRoomCount',
    title: 'Số phòng kho',
    className: 'w-181px',
    path: '/warehouseRoomCount',
    isShow: ['warehouse'],
  },
  {
    key: 'bedroomCount',
    title: 'Số phòng ngủ',
    className: 'w-181px',
    path: '/bedroomCount',
    isShow: [
      'townhouse',
      'apartment',
      'villa',
      'penthouse',
      'condotel',
      'motel',
    ],
  },
  {
    key: 'bedRoomArea',
    title: 'Diện tích phòng ngủ',
    className: 'w-181px',
    path: '/bedRoomArea',
    isShow: [true],
  },
  {
    key: 'toiletCount',
    title: 'Số nhà vệ sinh',
    className: 'w-181px',
    path: '/toiletCount',
    isShow: [
      'townhouse',
      'apartment',
      'shophouse',
      'villa',
      'penthouse',
      'building',
      'warehouse',
      'condotel',
      'motel',
    ],
  },
  {
    key: 'direction',
    title: 'Hướng',
    className: 'w-181px',
    path: '/direction',
    isShow: [true],
  },
  {
    key: 'isNewConstruction',
    title: 'Hiện trạng',
    className: 'w-181px',
    path: '/isNewConstruction',
    isShow: [true],
  },
  {
    key: 'shophouseField',
    title: 'Ngành hàng',
    className: 'w-181px',
    path: '/shophouseField',
    isShow: ['shophouse'],
  },
  {
    key: 'block',
    title: 'Thuộc Block',
    className: 'w-181px',
    path: '/block',
    isShow: ['apartment'],
  },
  {
    key: 'roomNumber',
    title: 'Số thứ tự phòng',
    className: 'w-181px',
    path: '/roomNumber',
    isShow: ['apartment'],
  },
  {
    key: 'onFloor',
    title: 'Vị trí tầng',
    className: 'w-181px',
    path: '/onFloor',
    isShow: ['apartment', 'penthouse', 'motel'],
  },
  {
    key: 'buildingType',
    title: 'Loại cao ốc',
    className: 'w-181px',
    path: '/buildingType',
    isShow: ['building'],
  },
  {
    key: 'warehouseType',
    title: 'Chức năng nhà xưởng',
    className: 'w-181px',
    path: '/warehouseType',
    isShow: ['warehouse'],
  },
  {
    key: 'hotelStar',
    title: 'Số sao',
    className: 'w-181px',
    path: '/hotelStar',
    isShow: ['hotel'],
  },
  {
    key: 'hasMezzanine',
    title: 'Có gác hay không',
    className: 'w-181px',
    path: '/hasMezzanine',
    isShow: ['motel'],
  },
  {
    key: 'privateNote',
    title: 'Ghi chú cá nhân',
    className: 'w-300px',
    path: '/privateNote',
    isShow: [true],
  },
  {
    key: 'project',
    title: 'Dự án',
    className: 'w-300px',
    path: '/project',
    isShow: [true],
  },
  {
    key: 'fixedUtilities',
    title: 'Tiện ích cố định',
    className: 'w-181px',
    path: '/fixedUtilities',
    isShow: [true],
  },
  {
    key: 'utilities',
    title: 'Tiện ích',
    className: 'w-181px',
    path: '/utilities',
    isShow: [true],
  },
  {
    key: 'localUtilities',
    title: 'Tiện ích khu vực',
    className: 'w-181px',
    path: '/localUtilities',
    isShow: [true],
  },
  {
    key: 'villaView',
    title: 'View',
    className: 'w-181px',
    path: '/villaView',
    isShow: [true],
  },
  {
    key: 'equipmentsAndServices',
    title: 'Thiết bị, dịch vụ',
    className: 'w-181px',
    path: '/equipmentsAndServices',
    isShow: [true],
  },
  {
    key: 'mediaUrls',
    title: 'Hình ảnh & video',
    className: 'w-181px',
    path: '/media',
    isShow: [true],
  },
  {
    key: 'attachments',
    title: 'Tài liệu đính kèm',
    className: 'w-181px',
    path: '/attachments',
    isShow: [true],
  },
  {
    key: 'date',
    title: 'Ngày chỉnh sửa',
    className: 'w-181px',
    isShow: [true],
  },
  {
    key: 'logCreator',
    title: 'Người chỉnh sửa',
    className: 'w-181px',
    isShow: [true],
  },
];
