import Label from '@components/label';
import MyInput from '@components/myInput';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import PhoneInput from '@components/phoneInput';
import { maxLength } from '../constant';
import { EMAIL_PATTERN, regexValidateName } from '@utils/configs/regex';
import Section from '@components/section';

export const fieldConditionConfigs = {
  contactName: {
    dataVisible: state => state?.contactName?.length > 0,
  },
  contactPhones: {
    dataVisible: state => state.contactPhones?.[0]?.phone?.length > 0,
  },
  contactEmail: {
    dataVisible: state => state?.contactEmail?.length > 0,
  },
  career: {
    dataVisible: state => state?.career?.length > 0,
  },
};
export const getTotalFieldCount = props => {
  const field = ['contactName', 'contactPhones', 'contactEmail', 'career'];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
type Props = {
  isDisableInput?: boolean,
  headerText?: string,
  fieldNames?: Object,
  hiddenFields?: Array,
  totalShowFields?: number,
  namePlaceholder?: string,
  isShowTips?: boolean,
};
export default function OwnerInformation({
  isDisableInput = true,
  headerText = 'Thông tin chủ nhà',
  fieldNames = {
    name: 'contactName',
    phone: 'contactPhones',
    email: 'contactEmail',
  },
  namePlaceholder = 'chủ nhà',
  hiddenFields = [],
  totalShowFields = 4,
  isShowTips = false,
}: Props) {
  const { control, watch } = useFormContext();
  const contactName = watch(fieldNames?.name);
  const contactPhone = watch(fieldNames?.phone);
  const contactEmail = watch(fieldNames?.email);
  const career = watch('career');
  const data = {
    contactName,
    contactPhones: contactPhone,
    contactEmail,
    career,
  };
  return (
    <Section>
      <div className="flex flex-row">
        <div className="text-base text-blue-main font-medium">
          {`${headerText} (${
            getTotalFieldCount(data).fieldVisibleCount
          }/${totalShowFields})`}
        </div>
        <div
          className={
            isShowTips
              ? `flex flex-1 space-x-1 items-center justify-end`
              : 'hidden'
          }
        >
          <img className="w-16px h-16px" src="/svg/info.svg" alt="Info icon" />
          <span className="text-sm font-normal">
            Thông tin chủ nhà sẽ được ẩn đi trên web{' '}
            <b className="font-medium">https://www.realtech247.com</b>
          </span>
        </div>
      </div>
      <div className="w-full mt-7 space-y-7">
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" isRequired label="Họ tên" />
          <Controller
            rules={{
              required: `Vui lòng nhập tên ${namePlaceholder}`,
              validate: {
                checkName: value =>
                  value?.trim()?.match(regexValidateName)
                    ? true
                    : 'Họ và tên không hợp lệ',
              },
            }}
            control={control}
            name={fieldNames?.name}
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  inputClassName="flex-1"
                  invalid={invalid}
                  errorMessage={error?.message}
                  placeholder={`Nhập họ tên ${namePlaceholder}`}
                  onDelete={() => onChange('')}
                  maxLength={maxLength.name}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" isRequired label="Số điện thoại" />
          {!hiddenFields?.includes(fieldNames?.phone) ? (
            <PhoneInput name={fieldNames?.phone} isDisable={isDisableInput} />
          ) : (
            <span className="text-sm text-orange-400">
              Bạn không có quyền xem SĐT chủ nhà
            </span>
          )}
        </div>
        <div
          className={
            hiddenFields?.includes(fieldNames?.email)
              ? 'hidden'
              : 'w-full flex flex-row space-x-2.5 items-start'
          }
        >
          <Label wrapperClass="w-152px" label="Email" />
          <Controller
            rules={{
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Vui lòng nhập email đúng định dạng',
              },
            }}
            control={control}
            name={fieldNames?.email}
            render={({
              field,
              field: { onChange, value },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  inputClassName="flex-1"
                  invalid={invalid}
                  hideMaxLength
                  maxLength={maxLength.email}
                  errorMessage={error?.message}
                  value={value}
                  onChange={nextValue => onChange(nextValue)}
                  onDelete={() => onChange('')}
                  placeholder="Ví dụ: nguyenvana@gmail.com"
                />
              );
            }}
          />
        </div>
        <div
          className={
            hiddenFields?.includes('career')
              ? 'hidden'
              : 'w-full flex flex-row space-x-2.5 items-start'
          }
        >
          <Label wrapperClass="w-152px" label="Ngành nghề" />
          <Controller
            control={control}
            name="career"
            render={({ field, field: { onChange } }) => {
              return (
                <MyInput
                  {...field}
                  inputClassName="flex-1"
                  placeholder="Nhập ngành nghề"
                  onDelete={() => onChange('')}
                  maxLength={maxLength.name}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
      </div>
    </Section>
  );
}
