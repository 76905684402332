import React from 'react';
import Section from './section';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';

type Props = {
  data?: Object,
};

export default function Utilities({ data }: Props) {
  const {
    utilities,
    fixedUtilities,
    localUtilities,
    equipmentsAndServices,
    villaViewTypes,
  } = configs;

  return (
    <Section source="/svg/settingCheck.svg" title="Tiện ích">
      <div className="flex flex-col space-y-4 py-2">
        <Items
          title="Tiện ích cố định"
          configs={fixedUtilities}
          value={data?.fixedUtilities}
        />
        <Items title="Tiện ích" configs={utilities} value={data?.utilities} />
        <Items
          title="Tiện ích trong khu vực"
          configs={localUtilities}
          value={data?.localUtilities}
        />
        <Items title="View" configs={villaViewTypes} value={data?.villaView} />
        <Items
          title="Thiết bị, dịch vụ"
          configs={equipmentsAndServices}
          value={data?.equipmentsAndServices}
        />
      </div>
    </Section>
  );
}

type ItemsProps = {
  title?: string,
  value?: Array,
  configs?: Array,
};

function Items(props: ItemsProps) {
  const { title, value = [], configs = [] } = props;

  const foundConfigs = configs
    ?.filter(e => value?.includes(e.key))
    ?.map(item => item.value)
    ?.join(', ');
  if (!value?.length) {
    return null;
  }

  return (
    <div className="flex w-full flex-col space-y-3">
      <div className="text-sm font-medium text-black-main">{title}</div>
      <div
        style={{ flexWrap: 'wrap' }}
        className="text-sm text-black-main break-words"
      >
        {foundConfigs}
      </div>
    </div>
  );
}
