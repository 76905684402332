import Label from '@components/label';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyInput from '@components/myInput';
import PasswordInput from '@components/passwordInput';
import {
  regexValidationPassword,
  regexValidPassword,
} from '@utils/configs/regex';
export const fieldConditionConfigs = {
  username: {
    dataVisible: state => state?.username?.length > 0,
  },
  password: {
    dataVisible: state => state.password?.length > 0,
  },
  confirmPassword: {
    dataVisible: state => state?.confirmPassword?.length > 0,
  },
};
export const getTotalFieldCount = props => {
  const field = ['username', 'password', 'confirmPassword'];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
type Props = {
  isUpdate?: boolean,
};
export default function LoginDetailsInput({ isUpdate }: Props) {
  const { control, watch } = useFormContext();
  const username = watch('username');
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const data = { username, password, confirmPassword };
  return (
    <div className="space-y-6 ">
      <div className="font-medium text-base text-blue-main">
        Thông tin đăng nhập ({getTotalFieldCount(data).fieldVisibleCount}/3)
      </div>
      <div className="w-full space-y-6 ">
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass="w-230px"
            label="Tên đăng nhập"
            isRequired={true}
          />
          <Controller
            rules={{
              required: 'Vui lòng nhập tên đăng nhập',
              validate: {
                checkPassword: value =>
                  value?.toString()?.match(regexValidationPassword)
                    ? true
                    : 'Vui lòng nhập tên đăng nhập hợp lệ',
              },
            }}
            control={control}
            name="username"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName="w-full flex-1"
                  placeholder="Nhập tên đăng nhập"
                  onDelete={() => onChange('')}
                  maxLength={118}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass="w-230px"
            label={isUpdate ? 'Nhập mật khẩu mới' : 'Mật khẩu'}
            isRequired={!isUpdate}
          />
          <Controller
            rules={{
              required: isUpdate ? false : 'Vui lòng nhập mật khẩu',
              validate: {
                checkPass: value => {
                  if (isUpdate) {
                    return value?.length === 0 ||
                      value?.toString()?.match(regexValidPassword)
                      ? true
                      : 'Vui lòng nhập mật khẩu 6-32 ký tự, gồm có ít nhất một chữ cái thường và 1 chữ cái in hoa';
                  } else {
                    return value?.match(regexValidPassword)
                      ? true
                      : 'Vui lòng nhập mật khẩu 6-32 ký tự, gồm có ít nhất một chữ cái thường và 1 chữ cái in hoa';
                  }
                },
              },
            }}
            control={control}
            name="password"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <PasswordInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName="w-full flex-1"
                  placeholder={isUpdate ? 'Nhập mật khẩu mới' : 'Nhập mật khẩu'}
                  onDelete={() => onChange('')}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass="w-230px"
            label={isUpdate ? 'Xác nhận mật khẩu mới' : 'Xác nhận mật khẩu'}
            isRequired={!isUpdate}
          />
          <Controller
            rules={{
              required: isUpdate ? false : 'Vui lòng nhập xác nhận mật khẩu',
              validate: {
                checkMatchPassword: value => {
                  if (isUpdate) {
                    return value?.length === 0
                      ? true
                      : value === watch()?.password
                      ? true
                      : 'Bạn nhập lại mật khẩu không đúng. Vui lòng nhập lại';
                  } else {
                    return value === watch()?.password
                      ? true
                      : 'Bạn nhập lại mật khẩu không đúng. Vui lòng nhập lại';
                  }
                },
              },
            }}
            control={control}
            name="confirmPassword"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <PasswordInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName="w-full flex-1"
                  placeholder="Nhập lại mật khẩu"
                  onDelete={() => onChange('')}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
