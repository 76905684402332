import React from 'react';
import MyInput from '@components/myInput';

type Props = {
  wrapperStyle?: string,
  from?: string,
  to?: string,
  setFrom?: Function,
  setTo?: Function,
  unit?: string,
  errorMessage?: string,
  handleKeyPress?: Function,
  borderError?: boolean,
};

export default function FromToInput(props: Props) {
  const {
    wrapperStyle,
    from,
    setFrom,
    to,
    setTo,
    // unit: initialUnit,
    errorMessage,
    handleKeyPress,
    borderError,
  } = props;
  return (
    <div className={`flex flex-row items-center ${wrapperStyle}`}>
      <MyInput
        type="number"
        errorMessage={errorMessage}
        value={from}
        onKeyPress={handleKeyPress}
        onChange={text => {
          setFrom(text.target.value);
        }}
        inputOuterClassName="w-139px"
        inputInnerClassName="w-full"
        placeholder="Từ"
        onDelete={() => setFrom('')}
        inputClassName={`${
          borderError ? 'border border-red-500 rounded-lg' : null
        }`}
      />
      <p className="text-sm mx-2">-</p>
      <MyInput
        type="number"
        errorMessage={errorMessage}
        value={to}
        onKeyPress={handleKeyPress}
        onChange={text => {
          setTo(text.target.value);
        }}
        onDelete={() => setTo('')}
        inputInnerClassName="w-full"
        inputOuterClassName="w-139px"
        placeholder="Đến"
        inputClassName={`${
          borderError ? 'border border-red-500 rounded-lg' : null
        }`}
      />
      {/*<View*/}
      {/*  style={{*/}
      {/*    marginLeft: 10,*/}
      {/*  }}>*/}
      {/*  <Typography type="bodyText">{initialUnit}</Typography>*/}
      {/*</View>*/}
    </div>
  );
}
