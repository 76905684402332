import RenderLeft from './renderLeft';
import RenderRight from './renderRight';

type Props = {
  data?: Object,
  demand?: string,
};
export default function ContentDetailRealEstate({ data, demand }: Props) {
  return (
    <div className="w-full flex flex-row space-x-4">
      <RenderLeft demand={demand} data={data} />
      <RenderRight data={data} demand={demand} />
    </div>
  );
}
