import styles from '@components/table/table.module.scss';
import needApprovedStyle from './needApproval.module.scss';
import React from 'react';
import { checkVisibleCol } from './renderHeader';
import { useNeedApprovalContext } from '../context';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import {
  demandConfigs,
  isNewConstructionConfigs,
  needDemandConfigs,
} from '@utils/configs/configsLocal';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import moment from 'moment/moment';
type RenderTagProps = {
  value?: any,
  removeValue?: Function,
};

const getValueSlider = ({
  keyValue,
  from,
  to,
  min,
  max,
  listSelections,
  showPlus,
  renderToValue: toValue,
  exceptValue,
  unit,
}) => {
  let finalValue = '';
  const tempUnit = listSelections?.find(e => e.key === keyValue)?.unit || unit;
  const renderUnit =
    to > max && showPlus ? '+ ' + ' ' + tempUnit : ' ' + tempUnit;
  const renderToValue = to > toValue ? toValue : to;
  const renderValue = keyValue
    ? listSelections?.find(e => e.key === keyValue)?.value
    : from + ' - ' + renderToValue + renderUnit;
  if ((!from && !to) || (from === min && to >= max)) {
    finalValue = 'Tất cả';
  } else {
    finalValue = exceptValue?.length > 0 ? exceptValue : renderValue;
  }
  return finalValue;
};
export function RenderTag({ value, removeValue }: RenderTagProps) {
  const { setListSelected } = useNeedApprovalContext();
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div className={needApprovedStyle.tagFilterResult}>
      <div className="text-13 truncate text-black-main">{value}</div>
      {removeValue && (
        <img
          onClick={() => {
            setListSelected?.clear();
            removeValue();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  );
}
type Props = {
  data?: array,
};

export default function RenderResultFilterHeader(props: Props) {
  const { data } = props;
  const { forSellRealEstateTypes, forRentRealEstateTypes, constructionArea } =
    configsRemote;
  const { townhouse } = configs;
  const { width, length, area, priceToBuy, priceToRent, floorCount } =
    townhouse || {};
  const convertWidth =
    width?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertLength =
    length?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertArea =
    area?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 20001 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToBuy =
    priceToBuy?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToRent =
    priceToRent?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 200.5 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertFloorCount =
    floorCount?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertConstructionArea =
    constructionArea?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const { stateFilter, setStateFilterAt, demand } = useNeedApprovalContext();
  const {
    realEstateId,
    isForSell,
    realEstateType,
    streetName,
    wardName,
    districtName,
    provinceName,
    widthKey,
    widthFrom,
    widthTo,
    lengthKey,
    lengthFrom,
    lengthTo,
    rearKey,
    rearFrom,
    rearTo,
    areaKey,
    areaFrom,
    areaTo,
    priceKey,
    priceFrom,
    priceTo,
    floorCountKey,
    floorCountFrom,
    floorCountTo,
    constructionAreaKey,
    constructionAreaFrom,
    constructionAreaTo,
    isNewConstruction,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    approvedAtFrom,
    approvedAtTo,
    approvedBy,
    houseNumber,
  } = stateFilter;
  const realEstateTypeConfigs =
    isForSell === 'ForSale' ? forSellRealEstateTypes : forRentRealEstateTypes;
  const listPrice =
    isForSell === 'ForRent' || isForSell === 'ToRent'
      ? convertPriceToRent
      : convertPriceToBuy || [];
  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div className={`${styles.headContainer} w-170px items-center `}>
          <RenderTag
            value={realEstateId}
            removeValue={() => setStateFilterAt('realEstateId')('')}
          />
        </div>
        {checkVisibleCol('isForSell') && (
          <div className={`${styles.headContainer} w-140px items-center `}>
            <RenderTag
              value={
                demand === 'sell'
                  ? demandConfigs.find(e => e.key === isForSell)?.value
                  : needDemandConfigs.find(e => e.key === isForSell)?.value
              }
              removeValue={() => {
                setStateFilterAt('isForSell')(
                  demand === 'sell' ? 'ForSale' : 'ToBuy'
                );
                setStateFilterAt('realEstateType')(['townhouse']);
              }}
            />
          </div>
        )}
        {checkVisibleCol('type') && (
          <div className={`${styles.headContainer} w-145px items-center`}>
            <RenderTag
              value={
                realEstateType?.length === 1
                  ? realEstateTypeConfigs.find(
                      e => e.key === realEstateType?.[0]
                    )?.value
                  : realEstateType.length
                  ? `${realEstateType.length} loại`
                  : ''
              }
              removeValue={() => setStateFilterAt('realEstateType')([])}
            />
          </div>
        )}
        {checkVisibleCol('houseNumber') && !data[0]?.isNeedToBuy && (
          <div className={`${styles.headContainer} w-170px items-center`}>
            <RenderTag
              value={houseNumber}
              removeValue={() => setStateFilterAt('houseNumber')('')}
            />
          </div>
        )}
        {checkVisibleCol('streetName') && (
          <div className={`${styles.headContainer} w-170px items-center`}>
            <RenderTag
              value={streetName}
              removeValue={() => {
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('wardName') && (
          <div className={`${styles.headContainer} w-140px items-center`}>
            <RenderTag
              value={wardName}
              removeValue={() => {
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('districtName') && (
          <div className={`${styles.headContainer} w-145px items-center`}>
            <RenderTag
              value={districtName}
              removeValue={() => {
                setStateFilterAt('district')('');
                setStateFilterAt('districtName')('');
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('provinceName') && (
          <div className={`${styles.headContainer} w-175px items-center`}>
            <RenderTag
              value={provinceName}
              removeValue={() => {
                setStateFilterAt('province')('');
                setStateFilterAt('provinceName')('');
                setStateFilterAt('district')('');
                setStateFilterAt('districtName')('');
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('country') && (
          <div className={`${styles.headContainer} w-124px items-center`}>
            <RenderTag value={'Việt Nam'} />
          </div>
        )}
        {checkVisibleCol('width') && (
          <div className={`${styles.headContainer} w-124px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: widthKey,
                from: widthFrom,
                to: widthTo,
                min: 0,
                max: 100,
                renderToValue: 100,
                exceptValue:
                  (widthFrom === 0 || !widthFrom) && widthTo === 3
                    ? '< 3 m'
                    : widthFrom === 100 && widthTo === 101
                    ? '> 100 m'
                    : '',
                listSelections: convertWidth,
                unit: 'm',
              })}
              removeValue={() => {
                setStateFilterAt('widthKey')('');
                setStateFilterAt('widthFrom')('');
                setStateFilterAt('widthTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('length') && (
          <div className={`${styles.headContainer} w-124px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: lengthKey,
                from: lengthFrom,
                to: lengthTo,
                min: 0,
                max: 500,
                renderToValue: 500,
                exceptValue:
                  (lengthFrom === 0 || !lengthFrom) && lengthTo === 10
                    ? '< 10 m'
                    : lengthFrom === 500 && lengthTo === 501
                    ? '> 500 m'
                    : '',
                listSelections: convertLength,
                unit: 'm',
              })}
              removeValue={() => {
                setStateFilterAt('lengthKey')('');
                setStateFilterAt('lengthFrom')('');
                setStateFilterAt('lengthTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('rear') && (
          <div className={`${styles.headContainer} w-124px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: rearKey,
                from: rearFrom,
                to: rearTo,
                min: 0,
                max: 100,
                renderToValue: 100,
                exceptValue:
                  (rearFrom === 0 || !rearFrom) && rearTo === 3
                    ? '< 3 m'
                    : rearFrom === 100 && rearTo === 101
                    ? '> 100 m'
                    : '',
                listSelections: convertWidth,
                unit: 'm',
              })}
              removeValue={() => {
                setStateFilterAt('rearKey')('');
                setStateFilterAt('rearFrom')('');
                setStateFilterAt('rearTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('area') && (
          <div className={`${styles.headContainer} w-124px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: areaKey,
                from: areaFrom,
                to: areaTo,
                min: 0,
                max: 5000,
                renderToValue: 20000,
                exceptValue:
                  (areaFrom === 0 || !areaFrom) && areaTo === 10
                    ? '< 10 m²'
                    : '',
                listSelections: convertArea,
                unit: 'm²',
              })}
              removeValue={() => {
                setStateFilterAt('areaKey')('');
                setStateFilterAt('areaFrom')('');
                setStateFilterAt('areaTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('price') && (
          <div className={`${styles.headContainer} w-154px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: priceKey,
                from: priceFrom,
                to: priceTo,
                min: 0,
                max:
                  isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50,
                renderToValue:
                  isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 100,
                exceptValue:
                  (priceFrom === 0 || !priceFrom) &&
                  priceTo === 1 &&
                  (isForSell === 'ForRent' || isForSell === 'ToRent')
                    ? '<= 1 triệu'
                    : priceFrom === 200 &&
                      priceTo === 200.5 &&
                      (isForSell === 'ForRent' || isForSell === 'ToRent')
                    ? '>= 200 triệu'
                    : priceFrom === 100 && priceTo === 101
                    ? '> 100 tỷ'
                    : '',
                listSelections: listPrice,
                unit:
                  isForSell === 'ForRent' || isForSell === 'ToRent'
                    ? 'triệu'
                    : 'tỷ',
              })}
              removeValue={() => {
                setStateFilterAt('priceKey')('');
                setStateFilterAt('priceFrom')('');
                setStateFilterAt('priceTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('floorCount') && (
          <div className={`${styles.headContainer} w-133px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: floorCountKey,
                from: floorCountFrom,
                to: floorCountTo,
                min: 0,
                max: 168,
                renderToValue: 168,
                exceptValue:
                  floorCountFrom === 0 && floorCountTo === 3 ? '<= 3 tầng' : '',
                listSelections: convertFloorCount,
                unit: 'tầng',
              })}
              removeValue={() => {
                setStateFilterAt('floorCountKey')('');
                setStateFilterAt('floorCountFrom')('');
                setStateFilterAt('floorCountTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('constructionArea') && (
          <div className={`${styles.headContainer} w-181px items-center`}>
            <RenderTag
              value={getValueSlider({
                keyValue: constructionAreaKey,
                from: constructionAreaFrom,
                to: constructionAreaTo,
                min: 0,
                max: 500,
                renderToValue: 500,
                exceptValue:
                  (constructionAreaFrom === 0 || !constructionAreaFrom) &&
                  constructionAreaTo === 10
                    ? '< 10 m'
                    : constructionAreaFrom === 500 && constructionAreaTo === 501
                    ? '> 500 m'
                    : '',
                listSelections: convertConstructionArea,
                unit: 'm²',
              })}
              removeValue={() => {
                setStateFilterAt('constructionAreaKey')('');
                setStateFilterAt('constructionAreaFrom')(0);
                setStateFilterAt('constructionAreaTo')(20001);
              }}
            />
          </div>
        )}
        {checkVisibleCol('isNewConstruction') && (
          <div className={`${styles.headContainer} w-166px items-center`}>
            <RenderTag
              value={
                isNewConstruction?.length === 2
                  ? 'Tất cả'
                  : isNewConstructionConfigs.find(
                      e => e.key === isNewConstruction[0]
                    )?.value
              }
              removeValue={() => setStateFilterAt('isNewConstruction')([])}
            />
          </div>
        )}
        {checkVisibleCol('createdAt') && (
          <div className={`${styles.headContainer} w-185px items-center`}>
            <RenderTag
              value={
                createdAtFrom && createdAtTo
                  ? `${moment
                      .unix(createdAtFrom)
                      .format('DD/MM/YYYY')} - ${moment
                      .unix(createdAtTo)
                      .format('DD/MM/YYYY')}`
                  : 'Tất cả'
              }
              removeValue={() => {
                setStateFilterAt('createdAtFrom')('');
                setStateFilterAt('createdAtTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('updatedAt') && (
          <div className={`${styles.headContainer} w-218px items-center`}>
            <RenderTag
              value={
                updatedAtFrom && updatedAtTo
                  ? `${moment
                      .unix(updatedAtFrom)
                      .format('DD/MM/YYYY')} - ${moment
                      .unix(updatedAtTo)
                      .format('DD/MM/YYYY')}`
                  : 'Tất cả'
              }
              removeValue={() => {
                setStateFilterAt('updatedAtFrom')('');
                setStateFilterAt('updatedAtTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('approvedBy') && (
          <div className={`${styles.headContainer} w-218px items-center`}>
            <RenderTag
              value={approvedBy}
              removeValue={() => setStateFilterAt('approvedBy')('')}
            />
          </div>
        )}
        {checkVisibleCol('approvedAt') && (
          <div className={`${styles.headContainer} w-218px items-center`}>
            <RenderTag
              value={
                approvedAtFrom && approvedAtTo
                  ? `${moment
                      .unix(approvedAtFrom)
                      .format('DD/MM/YYYY')} - ${moment
                      .unix(approvedAtTo)
                      .format('DD/MM/YYYY')}`
                  : 'Tất cả'
              }
              removeValue={() => {
                setStateFilterAt('approvedAtFrom')('');
                setStateFilterAt('approvedAtTo')('');
              }}
            />
          </div>
        )}
        {checkVisibleCol('department') && (
          <div className={`${styles.headContainer} w-218px items-center`} />
        )}
      </div>
    </div>
  );
}
