import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  lengthKey?: string,
  lengthFrom?: number,
  lengthTo?: number,
  setLengthKey?: Function,
  setLengthFrom?: Function,
  setLengthTo?: Function,
  onResetSelected?: Function,
};
export default function InputLengthDropdown({
  lengthKey,
  lengthFrom,
  lengthTo,
  setLengthKey,
  setLengthFrom,
  setLengthTo,
  onResetSelected,
}: Props) {
  const { townhouse } = configs;
  const { length } = townhouse || {};
  const convertLength =
    length?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle="Dài (m)"
      stepRenderLine={50}
      renderToValue={500}
      progress={250.5}
      sliderTitle={'Dài (m)'}
      key={Math.random() + lengthFrom + lengthTo}
      theValueIsShow={500}
      listSelections={convertLength || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setLengthKey(key);
        setLengthFrom(from);
        setLengthTo(to);
      }}
      keyValue={lengthKey}
      from={lengthFrom}
      to={lengthTo}
      unit={'m'}
      min={0}
      max={500}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 501, value: 'Tất cả' }]}
    />
  );
}
