import React, { useState } from 'react';
import MediaComponent from '@components/mediaComponent';
import MyModalV2 from '@components/modalV2/myModalV2';
import ModalViewMedia from 'modals/modalViewMedia';

type Props = {
  onRemove?: Function,
  imageUrls?: Array,
  readOnly?: boolean,
};
export default function FilesResult(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex1, setActiveIndex1] = useState(0);
  const { imageUrls, readOnly, onRemove } = props,
    images = imageUrls?.filter(e => e.type === 'image'),
    videos = imageUrls?.filter(e => ['video', 'youtube'].indexOf(e.type) >= 0);
  return (
    (!!images?.length || !!videos?.length) && (
      <div className="w-full mt-4 rounded-xl border bg-blue-history">
        {!!images?.length && (
          <div className="w-full p-3 space-y-4 ">
            <div className="w-78px text-13 text-blue-main font-medium">
              Hình ảnh
            </div>
            <div className="grid-cols-5 grid gap-3">
              {images?.map((item, index) => {
                return (
                  <MediaComponent
                    onPress={() => {
                      setShowModal(true);
                      setActiveIndex(index);
                    }}
                    {...item}
                    remove={() => {
                      onRemove(item.fileId);
                    }}
                    key={item.fileId}
                    readOnly={readOnly}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!!videos?.length && (
          <div className="w-ful p-3 space-y-4">
            <div className="w-78px text-13 text-blue-main font-medium">
              Video
            </div>
            <div className="grid-cols-5 grid gap-3">
              {videos?.map((item, index) => {
                return (
                  <MediaComponent
                    onPress={() => {
                      setShowModalVideo(true);
                      setActiveIndex1(index);
                    }}
                    {...item}
                    remove={() => onRemove(item.fileId)}
                    key={item.fileId}
                    readOnly={readOnly}
                  />
                );
              })}
            </div>
          </div>
        )}
        <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
          <ModalViewMedia activeIndex={activeIndex} listMedia={images} />
        </MyModalV2>
        <MyModalV2
          open={showModalVideo}
          onClose={() => setShowModalVideo(false)}
        >
          <ModalViewMedia activeIndex={activeIndex1} listMedia={videos} />
        </MyModalV2>
      </div>
    )
  );
}
