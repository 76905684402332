import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native-web';
import Switch from '@components/switch';
import { productPosted, realEstatePosted } from '@components/leftMenu/configs';
import qs from 'query-string';
import RenderTopTable from '../components/renderTopTable';
import RenderTableProduct from '../components/renderTableProduct';
import { useGetRealEstatePosted } from '@utils/hooks/manageProduct/useGetRealEstatePosted';
import { useProductContext } from '../product/context';
import { useGetPremiumRealEstates } from '@utils/hooks/manageProduct/useGetPremiumRealEstates';
import { useGetLocation } from '../configs';
import isEmpty from 'lodash/isEmpty';
import ActionRealEstateProductModal from '../../../../modals/actionRealEstateProductModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useDeleteListRealEstates } from '@utils/hooks/manageProduct/useDeleteListRealEstates';
import { blankStateFilter } from '@utils/hooks/manageRealEstate/useStateFilterNeedApproval';
import queryString from 'query-string';

export default function ProductPosted() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { tab = productPosted } = qs.parse(search);
  const title = realEstatePosted.items[0].items?.find(
    e => e.key === tab
  )?.title;
  const { stateFilter, listSelected, setListSelected, setState } =
    useProductContext();

  const { isTabProductPremium } = useGetLocation();
  let {
    dataRealEstatePosted,
    onLoadByPagination,
    loading,
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  } = useGetRealEstatePosted({ state: stateFilter });
  const {
    dataPremium,
    onLoadByPagination: onLoadByPaginationPremium,
    loading: loadingPremium,
    totalItem: totalItemPremium,
    totalPage: totalPagePremium,
    currentPage: currentPagePremium,
    rowsPerPage: rowsPerPagePremium,
    currentCountFrom: currentCountFromPremium,
    currentCountTo: currentCountToPremium,
    hasPrevPage: hasPrevPagePremium,
    hasNextPage: hasNextPagePremium,
  } = useGetPremiumRealEstates({ state: stateFilter });

  const pageParam = parseInt(queryString.parse(search).page) || 1;
  const pagination = {
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
  const paginationPremium = {
    totalItem: totalItemPremium,
    totalPage: totalPagePremium,
    currentPage: currentPagePremium,
    rowsPerPage: rowsPerPagePremium,
    currentCountFrom: currentCountFromPremium,
    currentCountTo: currentCountToPremium,
    hasPrevPage: hasPrevPagePremium,
    hasNextPage: hasNextPagePremium,
  };
  const [showModalProductPosted, setShowModalProductPosted] = useState(false);
  const { onSubmit, loading: loadingDeleted } = useDeleteListRealEstates(
    listSelected,
    setShowModalProductPosted
  );
  useEffect(() => {
    onLoadByPagination(pageParam);
    return () => {
      onLoadByPagination = null;
    };
  }, [pageParam]);
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          marginTop: 4,
          backgroundColor: 'white',
          paddingHorizontal: 16,
          paddingTop: 20,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
      >
        <Switch
          value={tab}
          setValue={(_, key) => {
            navigate(`${pathname}?tab=${key}`);
            setListSelected.clear();
            setState(blankStateFilter);
          }}
          configs={realEstatePosted.items[0].items}
        />
        <div className="pt-2">
          <RenderTopTable
            title={title}
            total={isTabProductPremium ? totalItemPremium : totalItem}
            isVisibleActionAllButton={
              !isEmpty(listSelected) && listSelected?.length > 1
            }
            onShowModalDeletedPostedProduct={() =>
              setShowModalProductPosted(true)
            }
          />
        </div>
        <div className="w-full">
          <RenderTableProduct
            loading={isTabProductPremium ? loadingPremium : loading}
            data={isTabProductPremium ? dataPremium : dataRealEstatePosted}
            pagination={isTabProductPremium ? paginationPremium : pagination}
            onLoadByPagination={
              isTabProductPremium
                ? onLoadByPaginationPremium
                : onLoadByPagination
            }
          />
        </div>
        <MyModalV2
          open={showModalProductPosted}
          onClose={() => setShowModalProductPosted(false)}
        >
          <ActionRealEstateProductModal
            loading={loadingDeleted}
            onConfirm={onSubmit}
            header={'Xóa sản phẩm'}
            question={`Bạn có chắc muốn xóa sản phẩm`}
            onClose={() => setShowModalProductPosted(false)}
          />
        </MyModalV2>
      </View>
    </View>
  );
}
