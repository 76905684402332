import React from 'react';
import { alleyWidthConfigs } from '@utils/configs/configsLocal';
import InputSelector from '@components/inputSelector';

type Props = {
  alleyWidthKey?: string,
  alleyWidthFrom?: number,
  alleyWidthTo?: number,
  setalleyWidthKey?: Function,
  setalleyWidthFrom?: Function,
  setalleyWidthTo?: Function,
  onResetSelected?: Function,
  title?: string,
};
export default function InputAreaDropdown({
  alleyWidthKey,
  alleyWidthFrom,
  alleyWidthTo,
  setalleyWidthKey,
  setalleyWidthFrom,
  setalleyWidthTo,
  onResetSelected,
  title = 'Độ rộng hẻm (m)',
}: Props) {
  const convertArea =
    alleyWidthConfigs?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle={title}
      renderToValue={20000}
      isLong
      progress={2500.5}
      sliderTitle={title}
      stepRenderLine={800}
      key={Math.random() + alleyWidthFrom + alleyWidthTo}
      theValueIsShow={5000}
      listSelections={convertArea || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setalleyWidthKey(key);
        setalleyWidthFrom(from);
        setalleyWidthTo(to);
      }}
      keyValue={alleyWidthKey}
      from={alleyWidthFrom}
      to={alleyWidthTo}
      unit={'m²'}
      min={0}
      max={5000}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 101, value: 'Tất cả' }]}
    />
  );
}
