import styles from './updateProductStatusModal.module.scss';
import MyButton from '@components/button';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import { initialValues } from './configs';
import MyTextAreaInput from '@components/textAreaInput';
import MyInput from '@components/myInput';
import { useProductContext } from '../../scenes/home/manageProduct/product/context';
import { useChangeRealEstateProductStatus } from '@utils/hooks/manageProduct/useChangeRealEstateProductStatus';
import { useLeaveComment } from '@utils/hooks/comment/useLeaveComment';
import ListUser from './components/listUsers';
import { useGetEmployeesLazy } from '@utils/hooks/b2bUser/useGetB2BEmployees';
import { useDebounce, useList } from 'react-use';

type Props = {
  onClose?: Function,
  header?: string,
  question?: string,
  content?: string,
  loading?: boolean,
  data?: Object,
};

export default function UpdateProductModal({ onClose, loading }: Props) {
  const form = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const [search, setSearch] = useState('');
  const [debounce, setDebounce] = useState('');
  // const [excludedIds, { push: pushIds, removeAt: removeAtIds }] = useList([]);
  const [listSelectedUser, { push, removeAt }] = useList([]);
  useDebounce(
    () => {
      setDebounce(search);
    },
    500,
    [search]
  );
  const { users, loading: loadUsers } = useGetEmployeesLazy(
    debounce,
    listSelectedUser?.map(e => e.id)
  );
  const { handleSubmit, setValue } = form;
  const { productStatus, currentId, setShowChangeStatusModal } =
    useProductContext();
  const { onSubmit } = useChangeRealEstateProductStatus(
    currentId,
    setShowChangeStatusModal
  );
  const { onSubmit: onLeaveMessage } = useLeaveComment(
    currentId,
    setShowChangeStatusModal
  );
  const handleChangeStatus = useCallback(
    value => {
      const { depositWaitingTime, shareToUsers, content } = value;
      if (productStatus === 'sold') {
        onLeaveMessage(
          content?.trim().length ? content : 'Không có ghi chú, BĐS đã bán'
        );
      }
      onSubmit(productStatus, depositWaitingTime, shareToUsers);
    },
    [onSubmit, onLeaveMessage, productStatus]
  );
  const onSelect = useCallback(
    value => {
      const index = listSelectedUser.findIndex(p => p.id === value.id);
      if (index > -1) {
        removeAt(index);
      } else {
        push(value);
      }
      setValue(
        'shareToUsers',
        listSelectedUser?.map(e => e.id)
      );
    },
    [listSelectedUser]
  );
  return (
    <FormProvider {...form}>
      <div className={styles.confirmLockUnlockModal}>
        <div className="text-16 font-medium text-blue-main">
          Chuyển trạng thái
        </div>
        {productStatus === 'sold' && (
          <div
            style={{ boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)' }}
            className="w-full text-sm p-4 flex flex-col space-y-4 rounded"
          >
            <span>
              Bạn đang chuyển trạng thái sản phẩm thành{' '}
              <b>&quot;Đã bán&quot;</b>, vui lòng nhập ghi chú cho các người
              dùng khác trong hệ thống có thể biết được điều này
            </span>
            <Controller
              control={form.control}
              name="content"
              render={({ field, field: { onChange } }) => {
                return (
                  <MyTextAreaInput
                    {...field}
                    inputClassName="flex-1x bg-white rounded-lg"
                    placeholder="Nhập ghi chú của bạn"
                    onDelete={() => onChange('')}
                    rows={3}
                    maxlength={888}
                  />
                );
              }}
            />
          </div>
        )}

        {productStatus === 'waitingForDeposit' && (
          <div>
            <div
              style={{ boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)' }}
              className="w-full mt-3 text-sm p-4 flex flex-col space-y-4 rounded shadow"
            >
              <span className="font-bold">Thời gian giữ trạng thái</span>
              <span>
                Thời gian giữ trạng thái <b>&quot;Đang chờ đặt cọc&quot;</b> tối
                đa là 120 giờ, sau khoảng thời gian này sản phẩm sẽ tự động
                chuyển lại thành trạng thái <b>&quot;Đang bán&quot;</b>.
              </span>
              <Controller
                control={form.control}
                name="depositWaitingTime"
                rules={{
                  required: 'Vui lòng nhập thời gian chờ đặt cọc',
                  validate: value =>
                    parseInt(value) > 120
                      ? 'Vui lòng nhập thời gian giữ nhỏ hơn 120'
                      : true,
                }}
                render={({
                  field,
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <div className="flex flex-1 space-x-4 items-center">
                      <MyInput
                        {...field}
                        inputClassName="flex-1 bg-white rounded-lg"
                        invalid={invalid}
                        errorMessage={error?.message}
                        placeholder="Nhập thời gian giữ trạng thái, tối đa là 120 giờ"
                        onDelete={() => onChange('')}
                        hideMaxLength
                        type="number"
                      />
                      <span className="w-35px text-sm">Giờ</span>
                    </div>
                  );
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: '#EDF4FF',
                boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
              }}
              className="w-full mt-3 text-sm p-4 flex flex-col space-y-4 rounded"
            >
              <span className="font-bold">Thêm người xem</span>
              <span>
                Sau khi chuyển trạng thái sản phẩm sang{' '}
                <b>&quot;Đang chờ đặt cọc&quot;</b>, những người dùng khác sẽ
                không nhìn thấy sản phẩm này trừ bạn và ban quản trị hệ thống.
              </span>
              <span className="text-xs text-gray-400">
                Bạn có thể chỉ định thêm người dùng có thể nhìn thấy sản phẩm
                này, bằng cách nhập thông tin phía dưới
              </span>
              <Controller
                control={form.control}
                name="shareToUsers"
                render={({ field, fieldState: { invalid, error } }) => {
                  return (
                    <div>
                      <MyInput
                        {...field}
                        inputClassName="flex-1 bg-white rounded-lg"
                        invalid={invalid}
                        errorMessage={error?.message}
                        placeholder="Nhập tên, email, số điện thoại hoặc mã của người dùng"
                        onDelete={() => setSearch('')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                      />
                      <ListUser
                        handleData={onSelect}
                        show={users?.length > 0 || loadUsers}
                        data={loadUsers ? [1, 2, 3] : users}
                        loading={loadUsers}
                      />
                    </div>
                  );
                }}
              />
              <ListUser
                showDelBtn={true}
                data={listSelectedUser}
                show={listSelectedUser?.length > 0}
                handleData={onSelect}
              />
            </div>
          </div>
        )}
        <div className="flex w-full justify-end space-x-2 mt-6 border-t border-gray-300 pt-3">
          <div className="w-140px">
            <MyButton label="Trở về" type="gray" onPress={onClose} />
          </div>
          <div className="w-140px">
            <MyButton
              disabled={loading}
              type="blue"
              label={loading ? 'Đang xử lý' : 'Xác nhận'}
              wrapperClass="w-140px"
              onPress={handleSubmit(handleChangeStatus)}
            />
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
