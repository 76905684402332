import React from 'react';
import styles from '../customToolMap.module.scss';
import MyButton from '@components/button';
type Props = {
  onPress?: Function,
  wrapperStyle?: string,
  show?: boolean,
};

type ContentProps = {
  onPress?: Function,
};
function Content({ onPress }: ContentProps) {
  return (
    <div>
      <p className="text-sm font-bold text-18 text-blue-main">
        Cần tìm vị trí hiện tại của bạn?
      </p>
      <div className="w-full flex flex-col space-y-2">
        <p className="text-black-main text-sm mt-2">
          Bạn cần cho phép <b className="text-sm">RealTech247</b> biết vị trí
          của bạn để dùng chức năng này
        </p>
        <p className="text-black-main text-sm">
          <b className="text-sm">Hướng dẫn</b> (chỉ dành cho trình duyệt web
          Chrome):
        </p>
        <p className="text-black-main mt-1 text-sm flex flex-row">
          1.Chọn biểu tượng{' '}
          <img
            alt="notLocation"
            src="/svg/notLocation.svg"
            className="w-20px h-20px bg-no-repeat bg-cover bg-center"
          />{' '}
          trên thanh địa chỉ web.
        </p>
        <p className="text-black-main text-sm">
          2. Nhấn vào tuỳ chọn “
          <b className="text-sm">
            Luôn luôn cho phép trang web {window.origin} được quyền truy cập ví
            trí của bạn
          </b>
          ”.
        </p>
        <p className="text-black-main text-sm">
          3. Nhấn vào nút “<b className="text-sm">Hoàn thành</b>” để xong tác
          vụ.
        </p>
        <p className="text-sm">
          4. Sau đó, bạn vui lòng tải lại trang web để có thể dùng chức năng
          này.
        </p>
      </div>
      <div className="w-full flex flex-col items-end mt-6">
        <div className="w-120px">
          <MyButton size="large" label="Đóng" onPress={onPress} />
        </div>
      </div>
    </div>
  );
}

export default function Notification(props: Props) {
  const { onPress, wrapperStyle } = props;
  return (
    <div
      className={`${styles.wrapper} absolute w-340px ${wrapperStyle} p-4`}
      style={{ bottom: 100, right: 70, zIndex: 1000 }}
    >
      <Content onPress={onPress} />
    </div>
  );
}
