import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useManageUserB2BContext } from 'scenes/home/manageFeedback/feedback/context';
import { DELETE_FEEDBACK } from '@utils/graphql/mutations/map';

export const useDeleteFeedback = setApprove => {
  const { listSelected, setListSelected } = useManageUserB2BContext();
  const [onDelete, { loading, error, client }] = useMutation(DELETE_FEEDBACK);
  const toast = useToast();
  const onSubmit = useCallback(async () => {
    try {
      await onDelete({
        variables: {
          feedbackId: listSelected[0],
        },
      });
      await resetQueries(['getListFeedbackValuationStreet'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Xóa góp ý thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setApprove(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="error"
            message={e?.message}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [onDelete, client, setListSelected]);
  return { onSubmit, loading, error };
};
