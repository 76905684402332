import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { CREATE_B2B_ADMIN_ACCOUNT } from '@utils/graphql/mutations/b2bAdminUsers';
import { pickBy } from 'lodash';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MyToast from '@components/MyToast';
export const useCreateB2BAdminUser = () => {
  const [createAdmin, { loading, error, client }] = useMutation(
    CREATE_B2B_ADMIN_ACCOUNT
  );
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async state => {
      try {
        await createAdmin({
          variables: pickBy(
            {
              ...state,
              avatar: state?.avatar?.fileId,
              logo: state?.logo?.fileId,
              idImages: state?.idImages?.map(e => e.fileId),
              attachments: state?.attachments?.map(e => e.fileId),
              dateOfBirth: state?.dateOfBirth
                ? moment.unix(state?.dateOfBirth).format('YYYY-MM-DD')
                : null,
            },
            e => e || e === false || e === null
          ),
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Tạo người dùng B2B thành công!"
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['boGetListB2BAdmin'], client.cache);
        await navigate('/manageUser/b2b');
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [createAdmin, client, navigate]
  );
  return { onSubmit, loading, error };
};
