import React from 'react';
import styles from '@components/table/table.module.scss';

type Props = {
  type?: string,
  headerTableConfigs?: Array,
  isPersonal?: boolean,
};

export default function HeaderTableEditUserHistory(props: Props) {
  const { headerTableConfigs, isPersonal } = props;
  return (
    <div className="flex flex-col">
      <div
        className="w-full h-40px flex"
        style={{
          backgroundColor: '#EDF4FF',
        }}
      >
        <div
          className="flex items-center justify-center border-r border-gray-300"
          style={{ width: isPersonal ? 1084 : 1240 }}
        >
          <div className="text-16 font-medium text-black-main">
            Thông tin khách hàng
          </div>
        </div>
        <div
          className="flex items-center justify-center"
          style={{ width: 2115 }}
        >
          <div className="text-16 font-medium text-black-main">
            Thông tin người quản trị
          </div>
        </div>
      </div>
      <div
        className="w-full h-40px flex border-t border-gray-main"
        style={{
          backgroundColor: '#EDF4FF',
        }}
      >
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        >
          <div
            className={`text-13 font-medium text-blue-main ${styles.textHead}`}
          >
            STT
          </div>
        </div>
        {headerTableConfigs.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles.headContainer} ${item?.className} items-center justify-center`}
            >
              <div
                className={`text-14 font-medium text-blue-main ${styles.textHead}`}
              >
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
