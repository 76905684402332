import { useQuery } from '@apollo/client';
import { LIST_EMPLOYEES } from '@utils/graphql';

export const useGetEmployees = () => {
  const { data, loading } = useQuery(LIST_EMPLOYEES, {
    variables: {
      data: { isLocked: false },
    },
  });
  const finalData = data?.b2bGetEmployees;
  return { data: finalData, loading: loading };
};

export const useGetEmployeesLazy = (searchString, excludedIds = []) => {
  const { data, loading } = useQuery(LIST_EMPLOYEES, {
    variables: {
      data: { isLocked: false },
      searchString,
      excludedIds,
    },
    skip: !searchString,
    fetchPolicy: 'no-cache',
  });
  return {
    data,
    loading,
    users: data?.b2bGetEmployees?.payload?.items,
  };
};
