import { Controller, useFormContext } from 'react-hook-form';
import PasswordInput from '@components/passwordInput';
import MyButton from '@components/button';
import React from 'react';
import Joi from 'joi';
import { regexValidationPassword } from '../../../utils/configs/regex';
import { useChangePassword } from '../../../utils/hooks/auth/useChangePassword';
type Props = {
  onClose?: Function,
  setShowModalSuccess?: Function,
};

export const changePasswordSchema = Joi.object({
  oldPassword: Joi.string()
    .required()
    .error(errors => {
      errors.forEach(err => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mật khẩu cũ';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  newPassword: Joi.string()
    .min(6)
    .max(32)
    .required()
    .regex(regexValidationPassword)
    .error(errors => {
      errors.forEach(err => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập mật khẩu mới';
            break;
          case 'string.min':
            err.message = 'Mật khẩu từ 6-32 ký tự';
            break;
          case 'string.max':
            err.message = 'Mật khẩu từ 6-32 ký tự';
            break;
          case 'string.pattern.base':
            err.message = 'Mật khẩu không hợp lệ';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  confirmNewPassword: Joi.string()
    .when('newPassword', {
      is: '',
      then: Joi.string().min(6).max(32).required(),
      otherwise: Joi.valid(Joi.ref('newPassword')),
    })
    .error(errors => {
      errors.forEach(err => {
        switch (err.code) {
          case 'string.empty':
            err.message = 'Vui lòng nhập lại mật khẩu mới';
            break;
          case 'any.only':
            err.message =
              'Bạn nhập lại mật khẩu mới không đúng. Vui lòng nhập lại';
            break;
          case 'string.min':
            err.message = 'Mật khẩu từ 6-32 ký tự';
            break;
          case 'string.max':
            err.message = 'Mật khẩu từ 6-32 ký tự';
            break;
          default:
            break;
        }
      });
      return errors;
    }),
});

export default function Content({ onClose, setShowModalSuccess }: Props) {
  const { control, handleSubmit, watch } = useFormContext();
  const { onChangePassword, loading, error } = useChangePassword(
    onClose,
    setShowModalSuccess
  );
  return (
    <div className="flex flex-col w-full mt-4 space-y-4">
      {error && <div className="text-11 text-red-500">{error?.message}</div>}
      <div className="text-13 ">
        Nhập mật khẩu mới có tối thiểu 6 và tối đa 32 ký tự bao gồm chữ cái in
        hoa hoặc in thường.
      </div>
      <div className="flex flex-col space-y-6">
        <Controller
          rules={{
            required: 'Vui lòng nhập mật khẩu cũ',
          }}
          control={control}
          name="oldPassword"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid },
            formState: { errors },
          }) => {
            return (
              <PasswordInput
                {...field}
                type="password"
                invalid={invalid}
                errors={errors}
                inputClassName="w-full"
                isRequired
                label="Nhập mật khẩu cũ"
                placeholder="Nhập mật khẩu cũ"
                onDelete={() => onChange('')}
                maxLength={32}
                hideMaxLength
              />
            );
          }}
        />
        <Controller
          rules={{
            required: 'Vui lòng nhập mật khẩu mới',
          }}
          control={control}
          name="newPassword"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid },
            formState: { errors },
          }) => {
            return (
              <PasswordInput
                {...field}
                type="password"
                invalid={invalid}
                errors={errors}
                inputClassName="w-full"
                isRequired
                label="Nhập Mật khẩu mới"
                placeholder="Mật khẩu mới từ 6 đến 32 ký tự"
                onDelete={() => onChange('')}
                maxLength={32}
                hideMaxLength
              />
            );
          }}
        />
        <Controller
          rules={{
            required: 'Vui lòng nhập lại mật khẩu mới',
            validate: {
              checkMatchPassword: value =>
                value === watch()?.password ||
                'Bạn nhập lại mật khẩu không đúng. Vui lòng nhập lại',
            },
          }}
          control={control}
          name="confirmNewPassword"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid },
            formState: { errors },
          }) => {
            return (
              <PasswordInput
                {...field}
                type="password"
                invalid={invalid}
                errors={errors}
                inputClassName="w-full"
                isRequired
                label="Nhập lại mật khẩu mới"
                placeholder="Nhập lại mật khẩu mới"
                onDelete={() => onChange('')}
                maxLength={32}
                hideMaxLength
              />
            );
          }}
        />
        <MyButton
          disabled={loading}
          type="blue"
          label={loading ? 'Đang xử lý' : 'Xác nhận'}
          wrapperClass="w-full"
          onPress={handleSubmit(onChangePassword)}
        />
      </div>
    </div>
  );
}
