import MyInput from '@components/myInput';
import Permission from '@gugotech/tncc-users-permissions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
import { nonAccentVietnamese } from '@utils/helper';
import {
  every,
  filter,
  forEach,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
} from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  isUpgrade: Boolean,
};

export default function PermissionManagement({ isUpgrade }: Props) {
  const allPermissions = Permission.getPermissionTree('all');
  const [permissionList, setPermissionList] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [search, setSearch] = useState([]);
  const form = useFormContext();
  const { control, setValue } = form;
  const modulesWatch = useWatch({ control, name: 'modules' });
  const permissionsWatch = useWatch({ control, name: 'permissions' });
  const selectedPermissionIds = map(
    permissionsWatch,
    permission => permission.id
  );

  const isLeaf = node => {
    return isEmpty(node?.children) || isNil(node?.children);
  };
  const findAllLeafNodes = permission => {
    let leafNodes = [];
    const traverse = node => {
      if (isLeaf(node)) {
        leafNodes.push(node);
        return;
      }
      forEach(node?.children, child => traverse(child));
    };
    traverse(permission);
    return leafNodes;
  };
  const handleSearch = event => {
    setSearch(event.target.value);
  };
  const handleClearSearch = () => {
    setSearch('');
  };
  const getPermissionByModuleName = module => {
    switch (module) {
      case 'PROJECT':
        return 'manage-project';
      case 'REALESTATE':
        return 'manage-products';
      case 'CRM':
        return 'manage-crm';
      case 'PROJECT_FOR_LEASE':
        return 'manage-for-lease-project';
      default:
        return -1;
    }
  };
  const getPermissionsByIds = ids => {
    let permissions = [];
    const permissionsNodes = filter(allPermissions, permission =>
      includes(ids, permission.id)
    );
    map(
      permissionsNodes,
      permissionNode =>
        (permissions = [...permissions, ...findAllLeafNodes(permissionNode)])
    );
    return permissions;
  };
  const handleSelectAllPermissions = isCheckedAll => {
    if (isCheckedAll) {
      setValue('permissions', []);
    } else {
      const permissions = findAllLeafNodes({ children: permissionList });
      setValue('permissions', permissions);
    }
  };
  const handleOnClickParentNode = selectedPermission => {
    const allSelectedPermission = findAllLeafNodes(selectedPermission);
    const isCheckAll = every(allSelectedPermission, permissionItem =>
      includes(selectedPermissionIds, permissionItem?.id)
    );
    if (isCheckAll) {
      setValue(
        'permissions',
        filter(
          permissionsWatch,
          permission =>
            !includes(
              map(
                allSelectedPermission,
                allSelectedPermissionItem => allSelectedPermissionItem?.id
              ),
              permission?.id
            )
        )
      );
    } else {
      setValue('permissions', [...permissionsWatch, ...allSelectedPermission]);
    }
  };
  const handleOnClickLeafNode = selectedPermission => {
    includes(selectedPermissionIds, selectedPermission?.id)
      ? setValue(
          'permissions',
          filter(
            permissionsWatch,
            permission => !isEqual(permission?.id, selectedPermission?.id)
          )
        )
      : setValue('permissions', [...permissionsWatch, selectedPermission]);
  };

  const renderPermissionTree = (permissions, prefix) =>
    map(permissions, (permission, permissionIndex) => {
      const allLeafNodes = findAllLeafNodes(permission);
      const filteredPermission = filter(allLeafNodes, allLeafNode =>
        includes(
          nonAccentVietnamese(allLeafNode?.name),
          nonAccentVietnamese(search)
        )
      );
      const isCheckAllNode = every(allLeafNodes, permissionItem =>
        includes(selectedPermissionIds, permissionItem?.id)
      );
      const isNoCheckNode = every(
        allLeafNodes,
        permissionItem => !includes(selectedPermissionIds, permissionItem?.id)
      );
      return !isLeaf(permission) ? (
        <Accordion
          key={permissionIndex}
          sx={{
            display: isEqual(size(filteredPermission), 0) ? 'none' : 'block',
            marginBottom: '16px',
            '& .MuiAccordionSummary-root': {
              minHeight: '36px !important',
              maxHeight: 36,
              backgroundColor: '#EDF4FF',
              overflow: 'hidden',
            },
            '& .MuiAccordionDetails-root': {
              marginTop: '8px',
              padding: 0,
            },
            '&.MuiAccordion-root:before': {
              display: 'none',
            },
          }}
        >
          <div className="flex items-center space-x-2">
            <AccordionSummary
              expandIcon={<img alt="icon" src="/svg/arrowBlack.svg" />}
              sx={{ flex: 1, borderRadius: '6px' }}
            >
              <span className="text-14 truncate" style={{ lineHeight: 18 }}>
                {`${prefix ? `${prefix}.` : ''}${permissionIndex + 1}. ${
                  permission?.name ?? ''
                } (${size(allLeafNodes)} quyền)`}
              </span>
            </AccordionSummary>
            <button
              className="select-none"
              onClick={() => handleOnClickParentNode(permission)}
            >
              <img
                src={
                  isNoCheckNode
                    ? '/svg/empty-check-table-icon.svg'
                    : isCheckAllNode
                    ? '/svg/checked-table-icon.svg'
                    : '/svg/ic_partial_check.svg'
                }
                alt="icon"
                className="w-6 h-6"
              />
            </button>
          </div>
          <AccordionDetails>
            <div className="pl-4 mt-4">
              {renderPermissionTree(
                permission?.children,
                prefix
                  ? `${prefix}.${permissionIndex + 1}`
                  : permissionIndex + 1
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : includes(
          nonAccentVietnamese(permission?.name),
          nonAccentVietnamese(search)
        ) ? (
        <li
          key={permissionIndex}
          className="flex h-52px items-center -mt-2"
          style={{
            borderLeft: '1px solid #dedede',
          }}
        >
          <div className="h-1px w-17px" style={{ background: '#dedede' }} />
          <div className="ml-10px px-4 flex-1 min-w-0">
            <div className="overflow-hidden">
              <Tooltip title={permission?.tips} arrow>
                <span className="text-14 truncate w-full">
                  {`${prefix}.${permissionIndex + 1} ${permission?.name ?? ''}`}
                </span>
              </Tooltip>
            </div>
          </div>
          <button
            className="select-none"
            onClick={() => handleOnClickLeafNode(permission)}
          >
            <img
              src={
                includes(selectedPermissionIds, permission?.id)
                  ? '/svg/checked-table-icon.svg'
                  : '/svg/empty-check-table-icon.svg'
              }
              alt="icon"
              className="w-6 h-6"
            />
          </button>
        </li>
      ) : null;
    });

  useEffect(() => {
    if (isFirstRender && isUpgrade) {
      setIsFirstRender(false);
      return;
    }
    const defaultNodePermissionIds = [
      'manage-employee',
      'manage-department',
      'manage-role',
    ];
    const permissionNodeIds = [...defaultNodePermissionIds];
    map(modulesWatch, module => {
      const permissionId = getPermissionByModuleName(module);
      if (permissionId) permissionNodeIds.push(permissionId);
    });
    setPermissionList(
      filter(allPermissions, permission =>
        includes(permissionNodeIds, permission?.id)
      )
    );
  }, [modulesWatch]);
  useEffect(() => {
    const permissions = findAllLeafNodes({ children: permissionList });
    setValue(
      'permissions',
      filter(permissionsWatch, permission =>
        includes(
          map(permissions, leafNode => leafNode?.id),
          permission?.id
        )
      )
    );
  }, [permissionList]);

  const isCheckedAllPermissions = isEqual(
    size(selectedPermissionIds),
    size(
      getPermissionsByIds(
        map(permissionList, permissionNode => permissionNode?.id)
      )
    )
  );
  const isNoCheckPermissions = isEqual(size(selectedPermissionIds), 0);

  return (
    <div className="w-full bg-white rounded-lg flex flex-col p-4 space-y-7">
      <div className="text-16 font-medium">Phân quyền</div>
      <MyInput
        value={search}
        placeholder="Nhập để tìm kiếm quyền..."
        onChange={handleSearch}
        onDelete={handleClearSearch}
        renderRightComponent={() => {
          return (
            <div className="w-6 h-6 flex items-center justify-center border-l border-gray-300">
              <img
                alt="search-icon"
                src="/svg/search-icon.svg"
                className="ml-2"
              />
            </div>
          );
        }}
      />
      <div className="flex flex-col space-y-4">
        <div className="flex justify-end space-x-2">
          <span className="text-14">Chọn tất cả</span>
          <button
            className="select-none"
            onClick={() => handleSelectAllPermissions(isCheckedAllPermissions)}
          >
            <img
              src={
                isNoCheckPermissions
                  ? '/svg/empty-check-table-icon.svg'
                  : isCheckedAllPermissions
                  ? '/svg/checked-table-icon.svg'
                  : '/svg/ic_partial_check.svg'
              }
              alt="icon"
              className="w-6 h-6"
            />
          </button>
        </div>
        <div>{renderPermissionTree(permissionList)}</div>
      </div>
    </div>
  );
}
