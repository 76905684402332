import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { includes } from 'lodash';
export function RoutersConfigs() {
  const { permissions, modules } = useGetMe();
  return [
    {
      id: 0,
      title: 'Tất cả ',
    },
    {
      id: 1,
      title: 'Quản lý bất động sản',
      path: '/manageProduct/product',
      icon: '/icons/modules/product-management.svg',
      isShow:
        includes(modules, 'REALESTATE') &&
        permissions?.some(p =>
          [
            PermissionIds.ManageProducts,
            PermissionIds.ViewProductList,
            PermissionIds.ViewProductGroup,
          ].includes(p.id)
        ),
    },
    {
      id: 2,
      title: 'Quản lý dự án',
      path: process.env.REACT_APP_MANAGEMENT_PROJECT_URL,
      icon: '/icons/modules/project-management.svg',
      isShow: includes(modules, 'PROJECT'),
    },
    {
      id: 3,
      path: process.env.REACT_APP_LEASE_PROJECT_MANAGEMENT_URL,
      title: 'Quản lý dự án thuê',
      icon: '/icons/modules/lease-project-management.svg',
      isShow: includes(modules, 'PROJECT_FOR_LEASE'),
    },
    {
      id: 4,
      path: process.env.REACT_APP_ORGANIZATION_MANAGEMENT_URL,
      title: 'Quản lý bộ máy tổ chức',
      icon: '/icons/modules/organization-management.svg',
      isShow: includes(modules, 'ORGANIZATION'),
    },
    {
      id: 6,
      path: process.env.REACT_APP_EMPLOYEE_MANAGEMENT_URL,
      title: 'Quản lý nhân viên',
      icon: '/icons/modules/employee-management.svg',
      isShow: includes(modules, 'EMPLOYEE'),
    },
    {
      id: 7,
      path: process.env.REACT_APP_CRM_URL,
      title: 'Quản lý CRM',
      icon: '/icons/modules/crm-management.svg',
      isShow: includes(modules, 'CRM'),
    },
    {
      id: 8,
      path: process.env.REACT_APP_MANAGEMENT_CALLCENTER_URL,
      title: 'Tổng đài thông minh',
      icon: '/icons/modules/call-center-management.svg',
      isShow: includes(modules, 'CALLCENTER'),
    },
    {
      id: 9,
      path: process.env.REACT_APP_UPGRADE_ACCOUNT_MANAGEMENT_URL,
      title: 'Quản lý nâng cấp tài khoản',
      icon: '/icons/modules/upgrade-account-management.svg',
      isShow: includes(modules, 'UPGRADE_ACCOUNT'),
    },
    {
      id: 9,
      path: process.env.REACT_APP_BANK_MANAGEMENT_URL,
      title: 'Quản lý vay vốn ngân hàng',
      icon: '/icons/modules/bank-loan-management.svg',
      isShow: includes(modules, 'LOAN'),
    },
    {
      id: 10,
      path: process.env.REACT_APP_NOTARIZATION_MANAGEMENT_URL,
      title: 'Quản lý hồ sơ công chứng',
      icon: '/icons/modules/notarization-request-management.svg',
      isShow: includes(modules, 'NOTARIZATION'),
    },
    {
      id: 11,
      path: process.env.REACT_APP_FLUCTUATION_MANAGEMENT_URL,
      title: 'Quản lý đăng ký biến động',
      icon: '/icons/modules/register-request-management.svg',
      isShow: includes(modules, 'REGISTER'),
    },
    {
      id: 12,
      path: process.env.REACT_APP_ATMNHA_PUBLISHED_POST_MANAGEMENT_URL,
      title: 'Quản lý tin đăng lên ATM Nhà',
      icon: '/icons/modules/post-to-atmnha-management.svg',
      isShow: includes(modules, 'B2B_PUBLIC_REALESTATE'),
    },
    {
      id: 13,
      path: process.env.REACT_APP_FORECLOSURE_MANAGEMENT_URL,
      title: 'Quản lý yêu cầu mua tài sản phát mãi',
      icon: '/icons/modules/foreclosure-request-management.svg',
      isShow: includes(modules, 'FORECLOSURE_REQUEST'),
    },
    {
      id: 14,
      path: process.env.REACT_APP_MEMBER_SOCIAL_MANAGEMENT_URL,
      title: 'Quản lý hội viên',
      icon: '/icons/modules/member-social-management.svg',
      isShow: includes(modules, 'MANAGE_MEMBERSHIP'),
    },
  ];
}
