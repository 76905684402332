import React from 'react';
import FromToSlider from '@components/fromToSlider';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';

type Props = {
  constructionAreaKey?: string,
  constructionAreaFrom?: number,
  constructionAreaTo?: number,
  setConstructionAreaKey?: Function,
  setConstructionAreaFrom?: Function,
  setConstructionAreaTo?: Function,
  onResetSelected?: Function,
};
export default function ConstructionAreaDropdown({
  constructionAreaKey,
  constructionAreaFrom,
  constructionAreaTo,
  setConstructionAreaKey,
  setConstructionAreaFrom,
  setConstructionAreaTo,
  onResetSelected,
}: Props) {
  const { constructionArea } = configs;
  const convertConstructionArea =
    constructionArea?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <FromToSlider
      dropdownButtonTitle="Diện tích sàn (m²)"
      stepRenderLine={50}
      renderToValue={500}
      progress={250.5}
      sliderTitle={'Diện tích sàn (m²)'}
      key={Math.random() + constructionAreaFrom + constructionAreaTo}
      theValueIsShow={500}
      listSelections={convertConstructionArea}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setConstructionAreaKey(key);
        setConstructionAreaFrom(from);
        setConstructionAreaTo(to);
      }}
      keyValue={constructionAreaKey}
      from={constructionAreaFrom}
      to={constructionAreaTo}
      unit={'m²'}
      min={0}
      max={500}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 501, value: 'Tất cả' }]}
    />
  );
}
