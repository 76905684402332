import styles from '@components/myInput/input.module.scss';
import React from 'react';

type LabelProps = {
  label?: string,
  isRequired?: boolean,
  isRequiredInline?: boolean,
  wrapperClass?: string,
  labelStyle?: Object,
};
export default function Label({
  label,
  isRequired,
  isRequiredInline = false,
  wrapperClass,
  labelStyle,
}: LabelProps) {
  return (
    <div className={`flex flex-row items-center space-x-1 ${wrapperClass}`}>
      <div style={labelStyle} className={styles.label}>
        {label}
        {isRequired && isRequiredInline && (
          <span className="text-base font-medium text-red-500 ml-1">*</span>
        )}
      </div>
      {isRequired && !isRequiredInline && (
        <div className="tex-sm font-medium text-red-500">*</div>
      )}
    </div>
  );
}
