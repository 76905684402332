import { useProductContext } from '../../../scenes/home/manageProduct/product/context';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATE_COMMENTS } from '@utils/graphql';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';

export default function useGetInternalRealEstateComments(newId) {
  const { listSelected } = useProductContext();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, fetchMore, loading } = useQuery(
      GET_INTERNAL_REAL_ESTATE_COMMENTS,
      {
        variables: {
          realEstateId: listSelected?.[0] || newId,
          limit: 10,
        },
        skip: !listSelected,
      }
    ),
    listComment = data?.getInternalRealEstateComments?.items,
    total = data?.getInternalRealEstateComments?.total,
    totalComments = data?.getInternalRealEstateComments?.totalComments,
    page = data?.getInternalRealEstateComments?.page,
    hasNextPage = data?.getInternalRealEstateComments?.hasNextPage;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    hasNextPage,
    currentPage: page,
    fetchMore,
  });
  return {
    listComment,
    loading,
    total,
    onLoadMore,
    hasNextPage,
    totalComments,
  };
}
