import styles from '@components/table/table.module.scss';
import React from 'react';

type Props = {
  type?: string,
  headerTableConfigs?: Array,
};

export default function HeaderTableRealEstateEditHistory(props: Props) {
  const { headerTableConfigs = [] } = props;
  return (
    <div
      className="w-full h-40px flex border-b border-gray-300"
      style={{
        borderRadius: '9px 9px 0 0',
        backgroundColor: '#EDF4FF',
      }}
    >
      <div
        className={`${styles.headContainer} w-60px items-center justify-center`}
      >
        <div
          className={`text-13 font-medium text-blue-main ${styles.textHead}`}
        >
          STT
        </div>
      </div>
      {headerTableConfigs.map((item, index) => {
        return (
          <div
            key={index}
            className={`${styles.headContainer} ${item?.className} items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              {item.title}
            </div>
          </div>
        );
      })}
    </div>
  );
}
