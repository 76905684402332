import { useSetState } from 'react-use';
import { useCallback } from 'react';

export const blankStateFilter = {
  employeeCode: '',
  fullName: '',
  phone: '',
  province: '',
  provinceName: '',
  district: '',
  districtName: '',
  ward: '',
  wardName: '',
  street: '',
  streetName: '',
  houseNumber: '',
  createdAtFrom: '',
  createdAtTo: '',
  email: '',
  userType: ['yes', 'no'],
  username: '',
  departmentIds: [],
};
export const useStateFilterManageUserB2B = () => {
  const [state, setState] = useSetState({
    ...blankStateFilter,
  });
  const setStateAt = useCallback(
    field => {
      return value => {
        setState({ [field]: value });
      };
    },
    [setState]
  );
  return { state, setStateAt, setState };
};
