import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { UPDATE_REAL_ESTATES } from '@utils/graphql/mutations/realEstateB2c';
import pickBy from 'lodash/pickBy';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';
export const useRenewRealEstate = (realEstateId, onClose) => {
  const [updateRealEstate, { loading, error, client }] =
    useMutation(UPDATE_REAL_ESTATES);
  const toast = useToast();
  const { setListSelected } = useProductContext();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      captchaCode,
      captchaId,
      source,
      postType,
      endShowTime,
      startShowTime,
      isActive,
      setCaptchaError
    ) => {
      try {
        await updateRealEstate({
          variables: {
            captchaCode,
            captchaId,
            editOnly: isActive ? true : false,
            realEstateId,
            data: pickBy(
              {
                source,
                postType: parseInt(postType),
                endShowTime: moment.unix(endShowTime).format('YYYY-MM-DD'),
                startShowTime: moment.unix(startShowTime).format('YYYY-MM-DD'),
                country: 'vietnam',
              },
              e => e || e === false
            ),
          },
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              message={'Gia hạn tin thành công.'}
              closeButtonPos="center"
            />
          ),
        });
        setListSelected.clear();
        onClose();
        await resetQueries(['getRealEstates'], client.cache);
      } catch (e) {
        await setCaptchaError(e?.message);
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e?.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [updateRealEstate, client, navigate]
  );
  return { onSubmit, loading, error };
};
