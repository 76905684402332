import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { UPDATE_REAL_ESTATES } from '@utils/graphql/mutations/realEstateB2c';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { cleanObj, resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import _, { omit } from 'lodash';
import { omitFields } from 'constant';
import { UPDATE_PREMIUM_REAL_ESTATES } from '@utils/graphql';

export const updateRealEstateVariables = state => {
  const imageUrls = state?.mediaUrls?.map(e => e?.fileId),
    attachments = state?.attachments?.map(e => e?.fileId),
    location =
      state?.long && state?.lat
        ? [parseFloat(state?.long), parseFloat(state?.lat)]
        : '';
  const data = {
    postTitle: state?.postTitle,
    postContent: state?.postContent,
    type: state?.typeRealEstate,
    province: state.province,
    district: state.district,
    // typeOfDemand: state.isForSell,
    isForSell: state.isForSell === 'forSale' ? true : false,
    ward: state.ward,
    street: state.street,
    houseNumber: state.houseNumber,
    // status: state.status,
    // commission: state.commission,
    hasAlley: state.hasAlley,
    alleyTurnCount: state.hasAlley ? parseInt(state.alleyTurnCount, 10) : null,
    alleyWidth: state.hasAlley ? parseInt(state.alleyWidth, 10) : null,
    frontHomeAlleyWidth: state.hasAlley ? parseInt(state.alleyWidth, 10) : null,
    isNewConstruction: state.isNewConstruction,
    width: parseFloat(state.width),
    length: parseFloat(state.length),
    rear: parseFloat(state.rear),
    area: state?.rear
      ? parseFloat(
          (
            ((parseFloat(state?.width) + parseFloat(state?.rear)) / 2) *
            parseFloat(state?.length)
          )?.toFixed(2)
        )
      : parseFloat(
          (parseFloat(state.width) * parseFloat(state.length))?.toFixed(2)
        ),
    floorCount: !state.floorCount ? 0 : parseInt(state.floorCount, 10), // Số tầng
    bedroomCount: parseInt(state.bedroomCount, 10),
    // roomCount: parseInt(state.roomCount, 10), // Số lượng phòng
    hotelStar: parseInt(state.hotelStar, 10),
    // hasSeaView: state.hasSeaView,
    price: parseFloat(state.price),
    toiletCount: parseInt(state.toiletCount, 10),
    direction: state.direction,
    project: state.project,
    privateNote: state.note,
    legals: state.legal,
    mediaUrls: imageUrls,
    villaView: state.villaView,
    buildingType: state.buildingType,
    warehouseType: state.warehouseType || '', // Chức năng nhà xưởng
    // warehouseRoomCount: parseInt(state.warehouseRoomCount, 10), // Số phòng kho
    block: state?.block, // Thuộc block nào
    // roomNumber: state.roomNumber?.toString(), // Số thứ tự phòng
    onFloor: parseInt(state.onFloor, 10), // Vị trí tầng
    // bedRoomArea: parseInt(state.bedRoomArea, 10), // Diện tích phòng ngủ
    // shophouseField: state?.shophouseField, // Thương mại ngành
    hasMezzanine: state.hasMezzanine, // Có gác hay không
    contactName: state.contactName,
    contactPhones:
      state.contactPhones?.length > 0
        ? state.contactPhones?.map(e => e?.phone)
        : '',
    contactEmail: state.contactEmail,
    // career: state.career,
    fixedUtilities: state.fixedUtilities,
    utilities: state.utilities,
    localUtilities: state.localUtilities,
    equipmentsAndServices: state.equipmentsAndServices,
    attachments, // Tệp đính kèm
    location: location ? { coordinates: location } : {},
    map: location ? { coordinates: location } : {},
    constructionArea: parseFloat(state?.constructionArea),
  };
  return omit(cleanObj(data), omitFields[data.type]);
};

export const useEditProductPosted = (realEstateId, setShowModal, isPremium) => {
  const [updateRealEstate, { loading, error, client }] = useMutation(
    isPremium ? UPDATE_PREMIUM_REAL_ESTATES : UPDATE_REAL_ESTATES
  );
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (state, setCaptchaError, captchaCode, captchaId) => {
      const pickObject = updateRealEstateVariables(state);
      try {
        await updateRealEstate({
          variables: isPremium
            ? {
                realEstateId,
                data: isPremium
                  ? _.omit(pickObject, ['frontHomeAlleyWidth', 'location'])
                  : _.omit(pickObject, ['map', 'alleyWidth']),
              }
            : {
                realEstateId,
                captchaCode,
                captchaId,
                editOnly: true,
                data: isPremium
                  ? _.omit(pickObject, ['frontHomeAlleyWidth', 'location'])
                  : _.omit(pickObject, ['map', 'alleyWidth']),
              },
        });
        setShowModal(true);
        await resetQueries(['getRealEstates'], client.cache);
      } catch (e) {
        (await e?.message.includes('Mã an toàn'))
          ? setCaptchaError(e?.message)
          : null;
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e?.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [updateRealEstate, client, navigate]
  );
  return { onSubmit, loading, error };
};
