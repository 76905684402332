import MyButton from '@components/button';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useCreateEmployee } from '@utils/hooks/b2bUser/useCreateEmployee';
import { useUpdateEmployee } from '@utils/hooks/b2bUser/useUpdateEmployee';

type Props = {
  isUpdate?: boolean,
  idUser?: string,
  onBack?: Function,
};
export default function Submit({ isUpdate, onBack, idUser }: Props) {
  const { onSubmit, loading } = useCreateEmployee();
  const { onUpdateSubmit, updateLoading } = useUpdateEmployee(idUser);
  const { handleSubmit } = useFormContext();
  return (
    <div className="w-full bg-white h-61px flex flex-row mt-5 items-center justify-end p-x-6 space-x-2 sticky bottom-0 border-t border-gray-300">
      <div className="w-147px">
        <MyButton
          disabled={loading}
          onPress={onBack}
          label="Trở về"
          type="gray"
        />
      </div>
      <div className="w-147px">
        <MyButton
          disabled={isUpdate ? updateLoading : loading}
          onPress={handleSubmit(isUpdate ? onUpdateSubmit : onSubmit)}
          label={isUpdate ? 'Cập nhật' : 'Lưu'}
          type="blue"
        />
      </div>
    </div>
  );
}
