import React, { useState } from 'react';
import { View } from 'react-native-web';
import { unionBy } from 'lodash';
import { useList } from 'react-use';
import { ManageUserB2BContext } from './context';
import { useStateFilterManageUserB2B } from '@utils/hooks/manageUserB2B/useStateFilterManageUserB2B';
import UserB2BTable from './components/feedbackTable';
import { useGetListB2BAdmin } from '@utils/hooks/manageUserB2B/useGetListB2BAdmin';
import { useGetListFeedback } from '@utils/hooks/manageFeedback/useGetListFeedbacks';
import UserDetail from './userDetail';
export default function ManageFeedback() {
  const [tab, setTab] = useState('/manageUser/b2b');
  const [listSelected, setListSelected] = useList([]);
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterManageUserB2B();
  let { listUser, loading } = useGetListB2BAdmin({ state: stateFilter });
  let { listFeedback } = useGetListFeedback();
  let feedbacks = listFeedback?.getListFeedbackValuationStreet;
  return (
    <ManageUserB2BContext.Provider
      value={{
        stateFilter,
        setStateFilterAt,
        setState,
        listSelected: unionBy(listSelected, e => e),
        setListSelected,
        tab,
        setTab,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            marginTop: 4,
            backgroundColor: 'white',
            paddingHorizontal: 16,
            paddingTop: 20,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        ></View>
        <View style={{ width: '100%', height: '100%' }}>
          <View
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingBottom: 12,
            }}
          >
            <div className="w-full px-4">
              <UserB2BTable
                data={listUser}
                test={feedbacks}
                loading={loading}
              />
            </div>
          </View>
          {listSelected?.length === 1 ? (
            <View
              style={{
                width: '100%',
                height: '100%',
                marginTop: 8,
              }}
            >
              <UserDetail />
            </View>
          ) : (
            <div className="p-4 text-black-main">
              Vui lòng chọn 1 góp ý để xem chi tiết
            </div>
          )}
        </View>
      </View>
    </ManageUserB2BContext.Provider>
  );
}
