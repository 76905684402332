import React from 'react';
import { TileLayer } from 'react-leaflet';

export default function InternalMapLayer() {
  return (
    <>
      <TileLayer
        zIndex={10}
        bounds={[
          [10.77018903685859, 106.6894140616174],
          [10.77959189530552, 106.7011389405447],
        ]}
        url="https://tncc-stg.s3-ap-southeast-1.amazonaws.com/map/BenThanh/{z}/{x}/{y}.png"
      />
      <TileLayer
        bounds={[
          [10.75356766566103, 106.68514256017],
          [10.76162047501218, 106.6925239483479],
        ]}
        zIndex={11}
        url="https://tncc-stg.s3-ap-southeast-1.amazonaws.com/map/CauKho/{z}/{x}/{y}.png"
      />
      <TileLayer
        bounds={[
          [10.76441700985375, 106.6964340865579],
          [10.77113487621708, 106.7082019886935],
        ]}
        zIndex={12}
        url="https://tncc-stg.s3-ap-southeast-1.amazonaws.com/map/NguyenThaiBinh/{z}/{x}/{y}.png"
      />
    </>
  );
}
