import styles from '../bottomRealEstate.module.scss';
import ItemDescription from '@components/itemRealEstate/itemDescription';
import React from 'react';
import { useDescriptionRealEstate } from '@components/itemRealEstate/utils';
import { usePrice } from '@components/itemRealEstate/hooks';
import realEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import RenderTime from '@components/itemRealEstate/components/renderTime';
type BottomRealEstateProps = {
  item?: Object,
};
export default function BottomRealEstateForMap({
  item,
}: BottomRealEstateProps) {
  const { postTitle } = item;
  const description = useDescriptionRealEstate({ item });
  const { pricePost, unitPrice } = usePrice(item);

  const { priceUnitsForRent } = realEstateCommonConfigs;

  const priceUnitName =
    priceUnitsForRent.find(e => e.key === item?.priceUnit)?.value || '';

  return (
    <div className={styles.descriptionForMap}>
      <div className="flex-1 flex flex-col">
        <div className="text-12 text-black-main">
          {postTitle?.toString()?.toLowerCase()}
        </div>
        <div className={`${styles.descriptionInnerForMap} mt-1`}>
          {description.map((des, index) => {
            return (
              <ItemDescription
                iconClass={styles.iconQuickInfoForMap}
                valueClass={styles.txtQuickInfoForMap}
                {...des}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <p className={styles.unitPriceForMap}>
          <b className={styles.priceTextForMap}>{pricePost}</b>
          {unitPrice}
          {priceUnitName.replace('VNĐ', '')}
        </p>
        <RenderTime createdAt={item?.createdAt} />
      </div>
      {item?.privateNote?.length > 0 && (
        <img
          alt="note"
          src="/svg/note.svg"
          className="w-30px h-30px absolute right-2 bottom-8"
        />
      )}
    </div>
  );
}
