import React, { useCallback } from 'react';
import MyInput from '@components/myInput';
import Checkbox from '@components/checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import SubmitButton from './submitButton';
import PasswordInput from '@components/passwordInput';
import { useLogin } from '@utils/hooks/auth/useLogin';

export default function Content() {
  const { control, handleSubmit } = useFormContext();
  const { doLogin, loading, error } = useLogin();
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSubmit(doLogin)();
      }
    },
    [handleSubmit, doLogin]
  );
  return (
    <div className="w-full flex flex-col items-center justify-center mx-20 space-y-5">
      <div className="text-24 font-medium text-blue-main uppercase">
        Đăng nhập
      </div>
      <Controller
        rules={{
          required: 'Vui lòng nhập email hoặc số điện thoại',
        }}
        control={control}
        name="username"
        render={({
          field,
          field: { onChange },
          fieldState: { invalid, error },
        }) => {
          return (
            <MyInput
              {...field}
              onKeyDown={event => handleKeyDown(event)}
              invalid={invalid}
              errorMessage={error?.message}
              inputClassName="w-full"
              isRequired
              label="Email/ Số điện thoại"
              placeholder="Nhập email hoặc số điện thoại"
              onDelete={() => onChange('')}
            />
          );
        }}
      />
      <Controller
        rules={{
          required: 'Vui lòng nhập mật khẩu',
        }}
        control={control}
        name="password"
        render={({
          field,
          field: { onChange },
          fieldState: { invalid, error },
        }) => {
          return (
            <PasswordInput
              {...field}
              onKeyDown={event => handleKeyDown(event)}
              type="password"
              invalid={invalid}
              errorMessage={error?.message}
              inputClassName="w-full"
              isRequired
              label="Mật khẩu"
              placeholder="Nhập mật khẩu"
              onDelete={() => onChange('')}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="isSavedToken"
        render={({ field: { onChange, value } }) => {
          return (
            <div className="w-full">
              <Checkbox
                isChecked={value}
                onPress={() => onChange(!value)}
                title="Nhớ đăng nhập"
              />
            </div>
          );
        }}
      />
      <SubmitButton doLogin={doLogin} loading={loading} error={error} />
    </div>
  );
}
