import Comment from '@components/comment';
import { useMutation } from '@apollo/client';
import { LEAVE_COMMENT } from '@utils/graphql';
import { useProductContext } from '../../../../product/context';
import InputComment from '@components/comment/component/inputComment';
import { useCallback, useRef } from 'react';
import { resetQueries } from '@utils/helper';
import useGetInternalRealEstateComments from '@utils/hooks/manageProduct/useGetInternalRealEstateComments';
import { useLocation } from 'react-router-dom';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export default function CommentProduct() {
  const { permissions } = useGetMe();
  const { pathname }: RouterLocation = useLocation();
  let newId = pathname.includes('/manageProduct/details/')
    ? pathname.slice(23)
    : null;
  const { listSelected } = useProductContext();
  const inputRef = useRef(null);
  const [leaveComment, { loading, client }] = useMutation(LEAVE_COMMENT),
    onSubmit = useCallback(async (content, idParent, setRepCommentId) => {
      try {
        await leaveComment({
          variables: {
            realEstateId: listSelected?.[0],
            parentComment: idParent,
            content,
          },
        });
        idParent && setRepCommentId(null);
        inputRef?.current?.resetValue();
        await resetQueries(
          ['getInternalRealEstates', 'getInternalRealEstateComments'],
          client.cache
        );
      } catch (e) {
        console.log(e);
      }
    });
  const {
    listComment,
    onLoadMore,
    loading: loadingComment,
    hasNextPage,
  } = useGetInternalRealEstateComments(newId);
  return (
    <div>
      {!newId && (
        <InputComment
          ref={inputRef}
          onSend={({ content }) => onSubmit(content)}
          disabled={
            loading ||
            !permissions?.some(p => p.id === PermissionIds.AddProductNote)
          }
        />
      )}
      <Comment
        newId={newId}
        hasNextPage={hasNextPage}
        onLoadMore={onLoadMore}
        loading={loadingComment}
        listComment={listComment}
        repComment={async ({ content, id, setRepCommentId }) => {
          await onSubmit(content, id, setRepCommentId);
        }}
      />
    </div>
  );
}
