import { useQuery } from '@apollo/client';
import { GET_LIST_BASIC_DEPARTMENTS } from '@utils/graphql';

export const useGetListBasicDepartments = (
  includedIds = [],
  excludedIds = []
) => {
  const { data, loading } = useQuery(GET_LIST_BASIC_DEPARTMENTS, {
    variables: {
      includedIds,
      excludedIds,
    },
    skip:
      includedIds?.includes('all') ||
      includedIds?.includes('owner') ||
      !Array.isArray(includedIds) ||
      !Array.isArray(excludedIds) ||
      (!includedIds?.length && !excludedIds?.length),
  });
  return { data: data?.b2bGetListBasicDepartments, loading };
};
