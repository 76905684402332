import React, { useMemo, useState, lazy, Suspense } from 'react';
import HeadLeftRealEstateDetail from './headLeftRealEstateDetail';
import ContentDetailRealEstate from './contentDetailRealEstate';
import { useNeedApprovalContext } from '../../../needApproval/context';
import RenewRealEstateHistory from '../../../components/renewRealEstateHistory';
import SwitchInner from '@components/switchInner';
const RealEstateEditHistory = lazy(() =>
  import('../../../components/realEstateEditHistory')
);
const InteractionHistory = lazy(() =>
  import('../../../components/interactionHistory')
);

type Props = {
  demand?: string,
  data?: Object,
};

const switchTabConfigs = () => {
  const { tab } = useNeedApprovalContext();
  return [
    {
      key: '0',
      value: 'Thông tin bài đăng',
    },
    {
      key: '1',
      value: 'Lịch sử gia hạn',
      isHide: tab.includes('needApproval'),
    },
    {
      key: '2',
      value: 'Lịch sử chỉnh sửa',
      isHide: tab.includes('needApproval'),
    },
    {
      key: '4',
      value: 'Lịch sử tương tác',
      isHide: tab.includes('needApproval'),
    },
  ];
};
export default function LeftRealEstateDetail({ demand, data }: Props) {
  const [currentTab, setCurrentTab] = useState('0');
  const { tab } = useNeedApprovalContext();
  const tabConfigs = switchTabConfigs();
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case '0':
        return <ContentDetailRealEstate data={data} demand={demand} />;
      case '1':
        return (
          <RenewRealEstateHistory
            demand={demand}
            isPremium={data?.realEstateType}
          />
        );
      case '2':
        return (
          <RealEstateEditHistory
            demand={demand}
            isPremium={data?.realEstateType}
          />
        );
      case '4':
        return (
          <InteractionHistory
            demand={demand}
            isPremium={data?.realEstateType}
          />
        );
    }
  }, [data, currentTab, demand]);

  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="flex-1 bg-white rounded-lg"
    >
      <HeadLeftRealEstateDetail
        postTitle={data?.postTitle}
        fullAddress={data?.fullAddress}
        postStatus={data?.postStatus}
        isDeleted={data?.isDeleted}
      />
      <SwitchInner
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      {tab.includes('denied') && currentTab === '0' && (
        <div className="flex-1 py-2 px-10 mx-4 rounded-lg border border-red-600 flex flex-row items-start space-x-2 mt-6">
          <img alt="ico" src="/svg/rejected-icon.svg" className="mt-1" />
          <div
            className="text-base"
            dangerouslySetInnerHTML={{
              __html: `<b className='text-base'>Lý do từ chối:</b> ${data?.approvalReason}`,
            }}
          />
        </div>
      )}
      <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
      </div>
    </div>
  );
}
