import HorizontalBar from '@components/horizontalBar';

type SwitchManageRealEstateProps = {
  value?: string,
  setValue?: Function,
  configs?: Array,
};

export default function Switch({
  value = '',
  setValue,
  configs,
}: SwitchManageRealEstateProps) {
  return (
    <div className="w-full overflow-x-auto overflow-y-hidden">
      <div className="flex-1 flex whitespace-nowrap flex-row space-x-6 pb-2 border-b border-gray-300">
        {configs.map((item, index) => {
          const isActive = value === item.pathname || item?.key === value;
          return (
            <div
              key={index}
              className="flex flex-col relative justify-center items-center"
            >
              <div
                style={{ color: isActive ? '#2459ad' : '#80888F' }}
                onClick={() => setValue(item.pathname, item.key)}
                key={index}
                className={`cursor-pointer text-13 font-medium`}
              >
                {item.title}
              </div>
              <HorizontalBar open={isActive} className="absolute -bottom-2.5" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
