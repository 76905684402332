import React, { useMemo, useState } from 'react';
import ReactSlider from 'react-slider';
import isEqual from 'lodash/isEqual';
import styles from './reactSlider.module.scss';
type Props = {
  min?: number,
  max?: number,
  step?: number,
  minDistance?: number,
  setValue?: Function,
  currentFrom?: number,
  currentTo?: number,
  unit?: string,
};
type CustomLabelProps = {
  text?: string,
  unit?: string,
  labelStyle?: any,
  renderLeft?: boolean,
  renderRight?: boolean,
  getAll?: boolean,
  renderToValue?: string,
  renderFromValue?: string,
  isLong?: boolean,
};
function CustomLabel({
  text,
  unit,
  labelStyle,
  renderLeft,
  renderRight,
  getAll,
  renderToValue,
  renderFromValue,
  isLong,
}: CustomLabelProps) {
  return (
    <div className={`${styles.labelContainer} ${labelStyle}`}>
      {!getAll && renderLeft && (
        <div className={styles.labelLeft}>
          <div className="font-semibold text-black text-11">
            {parseFloat(parseFloat(text).toFixed(1))}
            {unit && unit}
            {renderLeft && ' - ' + renderToValue}
          </div>
        </div>
      )}
      {!getAll && renderRight && (
        <div
          className={`${
            isLong && isLong ? styles.labelRightLong : styles.labelRightShort
          }`}
        >
          <div className="font-semibold text-black text-11">
            {renderRight && renderFromValue + ' - '}
            {parseFloat(parseFloat(text).toFixed(1))}
            {unit && unit}
          </div>
        </div>
      )}
      {getAll && (
        <div className={styles.getAllContainer}>
          {parseFloat(parseFloat(text).toFixed(1))}
          {unit && unit}
        </div>
      )}
    </div>
  );
}

type LabelProps = {
  props?: Object,
  state1?: string,
  state2?: string,
  state?: Object,
  max?: string,
  theValueIsShow?: string,
  labelStyle?: any,
  stepRenderLine?: number,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
};
function RenderLabel({
  props,
  state1,
  state2,
  state,
  max,
  theValueIsShow,
  labelStyle,
  stepRenderLine = 20,
  progress,
  isLong,
}: LabelProps) {
  const toValue = state2 >= max && theValueIsShow ? theValueIsShow : state2;
  const renderLine = state2 - state1 <= stepRenderLine;
  const renderUnit = '';
  const getAll = (state1 < progress && state2 > progress) || !renderLine;
  const getLabelLeft = state1 < progress && state2 <= progress;
  const getLabelRight = state1 > progress;
  return (
    <div {...props}>
      {state.index === 0 && getAll && (
        <CustomLabel getAll={getAll} labelStyle={labelStyle} text={state1} />
      )}
      {state.index === 1 && getAll && (
        <CustomLabel
          getAll={getAll}
          text={parseFloat(parseFloat(toValue).toFixed(1))}
          unit={renderUnit}
        />
      )}
      {state.index === 0 && renderLine && getLabelLeft && (
        <CustomLabel
          getAll={getAll}
          renderLeft={renderLine && getLabelLeft}
          labelStyle={labelStyle}
          text={state1}
          renderToValue={`${parseFloat(
            parseFloat(toValue).toFixed(1)
          )} ${renderUnit}`}
        />
      )}
      {state.index === 1 && renderLine && getLabelRight && (
        <CustomLabel
          isLong={isLong}
          getAll={getAll}
          renderRight={renderLine && getLabelRight}
          renderLine={renderLine}
          text={parseFloat(parseFloat(toValue).toFixed(1))}
          unit={renderUnit}
          renderFromValue={state1}
        />
      )}
    </div>
  );
}
export default React.memo(ReactSliderComponent, isEqual);
function ReactSliderComponent(props: Props) {
  const {
    min,
    max,
    step,
    minDistance = 1,
    setValue,
    theValueIsShow,
    stepRenderLine = 20,
    progress,
    isLong,
    showPlus = false,
  } = props;
  const [state1, setState1] = useState(min),
    [state2, setState2] = useState(max);
  return useMemo(() => {
    return (
      <div className="w-full space-y-4">
        <div className="w-full h-1px">
          <ReactSlider
            value={[parseFloat(state1), parseFloat(state2)]}
            onChange={values => {
              setState1(values[0]);
              setState2(values[1]);
            }}
            onAfterChange={() => {
              setValue([state1, state2]);
            }}
            min={min}
            max={max}
            thumbClassName="example-thumb"
            step={step}
            className="horizontal-slider"
            defaultValue={[parseFloat(state1), parseFloat(state2)]}
            ariaLabel={['Lower thumb', 'Upper thumb']}
            trackClassName="example-track"
            ariaValuetext={state => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => {
              return (
                <RenderLabel
                  theValueIsShow={theValueIsShow}
                  max={max}
                  props={props}
                  state={state}
                  state1={state1}
                  state2={state2}
                  stepRenderLine={stepRenderLine}
                  progress={progress}
                  isLong={isLong}
                  showPlus={showPlus}
                />
              );
            }}
            pearling
            minDistance={minDistance}
          />
        </div>
        <div className={styles.bottomMinMaxContainer}>
          <div className="text-gray-500 font-semibold text-11">{min}</div>
          <div className="text-gray-500 font-semibold text-11">{max}</div>
        </div>
      </div>
    );
  }, [
    isLong,
    max,
    min,
    minDistance,
    progress,
    setValue,
    showPlus,
    state1,
    state2,
    step,
    stepRenderLine,
    theValueIsShow,
  ]);
}
