import { HoChiMinhId } from '../../../constant';

export const blankState = {
  username: '',
  password: '',
  confirmPassword: '',
  avatar: '',
  fullName: '',
  province: HoChiMinhId,
  district: '',
  ward: '',
  street: '',
  gender: 'male',
  dateOfBirth: '',
  idImages: [],
  attachments: [],
  houseNumber: '',
  idNumber: '',
  phone: '',
  secondPhone: '',
  email: '',
  isLeader: false,
  departmentId: '',
  approveDepartment: '',
  position: '',
};

export const genders = [
  {
    key: 'male',
    value: 'Nam',
  },
  {
    key: 'female',
    value: 'Nữ',
  },
];

export const roles = [
  {
    key: 'create',
    value: 'Quyền tạo',
  },
  {
    key: 'approve',
    value: 'Quyền duyệt',
  },
];
