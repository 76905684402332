import RenderCustomerInfo from './renderCustomerInfo';
import RenderAdminInfo from './renderAdminInfo';

type Props = {
  data?: Object,
};
export default function ContentUserDetail({ data }: Props) {
  return (
    <div className="flex-1 flex flex-col space-y-10 ">
      <RenderCustomerInfo companyId={data?.company} />
      <div className="flex-1 flex flex-row items-center space-x-4">
        <div style={{ backgroundColor: '#B4B8BC' }} className="flex-1 h-1px" />
        <img alt="ico" src="/svg/lineLogo.svg" />
        <div style={{ backgroundColor: '#B4B8BC' }} className="flex-1 h-1px" />
      </div>
      <RenderAdminInfo adminData={data} />
    </div>
  );
}
