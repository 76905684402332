import React from 'react';
import ContractDetailOtherInfo from './contractDetailOtherInfo';
import Section from '../../../scenes/home/manageRealEstate/realEstateDetail/components/leftRealEstateDetail/contentDetailRealEstate/section';
import ItemRowContractDetail from './itemRowContractDetail';
import moment from 'moment/moment';
import { numberWithDots } from '@utils/helper';
import AttachmentsTable from '../../../scenes/home/manageUser/b2b/userDetail/components/leftUserDetail/contentUserDetail/attachments';
import { useGetUserBasicInfo } from '@utils/hooks/common/useGetUserBasicInfo';
import { contractTypeConfigs } from '../../../configs';

type Props = {
  contractDetail?: Object,
  listAttachment?: Array,
  b2bUserData?: Object,
};

const renderRowContractDetail = (data = {}, b2bUserData = {}) => {
  const status = data?.status === 'active' ? 'Còn hạn' : 'Hết hạn';
  return [
    {
      title: 'Tên hợp đồng',
      value: data?.name,
    },
    {
      title: 'Loại hợp đồng',
      value: contractTypeConfigs.find(e => e.key === data?.system)?.value,
    },
    {
      title: 'Tên công ty',
      value: !b2bUserData?.isPersonal ? b2bUserData?.name : '',
    },
    {
      title: 'Mã số thuế',
      value: data?.companyInfo?.taxId,
    },
    {
      title: 'Thời hạn hợp đồng',
      value: `${moment(data?.contractDate).format('DD/MM/YYYY')} - ${moment(
        data?.endDate
      ).format('DD/MM/YYYY')}`,
    },
    {
      title: 'Trạng thái hợp đồng',
      value: status,
    },
    {
      title: 'Số lượng nhân viên',
      value: numberWithDots(data?.totalMember),
    },
    {
      title: 'Giá khuyến mãi nhân viên',
      value: `<span class='text-red-500'>${numberWithDots(
        data?.discount
      )} VNĐ</span>/ nhân viên/ tháng`,
    },
    {
      title: 'Giá nhân viên',
      value: `<span class="text-red-500">
            ${numberWithDots(data?.priceMember || 0)} VNĐ
          </span> / nhân viên/ tháng`,
    },
    {
      title: 'Tổng tiền hợp đồng',
      value: `<span class='text-red-500'>${numberWithDots(
        data?.totalPrice
      )} VNĐ</span>`,
    },
  ];
};

export default function ContractInfoDetail({
  contractDetail,
  listAttachment,
  b2bUserData,
}: Props) {
  const renderRow = renderRowContractDetail(contractDetail, b2bUserData);
  const { userInfo } = useGetUserBasicInfo(contractDetail?.creator),
    creatorName = userInfo?.fullName,
    avatar = userInfo?.avatar?.originalUrl;
  return (
    <div className="flex-1 h-full flex flex-row p-4 space-x-4">
      <div className="w-250px xl:w-323px">
        <ContractDetailOtherInfo data={contractDetail} />
      </div>
      <Section
        source="/svg/book-icon.svg"
        title="Thông tin chi tiết hợp đồng"
        wrapperClass="flex-1 min-w-0 border border-[#f4f4f4] p-4 rounded-lg"
      >
        {renderRow?.map((item, index) => {
          return (
            <ItemRowContractDetail
              key={index}
              title={item?.title}
              value={item?.value}
            />
          );
        })}
        {listAttachment?.length > 0 && (
          <div className="mt-7 space-y-4 flex-1">
            <div className="flex items-center space-x-2">
              <div
                style={{ backgroundColor: '#F4F4F4' }}
                className="w-30px h-30px rounded-lg flex items-center justify-center"
              >
                <img alt="ico" src="/svg/pinAngle.svg" className="w-5 h-5" />
              </div>
              <div className="text-base font-medium text-blue-main">
                Tài liệu đính kèm
              </div>
            </div>
            <div className="text-sm font-medium text-black-main"></div>
            <AttachmentsTable
              avatar={avatar}
              fullName={creatorName}
              creator={contractDetail?.creator}
              attachments={listAttachment}
            />
          </div>
        )}
      </Section>
    </div>
  );
}
