import React, { useState } from 'react';
import MyButton from '@components/button';
import ActionEmployeeModal from '../../../../../../../modals/actionEmployeeModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useApproveFeedback } from '@utils/hooks/manageFeedback/useApproveFeedback';
import { useDeleteFeedback } from '@utils/hooks/manageFeedback/useDeleteFeedback';

export default function RightUserDetail() {
  const [openApprove, setApprove] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const { onSubmit, loading } = useApproveFeedback(setApprove);
  const { onSubmit: onSubmitDelete, loading: loadingDelete } =
    useDeleteFeedback(setDelete);

  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      <div className="space-y-4">
        <MyButton onPress={() => setApprove(true)} type="blue" label="Duyệt" />
        <MyButton onPress={() => setDelete(true)} type="gray" label="Xóa" />
      </div>
      <MyModalV2 open={openApprove} onClose={() => setApprove(false)}>
        <ActionEmployeeModal
          header="Duyệt góp ý"
          content="Bạn có muốn duyệt góp ý định giá này?"
          onClose={() => setApprove(false)}
          onConfirm={onSubmit}
          loading={loading}
        />
      </MyModalV2>
      <MyModalV2 open={openDelete} onClose={() => setDelete(false)}>
        <ActionEmployeeModal
          header="Xóa góp ý"
          content="Bạn có muốn xóa góp ý định giá này?"
          onClose={() => setDelete(false)}
          onConfirm={onSubmitDelete}
          loading={loadingDelete}
        />
      </MyModalV2>
    </div>
  );
}
