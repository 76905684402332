import React, { useState } from 'react';
import MyModalV2 from '@components/modalV2/myModalV2';
import ModalViewMedia from '../../../../../../../../modals/modalViewMedia';
import ReadImageAndVideo from '../../../../../../manageProduct/productDetail/components/leftProductDetail/contentProductDetail/readImageAndVideo';

type Props = {
  idImages?: string,
  wrapperClassName?: string,
};
export default function RenderIdImage({ idImages, wrapperClassName }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const listIdImage = idImages?.filter(e => e !== null);
  return (
    <div className={`flex flex-col space-y-4 ${wrapperClassName}`}>
      <div className="text-sm font-medium text-black-main">
        Hình ảnh CMND/ CCCD 2 mặt
      </div>
      {listIdImage?.length > 0 ? (
        <div
          style={{ backgroundColor: '#EDF4FF' }}
          className="w-full p-3 rounded flex flex-row space-x-3"
        >
          {idImages?.map((item, index) => {
            return (
              <ReadImageAndVideo
                key={index}
                onPress={() => {
                  setShowModal(true);
                  setActiveIndex(index);
                }}
                idUrls={item}
              />
            );
          })}
        </div>
      ) : (
        <div className="text-14 text-black-main">Không có</div>
      )}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={activeIndex} listMedia={listIdImage} />
      </MyModalV2>
    </div>
  );
}
