import gql from 'graphql-tag';

export const LIST_DEPARTMENTS = gql`
  query b2bGetListDepartments @api(name: users) {
    b2bGetListDepartments {
      id
      name
    }
  }
`;
