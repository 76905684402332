import MyDropdown from '@components/myDropdown';
import React, { useEffect, useState } from 'react';
import MyModal from '@components/modal';
import ConfirmLogout from '../../../modals/logoutModal';
import ChangePasswordModal from '../../../modals/changePasswordModal';
import ChangePasswordSuccessModal from '../../../modals/changePasswordSuccessModal';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { useNavigate } from 'react-router-dom';

type Props = {
  context?: {
    setOpenModalChangePassword?: Function,
    setOpenModalLogout?: Function,
  },
  close?: Function,
};
function UserDropdown(props: Props) {
  const {
    close,
    context: { setOpenModalChangePassword, setOpenModalLogout },
  } = props;
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-200px rounded-lg h-auto py-2 bg-white border border-gray-300"
    >
      <div
        onClick={() => {
          close();
          setOpenModalChangePassword(true);
        }}
        className="selectItem"
      >
        Đổi mật khẩu
      </div>
      <div
        onClick={() => {
          close();
          setOpenModalLogout(true);
        }}
        className="selectItem"
      >
        Đăng xuất
      </div>
    </div>
  );
}
export default function UserProfileDropdown() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const { avatarUrl, fullName, loading, error } = useGetMe();
  useEffect(() => {
    if (error?.message === 'Not authenticated') {
      navigate('/auth/login');
      localStorage.clear('');
    }
  }, [error?.message]);
  return (
    <>
      <MyDropdown
        DropdownComponent={UserDropdown}
        dropdownContext={{
          setOpenModalChangePassword: setShowModal,
          setOpenModalLogout: setShowModalLogout,
        }}
      >
        <div className="flex flex-row items-center space-x-2">
          <div className="flex flex-row items-center space-x-1">
            <div
              className={`w-6 h-6 rounded-full overflow-hidden border border-gray-300 ${
                !avatarUrl ? 'bg-white' : undefined
              }`}
            >
              {avatarUrl ? (
                <img
                  alt="avt"
                  src={avatarUrl}
                  className="w-6 h-6 rounded-full"
                />
              ) : (
                <img alt="avt" src="/svg/male.svg" />
              )}
            </div>
            <div className={`text-sm font-medium truncate text-white`}>
              {loading
                ? 'Đang tải dữ liệu'
                : error
                ? 'Unable to fetch'
                : fullName}
            </div>
          </div>
          <img alt="arr" src="/svg/arrow-white.svg" />
        </div>
      </MyDropdown>
      <MyModal
        style={{ width: 'auto' }}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <ChangePasswordModal
          setShowModalSuccess={() => setShowModalSuccess(true)}
          onClose={() => setShowModal(false)}
        />
      </MyModal>
      <MyModal
        style={{ width: 'auto' }}
        isOpen={showModalLogout}
        onClose={() => setShowModalLogout(false)}
      >
        <ConfirmLogout onClose={() => setShowModalLogout(false)} />
      </MyModal>
      <MyModal
        style={{ width: 'auto' }}
        isOpen={showModalSuccess}
        onClose={() => setShowModalSuccess(false)}
      >
        <ChangePasswordSuccessModal
          onClose={() => setShowModalSuccess(false)}
        />
      </MyModal>
    </>
  );
}
