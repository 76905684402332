import { ReactChildren } from 'react';
import { ScrollView } from 'react-native-web';

type Props = {
  children?: ReactChildren,
  renderHead?: Function,
  wrapperClassName?: string,
};
export default function Table({ children, renderHead }: Props) {
  return (
    <ScrollView
      horizontal
      contentContainerStyle={{
        minWidth: '100%',
      }}
    >
      {renderHead && renderHead()}
      <ScrollView
        style={{
          minHeight: 50,
          borderBottomLeftRadius: 9,
          borderBottomRightRadius: 9,
        }}
        className="flex-1 flex flex-col"
      >
        {children}
      </ScrollView>
    </ScrollView>
  );
}
