import React from 'react';
import isEqual from 'lodash/isEqual';
import MarkerWithPopup from './markerWithPopup';

type Props = {
  realEstateOnMap?: Array,
  setPosition?: Function,
  setViewRealEstateOnMap?: Function,
  setShowPopupRealEstate?: Function,
  onClickCircle?: Function,
  isFullScreen?: boolean,
  showPopupRealEstate?: boolean,
  zoom?: number,
  coordinatesRealEstate?: Object,
};

export default React.memo(RealEstatesOnMap, isEqual);

function RealEstatesOnMap(props: Props) {
  const {
    realEstateOnMap = [],
    setPosition,
    setViewRealEstateOnMap,
    isFullScreen,
    zoom,
    showPopupRealEstate,
    onClickCircle,
    coordinatesRealEstate,
  } = props;

  return realEstateOnMap?.map(item => {
    return (
      <MarkerWithPopup
        coordinatesRealEstate={coordinatesRealEstate}
        setPosition={setPosition}
        setViewRealEstateOnMap={setViewRealEstateOnMap}
        isFullScreen={isFullScreen}
        key={JSON.stringify({
          lat: item.point.coordinates[1],
          long: item.point.coordinates[0],
        })}
        zoom={zoom}
        lat={item.point.coordinates[1]}
        long={item.point.coordinates[0]}
        {...item}
        showPopupRealEstate={showPopupRealEstate}
        onClickCircle={onClickCircle}
      />
    );
  });
}
