import styles from './confirmLogoutModal.module.scss';
import MyButton from '@components/button';
import useLogOut from '../../utils/hooks/auth/useLogout';

type Props = {
  onClose?: Function,
};

export default function ConfirmLogout({ onClose }: Props) {
  const { logout } = useLogOut();

  return (
    <div className={styles.confirmLogoutModal}>
      <img className="w-54px h-54px" src="/svg/questions.svg" alt="icon" />
      <div className="text-24 font-medium text-blue-main mt-6">Đăng xuất</div>
      <div className="mt-3 text-sm">Bạn có chắc chắn muốn đăng xuất?</div>
      <div className="flex w-full space-x-2 mt-6">
        <MyButton
          label="Trở về"
          type="gray"
          wrapperClass="w-full"
          onPress={onClose}
        />
        <MyButton
          type="blue"
          label="Xác nhận"
          wrapperClass="w-full"
          onPress={() => {
            logout();
            onClose();
          }}
        />
      </div>
    </div>
  );
}
