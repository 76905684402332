import React, { useState } from 'react';
import styles from './textArea.module.scss';

export type InputProps = {
  label?: string,
  name?: string,
  inputClassName?: string,
  invalid?: boolean,
  errorMessage?: string,
  inputOuterClassName?: string,
  maxlength?: number,
  onDelete?: Function,
  rows?: number,
  disabled?: boolean,
  showIconClose?: boolean,
  labelClass?: string,
};

export default React.forwardRef(MyTextAreaInput);

function MyTextAreaInput(props: InputProps, ref) {
  const {
    label,
    errorMessage,
    inputClassName,
    inputOuterClassName,
    invalid,
    maxlength,
    onDelete,
    rows = 5,
    disabled,
    labelClass,
    showIconClose = true,
    ...inputProps
  } = props;
  const [focused, setFocused] = useState(false);
  return (
    <div
      className={`w-full flex flex-col space-y-2 ${inputClassName} ${
        invalid ? 'errorInput' : ''
      }`}
    >
      {label && (
        <div className="flex-1">
          <div className={`${styles.label} ${labelClass}`}>{label}</div>
        </div>
      )}
      <div
        style={{
          boxShadow: focused
            ? '0px 0px 3px 3px rgba(67,142,255,0.26)'
            : undefined,
        }}
        className={`flex flex-row px-2 pt-2 ${styles.inputContainer} ${
          disabled ? 'bg-gray-100' : undefined
        } ${invalid ? styles.inputError : inputOuterClassName}`}
      >
        <textarea
          {...inputProps}
          disabled={disabled}
          maxLength={maxlength}
          rows={rows}
          ref={ref}
          onFocus={e => {
            inputProps.onFocus && inputProps.onFocus(e);
            setFocused(true);
          }}
          onBlur={e => {
            inputProps.onBlur && inputProps.onBlur(e);
            setFocused(false);
          }}
          className={`${styles.innerInput} pr-10 ${
            disabled ? 'bg-gray-100' : undefined
          }`}
        />
        <div
          className="h-full flex flex-col justify-between absolute top-2.5 right-0.5 py-1"
          style={{
            minWidth: 32,
          }}
        >
          {inputProps?.value && showIconClose ? (
            <div
              onClick={!disabled ? onDelete : () => {}}
              className="w-14px h-14px cursor-pointer"
            >
              <img
                className="w-full h-full ml-1"
                alt="close"
                src="/svg/xIcon.svg"
              />
            </div>
          ) : (
            <div className="w-20px h-20px cursor-pointer" />
          )}
          <div
            style={{ fontSize: 11 }}
            className="text-gray-400 absolute bottom-4"
          >
            {maxlength && maxlength - inputProps?.value?.length}
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="text-sm text-red-400">{errorMessage}</div>
      )}
    </div>
  );
}
