import Label from '@components/label';
import { Controller, useFormContext } from 'react-hook-form';
import ProvinceDropdownSelection from '@components/addressDropdownGroup/provinceDropdownSelection';
import DistrictDropdownSelection from '@components/addressDropdownGroup/districtDropdownSelection';
import WardDropdownSelection from '@components/addressDropdownGroup/wardDropdownSelection';
import MyInput from '@components/myInput';
import StreetDropdownSelection from '@components/addressDropdownGroup/streetDropdownSelection';
import React from 'react';
import { useGetLocation } from '../scenes/home/manageProduct/configs';

type Props = {
  isDisabled?: boolean,
  isShowAdditionalInfo?: Boolean,
};
export default function AddressV2({
  isDisabled,
  isShowAdditionalInfo = false,
}: Props) {
  const { control, watch, setValue } = useFormContext();
  const province = watch('province');
  const district = watch('district');
  const ward = watch('ward');
  const { isTabPublish } = useGetLocation();
  return (
    <div className="w-full flex flex-row space-x-2.5 items-start">
      <Label wrapperClass="w-152px" isRequired label="Địa chỉ" />
      <div className="flex-1">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <Controller
              rules={{
                required: 'Vui lòng chọn Tỉnh/thành phố',
              }}
              control={control}
              name="province"
              render={({ field: { value, onChange } }) => {
                return (
                  <ProvinceDropdownSelection
                    key={Math.random()}
                    disabled={isDisabled}
                    value={value}
                    setValue={value => {
                      onChange(value);
                      setValue('district', '');
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              rules={{
                required: 'Vui lòng chọn quận/huyện',
              }}
              control={control}
              name="district"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <DistrictDropdownSelection
                    key={Math.random()}
                    onDelete={() => {
                      onChange('');
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                    value={value}
                    setValue={value => {
                      onChange(value);
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                    errorMessage={error?.message}
                    invalid={invalid}
                    provinceId={province}
                    disabled={!province || isDisabled}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              rules={{
                required: 'Vui lòng chọn phường/xã',
              }}
              control={control}
              name="ward"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <WardDropdownSelection
                    key={Math.random()}
                    disabled={!district || isDisabled}
                    onDelete={() => {
                      onChange('');
                      setValue('street', '');
                    }}
                    value={value}
                    setValue={value => {
                      onChange(value);
                    }}
                    errorMessage={error?.message}
                    invalid={invalid}
                    districtId={district}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              rules={{
                required: isTabPublish ? null : 'Vui lòng nhập số nhà',
              }}
              control={control}
              name="houseNumber"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputInnerClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập số nhà"
                    onDelete={() => onChange('')}
                    disabled={isDisabled}
                    maxLength={200}
                    type="text"
                    hideMaxLength={true}
                    onKeyPress={e => {
                      const charCode = e.charCode;
                      if (
                        (charCode < 97 || charCode > 122) &&
                        (charCode < 65 || charCode > 90) &&
                        (charCode < 48 || charCode > 57) &&
                        charCode !== 47 &&
                        charCode !== 45
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="col-span-2">
            <Controller
              rules={{
                required: 'Vui lòng chọn đường',
              }}
              control={control}
              name="street"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <StreetDropdownSelection
                    key={Math.random()}
                    getObject
                    disabled={!district || isDisabled}
                    onDelete={() => {
                      onChange('');
                    }}
                    value={value}
                    setValue={value => {
                      onChange(value.key);
                      setValue('lat', value?.location?.coordinates?.[1] || '');
                      setValue('long', value?.location?.coordinates?.[0] || '');
                    }}
                    errorMessage={error?.message}
                    invalid={invalid}
                    districtId={district}
                    wardId={ward}
                    provinceId={province}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            isShowAdditionalInfo
              ? 'w-full flex flex-1 space-x-1 items-center mt-2'
              : 'hidden'
          }
        >
          <img className="w-16px h-16px" src="/svg/info.svg" alt="Info icon" />
          <span className="text-sm">
            Số nhà chỉ để tham khảo, số nhà sẽ ẩn trên web{' '}
            <b className="font-medium">https://www.realtech247.com</b>
          </span>
        </div>
      </div>
    </div>
  );
}
