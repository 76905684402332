import React, { useContext, useRef } from 'react';
import splitPolygon from 'split-polygon';
import { v4 as uuidV4 } from 'uuid';
import { convertFloatWithDot } from '@utils/helper';
import { ActivePathContext } from '@components/map/utils';

const geoarea = require('geo-area')(/*options*/ { x: 'lng', y: 'lat' });

let ReactLeaflet, CustomMarker, Path;
if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  CustomMarker = require('./customMarker').CustomMarker;
  Path = ReactLeaflet.Polyline;
  // useLeaflet = ReactLeaflet.useLeaflet;
}

type Props = {
  lat: number,
  lng: number,
  distance: number,
  corner: number,
  shouldRotate: boolean,
  points: [{ lat: number, lng: number }],
  polygons?: [{ lat: number, lng: number }],
  color?: string,
};

export default function PathWithDistance({
  lat,
  lng,
  distance,
  corner,
  shouldRotate,
  points,
  polygons,
  color = '#65FF8C',
}: Props) {
  // const { map } = useLeaflet(),
  const [startPoint, endPoint] = points,
    { current: pathId } = useRef(uuidV4()),
    { setActivePath } = useContext(ActivePathContext);
  // isActive = activePath === pathId;

  let isAbove = false;

  if (polygons?.length > 2) {
    const a = (endPoint.lat - startPoint.lat) / (endPoint.lng - startPoint.lng),
      b = startPoint.lat - a * startPoint.lng;
    const parts = splitPolygon(
        polygons.map(e => [e.lng, e.lat]),
        [a, -1, b]
      ),
      aboveIncludeLine = !!(
        parts.positive.find(
          e => e[0] === startPoint.lng && e[1] === startPoint.lat
        ) &&
        parts.positive.find(e => e[0] === endPoint.lng && e[1] === endPoint.lat)
      ),
      // belowIncludeLine = !!(
      //   parts.negative.find(
      //     e => e[0] === startPoint.lng && e[1] === startPoint.lat,
      //   ) &&
      //   parts.negative.find(e => e[0] === endPoint.lng && e[1] === endPoint.lat)
      // ),
      polygonArea = geoarea(polygons).toFixed(2),
      above = geoarea(
        parts.positive.map(e => ({ lat: e[1], lng: e[0] }))
      ).toFixed(2),
      below = geoarea(
        parts.negative.map(e => ({ lat: e[1], lng: e[0] }))
      ).toFixed(2);
    if (polygonArea === above) {
      isAbove = false;
    } else if (polygonArea === below) {
      isAbove = true;
    } else if (aboveIncludeLine) {
      isAbove = false;
    } else {
      isAbove = above > below;
    }
  }

  // const { x: startX, y: startY } = map.latLngToLayerPoint([
  //     startPoint.lat,
  //     startPoint.lng,
  //   ]),
  //   { x: endX, y: endY } = map.latLngToLayerPoint([endPoint.lat, endPoint.lng]);
  // distanceInPixel = Math.sqrt(
  //   Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2),
  // );
  let renderDistance;
  if (distance < 1) {
    const t = (distance * 1000).toFixed(1);
    renderDistance = convertFloatWithDot(t) + ' m';
  } else {
    const t = distance.toFixed(1);
    renderDistance = convertFloatWithDot(t) + ' km';
  }

  return (
    <React.Fragment>
      <Path
        pane="markerPane"
        zIndexOffset={2000}
        riseOnHover
        onMouseOver={() => {
          setActivePath(pathId);
        }}
        onMouseOut={() => {
          setActivePath(null);
        }}
        opacity="1"
        color={
          // distanceInPixel < 100 && isActive ? colors.black22 : colors.gray888
          color
        }
        dashArray="4"
        weight="2"
        positions={points.map(e => [e.lat, e.lng])}
      />
      <CustomMarker zIndexOffset={2000} position={[lat, lng]}>
        <div
          onMouseOver={() => {
            setActivePath(pathId);
          }}
          onMouseLeave={() => {
            setActivePath(null);
          }}
          style={{
            transform: `translateX(-50%) translateY(-50%) rotate(${
              corner - 90 + (shouldRotate ? 180 : 0)
            }deg) translateY(${isAbove ? '70%' : '-70%'})`,
          }}
          className={`bg-white border border-blue-main flex flex-col py-1 px-2 rounded-lg`}
        >
          <p className="text-12 font-medium w-full flex text-center whitespace-nowrap">
            {renderDistance}
          </p>
        </div>
      </CustomMarker>
    </React.Fragment>
  );
}
