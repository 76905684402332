import React, { Suspense, useMemo } from 'react';
import ContentUserDetail from './contentUserDetail';
import HeadLeftUserDetail from './headLeftUserDetail';
import SwitchInner from '@components/switchInner';
import ManageContact from './manageContact';
import { useDeepCompareEffect } from 'react-use';

type Props = {
  data?: Object,
  currentTab?: string,
  setCurrentTab?: Function,
  contractId?: string,
  setContractId?: Function,
  setContract?: Function,
};
const switchTabConfigs = () => {
  return [
    {
      key: '0',
      value: 'Thông tin khách hàng',
    },
    {
      key: '1',
      value: 'Quản lý HĐ  khách hàng sử dụng phần mềm',
    },
  ];
};
export default function LeftUserDetail({
  data,
  currentTab,
  setCurrentTab,
  contractId,
  setContractId,
  setContract,
}: Props) {
  const tabConfigs = switchTabConfigs();
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case '0':
        return <ContentUserDetail data={data} />;
      case '1':
        return (
          <ManageContact
            contractId={contractId}
            setContract={setContract}
            setContractId={setContractId}
            userId={data?.id}
            tab={currentTab}
          />
        );
    }
  }, [data, currentTab, setContractId, contractId]);
  useDeepCompareEffect(() => {
    setContractId('');
  }, [currentTab, setContractId]);
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="flex-1 bg-white rounded-lg"
    >
      <HeadLeftUserDetail data={data} />
      <SwitchInner
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
      </div>
    </div>
  );
}
