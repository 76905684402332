import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import {
  GET_LAND_DETAILS_BY_COORDINATE,
  GET_NEAR_BY_LANDS_BY_LOCATION,
} from '@utils/graphql';
import PlansLayer from '@components/realEstateOnMap/components/plansLayer';
import centroid from 'polygon-centroid';
import Spinner from '@components/spinner';
type Props = {
  lat?: string,
  long?: string,
  onSelectedLand?: Function,
  landId?: string,
};
let ReactLeaflet, FeatureGroup, Polygon, CustomMarker;
if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  FeatureGroup = ReactLeaflet.FeatureGroup;
  Polygon = ReactLeaflet.Polygon;
  CustomMarker = require('../../map/customMarker').CustomMarker;
}
// const polygonCenter = function (arr) {
//   const minX = minBy(arr, (e) => e[0])[0],
//     minY = minBy(arr, (e) => e[1])[1],
//     maxX = maxBy(arr, (e) => e[0])[0],
//     maxY = maxBy(arr, (e) => e[1])[1];
//   return [(minX + maxX) / 2, (minY + maxY) / 2];
// };

export default React.memo(LandInfoLayer, isEqual);

function LandInfoLayer({ lat, long, landId, onSelectedLand }: Props) {
  const [landList, setLandList] = useState([]);
  const { loading } = useQuery(GET_NEAR_BY_LANDS_BY_LOCATION, {
    variables: {
      lat,
      long,
      radius: 500,
      limit: 100,
    },
    onCompleted: data => {
      setLandList(data.getNearByLandsByLocation || {});
    },
    skip: !lat || !long,
  });

  useQuery(GET_LAND_DETAILS_BY_COORDINATE, {
    variables: {
      lat,
      long,
    },
    skip: !lat || !long,
    onCompleted: data => {
      if (data) {
        onSelectedLand(data?.getLandDetailsByCoordinate?.id);
      }
    },
  });

  return (
    <>
      <PlansLayer plans={landList?.plans} />
      {landList?.lands?.map(item => {
        const [[[...coordinates]]] = item.polygon?.coordinates;
        return (
          <Plan
            landId={landId}
            key={item.id}
            item={item}
            listCoordinates={[{ color: item?.color, coordinates }]}
            onClick={onSelectedLand}
          />
        );
      })}
      {loading && (
        <div
          className="absolute bottom-16px left-16px flex"
          style={{ zIndex: 10000 }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

type PlanProps = {
  item?: Object,
  centerPoint?: Array,
  coordinates?: Array,
  listCoordinates?: Array,
  onClick?: Function,
  landId?: string,
};

function Plan({ item, onClick, landId }: PlanProps) {
  const { plans } = item;

  return plans?.map((point, index) => {
    const {
      polygon: { coordinates },
      color,
    } = point;
    const isActive = landId === item.id;

    const centerPoint = centroid(
      coordinates[0].map(e => ({ x: e[1], y: e[0] }))
    );

    const onClickLand = useCallback(() => {
      onClick(item.id);
    }, [item]);

    return (
      <FeatureGroup key={`${item.id} ${index}`}>
        {isActive && (
          <CustomMarker position={[centerPoint.x, centerPoint.y]}>
            <div
              className="flex flex-col items-center"
              style={{
                transform: 'translate(-50%, -100%)',
              }}
            >
              <div
                className="w-32px h-40px rounded-rd50 bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: 'url("/svg/Union.svg")' }}
              >
                <p
                  className="text-15 text-white font-text"
                  style={{
                    transform: 'translate(33%, 30%)',
                  }}
                >
                  {index + 1}
                </p>
              </div>
            </div>
          </CustomMarker>
        )}
        {coordinates.map((item, index) => {
          return (
            <Land
              item={item}
              key={`plan-${index}`}
              isActive={isActive}
              color={color}
              onClick={onClickLand}
            />
          );
        })}
      </FeatureGroup>
    );
  });
}

type LandProps = {
  item?: Array,
  isActive?: boolean,
  color?: string,
  onClick?: Function,
};

function Land({ item = [], isActive, color, onClick }: LandProps) {
  return (
    <FeatureGroup onClick={onClick}>
      <Polygon
        weight={1}
        color="#fff"
        fillColor={color === '#F9BB5F' ? '#CBD5E0' : color}
        fillOpacity={color === '#F9BB5F' ? 0.1 : 0.6}
        positions={[item.map(e => [e[1], e[0]])]}
      />
      {isActive && (
        <Polygon
          weight={2}
          color="#000"
          fillColor={color === '#F9BB5F' ? '#CBD5E0' : color}
          fillOpacity={color === '#F9BB5F' ? 0.1 : 0.6}
          positions={[item.map(e => [e[1], e[0]])]}
        />
      )}
    </FeatureGroup>
  );
}
