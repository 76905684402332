import { ErrorMessage } from '@hookform/error-message';

type Props = {
  errors?: Object,
  name?: string,
  wrapperClass?: string,
};

export default function ErrorForFormHook({
  errors,
  name,
  wrapperClass,
}: Props) {
  return (
    <ErrorMessage
      errors={errors || {}}
      name={name}
      render={({ message }) => {
        return (
          <div
            id="error-input"
            className={`text-sm text-red-400 ${wrapperClass}`}
          >
            {message}
          </div>
        );
      }}
    />
  );
}
