import gql from 'graphql-tag';

export const CREATE_PERMISSION_GROUP = gql`
  mutation createPermissionGroup(
    $name: String!
    $permissions: [PermissionInputType]!
  ) @api(name: users) {
    createPermissionGroup(name: $name, permissions: $permissions) {
      success
      msg
    }
  }
`;
export const UPDATE_PERMISSION_GROUP = gql`
  mutation updatePermissionGroup(
    $id: ObjectID!
    $name: String
    $permissions: [PermissionInputType]
  ) @api(name: users) {
    updatePermissionGroup(id: $id, name: $name, permissions: $permissions) {
      payload {
        id
        name
        permissions {
          id
          name
          configs
        }
      }
    }
  }
`;
