import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { blankState } from './configs';
import Intro from './components/intro';
import GeneralInfo from './components/generalInfo';
import DividingLine from '@components/dividingLine/dividingLine';
import PostDetails from './components/postDetails';
import RenderBottom from './components/bottom';
import qs from 'query-string';
import { useSetFormState } from '@utils/hooks/createB2BRealEstate/useSetFormState';
import { useGetPublishRealEstateType } from '@utils/hooks/createB2BRealEstate/useGetPublishRealEstateType';
import { useSetPublishProductFormState } from '@utils/hooks/manageProduct/useSetPublishProductFormState';
import Captcha from './components/captcha';
export default function PublishProduct() {
  const { id, isEdit } = qs.parse(location.search);
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });
  isEdit === 'true'
    ? useSetPublishProductFormState(id, form.reset)
    : useSetFormState(id, form.reset, true);
  const { authorized, premium } = useGetPublishRealEstateType();
  const [isContinue, setIsContinue] = useState(false);
  const [captchaId, setCaptchaId] = useState();
  const [captchaCode, setCaptchaCode] = useState();
  const [captchaError, setCaptchaError] = useState();

  return (
    <FormProvider {...form}>
      <div
        className="w-full h-full mt-1 bg-white px-4 pt-2.5 space-y-2"
        style={{ marginTop: 62 }}
      >
        <div className="w-full h-96px bg-white flex justify-center items-center relative">
          <div className="flex flex-col space-y-2.5 absolute left-2">
            <b className="font18x text-black-main font-medium">
              Đăng sản phẩm lên web Realtech247
            </b>
            <label className="font14x text-black-main">
              <b>Kho sản phẩm/</b>
              {isEdit === 'true'
                ? 'Chỉnh sửa tin đăng'
                : 'Đăng sản phẩm lên sàn'}{' '}
              {authorized ? ' - Tin ký gửi' : premium ? ' - Tin chính chủ' : ''}
            </label>
          </div>
        </div>
        <Intro />
        <div
          style={{
            backgroundColor: '#f4f4f4',
          }}
          className="w-full h-full rounded-lg px-4 py-7"
        >
          <GeneralInfo isEdit={isEdit} />
          <DividingLine />
          <PostDetails
            isContinue={isContinue}
            setIsContinue={setIsContinue}
            id={id}
            isEdit={isEdit === 'true'}
          />
          {isEdit && (
            <Captcha
              style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px -5px;"
              wrapperStyle="mt-4 bg-white rounded-lg px-4 py-4"
              setCaptchaError={setCaptchaError}
              captchaError={captchaError}
              setCaptchaId={setCaptchaId}
              setCaptchaCode={setCaptchaCode}
            />
          )}
        </div>
        <RenderBottom
          setIsContinue={setIsContinue}
          id={id}
          isEdit={isEdit}
          setCaptchaError={setCaptchaError}
          captchaId={captchaId}
          captchaCode={captchaCode}
        />
      </div>
    </FormProvider>
  );
}
