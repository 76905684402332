import React, { useState } from 'react';
import Label from '@components/label';
import { useUploadImage } from '@utils/hooks/common/useUploadImage';
import { useDropzone } from 'react-dropzone';
import MyButton from '@components/button';
import { getNameUrlFromSever } from '@utils/helper';
import { Controller, useFormContext } from 'react-hook-form';
import ViewFileModal from '../../../../modals/viewFileModal/viewFileModal';
import UploadErrorModal from './UploadErrorModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { DocumentsMaxSize10MB } from 'constant';

type Props = {
  attachments?: Array,
  onChange?: Function,
  isEdit?: boolean,
  maxFile?: number,
  isAddMoreAttachment?: boolean,
  setListAttachmentDeleted?: Function,
  isContinue?: boolean,
  setIsContinue?: Function,
  isDisable?: boolean,
  isDisableRemove?: boolean,
};
const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
type HeaderProps = {
  isHideRemove?: boolean,
};
function RenderHeader({ isHideRemove = false }: HeaderProps) {
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="w-full h-50px flex flex-row items-center overflow-x-auto"
    >
      <div
        className={`w-60px flex h-full px-2.5 text-black-main text-14 font-medium items-center justify-center truncate`}
      >
        STT
      </div>
      <div className={`flex-1 ${headerClass}`}>Tên tài liệu</div>
      <div className={`flex-1 ${headerClass}`}>Tiến trình tải lên</div>
      {!isHideRemove && <div className={`w-183px ${headerClass}`}> </div>}
    </div>
  );
}

type ItemRowProps = {
  index?: number,
  item?: Object,
  onRemove?: Function,
  isHideRemove?: boolean,
};

function ItemRow({
  item,
  index,
  onRemove,
  isHideRemove = false,
}: ItemRowProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="h-50px flex flex-row items-center">
      <div className="flex w-60px h-full items-center justify-center border-t border-gray-300 text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => setShowModal(true)}
        className={`flex-1 overflow-hidden flex flex-row items-center space-x-1 ${headerClass} border-t`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(
            item?.name || item?.originalKey || item?.originalUrl
          )}
        </div>
      </div>
      <div
        style={{ color: item?.loading ? '#80888F' : '#40D124' }}
        className={`flex-1 ${headerClass} border-t`}
      >
        <div className="flex flex-row">
          {item?.loading ? 'Đang tải lên' : 'Hoàn thành'}
        </div>
      </div>
      {!isHideRemove && (
        <div className={`w-183px ${headerClass} border-t justify-center`}>
          <img
            src="/svg/recycle-bin-icon.svg"
            onClick={onRemove}
            className="cursor-pointer"
            alt="Xóa icon"
          />
        </div>
      )}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ViewFileModal fileId={item?.fileId} />
      </MyModalV2>
    </div>
  );
}

type DocumentTableProps = {
  attachments?: Array,
  onRemove?: Function,
  isHideRemove?: boolean,
};
function DocumentTable({
  attachments,
  onRemove,
  isHideRemove = false,
}: DocumentTableProps) {
  return (
    <div className="w-full border border-gray-300 rounded-md overflow-hidden">
      <RenderHeader isHideRemove={isHideRemove} />
      {attachments?.map((item, index) => {
        return (
          <ItemRow
            isHideRemove={isHideRemove}
            onRemove={() => onRemove?.(item?.fileId)}
            item={item}
            key={index}
            index={index}
          />
        );
      })}
    </div>
  );
}

function UploadPanel({
  attachments,
  onChange,
  isEdit,
  maxFile = 6,
  isAddMoreAttachment,
  setListAttachmentDeleted,
  isDisable = false,
  isContinue,
  setIsContinue,
  isDisableRemove = false,
}: Props) {
  const [showUploadErr, setShowUploadErr] = useState(false);
  const { doUploadImage, removeFile } = useUploadImage({
    mediaUrls: attachments,
    setMediaUrls: onChange,
    setOpenModal: () => { },
    isEdit,
    fieldName: 'attachments',
    isAddMoreAttachment,
    setListAttachmentDeleted,
    isContinue,
    setIsContinue,
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'image/*',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'application/xhtml+xml',
      'application/xml',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/octet-stream',
    ],
    multiple: true,
    onDrop: async files => {
      let errorFiles = '';
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.size / DocumentsMaxSize10MB > 1) {
          errorFiles += `${files[i]?.name}, `;
        }
      }
      errorFiles.length > 0 && setShowUploadErr(true);
      files = files.filter(f => f?.size / DocumentsMaxSize10MB < 1);
      if (files?.length + attachments?.length > 6) {
        setShowUploadErr(true);
        return;
      }
      files?.length > 0 && doUploadImage(files);
    },
    disabled: attachments?.length >= maxFile || isDisable,
  });
  return (
    <div className="w-full space-y-7">
      <div className="w-full flex flex-col space-y-4 items-center text-gray-400 text-sm">
        <span className="flex flex-row">
          {`Bấm vào nút "Chọn tệp tải lên" để đăng tải tệp`}
        </span>
        <span className="flex flex-row">
          Lưu ý: Mỗi tệp không quá 10MB, tối đa 6 tệp
        </span>
        <div {...getRootProps({ className: 'dropzone' })} className="w-176px">
          <input {...getInputProps()} style={{ display: 'none' }} />
          <MyButton
            disabled={attachments?.length >= maxFile || isDisable}
            leftComponent={() => (
              <img src="/svg/plus-blue-icon.svg" alt="Thêm icon" />
            )}
            type="other"
            label="Chọn tệp tải lên"
          />
        </div>
      </div>
      {attachments?.length > 0 && (
        <DocumentTable
          isHideRemove={isDisableRemove}
          onRemove={removeFile}
          attachments={attachments}
        />
      )}
      <MyModalV2 onClose={() => setShowUploadErr(false)} open={showUploadErr}>
        <UploadErrorModal isDoc onClose={() => setShowUploadErr(false)} />
      </MyModalV2>
    </div>
  );
}

type ContractDocumentsProps = {
  isHideTopLabel?: boolean,
  label?: string,
  isShowCountFile?: boolean,
  wrapperClass?: string,
  isEdit?: boolean,
  style?: Object,
  labelStyle?: Object,
  renderText?: Function,
  setListAttachmentDeleted?: Function,
  isAddMoreAttachment?: boolean,
  isDisableUpload?: Boolean,
  isDisableRemove?: Boolean,
  isContinue?: boolean,
  setIsContinue?: Function,
};
export default function ContractDocuments({
  isHideTopLabel = false,
  label = 'Thêm tài liệu đính kèm cho nhân viên',
  isShowCountFile = false,
  wrapperClass,
  isEdit,
  style,
  labelStyle,
  renderText,
  isAddMoreAttachment,
  setListAttachmentDeleted,
  isDisableUpload,
  isContinue,
  setIsContinue,
  isDisableRemove = false,
}: ContractDocumentsProps) {
  const { control, watch } = useFormContext();
  const attachments = watch('attachments');
  return (
    <div
      style={style}
      className={`flex flex-1 rounded-lg py-5 mt-4 space-x-6 ${wrapperClass}`}
    >
      <div className="w-full space-y-6 ">
        {!isHideTopLabel && (
          <div className="font-medium text-base text-blue-main">
            Tài liệu đính kèm ({attachments?.length}/6)
          </div>
        )}
        <Label
          labelStyle={labelStyle}
          wrapperClass="w-full"
          label={`${label} ${isShowCountFile ? `(${attachments?.length}/6)` : ''
            }`}
        />
        {renderText && renderText()}
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Controller
            control={control}
            name="attachments"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full space-y-4">
                  <UploadPanel
                    isDisableRemove={isDisableRemove}
                    isContinue={isContinue}
                    setIsContinue={setIsContinue}
                    isDisable={isDisableUpload}
                    isAddMoreAttachment={isAddMoreAttachment}
                    setListAttachmentDeleted={setListAttachmentDeleted}
                    isEdit={isEdit}
                    onChange={onChange}
                    attachments={value}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
