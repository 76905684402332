import styles from './datePicker.module.scss';
import { getListDaysInMonth } from '../../utils/helper';
import moment, { Moment } from 'moment/moment';
import React, { useCallback, useState } from 'react';
import MyDropdown from '@components/myDropdown';
import Selector from '@components/selector';

const dateOfWeek = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];

const dateFormat = 'DD-MM-YYYY';

const dateFormatCompare = 'YYYY-MM-DD';

let monthList = [],
  yearList = [];
for (let i = 1; i <= 12; i += 1) {
  monthList.push(i);
}

for (let i = moment().get('year'); i >= 1940; i -= 1) {
  yearList.push(i);
}
type Props = {
  close?: Function,
  context?: {
    value?: Moment,
    setValue?: Function,
    maxDate?: string,
    minDate?: string,
  },
};

export default function DatePicker(props: Props) {
  const {
    close,
    context: { value, setValue, maxDate, minDate },
  } = props;

  const [month, setMonth] = useState(value.get('month') + 1),
    [year, setYear] = useState(value.get('year'));

  const listDays = getListDaysInMonth(month, year);

  const onSelectTime = useCallback(
    nextTime => {
      setValue(moment(nextTime, dateFormat));
      close();
    },
    [close, setValue]
  );
  const increaseMonth = useCallback(() => {
    setMonth(cr => {
      if (cr === 12) {
        setYear(year + 1);
        return 1;
      } else {
        return cr + 1;
      }
    });
  }, [setMonth, setYear]);

  const decreaseMonth = useCallback(() => {
    setMonth(cr => {
      if (cr === 1) {
        setYear(year - 1);
        return 12;
      } else {
        return cr - 1;
      }
    });
  }, [setMonth, setYear, year]);
  return (
    <div className={styles.container}>
      <div className={styles.calendarContainer}>
        <div className="w-full flex flex-row mb-4 items-center">
          <p className="font-medium text-base flex items-center space-x-1">
            Tháng
            <div className="ml-1">
              <MyDropdown
                zIndex={1501}
                DropdownComponent={Selector}
                dropdownContext={{
                  selections: monthList,
                  value: month,
                  setValue: setMonth,
                }}
              >
                <div className="flex flex-row items-center space-x-1">
                  <div className="text-base font-medium">{month}</div>
                  <img alt="icon" src="/svg/arrow.svg" className="w-4 h-4" />
                </div>
              </MyDropdown>
            </div>
            <MyDropdown
              zIndex={1501}
              DropdownComponent={Selector}
              dropdownContext={{
                selections: yearList,
                value: year,
                setValue: setYear,
              }}
            >
              <div className="flex flex-row items-center space-x-1">
                <div className="text-base font-medium">{year}</div>
                <img alt="icon" src="/svg/arrow.svg" className="w-4 h-4" />
              </div>
            </MyDropdown>
          </p>
          <div className="flex-1" />
          <button
            className={`${styles.buttonChangeMonth} mr-2`}
            onClick={decreaseMonth}
          >
            <img
              src="/svg/arrow.svg"
              className="w-4 h-4 transform rotate-90"
              alt="ico-arrow"
            />
          </button>
          <button
            onClick={increaseMonth}
            className={`${styles.buttonChangeMonth} transform -rotate-90`}
          >
            <img src="/svg/arrow.svg" className="w-4 h-4" alt="ico-arrow" />
          </button>
        </div>
        <div className="grid gap-2 grid-cols-2 grid-cols-7">
          {dateOfWeek.map(item => {
            return (
              <div
                key={item}
                className={`${styles.itemDate} font-medium text-sm font-medium`}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className="grid gap-2 grid-cols-2 grid-cols-7 mt-2">
          {listDays.map((item, index) => {
            return (
              <ItemDate
                date={value}
                month={month}
                year={year}
                key={index}
                item={item}
                onClick={() => onSelectTime(item)}
                maxDate={maxDate}
                minDate={minDate}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

type ItemDateProps = {
  item?: string,
  date?: string,
  year?: number,
  month?: number,
  maxDate?: string,
  minDate?: string,
};

function ItemDate({
  item,
  date,
  month,
  year,
  maxDate,
  minDate,
  ...rest
}: ItemDateProps) {
  const dateSelected = moment(
    moment(date, dateFormat).format(dateFormatCompare)
  ).isSame(moment(item, dateFormat).format(dateFormatCompare));
  const today = moment(moment().format(dateFormatCompare)).isSame(
    moment(item, dateFormat).format(dateFormatCompare)
  );
  const dateOfCurrentMonth = moment(
    moment(`${year}-${month}`, 'YYYY-MM').format('YYYY-MM')
  ).isSame(moment(item, dateFormat).format('YYYY-MM'));
  const disableMaxDate =
    maxDate && moment(item, 'DD-MM-YYYY').diff(moment(maxDate)) > 0;
  const disableMinDate =
    minDate && moment(minDate).isAfter(moment(item, 'DD-MM-YYYY'), 'dates');
  return (
    <button
      disabled={disableMaxDate || disableMinDate}
      {...rest}
      className={`${styles.itemDate} ${today && styles.today} ${
        dateSelected && styles.dateSelected
      } ${
        (!dateOfCurrentMonth || disableMaxDate || disableMinDate) &&
        'text-gray-400'
      } ${
        disableMaxDate || disableMinDate ? 'pointer-events-none' : undefined
      }`}
    >
      {moment(item, dateFormat).get('date')}
    </button>
  );
}
