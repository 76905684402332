import { genders } from '../../../scenes/home/createB2BUserAccount/configs';
import moment from 'moment/moment';

export const useConvertDataUserEditHistory = (
  item = {},
  setShowModal = () => {},
  setShowAttachmentModal = () => {},
  isPersonal
) => {
  return [
    {
      key: 'companyName',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('companyName'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      isHidden: isPersonal,
      key: 'taxIdOrIdNumber',
      value: () => {
        const foundValue = item.ops.find(e =>
          e.path.includes('taxIdOrIdNumber')
        )?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'companyProvince',
      value: () => {
        const foundValue = item.ops.find(e =>
          e.path.includes('companyProvince')
        )?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'companyDistrict',
      value: () => {
        const foundValue = item.ops.find(e =>
          e.path.includes('companyDistrict')
        )?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'companyWard',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('companyWard'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'companyStreet',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('companyStreet'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'companyHouseNumber',
      value: () => {
        const foundValue = item.ops.find(e =>
          e.path.includes('companyHouseNumber')
        )?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'fullName',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('fullName'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'province',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('province'))
          ?.changes?.value?.name;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'district',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('district'))
          ?.changes?.value?.name;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'ward',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('ward'))?.changes
          ?.value?.name;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'street',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('street'))
          ?.changes?.value?.name;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'houseNumber',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('houseNumber'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'phone',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('phone'))?.changes
          ?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'secondPhone',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('secondPhone'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'gender',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('gender'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return genders.find(e => e.key === foundValue)?.value;
      },
    },
    {
      key: 'dateOfBirth',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('dateOfBirth'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${moment(foundValue).format('DD/MM/YYYY')}`;
      },
    },
    {
      key: 'idNumber',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('idNumber'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'idImages',
      value: () => {
        const foundValue = item.ops
          .filter(e => e.path.includes('idImages'))
          .reduce((cr, item) => {
            const value = item?.changes?.value;
            const valueIsArray = Array.isArray(value);
            if (valueIsArray) {
              return [...cr, ...value];
            } else {
              return [...cr, value];
            }
          }, []);
        if (!foundValue?.length) {
          return '-';
        }
        return (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="flex justify-center w-full"
          >
            <img src="/svg/icon_camera.svg" />
          </button>
        );
      },
    },
    {
      key: 'attachments',
      value: () => {
        const foundValue = item.ops
          .filter(e => e.path.includes('attachments'))
          .reduce((cr, item) => {
            const value = item?.changes?.value;
            const valueIsArray = Array.isArray(value);
            if (valueIsArray) {
              return [...cr, ...value];
            } else {
              return [...cr, value];
            }
          }, []);
        if (!foundValue?.length) {
          return '-';
        }
        return (
          <button
            onClick={() => {
              setShowAttachmentModal(true);
            }}
            className="flex justify-center items-center w-full flex-col"
          >
            <img src="/svg/icon-file.svg" />
            <div className="border-b border-blue-900 w-20px" />
          </button>
        );
      },
    },
    {
      key: 'gender',
      value: () => {
        const foundValue = item.ops.find(e => e.path.includes('gender'))
          ?.changes?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
  ];
};
