import React from 'react';

type ItemProps = {
  title?: string,
  value?: string,
  valueClassName?: string,
  hideField?: boolean,
};
export default function ItemRowUserDetail({
  title,
  value = '',
  valueClassName,
  hideField,
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex-1 flex w-full py-2 border-b border-gray-300 space-x-10">
      <div className="text-sm mr-6 font-medium text-black-main w-120px">
        {title}
      </div>
      <div className={`text-sm ${valueClassName}`}>{value}</div>
    </div>
  );
}
