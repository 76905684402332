/* eslint-disable prettier/prettier */
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { getAuth } from '@utils/helper';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { GlobalContext } from 'context';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from 'scenes/loadingScreen';

const Layout = () => {
    const token = getAuth();
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [id, setId] = useState('');
    const location = useLocation();
    const { permissions, loading } = useGetMe();

    useEffect(() => {
        if (!token) {
            setIsLogin(false);
            window.location.href = `${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`;
        } else {
            setIsLogin(true);
            if (location.pathname === '/') {
                if (permissions?.some(p => p.id === PermissionIds.ViewEmployeeList)) {
                    navigate('/employee/company');
                } else if (
                    permissions?.some(p => p.id === PermissionIds.ViewProductList)
                ) {
                    navigate('/manageProduct/product');
                } else {
                    navigate('/dashboard');
                }
            }
        }
    }, [token]);

    return (
        <GlobalContext.Provider
            value={useMemo(
                () => ({ isLogin, setIsLogin, id, setId }),
                [isLogin, setIsLogin, id, setId]
            )}
        >
            {loading ? (
                <LoadingScreen />
            ) : <Outlet />}
        </GlobalContext.Provider>
    );
}
export default Layout;