import styles from '../contractDetailModal/contractDetailModal.module.scss';
import MyButton from '@components/button';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Content from './components/content';
import { useDeepCompareEffect } from 'react-use';
import { useUpdateContractNoteAndAttachments } from '@utils/hooks/manageContract/useUpdateContractNoteAndAttachments';

type Props = {
  onClose?: Function,
  listAttachment?: Array,
  contractId?: string,
  note?: string,
};
export default function AdditionalContractModal({
  onClose,
  listAttachment,
  contractId,
  note,
}: Props) {
  const form = useForm({
    defaultValues: {
      note: '',
      attachments: [],
    },
    mode: 'onChange',
  });

  useDeepCompareEffect(() => {
    form.reset({
      note,
      attachments: listAttachment,
    });
  }, []);
  const { onUpdate, loading } = useUpdateContractNoteAndAttachments({
    contractId,
    state: form.watch(),
    onClose,
  });
  return (
    <FormProvider {...form}>
      <div className="w-735px bg-white rounded-lg px-6">
        <div className="relative pt-6 pb-8 flex justify-center items-center">
          <h1 className="text-[16px] leading-[20px] font-medium">Bổ sung hợp đồng</h1>
          <button type="button" onClick={onClose}>
            <img
              style={{ zIndex: 1000 }}
              alt="ico"
              src="/svg/ico-close.svg"
              className="w-6 h-6 absolute right-0 top-6"
            />
          </button>
        </div>
        <Content />
        <div className="w-full h-full sticky bottom-0 bg-white">
          <div className="w-full h-60px flex justify-end items-center border-t border-gray-300 sticky bottom-0 space-x-2">
            <div className="w-147px">
              <MyButton onPress={onClose} type="gray" label="Trở về" />
            </div>
            <div className="w-147px">
              <MyButton
                disabled={loading}
                onPress={onUpdate}
                type="blue"
                label="Xác nhận"
              />
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
