import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { pickBy, omit } from 'lodash';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MyToast from '@components/MyToast';
import { CREATE_EMPLOYEE_ACCOUNT } from '@utils/graphql/mutations/employee';
export const useCreateEmployee = () => {
  const [createEmployee, { loading, error, client }] = useMutation(
    CREATE_EMPLOYEE_ACCOUNT
  );
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async state => {
      try {
        await createEmployee({
          variables: {
            inputData: pickBy(
              {
                ...omit(state, 'confirmPassword'),
                avatar: state?.avatar?.fileId,
                idImages: state?.idImages?.map(e => e.fileId),
                attachments: state?.attachments?.map(e => e.fileId),
                dateOfBirth: state?.dateOfBirth
                  ? moment.unix(state?.dateOfBirth).toJSON()
                  : null,
              },
              e => e || e === false || e === null
            ),
          },
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Tạo nhân viên thành công!"
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['b2bGetEmployees'], client.cache);
        await navigate('/employee/company');
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [createEmployee, client, navigate]
  );
  return { onSubmit, loading, error };
};
