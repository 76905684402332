import { useQuery } from '@apollo/client';
import { GET_EMPLOYEE_DETAILS } from '@utils/graphql';

export const useGetEmployeeDetails = id => {
  const { data, loading } = useQuery(GET_EMPLOYEE_DETAILS, {
    variables: {
      id,
    },
    skip: !id,
  });
  const finalData = data?.b2bGetUserInfo;
  return { data: finalData, loading: loading };
};
