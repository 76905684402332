import React, { useState } from 'react';
import { View } from 'react-native-web';
import { useManageUserB2BContext } from '../context';
import LeftUserDetail from './components/leftUserDetail';
import RightUserDetail from './components/rightUserDetail';
import { useGetUserInfo } from '@utils/hooks/manageUserB2B/useGetUserDetail';

export default function UserDetail() {
  const { listSelected } = useManageUserB2BContext();
  const { data } = useGetUserInfo(listSelected?.[0]);
  const [currentTab, setCurrentTab] = useState('0');
  const [contractId, setContractId] = useState('');
  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftUserDetail
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        contractId={contractId}
        setContractId={setContractId}
        data={data}
      />
      <RightUserDetail />
    </View>
  );
}
