import { useCallback, useState } from 'react';
import PermissionConfigs from 'scenes/home/manageEmployee/listEmployee/userDetail/components/leftUserDetail/userPermission/components/PermissionConfigs';
import PropTypes from 'prop-types';
import MyModalV2 from '@components/modalV2/myModalV2';
import ListTypeModal from 'scenes/home/manageEmployee/listEmployee/userDetail/components/leftUserDetail/userPermission/modal/ListTypeModal';
import { useList } from 'react-use';

BottomRightPermissionDetail.propTypes = {
  isEdit: PropTypes.bool,
  permissions: PropTypes.array,
  assignedPermissions: PropTypes.array,
};
function BottomRightPermissionDetail({
  isEdit,
  permissions,
  assignedPermissions,
}) {
  const [errors, setErrors] = useState({});
  const [modalError] = useState(false);
  const [id, setId] = useState();
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [showRegionModal, setShowRegionModal] = useState(false);

  const [type, setType] = useList([]);

  const triggers = {
    selectRealEstateType: useCallback(
      id => {
        setId(id);
        setShowTypeModal(true);
      },
      [id, setShowTypeModal]
    ),
    selectDepartment: useCallback(
      id => {
        setId(id);
        setShowDepartmentModal(true);
      },
      [id, setShowDepartmentModal]
    ),
    selectRealEstateRegion: useCallback(
      id => {
        setId(id);
        setShowRegionModal(true);
      },
      [id, setShowRegionModal]
    ),
  };

  return (
    <div className="flex-1 max-w-50% rounded-rd8px bg-white px-4 py-8 shadow-md">
      <PermissionConfigs
        modalError={modalError}
        errors={errors}
        setErrors={setErrors}
        assignedPermissions={assignedPermissions}
        isEdit={isEdit}
        selectedPermissions={permissions}
        triggers={triggers}
        emptyText="Vui lòng chọn quyền ở phần “Phân quyền” trước."
      />
      <MyModalV2 open={showTypeModal} onClose={() => setShowTypeModal(false)}>
        <ListTypeModal
          id={id}
          selectedItems={type}
          setItems={setType}
          onClose={() => setShowTypeModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showDepartmentModal}
        onClose={() => setShowDepartmentModal(false)}
      ></MyModalV2>
      <MyModalV2
        open={showRegionModal}
        onClose={() => setShowRegionModal(false)}
      ></MyModalV2>
    </div>
  );
}

export default BottomRightPermissionDetail;
