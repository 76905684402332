import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyTextAreaInput from '@components/textAreaInput';
import Label from '@components/label';
import Section from '@components/section';
type Props = {
  fieldName?: string,
  headerText?: string,
  isShowTips?: boolean,
};
export default function Note({
  fieldName = 'note',
  headerText = 'Ghi chú công ty',
  isShowTips = false,
}: Props) {
  const { control } = useFormContext();
  return (
    <Section>
      <div className="flex flex-row">
        <span className="w-152px text-base text-blue-main font-medium">
          {headerText}
        </span>
        <div
          className={
            isShowTips
              ? `flex flex-1 space-x-1 items-center justify-end`
              : 'hidden'
          }
        >
          <img className="w-16px h-16px" src="/svg/info.svg" alt="Info icon" />
          <span className="text-sm font-normal">
            Ghi chú cá nhân sẽ được ẩn đi trên web{' '}
            <b className="font-medium">https://www.realtech247.com</b>
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row space-x-2.5 items-start mt-7">
        <Label wrapperClass="w-152px" label="Ghi chú" />
        <Controller
          control={control}
          name={fieldName}
          render={({ field, field: { onChange } }) => {
            return (
              <MyTextAreaInput
                {...field}
                inputClassName="flex-1"
                placeholder="Chỉ có bạn mới được xem những ghi chú này"
                onDelete={() => onChange('')}
                rows={3}
                maxlength={888}
              />
            );
          }}
        />
      </div>
    </Section>
  );
}
