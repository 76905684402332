import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { useLeaflet } from 'react-leaflet';
import FreeDraw from '@gugotech/leaflet-freedraw';

type Props = {
  onPolygonCreated: Function,
};

export default React.forwardRef(DrawingLayer);

function DrawingLayer({ onPolygonCreated }: Props, ref) {
  const { map } = useLeaflet(),
    freedrawRef = useRef();
  useEffect(() => {
    const freeDraw = new FreeDraw({
      mode: 1,
    });
    freedrawRef.current = freeDraw;
    map.addLayer(freeDraw);
    freeDraw.on('markers', ({ latLngs }) => {
      onPolygonCreated && onPolygonCreated(latLngs[0] || []);
      if (latLngs[0]?.length) {
        freeDraw.mode(0);
      }
    });
    return () => {
      freeDraw.clear();
      freedrawRef.current?.mode(0);
      map.removeLayer(freeDraw);
    };
  }, [onPolygonCreated]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      freedrawRef.current?.mode(1);
      freedrawRef.current?.clear();
    },
    clear: () => {
      freedrawRef.current?.clear();
    },
  }));

  return null;
}
