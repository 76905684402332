import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_PROJECT_DETAILS } from '@utils/graphql';

export const useGetProjectDetails = (id, isBasic, isDeleted) => {
  const { data, loading } = useQuery(GET_REAL_ESTATE_PROJECT_DETAILS, {
    variables: {
      id,
      isBasic,
      isDeleted,
    },
    skip: !id,
  });
  return { data: data?.getRealEstateProjectDetails, loading };
};
