import React, { Suspense, useMemo } from 'react';
import { useDeepCompareEffect } from 'react-use';

type Props = {
  data?: Object,
  currentTab?: string,
  setCurrentTab?: Function,
  contractId?: string,
  setContractId?: Function,
  setContract?: Function,
};
export default function LeftUserDetail({
  data,
  currentTab,
  contractId,
  setContractId,
}: Props) {
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
    }
  }, [data, currentTab, setContractId, contractId]);
  useDeepCompareEffect(() => {
    setContractId('');
  }, [currentTab, setContractId]);
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="flex-1 bg-white rounded-lg"
    >
      <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
      </div>
    </div>
  );
}
