import { useQuery } from '@apollo/client';
import { GET_USER_B2B } from '@utils/graphql';
import { useLoadMore } from '../common/useLoadMore';
import { useState } from 'react';
import moment from 'moment/moment';
import pickBy from 'lodash/pickBy';
import { useGlobalContext } from '../../../context';
import isEmpty from 'lodash/isEmpty';

export const useGetListB2BAdmin = ({ state }) => {
  const { isLogin } = useGlobalContext();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = pickBy(
    {
      employeeCode: state?.employeeCode,
      fullName: state?.fullName,
      province: state?.province,
      district: state?.district,
      ward: state?.ward,
      street: state?.street,
      houseNumber: state?.houseNumber,
      email: state?.email,
      createdAt:
        !state.createdAtFrom || !state.createdAtTo
          ? null
          : {
              from: moment.unix(state.createdAtFrom).format('YYYY-MM-DD'),
              to: moment
                .unix(state.createdAtTo)
                .endOf('day')
                .format('YYYY-MM-DD'),
            },
      sort: { createdAt: -1 },
      isPersonal:
        state?.userType?.length === 2 || isEmpty(state?.userType)
          ? null
          : state?.userType[0] === 'yes',
    },
    e => e || e === false
  );
  const finalVariables = {
    ...variables,
    page: 1,
    limit: 10,
  };
  const { data, fetchMore, loading } = useQuery(GET_USER_B2B, {
    variables: {
      ...finalVariables,
    },
    skip: !isLogin,
  });
  const listUser = data?.boGetListB2BAdmin?.items || [];
  const hasNextPage = data?.boGetListB2BAdmin?.hasNextPage;
  const page = data?.boGetListB2BAdmin?.page || 1;
  const total = data?.boGetListB2BAdmin?.total;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });

  return {
    listUser: loading ? [1, 2, 3] : listUser || [],
    loading: loading,
    isLoadingMore,
    onLoadMore,
    hasNextPage,
    page,
    total,
  };
};
