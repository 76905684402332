import React, { useState } from 'react';
import Section from './section';
import RealEstateLocationOnMapModal from '@components/realEstateLocationOnMapModal';

type Props = {
  lat?: number,
  long?: number,
};
export default function LocationRealEstate({ lat, long }: Props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Section
        title="Vị trí bất động sản"
        source="/svg/locationDetail.svg"
        renderRightComponent={() => {
          return (
            <div
              className="flex items-center space-x-1 cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img
                alt="ico"
                src="/svg/eye_fill.svg"
                className="w-22px h-22px"
              />
              <div className="text-sm text-blue-main">Xem trên bản đồ</div>
            </div>
          );
        }}
      />
      <RealEstateLocationOnMapModal
        lat={lat}
        long={long}
        open={showModal}
        onClose={() => setShowModal(!showModal)}
      />
    </>
  );
}
