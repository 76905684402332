import Section from '@components/section';
import { useForm, FormProvider } from 'react-hook-form';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_ATTACHMENTS } from '@utils/graphql';
import { getMedias, getNameUrlFromSever } from '@utils/helper';
import { useDeepCompareEffect, useList } from 'react-use';
import Media from '../../scenes/home/createB2BRealEstate/components/media';
import React from 'react';
import ContractDocuments from '../../scenes/home/createB2BUserAccount/components/contractDocuments';
import MyButton from '@components/button';
import { useUpdateAttachment } from '@utils/hooks/manageProduct/useUpdateAttachment';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type Props = {
  data?: Object,
  open?: boolean,
  onClose?: Function,
  realEstateId?: string,
  mediaUrls?: Array,
};
export default function AddMoreMediaModal({
  data,
  open,
  onClose,
  realEstateId,
  mediaUrls,
}: Props) {
  const form = useForm({
    defaultValues: {
      mediaUrls: [],
      attachments: [],
    },
    mode: 'onChange',
  });
  const { data: dataAttachments } = useQuery(GET_PRODUCT_ATTACHMENTS, {
    variables: {
      realEstateId,
    },
    skip: !realEstateId,
  });
  const attachmentMedia = dataAttachments?.getProductAttachments?.attachments;
  const [listAttachmentDeleted, setListAttachmentDeleted] = useList([]);
  const { permissions } = useGetMe();
  const { onUpdateRealEstateProduct, loading } = useUpdateAttachment(
    realEstateId,
    listAttachmentDeleted,
    onClose,
    data
  );
  const { handleSubmit } = form;
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const medias = await getMedias(mediaUrls || []);
        const attachmentsConverted = await getMedias(attachmentMedia || []);
        const newArray = attachmentsConverted.map(
          ({ fileId, originalUrl, type, urls }) => ({
            fileId: fileId,
            name: getNameUrlFromSever(originalUrl),
            type: type,
            urls: urls,
          })
        );
        form.reset({
          mediaUrls: medias,
          attachments: newArray,
        });
      } catch (e) {
        console.log(e);
      }
    })();
  }, [attachmentMedia, mediaUrls]);
  return (
    <MyModalV2 open={open} onClose={onClose}>
      <FormProvider {...form}>
        <div
          style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
          className="w-670px h-5/6 overflow-auto rounded-lg"
        >
          <div className="flex-1 bg-white p-3">
            <div className="text-base font-medium text-blue-main text-center sticky">
              Bổ sung tài liệu sản phẩm
            </div>
            <div className="w-full space-y-7">
              <Media isEdit />
              <Section>
                <ContractDocuments
                  isDisableUpload={
                    !permissions?.some(
                      p => p.id === PermissionIds.AddProductAttachments
                    )
                  }
                  isDisableRemove={
                    !permissions?.some(
                      p => p.id === PermissionIds.DeleteProductAttachments
                    )
                  }
                  isAddMoreAttachment
                  setListAttachmentDeleted={setListAttachmentDeleted}
                  isEdit
                  isHideTopLabel
                  isShowCountFile
                  label="Tài liệu đính kèm"
                  style={{ padding: 0, marginTop: 0 }}
                  labelStyle={{ color: '#2459ad', fontSize: 16 }}
                  renderText={() => {
                    return (
                      <div className="text-sm font-medium text-black-main">
                        Thêm tài liệu đính kèm cho sản phẩm
                      </div>
                    );
                  }}
                />
              </Section>
            </div>
          </div>
          <div className="w-full p-3 border-t border-gray-300 bg-white flex items-center justify-end space-x-4 sticky bottom-0">
            <MyButton
              disabled={loading}
              onPress={onClose}
              style={{ width: 140 }}
              type="gray"
              label="Trở về"
            />
            <MyButton
              disabled={loading}
              onPress={handleSubmit(onUpdateRealEstateProduct)}
              style={{ width: 140 }}
              type="blue"
              label="Xác nhận"
            />
          </div>
        </div>
      </FormProvider>
    </MyModalV2>
  );
}
