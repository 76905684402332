import MyButton from '@components/button';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import moment from 'moment';
import RenderAvatarComponent from 'scenes/home/manageProduct/components/renderAvatarComponent';
import AttachmentsTable from './attachments';
import FilesResult from '@components/uploadMedia/components/fileResult';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';

type Props = {
  data?: Object,
  onClose?: Function,
};
export default function HistoryDetailModal({ data, onClose }: Props) {
  const type = data?.historyType;
  const [listUrls, setListUrls] = useState(data?.attachments);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(listUrls);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [listUrls]);
  return (
    <div
      className="w-610px h-full bg-white rounded-lg shadow-md p-4"
      style={{ minHeight: 368 }}
    >
      <span className="text-15 font-bold font-text text-blue-main mt-2 justify-center flex">
        Chi tiết lịch sử
      </span>
      <div className="bg-blue-history mt-8 rounded h-full">
        <Item
          title="Loại lịch sử"
          value={
            type === 'rejectedProduct'
              ? 'Từ chối sản phẩm'
              : type === 'addDocumentationProduct'
              ? 'Bổ sung tài liệu đính kèm'
              : 'Xóa tài liệu đính kèm'
          }
        />
        <Item
          title={
            type === 'rejectedProduct'
              ? 'Người từ chối'
              : type === 'addDocumentationProduct'
              ? 'Người chỉnh sửa'
              : 'Người xóa'
          }
          value={
            <div className="flex space-x-2">
              <RenderAvatarComponent
                avatarId={
                  data?.ops?.find(e => e.path === '/rejectedBy')?.value
                    ?.avatar || data?.userId?.avatar
                }
                wrapperStyle="w-40px h-40px"
              />
              <div className="flex flex-1 space-x-0.5 pt-2">
                <MyDropdown
                  zIndex={1500}
                  popperPlacement="right"
                  DropdownComponent={UserInfo}
                  dropdownContext={{
                    userId:
                      data?.ops?.find(e => e.path === '/rejectedBy')?.value
                        ?.id || data?.userId?.id,
                  }}
                >
                  <button className="flex items-start space-x-1">
                    <span
                      className="font-text font-medium underline whitespace-nowrap"
                      style={{ color: '#106FFF', fontSize: 14 }}
                    >
                      {data?.ops?.find(e => e.path === '/rejectedBy')?.value
                        ?.fullName || data?.userId?.fullName}
                    </span>
                    <img
                      src="/svg/eye.svg"
                      style={{ minWidth: 13, height: 20 }}
                    />
                  </button>
                </MyDropdown>
              </div>
            </div>
          }
        />
        <Item
          title={
            type === 'rejectedProduct'
              ? 'Thời điểm từ chối'
              : type === 'addDocumentationProduct'
              ? 'Thời điểm chỉnh sửa'
              : 'Thời điểm xóa'
          }
          value={
            type === 'rejectedProduct'
              ? moment(
                  data?.ops?.find(e => e.path === '/rejectedAt')?.value
                ).format('hh:mm A, DD/MM/YYYY')
              : moment(data?.date).format('hh:mm A, DD/MM/YYYY')
          }
        />
        {type === 'rejectedProduct' && (
          <Item
            title="Lý do từ chối"
            value={
              data?.ops?.find(e => e.path === '/approveStatus/reason')?.value
            }
          />
        )}
      </div>
      {type !== 'rejectedProduct' && (
        <div className="flex-col mt-8 rounded-lg space-y-8">
          <FilesResult readOnly={true} imageUrls={listUrls} />
          <AttachmentsTable attachments={listUrls} />
        </div>
      )}
      <div className="w-full border-t border-gray-300 mt-7"></div>
      <div className="flex justify-end mt-3 space-y-2">
        <div>
          <MyButton
            style={{ width: 160 }}
            label="Đóng"
            onPress={onClose}
            type="blue"
          />
        </div>
      </div>
    </div>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  valueStyle?: Object,
  hideField?: boolean,
};

function Item({ title, value = '', valueStyle, hideField }: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex w-full py-3 border-b border-gray-200">
      <div className="flex items-center space-x-2">
        <div className={`text-14 ml-4 font-bold text-black-main w-200px`}>
          {title}
        </div>
      </div>
      <div className={`text-sm text-left flex mr-4 ${valueStyle}`}>{value}</div>
    </div>
  );
}
