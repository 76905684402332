import React, { useState, useEffect } from 'react';
import { View } from 'react-native-web';
import { useProductContext } from '../product/context';
import LeftProductDetail from './components/leftProductDetail';
import RightProductDetail from './components/rightProductDetail';
import { useGetInternalRealEstateDetail } from '@utils/hooks/manageProduct/useGetInternalRealEstateDetails';
import { useGetRealEstateDetailV2 } from '@utils/hooks/manageRealEstate/useGetRealEstateDetailV2';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import { useSwitchTabConfigs } from './configs';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';

type ProductDetailProps = {
  tab?: string,
  demand?: string,
  id?: string,
};
export default function ProductDetail({ tab, id }: ProductDetailProps) {
  const { permissions } = useGetMe();
  const { listSelected, previousId } = useProductContext();
  const [sameButton, setSameButton] = useState(false);
  const [idSameProduct, setIdSameProduct] = useState('');
  let info = {};
  const { productPosted } = useGetTab();
  if (!productPosted) {
    const { data } = useGetInternalRealEstateDetail(id || listSelected?.[0]);
    info = data?.getInternalRealEstateDetails;
  } else {
    const { data2 } = useGetRealEstateDetailV2(id || listSelected?.[0]);
    info = data2?.getRealEstateDetails;
  }
  useEffect(() => {
    if (listSelected[0] !== previousId) {
      setSameButton(false);
    }
  }, [listSelected, previousId]);

  const [total, setTotal] = useState(0);
  let tabConfigs = useSwitchTabConfigs(info, total);
  permissions?.some(p => p.id === PermissionIds.ViewProductNote)
    ? tabConfigs
    : (tabConfigs = tabConfigs?.filter(p => p.key !== 'comment'));
  const [currentTab, setCurrentTab] = useState(tabConfigs[0].key);
  return (
    <View
      style={{
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftProductDetail
        tabConfigs={tabConfigs}
        total={total}
        setTotal={setTotal}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        data={info}
        tab={tab}
        setSameButton={setSameButton}
        setIdSameProduct={setIdSameProduct}
      />
      {!id && (
        <>
          <RightProductDetail
            setCurrentTab={setCurrentTab}
            realEstateId={info?.id}
            data={info}
            sameButton={sameButton}
            idSameProduct={idSameProduct}
          />
        </>
      )}
    </View>
  );
}
