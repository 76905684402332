import MyButton from '@components/button';
import React from 'react';

type Props = {
  onPressREAround?: Function,
  onPressPlanning?: Function,
  deleteDraw?: Function,
  showDeleteDraw?: boolean,
  setMode?: Function,
  onPressArea?: Function,
};

export default function MapType({ deleteDraw, showDeleteDraw }: Props) {
  return (
    <div
      className="flex flex-col fixed right-8"
      style={{ zIndex: 900, top: 160 }}
    >
      {showDeleteDraw && (
        <MyButton
          label="Xóa khoanh vùng"
          type="red"
          onPress={deleteDraw}
          wrapperClass="mt-2"
        />
      )}
    </div>
  );
}
