import MyButton from '@components/button';

import MyModalV2 from '@components/modalV2/myModalV2';
import { useState } from 'react';
import PermissionModal from './modal/PermissionModal';
/* eslint-disable react/no-unescaped-entities */
type PermissionProps = {
  employee: string,
};
export default function NonePermission({ employee }: PermissionProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="w-full border border-blue-main rounded-lg h-full flex flex-col">
      <span className="mt-4 ml-4 text-14">Nhân viên chưa được phân quyền</span>
      <div className="flex flex-col justify-center items-center space-y-4 mt-8 mb-4">
        <div className="w-196px">
          <MyButton
            type="blue"
            label="Phân quyền cho nhân viên"
            onPress={() => setShowModal(true)}
          />
        </div>
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <PermissionModal
          employee={employee}
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
