import { View } from 'react-native-web';
import styles from '@components/table/table.module.scss';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import React, { useCallback } from 'react';
import SearchTextDropdown from '../../../manageRealEstate/components/filterButtons/searchTextDropdown';
import RealEstateTypeDropdown from '../../../manageRealEstate/components/filterButtons/realEstateTypeDropdown';
import ProvinceDropdown from '../../../manageRealEstate/components/filterButtons/provinceDropdown';
import DistrictDropdown from '../../../manageRealEstate/components/filterButtons/districtDropdown';
import WardDropdown from '../../../manageRealEstate/components/filterButtons/wardDropdown';
import StreetDropdown from '../../../manageRealEstate/components/filterButtons/streetDropdown';
import CalendarDropdown from '../../../manageRealEstate/components/filterButtons/calendarDropdown';
import { useManageProjectContext } from '../context';
import RenderResultFilterHeader from './renderResultFilterHeader';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { merge2Array } from '@utils/helper';
import { HoChiMinhId } from '../../../../../constant';
import DropdownPriceRange from '../../components/filterButtons/dropdownPriceRange';

type Props = {
  data?: Array,
  loading?: boolean,
};

export default function RenderHeaderProject({ data, loading }: Props) {
  const { listSelected, setListSelected, stateFilter, setStateFilterAt } =
    useManageProjectContext();
  const isCheckAll = listSelected?.length === data?.length;
  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data?.map(e => e?.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { projectStatusType } = realEstateConfigurations;
  const { projectForRentTypes, projectForSaleTypes } = configsRemote;
  const projectTypeConfigs = merge2Array(
    projectForRentTypes,
    projectForSaleTypes
  );
  return (
    <View
      style={{ borderTopLeftRadius: 9, borderTopRightRadius: 9, width: '100%' }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              isPartialCheck={listSelected?.length > 0}
              onPress={handleCheckAll}
              isChecked={isCheckAll}
              disabled={loading}
            />
          </div>
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </div>
          </div>
          <div className={`${styles.headContainer} w-202px items-center`}>
            <SearchTextDropdown
              title="Mã dự án"
              search={stateFilter.projectCode}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('projectCode')(value);
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-180px items-center justify-center`}
          >
            <RealEstateTypeDropdown
              selections={projectStatusType}
              title="Trạng thái dự án"
              value={stateFilter.projectStatus}
              setValue={value => {
                setListSelected?.clear();
                setStateFilterAt('projectStatus')(value);
              }}
            />
          </div>
          <div className={`${styles.headContainer} w-250px items-center`}>
            <SearchTextDropdown
              search={stateFilter.projectName}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('projectName')(value);
              }}
              title="Tên dự án"
            />
          </div>
          <div
            className={`${styles.headContainer} w-210px items-center justify-center`}
          >
            <RealEstateTypeDropdown
              title="Loại dự án"
              selections={projectTypeConfigs}
              value={stateFilter.projectType}
              setValue={value => {
                setListSelected.clear();
                setStateFilterAt('projectType')(value);
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <SearchTextDropdown
              title="Số nhà"
              search={stateFilter.houseNumber}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('houseNumber')(value);
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <StreetDropdown
              value={stateFilter.street}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('street')(item.key);
                setStateFilterAt('streetName')(item.value);
              }}
              wardId={stateFilter.ward}
              districtId={stateFilter.district}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <WardDropdown
              value={stateFilter.ward}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('ward')(item.key);
                setStateFilterAt('wardName')(item.value);
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
              districtId={stateFilter.district}
              streetId={stateFilter.street}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <DistrictDropdown
              value={stateFilter.district}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('district')(item.key);
                setStateFilterAt('districtName')(item.value);
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
              provinceId={HoChiMinhId}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <ProvinceDropdown
              value={stateFilter.province}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('province')(item.key);
                setStateFilterAt('provinceName')(item.value);
                setStateFilterAt('district')('');
                setStateFilterAt('districtName')('');
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <CalendarDropdown
              start={stateFilter.startDay}
              end={stateFilter.endDay}
              setStart={value => setStateFilterAt('startDay')(value)}
              setEnd={value => setStateFilterAt('endDay')(value)}
              onResetSelected={() => {
                setListSelected.clear();
              }}
              title="Ngày đăng"
            />
          </div>
          <div
            className={`${styles.headContainer} w-200px items-center justify-center`}
          >
            <SearchTextDropdown title="Người tạo" />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <DropdownPriceRange
              priceFrom={stateFilter?.priceFrom}
              priceTo={stateFilter?.priceTo}
              priceKey={stateFilter?.priceKey}
              setPriceFrom={value => setStateFilterAt('priceFrom')(value)}
              setPriceTo={value => setStateFilterAt('priceTo')(value)}
              setPriceKey={value => setStateFilterAt('priceKey')(value)}
              onResetSelected={() => {
                setListSelected.clear();
              }}
            />
          </div>
        </View>
      </View>
      <RenderResultFilterHeader />
    </View>
  );
}
