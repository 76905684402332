import React from 'react';
import MyButton from '@components/button';
import SearchBox from '@components/searchBox';
import { useManageEmployeeContext } from '../context';
import { useNavigate } from 'react-router-dom';
import { useGetDepartments } from '@utils/hooks/b2bUser/useGetDepartments';
import DropdownSelection from '@components/dropdownSelection';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
type Props = {
  title?: string,
  currentTotal?: number,
  total?: number,
  department?: string,
  setDepartment?: Function,
};

export default function RenderTopTable({
  title,
  currentTotal = 0,
  total = 0,
  department,
  setDepartment,
}: Props) {
  const { stateFilter, setStateFilterAt, setListSelected } =
    useManageEmployeeContext();
  const navigate = useNavigate();
  const { finalData: departments } = useGetDepartments();
  const { permissions } = useGetMe();
  return (
    <div className="w-full flex flex-row py-2.5 overflow-auto space-x-2 items-end">
      <div style={{ minWidth: 300 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="text-18 font-medium text-black-main">{title}</div>
          <div className="text-14 text-black-main">
            ({currentTotal}/{total} hồ sơ)
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-end flex-row items-end space-x-4">
        <div className="flex items-center space-x-2">
          <span className="text-sm">Phòng ban</span>
          <DropdownSelection
            dropdownClassName="w-230px"
            hasItemRadio={false}
            placeholder="Tất cả phòng ban"
            selections={departments?.map(e => {
              return { key: e.id, value: e.name };
            })}
            value={department}
            setValue={value => {
              setDepartment(value);
              setStateFilterAt('department')(value);
              setListSelected.clear();
            }}
            onDelete={() => {
              setStateFilterAt('department')('');
              setDepartment('');
              setListSelected.clear();
            }}
          />
        </div>
        {permissions?.some(p => p.id === PermissionIds.AddEmployee) && (
          <MyButton
            onPress={() => navigate('/employee/create')}
            rightComponent={() => (
              <img alt="plus-icon" src="/svg/plus-icon.svg" />
            )}
            style={{ width: 146 }}
            type="blue"
            label="Tạo nhân viên"
          />
        )}
        <SearchBox
          placeholder="Tìm kiếm nhân viên"
          searchValue={stateFilter.name}
          setSearchValue={value => setStateFilterAt('fullName')(value)}
          onDeleteValue={() => setStateFilterAt('fullName')('')}
        />
      </div>
    </div>
  );
}
