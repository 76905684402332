import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import { UPDATE_PERMISSION_GROUP } from '@utils/graphql/mutations/organization';
import { resetQueries } from '@utils/helper';
import { pickBy } from 'lodash';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
export const useUpdatePermissionGroup = () => {
  const [updatePermissionGroup, { loading: updateLoading, error, client }] =
    useMutation(UPDATE_PERMISSION_GROUP);
  const toast = useToast();
  const navigate = useNavigate();
  const onUpdateSubmit = useCallback(
    async state => {
      try {
        await updatePermissionGroup({
          variables: pickBy(
            {
              ...state,
            },
            e => e || e === false || e === null
          ),
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Chỉnh sửa nhóm quyền thành công!"
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['listPermissionGroups'], client.cache);
        await navigate('/manageOrganization/permission-groups');
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [updatePermissionGroup, client, navigate]
  );
  return { onUpdateSubmit, updateLoading, error };
};
