import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import Label from '@components/label';
import MyInput from '@components/myInput';
import DropdownSelection from '@components/dropdownSelection';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

TopAddPermissionGroup.propTypes = {
  permissionGroups: PropTypes.array,
  setAssignedPermissions: PropTypes.func,
};
function TopAddPermissionGroup({ permissionGroups, setAssignedPermissions }) {
  const { control } = useFormContext();
  const navigate = useNavigate();

  return (
    <FormProvider>
      <div className="flex flex-col w-full p-4 bg-white gap-y-4 pl-4">
        <div className="flex justify-center items-center relative">
          <div className="text-blue-main font-bold text-16">Nhóm quyền</div>
          <img
            alt="close"
            src="/svg/ico-close.svg"
            className="absolute right-0 cursor-pointer"
            onClick={() => {
              navigate('/manageOrganization/permission-groups');
            }}
          />
        </div>
        <div className="flex flex-col gap-y-8 bg-inherit">
          <div className="w-full flex flex-row items-center space-x-2.5">
            <Label
              wrapperClass="w-230px"
              label="Tên nhóm quyền mẫu"
              isRequired={true}
            />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Vui lòng nhập tên nhóm quyền' }}
                render={({
                  field,
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      maxLength={62}
                      hideMaxLength={true}
                      inputClassName="w-full"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Nhập tên nhóm quyền mẫu"
                      onDelete={() => onChange('')}
                      hideDelete={false}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="flex items-center relative h-38px bg-inherit">
            <div className="relative w-full h-1px bg-gray-border" />
            <div className="flex absolute right-1/2 bottom-1/2 z-3 transform translate-x-1/2 translate-y-1/2 px-2.5 bg-inherit text-gray-border text-14">
              Hoặc chọn nhóm quyền có sẵn
            </div>
          </div>
          <div className="w-full flex flex-row items-center space-x-2.5">
            <Label wrapperClass="w-230px" label="Tên nhóm quyền mẫu" />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                control={control}
                name="id"
                render={({
                  field: { value, onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <div className="w-full">
                      <DropdownSelection
                        hasItemRadio={false}
                        placeholder="Chọn nhóm quyền mẫu để tạo nhanh"
                        selections={permissionGroups?.map(permission => ({
                          key: permission.id,
                          value: permission.name,
                          ...permission,
                        }))}
                        value={value}
                        setValue={value => {
                          onChange(value);
                          setAssignedPermissions(
                            permissionGroups
                              .find(
                                permissionGroup => permissionGroup.id === value
                              )
                              ?.permissions?.map(permission => permission.id)
                          );
                        }}
                        onDelete={() => {
                          onChange('');
                        }}
                        errorMessage={error?.message}
                        invalid={invalid}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default TopAddPermissionGroup;
