import React from 'react';
import RenderTableNeedApproval from './components/renderTableNeedApproval';
import RenderTopTable from './components/renderTopTable';
import { View } from 'react-native-web';
import { useNeedApprovalContext } from './context';
import { useGetRealEstateManagement } from '@utils/hooks/manageRealEstate/useGetRealEstateManagement';
import ApprovalRealEstateModal from '../../../../modals/approvalRealEstateModal';
import MyModal from '@components/modal';
import isEmpty from 'lodash/isEmpty';
import { useGetNeededRealEstateManagement } from '@utils/hooks/manageRealEstate/useGetNeededRealEstateManagement';
import RealEstateDetail from '../realEstateDetail';
export default function NeedApproval() {
  const {
    listField,
    stateFilter,
    demand,
    setDemand,
    listSelected,
    setListSelected,
    showModal,
    setShowModal,
    status,
    setStatus,
    tab,
  } = useNeedApprovalContext();
  const typeGetListData = tab.includes('needApproval')
    ? 'pending'
    : tab.includes('approved')
    ? 'approved'
    : 'rejected';
  const {
    realEstates,
    loading,
    isLoadingMore,
    onLoadMore,
    hasNextPage,
    total,
  } = useGetRealEstateManagement({
    fieldShowTable: listField,
    params: { approvalStatus: typeGetListData },
    state: stateFilter || {},
    tab,
  });
  const {
    realEstates: neededRealEstates,
    loading: neededLoading,
    isLoadingMore: neededIsLoadingMore,
    onLoadMore: neededOnLoadMore,
    hasNextPage: neededHasNextPage,
    total: neededTotal,
  } = useGetNeededRealEstateManagement({
    fieldShowTable: listField,
    params: { approvalStatus: typeGetListData },
    state: stateFilter || {},
    demand,
    tab,
  });
  const realEstateType = 'product';
  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          width: '100%',
          backgroundColor: 'white',
          paddingBottom: 12,
        }}
      >
        <div className="px-4 pt-2">
          <RenderTopTable
            title={`Tin đăng ${demand === 'sell' ? 'nhà phố' : 'nhu cầu'} ${
              tab.includes('needApproval')
                ? 'cần duyệt'
                : tab.includes('approved')
                ? 'đã duyệt'
                : 'từ chối'
            }`}
            isVisibleActionAllButton={
              !isEmpty(listSelected) &&
              listSelected?.length > 1 &&
              tab.includes('needApproval')
            }
            onShowModalApproval={() => {
              setStatus('approved');
              setShowModal(true);
            }}
            onShowModalReject={() => {
              setStatus('rejected');
              setShowModal(true);
            }}
            currentTotal={
              demand === 'sell'
                ? realEstates?.length
                : neededRealEstates?.length
            }
            total={demand === 'sell' ? total : neededTotal}
            demand={demand}
            setDemand={setDemand}
          />
        </div>
        <div className="w-full px-4">
          <RenderTableNeedApproval
            loading={demand === 'sell' ? loading : neededLoading}
            data={demand === 'sell' ? realEstates : neededRealEstates}
            isLoadingMore={isLoadingMore}
            neededIsLoadingMore={neededIsLoadingMore}
            onLoadMore={onLoadMore}
            neededOnLoadMore={neededOnLoadMore}
            canLoadMore={demand === 'sell' ? hasNextPage : neededHasNextPage}
          />
        </div>
      </View>
      <View
        style={{
          width: '100%',
        }}
      />
      {listSelected?.length === 1 ? (
        <View
          style={{
            width: '100%',
            height: '100%',
            marginTop: 8,
          }}
        >
          <RealEstateDetail
            setShowModal={setShowModal}
            setStatus={setStatus}
            demand={demand}
          />
        </View>
      ) : (
        <div className="p-4 text-black-main">
          Vui lòng chọn 1 bất động sản để xem chi tiết
        </div>
      )}
      <MyModal
        maxWidth={515}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <ApprovalRealEstateModal
          realEstateType={realEstateType}
          onClearState={() => setListSelected.clear()}
          realEstateIds={listSelected}
          status={status}
          type={demand === 'sell' ? 'product' : 'demand'}
          onClose={() => setShowModal(false)}
        />
      </MyModal>
    </View>
  );
}
