import React from 'react';

type Props = {
  plans?: Array,
};

let ReactLeaflet, Polygon;
if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  Polygon = ReactLeaflet.Polygon;
}

export default function PlansLayer({ plans }: Props) {
  if (!plans?.length) {
    return null;
  }

  return plans?.map((item, index) => {
    return item.polygon?.coordinates.map((subItem, subIndex) => {
      const [[...coordinates]] = subItem;
      coordinates.pop();
      return (
        <Plan
          key={`plan-${index}-${subIndex}`}
          item={item}
          listCoordinates={[{ color: '#249', coordinates }]}
          onClick={() => {}}
          planes={item?.planes}
        />
      );
    });
  });
}

type LandProps = {
  item?: Object,
  centerPoint?: Array,
  coordinates?: Array,
  listCoordinates?: Array,
  lat?: number,
  long?: number,
  onClick?: Function,
  landId?: string,
};

function Plan({ item, onClick, listCoordinates = [], landId }: LandProps) {
  return listCoordinates?.map((point, index) => {
    const { coordinates } = point;
    const isActive = landId === item.id;
    return (
      <Polygon
        key={`${item.id} ${index}`}
        onClick={() => {
          onClick(item.id);
        }}
        weight={isActive ? 3 : 1}
        fillColor={'transparent'}
        color="#B7791F"
        positions={[coordinates.map(e => [e[1], e[0]])]}
      />
    );
  });
}
