import styles from '../uploadMedia.module.scss';

type LeftButtonProps = {
  setOption?: Function,
};
export default function LeftButton({ setOption }: LeftButtonProps) {
  return (
    <div className={styles.leftButtonContainer}>
      <button onClick={() => setOption(0)} className={styles.leftButton}>
        <img className="w-40px h-40px" alt="computer" src="/svg/computer.svg" />
        <div
          style={{ fontSize: 13 }}
          className=" text-black font-medium text-center"
        >
          Đăng ảnh/video từ thiết bị của bạn
        </div>
      </button>
      <div className="w-138px h-1px bg-blue-main" />
      <button onClick={() => setOption(1)} className={styles.leftButton}>
        <img className="w-40px h-40px" alt="youtube" src="/svg/youtube.svg" />
        <div
          style={{ fontSize: 13 }}
          className="text-black font-medium text-center"
        >
          Đăng video từ Youtube
        </div>
      </button>
    </div>
  );
}
