import React from 'react';
import pickBy from 'lodash/pickBy';
import { pickByFromTo } from '@utils/hooks/manageProduct/useGetRealEstatePosted';

let L;
if (process.browser) {
  L = require('leaflet');
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
export function angleFromCoordinate(lat1, lon1, lat2, lon2) {
  const p1 = {
    x: lat1,
    y: lon1,
  };

  const p2 = {
    x: lat2,
    y: lon2,
  };
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export const ActivePathContext = React.createContext({
  activePath: null,
  setActivePath: () => {},
});

export function areCoordinatesDistantEnough(
  coord_one: L.LatLng,
  coord_two: L.LatLng
): boolean {
  return (
    JSON.stringify(coord_one[0].toString().substring(8, 3)) !==
    JSON.stringify(coord_two[0].toString().substring(8, 3))
  );
}

/**
 * Animate a leaflet element marker between two coordinates
 *
 * @param {L} leafletElement The leaflet element from marker
 * @param {L.LatLng} coordFrom The coordinate to go from
 * @param {L.LatLng} coordTo The coordinate to go to
 */
export function animateMarker(
  leafletElement: any,
  coordFrom: L.LatLng,
  coordTo: L.LatLng
): void {
  let position;
  let movingInterval;
  let curLat = coordFrom[0];
  let curLng = coordFrom[1];
  let newLat = coordTo[0];
  let newLng = coordTo[1];
  let threshold = 0.01;
  movingInterval = setInterval(() => {
    if (threshold >= 1) {
      clearInterval(movingInterval);
    }
    position = new L.LatLng(
      curLat + (newLat - curLat) * threshold,
      curLng + (newLng - curLng) * threshold
    );
    leafletElement.setLatLng(position);
    threshold += 0.1;
  }, 1000 / 60);
}

export const getVariablesFilterMap = variablesFilter => {
  const typeOfDemand = variablesFilter.typeOfDemand;
  const areaTo =
      variablesFilter?.areaTo === 20001 ? null : variablesFilter?.areaTo,
    areaFrom =
      variablesFilter?.areaFrom === 0 ? null : variablesFilter?.areaFrom;

  const priceFrom =
      variablesFilter?.priceFrom === 0
        ? null
        : typeOfDemand === 'rent'
        ? variablesFilter?.priceFrom * Math.pow(10, 6)
        : variablesFilter?.priceFrom * Math.pow(10, 9),
    priceTo =
      variablesFilter?.priceTo === 101 || variablesFilter?.priceTo === 200.5
        ? null
        : typeOfDemand === 'rent'
        ? variablesFilter?.priceTo * Math.pow(10, 6)
        : variablesFilter?.priceTo * Math.pow(10, 9);

  const widthFrom =
      variablesFilter?.widthFrom === 0 ? null : variablesFilter?.widthFrom,
    widthTo =
      variablesFilter?.widthTo === 101 ? null : variablesFilter?.widthTo;

  const lengthFrom =
      variablesFilter.lengthFrom === 0 ? null : variablesFilter.lengthFrom,
    lengthTo =
      variablesFilter.lengthTo === 501 ? null : variablesFilter.lengthTo,
    bedroomCountFrom =
      variablesFilter.bedroomCountFrom === 0
        ? null
        : variablesFilter.bedroomCountFrom,
    bedroomCountTo =
      variablesFilter.bedroomCountTo === 169
        ? null
        : variablesFilter.bedroomCountTo,
    toiletCountFrom =
      variablesFilter.toiletCountFrom === 0
        ? null
        : variablesFilter.toiletCountTo,
    toiletCountTo =
      variablesFilter.toiletCountTo === 169
        ? null
        : variablesFilter.toiletCountTo,
    floorCountFrom =
      variablesFilter.floorCountFrom === 0
        ? null
        : variablesFilter.floorCountFrom,
    floorCountTo =
      variablesFilter.floorCountTo === 169
        ? null
        : variablesFilter.floorCountTo;

  const area = pickByFromTo(areaFrom, areaTo),
    price = pickByFromTo(priceFrom, priceTo),
    width = pickByFromTo(widthFrom, widthTo),
    length = pickByFromTo(lengthFrom, lengthTo),
    bedroomCount = pickByFromTo(bedroomCountFrom, bedroomCountTo),
    toiletCount = pickByFromTo(toiletCountFrom, toiletCountTo),
    floorCount = pickByFromTo(floorCountFrom, floorCountTo),
    utilities = [0, 5].includes(variablesFilter?.utilities?.length)
      ? null
      : variablesFilter?.utilities,
    realEstateType =
      variablesFilter?.realEstateType?.length > 0
        ? variablesFilter?.realEstateType
        : null,
    hasAlley =
      variablesFilter.hasAlley === '' || !variablesFilter.hasAlley
        ? null
        : variablesFilter.hasAlley === 'yes';

  const variables = {
    typeOfDemand,
    realEstateType,
    price,
    area,
    utilities,
    length,
    width,
    bedroomCount,
    toiletCount,
    floorCount,
    hasAlley,
  };
  return { ...pickBy(variables, e => e || e === false) };
};
