import React, { useMemo } from 'react';
import { MODE } from '@utils/configs/map/mapConfigs';

import styles from '../customToolMap.module.scss';

type Props = {
  onPressDraw?: Function,
  onPressZoom?: Function,
  onPressEx?: Function,
  onPressPlus?: Function,
  onPressLocation?: Function,
  pictureDraw?: string,
  pictureLocation?: string,
  pictureEx?: string,
  picturePlus?: string,
  pictureZoom?: string,
  container?: Style,
  isFullScreen?: boolean,
  onPressMeasure?: Function,
  pictureMeasure?: String,
  hideZoomFullScreen?: boolean,
  showButtonWordMap?: boolean,
  onOpenDirect?: Function,
  showDirect?: boolean,
  showButtonZoom?: boolean,
  wrapperStyle?: Style,
  showGuide?: boolean,
  mode?: string,
  hideDraw?: boolean,
  showWorldMap?: boolean,
  toggleShowWorldMap?: Function,
  showPinLocation?: boolean,
  pinLocation?: boolean,
  setPinLocation?: Function,
  heightOfMeasureNoti?: number,
  isMeasuring?: boolean,
};

const tooltipOverlayProps = {
  placement: 'left',
  popperConfig: {
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: { offset: [0, 12] },
      },
      {
        name: 'preventOverflow',
        enabled: false,
      },
    ],
  },
};

// type GuideProps = {
//   title?: string,
//   subtitle?: string,
// };

// function GuideTooltip({ title, subtitle }: GuideProps) {
//   return (
//     <>
//       <p className="heading4">{title}</p>
//       <p className="bodyText mt-12px">{subtitle}</p>
//     </>
//   );
// }

export default function CustomToolMap(props: Props) {
  const {
      onPressDraw,
      onPressEx,
      onPressLocation,
      onPressPlus,
      onPressZoom,
      isFullScreen,
      onPressMeasure,
      mode,
      hideDraw = false,
      hideZoomFullScreen,
      onOpenDirect,
      showDirect,
      showButtonZoom = true,
      showGuide = true,
      showButtonWordMap,
      showWorldMap,
      toggleShowWorldMap,
      showPinLocation,
      pinLocation,
      setPinLocation,
      isMeasuring,
      heightOfMeasureNoti,
    } = props,
    tooltipDynamicOverlayProps = isFullScreen
      ? {
          ...tooltipOverlayProps,
          container: document.getElementById('map-container'),
        }
      : tooltipOverlayProps;

  const bottom = useMemo(() => {
    if (isMeasuring) {
      return heightOfMeasureNoti + 16;
    }
    return 24;
  }, [isMeasuring, heightOfMeasureNoti]);

  return (
    <div
      className="flex flex-col items-center fixed right-8 mb-2 space-y-1 absolute"
      style={{ zIndex: 1000, bottom }}
    >
      <ItemToolMap
        showGuide={showGuide}
        hideButton={!showButtonWordMap}
        src={showWorldMap ? '/svg/ico_global_blue.svg' : '/svg/ico_global.svg'}
        onPress={toggleShowWorldMap}
        tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        buttonClass={showWorldMap && styles.activeButton}
      />
      <ItemToolMap
        showGuide={showGuide}
        hideButton={!showPinLocation}
        src={pinLocation ? '/svg/pinLocationBlue.svg' : '/svg/pinLocation.svg'}
        onPress={setPinLocation}
        tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        title="Hướng dẫn đường đi"
        subtitle="Bạn có thể gợi ý đường đi bằng cách bấm vào nút này"
        buttonClass={pinLocation && styles.activeButton}
      />
      <ItemToolMap
        showGuide={showGuide}
        hideButton={!showDirect}
        src="/svg/direct.svg"
        onPress={onOpenDirect && onOpenDirect}
        tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        title="Hướng dẫn đường đi"
        subtitle="Bạn có thể gợi ý đường đi bằng cách bấm vào nút này"
      />
      <ItemToolMap
        showGuide={showGuide}
        onPress={onPressMeasure}
        src={mode === MODE.MEASURING ? '/svg/ruler_blue.svg' : '/svg/ruler.svg'}
        title="Đo khoảng cách & diện tích"
        subtitle="Để tính khoảng cách, bạn chấm từng điểm trên bản đồ. Nếu các điểm liền nhau, bạn sẽ tính được phần diện tích của vùng đó."
        tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        buttonClass={mode === MODE.MEASURING && styles.activeButton}
      />

      {
        <ItemToolMap
          showGuide={showGuide}
          hideButton={hideDraw}
          title="Khoanh vùng"
          subtitle="Bạn có thể vẽ khoanh vùng để có thể xem các bất động sản hiện đang có"
          buttonClass={mode === MODE.DRAWING && styles.activeButton}
          onPress={onPressDraw}
          src={mode === MODE.DRAWING ? '/svg/draw_blue.svg' : '/svg/draw.svg'}
          tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        />
      }

      <ItemToolMap
        showGuide={showGuide}
        title="Vị trí hiện tại"
        subtitle="Bạn có thể trở về vị trí hiện tại của bạn trên bản đồ này"
        src={'/svg/locationMap.svg'}
        tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
        onPress={onPressLocation}
      />

      {showButtonZoom && (
        <div className={styles.containerButtonZoom}>
          <ItemToolMap
            showGuide={showGuide}
            title="Phóng to"
            subtitle="Bạn có thể phóng to hoặc thu nhỏ khu vực bạn đang xem trên bản đồ"
            src="/svg/plus.svg"
            onPress={onPressPlus}
            tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
            buttonClass={styles.buttonZoom}
          />
          <div className={styles.line} />
          <ItemToolMap
            showGuide={showGuide}
            title="Thu nhỏ"
            subtitle="Bạn có thể phóng to hoặc thu nhỏ khu vực bạn đang xem trên bản đồ"
            src="/svg/zoom-out.svg"
            onPress={onPressEx}
            buttonClass={styles.buttonZoom}
            tooltipDynamicOverlayProps={tooltipDynamicOverlayProps}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTopWidth: 0,
            }}
          />
        </div>
      )}
      {
        <ItemToolMap
          showGuide={showGuide}
          title="Toàn màn hình"
          subtitle="Bạn có thể phóng to bản đồ đang xem toàn màn hình"
          tooltipDynamicOverlayProps={{
            ...tooltipDynamicOverlayProps,
            popperConfig: {
              modifiers: [
                {
                  name: 'offset',
                  enabled: true,
                  options: { offset: [-10, 12] },
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                },
              ],
            },
          }}
          onPress={onPressZoom}
          hideButton={hideZoomFullScreen}
          src={isFullScreen ? '/svg/collapse.svg' : '/svg/expand.svg'}
        />
      }
    </div>
  );
}

type ItemProps = {
  hideButton?: boolean,
  showGuide?: boolean,
  title?: string,
  subtitle?: string,
  tooltipDynamicOverlayProps?: Object,
  onPress?: Function,
  style?: Object,
  wrapperStyle?: string,
  iconClass?: string,
  src?: string,
  buttonClass?: string,
};

function ItemToolMap(props: ItemProps) {
  const {
    hideButton,
    // title,
    // subtitle,
    // tooltipDynamicOverlayProps,
    // source,
    onPress,
    wrapperStyle,
    iconClass,
    // showGuide,
    src,
    buttonClass,
  } = props;

  if (hideButton) {
    return null;
  }

  return (
    <div className={`${wrapperStyle} flex-1`}>
      <button
        onClick={onPress}
        className={`${styles.buttonMapStyle} ${styles.centering} ${buttonClass}`}
      >
        <img alt={'icon'} src={src} className={`w-18px h-18px ${iconClass}`} />
      </button>
    </div>
  );
}
