import React, { useState } from 'react';
import DateRangePicker from '@components/dateRangePicker';
import { useGetProductHistory } from '../../../../../../../utils/hooks/realEstateEditHistory/useGetProductHistory';
import { convertInteractionHistory } from './utils';
import moment from 'moment/moment';
import ItemHistory from './itemHistory';
import SeeMore from '@components/seeMore';
import Spinner from '@components/spinner';
import { useProductContext } from 'scenes/home/manageProduct/product/context';
import { historyTypesSelection } from './configs';
import DropdownMultiSelection from './dropdownMultiSelection';

type Props = {
  setCurrentTab?: Function,
};

export default function ProductHistory({ setCurrentTab }: Props) {
  const { listSelected } = useProductContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [types, setTypes] = useState([
    'addProduct',
    'editProduct',
    'deleteProduct',
    'viewProductOnMap',
    'viewProductDetail',
    'addDocumentationProduct',
    'removeDocumentationProduct',
    'contactPhones',
    'changeStatus',
    'approvedProduct',
    'rejectedProduct',
    'productComment',
  ]);
  const { data, onLoadMore, hasNextPage, loading } = useGetProductHistory({
    realEstateId: listSelected[0],
    params: {
      realEstateId: listSelected[0],
      historyTypes: types.map(e => {
        return {
          historyType: e,
        };
      }),
      sort: '-date',
      interval: {
        from: moment(startDate).isValid()
          ? moment.unix(startDate).format('YYYY-MM-DD')
          : null,
        to: moment(endDate).isValid()
          ? moment.unix(endDate).format('YYYY-MM-DD')
          : null,
      },
    },
  });
  const groupResultsToArray = convertInteractionHistory(data);
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex w-full justify-end space-x-2 items-center">
        <span className="text-14 text-black-main font-medium">
          Loại lịch sử
        </span>
        <DropdownMultiSelection
          wrapperStyle="self-center w-240px h-40px flex px-2 border rounded-lg border-blue-main"
          selections={historyTypesSelection}
          value={types}
          setValue={setTypes}
          title="Tất cả"
          onDelete={() => {
            setTypes([
              'addProduct',
              'editProduct',
              'deleteProduct',
              'viewProductOnMap',
              'viewProductDetail',
              'addDocumentationProduct',
              'removeDocumentationProduct',
              'contactPhones',
              'changeStatus',
              'approvedProduct',
              'rejectedProduct',
              'productComment',
            ]);
          }}
        />
        <span className="text-14 text-black-main font-medium">Thời gian</span>
        <DateRangePicker
          setStartDay={setStartDate}
          startDay={startDate}
          setEndDay={setEndDate}
          endDay={endDate}
        />
      </div>
      <div className="flex flex-1 flex-col">
        {groupResultsToArray.map((item, index) => {
          const { month, items } = item;
          return (
            <div key={index} className="flex flex-col space-y-4">
              <span className="text-black-main text-16 font-bold font-text mt-2">
                Tháng {moment(month, 'YYYY/MM').format('MM, YYYY')}
              </span>
              {items.map((history, idx) => {
                return (
                  <ItemHistory
                    data={history}
                    key={idx}
                    setCurrentTab={setCurrentTab}
                  />
                );
              })}
            </div>
          );
        })}
        {!groupResultsToArray?.length && !loading && (
          <p className="text-black-main text-base m-3">
            Không có lịch sử sản phẩm
          </p>
        )}
        {loading && (
          <div className="flex w-full justify-center">
            <Spinner />
          </div>
        )}
      </div>
      <SeeMore
        hasNextPage={hasNextPage}
        wrapperClass="mt-4"
        onLoadMore={onLoadMore}
        loading={loading}
      />
    </div>
  );
}
