import ListView from '../components/ListView';
import { realEstateStatus } from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import React from 'react';

type ListStatusModalProps = {
  onClose: Function,
  selectedItems: Array,
  setItems: Function,
  id?: string,
};

export default function ListStatusModal(props: ListStatusModalProps) {
  const { onClose, setItems, selectedItems, id } = props;
  return (
    <ListView
      setItems={setItems}
      selectedItems={selectedItems}
      id={id}
      onClose={onClose}
      data={realEstateStatus}
      headerLabel="Chọn trạng thái"
      searchPlaceHolder="Tìm kiếm trạng thái"
    />
  );
}
