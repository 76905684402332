import RealEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { DocTienBangChuV2, usePrice } from '@utils/helper';
import { buildingTypeConfigs } from '@utils/configs/configsLocal';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import RenderAvatarComponent from '../../../scenes/home/manageProduct/components/renderAvatarComponent';

const getArrayValue = ({ configs = [], key, item }) => {
  const foundConfigs = item.ops
    .filter(e => e.path.includes(key))
    ?.map(item => item.value);
  if (!foundConfigs?.length) {
    return '-';
  }
  if (foundConfigs[0] instanceof Array) {
    return configs
      .filter(e => foundConfigs.map(a => a === e.key))
      .map(item => item.value)
      .join(', ');
  } else {
    return configs
      .filter(e => foundConfigs.includes(e.key))
      .map(item => item.value)
      .join(', ');
  }
};
export const ReadPrice = price => {
  const from = price?.from,
    to = price?.to;
  if (from && to) {
    return `${DocTienBangChuV2(from?.toString())} - ${DocTienBangChuV2(
      to?.toString()
    )}`;
  }
  if (from) {
    return `>= ${DocTienBangChuV2(from?.toString())}`;
  }
  if (to) {
    return `<= ${DocTienBangChuV2(to?.toString())}`;
  }
  return '-';
};

export const useConvertDataProductEditHistory = (
  item = {},
  type,
  setShowModal = () => {},
  setShowAttachmentsModal = () => {}
) => {
  const {
    directions,
    fixedUtilities,
    utilities,
    localUtilities,
    villaViewTypes,
    equipmentsAndServices,
    warehouseType,
  } = RealEstateCommonConfigs;

  const { price, priceUnit } = usePrice({
    price: item.ops.find(e => e.path === '/price')?.value,
  });
  return [
    {
      key: 'width',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/width')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m`;
      },
    },
    {
      key: 'length',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/length')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m`;
      },
    },
    {
      key: 'rear',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/rear')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m`;
      },
    },
    {
      key: 'commission',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/commission')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue}`;
      },
    },
    {
      key: 'area',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/area')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m²`;
      },
    },
    {
      key: 'price',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/price')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${price} ${priceUnit}`;
      },
    },
    {
      key: 'floorCount',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/floorCount')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'warehouseRoomCount',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/warehouseRoomCount'
        )?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'constructionArea',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/constructionArea'
        )?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m²`;
      },
    },
    {
      key: 'bedroomCount',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/bedroomCount'
        )?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'bedRoomArea',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/bedRoomArea')?.value;
        if (!foundValue) {
          return '-';
        }
        return `${foundValue} m²`;
      },
    },
    {
      key: 'roomCount',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/roomCount')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'toiletCount',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/toiletCount')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'hasAlley',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/hasAlley')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue ? 'Hẻm' : 'Mặt tiền';
      },
    },
    {
      key: 'alleyTurnCount',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/alleyTurnCount'
        )?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'alleyWidth',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/alleyWidth')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'project',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/project')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'block',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/block')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },

    {
      key: 'shophouseField',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/shophouseField'
        )?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'alleyWidth',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/alleyWidth')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'roomNumber',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/roomNumber')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'onFloor',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/onFloor')?.value;
        if (!foundValue) {
          return '-';
        }
        return foundValue;
      },
    },
    {
      key: 'direction',
      value: () => {
        const foundData = item.ops.find(e => e.path === '/direction')?.value;
        if (!foundData?.length) {
          return '-';
        }
        return directions.find(e => e.key === foundData)?.value;
      },
    },
    {
      key: 'isNewConstruction',
      value: () => {
        const foundValue = item.ops.find(e =>
          ['/isNewConstruction', '/isNewly'].includes(e.path)
        )?.value;
        if (typeof foundValue !== 'boolean') {
          return '-';
        }
        return foundValue ? 'Xây mới' : 'Xây cũ';
      },
    },
    {
      key: 'buildingType',
      value: getArrayValue({
        configs: buildingTypeConfigs,
        item,
        key: 'buildingType',
      }),
    },
    {
      key: 'warehouseType',
      value: getArrayValue({
        configs: warehouseType,
        item,
        key: 'warehouseType',
      }),
    },
    {
      key: 'hotelStar',
      value: item.ops.find(e => e.path === '/hotelStar')?.value,
    },
    {
      key: 'hasMezzanine',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/hasMezzanine'
        )?.value;
        if (typeof foundValue !== 'boolean') {
          return '-';
        }
        return foundValue ? 'Có' : 'Không';
      },
    },
    {
      key: 'privateNote',
      value: (
        <Tooltip
          className="mr-2"
          title={
            item?.ops.find(e => e.path === '/privateNote')?.value
              ? item?.ops.find(e => e.path === '/privateNote')?.value
              : '-'
          }
        >
          <div>
            {item?.ops.find(e => e.path === '/privateNote')?.value
              ? item?.ops.find(e => e.path === '/privateNote')?.value
              : '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'fixedUtilities',
      value: (
        <Tooltip
          className="mr-2"
          title={getArrayValue({
            configs: fixedUtilities,
            item,
            key: 'fixedUtilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: fixedUtilities,
              item,
              key: 'fixedUtilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'utilities',
      value: (
        <Tooltip
          className="mr-2"
          title={getArrayValue({
            configs: utilities,
            item,
            key: 'utilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: utilities,
              item,
              key: 'utilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'localUtilities',
      value: (
        <Tooltip
          className="mr-2"
          title={getArrayValue({
            configs: localUtilities,
            item,
            key: 'localUtilities',
          })}
        >
          <div>
            {getArrayValue({
              configs: localUtilities,
              item,
              key: 'localUtilities',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'villaView',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: villaViewTypes,
            item,
            key: 'villaView',
          })}
        >
          <div>
            {getArrayValue({
              configs: villaViewTypes,
              item,
              key: 'villaView',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'equipmentsAndServices',
      value: (
        <Tooltip
          title={getArrayValue({
            configs: equipmentsAndServices,
            item,
            key: 'equipmentsAndServices',
          })}
        >
          <div>
            {getArrayValue({
              configs: equipmentsAndServices,
              item,
              key: 'equipmentsAndServices',
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      key: 'mediaUrls',
      value: () => {
        if (
          item?.mediaUrls?.length === 0 &&
          item?.historyType === 'addProduct'
        ) {
          return 'Không có hình ảnh & video';
        }
        if (item?.mediaUrls?.length === 0) {
          return 'Đã xóa ảnh';
        }
        return (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="flex justify-center w-full"
          >
            <img src="/svg/icon_camera.svg" />
          </button>
        );
      },
    },
    {
      key: 'attachments',
      value: () => {
        if (
          item?.attachments?.length === 0 &&
          item?.historyType === 'addProduct'
        ) {
          return 'Không có tài liệu';
        }
        if (item?.attachments?.length === 0) {
          return 'Đã xóa tài liệu đính kèm';
        }
        return (
          <button
            onClick={() => {
              setShowAttachmentsModal(true);
            }}
            className="flex justify-center w-full"
          >
            <img src="/svg/paperclip-icon.svg" />
          </button>
        );
      },
    },
    {
      key: 'date',
      value: moment(item?.date).format('hh:mm A, DD/MM/YYYY'),
    },
    {
      key: 'logCreator',
      value: () => {
        if (!item?.userId) {
          return '-';
        }
        return (
          <MyDropdown
            DropdownComponent={UserInfo}
            dropdownContext={{ userId: item.userId.id }}
          >
            <div className="flex space-x-2 items-center">
              <div className="flex w-30px h-30px rounded-rd50 border border-gray-300 bg-no-repeat bg-cover bg-center">
                <RenderAvatarComponent
                  wrapperStyle="w-30px h-30px"
                  avatarId={item?.userId?.avatar}
                />
              </div>
              <span className="text-14 font-text text-blue-main underline truncate">
                {item?.userId?.fullName}
              </span>
              <img src="/svg/eye.svg" />
            </div>
          </MyDropdown>
        );
      },
    },
  ];
};
