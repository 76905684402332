import { useSetState } from 'react-use';
import { useCallback } from 'react';

export const blankStateFilterPermissionGroups = {
  code: '',
  name: '',
  createdAtFrom: '',
  createdAtTo: '',
  creator: '',
};

export const useStateFilterPermissionGroups = () => {
  const setStateAt = useCallback(
    field => {
      return value => setState({ [field]: value });
    },
    [setState]
  );

  const [state, setState] = useSetState({
    ...blankStateFilterPermissionGroups,
  });

  return { state, setState, setStateAt };
};
