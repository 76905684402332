import RenderLeft from './renderLeft';
import RenderRight from './renderRight';

type Props = {
  data?: Object,
};
export default function ContentProductDetail({ data }: Props) {
  return (
    <div className="w-full flex flex-row space-x-4">
      <RenderLeft data={data} />
      <RenderRight data={data} key={JSON.stringify(data)} />
    </div>
  );
}
