export const useFieldConfigs = tab => {
  return [
    {
      key: 'realEstateId',
      value: 'ID tin rao',
      isVisible: true,
    },
    {
      key: 'isForSell',
      value: 'Nhu cầu',
      isVisible: true,
    },
    {
      key: 'type',
      value: 'Loại BĐS',
      isVisible: true,
    },
    {
      key: 'houseNumber',
      value: 'Số nhà',
      isVisible: true,
    },
    {
      key: 'streetName',
      value: 'Đường',
      isVisible: true,
    },
    {
      key: 'wardName',
      value: 'Phường/ Xã',
      isVisible: true,
    },
    {
      key: 'districtName',
      value: 'Quận/ Huyện',
      isVisible: true,
    },
    {
      key: 'provinceName',
      value: 'Tỉnh/ Thành phố',
      isVisible: true,
    },
    {
      key: 'country',
      value: 'Đất nước',
      isVisible: true,
    },
    {
      key: 'createdAt',
      value: 'Ngày tạo',
      isVisible: true,
    },
    {
      key: 'updatedAt',
      value: 'Ngày chỉnh sửa',
      isVisible: true,
    },
    {
      key: 'approvedBy',
      value: tab?.includes('approved') ? 'Người duyệt' : 'Người từ chối',
      isVisible: true,
      hide: tab?.includes('needApproval'),
    },
    {
      key: 'approvedAt',
      value: tab?.includes('approved') ? 'Ngày duyệt' : 'Ngày từ chối',
      isVisible: true,
      hide: tab?.includes('needApproval'),
    },
    {
      key: 'width',
      value: 'Ngang',
      isVisible: true,
    },
    {
      key: 'length',
      value: 'Dài',
      isVisible: true,
    },
    {
      key: 'rear',
      value: 'Mặt hậu',
      isVisible: true,
    },
    {
      key: 'area',
      value: 'Diện tích',
      isVisible: true,
    },
    {
      key: 'price',
      value: 'Giá dự kiến',
      isVisible: true,
    },
    {
      key: 'floorCount',
      value: 'Số tầng',
      isVisible: true,
    },
    {
      key: 'constructionArea',
      value: 'Diện tích sàn',
      isVisible: true,
    },
    {
      key: 'department',
      value: 'Phòng ban thấy tin rao',
      isVisible: true,
    },
    {
      key: 'isNewConstruction',
      value: 'Hiện trạng',
      isVisible: true,
    },
  ];
};
