import React from 'react';

type AssignedCountConfigProps = {
  permissionName: string,
  count: number,
  countLabel: string,
};
export default function AssignedCountConfig({
  permissionName,
  count,
  countLabel,
}: AssignedCountConfigProps) {
  return (
    <div className="w-full flex flex-col space-y-2 rounded-lg shadow-normal p-4">
      <span className="font-medium text-blue-main text-16">
        {permissionName}
      </span>
      <span className="font-medium text-black-main text-14 mt-4">
        {countLabel}
        {count === Number.MAX_SAFE_INTEGER
          ? 'Không giới hạn'
          : `${count} lần/ngày`}
      </span>
    </div>
  );
}
