import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS } from '@utils/graphql';
import { initialPriceUnitForSale } from '../../../configs';

export const useGetInternalRealEstateConfigurations = () => {
  const { data: realEstateConfigs, loading } = useQuery(
    GET_INTERNAL_REAL_ESTATE_CONFIGURATIONS
  );
  if (!realEstateConfigs) {
    return {};
  }
  const configs = realEstateConfigs?.getInternalRealEstateConfigurations || [];
  const realEstateForRentStatus = configs.common.realEstateForRentStatus,
    realEstateForSaleStatus = configs.common.realEstateForSaleStatus,
    realEstateForSaleAndRentStatus =
      configs.common.realEstateForSaleAndRentStatus,
    projectForSaleTypes = configs.common.projectForSaleTypes,
    projectForRentTypes = configs.common.projectForRentTypes,
    projectStatusType = configs.common.projectStatusType;
  const priceToBuy = configs.filter.project?.priceToBuy,
    priceToBuyV2 = priceToBuy?.map(({ from, to, key, value }) => ({
      from: from,
      to: to,
      key: key,
      value: value,
      unit: initialPriceUnitForSale,
    }));
  return {
    priceToBuyV2,
    realEstateForRentStatus,
    realEstateForSaleStatus,
    realEstateForSaleAndRentStatus,
    projectForRentTypes,
    projectForSaleTypes,
    projectStatusType,
    loading,
  };
};
