import CalendarFromToDropdown from '@components/calendarFromTo';
import moment from 'moment/moment';
import MyDropdown from '@components/myDropdown';
import React from 'react';

type Props = {
  startDay?: string,
  setStartDay?: Function,
  endDay?: string,
  setEndDay?: Function,
};

export default function DateRangePicker(props: Props) {
  const {
    setEndDay = () => {},
    endDay = null,
    setStartDay = () => {},
    startDay = null,
  } = props;
  const isValidDate = moment(startDay).isValid() && moment(endDay).isValid();
  return (
    <MyDropdown
      key={Math.random()}
      DropdownComponent={CalendarFromToDropdown}
      buttonDropdownClassName="px-2.5 border border-blue-main rounded-lg h-40px"
      dropdownContext={{
        startDay,
        endDay,
        setValue: nextValue => {
          setStartDay(nextValue.startDay);
          setEndDay(nextValue.endDay);
        },
        maxDate: moment(),
        disabledAddTo: true,
      }}
    >
      <div className="w-214px flex flex-row items-center justify-between space-x-2">
        <div className="w-full flex flex-row items-center justify-between space-x-1">
          <div className="text-sm text-blue-main text-left ">
            {isValidDate
              ? `${moment.unix(startDay).format('DD/MM/YYYY')} - ${moment
                  .unix(endDay)
                  .format('DD/MM/YYYY')}`
              : 'Chọn giá trị'}
          </div>
          {isValidDate && (
            <img
              onClick={() => {
                setStartDay(null);
                setEndDay(null);
              }}
              alt="ico"
              src="/svg/xIcon.svg"
              className="w-3 h-3 cursor-pointer"
            />
          )}
        </div>
        <img alt="ico" src="/svg/calendar-icon-dropdown.svg" />
      </div>
    </MyDropdown>
  );
}
