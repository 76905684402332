import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_DETAILS } from '@utils/graphql';

export const useGetRealEstateDetailV2 = realEstateId => {
  const { data, loading } = useQuery(GET_REAL_ESTATE_DETAILS, {
    variables: {
      realEstateId: realEstateId,
    },
    skip: !realEstateId,
  });
  return { data2: data, loading };
};
