import { useLocation } from 'react-router-dom';
import { RouterLocation } from 'typeDefinition';
export const useGetPublishRealEstateType = () => {
  const { pathname }: RouterLocation = useLocation();
  const product = pathname === '/publish/product';
  const authorized = pathname.includes('authorized');
  const premium = pathname.includes('premium');
  return {
    product,
    authorized,
    premium,
  };
};
