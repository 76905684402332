import styles from './uploadMedia.module.scss';
import LeftButton from '@components/uploadMedia/components/leftButton';
import RightUploadMedia from '@components/uploadMedia/components/rightUploadMedia';
import React, { useState } from 'react';
import FilesResult from '@components/uploadMedia/components/fileResult';
import { useUploadImage } from '@utils/hooks/common/useUploadImage';
import UploadErrorModal from '../../modals/uploadError';
import { isEqual } from 'lodash';
type UploadMediaProps = {
  mediaUrls?: Array,
  onChange?: Function,
  isEdit?: boolean,
  isContinue?: boolean,
  setIsContinue?: Function,
};
export default React.memo(UploadMedia, isEqual);
function UploadMedia({
  mediaUrls,
  onChange,
  isEdit,
  isContinue,
  setIsContinue,
}: UploadMediaProps) {
  const [option, setOption] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { doUploadImage, removeFile, push } = useUploadImage({
    mediaUrls,
    setMediaUrls: onChange,
    setOpenModal,
    isEdit,
    isContinue,
    setIsContinue,
    fieldName: 'mediaUrls',
  });
  return (
    <div className="w-full space-y-4">
      <div
        style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
        className="w-full bg-white rounded-lg p-3"
      >
        <div className="text-base text-blue-main font-medium">
          Hình ảnh & video ({mediaUrls?.length || 0}/{9})
        </div>
        <div className="w-full mt-7">
          <div className={styles.container}>
            <LeftButton setOption={setOption} />
            <RightUploadMedia
              mediaUrls={mediaUrls}
              push={push}
              doUploadImage={doUploadImage}
              option={option}
            />
          </div>
        </div>
        <UploadErrorModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          type="img"
        />
      </div>
      <div className="flex-1">
        <FilesResult onRemove={removeFile} imageUrls={mediaUrls} />
      </div>
    </div>
  );
}
