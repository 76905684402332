import { useMutation } from '@apollo/client';
import { UPDATE_HIDDEN_STATUS_PRODUCT } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';

export const useHiddenRealEstateProduct = (
  realEstateIds,
  setShowModalHidden,
  setShowModalAppear
) => {
  const { setListSelected } = useProductContext();
  const [hiddenProduct, { loading, client }] = useMutation(
    UPDATE_HIDDEN_STATUS_PRODUCT
  );
  const toast = useToast();
  const onHidden = useCallback(async () => {
    try {
      await hiddenProduct({
        variables: {
          realEstateIds: realEstateIds,
          isHidden: true,
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Ẩn sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModalHidden(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Ẩn sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [
    realEstateIds,
    setShowModalHidden,
    client,
    hiddenProduct,
    setListSelected,
  ]);
  const onAppear = useCallback(async () => {
    try {
      await hiddenProduct({
        variables: {
          realEstateIds: realEstateIds,
          isHidden: false,
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Bỏ ẩn sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModalAppear(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Bỏ ẩn sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [
    realEstateIds,
    setShowModalAppear,
    client,
    hiddenProduct,
    setListSelected,
  ]);
  return { onHidden, loading, onAppear };
};
