import { useQuery } from '@apollo/client';
import { REAL_ESTATE_HISTORY } from '@utils/graphql';
import { useMemo, useState } from 'react';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';
import { cleanObj } from '@utils/helper';

export const useGetRealEstateHistory = ({ realEstateId, params = {} } = {}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = useMemo(() => {
    return {
      realEstateId,
      ...cleanObj(params),
    };
  }, [realEstateId, params]);
  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(REAL_ESTATE_HISTORY, {
    variables,
    skip: !realEstateId.length,
  });
  const data = rawData?.realEstateHistory.items || [];
  const hasNextPage = rawData?.realEstateHistory.hasNextPage,
    page = rawData?.realEstateHistory.page || 1;

  const onLoadMore = useLoadMore({
    variables,
    isLoadingMore,
    setIsLoadingMore,
    hasNextPage,
    currentPage: page,
    fetchMore,
  });
  return { data, loading: loading || isLoadingMore, onLoadMore, hasNextPage };
};
