import styles from '@components/table/table.module.scss';
import React, { useState } from 'react';
import { useHeaderTableEditUserHistoryConfigs } from '../configs';
import { useConvertDataUserEditHistory } from '@utils/hooks/userEditHistory/useConvertDataUserEditHistory';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';
import MyModalV2 from '@components/modalV2/myModalV2';
import ModalViewMedia from '../../modalViewMedia';

type Props = {
  index?: number,
  item?: Object,
  isPersonal?: boolean,
};

export default function TableRow(props: Props) {
  const { index, item, isPersonal } = props;

  const [showModal, setShowModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const data = useConvertDataUserEditHistory(
    item,
    setShowModal,
    setShowAttachmentModal,
    isPersonal
  );
  const headerTableEditUserConfigs =
    useHeaderTableEditUserHistoryConfigs(isPersonal);

  const configs = headerTableEditUserConfigs
    ?.filter(e => !e?.isHidden)
    .map(item => {
      const foundConfigs = data
        ?.filter(e => !e?.isHidden)
        .find(e => e.key === item.key);
      if (foundConfigs) {
        return { ...item, ...foundConfigs };
      }
    });

  const listId = item?.ops
    ?.filter(e => e.path.includes('idImages'))
    .reduce((cr, item) => {
      const value = item?.changes?.value;
      const valueIsArray = Array.isArray(value);
      if (valueIsArray) {
        return [...cr, ...value];
      } else {
        return [...cr, value];
      }
    }, []);
  const listIdAttachments = item?.ops
    ?.filter(e => e.path.includes('attachments'))
    .reduce((cr, item) => {
      const value = item?.changes?.value;
      const valueIsArray = Array.isArray(value);
      if (valueIsArray) {
        return [...cr, ...value];
      } else {
        return [...cr, value];
      }
    }, []);

  const [listUrls, setListUrls] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(listId);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [item, listId]);

  const [listUrlsAttachment, setListUrlsAttachment] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(
          Array.isArray(listIdAttachments)
            ? listIdAttachments
            : [listIdAttachments]
        );
        setListUrlsAttachment(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [item, listIdAttachments, setListUrlsAttachment]);
  return (
    <>
      <div
        className="flex w-full border-t border-gray-main"
        style={{ minHeight: 40 }}
      >
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        >
          <div className={`text-14 text-black-main ${styles.textHead}`}>
            {index + 1}
          </div>
        </div>
        {configs?.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles.headContainer} ${item?.className} items-center`}
            >
              <div
                className={`text-14 text-black-main ${styles.textHead} w-full text-center`}
              >
                {typeof item?.value === 'function'
                  ? item?.value()
                  : item?.value || '-'}
              </div>
            </div>
          );
        })}
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={0} listMedia={listUrls || []} />
      </MyModalV2>
      <MyModalV2
        open={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
      >
        <ModalViewMedia activeIndex={0} listMedia={listUrlsAttachment} />
      </MyModalV2>
    </>
  );
}
