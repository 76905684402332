import { useLazyQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATES_ATTACHMENTS } from '@utils/graphql';

export const useGetProductAttachments = realEstateId => {
  const [getProductAttachments, { data, loading, error }] = useLazyQuery(
    GET_INTERNAL_REAL_ESTATES_ATTACHMENTS,
    {
      variables: {
        realEstateId: realEstateId,
      },
      skip: !realEstateId,
      onCompleted: () => {},
    }
  );
  return {
    getProductAttachments,
    data,
    loading,
    attachments: data?.getProductAttachments?.attachments,
    error,
  };
};
