import { useMemo, useState } from 'react';
import { cleanObj } from '@utils/helper';
import { useQuery } from '@apollo/client';
import { GET_USER_HISTORIES } from '@utils/graphql';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';

export const useGetUserHistories = ({ userId, params = {} } = {}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = useMemo(() => {
    return { userId, limit: 10, page: 1, ...cleanObj(params) };
  }, [userId, params]);
  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_USER_HISTORIES, {
    variables,
    skip: !userId,
  });

  const data = rawData?.getUserHistories?.items || [];
  const hasNextPage = rawData?.getUserHistories?.hasNextPage,
    page = rawData?.getUserHistories?.page || 1;
  const onLoadMore = useLoadMore({
    variables,
    isLoadingMore,
    setIsLoadingMore,
    hasNextPage,
    currentPage: page,
    fetchMore,
  });

  return { data, loading: loading || isLoadingMore, onLoadMore, hasNextPage };
};
