import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  rearKey?: string,
  rearFrom?: number,
  rearTo?: number,
  setRearKey?: Function,
  setRearFrom?: Function,
  setRearTo?: Function,
  onResetSelected?: Function,
};
export default function InputRearDropdown({
  rearKey,
  rearFrom,
  rearTo,
  setRearKey,
  setRearFrom,
  setRearTo,
  onResetSelected,
}: Props) {
  const { townhouse } = configs;
  const { width } = townhouse || {};
  const convertRear =
    width?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle="Mặt hậu (m)"
      renderToValue={100}
      progress={50.5}
      sliderTitle={'Mặt hậu (m)'}
      key={Math.random() + rearFrom + rearTo}
      theValueIsShow={100}
      listSelections={convertRear || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setRearKey(key);
        setRearFrom(from);
        setRearTo(to);
      }}
      keyValue={rearKey}
      from={rearFrom}
      to={rearTo}
      unit={'m'}
      min={0}
      max={100}
      step={1}
      additionalItems={[
        { key: '', from: 0, to: 101, value: 'Tất cả' },
        { key: '', from: null, to: 0, value: 'Không có' },
      ]}
    />
  );
}
