import React, { useCallback } from 'react';
import { MODE } from '@utils/configs/map/mapConfigs';
import styles from '../customToolMap.module.scss';
import MyButton from '@components/button';

type Props = {
  measurePoints?: Array,
  renderTotal?: number,
  renderArea?: number,
  clear?: Function,
  setClosePolygon?: Function,
  setMode?: Function,
  area?: any,
};

export default function MeasuringNotification(props: Props) {
  const {
    measurePoints,
    renderArea,
    renderTotal,
    clear,
    setClosePolygon,
    setMode,
    area,
  } = props;

  const onFinish = useCallback(() => {
    clear();
    setMode(MODE.NONE);
    setClosePolygon(false);
  }, [clear, setClosePolygon, setMode]);

  return (
    <div className={styles.poUpMeasuring}>
      <div className="w-full bg-white rounded-lg p-4 border border-blue-main">
        <p className="text-sm font-bold whitespace-nowrap">
          Đo khoảng cách & diện tích
        </p>
        <p className="text-sm text-gray-500 mt-1 whitespace-nowrap">
          Chấm từng điểm trên bản đồ để tính khoảng cách
        </p>
        <div className="flex w-full items-start items-end">
          <div className="flex flex-1 flex-row mt-4">
            <div className="flex flex-col">
              <p className="text-sm whitespace-nowrap">Tổng chiều dài</p>
              <p className="text-sm font-bold">
                {measurePoints.length > 1 ? renderTotal : '---'}
              </p>
            </div>
            {area && (
              <div className="ml-6">
                <p className="text-sm whitespace-nowrap">Tổng diện tích</p>
                <p className="text-sm font-bold">{renderArea}</p>
              </div>
            )}
          </div>
          <div className="flex w-full flex-row mt-2.5 space-x-2 ml-10">
            <div className="w-full">
              <MyButton
                labelStyle="whitespace-nowrap"
                wrapperClass=" border border-blue-main"
                type="white"
                label="Kết thúc"
                onPress={onFinish}
              />
            </div>
            <div className="w-full">
              {measurePoints.length >= 1 && (
                <MyButton
                  labelStyle="whitespace-nowrap"
                  label="Đo mới"
                  onPress={() => {
                    setClosePolygon(false);
                    clear();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
