import { View } from 'react-native-web';
import styles from '@components/table/table.module.scss';
import React, { useMemo } from 'react';

import SearchTextDropdown from '../../manageRealEstate/components/filterButtons/searchTextDropdown';
import DemandDropdown from '../../manageRealEstate/components/filterButtons/demandDropdown';
import RealEstateTypeDropdown from '../../manageRealEstate/components/filterButtons/realEstateTypeDropdown';
import StreetDropdown from '../../manageRealEstate/components/filterButtons/streetDropdown';
import ProvinceDropdown from '../../manageRealEstate/components/filterButtons/provinceDropdown';
import DistrictDropdown from '../../manageRealEstate/components/filterButtons/districtDropdown';
import WardDropdown from '../../manageRealEstate/components/filterButtons/wardDropdown';
import CalendarDropdown from '../../manageRealEstate/components/filterButtons/calendarDropdown';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { HoChiMinhId } from '../../../../constant';
import ProductCodeDropdown from '../../manageRealEstate/components/filterButtons/productCodeDropdown';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import {
  getListPostStatus,
  getProductStatusV2,
  getUnique,
  listPostStatus,
} from '@utils/helper';
import { useGetLocation } from '../configs';
import RenderResultFilterHeader from './renderSameProductResultFilterHeader';
import { useSameProductContext } from '../product/context';
import InputPriceDropdown from '../inputDropdownGroup/inputPriceDropdown';
import InputWidthDropdown from '../inputDropdownGroup/inputWidthDropdown';
import InputLengthDropdown from '../inputDropdownGroup/inputLengthDropdown';
import InputRearDropdown from '../inputDropdownGroup/inputRearDropdown';
import InputAreaDropdown from '../inputDropdownGroup/inputAreaDropdown';
import InputFloorCountDropdown from '../inputDropdownGroup/inputFloorCountDropdown';

export default function RenderHeader() {
  const { setListSelected, stateFilter, setStateFilterAt } =
    useSameProductContext();
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { realEstateForRentStatus, realEstateForSaleStatus } =
      realEstateConfigurations;
  const {
    isTabProduct,
    isTabMyProduct,
    isTabWaitingForApproval,
    isTabProductPosted,
    isTabProductPremium,
    isTabProductAuthorize,
    isTabDeleteProduct,
    isTabHiddenProduct,
  } = useGetLocation();
  const { forRentRealEstateTypes, forSellRealEstateTypes } = configsRemote;
  const tempStatus = [
      ...(realEstateForRentStatus || []),
      ...(realEstateForSaleStatus || []),
    ],
    statusConfigs = getUnique(tempStatus, 'key');
  const realEstateStatus =
    stateFilter?.isForSell === 'ForSale'
      ? statusConfigs?.filter(
          e =>
            ![
              'rented',
              'renting',
              'waitingForContract',
              'signedContract',
            ].includes(e.key)
        )
      : statusConfigs?.filter(
          e =>
            ![
              'sold',
              'onSale',
              'waitingForContract',
              'signedContract',
            ].includes(e.key)
        );

  const finalRealEstateForSaleAndRentStatus = useMemo(() => {
      return (
        realEstateStatus?.map(item => ({
          ...item,
          icon: '/svg/ic_triangle.svg',
          nextColor: getProductStatusV2(item.key),
          currentColor: '#48FF3E',
        })) || []
      );
    }, [statusConfigs]),
    selectionProductCode = useMemo(() => {
      return [
        ...finalRealEstateForSaleAndRentStatus,
        {
          key: 'isAbandoned',
          value: 'Sản phẩm bị cảnh báo',
          nextColor: getProductStatusV2('rejected'),
          icon: '/svg/icon_warning.svg',
        },
      ];
    }, [finalRealEstateForSaleAndRentStatus]),
    selectionProductCodeMyProduct = useMemo(() => {
      return [
        ...finalRealEstateForSaleAndRentStatus,
        {
          key: 'rejected',
          value: 'Sản phẩm bị từ chối',
          nextColor: getProductStatusV2('rejected'),
          icon: '/svg/icon_warning.svg',
        },
        {
          key: 'pending',
          value: 'Sản phẩm đang chờ duyệt',
          nextColor: getProductStatusV2('pending'),
          icon: '/svg/icon_pending.svg',
        },
      ];
    }, [finalRealEstateForSaleAndRentStatus]),
    selectionProductApproval = useMemo(() => {
      return [
        {
          key: 'isExpired12hours',
          value: 'Sản phẩm quá 12h chưa được duyệt',
          icon: '/svg/icon_warning.svg',
        },
      ];
    }, []);
  const listPostStatusFinal = listPostStatus?.map(item => ({
    ...item,
    icon: '/svg/ic_triangle.svg',
    nextColor: getListPostStatus(item.key),
    currentColor: '#48FF3E',
  }));

  let productSelections;
  if (isTabMyProduct) {
    productSelections = selectionProductCodeMyProduct;
  } else if (isTabProduct) {
    productSelections = selectionProductCode;
  } else if (isTabWaitingForApproval) {
    productSelections = selectionProductApproval;
  } else if (
    (isTabProductPosted || isTabProductPremium || isTabProductAuthorize) &&
    !(isTabHiddenProduct || isTabDeleteProduct)
  ) {
    productSelections = listPostStatusFinal;
  } else {
    productSelections = finalRealEstateForSaleAndRentStatus;
  }

  return (
    <View
      style={{ borderTopLeftRadius: 9, borderTopRightRadius: 9, width: '100%' }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </div>
          </div>
          <div className={`${styles.headContainer} w-210px items-center`}>
            <SearchTextDropdown
              title="Mã sản phẩm"
              search={stateFilter.realEstateId}
              setSearch={value => {
                setStateFilterAt('realEstateId')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-180px items-center justify-center`}
          >
            <ProductCodeDropdown
              selections={productSelections}
              value={stateFilter.postStatus}
              setValue={value => {
                setStateFilterAt('postStatus')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-140px items-center justify-center`}
          >
            <DemandDropdown
              demand={'sell'}
              value={stateFilter.isForSell}
              setValue={value => {
                setStateFilterAt('isForSell')(value);
                setStateFilterAt('postStatus')([]);
                setStateFilterAt('realEstateType')(['townhouse']);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-145px items-center justify-center`}
          >
            <RealEstateTypeDropdown
              title="Loại BĐS"
              selections={
                stateFilter.isForSell === 'ForSale'
                  ? forSellRealEstateTypes
                  : forRentRealEstateTypes
              }
              setValue={value => {
                setListSelected?.clear();
                setStateFilterAt('realEstateType')(value);
              }}
              value={stateFilter.realEstateType}
            />
          </div>

          <div
            className={`${styles.headContainer} w-140px items-center justify-center`}
          >
            <SearchTextDropdown
              title="Số nhà"
              search={stateFilter.houseNumber}
              setSearch={value => {
                setStateFilterAt('houseNumber')(value);
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-170px items-center justify-center`}
          >
            <StreetDropdown
              value={stateFilter.street}
              setValue={item => {
                setStateFilterAt('street')(item.key);
                setStateFilterAt('streetName')(item.value);
              }}
              wardId={stateFilter.ward}
              districtId={stateFilter.district}
            />
          </div>

          <div
            className={`${styles.headContainer} w-140px items-center justify-center`}
          >
            <WardDropdown
              value={stateFilter.ward}
              setValue={item => {
                setStateFilterAt('ward')(item.key);
                setStateFilterAt('wardName')(item.value);
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
              districtId={stateFilter.district}
              streetId={stateFilter.street}
            />
          </div>
          <div
            className={`${styles.headContainer} w-145px items-center justify-center`}
          >
            <DistrictDropdown
              value={stateFilter.district}
              setValue={item => {
                setStateFilterAt('district')(item.key);
                setStateFilterAt('districtName')(item.value);
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
              provinceId={HoChiMinhId}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <ProvinceDropdown
              value={stateFilter.province}
              setValue={item => {
                setStateFilterAt('province')(item.key);
                setStateFilterAt('provinceName')(item.value);
                setStateFilterAt('district')('');
                setStateFilterAt('districtName')('');
                setStateFilterAt('ward')('');
                setStateFilterAt('wardName')('');
                setStateFilterAt('street')('');
                setStateFilterAt('streetName')('');
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <SearchTextDropdown
              title="Tên chủ nhà"
              search={stateFilter.contactName}
              setSearch={value => {
                setStateFilterAt('contactName')(value);
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-154px items-center justify-center`}
          >
            <InputPriceDropdown
              isForSell={stateFilter?.isForSell}
              priceKey={stateFilter.priceKey}
              priceFrom={stateFilter.priceFrom}
              priceTo={stateFilter.priceTo}
              setPriceKey={value => setStateFilterAt('priceKey')(value)}
              setPriceFrom={value => setStateFilterAt('priceFrom')(value)}
              setPriceTo={value => setStateFilterAt('priceTo')(value)}
              onResetSelected={() => {
                setListSelected.clear();
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-124px items-center justify-center`}
          >
            <InputWidthDropdown
              widthFrom={stateFilter.widthFrom}
              widthTo={stateFilter.widthTo}
              widthKey={stateFilter.widthKey}
              setWidthKey={value => setStateFilterAt('widthKey')(value)}
              setWidthFrom={value => setStateFilterAt('widthFrom')(value)}
              setWidthTo={value => setStateFilterAt('widthTo')(value)}
              onResetSelected={value => {
                setListSelected.clear();
                setStateFilterAt('width')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-124px items-center justify-center`}
          >
            <InputLengthDropdown
              lengthFrom={stateFilter.lengthFrom}
              lengthTo={stateFilter.lengthTo}
              lengthKey={stateFilter.lengthKey}
              setLengthKey={value => setStateFilterAt('lengthKey')(value)}
              setLengthFrom={value => setStateFilterAt('lengthFrom')(value)}
              setLengthTo={value => setStateFilterAt('lengthTo')(value)}
              onResetSelected={value => {
                setListSelected.clear();
                setStateFilterAt('length')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-124px items-center justify-center`}
          >
            <InputRearDropdown
              rearFrom={stateFilter.rearFrom}
              rearTo={stateFilter.rearTo}
              rearKey={stateFilter.rearKey}
              setRearKey={value => setStateFilterAt('rearKey')(value)}
              setRearFrom={value => setStateFilterAt('rearFrom')(value)}
              setRearTo={value => setStateFilterAt('rearTo')(value)}
              onResetSelected={value => {
                setListSelected.clear();
                setStateFilterAt('rear')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <InputAreaDropdown
              areaFrom={stateFilter.areaFrom}
              areaTo={stateFilter.areaTo}
              title="Tổng diện tích (m²)"
              setAreaKey={value => setStateFilterAt('areaKey')(value)}
              setAreaFrom={value => setStateFilterAt('areaFrom')(value)}
              setAreaTo={value => setStateFilterAt('areaTo')(value)}
              onResetSelected={value => {
                setListSelected.clear();
                setStateFilterAt('area')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-133px items-center justify-center`}
          >
            <InputFloorCountDropdown
              floorCountFrom={stateFilter.floorCountFrom}
              floorCountTo={stateFilter.floorCountTo}
              floorCountKey={stateFilter.floorCountKey}
              setFloorCountFrom={value =>
                setStateFilterAt('floorCountFrom')(value)
              }
              setFloorCountTo={value => setStateFilterAt('floorCountTo')(value)}
              setFloorCountKey={value =>
                setStateFilterAt('floorCountKey')(value)
              }
              onResetSelected={() => {
                setListSelected.clear();
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-218px items-center justify-center`}
          >
            <SearchTextDropdown
              search={stateFilter.creator}
              setSearch={value => {
                setStateFilterAt('creator')(value);
              }}
              title="Người tạo"
            />
          </div>
          <div
            className={`${styles.headContainer} w-185px items-center justify-center`}
          >
            <CalendarDropdown
              start={stateFilter.createdAtFrom}
              end={stateFilter.createdAtTo}
              setStart={value => setStateFilterAt('createdAtFrom')(value)}
              setEnd={value => setStateFilterAt('createdAtTo')(value)}
              onResetSelected={() => {}}
              title="Ngày tạo"
            />
          </div>

          <div
            className={`${styles.headContainer} w-185px items-center justify-center`}
          >
            <CalendarDropdown
              title="Ngày chỉnh sửa"
              start={stateFilter.updatedAtFrom}
              end={stateFilter.updatedAtTo}
              setStart={value => setStateFilterAt('updatedAtFrom')(value)}
              setEnd={value => setStateFilterAt('updatedAtTo')(value)}
              onResetSelected={() => {}}
            />
          </div>

          <div
            className={`${styles.headContainer} w-218px items-center justify-center`}
          >
            <SearchTextDropdown
              search={stateFilter.approvedBy}
              setSearch={value => {
                setStateFilterAt('approvedBy')(value);
              }}
              title="Người duyệt"
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <CalendarDropdown
              start={stateFilter.approvedAtFrom}
              end={stateFilter.approvedAtTo}
              setStart={value => setStateFilterAt('approvedAtFrom')(value)}
              setEnd={value => setStateFilterAt('approvedAtTo')(value)}
              onResetSelected={() => {}}
              title="Ngày duyệt"
            />
          </div>
        </View>
      </View>
      <RenderResultFilterHeader productSelections={productSelections} />
    </View>
  );
}
