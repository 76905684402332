import React, { useState } from 'react';
import { CommentContext } from '@components/comment/context';
import ChildrenComment from '@components/comment/component/childrenComment';
import SeeMore from '@components/seeMore';

type Props = {
  listComment?: Array,
  repComment?: Function,
  loading?: boolean,
  hasNextPage?: boolean,
  onLoadMore?: Function,
  newId?: string,
};
export default function Comment(props: Props) {
  const {
    listComment = [],
    repComment,
    hasNextPage,
    loading,
    onLoadMore,
    newId,
  } = props;
  const [repCommentId, setRepCommentId] = useState(null);
  return (
    <div className="flex flex-col space-y-2 mt-2">
      {listComment?.length > 0 ? (
        <CommentContext.Provider
          value={{
            setRepCommentId,
            repComment,
            repCommentId,
          }}
        >
          {listComment?.map((item, index) => {
            return (
              <ChildrenComment
                newId={newId}
                id={item?.id}
                childrenComment={item?.lowerComment}
                key={index}
                comment={item?.content}
                time={item?.createdAt}
                owner={item?.user}
              />
            );
          })}
        </CommentContext.Provider>
      ) : (
        <span className="text-gray-500 pt-10">
          Chưa có ghi chú nào về sản phẩm này
        </span>
      )}
      <SeeMore
        hasNextPage={hasNextPage}
        loading={loading}
        onLoadMore={onLoadMore}
      />
    </div>
  );
}
