import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/label';
import MyInput from '@components/myInput';
import RadioButton from '@components/radio';
import {
  hasAlleyConfigs,
  hasSeaViewConfigs,
  isNewConstructionConfigs,
} from '../configs';
import DropdownSelection from '@components/dropdownSelection';
import {
  directions,
  realEstateForSaleStatus,
  realEstateForRentStatus,
  warehouseType as wareHouseTypes,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { building } from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import { LATITUDE_PATTERN, LONGITUDE_PATTERN } from '@utils/configs/regex';
import RadioWithInput from '@components/radioWithInput/radioWithInput';
import { DocTienBangChuV2, numberWithDots } from '@utils/helper';
import Section from '@components/section';
import { maxLength } from '../../../../constant';
import AddressV2 from '@components/addressV2';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export const fieldConditionConfigs = {
  province: {
    dataVisible: state => {
      return state?.province?.length > 0;
    },
  },
  district: {
    dataVisible: state => state.district?.length > 0,
  },
  ward: {
    dataVisible: state => state?.ward?.length > 0,
  },
  street: {
    dataVisible: state => state?.street?.length > 0,
  },
  houseNumber: {
    dataVisible: state => state?.houseNumber?.length > 0,
  },

  lat: {
    dataVisible: state => state?.lat > 0,
  },
  long: {
    dataVisible: state => state?.long > 0,
  },
  hasAlley: {
    dataVisible: state => typeof state?.hasAlley === 'boolean',
  },
  width: {
    dataVisible: state => state?.width,
  },
  length: {
    dataVisible: state => state?.length,
  },
  rear: {
    dataVisible: state => state?.rear,
  },
  floorCount: {
    dataVisible: state =>
      !['apartment', 'condotel'].includes(state?.type) && state?.floorCount > 0,
  },
  isNewConstruction: {
    dataVisible: state => typeof state?.isNewConstruction === 'boolean',
  },
  price: {
    dataVisible: state => state?.price?.length > 0,
  },
  priceOfOwner: {
    dataVisible: state => state?.priceOfOwner?.toString()?.length > 0,
  },
  commission: {
    dataVisible: state => state?.commission?.length > 0,
  },
  bedroomCount: {
    dataVisible: state =>
      ['townhouse', 'villa', 'apartment', 'penthouse', 'condotel'].includes(
        state?.type
      ) && state?.bedroomCount > 0,
  },
  toiletCount: {
    dataVisible: state => state?.type !== 'land' && state?.toiletCount > 0,
  },
  direction: {
    dataVisible: state => state?.direction?.length > 0,
  },
  project: {
    dataVisible: state => state?.project?.length > 0,
  },
  investor: {
    dataVisible: state =>
      !['townhouse', 'villa', 'building', 'warehouse'].includes(state?.type) &&
      state?.investor?.length > 0,
  },
  status: {
    dataVisible: state => state?.status?.length > 0,
  },
  alleyWidth: {
    dataVisible: state => state?.hasAlley && state?.alleyWidth?.length > 0,
  },
  alleyTurnCount: {
    dataVisible: state => state?.hasAlley && state?.alleyTurnCount > 0,
  },
  buildingType: {
    dataVisible: state =>
      state?.type === 'building' && state?.buildingType?.length > 0,
  },
  roomCount: {
    dataVisible: state => state?.type === 'hotel' && state?.roomCount > 0,
  },
  hotelStar: {
    dataVisible: state => state?.type === 'hotel' && state?.hotelStar > 0,
  },
  warehouseType: {
    dataVisible: state =>
      ['warehouse', 'land'].includes(state?.type) &&
      state?.warehouseType?.length > 0,
  },
  block: {
    dataVisible: state =>
      ['apartment', 'penthouse', 'land'].includes(state?.type) &&
      state?.block?.length > 0,
  },
  roomNumber: {
    dataVisible: state =>
      ['apartment', 'penthouse'].includes(state?.type) && state?.roomNumber > 0,
  },
  onFloor: {
    dataVisible: state =>
      ['apartment', 'penthouse', 'condotel'].includes(state?.type) &&
      state?.onFloor > 0,
  },
  clearanceArea: {
    dataVisible: state =>
      ['apartment', 'penthouse', 'condotel'].includes(state?.type) &&
      state?.onFloor > 0,
  },
  bedRoomArea: {
    dataVisible: state =>
      ['apartment', 'penthouse'].includes(state?.type) &&
      state?.bedRoomArea > 0,
  },
  shophouseField: {
    dataVisible: state =>
      ['shophouse'].includes(state?.type) && state?.shophouseField > 0,
  },
  constructionArea: {
    dataVisible: state => state?.constructionArea > 0,
  },
  warehouseRoomCount: {
    dataVisible: state =>
      state?.type === 'warehouse' && state?.warehouseRoomCount > 0,
  },
};
export const getTotalFieldCount = props => {
  const field = [
    'clearanceArea',
    'investor',
    'province',
    'ward',
    'district',
    'street',
    'houseNumber',
    'lat',
    'long',
    'hasAlley',
    'width',
    'length',
    'rear',
    'floorCount',
    'isNewConstruction',
    'price',
    'priceOfOwner',
    'commission',
    'bedroomCount',
    'toiletCount',
    'direction',
    'project',
    'status',
    'alleyWidth',
    'alleyTurnCount',
    'buildingType',
    'roomCount',
    'hotelStar',
    'warehouseType',
    'block',
    'roomNumber',
    'onFloor',
    'bedRoomArea',
    'shophouseField',
    'constructionArea',
    'warehouseRoomCount',
  ];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
type Props = {
  isPublishProduct?: boolean,
  priceFieldName?: string,
};
export const ProductDetails = ({
  isPublishProduct,
  priceFieldName = 'price',
}: Props) => {
  const { permissions } = useGetMe();
  const { control, watch, setValue, clearErrors } = useFormContext();
  const clearanceArea = watch('clearanceArea');
  const investor = watch('investor');
  const isForSell = watch('isForSell');
  const type = watch('typeRealEstate');
  const province = watch('province');
  const district = watch('district');
  const ward = watch('ward');
  const street = watch('street');
  const houseNumber = watch('houseNumber');
  const lat = watch('lat');
  const long = watch('long');
  const hasAlley = watch('hasAlley');
  const width = watch('width');
  const length = watch('length');
  const rear = watch('rear');
  const floorCount = watch('floorCount');
  const isNewConstruction = watch('isNewConstruction');
  const price = watch(priceFieldName);
  const commission = watch('commission');
  const bedroomCount = watch('bedroomCount');
  const toiletCount = watch('toiletCount');
  const direction = watch('direction');
  const project = watch('project');
  const status = watch('status');
  const alleyWidth = watch('alleyWidth');
  const alleyTurnCount = watch('alleyTurnCount');
  const buildingType = watch('buildingType');
  const roomCount = watch('roomCount');
  const hotelStar = watch('hotelStar');
  const warehouseType = watch('warehouseType');
  const block = watch('block');
  const roomNumber = watch('roomNumber');
  const onFloor = watch('onFloor');
  const bedRoomArea = watch('bedRoomArea');
  const shophouseField = watch('shophouseField');
  const constructionArea = watch('constructionArea');
  const warehouseRoomCount = watch('warehouseRoomCount');
  const data = {
    type,
    province,
    ward,
    district,
    clearanceArea,
    street,
    houseNumber,
    lat,
    long,
    hasAlley,
    width,
    length,
    rear,
    floorCount,
    isNewConstruction,
    [priceFieldName]: price,
    commission,
    bedroomCount,
    toiletCount,
    direction,
    project,
    investor,
    status,
    alleyTurnCount,
    alleyWidth,
    buildingType,
    hotelStar,
    roomCount,
    warehouseType,
    roomNumber,
    block,
    onFloor,
    bedRoomArea,
    shophouseField,
    constructionArea,
    warehouseRoomCount,
  };
  let totalCountFields;
  switch (type) {
    case 'villa':
      totalCountFields = hasAlley ? 23 : 21;
      break;
    case 'warehouse':
    case 'hotel':
    case 'penthouse':
      totalCountFields = hasAlley ? 24 : 22;
      break;
    case 'land':
      totalCountFields = hasAlley ? 21 : 19;
      break;
    case 'apartment':
      totalCountFields = hasAlley ? 26 : 24;
      break;
    default:
      totalCountFields = hasAlley ? 23 : 21;
  }
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    setValue('status', isForSell === 'forSale' ? 'onSale' : 'renting');
  }, [isForSell]);
  return (
    <Section>
      <div className="text-base text-blue-main font-medium">
        Chi tiết bất động sản ({getTotalFieldCount(data).fieldVisibleCount}/
        {isPublishProduct ? totalCountFields - 1 : totalCountFields})
      </div>
      <div className="w-full mt-7 space-y-7">
        <AddressV2
          isShowAdditionalInfo={isPublishProduct}
          isDisabled={!isPublishProduct}
        />
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" label="Tọa độ" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              rules={{
                validate: {
                  checkLong: value =>
                    value?.length === 0
                      ? true
                      : value?.toString()?.match(LONGITUDE_PATTERN)
                      ? true
                      : 'Vui lòng nhập kinh độ hợp lệ',
                },
              }}
              control={control}
              name="long"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    inputClassName="w-full"
                    {...field}
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Kinh độ"
                    onDelete={() => onChange('')}
                  />
                );
              }}
            />
            <Controller
              control={control}
              rules={{
                validate: {
                  checkLat: value =>
                    value?.length === 0
                      ? true
                      : value?.toString()?.match(LATITUDE_PATTERN)
                      ? true
                      : 'Vui lòng nhập kinh độ hợp lệ',
                },
              }}
              name="lat"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    inputClassName="w-full"
                    {...field}
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Vĩ độ"
                    onDelete={() => onChange('')}
                  />
                );
              }}
            />
            <div className="w-full basis-1/3"> </div>
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" isRequired label="Vị trí" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="hasAlley"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="space-y-2">
                    <div className="flex flex-row space-x-8">
                      {hasAlleyConfigs.map((pos, index) => {
                        return (
                          <RadioButton
                            key={index}
                            title={pos.value}
                            isChecked={pos.key === value}
                            onPress={() => {
                              onChange(pos.key);
                              if (!value) {
                                setValue('alleyWidth', '');
                                setValue('alleyTurnCount', '');
                                clearErrors('alleyWidth');
                                clearErrors('alleyTurnCount');
                              }
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        {hasAlley && (
          <div className="w-full flex flex-row space-x-2.5 items-start">
            <Label wrapperClass="w-152px" isRequired label="Số lần quẹo hẻm" />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                rules={{
                  required: hasAlley ? 'Vui lòng chọn số lần quẹo hẻm' : false,
                }}
                control={control}
                name="alleyTurnCount"
                render={({
                  field: { value, onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <div className="w-full">
                      <DropdownSelection
                        hasItemRadio={false}
                        placeholder="Chọn số lần quẹo hẻm"
                        selections={[1, 2, 3, 4, 5].map(e => {
                          return { key: e, value: e };
                        })}
                        value={value}
                        setValue={value => {
                          onChange(value);
                        }}
                        onDelete={() => {
                          onChange('');
                          setValue('status', '');
                        }}
                        invalid={invalid}
                        errorMessage={error?.message}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
        {hasAlley && (
          <div className="w-full flex flex-row space-x-2.5 items-start">
            <Label wrapperClass="w-152px" isRequired label="Độ rộng hẻm" />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                rules={{
                  required: 'Vui lòng nhập độ rộng hẻm',
                  validate: value =>
                    value > maxLength.alleyWidth
                      ? `Độ rộng hẻm không được quá ${maxLength.alleyWidth}m`
                      : true,
                }}
                control={control}
                name="alleyWidth"
                render={({
                  field,
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      inputClassName="w-full"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Nhập độ rộng hẻm trước nhà (m)"
                      onDelete={() => onChange('')}
                      type="text"
                      inputMode="decimal"
                      maxLength={3}
                      hideMaxLength
                    />
                  );
                }}
              />
            </div>
          </div>
        )}

        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label
            wrapperClass="w-152px"
            label={
              type === 'motel' ? (
                'Diện tích phòng'
              ) : ['condotel', 'penthouse', 'apartment'].includes(type) ? (
                <span>
                  Diện tích tim tường (m²){' '}
                  <span className="text-red-500 text-base">*</span>
                </span>
              ) : (
                'Diện tích đất'
              )
            }
          />
          <div className="flex-1">
            <div className="w-full flex flex-col space-y-2">
              <div className="w-full flex space-x-4">
                <Controller
                  rules={{
                    required: 'Vui lòng nhập chiều ngang',
                  }}
                  control={control}
                  name="width"
                  render={({
                    field,
                    field: { onChange },
                    fieldState: { invalid, error },
                  }) => {
                    return (
                      <MyInput
                        {...field}
                        inputClassName="w-1/3"
                        inputInnerClassName="w-full"
                        invalid={invalid}
                        errorMessage={error?.message}
                        placeholder="Ngang (m)"
                        onDelete={() => onChange('')}
                        maxLength={maxLength.width}
                        hideMaxLength
                        type="number"
                        inputMode="decimal"
                        onChange={e =>
                          onChange(e.target.value.replace(/^0/i, ''))
                        }
                      />
                    );
                  }}
                />
                <Controller
                  rules={{
                    required: 'Vui lòng nhập chiều dài',
                  }}
                  control={control}
                  name="length"
                  render={({
                    field,
                    field: { onChange },
                    fieldState: { invalid, error },
                  }) => {
                    return (
                      <MyInput
                        inputClassName="w-1/3"
                        inputInnerClassName="w-full"
                        {...field}
                        invalid={invalid}
                        errorMessage={error?.message}
                        placeholder="Dài (m)"
                        onDelete={() => onChange('')}
                        maxLength={maxLength.length}
                        hideMaxLength={true}
                        type="number"
                        inputMode="decimal"
                        onChange={e =>
                          onChange(e.target.value.replace(/^0/i, ''))
                        }
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="rear"
                  render={({
                    field,
                    field: { onChange },
                    fieldState: { invalid, error },
                  }) => {
                    return (
                      <MyInput
                        inputClassName="w-1/3"
                        inputInnerClassName="w-full"
                        {...field}
                        invalid={invalid}
                        errorMessage={error?.message}
                        placeholder="Mặt hậu (m)"
                        onDelete={() => onChange('')}
                        maxLength={maxLength.rear}
                        hideMaxLength
                        type="number"
                        inputMode="decimal"
                      />
                    );
                  }}
                />
              </div>
              <div
                className={
                  width && length
                    ? 'w-full flex flex-1 space-x-4 text-sm'
                    : 'hidden'
                }
              >
                Tổng diện tích đất:
                <span className="font-bold text-green-500 ml-1">
                  {rear && parseFloat(rear) !== 0
                    ? (
                        ((parseFloat(width) + parseFloat(rear)) / 2) *
                        parseFloat(length)
                      ).toLocaleString('vi-VN', {
                        maximumFractionDigits: 2,
                      })
                    : (width * length).toLocaleString('vi-VN', {
                        maximumFractionDigits: 2,
                      })}
                  m²
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" label="Diện tích sàn (m²)" />
          <div className="w-full flex flex-1 space-x-4">
            <div className="w-full flex flex-col space-y-2">
              <Controller
                rules={{
                  validate: value =>
                    value?.length > maxLength.constructionArea
                      ? `Diện tích sàn không quá ${maxLength.constructionArea} chữ số`
                      : true,
                }}
                control={control}
                name="constructionArea"
                render={({
                  field,
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      inputClassName="w-full"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Nhập diện tích sàn"
                      onDelete={() => onChange('')}
                      type="number"
                      hideMaxLength
                      maxLength={maxLength.constructionArea}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            ['apartment', 'condotel', 'penthouse'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Diện tích thông thủy (m²)" />
          <div className="w-full flex flex-1 space-x-4">
            <div className="w-full flex flex-col space-y-2">
              <Controller
                rules={{
                  validate: value =>
                    value?.length > maxLength.clearanceArea
                      ? `Diện tích thông thủy không quá ${maxLength.clearanceArea} chữ số`
                      : true,
                }}
                control={control}
                name="clearanceArea"
                render={({
                  field,
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      inputClassName="w-full"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Nhập diện tích thông thủy"
                      onDelete={() => onChange('')}
                      type="text"
                      inputMode="decimal"
                      hideMaxLength
                      maxLength={maxLength.violatedLand}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            !['apartment', 'condotel', 'motel'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label
            wrapperClass="w-152px"
            isRequired={type !== 'land'}
            label="Số tầng"
          />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              rules={{
                required: type !== 'land' ? 'Vui lòng chọn số tầng' : false,
                validate: value =>
                  value > maxLength.floor
                    ? `Số tầng không nhập quá ${maxLength.floor} tầng`
                    : true,
              }}
              name="floorCount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <RadioWithInput
                    hasZero={type === 'land'}
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" isRequired label="Hiện trạng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="isNewConstruction"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="space-y-2">
                    <div className="flex flex-row space-x-8">
                      {isNewConstructionConfigs.map((pos, index) => {
                        return (
                          <RadioButton
                            key={index}
                            title={pos.value}
                            isChecked={pos.key === value}
                            onPress={() => onChange(pos.key)}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label
            wrapperClass="w-152px"
            isRequired
            label={isForSell === 'forSale' ? 'Giá (VNĐ)' : 'Giá (VNĐ/tháng)'}
          />
          <div className="w-full flex flex-1 space-x-4">
            <div className="w-full flex flex-col space-y-2">
              <Controller
                rules={{
                  required: 'Vui lòng nhập giá',
                  validate: value =>
                    value?.length > maxLength.price
                      ? `Giá không nhập quá ${maxLength.price} chữ số`
                      : true,
                }}
                control={control}
                name={priceFieldName}
                render={({
                  field,
                  field: { value, onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      inputClassName="w-full"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Ví dụ: 3,700,000"
                      onDelete={() => onChange('')}
                      type={!focused ? undefined : 'number'}
                      hideMaxLength
                      maxLength={20}
                      value={!focused && value ? numberWithDots(value) : value}
                    />
                  );
                }}
              />
              {price && (
                <span className="text-red-500 text-sm font-bold">
                  {DocTienBangChuV2(price)} VNĐ
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" label="Phí hoa hồng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              rules={{
                validate: value =>
                  value?.length > maxLength.commissionDetail
                    ? `Mô tả phí hoa hồng không quá ${maxLength.commissionDetail} ký tự`
                    : true,
              }}
              control={control}
              name="commission"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập mô tả phí hoa hồng"
                    onDelete={() => onChange('')}
                    maxLength={maxLength.commissionDetail}
                    hideMaxLength
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            type === 'hotel'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" isRequired label="Số lượng phòng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              rules={{
                required: type === 'hotel' ? 'Vui lòng nhập số phòng' : false,
              }}
              control={control}
              name="roomCount"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập số phòng"
                    onDelete={() => onChange('')}
                    type="number"
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            type === 'hotel'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" isRequired label="Số sao" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              rules={{
                required: 'Vui lòng chọn số sao',
              }}
              control={control}
              name="hotelStar"
              render={({ field: { value, onChange } }) => {
                return [1, 2, 3, 4, 5, 6].map((item, key) => {
                  return (
                    <RadioButton
                      onPress={() => onChange(item)}
                      isChecked={value === item}
                      key={key}
                      title={item}
                    />
                  );
                });
              }}
            />
          </div>
        </div>
        <div
          className={
            type === 'hotel'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Có view biển" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="hasSeaView"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="space-y-2">
                    <div className="flex flex-row space-x-8">
                      {hasSeaViewConfigs.map((pos, index) => {
                        return (
                          <RadioButton
                            key={index}
                            title={pos.value}
                            isChecked={pos.key === value}
                            onPress={() => onChange(pos.key)}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            ['warehouse', 'land'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label
            wrapperClass="w-152px"
            isRequired={['warehouse'].includes(type)}
            label="Chức năng nhà xưởng"
          />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              rules={{
                required: ['warehouse'].includes(type)
                  ? 'Vui lòng chọn chức năng nhà xưởng'
                  : false,
              }}
              name="warehouseType"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <div className="w-full">
                    <DropdownSelection
                      hasItemRadio={false}
                      placeholder="Chọn loại nhà xưởng"
                      selections={wareHouseTypes}
                      value={value}
                      setValue={value => {
                        onChange(value);
                      }}
                      onDelete={() => {
                        onChange('');
                        setValue('warehouseType', '');
                      }}
                      errorMessage={error?.message}
                      invalid={invalid}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div
          className={
            ['apartment', 'land', 'penthouse', 'condotel'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Thuộc block nào" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="block"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Block A"
                    onDelete={() => onChange('')}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            ['apartment'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Số thứ tự phòng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="roomNumber"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="A-01"
                    onDelete={() => onChange('')}
                  />
                );
              }}
            />
          </div>
        </div>
        {type === 'motel' && (
          <div className="w-full flex flex-row space-x-2.5 items-start">
            <Label wrapperClass="w-152px" label="Có gác hay không" />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                control={control}
                name="hasMezzanine"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="space-y-2">
                      <div className="flex flex-row space-x-8">
                        {hasSeaViewConfigs.map((pos, index) => {
                          return (
                            <RadioButton
                              key={index}
                              title={pos.value}
                              isChecked={pos.key === value}
                              onPress={() => onChange(pos.key)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
        <div
          className={
            ['apartment', 'penthouse', 'condotel', 'motel'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Vị trí tầng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="onFloor"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <RadioWithInput
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            [
              'villa',
              'apartment',
              'penthouse',
              'condotel',
              'townhouse',
              'motel',
            ]?.includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Số phòng ngủ" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="bedroomCount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <RadioWithInput
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>

        <div
          className={
            ['apartment'].includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Diện tích phòng ngủ" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="bedRoomArea"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Diện tích phòng ngủ (m²)"
                    onDelete={() => onChange('')}
                    type="text"
                    maxLength={2}
                    hideMaxLength
                  />
                );
              }}
            />
          </div>
        </div>

        <div
          className={
            type === 'warehouse'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Số phòng kho" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="warehouseRoomCount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <RadioWithInput
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            type === 'building'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" isRequired label="Loại cao ốc" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              rules={{
                required:
                  type === 'building' ? 'Vui lòng chọn loại cao ốc' : false,
              }}
              name="buildingType"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <div className="w-full">
                    <DropdownSelection
                      hasItemRadio={false}
                      placeholder="Chọn loại cao ốc"
                      selections={building.buildingType}
                      value={value}
                      setValue={value => {
                        onChange(value);
                      }}
                      onDelete={() => {
                        onChange('');
                        setValue('buildingType', '');
                      }}
                      errorMessage={error?.message}
                      invalid={invalid}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div
          className={
            !['hotel', 'land']?.includes(type)
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Số nhà vệ sinh" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              rules={{
                validate: value =>
                  value?.length > 3
                    ? 'Số nhà vệ sinh không nhập quá 3 chữ số'
                    : true,
              }}
              control={control}
              name="toiletCount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <RadioWithInput
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            type === 'shophouse'
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Ngành hàng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="shophouseField"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập ngành hàng"
                    onDelete={() => onChange('')}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" label="Hướng" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="direction"
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="w-full">
                    <DropdownSelection
                      hasItemRadio={false}
                      placeholder="Chọn hướng"
                      selections={directions}
                      value={value}
                      setValue={value => {
                        onChange(value);
                      }}
                      onDelete={() => {
                        onChange('');
                        setValue('direction', '');
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-start">
          <Label wrapperClass="w-152px" label="Tên dự án" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="project"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập tên dự án"
                    onDelete={() => onChange('')}
                    maxLength={maxLength.name}
                    hideMaxLength
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className={
            !['townhouse', 'villa', 'building', 'warehouse', 'hotel'].includes(
              type
            )
              ? 'w-full flex flex-row space-x-2.5 items-start'
              : 'hidden'
          }
        >
          <Label wrapperClass="w-152px" label="Chủ đầu tư" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="investor"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập tên chủ đầu tư"
                    onDelete={() => onChange('')}
                    maxLength={maxLength.name}
                    hideMaxLength
                  />
                );
              }}
            />
          </div>
        </div>
        {permissions?.some(p =>
          [
            PermissionIds.ChangeProductState,
            PermissionIds.AddProduct,
          ]?.includes(p.id)
        ) && (
          <div
            className={
              isPublishProduct
                ? 'hidden'
                : 'w-full flex flex-row space-x-2.5 items-start'
            }
          >
            <Label
              wrapperClass="w-152px"
              isRequired
              label="Trạng thái sản phẩm"
            />
            <div className="w-full flex flex-1 space-x-4">
              <Controller
                control={control}
                rules={{
                  required: isPublishProduct
                    ? false
                    : 'Vui lòng chọn trạng thái sản phẩm',
                }}
                name="status"
                render={({
                  field: { onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <div className="w-full">
                      <DropdownSelection
                        hasItemRadio={false}
                        placeholder="Chọn trạng thái"
                        selections={
                          isForSell === 'forSale'
                            ? realEstateForSaleStatus.filter(
                                e => e.key === 'onSale'
                              )
                            : realEstateForRentStatus.filter(
                                e => e.key === 'renting'
                              )
                        }
                        invalid={invalid}
                        errorMessage={error?.message}
                        value={isForSell === 'forSale' ? 'onSale' : 'renting'}
                        setValue={value => {
                          onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};
