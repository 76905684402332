import Modal from '@mui/material/Modal';
import { isEqual } from 'lodash';

type Props = {
  isShowing?: boolean,
  onToggle?: Function,
  position?: 'center' | 'top' | 'left' | 'right',
  children?: JSX.Element | string | number,
};

export default function ModalWrapper({
  isShowing,
  onToggle,
  position = 'center',
  children,
}: Props) {
  const handleToggleModal = () => {
    onToggle?.();
  };

  return (
    <Modal
      closeAfterTransition
      disableEnforceFocus={true}
      disableAutoFocus={true}
      open={isShowing}
      onClose={handleToggleModal}
      BackdropProps={{
        timeout: 500,
      }}
      className={`fixed flex top-0 left-0 right-0 bottom-0 ${
        isEqual(position, 'center')
          ? 'justify-center items-center'
          : isEqual(position, 'top')
          ? 'justify-center items-start'
          : isEqual(position, 'bottom')
          ? 'justify-center items-end'
          : isEqual(position, 'right')
          ? 'justify-end items-center'
          : isEqual(position, 'left')
          ? 'justify-start items-center'
          : ''
      }`}
    >
      {children}
    </Modal>
  );
}
