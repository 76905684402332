import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import { CREATE_B2B_CONTRACT_V2 } from '@utils/graphql/mutations/b2bContract';
import { resetQueries } from '@utils/helper';
import { isEmpty, map, pick } from 'lodash';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
export const useCreateB2BContract = (idAdmin, onClose, onSuccess) => {
  const [createContract, { loading, error, client }] = useMutation(
    CREATE_B2B_CONTRACT_V2
  );
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async state => {
      try {
        await createContract({
          variables: {
            data: pickBy(
              {
                ...state,
                discount: parseFloat(state?.discount),
                totalMember: parseInt(state?.totalMember),
                dueDate: parseInt(state?.dueDate),
                attachments: state?.attachments.map(item => item.fileId),
                contractDate: moment
                  .unix(state?.contractDate)
                  .format('YYYY-MM-DD'),
                idAdmin,
                permissions: !isEmpty(state?.permissions)
                  ? map(state?.permissions, permission =>
                      pick(permission, ['id', 'configs'])
                    )
                  : undefined,
              },
              e => e || e === false
            ),
          },
        });
        await onClose();
        await onSuccess();
        await resetQueries(['getListContracts'], client.cache);
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e?.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [createContract, client, navigate]
  );
  return { onSubmit, loading, error };
};
