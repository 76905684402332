import { manageOrganization } from '@components/leftMenu/configs';
import Switch from '@components/switch';
import {
  blankStateFilterPermissionGroups,
  useStateFilterPermissionGroups,
} from '@utils/hooks/manageOrganization/useStateFilterPermissionGroups';
import { unionBy } from 'lodash';
import { useMemo, useState } from 'react';
import { View } from 'react-native-web';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect, useList } from 'react-use';
import { PermissionGroupStorageKey } from '../../../constant';
import { useFieldConfig } from '../../../modals/visibleFieldOnTableModalPermissionGroup/config';
import PermissionGroupDetail from './permissionGroupDetail/PermissionGroupDetail';
import { PermissionGroupsContext } from './permissionGroups/context';

export default function ManageOrganization() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [currentId, setCurrentId] = useState('');
  const [tab, setTab] = useState(pathname);

  const { pathname } = useLocation();
  const [listSelected, setListSelected] = useList([]);
  const [listField, setListField] = useList(
    listFieldLocalStorage || listFieldId || []
  );
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterPermissionGroups();
  const fields = useFieldConfig(tab);

  const listFieldId = fields
    ?.filter(filterItem => !filterItem?.hide)
    ?.map(mapItem => mapItem.key);
  const localStorageKey = PermissionGroupStorageKey;
  const listFieldLocalStorage = JSON.parse(
    localStorage.getItem(localStorageKey)
  );

  useDeepCompareEffect(() => {
    setListField.set(listFieldLocalStorage || listFieldId);
  }, [listFieldId, listFieldLocalStorage]);
  useDeepCompareEffect(() => {
    setTab(pathname);
  }, [pathname]);

  return (
    <PermissionGroupsContext.Provider
      value={useMemo(
        () => ({
          listField,
          setListField,
          listSelected: unionBy(listSelected, iteratee => iteratee),
          stateFilter,
          setState,
          setStateFilterAt,
          setListSelected,
          tab,
          currentId,
          setCurrentId,
        }),
        [
          listField,
          setListField,
          listSelected,
          stateFilter,
          setState,
          setStateFilterAt,
          setListSelected,
          tab,
          currentId,
          setCurrentId,
        ]
      )}
    >
      <View style={{ flex: 1 }}>
        {!id && (
          <>
            <div
              id="container-switch"
              className="flex items-center"
              style={{
                padding: '20px 16px 0',
                marginTop: 64,
                backgroundColor: 'white',
                borderRadius: '15px 15px 0 0',
              }}
            >
              <div className="flex flex-1 pl-4">
                <Switch
                  value={tab}
                  setValue={value => {
                    setTab(value);
                    navigate(value);
                    setListSelected.clear();
                    setState(blankStateFilterPermissionGroups);
                  }}
                  configs={manageOrganization.items[0].items}
                />
              </div>
            </div>
            <Outlet />
          </>
        )}
        {pathname === '/manageOrganization/permission-groups' &&
          (id || listSelected?.length === 1 ? (
            <PermissionGroupDetail />
          ) : listSelected?.length ? null : (
            <div className="ml-4 p-4 text-black-main">
              Vui lòng chọn 1 dòng bất kỳ để xem chi tiết
            </div>
          ))}
      </View>
    </PermissionGroupsContext.Provider>
  );
}
