import { useMutation } from '@apollo/client';
import { LOCK_UNLOCK_B2B_USER } from '@utils/graphql';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useToast } from '@chakra-ui/react';

export const useLockUnlockB2BUser = (id, setShowModal, isLocked) => {
  const [lockUnlock, { loading, error, client }] =
    useMutation(LOCK_UNLOCK_B2B_USER);
  const toast = useToast();
  const onSubmit = useCallback(async () => {
    try {
      await lockUnlock({
        variables: {
          userId: id,
        },
      });
      await resetQueries(
        ['b2bGetUserDetailsForBO', 'boGetListB2BAdmin'],
        client.cache
      );
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={
              isLocked
                ? 'Mở khóa tài khoản thành công'
                : 'Khóa tài khoản thành công'
            }
            closeButtonPos="center"
          />
        ),
      });
      setShowModal(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="error"
            message={
              isLocked
                ? 'Mở khóa tài khoản thất bại'
                : 'Khóa tài khoản thất bại'
            }
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [lockUnlock, client, id, setShowModal, isLocked]);
  return { onSubmit, loading, error };
};
