import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import RenderAvatarComponent from '../../../scenes/home/manageProduct/components/renderAvatarComponent';
import { timeSince } from '@components/comment/utils';
import { useCommentContext } from '@components/comment/context';
import CollapsibleV2 from '@components/collapsibleV2';
import InputComment from '@components/comment/component/inputComment';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';

type CommentRenderProps = {
  comment?: string,
  time?: string,
  id?: string | number,
  owner?: Object,
  isHiddenReply?: boolean,
  wrapperClass?: string,
  newId?: string,
};

function CommentRender(props: CommentRenderProps) {
  const { id, owner, comment, time, isHiddenReply, wrapperClass, newId } =
      props,
    { setRepCommentId, repCommentId, repComment } = useCommentContext();
  const { permissions } = useGetMe();
  const onRep = useCallback(() => {
    if (id !== repCommentId && repCommentId) {
      setRepCommentId(id);
    } else if (!repCommentId) {
      setRepCommentId(id);
    } else {
      setRepCommentId(null);
    }
  }, [id, setRepCommentId, repCommentId]);
  const inputRef = useRef(null);
  const repCommentRender = useMemo(() => {
    return (
      <div className="pl-12 mt-2 mb-2">
        <CollapsibleV2 expand={id === repCommentId}>
          <InputComment
            ref={inputRef}
            onSend={({ content }) =>
              repComment({ content, id, setRepCommentId })
            }
          />
        </CollapsibleV2>
      </div>
    );
  }, [id, repComment, repCommentId, setRepCommentId]);
  const fullName = owner?.fullName || '',
    avatarId = owner?.avatar || '',
    employeeCode = owner?.employeeCode || '';
  const renderTimeValue = timeSince(moment(time).toDate());
  return (
    <div className={`${wrapperClass} flex flex-col`}>
      <div className="flex flex-row">
        <RenderAvatarComponent
          wrapperStyle="w-40px h-40px"
          avatarId={avatarId}
        />
        <div
          style={{ backgroundColor: '#EDF4FF' }}
          className="px-4 ml-4 w-full flex flex-col py-2 rounded-lg"
        >
          <div className="flex flex-row">
            <MyDropdown
              popperPlacement="top-start"
              DropdownComponent={UserInfo}
              dropdownContext={{ userId: owner?.id }}
            >
              <div className="flex space-x-2 items-center">
                <span className="underline text-blue-main text-sm">
                  <span className="font-medium">{fullName}</span>
                  {employeeCode?.length > 0 && (
                    <span>{` (${employeeCode})`}</span>
                  )}
                </span>
                <img src="/svg/eye.svg" alt="icon" />
              </div>
            </MyDropdown>
          </div>
          <span style={{ wordBreak: 'break-word' }} className="text-sm">
            {comment}
          </span>
        </div>
      </div>
      <div className="flex space-x-2 px-14">
        {!isHiddenReply &&
          !newId &&
          permissions?.some(p => p.id === PermissionIds.AddProductNote) && (
            <span className="cursor-pointer text-11" onClick={onRep}>
              Trả lời
            </span>
          )}
        <span className="text-11 text-gray-500">{renderTimeValue}</span>
      </div>
      {permissions?.some(p => p.id === PermissionIds.AddProductNote) &&
        repCommentRender}
    </div>
  );
}

export default CommentRender;
