import styles from '@components/table/table.module.scss';
import React, { useCallback, useEffect } from 'react';
import RenderResultFilterHeader from './renderResultFilterHeader';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useNeedApprovalContext } from '../context';
import { View } from 'react-native-web';
import SearchTextDropdown from '../../components/filterButtons/searchTextDropdown';
import DemandDropdown from '../../components/filterButtons/demandDropdown';
import RealEstateTypeDropdown from '../../components/filterButtons/realEstateTypeDropdown';
import StreetDropdown from '../../components/filterButtons/streetDropdown';
import WardDropdown from '../../components/filterButtons/wardDropdown';
import DistrictDropdown from '../../components/filterButtons/districtDropdown';
import { HoChiMinhId } from '../../../../../constant';
import ProvinceDropdown from '../../components/filterButtons/provinceDropdown';
import CountryDropdown from '../../components/filterButtons/countryDropdown';
import IsNewConstructionDropdown from '../../components/filterButtons/isNewConstructionDropdown';
import CalendarDropdown from '../../components/filterButtons/calendarDropdown';
import DepartmentDropdown from '../../components/filterButtons/departmentDropdown';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import InputWidthDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputWidthDropdown';
import InputLengthDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputLengthDropdown';
import InputRearDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputRearDropdown';
import InputAreaDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputAreaDropdown';
import InputPriceDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputPriceDropdown';
import InputFloorCountDropdown from 'scenes/home/manageProduct/inputDropdownGroup/inputFloorCountDropdown';
import ConstructionAreaDropdown from '../../components/filterButtons/sliderDropdownGroup/constructionAreaDropdown';

export const checkVisibleCol = id => {
  const { listField } = useNeedApprovalContext();
  return listField?.includes(id);
};

type Props = {
  data?: Array,
  loading?: boolean,
};
export default function RenderHeader({ data, loading }: Props) {
  const { listSelected, setListSelected } = useNeedApprovalContext();
  const isCheckAll = listSelected?.length === data?.length;
  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data.map(e => e.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);
  const { stateFilter, setStateFilterAt, demand, tab } =
    useNeedApprovalContext();
  const { forRentRealEstateTypes, forSellRealEstateTypes } = configsRemote;
  useEffect(() => {
    if (demand === 'buy') {
      setStateFilterAt('isForSell')('ToBuy');
    } else {
      setStateFilterAt('isForSell')('ForSale');
    }
  }, [demand]);
  return (
    <View
      style={{
        borderTopLeftRadius: 9,
        borderTopRightRadius: 9,
        width: '100%',
      }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              isPartialCheck={listSelected?.length > 0}
              disabled={loading}
              isChecked={isCheckAll}
              onPress={handleCheckAll}
            />
          </div>
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </div>
          </div>
          <div className={`${styles.headContainer} w-170px items-center`}>
            <SearchTextDropdown
              search={stateFilter.realEstateId}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('realEstateId')(value);
              }}
            />
          </div>
          {checkVisibleCol('isForSell') && (
            <div
              className={`${styles.headContainer} w-140px items-center justify-center`}
            >
              <DemandDropdown
                demand={demand}
                value={stateFilter.isForSell}
                setValue={value => {
                  setListSelected.clear();
                  setStateFilterAt('isForSell')(value);
                  setStateFilterAt('realEstateType')(['townhouse']);
                  if (value === 'ForRent' || value === 'ToRent') {
                    setStateFilterAt('priceFrom')(0);
                    setStateFilterAt('priceTo')(200.5);
                    setStateFilterAt('priceKey')('');
                  } else {
                    setStateFilterAt('priceFrom')(0);
                    setStateFilterAt('priceTo')(101);
                    setStateFilterAt('priceKey')('');
                  }
                }}
              />
            </div>
          )}
          {checkVisibleCol('type') && (
            <div
              className={`${styles.headContainer} w-145px items-center justify-center`}
            >
              <RealEstateTypeDropdown
                title="Loại BĐS"
                selections={
                  stateFilter.isForSell === 'ForSale' ||
                  stateFilter.isForSell === 'ToBuy'
                    ? forSellRealEstateTypes
                    : forRentRealEstateTypes
                }
                value={stateFilter.realEstateType}
                setValue={value => {
                  setListSelected.clear();
                  setStateFilterAt('realEstateType')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('houseNumber') && !data[0]?.isNeedToBuy && (
            <div
              className={`${styles.headContainer} w-170px items-center justify-center`}
            >
              <SearchTextDropdown
                title="Số nhà"
                search={stateFilter.houseNumber}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('houseNumber')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('streetName') && (
            <div
              className={`${styles.headContainer} w-170px items-center justify-center`}
            >
              <StreetDropdown
                value={stateFilter.street}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('street')(item.key);
                  setStateFilterAt('streetName')(item.value);
                }}
                wardId={stateFilter.ward}
                districtId={stateFilter.district}
              />
            </div>
          )}
          {checkVisibleCol('wardName') && (
            <div
              className={`${styles.headContainer} w-140px items-center justify-center`}
            >
              <WardDropdown
                value={stateFilter.ward}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('ward')(item.key);
                  setStateFilterAt('wardName')(item.value);
                }}
                districtId={stateFilter.district}
                streetId={stateFilter.street}
              />
            </div>
          )}
          {checkVisibleCol('districtName') && (
            <div
              className={`${styles.headContainer} w-145px items-center justify-center`}
            >
              <DistrictDropdown
                value={stateFilter.district}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('district')(item.key);
                  setStateFilterAt('districtName')(item.value);
                }}
                provinceId={HoChiMinhId}
              />
            </div>
          )}
          {checkVisibleCol('provinceName') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <ProvinceDropdown
                value={stateFilter.province}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('province')(item.key);
                  setStateFilterAt('provinceName')(item.value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('country') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <CountryDropdown value={'vietnam'} />
            </div>
          )}
          {checkVisibleCol('width') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <InputWidthDropdown
                widthFrom={stateFilter.widthFrom}
                widthTo={stateFilter.widthTo}
                widthKey={stateFilter.widthKey}
                setWidthKey={value => setStateFilterAt('widthKey')(value)}
                setWidthFrom={value => setStateFilterAt('widthFrom')(value)}
                setWidthTo={value => setStateFilterAt('widthTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('width')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('length') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <InputLengthDropdown
                lengthFrom={stateFilter.lengthFrom}
                lengthTo={stateFilter.lengthTo}
                lengthKey={stateFilter.lengthKey}
                setLengthKey={value => setStateFilterAt('lengthKey')(value)}
                setLengthFrom={value => setStateFilterAt('lengthFrom')(value)}
                setLengthTo={value => setStateFilterAt('lengthTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('length')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('rear') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <InputRearDropdown
                rearFrom={stateFilter.rearFrom}
                rearTo={stateFilter.rearTo}
                rearKey={stateFilter.rearKey}
                setRearKey={value => setStateFilterAt('rearKey')(value)}
                setRearFrom={value => setStateFilterAt('rearFrom')(value)}
                setRearTo={value => setStateFilterAt('rearTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('rear')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('area') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <InputAreaDropdown
                areaFrom={stateFilter.areaFrom}
                areaTo={stateFilter.areaTo}
                title="Tổng diện tích (m²)"
                setAreaKey={value => setStateFilterAt('areaKey')(value)}
                setAreaFrom={value => setStateFilterAt('areaFrom')(value)}
                setAreaTo={value => setStateFilterAt('areaTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('area')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('price') && (
            <div
              className={`${styles.headContainer} w-154px items-center justify-center`}
            >
              <InputPriceDropdown
                isForSell={stateFilter?.isForSell}
                priceKey={stateFilter.priceKey}
                priceFrom={stateFilter.priceFrom}
                priceTo={stateFilter.priceTo}
                setPriceKey={value => setStateFilterAt('priceKey')(value)}
                setPriceFrom={value => setStateFilterAt('priceFrom')(value)}
                setPriceTo={value => setStateFilterAt('priceTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('floorCount') && (
            <div
              className={`${styles.headContainer} w-133px items-center justify-center`}
            >
              <InputFloorCountDropdown
                floorCountFrom={stateFilter.floorCountFrom}
                floorCountTo={stateFilter.floorCountTo}
                floorCountKey={stateFilter.floorCountKey}
                setFloorCountFrom={value =>
                  setStateFilterAt('floorCountFrom')(value)
                }
                setFloorCountTo={value =>
                  setStateFilterAt('floorCountTo')(value)
                }
                setFloorCountKey={value =>
                  setStateFilterAt('floorCountKey')(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('constructionArea') && (
            <div
              className={`${styles.headContainer} w-181px items-center justify-center`}
            >
              <ConstructionAreaDropdown
                constructionAreaKey={stateFilter.constructionAreaKey}
                constructionAreaFrom={stateFilter.constructionAreaFrom}
                constructionAreaTo={stateFilter.constructionAreaTo}
                setConstructionAreaKey={value =>
                  setStateFilterAt('constructionAreaKey')(value)
                }
                setConstructionAreaFrom={value =>
                  setStateFilterAt('constructionAreaFrom')(value)
                }
                setConstructionAreaTo={value =>
                  setStateFilterAt('constructionAreaTo')(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('isNewConstruction') && (
            <div
              className={`${styles.headContainer} w-166px items-center justify-center`}
            >
              <IsNewConstructionDropdown
                value={stateFilter.isNewConstruction}
                setValue={value => {
                  setListSelected.clear();
                  setStateFilterAt('isNewConstruction')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('createdAt') && (
            <div
              className={`${styles.headContainer} w-185px items-center justify-center`}
            >
              <CalendarDropdown
                start={stateFilter.createdAtFrom}
                end={stateFilter.createdAtTo}
                setStart={value => setStateFilterAt('createdAtFrom')(value)}
                setEnd={value => setStateFilterAt('createdAtTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
                title="Ngày tạo"
              />
            </div>
          )}
          {checkVisibleCol('updatedAt') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center`}
            >
              <CalendarDropdown
                start={stateFilter.updatedAtFrom}
                end={stateFilter.updatedAtTo}
                setStart={value => setStateFilterAt('updatedAtFrom')(value)}
                setEnd={value => setStateFilterAt('updatedAtTo')(value)}
                title="Ngày chỉnh sửa"
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('approvedBy') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center\``}
            >
              <SearchTextDropdown
                title={
                  tab.includes('approved') ? 'Người duyệt' : 'Người từ chối'
                }
                search={stateFilter.approvedBy}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('approvedBy')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('approvedAt') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center`}
            >
              <CalendarDropdown
                start={stateFilter.approvedAtFrom}
                end={stateFilter.approvedAtTo}
                setStart={value => setStateFilterAt('approvedAtFrom')(value)}
                setEnd={value => setStateFilterAt('approvedAtTo')(value)}
                title={tab.includes('approved') ? 'Ngày duyệt' : 'Ngày từ chối'}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('department') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center`}
            >
              <DepartmentDropdown />
            </div>
          )}
        </View>
      </View>
      <RenderResultFilterHeader data={data} />
    </View>
  );
}
