import React from 'react';
import MediaComponent from '@components/mediaComponent';

type Props = {
  onRemove?: Function,
  imageUrls?: Array,
  readOnly?: boolean,
};
export default function FilesResult(props: Props) {
  const { imageUrls, readOnly, onRemove } = props,
    images = imageUrls?.filter(e => e.type === 'image'),
    videos = imageUrls?.filter(e => ['video', 'youtube'].indexOf(e.type) >= 0);
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="w-full space-y-5 py-4 px-2.5 rounded-lg"
    >
      {!!images?.length && (
        <div className="flex-col  flex space-x-0 space-y-5 ">
          <div className="w-full text-13 text-black-main font-medium">
            Hình ảnh ({images?.length})
          </div>
          <div className="grid-cols-5 grid gap-3">
            {images?.map(item => {
              return (
                <MediaComponent
                  {...item}
                  remove={() => {
                    onRemove(item.fileId);
                  }}
                  key={item.fileId}
                  readOnly={readOnly}
                />
              );
            })}
          </div>
        </div>
      )}
      {!!videos?.length && (
        <div className="flex-col  flex space-y-5">
          <div className="w-full text-13 text-black-main font-medium">
            Video ({videos?.length})
          </div>
          <div className="grid-cols-2 md:grid-cols-3 mdLgX:grid-cols-5 grid gap-12px">
            {videos?.map(item => {
              return (
                <MediaComponent
                  {...item}
                  remove={() => onRemove(item.fileId)}
                  key={item.fileId}
                  readOnly={readOnly}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
