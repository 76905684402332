import MultiCheckDropdownComponent from '@components/filterDropdownComponentGroup/multiCheckDropdownComponent';
import styles from '@components/table/table.module.scss';
import MyDropdown from '@components/myDropdown';
import React from 'react';

type Props = {
  selections?: Array,
  value?: Array,
  setValue?: Function,
  isShowSearchBox?: boolean,
  title?: string,
  wrapperStyle?: string,
};
export default function DropdownMultiSelection({
  value,
  setValue,
  selections,
  isShowSearchBox,
  title,
  wrapperStyle,
}: Props) {
  return (
    <MyDropdown
      wrapperClass={`w-full ${wrapperStyle}`}
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={MultiCheckDropdownComponent}
      dropdownContext={{
        selections,
        value,
        setValue,
        isShowSearchBox,
      }}
    >
      <div className={`text-13 font-medium text-blue-main ${styles.textHead}`}>
        {title}
      </div>
      <img src="/svg/arrow-blue-filter.svg" alt="arrow" />
    </MyDropdown>
  );
}
