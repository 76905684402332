import MyInput from '@components/myInput';
import MyButton from '@components/button';
import { useUploadMediaYoutube } from '@utils/hooks/common/useUploadMediaYoutube';

type Props = {
  mediaUrls?: Array,
  setMediaUrls?: Function,
};
export default function UploadYoutubeMedia({ mediaUrls, setMediaUrls }: Props) {
  const { addVideoYoutube, loading, fileNotFound, youtubeFile, link, setLink } =
    useUploadMediaYoutube({ mediaUrls, setMediaUrls });
  const totalVideoFile = mediaUrls?.filter(
    e => e.type.includes('video') || e.type.includes('youtube')
  );
  const disableButton = totalVideoFile?.length > 2;
  return (
    <div className="w-full h-full flex flex-col flex-1 p-4">
      <div style={{ fontSize: 13 }} className="text-black font-medium">
        Thêm video từ youtube
      </div>
      <div className="w-full mt-2 flex flex-row space-x-2 justify-between">
        <div className="flex-1">
          <MyInput
            value={link}
            onChange={e => setLink(e.target.value)}
            placeholder="Nhập link video Youtube vào đây"
            onDelete={() => setLink('')}
          />
        </div>
        <div className="w-70px">
          <MyButton
            type="blue"
            onPress={addVideoYoutube}
            disabled={loading || fileNotFound || disableButton}
            label="Thêm"
          />
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        {youtubeFile ? (
          <div className="flex flex-1 flex-row space-x-2">
            <div
              style={{
                backgroundImage: `url('${youtubeFile?.payload?.youtubeMetadata?.thumbnailUrl}')`,
              }}
              className="w-150px h-100px rounded-lg bg-red-600 bg-cover bg-no-repeat bg-center"
            />
            <div className="flex-col flex-1 space-y-2">
              <div style={{ fontSize: 11 }} className="text-black font-medium">
                {' '}
                {youtubeFile?.payload?.youtubeMetadata?.title}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ fontSize: 13 }} className="text-gray-400">
            Video từ Youtube sẽ được hiển thị tại đây
          </div>
        )}
      </div>
    </div>
  );
}
