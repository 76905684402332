import styles from '@components/table/table.module.scss';
import React from 'react';
import { useManageEmployeeContext } from '../context';
import moment from 'moment/moment';

import needApprovedStyle from '../../../manageRealEstate/needApproval/components/needApproval.module.scss';
type RenderTagProps = {
  value?: any,
  removeValue?: Function,
};
export function RenderTag({ value, removeValue }: RenderTagProps) {
  const { setListSelected } = useManageEmployeeContext();
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div className={needApprovedStyle.tagFilterResult}>
      <div className="text-13 truncate text-black-main">{value}</div>
      {removeValue && (
        <img
          onClick={() => {
            removeValue();
            setListSelected?.clear();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  );
}

type Props = {
  stateFilter?: Object,
  setStateFilterAt?: Function,
};
export default function RenderResultFilterHeader({
  stateFilter,
  setStateFilterAt,
}: Props) {
  const {
    employeeCode,
    fullName,
    province,
    provinceName,
    district,
    districtName,
    username,
    createdAtFrom,
    createdAtTo,
    email,
    phone,
  } = stateFilter;
  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div className={`${styles.headContainer} w-202px items-center `}>
          <RenderTag
            value={employeeCode}
            removeValue={() => setStateFilterAt('employeeCode')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-232px items-center `}>
          <RenderTag
            value={fullName}
            removeValue={() => setStateFilterAt('fullName')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center `}>
          <RenderTag
            value={username}
            removeValue={() => setStateFilterAt('username')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center `}>
          <RenderTag
            value={phone}
            removeValue={() => setStateFilterAt('phone')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center `} />
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            key={province}
            value={provinceName}
            removeValue={() => {
              [
                'province',
                'provinceName',
                'district',
                'districtName',
                'ward',
                'wardName',
                'street',
                'streetName',
              ].forEach(item => {
                setStateFilterAt(item)('');
              });
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            key={district}
            value={districtName}
            removeValue={() => {
              [
                'district',
                'districtName',
                'ward',
                'wardName',
                'street',
                'streetName',
              ].forEach(item => {
                setStateFilterAt(item)('');
              });
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={
              createdAtFrom && createdAtTo
                ? `${moment.unix(createdAtFrom).format('DD/MM/YYYY')} - ${moment
                    .unix(createdAtTo)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('createdAtFrom')('');
              setStateFilterAt('createdAtTo')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center `}>
          <RenderTag
            value={email}
            removeValue={() => {
              setStateFilterAt('email')('');
            }}
          />
        </div>
      </div>
    </div>
  );
}
