import React from 'react';
import { allConfigs, demandConfigs } from '../configs';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/material/SvgIcon/SvgIcon';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import AddProduct from './AddProduct';
import {
  forRentRealEstateTypes,
  realEstateStatus,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { RenderTag } from '@components/tag';
import DepartmentAssignedInConfigs from './DepartmentAssignedInConfigs';
import AssignedCountConfig from './AssignedCountConfig';
import AssignedRegionConfig from './AssignedRegionConfig';
import { DocTienBangChuV2 } from '@utils/helper';

type Props = {
  permissionsWithConfigs: Array,
  fullWidth?: boolean,
};
export default function AssignPermissionConfig({
  permissionsWithConfigs,
  fullWidth = false,
}: Props) {
  const configs = permissionsWithConfigs?.length
      ? allConfigs.filter(p =>
          permissionsWithConfigs?.map(c => c.id).includes(p.id)
        )
      : [],
    productConfigs = configs?.filter(p => p.parent === 'manage-product'),
    employeeConfigs = configs?.filter(p => p.parent === 'manage-employee');
  const prodConfig = permissionsWithConfigs?.find(
    p => p.id === PermissionIds.ViewProductList
  )?.configs;
  return (
    <div>
      <Typography fontWeight="bold">Cài đặt quyền</Typography>
      <div
        style={fullWidth ? { maxHeight: 458 } : {}}
        className="overflow-y-auto"
      >
        {productConfigs?.length > 0 && (
          <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div" variant="subtitle2">
                {`Cài đặt quyền Quản lý kho sản phẩm (${productConfigs?.length} cấu hình quyền)`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col space-y-2">
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.AddProduct
              ) && (
                <AddProduct
                  viewOnly={true}
                  selectedValue={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.AddProduct
                    )?.configs
                  }
                />
              )}
              {productConfigs?.some(
                p => p.id === PermissionIds.ViewProductList
              ) && (
                <div className="w-full h-full flex flex-col space-y-2 rounded-lg shadow-normal p-4">
                  <span className="font-medium text-blue-main text-16">
                    Xem sản phẩm trong kho
                  </span>
                  <span className="font-medium text-black-main text-14 mt-4">
                    Loại nhu cầu được xem:
                  </span>
                  <div className="flex flex-col space-y-2">
                    {permissionsWithConfigs
                      ?.find(p => p.id === PermissionIds.ViewProductList)
                      ?.configs?.demandType?.includes('all') ? (
                      <span className="text-sm">Xem tất cả nhu cầu</span>
                    ) : (
                      <span className="text-sm">
                        {
                          demandConfigs.find(p =>
                            p.key?.includes(
                              permissionsWithConfigs?.find(
                                p => p.id === PermissionIds.ViewProductList
                              )?.configs?.demandType
                            )
                          )?.value
                        }
                      </span>
                    )}
                  </div>
                  <span className="font-medium text-black-main text-14 mt-2">
                    Loại BĐS được xem:
                  </span>
                  <div className="flex flex-col space-y-2">
                    {permissionsWithConfigs
                      ?.find(p => p.id === PermissionIds.ViewProductList)
                      ?.configs?.realEstateType?.includes('all') ? (
                      <span className="text-sm">Xem tất cả các loại BĐS</span>
                    ) : (
                      <div className="w-full flex flex-wrap space-x-2 space-y-2">
                        {forRentRealEstateTypes
                          .filter(p =>
                            permissionsWithConfigs
                              ?.find(
                                p => p.id === PermissionIds.ViewProductList
                              )
                              ?.configs?.realEstateType?.includes(p.key)
                          )
                          .map(type => (
                            <div key={Math.random()} className="w-fit mt-2">
                              <RenderTag key={type.key} value={type.value} />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <span className="font-medium text-black-main text-14 mt-2">
                    Trạng thái được xem:
                  </span>
                  <div className="flex flex-col space-y-2">
                    {permissionsWithConfigs
                      ?.find(p => p.id === PermissionIds.ViewProductList)
                      ?.configs?.status?.includes('all') ? (
                      <span className="text-sm">Xem tất cả trạng thái</span>
                    ) : (
                      <div className="w-full flex flex-wrap space-x-2">
                        {realEstateStatus
                          .filter(p =>
                            permissionsWithConfigs
                              ?.find(
                                p => p.id === PermissionIds.ViewProductList
                              )
                              ?.configs?.status?.includes(p.key)
                          )
                          ?.map(status => (
                            <div key={Math.random()} className="w-fit mt-2">
                              <RenderTag
                                key={status.key}
                                value={status.value}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <span className="font-medium text-black-main text-14 mt-2">
                    Khu vực được xem:
                  </span>
                  <div className="flex flex-col space-y-2">
                    {permissionsWithConfigs
                      ?.find(p => p.id === PermissionIds.ViewProductList)
                      ?.configs?.region?.filter(p => p.type === 'Province')
                      ?.map(l => l.id)
                      ?.includes('all') ? (
                      <span className="text-sm">Xem tất cả khu vực</span>
                    ) : (
                      <AssignedRegionConfig regionConfig={prodConfig?.region} />
                    )}
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="font-medium text-black-main text-14 mt-2">
                      Khoảng giá được xem:
                    </span>
                    <div>
                      {prodConfig?.priceRange?.from === 0 &&
                      prodConfig?.priceRange?.to === Number.MAX_SAFE_INTEGER ? (
                        <span className="text-sm">Xem tất cả khoảng giá</span>
                      ) : (
                        <div className="text-sm">
                          Từ{' '}
                          <span className="text-red-400">
                            {DocTienBangChuV2(prodConfig?.priceRange?.from)}
                          </span>{' '}
                          đến{' '}
                          <span className="text-red-400">
                            {DocTienBangChuV2(prodConfig?.priceRange?.to)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewOwnerPhoneNumber
              ) && (
                <AssignedCountConfig
                  permissionName="Xem số điện thoại chủ nhà"
                  count={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewOwnerPhoneNumber
                    )?.configs?.count
                  }
                  countLabel="Số lần được xem số điện thoại chủ nhà tối đa:"
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewProductAttachments
              ) && (
                <AssignedCountConfig
                  permissionName="Xem tài liệu đính kèm"
                  count={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewProductAttachments
                    )?.configs?.count
                  }
                  countLabel="Số lần được xem tài liệu đính kèm tối đa:"
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewProductLegalAttachments
              ) && (
                <AssignedCountConfig
                  permissionName="Xem hồ sơ pháp lý"
                  count={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewProductLegalAttachments
                    )?.configs?.count
                  }
                  countLabel="Số lần được xem hồ sơ pháp lý tối đa:"
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewAwaitApprovingProductList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem sản phẩm đang chờ duyệt"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewAwaitApprovingProductList
                    )?.configs?.departments
                  }
                  ownerDepartmentLabel="Xem sản phẩm chờ duyệt của chính tôi"
                  allLabel="Xem nhân viên cần duyệt toàn công ty"
                  assignedDepartmentLabel="Xem sản phẩm đang chờ duyệt của phòng ban:"
                  permissionId={PermissionIds.ViewAwaitApprovingProductList}
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewDeletedProductList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem sản phẩm đã xóa"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewDeletedProductList
                    )?.configs?.departments
                  }
                  ownerDepartmentLabel="Xem sản phẩm chính tôi xóa"
                  allLabel="Xem sản phẩm đã xóa toàn công ty"
                  assignedDepartmentLabel="Xem sản phẩm đã xóa của phòng ban:"
                  permissionId={PermissionIds.ViewDeletedProductList}
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewHiddenProductList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem sản phẩm đã ẩn"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewHiddenProductList
                    )?.configs?.departments
                  }
                  allLabel="Xem sản phẩm đã ẩn toàn công ty"
                  assignedDepartmentLabel="Xem sản phẩm đã ẩn của phòng ban:"
                  permissionId={PermissionIds.ViewHiddenProductList}
                />
              )}
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewPublishedProductList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem danh sách sản phẩm đã đăng tin rao lên sàn"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewPublishedProductList
                    )?.configs?.departments
                  }
                  ownerDepartmentLabel="Xem sản phẩm đã đăng tin rao lên sàn của chính tôi"
                  allLabel="Xem sản phẩm đã đăng tin rao lên sàn của toàn công cty"
                  assignedDepartmentLabel="Xem sản phẩm đã đăng tin rao lên sàn của phòng ban:"
                  permissionId={PermissionIds.ViewPublishedProductList}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {employeeConfigs?.length > 0 && (
          <Accordion expanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="div" variant="subtitle2">
                {`Cài đặt quyền Quản lý nhân viên (${employeeConfigs?.length} cấu hình quyền)`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col space-y-2">
              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.AddEmployee
              ) &&
                (permissionsWithConfigs?.find(
                  p => p.id === PermissionIds.AddEmployee
                )?.configs?.isNeedToApprove ? (
                  <div className="w-full flex flex-col space-y-2 rounded-lg shadow-normal p-4">
                    <span className="font-medium text-blue-main text-16">
                      Thêm nhân viên
                    </span>
                    <div className="text-sm font-medium">
                      Phòng ban duyệt bằng tay
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-col space-y-2 rounded-lg shadow-normal p-4">
                    <span className="font-medium text-blue-main text-16">
                      Thêm nhân viên
                    </span>
                    <div className="text-sm font-medium">
                      Hệ thống duyệt tự động
                    </div>
                  </div>
                ))}

              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewEmployeeList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem nhân viên công ty"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewEmployeeList
                    )?.configs?.departments
                  }
                  allLabel="Xem nhân viên toàn công ty"
                  assignedDepartmentLabel="Xem nhân viên của phòng ban:"
                  permissionId={PermissionIds.ViewEmployeeList}
                />
              )}

              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewDeletedEmployeeList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem nhân viên đã xóa"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewDeletedEmployeeList
                    )?.configs?.departments
                  }
                  allLabel="Xem nhân viên đã xóa toàn công ty"
                  assignedDepartmentLabel="Xem nhân viên đã xóa của phòng ban:"
                  permissionId={PermissionIds.ViewDeletedEmployeeList}
                />
              )}

              {permissionsWithConfigs?.some(
                p => p.id === PermissionIds.ViewNeedToApproveEmployeeList
              ) && (
                <DepartmentAssignedInConfigs
                  permissionName="Xem nhân viên cần duyệt"
                  departments={
                    permissionsWithConfigs?.find(
                      p => p.id === PermissionIds.ViewNeedToApproveEmployeeList
                    )?.configs?.departments
                  }
                  allLabel="Xem nhân viên cần duyệt toàn công ty"
                  assignedDepartmentLabel="Xem nhân viên cần duyệt của phòng ban:"
                  permissionId={PermissionIds.ViewNeedToApproveEmployeeList}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
}
