export const reasonConfigs = {
  approve: [
    {
      key: 'Thông tin BĐS đầy đủ và đúng quy định',
    },
  ],
  reject: [
    {
      key: 'Hình ảnh không đúng quy định',
    },
    {
      key: 'Thông tin đăng tải không phù hợp với pháp luật Việt Nam',
    },
  ],
};
