import styles from '../bottomRealEstate.module.scss';
import moment from 'moment';
import React from 'react';

type RenderTimeProps = {
  createdAt?: string,
};
export default function RenderTime({ createdAt }: RenderTimeProps) {
  return (
    <div className="flex flex-row items-center">
      <img
        src="/svg/calendar-icon.svg"
        alt="icon"
        className="mr-1 w-11px h-11px"
      />
      <div className={styles.createdAtText}>
        {moment(createdAt).format('DD/MM/YYYY')}
      </div>
    </div>
  );
}
