import ListView from '../components/ListView';
import { forRentRealEstateTypes } from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import React from 'react';

type ListTypeModalProps = {
  onClose: Function,
  selectedItems: Array,
  setItems: Function,
  id?: string,
};
export default function ListTypeModal(props: ListTypeModalProps) {
  const { onClose, setItems, selectedItems, id } = props;
  return (
    <ListView
      selectedItems={selectedItems}
      setItems={setItems}
      onClose={onClose}
      id={id}
      data={forRentRealEstateTypes}
      headerLabel="Chọn loại bất động sản"
      searchPlaceHolder="Tìm kiếm loại bất động sản"
    />
  );
}
