import {
  convertFromToPriceValue,
  useRenderPriceStagesRangeProject,
} from '@utils/helper';

type Props = {
  data?: Object,
};

export default function RenderPriceRangeProject({ data }: Props) {
  const {
    from: fromRange,
    to: toRange,
    unitFrom: unitFromRange,
    unitTo: unitToRange,
  } = useRenderPriceStagesRangeProject(data);
  return (
    <p className="text-sm">
      {convertFromToPriceValue(fromRange, toRange, unitFromRange, unitToRange)}
    </p>
  );
}
