/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TreeItem from '@mui/lab/TreeItem';
import { makeStyles } from '@material-ui/core';
import TreeView from '@mui/lab/TreeView';
import { projectStatus } from './configs.json';
import MyModalV2 from '@components/modalV2/myModalV2';
import BlockDetailModal from './blockDetailModal';
import { lastIndexOf } from 'lodash';

const useTreeItemStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTreeItem-content.Mui-selected,.MuiTreeItem-content.Mui-selected.Mui-focused':
      {
        backgroundColor: '#FFE6B4',
      },
    '.MuiTreeItem-content:hover': {
      backgroundColor: '#FFE6B450',
    },
  },
  content: {
    flexDirection: 'row-reverse',
    marginBottom: '10px',
    marginTop: '15px',
    borderRadius: '8px',
    border: '1px solid #B4B8BC',
    backgroundColor: '#EDF4FF',
    height: 40,
  },
  root: {
    position: 'relative',
    '&:before': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 14,
      left: -16,
      top: 20,
      borderBottom: props => (!props.rootNode ? `1px solid #B4B8BC` : 'none'),
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid #B4B8BC`,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles(props);
  const {
    labelText,
    rootNode,
    labelIconLeft,
    labelIconRight,
    indexText,
    info,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className="flex flex-row text-14 items-center space-x-2">
          <div className="flex">{indexText}</div>
          {labelIconLeft && (
            <div className="flex items-center">
              <img alt="image" src={labelIconLeft} />
            </div>
          )}
          <div className="flex items-center space-x-1">
            <div className={info ? 'underline text-blue-main' : 'none'}>
              {labelText}
            </div>
            {labelIconRight ? <img alt="image" src={labelIconRight} /> : ''}
          </div>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        group: classes.group,
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}
const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 'auto',
  },
}));
type Props = {
  data?: Object,
};

export default function BlockDetail(props: Props) {
  const { data } = props;
  const blocks = data?.blocks;
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };
  return (
    <div className="flex w-full flex-col space-y-4 h-full">
      <div className="w-full flex flex-col">
        <div className="flex flex-row">
          <div className="w-1/5">
            <span className="text-16 font-medium">Ghi chú:</span>
          </div>
          <div className="grid grid-cols-4 gap-y-4 w-4/5">
            {projectStatus?.map((status, index) => {
              return (
                <Item value={status?.value} src={status?.src} key={index} />
              );
            })}
          </div>
        </div>
      </div>

      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandLessIcon />}
        defaultExpandIcon={<ExpandMoreIcon />}
        expanded={expanded}
        onNodeToggle={handleChange}
      >
        {blocks?.map((item, index) => {
          return (
            <StyledTreeItem
              key={index}
              rootNode={true}
              nodeId={item?.id}
              indexText={`${index + 1}.`}
              labelText={
                `   ${item?.name} (${item?.floors?.length} tầng)` ||
                item?.otherName + `( ${item?.floors?.length} tầng)`
              }
            >
              {item?.floors?.map((floor, ids) => {
                return (
                  <StyledTreeItem
                    key={ids}
                    nodeId={floor?.id}
                    indexText={`${index + 1}.${ids + 1}`}
                    labelText={`  Tầng ${ids + 1}`}
                  >
                    {floor?.products?.map((products, idx) => {
                      return (
                        <StyledTreeItem
                          key={idx}
                          nodeId={products?.id}
                          indexText={`${index + 1}.${ids + 1}.${idx + 1}`}
                          labelIconLeft="/svg/circle-dangban-chothue.svg"
                          labelIconRight="/svg/eye.svg"
                          labelText={products?.formatName}
                          info={products}
                        />
                      );
                    })}
                  </StyledTreeItem>
                );
              })}
            </StyledTreeItem>
          );
        })}
      </TreeView>
    </div>
  );
}

function Item({ value = '', src, valueStyle, hideField }: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex space-x-2.5">
      <div className="flex items-center">
        <img alt="ico" src={src} className="w-4 h-4" />
      </div>
      <div className={`text-14 flex items-center ${valueStyle}`}>{value}</div>
    </div>
  );
}
