import React from 'react';

type Props = {
  wrapperClass?: string,
};

export default function DividingLine({ wrapperClass }: Props) {
  return (
    <div className={`w-full flex flex-row items-center my-4 ${wrapperClass}`}>
      <div className="h-1 border-t border-gray-300 flex-1" />
      <img className="mx-2" src="/svg/blackwhite-logo.svg" alt="logo" />
      <div className="h-1 border-t border-gray-300 flex-1" />
    </div>
  );
}
