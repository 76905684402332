import { HoChiMinhId } from '../../../constant';

export const blankState = {
  isForSell: 'forSale',
  typeRealEstate: 'townhouse',
  province: HoChiMinhId,
  district: '',
  ward: '',
  street: '',
  houseNumber: '',
  lat: '',
  long: '',
  contactName: '',
  contactPhones: [],
  contactEmail: '',
  career: '',
  fixedUtilities: '',
  utilities: '',
  localUtilities: '',
  equipmentsAndServices: '',
  attachments: [],
  note: '',
  legal: [],
  mediaUrls: [],
  isNewConstruction: false,
  hasAlley: false,
  width: '',
  length: '',
  rear: '',
  floorCount: 0,
  price: '',
  bedroomCount: 0,
  toiletCount: 0,
  direction: '',
  project: '',
  status: '',
  alleyTurnCount: '',
  alleyWidth: '',
  commission: '',
  villaView: [],
  buildingType: '',
  roomCount: '',
  hotelStar: 1,
  hasSeaView: false,
  warehouseType: '',
  warehouseRoomCount: 1,
  roomNumber: '',
  onFloor: 1,
  bedRoomArea: '',
  block: '',
  shophouseField: '',
  hasMezzanine: false,
  timeForRent: '',
  timeForRentUnit: '',
  constructionArea: '',
  isCompleted: false,
  isChecked: false,
  total: undefined,
  canContinue: undefined,
};

export const hasAlleyConfigs = [
  {
    key: false,
    value: 'BĐS mặt tiền',
  },
  {
    key: true,
    value: 'BĐS trong hẻm',
  },
];

export const isNewConstructionConfigs = [
  {
    key: true,
    value: 'Xây mới',
  },
  {
    key: false,
    value: 'Xây cũ',
  },
];

export const hasSeaViewConfigs = [
  {
    key: true,
    value: 'Có',
  },
  {
    key: false,
    value: 'Không',
  },
];

export const stepConfigs = [
  {
    label: 'Kiểm tra',
    path: '/createB2BRealEstate/check-product',
  },
  {
    label: 'Nhập thông tin',
    path: '/createB2BRealEstate/add-product-information',
  },
];
