import MapType from '@components/realEstateOnMap/components/mapType';

type Props = {
  onResetLocation?: Function,
  variablesFilter?: Object,
  setVariablesFilter?: Function,
  onPressREAround?: Function,
  onPressPlanning?: Function,
  onSearch?: Function,
  deleteDraw?: Function,
  showDeleteDraw?: boolean,
  setOffPopup?: Function,
  setMode?: Function,
  openMap4d?: Function,
  onPressArea?: Function,
};

export default function HeadMap(props: Props) {
  const {
    onPressREAround,
    onPressPlanning,
    deleteDraw,
    showDeleteDraw,
    setMode,
    openMap4d,
    onPressArea,
  } = props;

  return (
    <>
      <MapType
        setMode={setMode}
        onPressPlanning={onPressPlanning}
        onPressREAround={onPressREAround}
        deleteDraw={deleteDraw}
        showDeleteDraw={showDeleteDraw}
        openMap4d={openMap4d}
        onPressArea={onPressArea}
      />
    </>
  );
}
