import React, { useState } from 'react';
import { View } from 'react-native-web';
import { useManageEmployeeContext } from '../context';
import LeftUserDetail from './components/leftUserDetail';
import RightUserDetail from './components/rightUserDetail';
import { useDeepCompareEffect } from 'react-use';
import { useSwitchTabConfigs } from './config';
import { useQuery } from '@apollo/client';
import { GET_EMPLOYEE_DETAILS } from '@utils/graphql';

export default function UserDetail() {
  const { listSelected } = useManageEmployeeContext();
  const { data } = useQuery(GET_EMPLOYEE_DETAILS, {
    variables: {
      id: listSelected?.[0],
    },
    skip: !listSelected?.[0],
  });
  const tabConfigs = useSwitchTabConfigs();
  const [currentTab, setCurrentTab] = useState(tabConfigs[0].key);
  const [contractId, setContractId] = useState('');
  useDeepCompareEffect(() => {
    setContractId('');
    setCurrentTab(tabConfigs[0].key);
  }, [listSelected?.[0], setContractId]);
  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftUserDetail
        tabConfigs={tabConfigs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        contractId={contractId}
        setContractId={setContractId}
        data={data?.b2bGetUserInfo}
      />
      <RightUserDetail
        employee={data?.b2bGetUserInfo}
        tab={currentTab}
        userId={listSelected?.[0]}
      />
    </View>
  );
}
