import Label from '@components/label';
import MyInput from '@components/myInput';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { maxLength } from '../../../../constant';
import Section from '@components/section';
import MyTextAreaInput from '@components/textAreaInput';
import { DocTienBangChuV2, numberWithDots } from '@utils/helper';

export const fieldConditionConfigs = {
  postTitle: {
    dataVisible: state => state?.postTitle?.length > 0,
  },
  postContent: {
    dataVisible: state => state?.postContent?.length > 0,
  },
  price: {
    dataVisible: state => state?.price?.length > 0,
  },
};
export const getTotalFieldCount = props => {
  const field = ['postTitle', 'postContent', 'price'];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
export default function PostInfo() {
  const { control, watch } = useFormContext();
  const postTitle = watch('postTitle');
  const postContent = watch('postContent');
  const price = watch('price');
  const data = {
    postTitle,
    postContent,
    price,
  };
  const [focused, setFocused] = useState(false);
  return (
    <Section>
      <div className="text-base text-blue-main font-medium">
        Thông tin bài đăng ({getTotalFieldCount(data).fieldVisibleCount}/{3})
      </div>
      <div className="w-full mt-7 space-y-7">
        <div className="w-full flex flex-row space-x-2.5 items-center">
          <Label wrapperClass="w-152px" isRequired label="Tiêu đề đăng tin" />
          <Controller
            rules={{
              required: 'Vui lòng nhập tiêu đề tin đăng',
              validate: value =>
                value?.length < 28
                  ? 'Vui lòng nhập tiêu đề ít nhất 28 ký tự'
                  : true,
            }}
            control={control}
            name="postTitle"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  inputClassName="flex-1"
                  invalid={invalid}
                  errorMessage={error?.message}
                  placeholder="Nhập tiêu đề tin đăng"
                  onDelete={() => onChange('')}
                  maxLength={maxLength.name}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-center">
          <Label wrapperClass="w-152px" isRequired label="Nội dung tin đăng" />
          <Controller
            rules={{
              required: 'Vui lòng nhập nội dung',
              validate: value =>
                value?.length < 28
                  ? 'Vui lòng nhập nội dung ít nhất 28 ký tự'
                  : true,
            }}
            control={control}
            name="postContent"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyTextAreaInput
                  {...field}
                  inputClassName="flex-1"
                  maxlength={maxLength.postContent}
                  invalid={invalid}
                  errorMessage={error?.message}
                  placeholder="Nhập nội dung tin đăng"
                  onDelete={() => onChange('')}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-center">
          <Label
            wrapperClass="w-152px"
            isRequired
            isRequiredInline={true}
            label="Giá đăng lên sàn (VNĐ)"
          />
          <div className="w-full flex flex-1 space-x-4">
            <div className="w-full flex flex-col space-y-2">
              <Controller
                rules={{
                  required: 'Vui lòng nhập giá',
                  validate: value =>
                    value?.length > maxLength.price
                      ? `Giá nhập vào không quá ${maxLength.price} chữ số`
                      : true,
                }}
                control={control}
                name="price"
                render={({
                  field,
                  field: { value, onChange },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <MyInput
                      {...field}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      inputClassName="flex-1"
                      invalid={invalid}
                      errorMessage={error?.message}
                      placeholder="Ví dụ: 3,700,000"
                      onDelete={() => onChange('')}
                      type={!focused ? undefined : 'text'}
                      hideMaxLength
                      maxLength={20}
                      value={!focused && value ? numberWithDots(value) : value}
                    />
                  );
                }}
              />
              {price && (
                <span className="text-red-500 text-sm font-bold">
                  {DocTienBangChuV2(price)} VNĐ
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
