type Props = {
  children?: React.ReactChildren,
  wrapperClassName?: string,
};
export default function Section({ children, wrapperClassName }: Props) {
  return (
    <div
      style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
      className={`w-full bg-white rounded-lg p-3 ${wrapperClassName}`}
    >
      {children}
    </div>
  );
}
