import NonePermission from './nonePermission';
import React from 'react';
import { Permission } from '@gugotech/tncc-users-permissions/lib';
import { useList } from 'react-use';
import AssignPermissionConfig from './components/AssignedPermissionConfigs';
import MyTreeView from '@components/treeView/treeView1';
import { filterAndMapChildren } from '@utils/helper';

type Props = {
  data?: Object,
};

export default function UserPermission({ data }: Props) {
  const employeePermissions = [
    ...(data?.productPermissions ?? []),
    ...(data.projectPermissions ?? []),
  ];
  const [permissionList] = useList(employeePermissions?.map(p => p.id)),
    filterPermissions = filterAndMapChildren(
      Permission.getPermissionTree('product'),
      permissionList
    );
  return (
    <div className="w-full">
      {employeePermissions?.length === 0 ? (
        <NonePermission employee={data} />
      ) : (
        <div className="w-full">
          <div className="bg-white w-full h-50px text-blue-main font-medium text-16 flex justify-center items-center rounded-t-lg">
            Quyền đã phân cho nhân viên
          </div>
          <div className="w-full flex flex-row space-x-4 p-4">
            <div className="w-1/2">
              <MyTreeView
                defaultCheckedIds={permissionList}
                data={filterPermissions}
                title="Quyền trong kho BĐS"
                searchPlaceHolder="Tìm kiếm quyền"
              />
            </div>
            <div className="flex-1">
              <AssignPermissionConfig
                permissionsWithConfigs={employeePermissions}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
