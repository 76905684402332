import { useQuery } from '@apollo/client';
import { GET_FEEDBACKS } from '@utils/graphql';

export const useGetListFeedback = () => {
  const { data, loading } = useQuery(GET_FEEDBACKS);
  const listFeedback = data;
  return {
    listFeedback,
    loading,
  };
};
