type RenderTagProps = {
  value?: string,
  removeValue?: Function,
};

export function RenderTag({ value, removeValue }: RenderTagProps) {
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div
      className="rounded-2xl flex flex-row px-1 items-center space-x-1"
      style={{ backgroundColor: '#ECECEC' }}
    >
      <div className={`text-13 text-black-main w-fit px-1`}>{value}</div>
      {removeValue && (
        <div className="pr-1">
          <img
            onClick={() => {
              removeValue();
            }}
            alt="ico"
            src="/svg/xIcon.svg"
            className="w-2.5 h-2.5 cursor-pointer"
          />
        </div>
      )}
    </div>
  );
}
