import DropdownSelection from '@components/dropdownSelection';
import MyInput from '@components/myInput';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { convertFloatWithDot } from '@utils/helper';
import { useEffect, useState } from 'react';
import moment from 'moment';
import RadioButton from '@components/radio';
import balanceConfigs from '@utils/configs/remoteConfigs/ewalletConfigs.json';
import { useGetEwallet } from '../../utils/hooks/common/useGetEwallet';
import { useGetRealEstateFees } from '@utils/hooks/common/useGetRealEstateFees';
import Submit from './submit';
import Captcha from './captcha';

type Props = {
  onClose?: Function,
  data?: Object,
};

export default function RenewProductModal({ onClose, data }: Props) {
  const previousPostType = data?.postType;
  const { postTypes } = configs;
  const { balanceTypes } = balanceConfigs;
  const [postType, setPostType] = useState(String(data?.postType));
  const [balanceType, setBalanceType] = useState('main');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaId, setCaptchaId] = useState();
  const [captchaCode, setCaptchaCode] = useState();
  const [date, setDate] = useState();
  const [captchaError, setCaptchaError] = useState();
  const wallet = useGetEwallet();
  let isActive = false;
  data?.postStatus === 'expired' ? isActive : (isActive = true);
  const { data: feeInfo } = useGetRealEstateFees({
    startDay: isActive ? data?.endShowTime : moment().unix(),
    endDay: isActive
      ? moment.unix(data?.endShowTime).add(date, 'days').unix()
      : moment().add(date, 'days').unix(),
    postType,
    id: data?.id,
    actionType: 'renewPost',
    type: 'product',
  });
  const message = useEffect(() => {
    wallet[balanceType] < feeInfo?.getRealEstateFees?.totalFees
      ? setErrorMessage(
          `${balanceTypes?.find(p => p.key === balanceType)?.value} không đủ.`
        )
      : setErrorMessage(null);
  }, [setErrorMessage, wallet, feeInfo, errorMessage]);
  return (
    <div className="flex w-428px bg-white flex-col rounded-lg overflow-auto">
      <div className="flex w-428px bg-white flex-col rounded-lg overflow-auto">
        <div className="flex-col flex px-4">
          <div className="mt-4 flex-row flex space-x-2">
            <img
              src="/svg/calendar-icon.svg"
              alt="calendar"
              className="flex w-24px h-24px"
            />
            <div className="flex text-16 text-blue-main font-medium">
              Gia hạn tin rao
            </div>
          </div>
          <div className="mt-2 flex-col flex">
            <div className="flex flex-row w-full mt-4 mb-4 h-fit space-x-2">
              <div className="w-full flex flex-col rounded shadow-md pl-2 pt-2">
                <div className="flex text-14 font-medium">Hết hạn vào lúc:</div>
                {isActive && (
                  <div className="flex text-14">
                    {moment
                      .unix(data?.endShowTime)
                      .format('hh:mm A, DD/MM/YYYY')}
                  </div>
                )}
                <div className="flex text-14">
                  {`(${
                    data?.remainingActivePost.includes('hết hạn')
                      ? data?.remainingActivePost
                      : `còn lại ${data?.remainingActivePost}`
                  })`}
                </div>
              </div>
              <div className="w-full flex flex-col shadow-md rounded pl-2 pt-2">
                {balanceTypes.map((item, index) => (
                  <RadioButton
                    key={index}
                    title={item?.value}
                    titleClass="font-medium"
                    title2={`${convertFloatWithDot(wallet?.main) || 0} VNĐ`}
                    title2Class="text-left text-red-500"
                    value={balanceType}
                    isChecked={item.key === balanceType}
                    onPress={() => {
                      setBalanceType(item.key);
                    }}
                  />
                ))}
              </div>
            </div>
            <span className="flex text-14 font-medium">
              Chọn loại tin rao cần gia hạn
            </span>
            <div className="flex mt-2">
              <DropdownSelection
                zIndex={1500}
                selections={postTypes.filter(
                  e => parseInt(e.key) <= previousPostType
                )}
                placeholder={'Chọn loại tin'}
                isHideDelete={false}
                onDelete={() => {
                  setPostType('');
                }}
                value={postType}
                setValue={setPostType}
              />
            </div>
          </div>
          <div className="mt-5 flex-col flex">
            <span className="flex text-14 font-medium">
              Nhập thời gian gia hạn thêm
            </span>
            <div className="flex mt-2 space-x-2">
              <MyInput
                hideDelete={false}
                onDelete={() => {
                  setDate('');
                }}
                errorMessage={errorMessage}
                type={'number'}
                onChange={e => {
                  setDate(e.target.value);
                  message;
                }}
                placeholder="Nhập thời gian gia hạn"
                inputClassName="w-full"
                value={date}
              />
              <span className="flex self-center text-14">Ngày</span>
            </div>
          </div>
          <div className="mt-4 flex-row flex space-x-1">
            <img
              src="/svg/privateNote.svg"
              className="flex self-start mt-0.5"
            />
            <div className="flex text-14 text-black-main">
              Gia hạn trên 30 ngày được giảm 10%, trên 60 ngày giảm 15% chi phí
            </div>
          </div>
          {date >= 1 && (
            <div>
              <div className="border-t border-gray-300 mt-5 space-y-3 border-b">
                <Item
                  title="Thời gian gia hạn:"
                  value={
                    isActive
                      ? `${moment
                          .unix(data?.endShowTime)
                          .format('DD/MM/YYYY')} đến ${moment
                          .unix(data?.endShowTime)
                          .add(date, 'days')
                          .format('DD/MM/YYYY')}`
                      : `${moment().format('DD/MM/YYYY')} đến ${moment()
                          .add(date, 'days')
                          .format('DD/MM/YYYY')}`
                  }
                  wrapperClass="mt-5"
                />
                <Item
                  title="Giá theo ngày:"
                  value={`${convertFloatWithDot(
                    postTypes.find(e => e.key === postType)?.fee
                  )} VNĐ/ngày đối với ${
                    postTypes.find(e => e.key === postType)?.value
                  }`}
                />
                <Item
                  title="Tổng giá:"
                  value={`${convertFloatWithDot(
                    feeInfo?.getRealEstateFees?.basicFees
                  )} VNĐ`}
                />
                <Item
                  title="VAT (10%):"
                  value={`${convertFloatWithDot(
                    feeInfo?.getRealEstateFees?.vat
                  )} VNĐ`}
                />
                <Item
                  title="Tổng chi phí:"
                  value={`${convertFloatWithDot(
                    feeInfo?.getRealEstateFees?.totalFees
                  )} VNĐ`}
                  valueStyle="text-blue-main font-medium"
                  wrapperClass="pb-4"
                />
              </div>
            </div>
          )}
          <Captcha
            setCaptchaError={setCaptchaError}
            captchaError={captchaError}
            wrapperStyle="mt-4"
            setCaptchaId={setCaptchaId}
            setCaptchaCode={setCaptchaCode}
          />
        </div>
      </div>
      <div>
        <Submit
          setCaptchaError={setCaptchaError}
          isActive={isActive}
          source={balanceType}
          endShowTime={
            isActive
              ? moment.unix(data?.endShowTime).add(date, 'days').unix()
              : moment().add(date, 'days').unix()
          }
          startShowTime={isActive ? data?.endShowTime : moment().unix()}
          postType={postType}
          captchaId={captchaId}
          captchaCode={captchaCode}
          errorMessage={errorMessage}
          date={date}
          onClose={onClose}
          realEstateId={data?.id}
        />
      </div>
    </div>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  valueStyle?: Object,
  hideField?: boolean,
  wrapperClass?: string,
};

function Item({
  title,
  value = '',
  valueStyle,
  hideField,
  wrapperClass = '',
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className={`flex w-full ${wrapperClass}`}>
      <div className="flex space-x-2">
        <div className={`text-14 font-medium text-black-main w-150px`}>
          {title}
        </div>
      </div>
      <div className={`text-sm text-left flex mr-4 ${valueStyle}`}>{value}</div>
    </div>
  );
}
