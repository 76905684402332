import React from 'react';

type Props = {
  data?: Object,
};
export default function HeadLeftUserDetail({ data }: Props) {
  return (
    <div
      className="flex w-full items-start border-b border-gray-300 space-x-6"
      style={{
        padding: '25px 30px 10px',
      }}
    >
      <div
        className="text-14 text-gray-300 font-medium"
        dangerouslySetInnerHTML={{
          __html: `<span class="text-black-main">Mã người dùng:</span> <span class="text-black-main">${
            data?.employeeCode || ''
          }</span> | <span class="text-black-main">${
            data?.fullName || ''
          }</span>`,
        }}
      />
    </div>
  );
}
