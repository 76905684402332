import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { omit, pickBy } from 'lodash';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MyToast from '@components/MyToast';
import { UPDATE_EMPLOYEE_ACCOUNT } from '@utils/graphql/mutations/employee';
import qs from 'query-string';
export const useUpdateEmployee = (idUser, onClose) => {
  const [updateEmployee, { loading: updateLoading, error, client }] =
    useMutation(UPDATE_EMPLOYEE_ACCOUNT);
  const toast = useToast();
  const navigate = useNavigate();
  const { isEdit } = qs.parse(location.search);
  const onUpdateSubmit = useCallback(
    async (state, isUpdatePermission = false) => {
      try {
        await updateEmployee({
          variables: {
            id: idUser,
            updateData: pickBy(
              {
                ...omit(
                  state,
                  'confirmPassword',
                  'password',
                  'username',
                  'role'
                ),
                avatar: state?.avatar?.fileId,
                idImages: state?.idImages?.map(e => e.fileId),
                attachments: state?.attachments?.map(e => e.fileId),
                dateOfBirth: state?.dateOfBirth
                  ? moment.unix(state?.dateOfBirth).toJSON()
                  : null,
              },
              e => e || e === false || e === null
            ),
          },
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message={
                isUpdatePermission && state?.permissions?.length > 0
                  ? 'Chỉnh sửa quyền thành công'
                  : state?.permissions?.length > 0
                  ? 'Phân quyền nhân viên thành công!'
                  : 'Chỉnh sửa nhân viên thành công!'
              }
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['b2bGetEmployees', 'b2bGetUserInfo'], client.cache);
        onClose && onClose();
        isEdit ? navigate(-1) : null;
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [updateEmployee, client, navigate]
  );
  return { onUpdateSubmit, updateLoading, error };
};
