import React from 'react';
import Section from './section';
import moment from 'moment/moment';
import { numberWithDots } from '@utils/helper';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';

type Props = {
  data?: Object,
};

export default function DeadlineAndPostType({ data }: Props) {
  const { postTypes } = configs;
  return data?.realEstateType !== 'PremiumRealEstate' ? (
    <Section
      source="/svg/dueDate.svg"
      title="Thời hạn & Loại tin đăng"
      style={{ width: '100%' }}
      innerClass="space-y-4 mt-4"
    >
      <Item
        title="Thời hạn"
        value={`${moment
          .unix(parseInt(data?.latestRenewInfo?.startTime, 10))
          .format('DD/MM/YYYY')} - ${moment
          .unix(parseInt(data?.latestRenewInfo?.endTime, 10))
          .format('DD/MM/YYYY')}`}
      />
      <Item
        title="Tổng giá trị tin đăng"
        value={`${numberWithDots(
          data?.latestRenewInfo?.basicAmount + data?.latestRenewInfo?.vat
        )} VNĐ`}
        valueStyle="text-blue-main font-medium"
      />
      <Item
        title="Loại tin đăng"
        value={
          postTypes?.find(
            item => item.key === data?.latestRenewInfo?.postType?.toString()
          )?.value
        }
      />
    </Section>
  ) : null;
}

type ItemProps = {
  title?: string,
  value?: string,
  valueStyle?: Object,
};

function Item({ title, value, valueStyle }: ItemProps) {
  return (
    <div className="flex w-full py-2 border-b border-gray-300 space-x-10">
      <div className="w-150px text-sm font-medium text-black-main">{title}</div>
      <div className={`flex-1 text-sm ${valueStyle}`}>{value}</div>
    </div>
  );
}
