import ModalWrapper from '@components/modalwrapper/ModalWrapper';
import { useGetContractDetail } from '@utils/hooks/manageContract/useGetContractDetail';
import { useGetUserInfo } from '@utils/hooks/manageUserB2B/useGetUserDetail';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import { useManageUserB2BContext } from 'scenes/home/manageUser/b2b/context';
import ConfirmModal from '../confirmModal';
import CustomerInfoInput from './components/contractinput';
import ContractDocuments from './components/documents';
import PermissionManagement from './components/permission';
import Submit from './components/submit';
import { blankState } from './configs';

type Props = {
  isNew?: Boolean,
  onClose?: Function,
  idAdmin?: String,
  onSuccess?: Function,
  tab?: String,
  contractId?: String,
  onCloseDetailModal?: Function,
  b2bUserData?: Object,
  listAttachment?: Array,
};

export default function CreateContract({
  isNew,
  onClose,
  idAdmin,
  onSuccess,
  tab,
  contractId,
  onCloseDetailModal,
  b2bUserData,
  listAttachment,
}: Props) {
  const { listSelected } = useManageUserB2BContext();
  const { data } = useGetUserInfo(listSelected?.[0]);
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });
  const [showModal, setShowModal] = useState(false);
  const isChanged = isEqual(blankState, form.watch());
  const { contractDetail } = useGetContractDetail({ id: contractId });
  let isUpgrade = false;
  contractId && !isNew ? (isUpgrade = true) : isUpgrade;
  const mergedData = {
    ...contractDetail,
  };
  const oldData = {
    name: mergedData?.name,
    note: mergedData?.note,
    contractDate: moment(mergedData?.contractDate).unix(),
    dueDate: mergedData?.dueDate,
    totalMember: mergedData?.totalMember,
    discount: mergedData?.discount,
    attachments: listAttachment ?? [],
    system: mergedData?.system,
    modules: mergedData?.modules ?? [],
    permissions: mergedData?.permissions ?? [],
  };
  useDeepCompareEffect(() => {
    if (mergedData && isUpgrade) {
      form.reset(oldData);
    }
  }, [mergedData]);
  const isUpgradeChanged = isEqual(oldData, form.watch());
  const onCloseModal = useCallback(() => {
    if ((!isChanged && isNew) || (!isUpgradeChanged && isUpgrade)) {
      setShowModal(true);
    } else {
      onClose();
    }
  }, [isChanged, isUpgradeChanged, isUpgrade, setShowModal, onClose]);
  return (
    <div
      className="h-fit w-full flex flex-col bg-white rounded-sm overflow-hidden"
      style={{
        maxWidth: 'calc(100vw - 80px)',
        maxHeight: 'calc(100vh - 80px)',
        borderRadius: 8,
      }}
    >
      <div className="relative pt-4 pb-6 flex justify-center items-center">
        <h1 className="text-[16px] leading-[20px] font-bold">Thêm hợp đồng</h1>
        <button type="button" onClick={onClose}>
          <img
            style={{ zIndex: 1000 }}
            alt="ico"
            src="/svg/ico-close.svg"
            className="w-6 h-6 absolute right-4 top-4"
          />
        </button>
      </div>
      <FormProvider {...form}>
        <div
          className="flex space-x-4 p-4 overflow-auto"
          style={{ background: '#f4f4f4' }}
        >
          <div className="w-1/2 h-full rounded-lg p-4 bg-white space-y-7">
            <h2 className="font-medium text-16" style={{ lineHeight: '20px' }}>
              Thông tin hợp đồng
            </h2>
            <CustomerInfoInput
              b2bUserData={b2bUserData}
              isUpgrade={isUpgrade}
            />
            <ContractDocuments
              attachments={listAttachment}
              isEdit={isUpgrade}
            />
          </div>
          <div className="w-1/2 h-fit">
            <PermissionManagement isUpgrade={isUpgrade} />
          </div>
        </div>
        <div className="px-4">
          <Submit
            onCloseDetailModal={onCloseDetailModal}
            contractId={contractId}
            isUpgrade={isUpgrade}
            tab={tab}
            onClose={onCloseModal}
            idAdmin={idAdmin}
            onSuccess={onSuccess}
            onCloseUpgrade={onClose}
          />
        </div>
      </FormProvider>
      <ModalWrapper isShowing={showModal} onToggle={() => setShowModal(false)}>
        <ConfirmModal
          question="Bạn có chắc muốn thoát?"
          contentTop="Bạn đang tạo hợp đồng mới, bạn có"
          contentBottom="chắc chắn muốn thoát?"
          onBack={onClose}
          onContinue={() => setShowModal(false)}
        />
      </ModalWrapper>
    </div>
  );
}
