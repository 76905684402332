import React from 'react';

type Props = {
  index?: number,
  measurePoints?: Array,
};

export default function MeasureMarker(props: Props) {
  const { index, measurePoints } = props,
    lastPoint = index === measurePoints.length - 1;
  const circleSize = lastPoint ? 24 : 15;

  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <div
        style={{
          width: circleSize,
          height: circleSize,
        }}
      >
        <img
          alt="icon"
          src={lastPoint ? '/svg/pinLast.svg' : '/svg/pinRed.svg'}
          className="w-full h-full"
        />
      </div>
    </div>
  );
}
