import React, { useCallback } from 'react';
import Table from '@components/table';
import RenderHeader from './renderHeader';
import { View } from 'react-native-web';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useManageUserB2BContext } from '../context';
import styles from '../../../manageRealEstate/needApproval/components/needApproval.module.scss';

import PropTypes from 'prop-types';
import { DocTienBangChuV2 } from '@utils/helper';
ItemUserB2B.propTypes = {
  loading: PropTypes.bool,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  singleOnSelect: PropTypes.func,
  isChecked: PropTypes.bool,
  item: PropTypes.object,
  pagination: PropTypes.object,
};
function ItemUserB2B({ loading, onSelect, singleOnSelect, isChecked, item }) {
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{
            backgroundColor: isChecked ? '#FFE6B4' : undefined,
            width: '100%',
          }}
          className={styles.row}
        >
          <div
            className={`${styles.itemTable} items-center justify-center text-sm w-60px`}
          >
            <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
          </div>
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div className={`${styles.itemTable} w-175px`}>
              {item?.street?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {item?.ward?.name ? (
                item?.ward?.name
              ) : (
                <p className="opacity-50 italic">Không có</p>
              )}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {item?.street?.district?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {item?.street?.district?.province?.name}
            </div>
            <div className={`${styles.itemTable} w-200px`}>
              {DocTienBangChuV2(item?.minPrice)}
            </div>
            <div className={`${styles.itemTable} w-200px`}>
              {DocTienBangChuV2(item?.avgPrice)}
            </div>
            <div
              className={`${styles.itemTable} w-200px`}
              style={{ borderRight: 'unset' }}
            >
              {DocTienBangChuV2(item?.maxPrice)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
UserB2BTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  test: PropTypes.array,
};
export default function UserB2BTable({ data, loading, test }) {
  const { listSelected, setListSelected } = useManageUserB2BContext();
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleSingleOnSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([key]);
      }
    },
    [setListSelected, listSelected]
  );

  return (
    <>
      <View
        style={{
          width: '100%',
          maxHeight: 527,
          backgroundColor: 'white',
          marginTop: 16,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
        }}
      >
        <Table>
          <View style={{ width: '100%' }}>
            <RenderHeader data={test} loading={loading} />
            {test?.map((item, index) => {
              const isChecked = listSelected.find(e => e === item.id);
              return (
                <ItemUserB2B
                  item={item}
                  loading={loading}
                  isChecked={isChecked}
                  onSelect={() => handleCheck(item?.id, isChecked)}
                  singleOnSelect={() =>
                    handleSingleOnSelect(item?.id, isChecked)
                  }
                  key={index}
                  index={index}
                />
              );
            })}
            {!loading && !data?.length && (
              <p className="text-base m-3">Không có khách hàng</p>
            )}
          </View>
        </Table>
      </View>
    </>
  );
}
