import CustomClickAwayListener from '@components/header/components/customClickAwayListener';
import Spotlight from '@components/header/components/spotlight';
import UserProfileDropdown from '@components/header/components/userProfileDropdown';
import HorizontalMenu from '@components/horizontal-menu/HorizontalMenu';
import { PermissionIds } from '@gugotech/tncc-users-permissions';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

Page403.propTypes = {
  open: PropTypes.bool,
  openSpotlight: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSpotlight: PropTypes.func,
};

export default function Page403({
  open,
  openSpotlight,
  setOpen,
  setOpenSpotlight,
}) {
  const navigate = useNavigate();
  const { permissions } = useGetMe();
  const handleOnClickSpotlightIcon = () => {
    setOpenSpotlight(!openSpotlight);
    setOpen(false);
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        minWidth: 1200,
        overflowX: 'hidden',
      }}
    >
      <div>
        <div
          style={{ zIndex: 1000 }}
          className="w-full h-60px bg-blue-main flex flex-row items-center px-4 py-3 justify-between fixed top-0"
        >
          <div className="flex flex-row items-center space-x-4 ">
            <img
              id="total-button"
              onClick={() => setOpen(!open)}
              alt="total-icon"
              src="/icons/modules/total-icon.svg"
              className="bg-contain cursor-pointer pl-2"
            />
            <img
              onClick={() => navigate('/dashboard')}
              alt="logo"
              src="/svg/logo_header_Biha.svg"
              className="bg-contain cursor-pointer "
            />
          </div>
          <div className="flex flex-row items-center space-x-5 sm:space-x-3">
            {permissions?.some(p => p.id === PermissionIds.PostProduct) && (
              <div className="flex flex-row border items-center border-white bg-blue-800 rounded-full px-2 py-1 cursor-pointer mr-4">
                <p
                  onClick={() => navigate('/publish/product')}
                  className="text-white font-medium mr-1 text-14 sm:text-13 pl-1"
                >
                  Đăng tin rao
                </p>
                <img alt="plus-icon" src="/svg/plus-icon.svg" />
              </div>
            )}
            <img
              alt="chat-ico"
              src="/svg/chat-icon.svg"
              className="cursor-pointer"
            />
            <img
              alt="noti-ico"
              src="/svg/noti-icon.svg"
              className="cursor-pointer"
            />
            <UserProfileDropdown />
          </div>
        </div>
        <div
          style={{
            zIndex: 900,
            position: 'fixed',
            top: open ? 60 : -180,
            left: 0,
            width: '100%',
            transition: 'top 0.3s ease-out',
          }}
        >
          <CustomClickAwayListener onClickAway={() => setOpen(false)}>
            <HorizontalMenu
              onClickUrl={() => setOpen(false)}
              onClickSpotlightIcon={handleOnClickSpotlightIcon}
              permissions={permissions}
            />
          </CustomClickAwayListener>
        </div>
        {openSpotlight ? (
          <Spotlight onClickSpotlightIcon={handleOnClickSpotlightIcon} />
        ) : null}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 'calc(100vh - 60px)',
        }}
      >
        {/* <ForbiddenImage /> */}
        <p
          style={{
            marginTop: 30,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#D01426',
            }}
          >
            Quyền truy cập bị từ chối
          </p>
          <p style={{ marginTop: 15 }}>
            Bạn không có quyền truy cập vào trang này.
          </p>
        </p>
      </div>
    </div>
  );
}
