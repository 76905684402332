import React from 'react';
import DatePicker from '@components/datePicker';
import moment from 'moment';
import MyDropdown from './myDropdown';

type Props = {
  value?: string,
  setValue?: Function,
  onDelete?: Function,
  isHideDelete?: boolean,
  disabled?: boolean,
  errorMessage?: string,
  dropdownClassName?: string,
  placeholder?: string,
  isMaxDate?: boolean,
};
export default function SingleCalendar({
  value,
  setValue,
  onDelete,
  isHideDelete,
  disabled,
  errorMessage,
  dropdownClassName,
  placeholder,
  isMaxDate = true,
}: Props) {
  return (
    <div className={`w-full relative space-y-2 ${dropdownClassName}`}>
      <MyDropdown
        zIndex={1500}
        wrapperClass="w-full"
        buttonDropdownClassName={`w-full h-40px p-2 flex justify-between items-center rounded-lg border  ${
          disabled ? 'bg-gray-100' : undefined
        } ${errorMessage?.length > 0 ? 'border-red-400' : 'border-blue-main'}`}
        DropdownComponent={DatePicker}
        dropdownContext={{
          value: value ? moment.unix(value) : moment(),
          setValue: nextValue => {
            setValue(moment(nextValue).unix());
          },
          maxDate: isMaxDate && moment(),
        }}
      >
        <div className={`text-sm text-black-main`}>
          {value
            ? `${moment.unix(value).format('DD/MM/YYYY')}`
            : placeholder || 'Chọn giá trị'}
        </div>
        <div className="flex flex-row items-center space-x-2">
          {value && !isHideDelete && onDelete && (
            <div
              onClick={!disabled ? onDelete : () => {}}
              className="w-20px h-20px cursor-pointer"
            >
              <img
                className="w-full h-full ml-1"
                alt="close"
                src="/svg/ico-close.svg"
              />
            </div>
          )}
          <div className="w-1px h-24px bg-gray-300" />
          <img
            src="/svg/calendar-icon-dropdown.svg"
            alt="arrow"
            className="w-18px h-18px"
          />
        </div>
      </MyDropdown>
      {!!errorMessage?.length && (
        <div className="text-sm text-red-400">{errorMessage}</div>
      )}
    </div>
  );
}
