import { Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/core';

type Props = {
  isLeader?: boolean,
};

export default function RenderIconDepartments({ isLeader }: Props) {
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: '#FFFFFF',
      color: '#22313E',
    },
  }));
  const classes = useStyles();
  return (
    <div className="ml-2">
      {isLeader && (
        <Tooltip
          title="Trưởng phòng"
          className="mr-2"
          classes={{
            tooltip: classes.customTooltip,
          }}
        >
          <img src="/svg/icon-leader.svg" alt="icon" />
        </Tooltip>
      )}
    </div>
  );
}
