import OwnerInformation from '@components/ownerInformation';
import RealEstateLocationOnMap from '@components/realEstateLocationOnMap';
import Utilities from '@components/utilities';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import Legal from '@components/legal';
import Media from '../components/media';
import ContractDocuments from '../../createB2BUserAccount/components/contractDocuments';
import React from 'react';
import { ProductDetails } from '../components/details';
import Demand from '@components/demand';
import Section from '@components/section';
import { useFormContext } from 'react-hook-form';
import { useValidateStep } from '@utils/hooks/createB2BRealEstate/useValidateStep';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSetFormState } from '@utils/hooks/createB2BRealEstate/useSetFormState';
import Note from '@components/note';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export default function AddProductInformation() {
  const location = useLocation();
  const {
    utilities,
    fixedUtilities,
    localUtilities,
    equipmentsAndServices,
    villaViewTypes,
  } = configs;
  const { permissions } = useGetMe();
  const { watch, reset } = useFormContext();
  const isCompleted = watch('isCompleted');
  const lat = watch('lat');
  const long = watch('long');
  const { isEdit, id } = qs.parse(location.search);
  useSetFormState(id, reset);
  useValidateStep(isCompleted, isEdit);
  return (
    <div className="w-full flex flex-row space-x-4">
      <div className="w-8/12 h-full space-y-4">
        <Demand isStep2={isCompleted || isEdit === 'true'} />
        <OwnerInformation
          hiddenFields={
            !permissions?.some(p => p.id === PermissionIds.ViewOwnerPhoneNumber)
              ? ['contactPhones']
              : []
          }
        />
        <ProductDetails />
        <Note headerText="Ghi chú công ty" fieldName="note" />
        <Legal />
        <Media isEdit={isEdit === 'true'} />
        <Section>
          <ContractDocuments
            isEdit={isEdit === 'true'}
            isDisableUpload={
              !permissions?.some(
                p => p.id === PermissionIds.AddProductAttachments
              )
            }
            isDisableRemove={
              !permissions?.some(
                p => p.id === PermissionIds.DeleteProductAttachments
              )
            }
            isHideTopLabel
            isShowCountFile
            label="Tài liệu đính kèm"
            style={{ padding: 0, marginTop: 0 }}
            labelStyle={{ color: '#2459ad', fontSize: 16 }}
            renderText={() => {
              return (
                <div className="text-sm font-medium text-black-main">
                  Thêm tài liệu đính kèm cho sản phẩm
                </div>
              );
            }}
          />
        </Section>
      </div>
      <div className="w-4/12 h-full space-y-4">
        <RealEstateLocationOnMap lat={lat} long={long} />
        <Utilities
          title="Tiện ích cố định"
          name="fixedUtilities"
          configs={fixedUtilities}
        />
        <Utilities title="Tiện ích" name="utilities" configs={utilities} />
        <Utilities
          title="Tiện ích trong khu vực"
          name="localUtilities"
          configs={localUtilities}
        />
        <Utilities title="View" name="villaView" configs={villaViewTypes} />
        <Utilities
          title="Tiện ích cố định"
          name="equipmentsAndServices"
          configs={equipmentsAndServices}
        />
      </div>
    </div>
  );
}
