import React from 'react';
import MyButton from '@components/button';
import SearchBox from '@components/searchBox';
import { useManageUserB2BContext } from '../b2b/context';
import { useNavigate } from 'react-router-dom';
type Props = {
  title?: string,
  currentTotal?: number,
  total?: number,
};

export default function RenderTopTable({
  title,
  currentTotal = 0,
  total = 0,
}: Props) {
  const { stateFilter, setStateFilterAt } = useManageUserB2BContext();
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-row py-2.5 overflow-auto space-x-2 items-end">
      <div style={{ minWidth: 300 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="text-18 font-medium text-black-main">{title}</div>
          <div className="text-14 text-black-main">
            ({currentTotal}/{total} hồ sơ)
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-end flex-row items-end space-x-4">
        <MyButton
          onPress={() => navigate('/createB2BUserAccount')}
          rightComponent={() => (
            <img alt="plus-icon" src="/svg/plus-icon.svg" />
          )}
          style={{ width: 146 }}
          type="blue"
          label="Tạo tài khoản"
        />
        <SearchBox
          searchValue={stateFilter.name}
          setSearchValue={value => setStateFilterAt('fullName')(value)}
          onDeleteValue={() => setStateFilterAt('fullName')('')}
        />
      </div>
    </div>
  );
}
