import { useQuery } from '@apollo/client';
import { GET_LIST_REAL_ESTATE_PROJECTS } from '@utils/graphql';
import { useGlobalContext } from '../../../context';
import { pickBy } from 'lodash';
import { cleanObj } from '@utils/helper';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';
import { useState } from 'react';
import moment from 'moment';

const getPriceFromToValue = (from, to) => {
  const amount = 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};

export const getVariable = state => {
  const t = pickBy(
    {
      projectCode: state?.projectCode,
      projectStatus: state?.projectStatus,
      projectName: state?.projectName,
      projectType: state?.projectType,
      houseNumber: state?.houseNumber,
      province: state?.province,
      district: state?.district,
      ward: state?.ward,
      street: state?.street,
      startDay: !state?.startDay ? null : moment.unix(state?.startDay).format(),
      endDay: !state?.endDay
        ? null
        : moment.unix(state?.endDay).endOf('day').format(),
      priceRange: getPriceFromToValue(
        state?.priceFrom,
        state?.priceTo > 100 ? null : state?.priceTo
      ),
    },
    e => e
  );
  return { ...cleanObj(t) };
};

export const useGetListRealEstateProjects = ({ state }) => {
  const { isLogin } = useGlobalContext();
  const variable = getVariable(state);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const finalVariables = {
    searchString: state?.searchString,
    inputData: {
      ...variable,
    },
    page: 1,
    limit: 10,
  };
  const { data, fetchMore, loading } = useQuery(GET_LIST_REAL_ESTATE_PROJECTS, {
    variables: {
      ...finalVariables,
    },
    skip: !isLogin,
  });
  const projectRealEstates = data?.getListRealEstateProjects?.items || [];
  const hasNextPage = data?.getListRealEstateProjects?.hasNextPage;
  const page = data?.getListRealEstateProjects?.page || 1;
  const total = data?.getListRealEstateProjects?.total;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });
  return {
    projectRealEstates,
    loading: loading,
    hasNextPage,
    page,
    total,
    onLoadMore,
    isLoadingMore,
  };
};
