import React, { useEffect, useState } from 'react';

function getVideoCover(fileUrl, seekTo = 0.0) {
  let Promise = require('es6-promise').Promise;
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', fileUrl);
    videoPlayer.load();
    videoPlayer.addEventListener('error', ex => {
      reject('error when loading video file', ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          blob => {
            resolve(URL.createObjectURL(blob));
          },
          'image/jpeg',
          0.75 /* quality */
        );
      });
    });
  });
}
type Props = {
  loading?: Boolean,
  data?: { uri?: string },
  remove?: Function,
  wrapperStyle?: string,
  readOnly?: boolean,
  type?: string,
  youtubeMetadata?: Object,
  url?: string,
  urls?: Array,
  originalUrl?: string,
  progress?: number,
  onPress?: Function,
};
export default function MediaComponent(props: Props) {
  const {
    loading,
    remove,
    wrapperStyle,
    type,
    youtubeMetadata,
    url,
    urls,
    progress,
    readOnly,
    originalUrl,
    onPress,
  } = props;
  const [thumbnailUrl, setThumbnailUrl] = useState(
    youtubeMetadata?.thumbnailUrl
  );
  useEffect(() => {
    (async function () {
      if (type === 'video' && url) {
        const thumbnail = await getVideoCover(url);
        setThumbnailUrl(thumbnail);
      }
      if (type === 'video' && urls) {
        setThumbnailUrl(urls?.find(e => e.type === 'thumbnailHd')?.url);
      }
      if (type === 'image' && url) {
        setThumbnailUrl(url);
      }
      if (type === 'image' && (urls || originalUrl)) {
        setThumbnailUrl(
          urls?.find(e => e.type === 'hd')?.url || urls?.url || originalUrl
        );
      }
    })();
  }, [type, url, urls, originalUrl]);
  return (
    <div
      className={`flex w-95px h-55px border border-gray-200 relative ${wrapperStyle}`}
      style={{ borderRadius: 9 }}
    >
      <div className="flex flex-1 items-center justify-center overflow-hidden">
        <div
          onClick={onPress}
          className={`w-full h-full bg-no-repeat bg-contain bg-center rounded ${
            loading ? 'opacity-30' : undefined
          }`}
          style={{ backgroundImage: `url('${thumbnailUrl}')` }}
        />
        {loading && (
          <div
            className="flex rounded-lg flex-col w-full h-full absolute items-center justify-center"
            style={{
              backgroundColor: 'rgba(0,0,0,0.4)',
            }}
          >
            <div className="text-white" style={{ fontSize: 11 }}>
              {parseInt(progress * 100, 10)}%
            </div>
          </div>
        )}
        {!loading && ['video', 'youtube'].indexOf(type) >= 0 && (
          <img
            onClick={onPress}
            className="w-44px h-44px absolute"
            alt="play"
            src="/svg/play.svg"
          />
        )}
        {!readOnly && (
          <button
            className="flex w-27px h-27px rounded-full absolute items-center justify-center"
            style={{
              top: -8,
              right: -10,
            }}
            onClick={remove}
          >
            <img className="w-full h-full" alt="close" src="/svg/close.svg" />
          </button>
        )}
      </div>
    </div>
  );
}
