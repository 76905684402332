import styles from './editUserHistoryModal.module.scss';
import SwitchInner from '@components/switchInner';
import React from 'react';
import MyButton from '@components/button';
import HeaderTableEditUserHistory from './component/headerTableEditUserHistory';
import Table from '@components/table';
import { useHeaderTableEditUserHistoryConfigs } from './configs';
import { useGetUserHistories } from '@utils/hooks/userEditHistory/useGetUserHistories';
import SeeMore from '@components/seeMore';
import TableRow from './component/tableRow';
import { View } from 'react-native-web';

const switchTabConfigs = () => {
  return [
    {
      key: '0',
      value: 'Lịch sử chỉnh sửa',
    },
  ];
};

type Props = {
  userId?: string,
  onClose?: Function,
  isPersonal?: boolean,
};
export default function EditUserHistoryModal({
  userId,
  onClose,
  isPersonal,
}: Props) {
  const tabConfigs = switchTabConfigs();
  const headerTableConfigs = useHeaderTableEditUserHistoryConfigs(isPersonal);
  const { data, loading, hasNextPage, onLoadMore } = useGetUserHistories({
    userId,
  });
  return (
    <div className={`${styles.modalContainer}`}>
      <div style={{ zIndex: 20 }} className="sticky top-0 bg-white">
        <SwitchInner
          currentTab={'0'}
          setCurrentTab={() => {}}
          configs={tabConfigs}
        />
      </div>
      <View
        style={{
          flex: 1,
          maxHeight: 400,
          backgroundColor: 'white',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
          marginVertical: 20,
          marginHorizontal: 8,
        }}
      >
        <Table>
          <View style={{ width: '100%' }}>
            <HeaderTableEditUserHistory
              isPersonal={isPersonal}
              headerTableConfigs={headerTableConfigs?.filter(e => !e?.isHidden)}
            />
            {data?.map((item, index) => {
              return (
                <TableRow
                  isPersonal={isPersonal}
                  key={`row-${index}`}
                  item={item}
                  index={index}
                />
              );
            })}
            {!loading && !data?.length && (
              <p className="text-black-main text-base m-3">
                Không có lịch sử chỉnh sửa
              </p>
            )}
          </View>
        </Table>
        <SeeMore
          hasNextPage={hasNextPage}
          loading={loading}
          onLoadMore={onLoadMore}
        />
      </View>
      <div className="w-full h-60px flex justify-end items-center border-t border-gray-300 sticky bottom-0 bg-white">
        <div className="w-160px">
          <MyButton onPress={onClose} type="gray" label="Trở về" />
        </div>
      </div>
    </div>
  );
}
