import ContactInfo from './contactInfo';
import Section from './section';
import DetailRealEstate from './detailRealEstate';
import DeadlineAndPostType from './deadlineAndPostType';
import Utilities from './utilities';
import Legal from './legal';
import LocationRealEstate from './locationRealEstate';

type Props = {
  data?: Object,
  demand?: string,
};
export default function RenderRight({ data, demand }: Props) {
  return (
    <div className="flex-1 p-4 rounded-lg border border-blue-main">
      <div className="flex flex-col flex-1 space-y-8">
        <ContactInfo data={data} />
        <Section
          source="/svg/title.svg"
          title="Tiêu đề tin rao"
          innerClass="py-2 border-b border-gray-300"
        >
          <div className="text-sm text-black-main">{data?.postTitle}</div>
        </Section>
        <Section
          source="/svg/mdi-light_content-paste.svg"
          title="Nội dung tin rao"
          innerClass="py-2 border-b border-gray-300"
        >
          <div className="text-sm text-black-main">{data?.postContent}</div>
        </Section>
        <DetailRealEstate data={data} demand={demand} />
        <DeadlineAndPostType data={data} />
        {[
          ...(data?.fixedUtilities || []),
          ...(data?.utilities || []),
          ...(data?.localUtilities || []),
          ...(data?.equipmentsAndServices || []),
        ]?.length > 0 && <Utilities data={data} />}
        <Legal data={data} />
        <LocationRealEstate
          lat={data?.map?.coordinates?.[1]}
          long={data?.map?.coordinates?.[0]}
        />
      </div>
    </div>
  );
}
