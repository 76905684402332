import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@utils/graphql';
import { convertProvinceTree } from '@utils/helper';
import { HoChiMinhId } from '../../../../../../../../../constant';
import React from 'react';
import ProvinceTreeView from '../components/ProvinceTreeView';

type ProvinceModelProps = {
  selectedItems: Array,
  setItems: Function,
  onClose: Function,
  id?: string,
};
export default function ListProvinceModal({
  setItems,
  selectedItems,
  onClose,
}: ProvinceModelProps) {
  const { data } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
      variables: {
        provinceId: HoChiMinhId,
        limit: 100,
      },
    }),
    rawData = data?.getListDistrictsOfProvince?.districts || [];
  const dataSelections = [
      {
        id: HoChiMinhId,
        name: 'Thành phố Hồ Chí Minh',
        children: convertProvinceTree(rawData, HoChiMinhId),
        type: 'Province',
        totalDistricts: rawData?.length,
      },
    ],
    flatItems = [
      {
        id: HoChiMinhId,
        name: 'Thành phố Hồ Chí Minh',
        type: 'Province',
        totalDistricts: rawData?.length,
      },
      ...convertProvinceTree(rawData, HoChiMinhId),
    ];
  return (
    <ProvinceTreeView
      onClose={onClose}
      allFlatItems={flatItems}
      selectedItems={selectedItems}
      setItems={setItems}
      data={dataSelections}
      headerLabel="Chọn khu vực"
      searchPlaceHolder="Tìm kiếm khu vực"
    />
  );
}
