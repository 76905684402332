import { useMutation } from '@apollo/client';
import { CHANGE_PRODUCT_STATUS } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';

export const useChangeRealEstateProductStatus = (
  realEstateId,
  setShowModal
) => {
  const [onUpdateStatus, { loading, error, client }] = useMutation(
    CHANGE_PRODUCT_STATUS
  );
  const toast = useToast();
  const onSubmit = useCallback(
    async (status, depositWaitingTime, shareToUsers) => {
      try {
        const data = {
          realEstateId,
          status,
        };
        if (depositWaitingTime) {
          data.depositWaitingTime = parseInt(depositWaitingTime);
        }
        if (shareToUsers?.length) {
          data.shareToUsers = shareToUsers;
        }
        await onUpdateStatus({
          variables: {
            ...data,
          },
        });
        await resetQueries(
          ['getInternalRealEstateDetails', 'getRealEstateHistories'],
          client.cache
        );
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Chuyển trạng thái sản phẩm thành công"
              closeButtonPos="center"
            />
          ),
        });
        setShowModal(false);
      } catch (e) {
        console.log(e);
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="error"
              message="Chuyển trạng thái sản phẩm thất bại"
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [onUpdateStatus, client, realEstateId, setShowModal]
  );
  return { onSubmit, loading, error };
};
