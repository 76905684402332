import React from 'react';
import { useQuery } from '@apollo/client';
import { convertDataSelections } from '@utils/helper';
import { GET_LIST_STREET_OF_DISTRICT } from '@utils/graphql';
import DropdownSelection from '@components/dropdownSelection';

type Props = {
  value?: string,
  setValue?: Function,
  onDelete?: Function,
  invalid?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  districtId?: string,
  wardId?: string,
  getObject?: boolean,
  provinceId?: string,
};
export default function StreetDropdownSelection({
  value,
  setValue,
  onDelete,
  invalid,
  errorMessage,
  disabled,
  districtId,
  wardId,
  getObject,
}: Props) {
  let queryStreet,
    queryVariables = '';
  (queryStreet = GET_LIST_STREET_OF_DISTRICT),
    (queryVariables = { districtId, limit: 500 });
  const { data, loading } = useQuery(queryStreet, {
    variables: {
      ...queryVariables,
    },
    skip: !districtId && !wardId,
  });
  const finalData = data?.getListStreetsOfDistrict?.streets;
  const rawData = [...(finalData || [])];
  const dataSelections = convertDataSelections(rawData);
  return (
    <DropdownSelection
      getObject={getObject}
      selections={dataSelections}
      value={value}
      setValue={setValue}
      onDelete={onDelete}
      loading={loading}
      errorMessage={errorMessage}
      invalid={invalid}
      disabled={disabled}
      isShowSearchBox
      placeholder="Đường"
    />
  );
}
