import RenderEmployeeInfo from './renderEmployeeInfo';
import React from 'react';

type Props = {
  data?: Object,
};
export default function ContentUserDetail({ data }: Props) {
  return (
    <div className="flex-1 flex flex-col space-y-10 ">
      <RenderEmployeeInfo data={data} />
    </div>
  );
}
