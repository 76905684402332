import styles from '@components/table/table.module.scss';
import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';
import MyModalV2 from '@components/modalV2/myModalV2';
import ModalViewMedia from 'modals/modalViewMedia';
import { useConvertDataProductEditHistory } from '@utils/hooks/realEstateEditHistory/useConvertDataProductEditHistory';
import AttachmentsModal from './attachmentsModal';
import PropTypes from 'prop-types';

TableRow.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  type: PropTypes.string,
  headerTableConfigs: PropTypes.array,
  attachments: PropTypes.array,
  pagination: PropTypes.object,
};
export default function TableRow(props) {
  const {
    index,
    item,
    type,
    headerTableConfigs,
    pagination = [],
    attachments,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const data = useConvertDataProductEditHistory(
    item,
    type,
    setShowModal,
    setShowAttachmentsModal
  );
  const configs = headerTableConfigs.map(item => {
    const foundConfigs = data.find(e => e.key === item.key);
    if (foundConfigs) {
      return { ...item, ...foundConfigs };
    }
  });
  const dataRender = configs;
  let listId = [];
  listId = item?.mediaUrls;
  const [listUrls, setListUrls] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(listId);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [item, listId]);
  return (
    <>
      <div
        className="flex w-full border-b border-gray-300"
        style={{ minHeight: 40 }}
      >
        <div
          className={`${styles.headContainer} w-120px items-center justify-center`}
        >
          <div className={`text-13 text-black-main ${styles.textHead}`}>
            {pagination.totalItem -
              pagination.rowsPerPage * (pagination.currentPage - 1) -
              index -
              1 || 'Khởi tạo'}
          </div>
        </div>
        {dataRender.map((item, index) => {
          if (item?.isShow.includes(type) || item?.isShow.includes(true))
            return (
              <div
                key={index}
                className={`${styles.headContainer} ${item?.className} items-center`}
              >
                <div
                  className={`text-13 text-black-main ${styles.textHead} w-full text-center truncate`}
                >
                  {typeof item?.value === 'function'
                    ? item?.value()
                    : item?.value || '-'}
                </div>
              </div>
            );
        })}
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={0} listMedia={listUrls || []} />
      </MyModalV2>
      <MyModalV2
        open={showAttachmentsModal}
        onClose={() => setShowAttachmentsModal(false)}
      >
        <AttachmentsModal data={attachments || []} />
      </MyModalV2>
    </>
  );
}
