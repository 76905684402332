import UserAvatar from './userAvatar';
import Section from '../../../../../../manageRealEstate/realEstateDetail/components/leftRealEstateDetail/contentDetailRealEstate/section';
import ItemRowUserDetail from './itemRowUserDetail';
import { useGetCompanyInfo } from '@utils/hooks/manageUserB2B/useCompanyInfo';
type Props = {
  companyId?: string,
};
export default function RenderCustomerInfo({ companyId }: Props) {
  const { data } = useGetCompanyInfo(companyId);
  return (
    <div className="w-full flex flex-row space-x-4">
      <UserAvatar avatarUrl={data?.logo?.originalUrl || '/svg/male.svg'} />
      <Section
        source="/svg/book-icon.svg"
        title="Thông tin chi tiết khách hàng"
        wrapperClass="flex-1 border border-blue-main p-4 rounded-lg"
      >
        <ItemRowUserDetail
          title={data?.isPersonal ? 'Tên người dùng' : 'Tên công ty'}
          value={data?.name}
        />
        {!data?.isPersonal && (
          <ItemRowUserDetail title="Mã số thuế" value={data?.taxId} />
        )}
        <ItemRowUserDetail
          title="Địa chỉ"
          value={`${data?.houseNumber ? data?.houseNumber + ',' : ''} ${
            data?.street?.name ? data?.street?.name + ',' : ''
          } ${data?.ward?.name ? data?.ward?.name + ',' : ''}  ${
            data?.district?.name ? data?.district?.name + ',' : ''
          } ${data?.province?.name ? data?.province?.name : ''}`}
        />
      </Section>
    </div>
  );
}
