export const blankState = {
  name: '',
  note: '',
  contractDate: '',
  dueDate: '',
  totalMember: '',
  discount: '',
  attachments: [],
  permissions: [],
  modules: [],
};

export const systems = [
  {
    key: 'product',
    value: 'BDS riêng lẻ',
  },
  {
    key: 'project',
    value: 'Dự án',
  },
];
