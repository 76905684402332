import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';

export const useConvertDataRenewRealEstate = (item = {}, type) => {
  const { forRentRealEstateTypes } = configs;
  const isNeedRealEstate = type === 'demand';
  return [
    {
      key: 'typeOfDemand',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/isForSell')?.value;
        if (typeof foundValue !== 'boolean') {
          return 'Không có';
        }
        if (isNeedRealEstate) {
          return foundValue ? 'Cần mua' : 'Cần thuê';
        }
        return foundValue ? 'Bán' : 'Cho thuê';
      },
    },
    {
      key: 'realEstateType',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/type')?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return forRentRealEstateTypes.find(e => e.key === foundValue)?.value;
      },
    },
    {
      key: 'street',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/streetName')?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return foundValue;
      },
    },
    {
      key: 'ward',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/wardName')?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return foundValue;
      },
    },
    {
      key: 'district',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/districtName'
        )?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return foundValue;
      },
    },
    {
      key: 'province',
      value: () => {
        const foundValue = item.ops.find(
          e => e.path === '/provinceName'
        )?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return foundValue;
      },
    },
    {
      key: 'country',
      value: () => {
        const foundValue = item.ops.find(e => e.path === '/country')?.value;
        if (!foundValue) {
          return 'Không có';
        }
        return foundValue;
      },
    },
  ];
};
