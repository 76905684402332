import MyButton from '@components/button';

export default function RightProjectDetail() {
  return (
    <div
      style={{
        boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)',
        minWidth: 240,
      }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7"
    >
      <div className="space-y-4">
        <MyButton type="blue" label="Xem chi tiết sản phẩm DA" />
        <MyButton type="gray" label="Chỉnh sửa dự án" />
        <MyButton type="gray" label="Đăng DA lên sàn giao dịch" />
      </div>
    </div>
  );
}
