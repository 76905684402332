import { useToast } from '@chakra-ui/react';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';
import { useMutation } from '@apollo/client';
import { APPROVE_OR_REJECT_REAL_ESTATE_PRODUCT } from '@utils/graphql';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';

export const useApprovalRealEstateProduct = (
  realEstateIds,
  setShowModalApproval
) => {
  const toast = useToast();
  const { setListSelected } = useProductContext();
  const [approvalProduct, { loading, client }] = useMutation(
    APPROVE_OR_REJECT_REAL_ESTATE_PRODUCT
  );

  const onApproval = useCallback(async () => {
    try {
      await approvalProduct({
        variables: {
          productsIds: realEstateIds,
          approveStatus: {
            status: 'approved',
          },
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Duyệt tin rao thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModalApproval(false);
    } catch (e) {
      toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            type="error"
            message={'Duyệt tin rao thất bại'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [approvalProduct, realEstateIds, setListSelected]);
  return { onApproval, loading };
};
