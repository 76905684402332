import { useLocation } from 'react-router-dom';

export const blankState = {
  name: '',
};

export const useGetLocation = () => {
  const { pathname } = useLocation();

  const isTabOrganizationDiagram =
      '/manageOrganization/organization-chart'.includes(pathname),
    isTabPermissionGroups = '/manageOrganization/permission-groups'.includes(
      pathname
    );

  return {
    isTabOrganizationDiagram,
    isTabPermissionGroups,
  };
};
