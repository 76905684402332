import React from 'react';
import Demand from '@components/demand';
import RealEstateLocationOnMap from '@components/realEstateLocationOnMap';
import Utilities from '@components/utilities';
import {
  utilities,
  fixedUtilities,
  localUtilities,
  equipmentsAndServices,
  villaViewTypes,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import Legal from '@components/legal';
import Media from '../../createB2BRealEstate/components/media';
import Section from '@components/section';
import ContractDocuments from '../../createB2BUserAccount/components/contractDocuments';
import OwnerInformation from '@components/ownerInformation';
import { useFormContext } from 'react-hook-form';
import { ProductDetails } from '../../createB2BRealEstate/components/details';

type Props = {
  id?: string,
  isContinue?: boolean,
  setIsContinue?: Function,
  isEdit?: Boolean,
};
export default function PostDetails({
  id,
  isContinue,
  setIsContinue,
  isEdit = false,
}: Props) {
  const { watch } = useFormContext();
  const lat = watch('lat');
  const long = watch('long');
  return (
    <div className="grid grid-cols-5 gap-4">
      <div className="col-span-3 grid grid-row gap-4">
        <Demand />
        <ProductDetails priceFieldName="priceOfOwner" isPublishProduct={true} />
        <OwnerInformation
          fieldNames={{
            name: 'ownerName',
            phone: 'ownerPhone',
            email: 'ownerEmail',
          }}
          isDisableInput={false}
          isShowTips={true}
        />
        <Legal />
        <Media
          isEdit={id || isEdit}
          isContinue={isContinue}
          setIsContinue={setIsContinue}
        />
        <Section>
          <ContractDocuments
            isDisableUpload={true}
            isEdit={isEdit}
            isContinue={isContinue}
            setIsContinue={setIsContinue}
            isHideTopLabel
            isShowCountFile
            label="Tài liệu đính kèm"
            style={{ padding: 0, marginTop: 0 }}
            labelStyle={{ color: '#2459ad', fontSize: 16 }}
            renderText={() => {
              return (
                <div className="text-sm font-medium text-black-main">
                  Thêm tài liệu đính kèm cho sản phẩm
                </div>
              );
            }}
          />
        </Section>
      </div>
      <div className="col-span-2 grid grid-row auto-rows-min gap-4">
        <RealEstateLocationOnMap lat={lat} long={long} />
        <Utilities
          title="Tiện ích cố định"
          name="fixedUtilities"
          configs={fixedUtilities}
        />
        <Utilities title="Tiện ích" name="utilities" configs={utilities} />
        <Utilities
          title="Tiện ích trong khu vực"
          name="localUtilities"
          configs={localUtilities}
        />
        <Utilities title="View" name="villaView" configs={villaViewTypes} />
        <Utilities
          title="Thiết bị & dịch vụ"
          name="equipmentsAndServices"
          configs={equipmentsAndServices}
        />
      </div>
    </div>
  );
}
