import { View } from 'react-native-web';
import Table from '@components/table';
import React, { useState } from 'react';
import styles from '../../../../../../manageRealEstate/needApproval/components/needApproval.module.scss';
import realEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import moment from 'moment';
import {
  covertValueFromTo,
  listPostStatus,
  merge2Array,
  usePrice,
} from '@utils/helper';
import { isEmpty } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import { useGetLocation } from 'scenes/home/manageProduct/configs';
import RenderIconComponent from 'scenes/home/manageProduct/componentIconTable/renderIconComponent';
import RenderAvatarComponent from 'scenes/home/manageProduct/components/renderAvatarComponent';
import TriangleComponent from 'scenes/home/manageProduct/components/triangleComponent';
import RenderHeader from 'scenes/home/manageProduct/components/renderSameProductHeader';
import Pagination from '@components/pagination';
import PropTypes from 'prop-types';
import { useSameProductContext } from 'scenes/home/manageProduct/product/context';
import { useGetRelatedInternalRealEstates } from '@utils/hooks/manageProduct/useGetRelatedInternalRealEstates';

ItemTableProduct.propTypes = {
  loading: PropTypes.bool,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  singleOnSelect: PropTypes.func,
  isChecked: PropTypes.bool,
  item: PropTypes.object,
  pagination: PropTypes.object,
  selectedItem: PropTypes.string,
};
function ItemTableProduct({
  loading,
  index,
  singleOnSelect,
  item,
  selectedItem,
  pagination,
}) {
  const {
    realEstateId,
    type,
    street,
    ward,
    district,
    province,
    houseNumber,
    createdAt,
    updatedAt,
    price,
    priceUnit,
    contactName,
    floorCount,
    width,
    length,
    rear,
    area,
    approvedAt,
    approvedBy,
    creator,
    status,
    postStatus,
  } = item || {};
  const { pathname } = useLocation();
  const typeOfDemandProduct = [
    '/manageProduct/product',
    '/manageProduct/my-product',
    '/manageProduct/product/waiting-for-approval',
    '/manageProduct/product/hidden',
    '/manageProduct/product/deleted',
  ].includes(pathname);
  const { isTabMyProduct } = useGetLocation();
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { realEstateForRentStatus, realEstateForSaleStatus } =
      realEstateConfigurations,
    finalStatus = merge2Array(realEstateForRentStatus, realEstateForSaleStatus);
  const configStatusName = finalStatus
      ?.filter(e => status?.includes(e?.key))
      ?.map(e => e.value),
    configStatusPosted = listPostStatus
      ?.filter(e => postStatus?.includes(e?.key))
      ?.map(e => e.value);
  const isForSell = item?.typeOfDemand === 'sale' ? 'Bán' : 'Cho thuê';
  const typeOfDemand = item?.isForSell ? 'Bán' : 'Cho thuê';
  const { forRentRealEstateTypes } = realEstateCommonConfigs;
  const { price: resultPrice, priceUnit: resultPriceUnit } = usePrice(item);
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: '#FFFFFF',
      color: '#22313E',
    },
  }));
  const classes = useStyles();
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{
            backgroundColor: selectedItem === item?.id ? '#FFE6B4' : undefined,
          }}
          className={styles.row}
        >
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div
              className={`${styles.itemTable} text-sm w-60px flex items-center justify-center`}
            >
              {(pagination.currentPage - 1) * pagination.rowsPerPage +
                index +
                1}
            </div>
            <div className={`${styles.itemTable} relative w-210px`}>
              <div className="flex flex-1 text-black-main">{realEstateId}</div>
              {isTabMyProduct && (
                <RenderIconComponent
                  isTabMyProduct={isTabMyProduct}
                  approvedStatus={item?.approveStatus?.status}
                />
              )}
              {item?.isRead ? (
                <Tooltip
                  className="mr-2"
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Có cập nhật mới về sản phẩm"
                >
                  <img src="/svg/rectangle.svg" alt="icon" />
                </Tooltip>
              ) : (
                <div className="mr-4" />
              )}
            </div>
            <div className={`${styles.itemTable} relative w-180px`}>
              {typeOfDemandProduct ? configStatusName : configStatusPosted}
              <TriangleComponent
                status={typeOfDemandProduct ? item?.status : item?.postStatus}
              />
            </div>
            <div className={`${styles.itemTable} w-140px`}>
              {typeOfDemandProduct ? isForSell : typeOfDemand}
            </div>
            <div className={`${styles.itemTable} w-145px`}>
              {forRentRealEstateTypes?.find(e => e.key === type)?.value}
            </div>
            <div className={`${styles.itemTable} w-140px truncate`}>
              {houseNumber}
            </div>
            <div className={`${styles.itemTable} w-170px`}>{street?.name}</div>
            <div className={`${styles.itemTable} w-140px`}>
              {ward?.name ? ward?.name : 'Không có'}
            </div>
            <div className={`${styles.itemTable} w-145px`}>
              {district?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {province?.name}
            </div>
            <div className={`${styles.itemTable} w-175px truncate`}>
              {contactName}
            </div>
            <div className={`${styles.itemTable} w-154px`}>
              {price ? (
                `${resultPrice} ${resultPriceUnit} ${
                  priceUnit ? priceUnit : ''
                }`
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>

            <div className={`${styles.itemTable} w-124px`}>
              {item?.width?.to || width ? (
                `${covertValueFromTo(item, 'width')} m`
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-124px`}>
              {item?.length?.to || length ? (
                `${covertValueFromTo(item, 'length')} m`
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-124px`}>
              {item?.rear?.to || rear ? (
                `${covertValueFromTo(item, 'rear')} m`
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {item?.area?.to || area ? (
                `${covertValueFromTo(item, 'area')} m²`
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>

            <div className={`${styles.itemTable} w-133px`}>
              {floorCount ? (
                floorCount
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>

            <div className={`${styles.itemTable} w-218px`}>
              {!isEmpty(creator) ? (
                <MyDropdown
                  DropdownComponent={UserInfo}
                  dropdownContext={{ userId: item.creator.id }}
                >
                  <div className="flex space-x-2 items-center w-200px">
                    <RenderAvatarComponent
                      wrapperStyle="w-30px h-30px"
                      avatarId={item?.creator?.avatar}
                    />
                    <span className="text-14 font-text text-blue-main underline truncate">
                      {item?.creator?.fullName}
                    </span>
                    <img src="/svg/eye.svg" alt="icon" />
                  </div>
                </MyDropdown>
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-185px`}>
              {moment(createdAt).format('hh:mmA,DD/MM/YYYY')}
            </div>
            <div className={`${styles.itemTable} w-185px`}>
              {updatedAt ? (
                moment(updatedAt).format('hh:mmA,DD/MM/YYYY')
              ) : (
                <span className="italic">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-218px`}>
              {!isEmpty(approvedBy) ? (
                <MyDropdown
                  DropdownComponent={UserInfo}
                  dropdownContext={{ userId: item?.approvedBy.id }}
                >
                  <div className="flex space-x-2 items-center w-200px">
                    <RenderAvatarComponent
                      wrapperStyle="w-30px h-30px"
                      avatarId={item?.approvedBy?.avatar}
                    />
                    <span className="text-14 font-text text-blue-main underline truncate">
                      {item?.approvedBy?.fullName}
                    </span>
                    <img src="/svg/eye.svg" alt="icon" />
                  </div>
                </MyDropdown>
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {approvedAt ? (
                moment(approvedAt).format('hh:mmA,DD/MM/YYYY')
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

RenderTableProduct.propTypes = {
  id: PropTypes.string,
  demand: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  setSameButton: PropTypes.func,
  setIdSameProduct: PropTypes.func,
  pagination: PropTypes.object,
};
export default function RenderTableProduct({
  id,
  demand,
  type,
  data,
  loading,
  setSameButton,
  setIdSameProduct,
  pagination,
}) {
  const { stateFilter } = useSameProductContext();

  const [selectedItem, setSelectedItem] = useState();
  const { onLoadByPagination } = useGetRelatedInternalRealEstates({
    state: stateFilter,
    info: { typeOfDemand: demand, type },
    currentREId: id,
  });

  const handleOnPageChange = event => {
    onLoadByPagination(parseInt(event.selected));
  };

  return (
    <>
      <div
        className="w-full h-full overflow-hidden table table-fixed"
        style={{
          width: '100%',
          backgroundColor: 'white',
          marginTop: 8,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
        }}
      >
        <Table>
          <View style={{ width: '100%', maxHeight: 480 }}>
            <RenderHeader data={data} loading={loading} />
            {data?.map((item, index) => {
              return (
                <ItemTableProduct
                  item={item}
                  loading={loading}
                  singleOnSelect={() => {
                    if (!selectedItem) {
                      setSelectedItem(item?.id);
                      setSameButton(true);
                      setIdSameProduct(item?.id);
                    } else if (selectedItem !== item?.id) {
                      setSelectedItem(item?.id);
                      setSameButton(true);
                      setIdSameProduct(item?.id);
                    } else {
                      setSelectedItem('');
                      setSameButton(false);
                    }
                  }}
                  selectedItem={selectedItem}
                  key={index}
                  index={index}
                  pagination={pagination}
                />
              );
            })}
            {!loading && !data?.length && (
              <p className="text-base m-3">
                Chưa có nhu cầu phù hợp với sản phẩm này
              </p>
            )}
          </View>
        </Table>
      </div>
      <div className="w-full flex justify-end py-6">
        <Pagination
          loading={loading}
          pagination={pagination}
          onPageChange={handleOnPageChange}
        />
      </div>
    </>
  );
}
