import styles from '../comment.module.css';
import MyButton from '@components/button';
import React, { useState, useImperativeHandle } from 'react';

const maxlength = 888;

type Props = {
  onSend?: Function,
  disabled?: boolean,
};
export default React.forwardRef(InputComment);

function InputComment(props: Props, ref) {
  const { onSend = () => {}, disabled } = props;
  const [value, setValue] = useState('');
  useImperativeHandle(ref, () => ({
    resetValue: () => setValue(''),
  }));
  return (
    <div
      className="w-full flex flex-row border relative border-blue-main rounded-lg items-center"
      style={{ padding: 10 }}
    >
      <textarea
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        placeholder="Nhập nội dung để ghi chú"
        className={`${styles.textInput}`}
        rows={2}
        maxLength={maxlength}
      />
      <div
        className="h-full flex flex-col justify-between absolute top-1 right-28 py-2"
        style={{
          minWidth: 32,
        }}
      >
        <div
          style={{ fontSize: 11 }}
          className="text-gray-400 absolute bottom-4"
        >
          {maxlength - value?.length}
        </div>
      </div>
      <MyButton
        disabled={disabled || !value?.trim().length}
        style={{ width: 105, height: 40 }}
        type="blue"
        label="Gửi"
        onPress={() => {
          onSend({ content: value });
          setValue('');
        }}
      />
    </div>
  );
}
