import MyButton from '@components/button';
import Label from '@components/label';
import { ClickAwayListener } from '@mui/material';
import { nonAccentVietnamese } from '@utils/helper';
import { filter, includes, isEmpty, isEqual, map, size } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';

export default function ModulesSelection() {
  const modules = [
    {
      name: 'Quản lý dự án',
      value: 'PROJECT',
      url: '/icons/modules/manager-project.svg',
    },
    {
      name: 'Quản lý nhà phố',
      value: 'REALESTATE',
      url: '/icons/modules/manager-product.svg',
    },
    {
      name: 'Quản lý dự án thuê',
      value: 'PROJECT_FOR_LEASE',
      url: '/icons/modules/manager-project.svg',
    },
    {
      name: 'Quản lý CRM',
      value: 'CRM',
      url: '/icons/modules/manager-crm.svg',
    },
    {
      name: 'Tổng đài thông minh',
      value: 'CALLCENTER',
      url: '/icons/modules/call-center.svg',
    },
    {
      name: 'Quản lý hồ sơ công chứng',
      value: 'NOTARIZATION',
      url: '/icons/modules/manager-notarization.svg',
    },
    {
      name: 'Quản lý vay vốn ngân hàng',
      value: 'LOAN',
      url: '/icons/modules/manager-loan.svg',
    },
    {
      name: 'Quản lý đăng ký đất đai',
      value: 'REGISTER',
      url: '/icons/modules/manager-register.svg',
    },
    {
      name: 'Quản lý yêu cầu mua tài sản phát mãi',
      value: 'FORECLOSURE_REQUEST',
      url: '/icons/modules/manager-foreclosure.svg',
    },
  ];
  const [isShowing, setShowing] = useState(false);
  const [privateOptions, setPrivateOptions] = useState(modules);
  const outerForm = useFormContext();
  const selectedModules = useWatch({
    control: outerForm.control,
    name: 'modules',
  });
  const innerForm = useForm({
    defaultValues: {
      search: undefined,
      modules: [],
    },
  });
  const { register, control, setValue } = innerForm;
  const searchWatch = useWatch({ control: control, name: 'search' });
  const modulesWatch = useWatch({ control: control, name: 'modules' });
  const isCheckAll = isEqual(size(modulesWatch), size(modules));
  const isNoCheck = isEqual(size(modulesWatch), 0);
  const handleOpen = () => {
    if (isShowing) return;
    setShowing(true);
  };
  const handleClose = () => {
    if (!isShowing) return;
    setShowing(false);
  };
  const handleClearSearch = () => {
    innerForm.setValue('search', undefined);
  };
  const handleClickCheckAll = () => {
    isCheckAll
      ? setValue('modules', [])
      : setValue(
          'modules',
          map(modules, module => module.value)
        );
  };
  const handleCheck = value => {
    includes(modulesWatch, value)
      ? setValue(
          'modules',
          filter(modulesWatch, module => !isEqual(module, value))
        )
      : setValue('modules', [...modulesWatch, value]);
  };

  const handleSubmit = data => {
    const { modules } = data;
    outerForm.setValue('modules', modules);
    handleClose();
  };
  const handleClickOuterModules = value => {
    outerForm.setValue(
      'modules',
      filter(selectedModules, module => !isEqual(module, value))
    );
  };

  useEffect(() => {
    setPrivateOptions(
      filter(modules, module =>
        includes(
          nonAccentVietnamese(module.name),
          nonAccentVietnamese(searchWatch)
        )
      )
    );
  }, [searchWatch]);

  useEffect(() => {
    isShowing && setValue('modules', selectedModules ?? []);
  }, [isShowing]);

  useEffect(() => {
    if (isEmpty(selectedModules)) {
      outerForm.setError('modules', {
        type: 'manual',
        message: 'Vui lòng chọn chức năng',
      });
    } else outerForm.clearErrors('modules');
  }, [selectedModules]);

  return (
    <form {...innerForm} className="w-full">
      <div className="relative">
        <div className="flex" onClick={handleOpen}>
          <Label wrapperClass="w-230px" label={'Chức năng'} isRequired={true} />
          <ClickAwayListener onClickAway={handleClose}>
            <div className="relative flex-1 ml-2">
              <div className="flex flex-col">
                <div
                  className="flex items-center"
                  style={{
                    border: '1px solid',
                    borderColor: outerForm.formState.errors.modules?.message
                      ? '#f87171'
                      : isShowing
                      ? '#2459AD'
                      : '#dedede',
                    borderRadius: 6,
                    overflow: 'hidden',
                    minWidth: 212,
                  }}
                >
                  <input
                    {...register('search')}
                    autoComplete="off"
                    type="text"
                    placeholder="Tìm kiếm chức năng"
                    className="w-full h-38px flex-1 text-14 px-2 border-none focus:outline-none"
                    onFocus={handleOpen}
                  />
                  {!isEmpty(searchWatch) && (
                    <img
                      alt="icon"
                      src="/svg/closeIcon.svg"
                      className="mr-2 h-25px w-25px cursor-pointer"
                      onClick={handleClearSearch}
                    />
                  )}
                  <div
                    className="cursor-pointer flex items-center rotate-180 select-none"
                    onClick={handleClose}
                  >
                    <div className="h-25px w-1px bg-gray-300" />
                    <img alt="icon" src="/svg/arrowDown.svg" className="mx-4" />
                  </div>
                </div>
                {outerForm.formState.errors.modules?.message && (
                  <span className="text-14 text-red-400 mt-2">
                    {outerForm.formState.errors.modules?.message}
                  </span>
                )}
              </div>
              {isShowing && (
                <div
                  className="absolute right-0 left-0 py-4 bg-white"
                  style={{
                    top: 42,
                    borderRadius: 8,
                    boxShadow: '0px 3px 8px 0px rgba(132, 132, 134, 0.24)',
                    zIndex: 999,
                  }}
                >
                  <div
                    style={{
                      maxHeight: 'calc(100vh - 600px)',
                      overflowY: 'auto',
                      cursor: 'pointer',
                    }}
                  >
                    {!isEmpty(privateOptions) ? (
                      <div
                        className="flex items-center px-3 space-x-3"
                        onClick={handleClickCheckAll}
                      >
                        <img
                          src={
                            isNoCheck
                              ? '/svg/empty-check-table-icon.svg'
                              : isCheckAll
                              ? '/svg/checked-table-icon.svg'
                              : '/svg/ic_partial_check.svg'
                          }
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <div
                          className="font-text w-full flex items-center space-x-2 text-black text-left py-4 text-14 text-black-main"
                          style={{
                            borderBottom: '1px solid #dedede',
                          }}
                        >
                          <span style={{ fontSize: 12 }}>Chọn tất cả</span>
                        </div>
                      </div>
                    ) : (
                      <span
                        className="italic px-4"
                        style={{ fontSize: 12, color: '#80888F' }}
                      >
                        Không tìm thấy
                      </span>
                    )}
                    {map(privateOptions, (module, moduleIndex) => (
                      <div
                        key={moduleIndex}
                        className="flex items-center px-3 space-x-3 cursor-pointer"
                        onClick={() => handleCheck(module.value)}
                      >
                        <img
                          src={
                            includes(modulesWatch, module.value)
                              ? '/svg/checked-table-icon.svg'
                              : '/svg/empty-check-table-icon.svg'
                          }
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <div
                          className="font-text w-full flex items-center space-x-2 text-black text-left py-4 text-14 text-black-main"
                          style={{
                            borderBottom: '1px solid #dedede',
                          }}
                        >
                          <img
                            src={module.url}
                            alt="icon"
                            className="w-10 h-10"
                          />
                          <span style={{ fontSize: 12 }}>{module.name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="flex justify-end space-x-4 px-4 pt-4 mt-4"
                    style={{
                      borderTop: '1px solid #dedede',
                    }}
                  >
                    <MyButton
                      wrapperClass="w-100px !h-38px"
                      onPress={handleClose}
                      label="Huỷ"
                      type="gray"
                    />
                    <MyButton
                      wrapperClass="w-100px !h-38px"
                      onPress={innerForm.handleSubmit(handleSubmit)}
                      label="Xác nhận"
                      type="blue"
                    />
                  </div>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
        <ul className="flex flex-wrap">
          {map(modules, (module, moduleIndex) =>
            includes(selectedModules, module.value) ? (
              <li
                key={moduleIndex}
                className="p-2 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4"
              >
                <div className="group relative hover:bg-blue-50 min-h-130px h-full flex py-3 flex-col items-center justify-center space-y-4 rounded-md select-none">
                  <img src={module.url} alt="icon" className="w-60px h-60px" />
                  <span
                    className="text-center"
                    style={{ maxWidth: '85%', fontSize: 12 }}
                  >
                    {module.name}
                  </span>
                  <div
                    className="absolute rounded-md cursor-pointer opacity-0 group-hover:opacity-100"
                    style={{
                      margin: 0,
                      padding: 5,
                      top: 8,
                      right: 8,
                      background: '#22313E40',
                    }}
                    onClick={() => handleClickOuterModules(module.value)}
                  >
                    <img
                      src="/svg/close-white.svg"
                      alt="icon-close"
                      className="w-10px h-10px"
                    />
                  </div>
                </div>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </form>
  );
}
