import MultiCheckDropdownComponent from '@components/filterDropdownComponentGroup/multiCheckDropdownComponent';
import styles from '@components/table/table.module.scss';
import MyDropdown from '@components/myDropdown';
import React from 'react';

type Props = {
  selections?: Array,
  value?: Array,
  setValue?: Function,
  title?: string,
  wrapperStyle?: string,
  onDelete?: Function,
  container?: any,
};
export default function DropdownMultiSelectionOnMap({
  value,
  setValue,
  selections,
  title,
  wrapperStyle,
  onDelete,
  container,
}: Props) {
  return (
    <MyDropdown
      zIndex={10000}
      container={container}
      wrapperClass={`w-full ${wrapperStyle}`}
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={MultiCheckDropdownComponent}
      dropdownContext={{
        selections,
        value,
        setValue,
      }}
    >
      <div
        className={`text-13 font-medium text-blue-main truncate ${styles.textHead}`}
        style={{ maxWidth: 160 }}
      >
        {value?.length === 11
          ? title
          : selections
              .filter(e => value?.includes(e.key))
              .map(a => a.value)
              .join(', ')}
      </div>
      <div className="flex flex-row items-center space-x-2">
        <div onClick={onDelete} className="w-20px h-20px cursor-pointer">
          <img
            className="w-20px h-20px ml-1"
            alt="close"
            src="/svg/ico-close.svg"
          />
        </div>
        <div className="w-1px h-24px bg-gray-300" />
        <img
          src="/svg/arrow-blue-filter.svg"
          alt="arrow"
          className="h-10px w-12px"
        />
      </div>
    </MyDropdown>
  );
}
