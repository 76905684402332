import { useSetState } from 'react-use';
import { useCallback } from 'react';
// import { HoChiMinhId } from '../../../constant';

export const blankStateFilterProduct = {
  createdAtFrom: '',
  createdAtTo: '',
  updatedAtFrom: '',
  updatedAtTo: '',
  realEstateId: '',
  realEstateType: ['townhouse'],
  isForSell: 'ForSale',

  hasAlley: [true, false],

  direction: [],

  postStatus: [],
  street: '',
  streetName: '',
  ward: '',
  wardName: '',
  district: '',
  districtName: '',
  province: '',
  provinceName: '',
  priceFrom: 0,
  priceTo: 101,
  priceKey: '',

  widthFrom: 0,
  widthTo: 101,
  widthKey: '',

  lengthFrom: 0,
  lengthTo: 501,
  lengthKey: '',

  rearFrom: 0,
  rearTo: 101,
  rearKey: '',

  floorCountFrom: 0,
  floorCountTo: 169,
  floorCountKey: '',

  bedroomCountFrom: 0,
  bedroomCountTo: 169,
  bedroomCountKey: '',

  toiletCountFrom: 0,
  toiletCountTo: 169,
  toiletCountKey: '',

  areaFrom: 0,
  areaTo: 20001,
  areaKey: '',

  constructionAreaFrom: 0,
  constructionAreaTo: 20001,
  constructionAreaKey: '',

  alleyWidthFrom: 0,
  alleyWidthTo: 101,
  alleyWidthKey: '',

  frontHomeAlleyWidthFrom: 0,
  frontHomeAlleyWidthTo: 101,
  frontHomeAlleyWidthKey: '',

  contactName: '',
  contactPhone: '',

  ownerName: '',
  ownerPhone: '',

  commission: '',

  approvedBy: '',
  rejectedBy: '',
  creator: '',
  isExpired12hours: false,
  isAbandoned: false,
  sort: '',
};

export const useStateFilterProduct = () => {
  const [state, setState] = useSetState({ ...blankStateFilterProduct });
  const setStateAt = useCallback(
    field => {
      return value => setState({ [field]: value });
    },
    [setState]
  );
  return { state, setState, setStateAt };
};
