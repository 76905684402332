import 'react-alice-carousel/lib/alice-carousel.css';
import React, { useRef, useCallback, useState, useEffect } from 'react';
import styles from './carousel.module.scss';
import MediaViewer from '@components/mediaViewer';
import { FlatList, Image, View } from 'react-native-web';
import { getMediaUrlByType } from '@utils/helper';
import { getFileViewerType } from '../viewFileModal/viewFileModal';
import FileViewer from 'react-file-viewer';

type Props = {
  listMedia?: Array,
  activeIndex?: number,
};

export default function ModalViewMedia({ listMedia = [], activeIndex }: Props) {
  const [activeMedia, setActiveMedia] = useState(activeIndex || 0),
    flatListRef = useRef(null);
  const goBack = useCallback(() => {
    if (activeMedia === 0) {
      setActiveMedia(listMedia?.length - 1);
    } else {
      setActiveMedia(activeMedia - 1);
    }
  }, [activeMedia, setActiveMedia, listMedia]);

  const onNext = useCallback(() => {
    if (activeMedia === listMedia?.length - 1) {
      setActiveMedia(0);
    } else {
      setActiveMedia(activeMedia + 1);
    }
  }, [activeMedia, listMedia, setActiveMedia]);
  useEffect(() => {
    if (listMedia.length) {
      flatListRef.current?.scrollToIndex({ index: activeMedia });
    }
  }, [activeMedia, listMedia]);
  return (
    <div
      style={{ zIndex: 0, width: 1000, height: 500 }}
      className="w-full bg-black rounded-lg flex flex-col items-center justify-center overflow-hidden"
    >
      <View style={{ width: '100%', height: '75%' }}>
        {listMedia?.[activeMedia]?.type === 'image' && (
          <MediaViewer
            type={'image'}
            imageUrl={listMedia?.[activeMedia]?.originalUrl}
          />
        )}
        {listMedia?.[activeMedia]?.type === 'image360' && (
          <MediaViewer
            type={'image360'}
            imageUrl={listMedia?.[activeMedia]?.originalUrl}
          />
        )}
        {listMedia?.[activeMedia]?.type === 'youtube' && (
          <MediaViewer
            type={'youtube'}
            videoUrl={listMedia?.[activeMedia]?.youtubeMetadata?.videoId}
          />
        )}
        {listMedia?.[activeMedia]?.type === 'video' && (
          <MediaViewer
            type={'video'}
            videoUrl={listMedia?.[activeMedia]?.originalUrl}
          />
        )}
        {['doc', 'sheet', 'pdf']?.includes(listMedia?.[activeMedia]?.type) && (
          <FileViewer
            fileType={getFileViewerType(listMedia?.[activeMedia]?.type)}
            filePath={listMedia?.[activeMedia]?.originalUrl}
          />
        )}
      </View>
      <View style={{ marginTop: 16, paddingHorizontal: 30 }}>
        <FlatList
          onScrollToIndexFailed={() => {
            flatListRef.current?.scrollToIndex({ index: 0 });
          }}
          ref={flatListRef}
          keyExtractor={(item, index) => index.toString()}
          style={{ flex: 1, scrollSnapType: 'x mandatory' }}
          showsHorizontalScrollIndicator={false}
          horizontal
          data={listMedia}
          renderItem={({ item, index }) => {
            const isActive = activeMedia === index;
            return (
              <View
                style={{
                  width: 143,
                  marginRight: 0,
                  height: 100,
                  paddingVertical: 2,
                  paddingHorizontal: 1,
                  scrollSnapAlign: 'center',
                }}
              >
                <View
                  onClick={() => {
                    setActiveMedia(index);
                  }}
                  style={[
                    {
                      borderRadius: 8,
                      flex: 1,
                      borderWidth: 2,
                      borderColor: '#2459ad',
                    },
                    isActive && {
                      borderWidth: 2,
                      borderColor: 'red',
                    },
                  ]}
                >
                  {item?.type === 'youtube' ? (
                    <Image
                      resizeMode="cover"
                      source={{ uri: item?.youtubeMetadata?.thumbnailUrl }}
                      style={{ flex: 1, borderRadius: 4 }}
                    />
                  ) : (
                    <>
                      {item?.type === 'video' ? (
                        <Image
                          resizeMode="cover"
                          source={{ uri: getMediaUrlByType(item, 'medium') }}
                          style={{ flex: 1, borderRadius: 4 }}
                        />
                      ) : (
                        <Image
                          resizeMode="cover"
                          source={{ uri: getMediaUrlByType(item, 'medium') }}
                          style={{ flex: 1, borderRadius: 4 }}
                        />
                      )}
                    </>
                  )}
                </View>
              </View>
            );
          }}
        />
      </View>
      <button
        aria-label="Prev Slide"
        onClick={goBack}
        className={`${styles.navigation} ${styles.prev}`}
      >
        <img src="/svg/arrow_nav.svg" alt="icon" className="w-full h-full" />
      </button>
      <button
        aria-label="Next Slide"
        onClick={onNext}
        className={`${styles.navigation} ${styles.next}`}
      >
        <img
          src="/svg/arrow_nav.svg"
          alt="icon"
          className={`w-full h-full ${styles.iconNext}`}
        />
      </button>
    </div>
  );
}
