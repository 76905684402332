import styles from './visibleFieldOnTableModal.module.scss';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native-web';
import { useFieldConfigs } from './config';
import MyButton from '@components/button';
import { useNeedApprovalContext } from '../../scenes/home/manageRealEstate/needApproval/context';
import { useList } from 'react-use';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import {
  ApprovedLocalStorageKey,
  DeniedLocalStorageKey,
  NeedApprovalLocalStorageKey,
} from '../../constant';
type Props = {
  onClose?: Function,
};
export default function VisibleFieldOnTableModal({ onClose: close }: Props) {
  const { listField, setListField, tab } = useNeedApprovalContext();
  const configs = useFieldConfigs(tab);
  const [tempListField, setTempListField] = useList(listField);
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setTempListField.set(tempListField.filter(e => e !== key));
      } else {
        setTempListField.set([...(tempListField || []), key]);
      }
    },
    [setTempListField, tempListField]
  );
  const localStorageKey = tab.includes('needApproval')
    ? NeedApprovalLocalStorageKey
    : tab?.includes('approved')
    ? ApprovedLocalStorageKey
    : DeniedLocalStorageKey;
  useEffect(() => {
    setTempListField.set([...(listField || [])]);
  }, [listField]);
  return (
    <View className={styles.modalContainer}>
      <div className="w-full text-base font-medium text-center text-blue-main mt-5">
        Chỉnh sửa hiển thị
      </div>
      <div className="mx-7 mb-7 mt-5 p-4 rounded-lg border border-blue-main">
        <div className="grid grid-flow-row grid-cols-3 grid-rows-3 gap-4">
          {configs?.map(item => {
            const isChecked = tempListField.includes(item.key);
            if (!item.hide) {
              return (
                <CheckboxUsingTable
                  titleStyle={{
                    color:
                      item.key === 'realEstateId' ? '#2459ad50' : undefined,
                  }}
                  onPress={() => handleCheck(item.key, isChecked)}
                  isChecked={isChecked}
                  disabled={item.key === 'realEstateId'}
                  key={item.key}
                  title={item.value}
                  titleClass={
                    isChecked ? 'text-blue-main font-medium' : undefined
                  }
                />
              );
            } else {
              return;
            }
          })}
        </div>
      </div>
      <div className="flex flex-row items-center justify-end px-6 py-2.5 border-t border-gray-300 space-x-2">
        <div className="w-147px">
          <MyButton onPress={close} label="Trở về" type="gray" />
        </div>
        <div className="w-147px">
          <MyButton
            onPress={() => {
              setListField.set([...(tempListField || [])]);
              localStorage.setItem(
                localStorageKey,
                JSON.stringify([...(tempListField || [])])
              );
              close();
            }}
            label="Xác nhận"
            type="blue"
          />
        </div>
      </div>
    </View>
  );
}
