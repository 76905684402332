import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export const managePost = {
  title: 'Quản lý bán hàng và vùng',
  items: [
    {
      title: 'Quản lý tin đăng nhà phố',
      items: [
        {
          title: 'Tin đăng nhà phố đã được duyệt',
          pathname: '/manageRealEstate/approved',
        },
        {
          title: 'Tin đăng nhà phố cần duyệt',
          pathname: '/manageRealEstate/needApproval',
        },
        {
          title: 'Tin đăng nhà phố bị từ chối',
          pathname: '/manageRealEstate/denied',
        },
        {
          title: 'Góp ý định giá',
          pathname: '/manageRealEstate/feedbackValuation',
        },
      ],
    },
  ],
};
export const manageUser = {
  title: 'Quản lý khách hàng',
  items: [
    {
      items: [
        {
          title: 'Quản lý khách hàng B2B',
          pathname: '/manageUser/b2b',
        },
        {
          title: 'Quản lý khách hàng B2C',
          pathname: '/manageUser/b2c',
        },
      ],
    },
  ],
};
export const manageOrganization = {
  title: 'Quản lý bộ máy tổ chức',
  items: [
    {
      items: [
        // {
        //   title: 'Sơ đồ phòng ban',
        //   pathname: '/manageOrganization/organization-chart',
        // },
        {
          title: 'Danh sách nhóm quyền',
          pathname: '/manageOrganization/permission-groups',
        },
      ],
    },
  ],
};
export const manageStaff = {
  title: 'Quản lý nhân viên',
  items: [
    {
      items: [
        {
          permissionId: PermissionIds.ViewOrganizationChart,
          title: 'Danh sách phòng ban',
          pathname: '#',
        },
        {
          permissionId: PermissionIds.ViewEmployeeList,
          title: 'Nhân viên công ty',
          pathname: '/employee/company',
        },
        {
          permissionId: PermissionIds.ViewMyEmployeeList,
          title: 'Nhân viên của tôi',
          pathname: '#',
        },
        {
          permissionId: PermissionIds.ViewNeedToApproveEmployeeList,
          title: 'Nhân viên tôi cần duyệt',
          pathname: '#',
        },
        {
          permissionId: PermissionIds.AddEmployee,
          title: 'Nhân viên tôi đã tạo',
          pathname: '#',
        },
        {
          permissionId: PermissionIds.AddEmployee,
          title: 'Tạo nhân viên',
          pathname: '/employee/create',
        },
      ],
    },
  ],
};
export const managePayment = {
  title: 'Quản lý thanh toán',
  items: [
    {
      items: [
        {
          title: 'Giao dịch nạp tiền',
          pathname: '#',
        },
        {
          title: 'Giao dịch nạp tiền cần duyệt',
          pathname: '#',
        },
        {
          title: 'Giao dịch tin đăng',
          pathname: '#',
        },
        {
          title: 'Thống kê hoa hồng',
          pathname: '#',
        },
      ],
    },
  ],
};
export const realEstate = {
  title: 'Quản lý bất động sản',
  items: [
    {
      items: [
        {
          permissionId: PermissionIds.ViewProductList,
          title: 'Kho sản phẩm',
          pathname: '/manageProduct/product',
        },
        {
          permissionId: PermissionIds.AddProduct,
          title: 'Sản phẩm của tôi',
          pathname: '/manageProduct/my-product',
        },
        {
          permissionId: PermissionIds.ViewPublishedProductList,
          title: 'Sản phẩm đã đăng lên sàn',
          pathname: '/manageProduct/product-posted',
        },
        {
          permissionId: PermissionIds.ViewAwaitApprovingProductList,
          title: 'Sản phẩm cần duyệt',
          pathname: '/manageProduct/product/waiting-for-approval',
        },
        {
          permissionId: PermissionIds.ViewHiddenProductList,
          title: 'Sản phẩm đã ẩn',
          pathname: '/manageProduct/product/hidden',
        },
        {
          permissionId: PermissionIds.ViewDeletedProductList,
          title: 'Sản phẩm đã xóa',
          pathname: '/manageProduct/product/deleted',
        },
        {
          title: 'Sản phẩm đã từ chối',
          pathname: '/manageProduct/product/rejected',
        },
      ],
    },
  ],
};
export const productPosted = 'product-posted',
  productPremium = 'product-premium',
  productAuthorize = 'product-authorize';
export const realEstatePosted = {
  title: 'Quản lý bất động sản',
  items: [
    {
      items: [
        {
          permissionId: PermissionIds.ViewPublishedProductList,
          title: 'Tin rao đã đăng lên sàn',
          key: productPosted,
        },
        {
          permissionId: PermissionIds.ViewPublishedProductList,
          title: 'Tin rao chính chủ đã đăng lên sàn',
          key: productPremium,
        },
        {
          permissionId: PermissionIds.ViewPublishedProductList,
          title: 'Tin rao ký gửi đã đăng lên sàn',
          key: productAuthorize,
        },
      ],
    },
  ],
};
export const manageProject = {
  title: 'Quản lý dự án',
  items: [
    {
      items: [
        {
          title: 'Dự án chủ đầu tư',
          pathname: '/project/company',
        },
        {
          title: 'Dự án được chia sẻ',
          pathname: '#',
        },
        {
          title: 'Dự án đã chia sẻ',
          pathname: '#',
        },
        {
          title: 'Các dự án của đầu tư khác',
          pathname: '#',
        },
        {
          title: 'Dự án đã đăng lên sàn',
          pathname: '#',
        },
        {
          title: 'Dự án đã xóa',
          pathname: '#',
        },
        {
          title: 'Dự án đươc chia sẽ bị hủy liên kết',
          pathname: '#',
        },
        {
          title: 'Quản lý đơn vị phân phối',
          pathname: '#',
        },
      ],
    },
  ],
};
