import PostInfo from './postInfo';
import React from 'react';
import PostPeriod from './postPeriod';
import Note from '@components/note';
import OwnerInformation from '@components/ownerInformation';
import { useGetPublishRealEstateType } from '@utils/hooks/createB2BRealEstate/useGetPublishRealEstateType';

type Props = {
  isEdit?: Boolean,
};

export default function GeneralInfo({ isEdit }: Props) {
  const { premium } = useGetPublishRealEstateType();
  return (
    <div className="grid grid-cols-5 gap-4">
      <div className="col-span-3 grid grid-row auto-rows-min gap-4">
        <PostInfo />
        <Note
          fieldName="privateNote"
          isShowTips={true}
          headerText="Ghi chú cá nhân"
        />
      </div>
      <div className="col-span-2 grid grid-row auto-rows-min gap-4">
        <OwnerInformation
          isDisableInput={false}
          headerText="Thông tin liên lạc của bạn"
          totalShowFields="3"
          hiddenFields={['career']}
          namePlaceholder="của bạn"
        />
        {!premium && !isEdit && <PostPeriod />}
      </div>
    </div>
  );
}
