import React from 'react';
import MyButton from '@components/button';
import { useFormContext } from 'react-hook-form';

type Props = {
  doLogin?: any,
  loading?: boolean,
  error?: Object,
};
export default function SubmitButton({ doLogin, loading, error }: Props) {
  const { handleSubmit } = useFormContext();
  return (
    <div className="w-full space-y-5">
      <MyButton
        onPress={handleSubmit(doLogin)}
        type="blue"
        label={loading ? 'Đang đăng nhập' : 'Đăng nhập'}
      />
      {error && <div className="text-12 text-red-500">{error?.message}</div>}
    </div>
  );
}
