import moment from 'moment';
import MyDropdown from '@components/myDropdown';
import React, { useCallback, useState } from 'react';
import DatePicker from '@components/datePicker';
import MyButton from '@components/button';

type Props = {
  context?: Object,
  close?: Function,
};
export default function CalendarFromToDropdown(props: Props) {
  const {
    startDay,
    endDay,
    setValue,
    minDate,
    maxDate,
    disabledAddTo,
    disabledSelectFrom,
    isDuplicateTo = true,
    isPublic = false,
    disableMaxDateFrom = false,
  } = props.context;
  const [tempStartDay, setTempStartDay] = useState(startDay);
  const [tempEndDay, setTempEndDay] = useState(endDay);
  const onConfirm = useCallback(() => {
    setValue({ startDay: tempStartDay, endDay: tempEndDay });
    props.close();
  }, [setValue, props, tempStartDay, tempEndDay]);
  return (
    <div className="w-470px rounded-lg border border-blue-main p-6 bg-white">
      <div className="flex flex-row items-center flex-1 justify-between">
        <div className="flex-1 text-blue-main text-13">Từ ngày</div>
        <div className="flex-1">
          <MyDropdown
            disabled={disabledSelectFrom}
            DropdownComponent={DatePicker}
            dropdownContext={{
              value: tempStartDay ? moment.unix(tempStartDay) : moment(),
              setValue: startDayValue => {
                setTempStartDay(moment(startDayValue).unix());
                !disabledAddTo &&
                  setTempEndDay(moment(startDayValue.add(1, 'days')).unix());
              },
              maxDate:
                maxDate && isPublic
                  ? maxDate
                  : maxDate && tempEndDay
                  ? moment.unix(tempEndDay)
                  : !disableMaxDateFrom
                  ? moment()
                  : undefined,
              minDate: minDate && minDate,
            }}
          >
            <div className="w-210px flex flex-row items-center justify-between border border-blue-main rounded-lg flex-1 h-40px px-2">
              <div className="text-13 text-blue-main">
                {tempStartDay
                  ? `${moment.unix(tempStartDay).format('DD/MM/YYYY')}`
                  : 'Chọn giá trị'}
              </div>
              <img alt="ico" src="/svg/arrowDown.svg" />
            </div>
          </MyDropdown>
        </div>
      </div>
      <div className="flex flex-row items-center flex-1 justify-between mt-2">
        <div className="flex-1 text-blue-main text-13">Đến ngày</div>
        <div className="flex-1">
          <MyDropdown
            DropdownComponent={DatePicker}
            dropdownContext={{
              value: tempEndDay ? moment.unix(tempEndDay) : moment(),
              setValue: endDayValue => {
                setTempEndDay(moment(endDayValue).unix());
              },
              minDate: !isDuplicateTo
                ? moment.unix(tempStartDay).add(1, 'days')
                : tempStartDay
                ? moment.unix(tempStartDay)
                : moment(),
            }}
          >
            <div className="w-210px flex flex-row items-center justify-between border border-blue-main rounded-lg flex-1 h-40px px-2">
              <div className="text-13 text-blue-main">
                {tempEndDay
                  ? ` ${moment.unix(tempEndDay).format('DD/MM/YYYY')}`
                  : 'Chọn giá trị'}
              </div>
              <img alt="ico" src="/svg/arrowDown.svg" />
            </div>
          </MyDropdown>
        </div>
      </div>
      <div className="flex-row flex items-center flex-1 justify-between mt-8">
        <div className="flex flex-row flex-1 items-center">
          <img
            alt="calendar"
            src="/svg/calendar-icon.svg"
            className="mr-1 w-6 h-6"
          />
          <div className="text-base font-medium">Thời gian đã chọn:</div>
        </div>
        <div className="flex-1 flex flex-row items-center space-x-2">
          <div className="flex flex-row items-center space-x-1">
            <div className="text-13">Từ:</div>
            <div className="text-15 text-blue-main font-medium">
              {tempStartDay
                ? `${moment.unix(tempStartDay).format('DD/MM/YYYY')}`
                : '...'}
            </div>
          </div>
          <div className="flex flex-row items-center space-x-1">
            <div className="text-13">đến:</div>
            <div className="text-15 text-blue-main font-medium">
              {tempEndDay
                ? `${moment.unix(tempEndDay).format('DD/MM/YYYY')}`
                : '...'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-end  mt-10">
        <div className="flex flex-1 flex-row items-center space-x-2">
          <div onClick={props.close} className="w-114px">
            <MyButton type="gray" label="Trở về" />
          </div>
          <div onClick={onConfirm} className="w-114px">
            <MyButton type="blue" label="Xác nhận" />
          </div>
        </div>
      </div>
    </div>
  );
}
