import { HoChiMinhId } from '../../../constant';

export const blankState = {
  isPersonal: false,
  username: '',
  password: '',
  confirmPassword: '',
  logo: '',
  avatar: '',
  companyName: '',
  taxIdOrIdNumber: '',
  companyProvince: HoChiMinhId,
  companyDistrict: '',
  companyWard: '',
  companyStreet: '',
  companyHouseNumber: '',
  fullName: '',
  province: HoChiMinhId,
  district: '',
  ward: '',
  street: '',
  gender: 'male',
  dateOfBirth: '',
  idImages: [],
  attachments: [],
  houseNumber: '',
  idNumber: '',
  phone: '',
  secondPhone: '',
  email: '',
};

export const genders = [
  {
    key: 'male',
    value: 'Nam',
  },
  {
    key: 'female',
    value: 'Nữ',
  },
];
