export const defaultLat = 10.7715959,
  defaultLong = 106.704092;

export const colorLine = '#22313E';

export const MODE = {
  MEASURING: 'MEASURING',
  DRAWING: 'DRAWING',
  NONE: null,
};

export const MAP_MODE = {
  NORMAL: 'normal',
  INTERNAL: 'internal',
  AREA: 'area',
};

export const typeOfDemand = {
  rent: 'ForRent',
  sale: 'ForSale',
};
