import styles from './changeEmployeeLoginInfoModal.module.scss';
import { useForm, FormProvider } from 'react-hook-form';
import React from 'react';
import Content from './components/content';

type Props = {
  employee: Object,
  onClose?: Function,
  setShowModalSuccess?: Function,
};

export default function ChangeEmployeeLoginInfoModal({
  employee,
  onClose,
  setShowModalSuccess,
}: Props) {
  const form = useForm({
    defaultValues: {
      employeeId: employee?.id,
      username: employee?.username,
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });
  return (
    <FormProvider {...form}>
      <div className={styles.changeEmployeeLoginInfoModal}>
        <div className="w-full text-blue-main font-medium text-left">
          Đổi thông tin đăng nhập của nhân viên
        </div>
        <Content setShowModalSuccess={setShowModalSuccess} onClose={onClose} />
      </div>
    </FormProvider>
  );
}
