import React, { useCallback } from 'react';
import Demand from '@components/demand';
import Section from '@components/section';
import Label from '@components/label';
import PhoneInput from '@components/phoneInput';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import AddressV2 from '@components/addressV2';

const countFields = data => {
  let count = 0;
  data?.forEach(item => {
    if (item?.length > 0) {
      count += 1;
    }
  });
  return count;
};

export default function CheckProduct() {
  const location = useLocation();
  const isStep1 = location.pathname.includes('check-product');
  const { watch } = useFormContext();
  const phone = watch('contactPhones');
  const total = watch('total');
  const canContinue = watch('canContinue');
  const province = watch('province');
  const district = watch('district');
  const ward = watch('ward');
  const street = watch('street');
  const houseNumber = watch('houseNumber');
  const data = [province, district, ward, street, houseNumber];
  const openTabListDuplicateProduct = useCallback(() => {
    window.open(
      `/manageProduct/product?province=${province}&district=${district}&ward=${ward}&street=${street}&houseNumber=${houseNumber}&isDuplicate=${true}`
    );
  }, [province, district, ward, street, houseNumber]);
  return (
    <div className="form-center h-full space-y-4">
      <Demand />
      <Section wrapperClassName="space-y-7">
        <div className="text-base font-medium text-blue-main">
          Thông tin chủ nhà ({phone[0]?.phone?.length > 0 ? 1 : 0}/1)
        </div>
        <div className="flex w-full space-x-2.5">
          <Label wrapperClass="w-152px" label="Số điện thoại" isRequired />
          <div className="flex-1">
            <PhoneInput isStep1={isStep1} />
          </div>
        </div>
      </Section>

      <Section wrapperClassName="space-y-7">
        <div className="text-base font-medium text-blue-main">
          Chi tiết bất động sản ({countFields(data)}/5)
        </div>
        <AddressV2 />
      </Section>
      {total > 0 && canContinue && (
        <div className="flex items-center">
          <div className="text-sm text-black-main">
            Có {total} sản phẩm trùng với thông tin bạn đã nhập,{' '}
          </div>
          <b
            onClick={openTabListDuplicateProduct}
            className="text-sm underline cursor-pointer text-blue-main font-medium"
          >
            xem chi tiết sản phẩm trùng
          </b>
        </div>
      )}
      {total > 0 && !canContinue && (
        <div style={{ color: '#D44950' }} className="text-sm">
          Sản phẩm bạn muốn thêm bị trùng số điện thoại và địa chỉ.
        </div>
      )}
    </div>
  );
}
