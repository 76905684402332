import MapComponent from '@components/map';

type RealEstateLocationOnMapProps = {
  lat?: number,
  long?: number,
};
export default function RealEstateLocationOnMap({
  lat,
  long,
}: RealEstateLocationOnMapProps) {
  return (
    <div
      style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
      className="w-full bg-white rounded-lg p-3"
    >
      <div className="text-base text-blue-main font-medium">
        Bản đồ vị trí bất động sản
      </div>
      <div className="flex-1 h-354px mt-7 z-1">
        <MapComponent lat={lat} long={long} />
      </div>
    </div>
  );
}
