import { useMutation } from '@apollo/client';
import { removeAuth } from '@utils/helper';
import { useGlobalContext } from '../../../context';
import { LOGOUT } from '../../graphql';
export default function useLogOut() {
  const { setIsLogin } = useGlobalContext();

  const [logout] = useMutation(LOGOUT, {
    onCompleted: async () => {
      setIsLogin(false);
      localStorage.clear();
      removeAuth();
      window.location.href = `${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`;
    },
  });
  return { logout };
}
