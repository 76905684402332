import CommentRender from '@components/comment/component/commentRender';
import useToggle from 'react-use/lib/useToggle';

type Props = {
  comment?: string,
  childrenComment?: Array,
  time?: string,
  owner?: Object,
  id?: string,
  newId?: string,
};
export default function ChildrenComment(props: Props) {
  const { comment, childrenComment, time, owner, id, newId } = props;
  const [isShowFullComment, setIsShowFullComment] = useToggle(false);
  return (
    <div className="flex flex-col">
      <CommentRender
        newId={newId}
        wrapperClass="first:mt-2"
        id={id}
        comment={comment}
        time={time}
        owner={owner}
      />
      <div className="pl-10 space-y-2 flex flex-col">
        {childrenComment?.map((item, index) => {
          if (index < 3 || isShowFullComment) {
            return (
              <CommentRender
                newId={newId}
                wrapperClass="first:mt-2"
                isHiddenReply
                key={`children${index}`}
                comment={item?.content}
                time={item?.createdAt}
                owner={item?.user}
              />
            );
          }
          return null;
        })}
        {childrenComment?.length > 3 && (
          <span
            onClick={setIsShowFullComment}
            className="cursor-pointer underline font-bold text-blue-main text-sm pl-12"
          >
            {!isShowFullComment
              ? `Xem thêm ${childrenComment?.length - 3} ghi chú`
              : 'Thu gọn'}
          </span>
        )}
      </div>
    </div>
  );
}
