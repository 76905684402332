import React from 'react';
import CustomerInfoInput from './customerInfoInput';

export default function LeftCustomerInfo() {
  return (
    <div className="flex flex-1 rounded-lg px-4 py-5 border border-gray-300 space-x-6">
      <CustomerInfoInput />
    </div>
  );
}
