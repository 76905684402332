import { convertFloatWithDot } from '@utils/helper';
import RealEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import moment from 'moment';

export const useConvertDataProductRenewHistory = item => {
  const fee = item?.renewDetails?.basicAmount + item?.renewDetails?.vat;
  const { postTypes } = RealEstateCommonConfigs;
  return [
    {
      key: 'transactionId',
      value: item?.orderId,
    },
    {
      key: 'postType',
      value: postTypes.find(
        e => e.key === item?.renewDetails?.postType.toString()
      )?.value,
    },
    {
      key: 'renewDate',
      value: () => {
        const parseIntStart = parseInt(item?.renewDetails?.startTime, 10);
        const parseIntEnd = parseInt(item?.renewDetails?.endTime, 10);
        const start = moment.unix(parseIntStart);
        const end = moment.unix(parseIntEnd);
        if (
          !moment.unix(parseIntStart).isValid() ||
          !moment.unix(parseIntEnd).isValid()
        ) {
          return '-';
        }
        return `${end.diff(start, 'days')} ngày (${moment
          .unix(parseIntStart)
          .format('hh:mm A, DD/MM/YYYY')} - ${moment
          .unix(parseIntEnd)
          .format('hh:mm A, DD/MM/YYYY')})`;
      },
    },
    {
      key: 'basicAmount',
      value: `${convertFloatWithDot(fee)} VNĐ`,
    },
    {
      key: 'date',
      value: moment(item?.date).format('hh:mm A, DD/MM/YYYY'),
    },
  ];
};
