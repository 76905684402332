import gql from 'graphql-tag';

export const REQUEST_CAPTCHA = gql`
  mutation requestCaptcha @api(name: common) {
    requestCaptcha {
      success
      msg
      payload {
        id
        image
      }
    }
  }
`;
