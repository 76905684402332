import MyButton from '@components/button';
import MyModalV2 from '@components/modalV2/myModalV2';
import RadioButton from '@components/radio';
import React, { useEffect, useState } from 'react';
import ListTypeModal from '../modal/ListTypeModal';
import { demandConfigs } from '../configs';
import { useList } from 'react-use';
import { RenderTag } from '@components/tag';
import {
  realEstateStatus,
  forRentRealEstateTypes,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import ListStatusModal from '../modal/ListStatusModal';
import ListProvinceModal from '../modal/ListProvinceModal';
import MyInput from '@components/myInput';
import { DocTienBangChuV2 } from '@utils/helper';
import { useFormContext } from 'react-hook-form';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_FROM_PERMISSION } from '@utils/graphql';
type ViewProductProps = {
  isEdit?: boolean,
  selectedValue?: Object,
  errors: Object,
  setErrors: Function,
};
import { omit, isFinite } from 'lodash';

export default function ViewProduct({
  isEdit = false,
  selectedValue = {},
  errors = {},
  setErrors,
}: ViewProductProps) {
  const { watch, setValue } = useFormContext();
  const [demand, setDemand] = useState(
    isEdit ? selectedValue?.demandType : ['all']
  );
  const [types, setTypes] = useList(
    isEdit
      ? selectedValue?.realEstateType?.includes('all')
        ? ['all']
        : forRentRealEstateTypes.filter(p =>
            selectedValue?.realEstateType?.includes(p.key)
          )
      : ['all']
  );
  const [status, setStatus] = useList(
    isEdit
      ? selectedValue?.status?.includes('all')
        ? ['all']
        : realEstateStatus?.filter(p => selectedValue?.status?.includes(p.key))
      : ['all']
  );
  const [districts, setDistricts] = useList(() => {
    if (isEdit) {
      if (
        selectedValue?.region
          ?.filter(p => p.type === 'Province')
          ?.map(r => r.id)
          ?.includes('all')
      ) {
        return ['all'];
      } else {
        return selectedValue?.region;
      }
    }
    return ['all'];
  });
  const { data } = useQuery(GET_LIST_DISTRICT_FROM_PERMISSION, {
    variables: { item: districts },
    skip: districts?.includes('all') || districts?.length === 0,
  });
  const [priceRange, setPriceRange] = useState(
    isEdit
      ? selectedValue?.priceRange
      : {
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
        }
  );
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDistrictModal, setShowDistrictModal] = useState(false);
  const onRemoveItem = (originalList, setList, item) => {
    const newValues = [...originalList];
    const remainValues = newValues?.filter(
      p => ![item?.key, item?.id].includes(p.key || p.id)
    );
    setList.set(remainValues);
  };
  useEffect(() => {
    setValue(PermissionIds.ViewProductList, {
      ...watch(PermissionIds.ViewProductList),
      demandType: demand,
    });
  }, [demand]);
  useEffect(() => {
    setValue(PermissionIds.ViewProductList, {
      ...watch(PermissionIds.ViewProductList),
      realEstateType: types?.includes('all') ? ['all'] : types?.map(t => t.key),
    });
    if (!types?.length) {
      setErrors({ ...errors, types: 'Vui lòng chọn loại BĐS được xem' });
    } else {
      setErrors(omit(errors, ['types']));
    }
  }, [types]);
  useEffect(() => {
    setValue(PermissionIds.ViewProductList, {
      ...watch(PermissionIds.ViewProductList),
      status: status?.includes('all') ? ['all'] : status?.map(s => s.key),
    });
    if (!status?.length) {
      setErrors({
        ...errors,
        status: 'Vui lòng chọn trạng thái được xem',
      });
    } else {
      setErrors(omit(errors, ['status']));
    }
  }, [status]);
  useEffect(() => {
    if (
      !isFinite(parseFloat(priceRange?.from)) ||
      !isFinite(parseFloat(priceRange?.to))
    ) {
      setErrors({ ...errors, priceRange: 'Vui lòng nhập khoảng giá được xem' });
    } else if (parseFloat(priceRange?.from) > parseFloat(priceRange?.to)) {
      setErrors({ ...errors, priceRange: 'Vui lòng nhập khoảng giá hợp lệ' });
    } else {
      setErrors(omit(errors, ['priceRange']));
    }

    setValue(PermissionIds.ViewProductList, {
      ...watch(PermissionIds.ViewProductList),
      priceRange: {
        from: parseFloat(priceRange?.from),
        to: parseFloat(priceRange?.to),
      },
    });
  }, [priceRange]);
  useEffect(() => {
    if (!districts?.length) {
      setErrors({ ...errors, region: 'Vui lòng chọn khu vực được xem' });
    } else {
      setErrors(omit(errors, 'region'));
    }
    const newRegions = districts?.includes('all')
      ? [
          {
            type: 'Province',
            id: 'all',
          },
          {
            type: 'District',
            id: 'all',
          },
        ]
      : isEdit
      ? districts
      : districts?.map(d => ({ type: d.type, id: d.key || d.id }));
    setValue(PermissionIds.ViewProductList, {
      ...watch(PermissionIds.ViewProductList),
      region: newRegions,
    });
  }, [districts]);
  return (
    <div className="w-full h-full flex flex-col space-y-4 rounded-lg shadow-card p-4">
      <span className="font-medium text-blue-main text-16">
        Xem sản phẩm trong kho
      </span>
      <div className="flex flex-col space-y-4">
        <span className="font-medium text-black-main text-14">
          Loại nhu cầu được xem
        </span>
        {demandConfigs.map(config => (
          <RadioButton
            key={config.key?.toString()}
            titleClass={demand?.includes(config.key) ? 'font-medium' : 'none'}
            isChecked={demand?.includes(config.key?.toString())}
            title={config.value}
            wrapperClass="ml-4"
            onPress={() => {
              setDemand(config.key);
            }}
          />
        ))}

        <span className="font-medium text-black-main text-14">
          Loại BĐS được xem
        </span>
        <div className="space-y-4">
          <RadioButton
            titleClass={types?.includes('all') ? 'font-medium' : 'none'}
            isChecked={types?.includes('all')}
            title="Xem tất cả BĐS"
            wrapperClass="ml-4"
            onPress={() => {
              setTypes.set(['all']);
            }}
          />
          <RadioButton
            titleClass={!types?.includes('all') ? 'font-medium' : 'none'}
            isChecked={!types?.includes('all')}
            title="Chọn loại bất động sản"
            wrapperClass="ml-4"
            onPress={() => {
              !types?.includes('all') ? setTypes.set(types) : setTypes.set([]);
            }}
          />
          {!types?.includes('all') && (
            <div>
              <MyButton
                type="gray"
                label="Chọn loại BĐS"
                leftComponent={() => (
                  <img src="/svg/plus.svg" alt="Thêm icon" />
                )}
                onPress={() => setShowModal(true)}
              />
              <div className="w-full flex flex-wrap space-x-2">
                {types.map(item => (
                  <div key={Math.random()} className="w-fit mt-3">
                    <RenderTag
                      value={item?.name || item?.value}
                      removeValue={() => onRemoveItem(types, setTypes, item)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!errors?.types?.length && (
            <div className="text-sm text-red-400">{errors?.types}</div>
          )}
        </div>

        <span className="font-medium text-black-main text-14">
          Trạng thái được xem
        </span>
        <div className="space-y-4">
          <RadioButton
            titleClass={status?.includes('all') ? 'font-medium' : 'none'}
            isChecked={status?.includes('all')}
            title="Xem tất cả trạng thái"
            wrapperClass="ml-4"
            onPress={() => {
              setStatus.set(['all']);
            }}
          />
          <RadioButton
            titleClass={!status?.includes('all') ? 'font-medium' : 'none'}
            isChecked={!status?.includes('all')}
            title="Chọn trạng thái được xem"
            wrapperClass="ml-4"
            onPress={() => {
              !status?.includes('all')
                ? setStatus.set(status)
                : setStatus.set([]);
            }}
          />
          {!status?.includes('all') && (
            <div>
              <MyButton
                type="gray"
                label="Chọn trạng thái được xem"
                leftComponent={() => (
                  <img src="/svg/plus.svg" alt="Thêm icon" />
                )}
                onPress={() => setShowStatusModal(true)}
              />
              <div className="w-full flex flex-wrap space-x-2">
                {status?.map(item => (
                  <div key={Math.random()} className="w-fit mt-3">
                    <RenderTag
                      value={item?.name || item?.value}
                      removeValue={() => onRemoveItem(status, setStatus, item)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!errors?.status?.length && (
            <div className="text-sm text-red-400">{errors?.status}</div>
          )}
        </div>

        <span className="font-medium text-black-main text-14">
          Khu vực được xem
        </span>
        <div className="space-y-4">
          <RadioButton
            titleClass={districts?.includes('all') ? 'font-medium' : 'none'}
            isChecked={districts?.includes('all')}
            title="Xem tất cả khu vực"
            wrapperClass="ml-4"
            onPress={() => {
              setDistricts.set(['all']);
            }}
          />
          <RadioButton
            titleClass={!districts?.includes('all') ? 'font-medium' : 'none'}
            isChecked={!districts?.includes('all')}
            title="Chọn khu vực được xem"
            wrapperClass="ml-4"
            onPress={() => {
              !districts?.includes('all')
                ? setDistricts.set(districts)
                : setDistricts.set([]);
            }}
          />
          {!districts?.includes('all') && (
            <div>
              <MyButton
                type="gray"
                label="Chọn khu vực được xem"
                leftComponent={() => (
                  <img src="/svg/plus.svg" alt="Thêm icon" />
                )}
                onPress={() => setShowDistrictModal(true)}
              />
              <div className="w-full flex flex-col space-y-2">
                {data?.getDistrictsWithProvince?.map(item => (
                  <div
                    key={Math.random()}
                    className="flex flex-col space-y-1 mt-3"
                  >
                    <div className="text-sm font-medium">
                      {`${item?.value || item?.name} (${
                        item?.districts?.length
                      }/${item?.totalDistricts})`}
                    </div>
                    <div className="w-full flex flex-wrap space-x-2">
                      {item?.districts?.map(dist => (
                        <div key={Math.random()} className="w-fit mt-2">
                          <RenderTag
                            key={dist?.id || dist?.key}
                            value={dist?.value || dist?.name}
                            removeValue={() =>
                              onRemoveItem(districts, setDistricts, dist)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {errors?.region?.length && (
            <div className="text-sm text-red-400">{errors?.region}</div>
          )}
        </div>

        <span className="font-medium text-blue-main text-16">
          Khoảng giá được xem
        </span>
        <div className="flex flex-col space-y-4">
          <RadioButton
            titleClass={
              priceRange?.from === 0 &&
              priceRange?.to === Number.MAX_SAFE_INTEGER
                ? 'font-medium'
                : 'none'
            }
            isChecked={
              priceRange?.from === 0 &&
              priceRange?.to === Number.MAX_SAFE_INTEGER
            }
            title="Xem tất cả khoảng giá"
            wrapperClass="ml-4"
            onPress={() => {
              setPriceRange({ from: 0, to: Number.MAX_SAFE_INTEGER });
            }}
          />
          <RadioButton
            titleClass={
              priceRange?.to !== Number.MAX_SAFE_INTEGER
                ? 'font-medium'
                : 'none'
            }
            isChecked={priceRange?.to !== Number.MAX_SAFE_INTEGER}
            title="Nhập khoảng giá được xem"
            wrapperClass="ml-4"
            onPress={() => {
              priceRange?.from !== 0 &&
              priceRange?.to !== Number.MAX_SAFE_INTEGER
                ? setPriceRange(priceRange)
                : setPriceRange({});
            }}
          />
        </div>
        {priceRange?.to !== Number.MAX_SAFE_INTEGER && (
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="flex flex-row items-center space-x-2">
                <MyInput
                  value={priceRange?.from ?? ''}
                  type="number"
                  placeholder="Từ"
                  inputClassName="w-150px"
                  inputInnerClassName="w-full"
                  onDelete={() => setPriceRange({ ...priceRange, from: '' })}
                  onChange={e =>
                    setPriceRange({ ...priceRange, from: e.target.value })
                  }
                  maxLength={20}
                  hideMaxLength
                />
                <span className="text-sm">VNĐ </span>
              </div>
              <span className="mx-1 self-center">-</span>
              <div className="flex flex-row items-center space-x-2 ml-2">
                <MyInput
                  value={priceRange?.to ?? ''}
                  type="number"
                  placeholder="Đến"
                  inputClassName="w-150px"
                  inputInnerClassName="w-full"
                  onDelete={() => setPriceRange({ ...priceRange, to: '' })}
                  onChange={e =>
                    setPriceRange({ ...priceRange, to: e.target.value })
                  }
                  maxLength={20}
                  hideMaxLength
                />
                <span className="text-sm">VNĐ</span>
              </div>
            </div>
            <div className="text-sm mt-1">
              {priceRange?.from && priceRange?.to && (
                <div>
                  Từ{' '}
                  <span className="text-red-400">
                    {DocTienBangChuV2(priceRange?.from)}
                  </span>{' '}
                  đến{' '}
                  <span className="text-red-400">
                    {DocTienBangChuV2(priceRange?.to)}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        {!!errors?.priceRange?.length && (
          <div className="text-sm text-red-400">{errors?.priceRange}</div>
        )}
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ListTypeModal
          selectedItems={types}
          setItems={setTypes}
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
      >
        <ListStatusModal
          setItems={setStatus}
          selectedItems={status}
          onClose={() => setShowStatusModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showDistrictModal}
        onClose={() => setShowDistrictModal(false)}
      >
        <ListProvinceModal
          setItems={setDistricts}
          selectedItems={districts}
          onClose={() => setShowDistrictModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
