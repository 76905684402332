import MyDropdown from '@components/myDropdown';
import EnterFieldForSearch from '@components/filterDropdownComponentGroup/enterFieldForSearch';
import styles from '@components/table/table.module.scss';
import React from 'react';

type Props = {
  search?: string,
  setSearch?: Function,
  title?: string,
};
export default function SearchTextDropdown({
  search,
  setSearch,
  title = 'ID tin rao',
}: Props) {
  return (
    <MyDropdown
      popperPlacement="bottom"
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={EnterFieldForSearch}
      dropdownContext={{
        search,
        setSearch,
      }}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              {title}
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
