import { useMutation } from '@apollo/client';
import { DELETE_REAL_ESTATE_PRODUCT } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';

export const useDeletedRealEstateProduct = (realEstateIds, setShowModal) => {
  const { setListSelected } = useProductContext();
  const [onDeleted, { loading, error, client }] = useMutation(
    DELETE_REAL_ESTATE_PRODUCT
  );
  const toast = useToast();
  const onSubmit = useCallback(async () => {
    try {
      await onDeleted({
        variables: {
          realEstateIds: realEstateIds,
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Xóa sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModal(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Xóa sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [onDeleted, client, realEstateIds, setShowModal, setListSelected]);
  return { onSubmit, loading, error };
};
