import { useQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import React from 'react';
import { isEmpty } from 'lodash';

type Props = {
  context?: {
    userId?: string,
    isB2B?: boolean,
  },
};

export default function UserInfo(props: Props) {
  const {
    context: { userId },
  } = props;

  const { data } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      userId,
    },
    skip: !userId?.length,
  });

  const userInfo = data?.getUserBasicInfo || {};

  const toast = useToast();
  const phones = [userInfo?.phone, userInfo?.secondPhone]?.filter(e => e);
  return (
    <div
      className="flex flex-col rounded-rd8px bg-white"
      style={{
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
        width: 350,
      }}
    >
      <div className="flex flex-1 space-x-4" style={{ padding: '15px 15px' }}>
        <div
          className="bg-no-repeat bg-contain bg-center rounded-full border border-gray-300"
          style={{
            width: 65,
            height: 65,
            backgroundImage: `url("${
              userInfo?.avatar?.originalUrl || '/svg/male.svg'
            }")`,
          }}
        />
        <div className="flex flex-col flex-1">
          <div className="flex space-x-2">
            <span className="text-14 font-text font-medium w-100px">
              Họ và tên:
            </span>
            <span className="text-14 font-text">{userInfo.fullName}</span>
          </div>
          <div className="flex space-x-2">
            <span className="text-14 font-text font-medium w-100px">
              Email:
            </span>
            <span
              className="text-14 font-text truncate"
              style={{ maxWidth: 130 }}
            >
              {userInfo.email}
            </span>
          </div>
          {phones
            ?.filter(e => e)
            ?.map((item, index) => {
              return (
                <div className="flex space-x-2" key={`${item}-${index}`}>
                  <span className="text-14 font-text font-medium w-100px">{`Số điện thoại ${
                    index + 1
                  }`}</span>
                  <span className="text-14 font-text">{item}</span>
                </div>
              );
            })}
          {isEmpty(phones) && (
            <div className="flex space-x-2">
              <span className="text-14 font-text font-medium w-100px">{`Số điện thoại`}</span>
              <span className="text-14 font-text italic text-gray-500">
                Không có
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex h-40px items-center justify-end border-t border-gray-400"
        style={{ padding: '0 30px' }}
      >
        <button
          className="text-13 underline font-text text-blue-main space-x-1 flex"
          onClick={async () => {
            await toast({
              position: 'bottom-right',
              duration: 5000,
              render: ({ id, onClose }) => (
                <MyToast
                  id={id}
                  onClose={onClose}
                  type="warning"
                  message="Chức năng đang được hoàn thiện"
                  closeButtonPos="center"
                />
              ),
            });
          }}
        >
          <img src="/svg/message.svg" />
          <span>Nhắn tin</span>
        </button>
      </div>
    </div>
  );
}
