import React from 'react';
import { ClientStyle } from 'react-css-component';

type Props = {
  radius?: number,
  lat?: number,
  lng?: number,
  duplicateLocation?: boolean,
  showCircle?: boolean,
};

let CustomMarker, Circle, ReactLeaflet;
if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  Circle = ReactLeaflet.Circle;
  CustomMarker = require('./customMarker').CustomMarker;
}

const css = `
  .leaflet-map-circle {
  	stroke-width: 2 !important;
  	fill: rgba(135, 180, 252) !important;
  	fill-opacity: 0.15 !important;
  	stroke: rgba(36, 89, 173) !important;
  	stroke-opacity: 0.9 !important;
  }
`;

export default function SelectedLocationMarker({
  lat,
  lng,
  duplicateLocation,
  radius,
  showCircle,
}: Props) {
  if (!lat || !lng) {
    return null;
  }
  return (
    <React.Fragment>
      <ClientStyle css={css} />
      <CustomMarker position={[lat, lng]}>
        {!duplicateLocation && (
          <div
            className="flex flex-col items-center"
            style={{
              transform: 'translate(-50%, -80%)',
            }}
          >
            <div
              className="w-40px h-42px"
              style={{ backgroundImage: 'url("/svg/currentLocation.svg")' }}
            />
          </div>
        )}
      </CustomMarker>
      {showCircle && (
        <Circle
          className="leaflet-map-circle"
          center={[lat, lng]}
          radius={radius}
        />
      )}
    </React.Fragment>
  );
}
