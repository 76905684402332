import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { LEAVE_COMMENT } from '@utils/graphql';

export const useLeaveComment = (realEstateId, setShowModal) => {
  const [onLeaveComment, { loading, error, client }] =
    useMutation(LEAVE_COMMENT);
  const onSubmit = useCallback(
    async (content, parentComment) => {
      try {
        const data = {
          realEstateId,
          content,
        };
        if (parentComment) {
          data.parentComment = parentComment;
        }
        await onLeaveComment({
          variables: {
            ...data,
          },
        });
        await resetQueries(['getInternalRealEstateDetails'], client.cache);
        setShowModal(false);
      } catch (e) {
        console.log(e);
      }
    },
    [onLeaveComment, client, realEstateId, setShowModal]
  );
  return { onSubmit, loading, error };
};
