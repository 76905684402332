import React from 'react';
import { Image } from 'react-native-web';

export default function LoadingScreen() {
  return (
    <div className="fixed inset-0 overflow-hidden">
      <Image
        style={{ width: '100%', height: '100%' }}
        source="/images/loadingBanner.png"
      />
      <div
        className="absolute flex flex-col z-50 top-1/2 right-1/2"
        style={{
          transform: 'translate(50%, -50%)',
          maxHeight: 'calc(100vh - 20px)',
          maxWidth: '100vw',
          width: 517,
          height: 521,
        }}
      >
        <img
          className="h-full min-h-0 min-w-0 flex-1 w-full object-contain bg-no-repeat"
          src="/svg/welcome.svg"
        />
        <h1
          className="text-white font-semibold text-center"
          style={{ fontSize: 24, lineHeight: '30px' }}
        >
          Chào mừng đến với phần mềm quản lý Biha
        </h1>
      </div>
    </div>
  );
}
