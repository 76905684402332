import MyButton from '@components/button';
import React from 'react';
import MyModalV2 from '@components/modalV2/myModalV2';

type Props = {
  isOpen?: boolean,
  onClose?: Function,
  type: 'img' | 'file',
  singleUpload?: boolean,
};
export default function UploadErrorModal({
  isOpen,
  onClose,
  type,
  singleUpload = false,
}: Props) {
  const title = `KHÔNG THỂ ĐĂNG ${type === 'img' ? 'ẢNH' : 'TẢI TỆP'}!`,
    maxFileMsg = `Tổng số lượng ${
      type === 'img' ? 'hình ảnh' : 'tệp'
    } không vượt quá <b>6 ${type === 'img' ? 'ảnh' : 'tệp'}</b>`,
    maxFileSizeMsg = `Kích thước ${
      type === 'img' ? 'hình ảnh' : 'tệp'
    } không vượt quá <b>1 MB</b>`;
  return (
    <MyModalV2 open={isOpen} onClose={onClose}>
      <div className="bg-white w-600px h-fit rounded-full p-6">
        <div className="flex flex-col w-full h-full space-y-3">
          <div
            style={{ color: '#EC6E00' }}
            className="w-full text-center text-xl sm:text-2xl font-medium"
          >
            {title}
          </div>
          <div className="w-full flex justify-center">
            <img
              className="w-249px h-155px"
              alt="image"
              src="/images/uploadError.webp"
            />
          </div>
          <div className="w-full flex flex-col space-y-1.5 px-0 sm:px-16">
            {!singleUpload && (
              <div className="flex flex-row items-center space-x-2 justify-start">
                <div
                  style={{ backgroundColor: '#000000' }}
                  className="w-5px h-5px rounded-full"
                />
                <div
                  className="text-sm sm:text-18 text-black"
                  dangerouslySetInnerHTML={{
                    __html: maxFileMsg,
                  }}
                />
              </div>
            )}
            <div className="w-full flex flex-row items-center justify-start space-x-2">
              <div
                style={{ backgroundColor: '#000000' }}
                className="w-5px h-5px rounded-full bg-black"
              />
              <div
                className="text-sm sm:text-18 text-black"
                dangerouslySetInnerHTML={{
                  __html: maxFileSizeMsg,
                }}
              />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-center">
            <div className="w-148px" onClick={onClose}>
              <MyButton type="gray" label="Trở về" />
            </div>
          </div>
        </div>
      </div>
    </MyModalV2>
  );
}
