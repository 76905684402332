import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useDeepCompareEffect, useList } from 'react-use';
import { View } from 'react-native-web';
import Switch from '@components/switch';
import { realEstate } from '@components/leftMenu/configs';
import { useFieldConfig } from '../../../modals/visibleFieldOnTableModalProduct/config';
import { ProductContext } from './product/context';
import { unionBy } from 'lodash';
import ProductDetail from './productDetail';
import {
  blankStateFilterProduct,
  useStateFilterProduct,
} from '@utils/hooks/manageProduct/useStateFilterProduct';
import {
  ApprovedProductStorageKey,
  HiddenProductStorageKey,
  MyProductStorageKey,
  ProductStorageKey,
  DeletedProductStorageKey,
  PostedProductStorageKey,
  PremiumProductStorageKey,
  AuthorizeProductStorageKey,
} from '../../../constant';
import { useGetLocation } from './configs';
import SwitchCustom from '@components/switchCustom';
import useToggle from 'react-use/lib/useToggle';
import MapLayout from '@components/realEstateOnMap';
import { useGetMe } from '@utils/hooks/common/useGetMe';

export default function ManageProduct() {
  const location = useLocation();
  const [listSelected, setListSelected] = useList([]);
  const [previousId, setPreviousId] = useState('');
  const { permissions } = useGetMe();

  const [tab, setTab] = useState(location.pathname);
  const [status, setStatus] = useState('approved');
  const [productStatus, setProductStatus] = useState('');
  const [currentId, setCurrentId] = useState('');
  const [showChangeStatusModal, setShowChangeStatusModal] = useState('');
  const navigate = useNavigate();
  const fields = useFieldConfig(tab);
  const listFieldId = fields?.filter(e => !e?.hide)?.map(e => e.key);
  const [mapMode, setMapMode] = useToggle(false);
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterProduct();
  const { id } = useParams();
  const {
    isTabProduct,
    isTabMyProduct,
    isTabWaitingForApproval,
    isTabHiddenProduct,
    isTabDeleteProduct,
    isTabProductPosted,
    isTabProductPremium,
  } = useGetLocation();
  const localStorageKey = isTabProduct
    ? ProductStorageKey
    : isTabMyProduct
    ? MyProductStorageKey
    : isTabWaitingForApproval
    ? ApprovedProductStorageKey
    : isTabHiddenProduct
    ? HiddenProductStorageKey
    : isTabDeleteProduct
    ? DeletedProductStorageKey
    : isTabProductPosted
    ? PostedProductStorageKey
    : isTabProductPremium
    ? PremiumProductStorageKey
    : AuthorizeProductStorageKey;
  const listFieldLocalStorage = JSON.parse(
    localStorage.getItem(localStorageKey)
  );

  const [listField, setListField] = useList(
    listFieldLocalStorage || listFieldId || []
  );

  useDeepCompareEffect(() => {
    setListField.set(listFieldLocalStorage || listFieldId);
  }, [listFieldId, listFieldLocalStorage]);

  useDeepCompareEffect(() => {
    setTab(location.pathname);
  }, [location.pathname]);

  const headerHeight = document.getElementById('header')?.offsetHeight;
  const containerSwitchHeight =
    document.getElementById('container-switch')?.offsetHeight;

  return (
    <ProductContext.Provider
      value={React.useMemo(
        () => ({
          listField,
          setListField,
          listSelected: unionBy(listSelected, e => e),
          stateFilter,
          setState,
          setStateFilterAt,
          status,
          setStatus,
          setListSelected,
          tab,
          productStatus,
          setProductStatus,
          currentId,
          setCurrentId,
          previousId,
          setPreviousId,
          showChangeStatusModal,
          setShowChangeStatusModal,
          mapMode,
          setMapMode,
        }),
        [
          mapMode,
          setMapMode,
          listField,
          setListField,
          listSelected,
          stateFilter,
          setState,
          setStateFilterAt,
          setListSelected,
          tab,
          productStatus,
          setProductStatus,
          currentId,
          setCurrentId,
          previousId,
          setPreviousId,
          showChangeStatusModal,
          setShowChangeStatusModal,
        ]
      )}
    >
      <View style={{ flex: 1 }}>
        {!id && (
          <>
            <div
              id="container-switch"
              className="flex items-center"
              style={{
                padding: '0 16px 0',
                marginTop: 64,
                marginLeft: 16,
                backgroundColor: 'white',
                borderRadius: '15px 15px 0 0',
              }}
            >
              <div className="flex flex-1">
                <Switch
                  value={tab}
                  setValue={value => {
                    setTab(value);
                    navigate(value);
                    setListSelected.clear();
                    setState(blankStateFilterProduct);
                  }}
                  configs={realEstate.items[0].items?.filter(
                    p =>
                      permissions?.map(e => e.id)?.includes(p.permissionId) ||
                      !p.permissionId
                  )}
                />
              </div>
              <div className="border-b border-gray-300 mb-2.5">
                <SwitchCustom
                  value={mapMode}
                  toggleValue={setMapMode}
                  title="Xem trên bản đồ"
                />
              </div>
            </div>
            {!mapMode && <Outlet />}
          </>
        )}
        {(id || listSelected?.length === 1) && !mapMode ? (
          <View
            style={{
              width: '100%',
              height: '100%',
              marginTop: 8,
            }}
          >
            <ProductDetail tab={tab} id={id} />
          </View>
        ) : !mapMode ? (
          <div className="p-4 pl-8 text-black-main">
            Vui lòng chọn 1 bất động sản để xem chi tiết
          </div>
        ) : null}
        {mapMode && (
          <View
            style={{
              width: '100%',
              height: `calc(100vh - ${headerHeight + containerSwitchHeight}px)`,
              minHeight: 768,
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}
          >
            <MapLayout />
          </View>
        )}
      </View>
    </ProductContext.Provider>
  );
}
