import RadioButton from '@components/radio';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
type RadioConfigProps = {
  config: Object,
  assignedConfig: boolean,
  multiRadio?: boolean,
};
export default function RadioConfig({
  config = {},
  assignedConfig = false,
  multiRadio = false,
}: RadioConfigProps) {
  const { name, options, id, configKey } = config;
  const [assignedValue, setAssignedValue] = useState(assignedConfig);
  const { setValue } = useFormContext();
  return (
    <div className="w-full flex flex-col space-y-4 rounded-lg shadow-card p-4">
      <span
        className={
          multiRadio
            ? `font-medium text-black-main text-14`
            : `font-medium text-blue-main text-16`
        }
      >
        {name}
      </span>
      <div className="flex flex-col space-y-4">
        {options?.map(item => (
          <RadioButton
            key={Math.random()}
            titleClass={assignedValue === item?.key ? 'font-medium' : 'none'}
            isChecked={assignedValue === item?.key}
            title={item?.value}
            wrapperClass="ml-4"
            onPress={() => {
              setAssignedValue(item?.key);
              setValue(`${id}.${configKey}`, item?.key);
            }}
          />
        ))}
      </div>
    </div>
  );
}
