import Label from '@components/label';
import { demandConfig } from '../configs';
import { Controller, useFormContext } from 'react-hook-form';
import RadioButton from '@components/radio';
import DropdownSelection from '@components/dropdownSelection';
import React from 'react';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import Section from '@components/section';

type Props = {
  isStep2?: boolean,
};
export default function Demand({ isStep2 = false }: Props) {
  const { forRentRealEstateTypes, forSellRealEstateTypes } = configsRemote;
  const { control, watch } = useFormContext();
  const isForSell = watch('isForSell');
  return (
    <Section wrapperClassName="space-y-5">
      <div className="text-base font-medium text-blue-main">Nhu cầu (2/2)</div>
      <div className="flex w100p mb15x space-x-2.5">
        <Label wrapperClass="w-152px" isRequired label="Nhu cầu" />
        <div className="flex-1 flex space-x-6">
          {demandConfig.map((item, index) => {
            return (
              <Controller
                key={index}
                control={control}
                name="isForSell"
                render={({ field: { onChange, value } }) => {
                  return (
                    <RadioButton
                      disabled={isStep2}
                      onPress={() => {
                        onChange(item.key);
                      }}
                      isChecked={item.key === value}
                      title={item.value}
                    />
                  );
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="flex w-full mb15x space-x-2.5">
        <Label wrapperClass="w-152px" isRequired label="Loại bất động sản" />
        <div className="flex-1">
          <Controller
            rules={{
              required: 'Vui lòng chọn loại bất động sản',
            }}
            control={control}
            name="typeRealEstate"
            render={({
              field: { onChange, value },
              fieldState: { invalid, error },
            }) => {
              return (
                <DropdownSelection
                  isHideDelete
                  disabled={isStep2}
                  selections={
                    isForSell === 'forSale'
                      ? forSellRealEstateTypes
                      : forRentRealEstateTypes
                  }
                  value={value}
                  setValue={onChange}
                  onDelete={() => onChange('')}
                  placeholder="Chọn loại bất động sản"
                  hasItemRadio={false}
                  invalid={invalid}
                  errorMessage={error?.message}
                />
              );
            }}
          />
        </div>
      </div>
    </Section>
  );
}
