import styles from './button.module.scss';

type Props = {
  label?: string,
  type?: 'blue' | 'gray' | 'red' | 'other' | 'white',
  onPress?: Function,
  wrapperClass?: string,
  disabled?: boolean,
  style?: Object,
  leftComponent?: Function,
  rightComponent?: Function,
  labelStyle?: string,
};

export default function MyButton(props: Props) {
  const {
    label,
    type = 'blue',
    onPress,
    wrapperClass,
    disabled,
    style,
    leftComponent,
    rightComponent,
    labelStyle,
    ...rest
  } = props;

  const buttonType = `${type}Button`;
  const labelType = `${type}Label`;

  return (
    <button
      {...rest}
      onClick={onPress}
      disabled={disabled}
      style={style}
      className={`${styles.buttonContainer} ${
        styles[buttonType]
      } ${wrapperClass} ${disabled && 'opacity-50 cursor-not-allowed'}`}
    >
      {leftComponent && leftComponent()}
      <div
        dangerouslySetInnerHTML={{ __html: label }}
        className={` ${styles[labelType]} ${labelStyle}`}
      />

      {rightComponent && rightComponent()}
    </button>
  );
}
