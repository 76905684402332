import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { GET_PERMISSION_GROUP_DETAILS } from '@utils/graphql';
import { useState } from 'react';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import { useSwitchTabConfigs } from './config';
import PermissionGroupDetailLeft from './perrmissionGroupDetailLeft/PermissionGroupDetailLeft';
import PermissionGroupDetailRight from './PermissionGroupDetailRight';

export default function PermissionGroupDetail() {
  const { listSelected } = usePermissionGroupsContext();
  const { data } = useQuery(GET_PERMISSION_GROUP_DETAILS, {
    variables: {
      permissionGroupId: listSelected?.[0],
    },
    skip: !listSelected?.[0],
  });
  const tabConfigs = useSwitchTabConfigs();
  const [currentTab, setCurrentTab] = useState(tabConfigs[0].key);

  return (
    <Box className="flex gap-4 pl-8 pr-4 py-4">
      <PermissionGroupDetailLeft
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        permissionGroup={data?.permissionGroupDetails}
      />
      <PermissionGroupDetailRight
        currentTab={currentTab}
        permissionGroup={data?.permissionGroupDetails}
        permissionGroupId={listSelected?.[0]}
      />
    </Box>
  );
}
