import MyButton from '@components/button';
import MyTreeView from '@components/treeView/treeView1';
import { Permission } from '@gugotech/tncc-users-permissions/lib';
import { Paper } from '@mui/material';
import { useUpdateEmployee } from '@utils/hooks/b2bUser/useUpdateEmployee';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useList } from 'react-use';
import PermissionConfigs from '../components/PermissionConfigs';
import { blankState } from '../configs';

type PermissionModalProps = {
  onClose: Function,
  employee: Object,
  isEdit?: boolean,
};

export default function PermissionModal({
  onClose,
  employee,
  isEdit = false,
}: PermissionModalProps) {
  const assignedPermissions = [
    ...(employee?.productPermissions ?? {}),
    ...(employee?.projectPermissions ?? {}),
  ];
  const [permissions, setPermissions] = useList(
    isEdit ? assignedPermissions?.map(p => p.id) : []
  );
  const [errors, setErrors] = useState({});
  const [modalError] = useState(false);
  const { onUpdateSubmit, updateLoading } = useUpdateEmployee(
    employee?.id,
    onClose
  );
  const form = useForm({
    defaultValues: blankState,
    mode: 'onChange',
  });
  useEffect(() => {}, [errors]);
  const handleAssignPermission = useCallback(
    async e => {
      if (Object.keys(errors)?.length > 0) {
        e.preventDefault();
      } else {
        const allPermission = Permission.getPermissionTree(employee?.system);
        const configs = form.watch();
        const selectedPermissions = allPermission?.filter(p =>
            permissions?.includes(p.id)
          ),
          latestPermissions = selectedPermissions?.map(s => {
            if (configs[s.id]) {
              return {
                id: s.id,
                configs: configs[s.id],
              };
            }
            return { id: s.id, configs: s.configs };
          });
        await onUpdateSubmit({ permissions: latestPermissions }, isEdit);
      }
    },
    [permissions, errors]
  );
  return (
    <FormProvider {...form}>
      <div
        style={{ minWidth: 1100, maxWidth: 1200 }}
        className="bg-gray-200 rounded-lg"
      >
        <div className="bg-white w-full h-50px text-blue-main font-medium text-16 flex justify-center items-center rounded-t-lg">
          Bộ quyền
        </div>
        <div className="w-full flex flex-row space-x-4 p-4 shadow-md">
          <div className="w-1/2 bg-white rounded shadow-md p-4">
            <MyTreeView
              title="Phân quyền"
              checkable
              searchable
              searchPlaceHolder="Nhập để tìm kiếm quyền..."
              minHeight={458}
              maxHeight={458}
              data={Permission.getPermissionTree('product')}
              defaultCheckedIds={permissions}
              setStorage={setPermissions}
            />
          </div>
          <div className="w-1/2 bg-white rounded shadow-md p-4">
            <PermissionConfigs
              modalError={modalError}
              errors={errors}
              setErrors={setErrors}
              assignedPermissions={assignedPermissions}
              isEdit={isEdit}
              selectedPermissions={permissions}
              maxHeight={458}
            />
          </div>
        </div>
        <div className="border-b border-gray-300"></div>
        <Paper className="flex flex-row w-full justify-end space-x-4 p-4">
          <div className="w-147px">
            <MyButton onPress={onClose} type="gray" label="Trở về" />
          </div>
          <div className="w-147px">
            <MyButton
              type="blue"
              onPress={e => handleAssignPermission(e)}
              disabled={updateLoading || Object.keys(errors)?.length > 0}
              label="Xác nhận"
            />
          </div>
        </Paper>
      </div>
    </FormProvider>
  );
}
