import { useQuery } from '@apollo/client';
import { LIST_EMPLOYEES } from '@utils/graphql';
import { useLoadMore } from '../common/useLoadMore';
import { useState } from 'react';
import moment from 'moment/moment';
import pickBy from 'lodash/pickBy';
import { useGlobalContext } from '../../../context';

export const useGetListEmployee = ({ state }) => {
  const { isLogin } = useGlobalContext();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = pickBy(
    {
      employeeCode: state?.employeeCode,
      fullName: state?.fullName,
      username: state?.username,
      phone: state?.phone,
      provinces: state?.province ? state?.province : null,
      districts: state?.district ? state?.district : null,
      email: state?.email,
      createdAt:
        !state.createdAtFrom || !state.createdAtTo
          ? null
          : {
              from: moment.unix(state.createdAtFrom).format('YYYY-MM-DD'),
              to: moment
                .unix(state.createdAtTo)
                .endOf('day')
                .format('YYYY-MM-DD'),
            },
    },
    e => e || e === false
  );
  if (state?.department) {
    if (state?.department === 'noDepartment') {
      variables.isInLobby = true;
    } else {
      variables.departmentIds = [state?.department];
    }
  }
  const finalVariables = {
    data: variables,
    sort: '-createdAt',
    page: 1,
    limit: 10,
  };
  const { data, fetchMore, loading } = useQuery(LIST_EMPLOYEES, {
    variables: finalVariables,
    skip: !isLogin,
  });
  const listUser = data?.b2bGetEmployees?.payload?.items || [];
  const hasNextPage = data?.b2bGetEmployees?.payload?.hasNextPage;
  const page = data?.b2bGetEmployees?.payload?.page || 1;
  const total = data?.b2bGetEmployees?.payload?.total;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });

  return {
    listUser: loading ? [1, 2, 3] : listUser || [],
    loading: loading,
    isLoadingMore,
    onLoadMore,
    hasNextPage,
    page,
    total,
  };
};
