import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/label';
import Section from '@components/section';
import DropdownSelection from '@components/dropdownSelection';
import { postTypes } from '@utils/configs/remoteConfigs/realEstateCommonConfigs';
import MyInput from '@components/myInput';
import { maxLength } from '../../../../constant';
export const fieldConditionConfigs = {
  postType: {
    dataVisible: state => state?.postType > 0,
  },
  numberOfPostingDay: {
    dataVisible: state => state?.numberOfPostingDay > 0,
  },
};
export const getTotalFieldCount = props => {
  const field = ['postType', 'numberOfPostingDay'];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};

export default function PostPeriod() {
  const { control, watch, setValue } = useFormContext();
  const postType = watch('postType');
  const numberOfPostingDay = watch('numberOfPostingDay');
  const data = {
    postType,
    numberOfPostingDay,
  };
  return (
    <Section>
      <div className="text-base text-blue-main font-medium">
        Thời hạn đăng tin ({getTotalFieldCount(data).fieldVisibleCount}/{2})
      </div>
      <div className="w-full mt-7 space-y-7">
        <div className="w-full flex flex-row space-x-2.5 items-center">
          <Label wrapperClass="w-152px" isRequired label="Loại tin đăng" />
          <Controller
            control={control}
            rules={{
              required: 'Vui lòng chọn loại tin',
            }}
            name="postType"
            render={({
              field: { value, onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <div className="flex-1">
                  <DropdownSelection
                    hasItemRadio={false}
                    placeholder="Chọn loại tin"
                    selections={postTypes}
                    value={value}
                    setValue={value => {
                      onChange(value);
                    }}
                    onDelete={() => {
                      onChange('');
                      setValue('postType', '');
                    }}
                    errorMessage={error?.message}
                    invalid={invalid}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row space-x-2.5 items-center">
          <Label wrapperClass="w-152px" isRequired label="Thời hạn đăng tin" />
          <Controller
            rules={{
              required: 'Vui lòng nhập thời gian đăng tin',
            }}
            control={control}
            name="numberOfPostingDay"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <div className="flex flex-1 space-x-4 items-center">
                  <MyInput
                    {...field}
                    inputClassName="flex-1"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập số ngày"
                    onDelete={() => onChange('')}
                    maxLength={maxLength.maxPostDays}
                    hideMaxLength
                    type="number"
                  />
                  <span className="w-35px text-sm">Ngày</span>
                </div>
              );
            }}
          />
        </div>
      </div>
    </Section>
  );
}
