export const headerRenewRealEstateConfigs = [
  {
    key: 'typeOfDemand',
    title: 'Nhu cầu',
    className: 'w-124px',
  },
  {
    key: 'realEstateType',
    title: 'Loại bất động sản',
    className: 'w-170px',
  },
  {
    key: 'street',
    title: 'Đường',
    className: 'w-170px',
  },
  {
    key: 'ward',
    title: 'Phường/ Xã',
    className: 'w-170px',
  },
  {
    key: 'district',
    title: 'Quận/ Huyện',
    className: 'w-170px',
  },
  {
    key: 'province',
    title: 'Tỉnh/ Thành phố',
    className: 'w-170px',
  },
  {
    key: 'country',
    title: 'Đất nước',
    className: 'w-124px',
  },
];
