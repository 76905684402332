import MyTreeView from '@components/treeView/treeView1';
import { Permission } from '@gugotech/tncc-users-permissions/lib';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';

BottomLeftPermissionDetail.propTypes = {
  setPermissions: PropTypes.object,
  assignedPermissions: PropTypes.array,
};
function BottomLeftPermissionDetail({ setPermissions, assignedPermissions }) {
  return (
    <Box className="flex-1 rounded-rd8px bg-white px-4 py-8 shadow-md">
      <MyTreeView
        title="Phân quyền"
        checkable
        searchable
        searchPlaceHolder="Nhập để tìm kiếm quyền..."
        data={Permission.getPermissionTree('product')}
        defaultCheckedIds={assignedPermissions}
        setStorage={setPermissions}
      />
    </Box>
  );
}

export default BottomLeftPermissionDetail;
