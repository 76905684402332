import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@utils/graphql';
import { convertDataSelections } from '@utils/helper';
import DropdownSelection from '@components/dropdownSelection';

type Props = {
  value?: string,
  setValue?: Function,
  onDelete?: Function,
  invalid?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  provinceId?: string,
};
export default function DistrictDropdownSelection({
  value,
  setValue,
  onDelete,
  invalid,
  errorMessage,
  disabled,
  provinceId,
}: Props) {
  const { data, loading } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
    variables: {
      provinceId,
      limit: 100,
    },
    skip: !provinceId,
  });
  const rawData = [...(data?.getListDistrictsOfProvince?.districts || [])];
  const dataSelections = convertDataSelections(rawData);
  return (
    <DropdownSelection
      selections={dataSelections}
      value={value}
      setValue={setValue}
      onDelete={onDelete}
      loading={loading}
      errorMessage={errorMessage}
      invalid={invalid}
      disabled={disabled}
      isShowSearchBox
      placeholder="Quận/ Huyện"
    />
  );
}
