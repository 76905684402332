import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { Animated } from 'react-native-web';

type Props = {
  title?: string,
  value?: boolean,
  toggleValue?: Function,
};

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  border: '1px solid #E0E0E0',
  borderRadius: 26 / 2,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2459AD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    border: '1px solid #d3d0cf',
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function SwitchCustom({ title, value, toggleValue }: Props) {
  const { current: animatedNode } = useRef(new Animated.Value(value ? 1 : 0));
  useEffect(() => {
    Animated.timing(animatedNode, {
      toValue: value ? 1 : 0,
      duration: 250,
    }).start();
  }, [value, animatedNode]);
  return (
    <div className="flex items-center flex-row">
      <IOSSwitch onChange={toggleValue} checked={value} sx={{ m: 1.3 }} />
      <span className="text-14">{title}</span>
    </div>
  );
}
