import styles from './changePasswordModal.module.scss';
import { useForm, FormProvider } from 'react-hook-form';
import React from 'react';
import Content, { changePasswordSchema } from './components/content';
import { getErrorsFormHook } from '../../utils/helper';

type Props = {
  onClose?: Function,
  setShowModalSuccess?: Function,
};

export default function ChangePasswordModal({
  onClose,
  setShowModalSuccess,
}: Props) {
  const form = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: async data => {
      const { error, value: values } = changePasswordSchema.validate(
        { ...data },
        {
          abortEarly: false,
        }
      );
      return getErrorsFormHook({ error, values });
    },
    mode: 'onChange',
  });
  return (
    <FormProvider {...form}>
      <div className={styles.changePasswordModal}>
        <div className="w-full text-base font-medium text-left">
          Đặt mật khẩu mới
        </div>
        <Content setShowModalSuccess={setShowModalSuccess} onClose={onClose} />
      </div>
    </FormProvider>
  );
}
