import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_REAL_ESTATE_FEES } from '@utils/graphql';

export const useGetRealEstateFees = ({
  startDay,
  endDay,
  postType,
  actionType = 'createPost',
}) => {
  const { data } = useQuery(GET_REAL_ESTATE_FEES, {
    variables: {
      startDate: moment.unix(startDay).format('YYYY-MM-DD'),
      endDate: moment.unix(endDay).format('YYYY-MM-DD'),
      postType: parseInt(postType, 10),
      actionType,
    },
    skip: !postType,
  });
  return { data };
};
