type Props = {
  isChecked?: boolean,
  disabled?: boolean,
  isPartialCheck?: boolean,
  title?: string,
  titleClass?: string,
  value?: any,
  onPress?: Function,
  titleStyle?: Object,
};

export default function CheckboxUsingTable(props: Props) {
  const {
    isChecked,
    title,
    titleClass,
    onPress,
    value,
    disabled,
    titleStyle,
    isPartialCheck,
  } = props;

  const Icon = isChecked
    ? '/svg/checked-table-icon.svg'
    : isPartialCheck
    ? '/svg/ic_partial_check.svg'
    : '/svg/empty-check-table-icon.svg';

  return (
    <button
      disabled={disabled}
      className={`flex flex-row items-center space-x-2 ${
        disabled ? 'pointer-events-none' : undefined
      }`}
      onClick={() => onPress(value)}
    >
      <div className="w-6 h-6 flex items-center justify-center">
        <img src={Icon} alt="icon" className="w-6 h-6" />
      </div>
      {title && (
        <div
          style={titleStyle}
          className={`text-sm font-text text-left ${titleClass}`}
        >
          {title}
        </div>
      )}
    </button>
  );
}
