import { View } from 'react-native-web';
import RenderTopTable from '../components/renderTopTable';
import { manageOrganization } from '@components/leftMenu/configs';
import { useLocation } from 'react-router-dom';
import RenderTablePermissionGroups from '../components/renderTablePermissionGroups';
import { useGetPermissionGroups } from '@utils/hooks/manageOrganization/useGetPermissionGroups';
import { usePermissionGroupsContext } from './context';
import React, { useEffect } from 'react';
import queryString from 'query-string';

export default function PermissionGroup() {
  const { pathname, search } = useLocation();

  const { stateFilter } = usePermissionGroupsContext();
  let {
    permissionGroups,
    onLoadByPagination,
    loading,
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  } = useGetPermissionGroups({ state: stateFilter });

  const pageParam = parseInt(queryString.parse(search).page) || 1;
  const title = manageOrganization.items[0].items?.find(
    item => item.pathname === pathname
  )?.title;
  const pagination = {
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };

  useEffect(() => {
    onLoadByPagination(pageParam);
    return () => {
      onLoadByPagination = null;
    };
  }, [pageParam]);

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          width: '100%',
          backgroundColor: 'white',
          paddingLeft: 16,
        }}
      >
        <div className="px-4 pt-2">
          <RenderTopTable title={title} total={totalItem} />
        </div>
        <div className="w-full px-4">
          <RenderTablePermissionGroups
            loading={loading}
            data={permissionGroups}
            pagination={pagination}
            onLoadByPagination={onLoadByPagination}
          />
        </div>
      </View>
    </View>
  );
}
