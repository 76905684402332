import SwitchInner from '@components/switchInner';
import { Suspense, useMemo } from 'react';
import BlockDetail from './blockDetail';
import HeadLeftProjectDetail from './headLeftProjectDetail';
import ProjectDetail from './projectDetail';

type Props = {
  data?: Object,
  currentTab?: string,
  setCurrentTab?: Function,
  tabConfigs?: Array,
};

export default function RenderRight(props: Props) {
  const { data, currentTab, setCurrentTab, tabConfigs } = props;
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case 'productDetail':
        return <ProjectDetail data={data} />;
      case 'blockDetail':
        return <BlockDetail data={data} />;
    }
  }, [data, currentTab]);
  return (
    <div className="w-full">
      <HeadLeftProjectDetail
        postTitle={data?.projectName}
        fullAddress={data?.fullAddress}
        postStatus={data?.projectStatus}
      />
      <SwitchInner
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
      </div>
    </div>
  );
}
