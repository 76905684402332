export const useHeaderTableEditUserHistoryConfigs = isPersonal => {
  return [
    {
      key: 'companyName',
      title: 'Tên doanh nghiệp',
      className: 'w-175px',
      path: '/companyName',
    },
    {
      isHidden: isPersonal,
      key: 'taxIdOrIdNumber',
      title: 'Mã số thuế',
      className: 'w-156px',
      path: '/taxIdOrIdNumber',
    },
    {
      key: 'companyProvince',
      title: 'Tỉnh/ Thành phố',
      className: 'w-156px',
      path: '/companyProvince',
    },
    {
      key: 'companyDistrict',
      title: 'Quận/ Huyện',
      className: 'w-156px',
      path: '/companyDistrict',
    },
    {
      key: 'companyWard',
      title: 'Phường/ Xã',
      className: 'w-155px',
      path: '/companyWard',
    },
    {
      key: 'companyStreet',
      title: 'Đường',
      className: 'w-188px',
      path: '/companyStreet',
    },
    {
      key: 'companyHouseNumber',
      title: 'Số nhà',
      className: 'w-194px',
      path: '/companyHouseNumber',
    },
    {
      key: 'fullName',
      title: 'Họ và tên',
      className: 'w-175px',
      path: '/fullName',
    },
    {
      key: 'province',
      title: 'Tỉnh/ Thành phố',
      className: 'w-156px',
      path: '/province',
    },
    {
      key: 'district',
      title: 'Quận/ Huyện',
      className: 'w-156px',
      path: '/district',
    },
    {
      key: 'ward',
      title: 'Phường/ Xã',
      className: 'w-155px',
      path: '/ward',
    },
    {
      key: 'street',
      title: 'Đường',
      className: 'w-188px',
      path: '/street',
    },
    {
      key: 'houseNumber',
      title: 'Số nhà',
      className: 'w-194px',
      path: '/houseNumber ',
    },
    {
      key: 'phone',
      title: 'Số điện thoại 1',
      className: 'w-155px',
      path: '/phone',
    },
    {
      key: 'secondPhone',
      title: 'Số điện thoại 2',
      className: 'w-155px',
      path: '/secondPhone',
    },
    {
      key: 'gender',
      title: 'Giới tính',
      className: 'w-155px',
      path: '/gender',
    },
    {
      key: 'dateOfBirth',
      title: 'Ngày sinh',
      className: 'w-155px',
      path: '/dateOfBirth',
    },
    {
      key: 'idNumber',
      title: 'Số CMND',
      className: 'w-155px',
      path: '/idNumber',
    },
    {
      key: 'idImages',
      title: 'Hình ảnh CMND',
      className: 'w-155px',
      path: '/idImages',
    },
    {
      key: 'attachments',
      title: 'Tài liệu đính kèm',
      className: 'w-155px',
      path: '/attachments',
    },
  ];
};
