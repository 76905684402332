import RenderMedia from './renderMedia';
import RenderUserInfo from './renderUserInfo';
import RenderNote from './renderNote';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';

type Props = {
  data?: Object,
};
export default function RenderLeft({ data }: Props) {
  const { productPosted } = useGetTab();
  return (
    <div className="w-323px h-full space-y-5">
      <RenderMedia
        mediaUrlIds={!productPosted ? data?.mediaUrls : data?.mediaUrlsIds}
      />
      <RenderNote data={data} />
      <RenderUserInfo data={data} />
    </div>
  );
}
