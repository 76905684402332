import React from 'react';
import Section from './section';
import { DocTienBangChuV2 } from '@utils/helper';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import moment from 'moment';
type Props = {
  data?: Object,
  demand?: string,
};

export default function QuickInfo({ data, demand }: Props) {
  const { productPosted } = useGetTab();
  return (
    <Section
      title="Xem thông tin nhanh"
      source="/svg/detailRealEstate/thongtinnhanh.svg"
    >
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Diện tích
        </div>
        <div className="flex-1 text-sm text-black-main">
          Ngang: {data?.width}m | Dài: {data?.length}m | Mặt hậu:{' '}
          {data?.rear ? (
            data?.rear + 'm '
          ) : (
            <span className="italic opacity-50">Không có </span>
          )}
          | Tổng diện tích: {data?.area} m²
        </div>
      </div>
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          {productPosted
            ? demand === 'sale' || demand === true
              ? 'Giá bán đã đăng'
              : 'Giá cho thuê đã đăng'
            : demand === 'sale' || demand === true
            ? 'Giá bán'
            : 'Giá cho thuê'}
        </div>
        <div className="flex-1 text-sm text-red-600">
          {demand === 'sale' || demand === true
            ? DocTienBangChuV2(data?.price)
            : `${DocTienBangChuV2(data?.price)}/tháng`}
        </div>
      </div>
      {productPosted &&
        (demand === 'sale' || demand === true) &&
        data?.priceOfOwner && (
          <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
            <div className="w-120px text-sm font-medium text-black-main">
              Giá từ chủ nhà
            </div>
            <div className="flex-1 text-sm text-black-main">
              {DocTienBangChuV2(data?.priceOfOwner)}
            </div>
          </div>
        )}
      {(demand === 'sale' || demand === true) && (
        <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
          <div className="w-120px text-sm font-medium text-black-main">
            Giá theo DT đất
          </div>
          <div className="flex-1 text-sm text-black-main">
            {DocTienBangChuV2(parseInt(data?.price / data?.area)) + '/m²'}
          </div>
        </div>
      )}
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-5">
        <div className="w-132px text-sm font-medium text-black-main">
          Định giá của RT247
        </div>
        <div className="flex-1 text-sm text-black-main">
          {data?.tnccPrice || '-'}
        </div>
      </div>
      {data?.latestRenewInfo && (
        <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-5">
          <div className="w-132px text-sm font-medium text-black-main">
            Thời hạn đăng tin
          </div>
          <div className="flex-1 text-sm text-black-main">{`${moment
            .unix(parseInt(data?.latestRenewInfo?.startTime, 10))
            .format('HH:mm A, DD/MM/YYYY')} - ${moment
            .unix(parseInt(data?.latestRenewInfo?.endTime, 10))
            .format('HH:mm A, DD/MM/YYYY')} (${
            data?.remainingActivePost.includes('hết hạn')
              ? data?.remainingActivePost
              : `còn lại ${data?.remainingActivePost}`
          })`}</div>
        </div>
      )}
    </Section>
  );
}
