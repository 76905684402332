import React from 'react';

export const NeedApprovalContext = React.createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  demand: 'sell',
  setDemand: () => {},
  showModal: false,
  setShowModal: () => {},
  status: 'approved',
  setStatus: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
});

export const useNeedApprovalContext = () =>
  React.useContext(NeedApprovalContext);
