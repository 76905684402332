import React, { useState } from 'react';
import MyModalV2 from '@components/modalV2/myModalV2';
import MyButton from '@components/button';
import ChangeEmployeeLoginInfoModal from '../../../../../../../modals/changeEmployeeLoginInfoModal';
import ActionEmployeeModal from '../../../../../../../modals/actionEmployeeModal';
import { useNavigate } from 'react-router';
import PermissionModal from '../leftUserDetail/userPermission/modal/PermissionModal';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ME } from '@utils/graphql';
import { useGlobalContext } from '../../../../../../../context';
import { useManageEmployeeContext } from '../../../context';
import {
  LOCK_EMPLOYEE,
  UNLOCK_EMPLOYEE,
  REMOVE_EMPLOYEE,
} from '@utils/graphql/mutations/employee';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import pickBy from 'lodash/pickBy';
import moment from 'moment/moment';
import {
  GET_EMPLOYEE_DETAILS,
  LIST_EMPLOYEES,
} from '@utils/graphql/queries/user';

type Props = {
  setStatus?: Function,
  setShowModal?: Function,
  state?: any,
  userId?: string,
  tab?: string,
  employee?: Object,
};

export default function RightUserDetail(props: Props) {
  const { employee, userId, state } = props;
  const { isLogin } = useGlobalContext();
  const { data } = useQuery(GET_ME);
  const toast = useToast();
  const { setListSelected } = useManageEmployeeContext();
  const loggedInPermissions = [
    ...(data?.b2bGetMe?.payload?.productPermissions ?? []),
    ...(data?.b2bGetMe?.payload?.projectPermissions ?? []),
  ];
  const [showChangeEmpLoginModal, setShowChangeEmpLoginModal] = useState(false);
  const [showChangePermissionModal, setShowChangePermissionModal] =
    useState(false);
  const [showModalLockOrUnlockEmployee, setShowModalLockOrUnlockEmployee] =
    useState(false);
  const [showModalDeleteEmployee, setShowModalDeleteEmployee] = useState(false);
  const navigate = useNavigate();
  const variables = pickBy(
    {
      employeeCode: state?.employeeCode,
      fullName: state?.fullName,
      username: state?.username,
      phone: state?.phone,
      provinces: state?.province ? state?.province : null,
      districts: state?.district ? state?.district : null,
      email: state?.email,
      createdAt:
        !state?.createdAtFrom || !state?.createdAtTo
          ? null
          : {
              from: moment.unix(state?.createdAtFrom).format('YYYY-MM-DD'),
              to: moment
                .unix(state?.createdAtTo)
                .endOf('day')
                .format('YYYY-MM-DD'),
            },
    },
    e => e || e === false
  );
  const finalVariables = {
    data: variables,
    sort: '-createdAt',
    page: 1,
    limit: 10,
  };
  const [lockEmployee] = useMutation(LOCK_EMPLOYEE, {
    onCompleted: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="success"
            message="Khóa nhân viên thành công."
            closeButtonPos="center"
          />
        ),
      });
    },
    onError: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="error"
            message="Khóa nhân viên thất bại."
            closeButtonPos="center"
          />
        ),
      });
    },
    refetchQueries: [
      {
        query: LIST_EMPLOYEES,
        variables: finalVariables,
        skip: !isLogin,
      },
      {
        query: GET_EMPLOYEE_DETAILS,
        variables: {
          id: employee?.id,
        },
        skip: !employee?.id,
      },
    ],
    fetchPolicy: 'network-only',
  });
  const [unLockEmployee] = useMutation(UNLOCK_EMPLOYEE, {
    onCompleted: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="success"
            message="Khóa nhân viên thành công."
            closeButtonPos="center"
          />
        ),
      });
    },
    onError: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="error"
            message="Khóa nhân viên thất bại."
            closeButtonPos="center"
          />
        ),
      });
    },
    refetchQueries: [
      {
        query: LIST_EMPLOYEES,
        variables: finalVariables,
        skip: !isLogin,
      },
      {
        query: GET_EMPLOYEE_DETAILS,
        variables: {
          id: employee?.id,
        },
        skip: !employee?.id,
      },
    ],
    fetchPolicy: 'network-only',
  });
  const [removeEmployee] = useMutation(REMOVE_EMPLOYEE, {
    onCompleted: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="success"
            message="Khóa nhân viên thành công."
            closeButtonPos="center"
          />
        ),
      });
    },
    onError: async () => {
      await toast({
        position: 'bottom-right',
        duration: 3000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="error"
            message="Khóa nhân viên thất bại."
            closeButtonPos="center"
          />
        ),
      });
    },
    refetchQueries: [
      {
        query: LIST_EMPLOYEES,
        variables: finalVariables,
        skip: !isLogin,
      },
    ],
    fetchPolicy: 'network-only',
  });
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      <div className="space-y-4">
        {loggedInPermissions?.some(
          p => p.id === PermissionIds.EditEmployeeInfo
        ) && (
          <MyButton
            type="gray"
            label="Chỉnh sửa thông tin"
            onPress={() => navigate(`/employee/edit?id=${userId}&isEdit=true`)}
          />
        )}
        {loggedInPermissions?.some(
          p => p.id === PermissionIds.EditEmployeePermission
        ) && (
          <MyButton
            onPress={() => setShowChangePermissionModal(true)}
            type="gray"
            label={
              employee?.productPermissions?.length > 0 ||
              employee?.projectPermissions?.length > 0
                ? 'Chỉnh sửa quyền'
                : 'Phân quyền'
            }
          />
        )}
        {loggedInPermissions?.some(
          p => p.id === PermissionIds.EditEmployeeLoginInfo
        ) && (
          <MyButton
            onPress={() => setShowChangeEmpLoginModal(true)}
            type="gray"
            label="Chỉnh sửa thông tin </br> đăng nhập"
          />
        )}
        {loggedInPermissions?.some(
          p => p.id === PermissionIds.LockUnlockEmployee
        ) && (
          <MyButton
            onPress={() => setShowModalLockOrUnlockEmployee(true)}
            type="gray"
            label={employee?.isLocked ? 'Mở khóa nhân viên' : 'Khóa nhân viên'}
          />
        )}
        {loggedInPermissions?.some(
          p => p.id === PermissionIds.DeleteEmployee
        ) && (
          <MyButton
            type="gray"
            label="Xoá nhân viên"
            onPress={() => setShowModalDeleteEmployee(true)}
          />
        )}
      </div>
      <MyModalV2
        open={showModalLockOrUnlockEmployee}
        onClose={() => setShowModalLockOrUnlockEmployee(false)}
      >
        <ActionEmployeeModal
          header={employee?.isLocked ? 'Mở khóa nhân viên' : 'Khóa nhân viên'}
          question={
            employee?.isLocked
              ? 'Bạn có chắc muốn mở khóa nhân viên này?'
              : 'Bạn có chắc muốn khóa nhân viên này?'
          }
          content={
            employee?.isLocked
              ? 'Sau khi được mở khóa, người dùng sẽ có thể đăng nhập vào hệ thống.'
              : 'Sau khi bị khóa người dùng sẽ không thể đăng nhập vào hệ thống được nữa.'
          }
          onConfirm={async () => {
            !employee?.isLocked
              ? await lockEmployee({
                  variables: {
                    userIds: [employee.id],
                  },
                })
              : await unLockEmployee({
                  variables: {
                    userIds: [employee.id],
                  },
                });
            setShowModalLockOrUnlockEmployee(false);
          }}
          onClose={() => setShowModalLockOrUnlockEmployee(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showModalDeleteEmployee}
        onClose={() => setShowModalDeleteEmployee(false)}
      >
        <ActionEmployeeModal
          header="Xoá nhân viên"
          question="Bạn có chắc muốn xoá nhân viên này?"
          content="Sau khi bị xoá sản phẩm sẽ được lưu ở mục 
          “Nhân viên đã xoá”."
          onConfirm={async () => {
            await removeEmployee({
              variables: {
                userId: employee.id,
              },
            });
            setShowModalDeleteEmployee(false);
            setListSelected.set([]);
          }}
          onClose={() => setShowModalDeleteEmployee(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showChangeEmpLoginModal}
        onClose={() => setShowChangeEmpLoginModal(false)}
      >
        <ChangeEmployeeLoginInfoModal
          employee={employee}
          onClose={() => setShowChangeEmpLoginModal(false)}
        />
      </MyModalV2>
      <MyModalV2
        open={showChangePermissionModal}
        onClose={() => setShowChangePermissionModal(false)}
      >
        <PermissionModal
          isEdit={
            employee?.productPermissions?.length > 0 ||
            employee?.projectPermissions?.length > 0
          }
          employee={employee}
          onClose={() => setShowChangePermissionModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
