export const useFieldConfig = () => {
  return [
    {
      key: 'code',
      value: 'Mã nhóm quyền',
      isVisible: true,
    },
    {
      key: 'name',
      value: 'Tên nhóm quyền',
      isVisible: true,
    },
    {
      key: 'creator',
      value: 'Người tạo',
      isVisible: true,
    },
    {
      key: 'createdAt',
      value: 'Ngày tạo',
      isVisible: true,
    },
  ];
};
