import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { UPGRADE_B2B_CONTRACT } from '@utils/graphql/mutations/b2bContract';
import pickBy from 'lodash/pickBy';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
export const useUpgradeB2BContract = (
  onSuccess,
  tab,
  contractId,
  onCloseUpgrade,
  onCloseDetailModal
) => {
  const [upgradeContract, { loading, error, client }] =
    useMutation(UPGRADE_B2B_CONTRACT);
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async state => {
      try {
        delete state.system;
        await upgradeContract({
          variables: {
            contractId,
            data: pickBy(
              {
                ...state,
                discount: parseFloat(state?.discount),
                totalMember: parseInt(state?.totalMember),
                dueDate: parseInt(state?.dueDate),
                attachments: state?.attachments.map(item => item.fileId),
                contractDate: moment
                  .unix(state?.contractDate)
                  .format('YYYY-MM-DD'),
              },
              e => e || e === false
            ),
          },
        });
        await onCloseUpgrade();
        await onSuccess();
        await onCloseDetailModal();
        await resetQueries(['getListContracts'], client.cache);
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e?.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [upgradeContract, client, navigate]
  );
  return { onSubmit, loading, error };
};
