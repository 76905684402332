export const permissionsConfigs = [
  {
    id: 'manage-product',
    name: 'Cài đặt quyền Quản lý kho sản phẩm',
    configs: [
      {
        id: 'add-product',
        name: 'Thêm sản phẩm',
        configKey: 'isNeedToApprove',
        type: 'radio',
        options: [
          {
            key: true,
            value: 'Phòng ban duyệt bằng tay',
          },
          {
            key: false,
            value: 'Hệ thống tự động duyệt',
          },
        ],
        configs: {
          isNeedToApprove: false,
        },
      },
      {
        id: 'view-product-list',
        name: 'Xem sản phẩm trong kho',
        type: 'multiRadio',
        options: [
          {
            name: 'Loại nhu cầu được xem',
            id: 'view-product-list',
            type: 'radio',
            configKey: 'demandType',
            options: [
              {
                key: 'all',
                value: 'Xem tất cả nhu cầu',
              },
              {
                key: 'forSale',
                value: 'Bán',
              },
              {
                key: 'forRent',
                value: 'Cho thuê',
              },
            ],
            configs: {
              demandType: ['all'],
            },
          },
          {
            name: 'Loại BĐS được xem',
            id: 'view-product-list',
            type: 'radioWithSelection',
            onClickModal: 'selectRealEstateType',
            btnLabel: 'Chọn loại BĐS',
            configKey: 'realEstateType',
            options: [
              {
                key: 'all',
                value: 'Xem tất cả BĐS',
              },
              {
                value: 'Chọn loại bất động sản',
                key: 'other',
                hasSelection: true,
              },
            ],
            configs: {
              realEstateType: ['all'],
            },
          },
          {
            name: 'Trạng thái được xem',
            id: 'view-product-list',
            type: 'radioWithSelection',
            onClickModal: 'selectRealEstateStatus',
            btnLabel: 'Chọn trạng thái',
            configKey: 'status',
            options: [
              {
                key: 'all',
                value: 'Xem tất cả trạng thái',
              },
              {
                value: 'Chọn trạng thái được xem',
                key: 'other',
                hasSelection: true,
              },
            ],
            configs: {
              status: ['all'],
            },
          },
          {
            name: 'Khu vực được xem',
            id: 'view-product-list',
            type: 'radioWithSelection',
            onClickModal: 'selectRealEstateRegion',
            btnLabel: 'Chọn khu vực',
            configKey: 'region',
            options: [
              {
                key: 'all',
                value: 'Xem tất cả khu vực',
              },
              {
                value: 'Chọn khu vực được xem',
                key: 'other',
                hasSelection: true,
              },
            ],
            configs: {
              region: [
                {
                  type: 'Province',
                  id: 'all',
                },
                {
                  type: 'District',
                  id: 'all',
                },
              ],
            },
          },
          {
            name: 'Khoảng giá được xem',
            id: 'view-product-list',
            type: 'radioWithFromToInput',
            configKey: 'priceRange',
            options: [
              {
                key: 'unlimited',
                value: 'Xem tất cả khoảng giá',
              },
              {
                value: 'Nhập khoảng giá được xem',
                key: 'other',
                hasInput: true,
              },
            ],
            configs: {
              priceRange: { from: 0, to: Number.MAX_SAFE_INTEGER },
            },
          },
        ],
        configs: {
          demandType: ['all'],
          realEstateType: ['all'],
          status: ['all'],
          priceRange: { from: 0, to: Number.MAX_SAFE_INTEGER },
          region: [
            {
              type: 'Province',
              id: 'all',
            },
            {
              type: 'District',
              id: 'all',
            },
          ],
        },
      },
      // {
      //   id: 'view-product-attachments',
      //   name: 'Xem tài liệu đính kèm',
      //   subtitle: 'Số lần được xem tài liệu đính kèm',
      //   type: 'radioWithInput',
      //   configKey: 'count',
      //   options: [
      //     {
      //       key: 'unlimited',
      //       value: 'Không giới hạn số lần xem tài liệu đính kèm',
      //     },
      //     {
      //       key: 'other',
      //       value: 'Số lần được xem tài liệu tối đa',
      //       hasInput: true,
      //     },
      //   ],
      //   configs: {
      //     count: Number.MAX_SAFE_INTEGER,
      //   },
      // },
      // {
      //   id: 'view-product-legal-attachments',
      //   name: 'Xem hồ sơ pháp lý',
      //   subtitle: 'Số lần được xem hồ sơ pháp lý',
      //   type: 'radioWithInput',
      //   configKey: 'count',
      //   options: [
      //     {
      //       key: 'unlimited',
      //       value: 'Không giới hạn số lần xem hồ sơ pháp lý',
      //     },
      //     {
      //       key: 'other',
      //       value: 'Số lần được xem hồ sơ pháp lý tối đa',
      //       hasInput: true,
      //     },
      //   ],
      //   configs: {
      //     count: Number.MAX_SAFE_INTEGER,
      //   },
      // },
      {
        id: 'view-owner-phone-number.count',
        name: 'Xem số điện thoại chủ nhà',
        subtitle: 'Số lần được xem số điện thoại chủ nhà',
        type: 'radioWithInput',
        configKey: 'count',
        options: [
          {
            key: 'unlimited',
            value: 'Không giới hạn số lần được xem số điện thoại chủ nhà',
          },
          {
            key: 'other',
            value: 'Số lần được xem số điện thoại chủ nhà',
            hasInput: true,
          },
        ],
        configs: {
          count: Number.MAX_SAFE_INTEGER,
        },
      },
      {
        id: 'view-await-approving-product-list.departments',
        name: 'Xem sản phẩm đang chờ duyệt',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem sản phẩm đang chờ duyệt toàn công ty',
          },
          {
            key: 'owner',
            value: 'Xem sản phẩm chờ duyệt của chính tôi',
          },
          {
            key: 'other',
            value: 'Xem sản phẩm chờ duyệt của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
      {
        id: 'view-deleted-product-list.departments',
        name: 'Xem danh sách sản phẩm đã xóa',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem sản phẩm đã xóa của toàn công ty',
          },
          {
            key: 'owner',
            value: 'Xem sản phẩm tôi đã xóa',
          },
          {
            key: 'other',
            value: 'Xem sản phẩm đã xóa của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
      // {
      //   id: 'view-hidden-product-list.departments',
      //   name: 'Xem danh sách sản phẩm đã ẩn',
      //   type: 'radioWithSelection',
      //   onClickModal: 'selectDepartment',
      //   btnLabel: 'Chọn phòng ban',
      //   configKey: 'departments',
      //   options: [
      //     {
      //       key: 'all',
      //       value: 'Xem sản phẩm đã ẩn của toàn công ty',
      //     },
      //     {
      //       key: 'other',
      //       value: 'Xem sản phẩm đã ẩn của phòng ban',
      //       hasSelection: true,
      //     },
      //   ],
      //   configs: {
      //     departments: ['all'],
      //   },
      // },
      {
        id: 'view-published-product-list.departments',
        name: 'Xem danh sách sản phẩm đã đăng tin rao lên sàn',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem sản phẩm đã đăng tin rao lên sàn của toàn công ty',
          },
          {
            key: 'other',
            value: 'Xem sản phẩm đã đăng tin rao lên sàn của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
    ],
  },
  {
    id: 'manage-employee',
    name: 'Cài đặt quyền quản lý nhân viên',
    configs: [
      {
        id: 'add-employee.isNeedToApprove',
        name: 'Tạo nhân viên',
        type: 'radio',
        configKey: 'isNeedToApprove',
        options: [
          {
            key: true,
            value: 'Phòng ban duyệt bằng tay',
          },
          {
            key: false,
            value: 'Hệ thống tự động duyệt',
          },
        ],
        configs: {
          isNeedToApprove: false,
        },
      },
      {
        id: 'view-employee-list.departments',
        name: 'Xem nhân viên công ty',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem nhân viên toàn công ty',
          },
          {
            key: '',
            value: 'Xem nhân viên của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
      {
        id: 'view-deleted-employee-list.departments',
        name: 'Xem nhân viên đã xóa',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem nhân viên đã xóa toàn công ty',
          },
          {
            key: '',
            value: 'Xem nhân viên đã xóa của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
      {
        id: 'view-need-to-approve-employee-list.departments',
        name: 'Xem nhân viên cần duyệt',
        type: 'radioWithSelection',
        onClickModal: 'selectDepartment',
        btnLabel: 'Chọn phòng ban',
        configKey: 'departments',
        options: [
          {
            key: 'all',
            value: 'Xem nhân viên cần duyệt của toàn công ty',
          },
          {
            key: '',
            value: 'Xem nhân viên cần duyệt của phòng ban',
            hasSelection: true,
          },
        ],
        configs: {
          departments: ['all'],
        },
      },
    ],
  },
];

export const blankState = {
  'add-product': { isNeedToApprove: false },
  'view-product-list': {
    demandType: 'all',
    realEstateType: 'all',
    region: 'all',
    status: 'all',
  },
  'view-owner-phone-number': {
    count: 'unlimited',
  },
  'view-await-approving-product-list': {
    departments: 'owner',
  },
  'view-deleted-product-list': {
    departments: 'all',
  },
  'view-hidden-product-list': {
    departments: 'all',
  },
  'view-published-product-list': {
    departments: 'owner',
  },
  'add-employee': {
    isNeedToApprove: false,
  },
  'view-employee-list': {
    departments: 'all',
  },
  'view-deleted-employee-list': {
    departments: 'all',
  },
  'view-need-to-approve-employee-list': {
    departments: 'all',
  },
};

export const initialConfigs = {
  'manage-product': {
    id: 'manage-product',
    name: 'Cài đặt quyền Quản lý kho sản phẩm',
    configs: [],
  },
  'manage-employee': {
    id: 'manage-employee',
    name: 'Cài đặt quyền quản lý nhân viên',
    configs: [],
  },
};

export const allConfigs = [
  {
    id: 'add-employee',
    parent: 'manage-employee',
    name: 'Tạo nhân viên',
    type: 'radio',
    configKey: 'isNeedToApprove',
    options: [
      {
        key: true,
        value: 'Phòng ban duyệt bằng tay',
      },
      {
        key: false,
        value: 'Hệ thống tự động duyệt',
      },
    ],
    configs: {
      isNeedToApprove: false,
    },
  },
  {
    id: 'view-employee-list',
    parent: 'manage-employee',
    name: 'Xem nhân viên công ty',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem nhân viên toàn công ty',
      },
      {
        key: 'other',
        value: 'Xem nhân viên của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['all'],
    },
  },
  {
    id: 'view-deleted-employee-list',
    parent: 'manage-employee',
    name: 'Xem nhân viên đã xóa',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem nhân viên đã xóa toàn công ty',
      },
      {
        key: 'other',
        value: 'Xem nhân viên đã xóa của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['all'],
    },
  },
  {
    id: 'view-need-to-approve-employee-list',
    parent: 'manage-employee',
    name: 'Xem nhân viên cần duyệt',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem nhân viên cần duyệt của toàn công ty',
      },
      {
        key: 'other',
        value: 'Xem nhân viên cần duyệt của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['all'],
    },
  },
  {
    id: 'add-product',
    parent: 'manage-product',
    name: 'Thêm sản phẩm',
    configKey: 'isNeedToApprove',
    type: 'radio',
    options: [
      {
        key: true,
        value: 'Phòng ban duyệt bằng tay',
      },
      {
        key: false,
        value: 'Hệ thống tự động duyệt',
      },
    ],
    configs: {
      isNeedToApprove: false,
    },
  },
  {
    id: 'view-product-list',
    parent: 'manage-product',
    name: 'Xem sản phẩm trong kho',
    type: 'multiRadio',
    options: [
      {
        name: 'Loại nhu cầu được xem',
        id: 'view-product-list',
        type: 'radio',
        configKey: 'demandType',
        options: [
          {
            key: 'all',
            value: 'Xem tất cả nhu cầu',
          },
          {
            key: 'forSale',
            value: 'Bán',
          },
          {
            key: 'forRent',
            value: 'Cho thuê',
          },
        ],
        configs: {
          demandType: ['all'],
        },
      },
      {
        name: 'Loại BĐS được xem',
        id: 'view-product-list',
        type: 'radioWithSelection',
        onClickModal: 'selectRealEstateType',
        btnLabel: 'Chọn loại BĐS',
        configKey: 'realEstateType',
        options: [
          {
            key: 'all',
            value: 'Xem tất cả BĐS',
          },
          {
            value: 'Chọn loại bất động sản',
            key: 'other',
            hasSelection: true,
          },
        ],
        configs: {
          realEstateType: ['all'],
        },
      },
      {
        name: 'Trạng thái được xem',
        id: 'view-product-list',
        type: 'radioWithSelection',
        onClickModal: 'selectRealEstateStatus',
        btnLabel: 'Chọn trạng thái',
        configKey: 'status',
        options: [
          {
            key: 'all',
            value: 'Xem tất cả trạng thái',
          },
          {
            value: 'Chọn trạng thái được xem',
            key: 'other',
            hasSelection: true,
          },
        ],
        configs: {
          status: ['all'],
        },
      },
      {
        name: 'Khu vực được xem',
        id: 'view-product-list',
        type: 'radioWithSelection',
        onClickModal: 'selectRealEstateRegion',
        btnLabel: 'Chọn khu vực',
        configKey: 'region',
        options: [
          {
            key: 'all',
            value: 'Xem tất cả khu vực',
          },
          {
            value: 'Chọn khu vực được xem',
            key: 'other',
            hasSelection: true,
          },
        ],
        configs: {
          region: [
            {
              type: 'Province',
              id: 'all',
            },
            {
              type: 'District',
              id: 'all',
            },
          ],
        },
      },
      {
        name: 'Khoảng giá được xem',
        id: 'view-product-list',
        type: 'radioWithFromToInput',
        configKey: 'priceRange',
        options: [
          {
            key: 'unlimited',
            value: 'Xem tất cả khoảng giá',
          },
          {
            value: 'Nhập khoảng giá được xem',
            key: 'other',
            hasInput: true,
          },
        ],
        configs: {
          priceRange: { from: 0, to: Number.MAX_SAFE_INTEGER },
        },
      },
    ],
    configs: {
      demandType: ['all'],
      realEstateType: ['all'],
      status: ['all'],
      priceRange: { from: 0, to: Number.MAX_SAFE_INTEGER },
      region: [
        {
          type: 'Province',
          id: 'all',
        },
        {
          type: 'District',
          id: 'all',
        },
      ],
    },
  },
  {
    id: 'view-owner-phone-number',
    parent: 'manage-product',
    name: 'Xem số điện thoại chủ nhà',
    subtitle: 'Số lần được xem số điện thoại chủ nhà',
    type: 'radioWithInput',
    configKey: 'count',
    options: [
      {
        key: 'unlimited',
        value: 'Không giới hạn số lần được xem số điện thoại chủ nhà',
      },
      {
        key: 'other',
        value: 'Số lần được xem số điện thoại chủ nhà',
        hasInput: true,
      },
    ],
    configs: {
      count: Number.MAX_SAFE_INTEGER,
    },
  },
  {
    id: 'view-product-attachments',
    parent: 'manage-product',
    name: 'Xem tài liệu đính kèm',
    subtitle: 'Số lần được xem tài liệu đính kèm',
    type: 'radioWithInput',
    configKey: 'count',
    options: [
      {
        key: 'unlimited',
        value: 'Không giới hạn số lần xem tài liệu đính kèm',
      },
      {
        key: 'other',
        value: 'Số lần được xem tài liệu tối đa',
        hasInput: true,
      },
    ],
    configs: {
      count: Number.MAX_SAFE_INTEGER,
    },
  },
  {
    id: 'view-product-legal-attachments',
    parent: 'manage-product',
    name: 'Xem hồ sơ pháp lý',
    subtitle: 'Số lần được xem hồ sơ pháp lý',
    type: 'radioWithInput',
    configKey: 'count',
    options: [
      {
        key: 'unlimited',
        value: 'Không giới hạn số lần xem hồ sơ pháp lý',
      },
      {
        key: 'other',
        value: 'Số lần được xem hồ sơ pháp lý tối đa',
        hasInput: true,
      },
    ],
    configs: {
      count: Number.MAX_SAFE_INTEGER,
    },
  },
  {
    id: 'view-await-approving-product-list',
    parent: 'manage-product',
    name: 'Xem sản phẩm đang chờ duyệt',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem sản phẩm đang chờ duyệt toàn công ty',
      },
      {
        key: 'owner',
        value: 'Xem sản phẩm chờ duyệt của chính tôi',
      },
      {
        key: 'other',
        value: 'Xem sản phẩm chờ duyệt của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['owner'],
    },
  },
  {
    id: 'view-deleted-product-list',
    parent: 'manage-product',
    name: 'Xem danh sách sản phẩm đã xóa',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem sản phẩm đã xóa của toàn công ty',
      },
      {
        key: 'owner',
        value: 'Xem sản phẩm tôi đã xóa',
      },
      {
        key: 'other',
        value: 'Xem sản phẩm đã xóa của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['all'],
    },
  },
  // {
  //   id: 'view-hidden-product-list',
  //   parent: 'manage-product',
  //   name: 'Xem danh sách sản phẩm đã ẩn',
  //   type: 'radioWithDepartmentSelection',
  //   onClickModal: 'selectDepartment',
  //   btnLabel: 'Chọn phòng ban',
  //   configKey: 'departments',
  //   options: [
  //     {
  //       key: 'all',
  //       value: 'Xem sản phẩm đã ẩn của toàn công ty',
  //     },
  //     {
  //       key: 'other',
  //       value: 'Xem sản phẩm đã ẩn của phòng ban',
  //       hasSelection: true,
  //     },
  //   ],
  //   configs: {
  //     departments: ['all'],
  //   },
  // },
  {
    id: 'view-published-product-list',
    parent: 'manage-product',
    name: 'Xem danh sách sản phẩm đã đăng tin rao lên sàn',
    type: 'radioWithDepartmentSelection',
    onClickModal: 'selectDepartment',
    btnLabel: 'Chọn phòng ban',
    configKey: 'departments',
    options: [
      {
        key: 'all',
        value: 'Xem sản phẩm đã đăng tin rao lên sàn của toàn công ty',
      },
      {
        key: 'owner',
        value: 'Xem sản phẩm đã đăng tin rao lên sàn của chính tôi',
        hasSelection: true,
      },
      {
        key: 'other',
        value: 'Xem sản phẩm đã đăng tin rao lên sàn của phòng ban',
        hasSelection: true,
      },
    ],
    configs: {
      departments: ['owner'],
    },
  },
];

export const demandConfigs = [
  {
    key: ['all'],
    value: 'Xem tất cả nhu cầu',
  },
  {
    key: 'ForSale',
    value: 'Bán',
  },
  {
    key: 'ForRent',
    value: 'Cho thuê',
  },
];

export const configErrors = {
  'view-product-list': {
    types: 'Vui lòng chọn loại BĐS được xem',
    status: 'Vui lòng chọn trạng thái được xem',
    priceRange: 'Vui lòng nhập khoảng giá được xem',
    region: 'Vui lòng chọn khu vực được xem',
  },
  'view-owner-phone-number': {
    'view-owner-phone-number': 'Vui lòng nhập vào giá trị',
  },
  'view-product-attachments': {
    'view-product-attachments': 'Vui lòng nhập vào giá trị',
  },
  'view-product-legal-attachments': {
    'view-product-legal-attachments': 'Vui lòng nhập vào giá trị',
  },
};
