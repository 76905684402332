import gql from 'graphql-tag';
export const CHECK_DUPLICATED_REAL_ESTATES = gql`
  query checkDuplicatedRealEstates(
    $typeOfDemand: TypeOfDemandEnum
    $type: RealEstateTypeEnum
    $homeOwnerPhone: String!
    $province: String!
    $district: String!
    $ward: String!
    $street: String!
    $houseNumber: String!
  ) @api(name: b2bRealEstate) {
    checkDuplicatedRealEstates(
      typeOfDemand: $typeOfDemand
      type: $type
      homeOwnerPhone: $homeOwnerPhone
      province: $province
      district: $district
      ward: $ward
      street: $street
      houseNumber: $houseNumber
    ) {
      payload
    }
  }
`;
export const CREATE_REAL_ESTATE_PRODUCT = gql`
  mutation createRealEstateProduct(
    $clearanceArea: Float
    $type: RealEstateTypeEnum!
    $province: String!
    $district: String!
    $typeOfDemand: TypeOfDemandEnum!
    $status: String!
    $tradingStatus: CommonTradingStatusTypeEnum
    $commission: String
    $ward: String!
    $street: String!
    $houseNumber: String!
    $hasAlley: Boolean
    $alleyTurnCount: Int
    $timeForRent: Int
    $timeForRentUnit: String
    $alleyWidth: Float
    $isNewConstruction: Boolean
    $width: Float
    $length: Float
    $rear: Float
    $area: Float!
    $constructionArea: Float
    $floorCount: Int
    $bedroomCount: Int
    $bedRoomArea: Float
    $hotelStar: Int
    $hasSeaView: Boolean
    $villaView: [String]
    $buildingType: String
    $warehouseType: String
    $warehouseRoomCount: Int
    $roomArea: Float
    $hasMezzanine: Boolean
    $block: String
    $roomNumber: String
    $motelName: String
    $investor: String
    $onFloor: Int
    $shophouseField: String
    $price: Float!
    $priceUnit: String
    $roomCount: Int
    $toiletCount: Int
    $direction: String
    $project: String
    $privateNote: String
    $legals: [String]
    $legalMediaUrls: [String]
    $contactName: String!
    $contactPhones: [String]!
    $contactEmail: String
    $mediaUrls: [String]
    $attachments: [String]
    $career: String
    $map: MapInput
    $fixedUtilities: [String]
    $utilities: [String]
    $localUtilities: [String]
    $equipmentsAndServices: [String]
    $bathroomCount: Int
  ) @api(name: b2bRealEstate) {
    createRealEstateProduct(
      data: {
        clearanceArea: $clearanceArea
        type: $type
        province: $province
        district: $district
        typeOfDemand: $typeOfDemand
        status: $status
        commission: $commission
        tradingStatus: $tradingStatus
        ward: $ward
        street: $street
        houseNumber: $houseNumber
        hasAlley: $hasAlley
        alleyTurnCount: $alleyTurnCount
        timeForRent: $timeForRent
        timeForRentUnit: $timeForRentUnit
        alleyWidth: $alleyWidth
        isNewConstruction: $isNewConstruction
        width: $width
        length: $length
        rear: $rear
        area: $area
        constructionArea: $constructionArea
        floorCount: $floorCount
        bedRoomArea: $bedRoomArea
        hotelStar: $hotelStar
        hasSeaView: $hasSeaView
        villaView: $villaView
        buildingType: $buildingType
        warehouseType: $warehouseType
        warehouseRoomCount: $warehouseRoomCount
        roomArea: $roomArea
        hasMezzanine: $hasMezzanine
        block: $block
        roomNumber: $roomNumber
        motelName: $motelName
        investor: $investor
        onFloor: $onFloor
        shophouseField: $shophouseField
        price: $price
        priceUnit: $priceUnit
        bedroomCount: $bedroomCount
        roomCount: $roomCount
        toiletCount: $toiletCount
        direction: $direction
        project: $project
        privateNote: $privateNote
        legals: $legals
        legalMediaUrls: $legalMediaUrls
        contactName: $contactName
        contactPhones: $contactPhones
        contactEmail: $contactEmail
        mediaUrls: $mediaUrls
        attachments: $attachments
        career: $career
        map: $map
        fixedUtilities: $fixedUtilities
        utilities: $utilities
        localUtilities: $localUtilities
        equipmentsAndServices: $equipmentsAndServices
        bathroomCount: $bathroomCount
      }
    ) {
      id
    }
  }
`;
export const UPDATE_REAL_ESTATE_PRODUCT = gql`
  mutation updateRealEstateProduct(
    $realEstateId: ObjectID!
    $typeOfDemand: TypeOfDemandEnum
    $type: RealEstateTypeEnum
    $province: String
    $district: String
    $status: String
    $commission: String
    $ward: String
    $street: String
    $houseNumber: String
    $hasAlley: Boolean
    $alleyTurnCount: Int
    $timeForRent: Int
    $timeForRentUnit: String
    $alleyWidth: Float
    $isNewConstruction: Boolean
    $width: Float
    $length: Float
    $rear: Float
    $area: Float
    $floorCount: Int
    $bedroomCount: Int
    $bedRoomArea: Float
    $hotelStar: Int
    $hasSeaView: Boolean
    $villaView: [String]
    $buildingType: String
    $warehouseType: String
    $warehouseRoomCount: Int
    $roomArea: Float
    $hasMezzanine: Boolean
    $block: String
    $roomNumber: String
    $motelName: String
    $investor: String
    $onFloor: Int
    $shophouseField: String
    $price: Float
    $priceUnit: String
    $roomCount: Int
    $toiletCount: Int
    $direction: String
    $project: String
    $privateNote: String
    $legals: [String]
    $contactName: String
    $contactPhones: [String]
    $contactEmail: String
    $career: String
    $mediaUrls: [String]
    $map: MapInput
    $fixedUtilities: [String]
    $utilities: [String]
    $localUtilities: [String]
    $equipmentsAndServices: [String]
    $constructionArea: Float
  ) @api(name: b2bRealEstate) {
    updateRealEstateProduct(
      realEstateId: $realEstateId
      data: {
        type: $type
        province: $province
        district: $district
        typeOfDemand: $typeOfDemand
        status: $status
        commission: $commission
        ward: $ward
        street: $street
        houseNumber: $houseNumber
        hasAlley: $hasAlley
        alleyTurnCount: $alleyTurnCount
        timeForRent: $timeForRent
        timeForRentUnit: $timeForRentUnit
        alleyWidth: $alleyWidth
        isNewConstruction: $isNewConstruction
        width: $width
        length: $length
        rear: $rear
        area: $area
        floorCount: $floorCount
        bedroomCount: $bedroomCount
        bedRoomArea: $bedRoomArea
        hotelStar: $hotelStar
        hasSeaView: $hasSeaView
        villaView: $villaView
        buildingType: $buildingType
        warehouseType: $warehouseType
        warehouseRoomCount: $warehouseRoomCount
        roomArea: $roomArea
        hasMezzanine: $hasMezzanine
        block: $block
        roomNumber: $roomNumber
        motelName: $motelName
        investor: $investor
        onFloor: $onFloor
        shophouseField: $shophouseField
        price: $price
        priceUnit: $priceUnit
        roomCount: $roomCount
        toiletCount: $toiletCount
        direction: $direction
        project: $project
        privateNote: $privateNote
        legals: $legals
        contactName: $contactName
        contactPhones: $contactPhones
        contactEmail: $contactEmail
        career: $career
        mediaUrls: $mediaUrls
        map: $map
        fixedUtilities: $fixedUtilities
        utilities: $utilities
        localUtilities: $localUtilities
        equipmentsAndServices: $equipmentsAndServices
        constructionArea: $constructionArea
      }
    ) {
      id
      realEstateId
    }
  }
`;
export const DELETE_REAL_ESTATE_PRODUCT = gql`
  mutation deleteRealEstateProduct($realEstateIds: [String!]!)
  @api(name: b2bRealEstate) {
    deleteRealEstateProduct(realEstateIds: $realEstateIds) {
      success
      msg
      payload
    }
  }
`;
export const UPDATE_HIDDEN_STATUS_PRODUCT = gql`
  mutation updateHiddenStatusProduct(
    $realEstateIds: [String!]!
    $isHidden: Boolean
  ) @api(name: b2bRealEstate) {
    updateHiddenStatusProduct(
      realEstateIds: $realEstateIds
      isHidden: $isHidden
    ) {
      success
      msg
      payload
    }
  }
`;
export const RESTORE_DELETED_PRODUCTS = gql`
  mutation restoreDeletedProducts($realEstateIds: [String!]!)
  @api(name: b2bRealEstate) {
    restoreDeletedProducts(realEstateIds: $realEstateIds) {
      success
      msg
      payload
    }
  }
`;
export const APPROVE_OR_REJECT_REAL_ESTATE_PRODUCT = gql`
  mutation approveOrRejectRealEstateProduct(
    $productsIds: [ObjectID]
    $approveStatus: ApproveStatusInputType
  ) @api(name: b2bRealEstate) {
    approveOrRejectRealEstateProduct(
      productsIds: $productsIds
      approveStatus: $approveStatus
    ) {
      success
      msg
      payload
    }
  }
`;

export const PUBLISH_REAL_ESTATE_PRODUCT = gql`
  mutation publishRealEstateProductToCustomer($data: PublishRealEstateInputType)
  @api(name: b2bRealEstate) {
    publishRealEstateProductToCustomer(data: $data) {
      success
      msg
      payload
    }
  }
`;

export const PUBLISH_PREMIUM_REAL_ESTATE_PRODUCT = gql`
  mutation publishPremiumRealEstateToCustomer($data: PublishRealEstateInputType)
  @api(name: b2bRealEstate) {
    publishPremiumRealEstateToCustomer(data: $data) {
      success
      msg
      payload
    }
  }
`;

export const CHANGE_PRODUCT_STATUS = gql`
  mutation changeStatusOfProduct(
    $realEstateId: String!
    $status: String!
    $depositWaitingTime: Int
    $shareToUsers: [String]
  ) @api(name: b2bRealEstate) {
    changeStatusOfProduct(
      realEstateId: $realEstateId
      status: $status
      depositWaitingTime: $depositWaitingTime
      shareToUsers: $shareToUsers
    ) {
      success
      msg
      payload
    }
  }
`;

export const UPDATE_PREMIUM_REAL_ESTATES = gql`
  mutation updatePublishedPremiumRealEstateToCustomer(
    $realEstateId: ObjectID!
    $data: PublishRealEstateInputType
  ) @api(name: b2bRealEstate) {
    updatePublishedPremiumRealEstateToCustomer(
      realEstateId: $realEstateId
      data: $data
    ) {
      msg
    }
  }
`;
