import MyDropdown from '@components/myDropdown';
import styles from '@components/table/table.module.scss';
import React from 'react';
import MultiCheckDropdownComponent from '../../../manageProduct/filterDropdownComponent/multiCheckDropdownComponent';
import { useGetMe } from '@utils/hooks/common/useGetMe';

type Props = {
  value?: string,
  setValue?: Function,
  selections?: Array,
  search?: string,
  setSearch?: Function,
  title?: string,
};

export default function ProductCodeDropdown({
  value = '',
  setValue = () => {},
  selections = [],
  search,
  setSearch,
  title = 'Trạng thái sản phẩm',
}: Props) {
  const { permissions } = useGetMe();
  const userPermissionConfig = permissions?.find(
    e => e.id === 'view-product-list'
  )?.configs?.status;
  const newSelections = userPermissionConfig?.includes('all')
    ? selections
    : selections?.filter(s => userPermissionConfig?.includes(s.key));
  return (
    <MyDropdown
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={MultiCheckDropdownComponent}
      dropdownContext={{
        selections: newSelections,
        value,
        setValue,
        search,
        setSearch,
      }}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              {title}
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
