import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@components/checkbox';

type Props = {
  title?: string,
  name?: string,
  configs?: Array,
};
export default function Utilities({ title, name, configs }: Props) {
  const { watch, setValue } = useFormContext();
  const stateName = watch(name) || [];
  const onCheck = useCallback(
    (nextKey, isChecked) => {
      if (isChecked) {
        setValue(name, [...stateName?.filter(e => e !== nextKey)]);
      } else {
        setValue(name, [...(stateName || []), nextKey]);
      }
    },
    [setValue, stateName]
  );
  return (
    <div
      style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
      className="w-full bg-white rounded-lg p-3"
    >
      <div className="text-base text-blue-main font-medium">
        {title} ({stateName?.length || 0}/{configs?.length})
      </div>
      <div className="grid-cols-2 grid gap-3 mt-7 flex">
        {configs.map((item, index) => {
          const isChecked = stateName?.find(e => e === item.key);
          return (
            <Checkbox
              key={index}
              title={item.value}
              isChecked={isChecked}
              onPress={() => onCheck(item.key, isChecked)}
            />
          );
        })}
      </div>
    </div>
  );
}
