import MyModalV2 from '@components/modalV2/myModalV2';
import RealEstateLocationOnMap from '@components/realEstateLocationOnMap';

type Props = {
  open?: boolean,
  onClose?: Function,
  lat?: number,
  long?: number,
};
export default function RealEstateLocationOnMapModal({
  open,
  onClose,
  lat,
  long,
}: Props) {
  return (
    <MyModalV2 open={open} onClose={onClose}>
      <div className="w-600px">
        <RealEstateLocationOnMap lat={lat} long={long} />
      </div>
    </MyModalV2>
  );
}
