import styles from '@components/table/table.module.scss';
import React from 'react';
import needApprovedStyle from '../../../manageRealEstate/needApproval/components/needApproval.module.scss';
import { useManageProjectContext } from '../context';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { merge2Array } from '@utils/helper';
import moment from 'moment';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';

type RenderTagProps = {
  value?: any,
  removeValue?: Function,
};

const getValueSlider = ({
  keyValue,
  from,
  to,
  min,
  max,
  listSelections,
  showPlus,
  renderToValue: toValue,
  exceptValue,
  unit,
}) => {
  let finalValue = '';
  const tempUnit = listSelections?.find(e => e.key === keyValue)?.unit || unit;
  const renderUnit =
    to > max && showPlus ? '+ ' + ' ' + tempUnit : ' ' + tempUnit;
  const renderToValue = to > toValue ? toValue : to;
  const renderValue = keyValue
    ? listSelections?.find(e => e.key === keyValue)?.value
    : from + ' - ' + renderToValue + renderUnit;
  if ((!from && !to) || (from === min && to >= max)) {
    finalValue = 'Tất cả';
  } else {
    finalValue = exceptValue?.length > 0 ? exceptValue : renderValue;
  }
  return finalValue;
};

export function RenderTag({ value, removeValue }: RenderTagProps) {
  const { setListSelected } = useManageProjectContext();
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div className={needApprovedStyle.tagFilterResult}>
      <div className="text-13 truncate text-black-main">
        {Array.isArray(value) ? value.join(', ') : value}
      </div>
      {removeValue && (
        <img
          onClick={() => {
            setListSelected?.clear();
            removeValue();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  );
}

export default function RenderResultFilterHeader() {
  const { projectForRentTypes, projectForSaleTypes } = configsRemote;
  const projectTypeConfigs = merge2Array(
    projectForRentTypes,
    projectForSaleTypes
  );
  const { priceToBuyV2, projectStatusType } =
    useGetInternalRealEstateConfigurations();
  const convertPrice =
    priceToBuyV2?.map(e => {
      return {
        from: e?.from,
        to: !e?.to ? 101 : e?.to,
        value: e?.value,
        key: e?.key,
      };
    }) || [];
  const { stateFilter, setStateFilterAt } = useManageProjectContext();
  const {
    projectCode,
    projectStatus,
    projectName,
    projectType,
    streetName,
    wardName,
    districtName,
    provinceName,
    houseNumber,
    startDay,
    endDay,
    creator,
    priceFrom,
    priceTo,
    priceKey,
  } = stateFilter;
  const configProjectTypeName = projectStatus?.map(e => {
    return projectStatusType?.find(item => item.key === e)?.value;
  });
  const configProjectType = projectType?.map(e => {
    return projectTypeConfigs?.find(item => item.key === e)?.value;
  });
  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div className={`${styles.headContainer} w-202px items-center`}>
          <RenderTag
            value={projectCode}
            removeValue={() => setStateFilterAt('projectCode')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-180px items-center`}>
          <RenderTag
            value={configProjectTypeName}
            removeValue={() => setStateFilterAt('projectStatus')(['onSale'])}
          />
        </div>
        <div className={`${styles.headContainer} w-250px items-center`}>
          <RenderTag
            value={projectName}
            removeValue={() => setStateFilterAt('projectName')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-210px items-center`}>
          <RenderTag
            value={configProjectType}
            removeValue={() => setStateFilterAt('projectType')([])}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={houseNumber}
            removeValue={() => setStateFilterAt('houseNumber')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={streetName}
            removeValue={() => {
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={wardName}
            removeValue={() => {
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={districtName}
            removeValue={() => {
              setStateFilterAt('district')('');
              setStateFilterAt('districtName')('');
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={provinceName}
            removeValue={() => {
              setStateFilterAt('province')('');
              setStateFilterAt('provinceName')('');
              setStateFilterAt('district')('');
              setStateFilterAt('districtName')('');
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={
              startDay && endDay
                ? `${moment.unix(startDay).format('DD/MM/YYYY')} - ${moment
                    .unix(endDay)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('startDay')('');
              setStateFilterAt('endDay')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-200px items-center`}>
          <RenderTag
            value={creator}
            removeValue={() => setStateFilterAt('creator')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-154px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: priceKey,
              from: priceFrom,
              to: priceTo,
              min: 0,
              max: 50,
              renderToValue: 100,
              exceptValue:
                (priceFrom === 0 || !priceFrom) && priceTo === 10
                  ? '<= 10 tỷ'
                  : priceFrom === 100 && priceTo === 101
                  ? '>= 100 tỷ'
                  : '',
              listSelections: convertPrice,
              unit: 'tỷ',
            })}
            removeValue={() => {
              setStateFilterAt('priceKey')('');
              setStateFilterAt('priceFrom')('');
              setStateFilterAt('priceTo')('');
            }}
          />
        </div>
      </div>
    </div>
  );
}
