import SelectionComponentFilter from '@components/filterDropdownComponentGroup/selectionComponentFilter';

import MyDropdown from '@components/myDropdown';
import React from 'react';

type Props = {
  selections?: Array,
  value?: string,
  setValue?: Function,
  isShowSearchBox?: boolean,
  getObject?: boolean,
  loading?: boolean,
  onDelete?: Function,
  isHideDelete?: boolean,
  invalid?: boolean,
  disabled?: boolean,
  errorMessage?: string,
  dropdownClassName?: string,
  placeholder?: string,
  hasItemRadio?: boolean,
  zIndex?: number,
};
export default function DropdownSelection({
  selections,
  value,
  setValue,
  isShowSearchBox,
  loading,
  getObject,
  onDelete,
  isHideDelete,
  invalid,
  disabled,
  errorMessage,
  dropdownClassName,
  placeholder,
  hasItemRadio,
  zIndex,
}: Props) {
  return (
    <div className={`w-full relative space-y-2 ${dropdownClassName}`}>
      <MyDropdown
        zIndex={zIndex}
        disabled={disabled}
        wrapperClass="w-full"
        buttonDropdownClassName={`w-full h-40px p-2 flex justify-between items-center rounded-lg border  ${
          disabled ? 'opacity-50' : undefined
        } ${
          invalid || errorMessage?.length > 0
            ? 'border-red-400'
            : 'border-blue-main'
        }`}
        DropdownComponent={SelectionComponentFilter}
        dropdownContext={{
          selections,
          value,
          setValue,
          isShowSearchBox,
          loading,
          getObject,
          hasItemRadio,
        }}
      >
        {showDropdown => {
          return (
            <>
              <div className={`text-sm text-black-main truncate`}>
                {selections?.find(e => e.key === value)?.value || placeholder}
              </div>
              <div className="flex flex-row items-center space-x-2">
                {value && !isHideDelete && onDelete && (
                  <div
                    onClick={!disabled ? onDelete : () => {}}
                    className="w-20px h-20px cursor-pointer"
                  >
                    <img
                      className="w-full h-full ml-1"
                      alt="close"
                      src="/svg/ico-close.svg"
                    />
                  </div>
                )}
                <div className="w-1px h-24px bg-gray-300" />
                <img
                  src="/svg/arrowDown.svg"
                  alt="arrow"
                  className={`w-12px h-10px ${
                    showDropdown
                      ? 'transform rotate-180 duration-300'
                      : 'duration-300'
                  }`}
                />
              </div>
            </>
          );
        }}
      </MyDropdown>
      {!!errorMessage?.length && (
        <div className="text-sm text-red-400">{errorMessage}</div>
      )}
    </div>
  );
}
