import { useQuery } from '@apollo/client';
import { GET_PREMIUM_REAL_ESTATES } from '@utils/graphql';
import { useGlobalContext } from '../../../context';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { useState } from 'react';
import { cleanObj } from '@utils/helper';
import { useGetLocation } from '../../../scenes/home/manageProduct/configs';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { useGetMe } from '../common/useGetMe';
import { PAGINATION_LIMIT } from 'constant';

export const pickByFromTo = (from, to) => {
  let floatFrom,
    floatTo,
    t = {};
  if (from !== null && to !== 0) {
    floatFrom = parseFloat(from);
    floatTo = parseFloat(to);

    t = pickBy(
      { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
      e => e
    );
  } else {
    t = { from: null, to: 0 };
  }
  return isEmpty(t) ? null : t;
};
const getPriceFromToValue = (from, to, isForSale) => {
  const amount = isForSale === 'sale' ? 1000000000 : 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};

// export const getVariables = () => {
//   const finalVariables = {
//     limit: 10,
//     page: 1,
//   };
//   return { ...cleanObj(finalVariables) };
// };
export const getVariable = state => {
  const type = state?.isForSell ? 'sale' : 'rent';
  const finalVariables = {
    searchString: state?.searchString,
    realEstateId: state?.realEstateId,
    typeOfDemand: state?.isForSell,
    realEstateType: state?.realEstateType,
    province: state?.province ? state?.province : null,
    district: state?.district ? state?.district : null,
    ward: state?.ward ? state?.ward : null,
    street: state?.street ? state?.street : null,
    houseNumber: state?.houseNumber,
    createdAt:
      !state.createdAtFrom || !state.createdAtTo
        ? null
        : {
            from: moment.unix(state.createdAtFrom).format(),
            to: moment.unix(state.createdAtTo).endOf('day').format(),
          },
    updatedAt:
      !state.updatedAtFrom || !state.updatedAtTo
        ? null
        : {
            from: moment.unix(state.updatedAtFrom).format(),
            to: moment.unix(state.updatedAtTo).endOf('day').format(),
          },
    price: getPriceFromToValue(
      state?.priceFrom,
      (state?.priceTo > 100 && type) || (state?.priceTo > 200 && !type)
        ? null
        : state?.priceTo,
      type
    ),
    contactName: state?.contactName,
    floorCount: pickByFromTo(
      state?.floorCountFrom,
      state?.floorCountTo > 168 ? null : state?.floorCountTo
    ),
    width: pickByFromTo(
      state?.widthFrom,
      state?.widthTo > 100 ? null : state?.widthTo
    ),
    length: pickByFromTo(
      state?.lengthFrom,
      state?.lengthTo > 500 ? null : state?.lengthTo
    ),
    rear: pickByFromTo(
      state?.rearFrom,
      state?.rearTo > 100 ? null : state?.rearTo
    ),
    area: pickByFromTo(
      state?.areaFrom,
      state?.areaTo > 20000 ? null : state?.areaTo
    ),
    approvedAt:
      !state.approvedAtFrom || !state.approvedAtTo
        ? null
        : {
            from: moment.unix(state.approvedAtFrom).format(),
            to: moment.unix(state.approvedAtTo).endOf('day').format(),
          },
    approvedBy: state?.approvedBy ? state?.approvedBy : null,
    creatorName: state?.creator ? state?.creator : null,
    sort: '-approvedAt',
    approvalStatus: 'approved',
    page: 1,
    limit: PAGINATION_LIMIT,
  };
  return { ...cleanObj(finalVariables) };
};
export const useGetPremiumRealEstates = ({ state }) => {
  const { company } = useGetMe();
  const [loading, setLoading] = useState(false);
  const variables = getVariable(state);
  const { isTabProductPremium } = useGetLocation();
  const { isLogin } = useGlobalContext();
  const { data, fetchMore } = useQuery(GET_PREMIUM_REAL_ESTATES, {
    variables: {
      ...variables,
      company,
    },
    skip: !isLogin || !isTabProductPremium,
  });
  const dataPremium = data?.getPremiumRealEstates?.items || [];
  const currentPage = data?.getPremiumRealEstates?.page || 1;
  const totalItem = data?.getPremiumRealEstates?.total;
  const totalPage = data?.getPremiumRealEstates?.totalPages;
  const rowsPerPage = data?.getPremiumRealEstates?.limit;
  const currentCountFrom = data?.getPremiumRealEstates?.pagingCounter;
  const currentCountTo = currentCountFrom + dataPremium.length - 1;
  const hasPrevPage = data?.getPremiumRealEstates?.hasPrevPage;
  const hasNextPage = data?.getPremiumRealEstates?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  return {
    dataPremium,
    loading,
    onLoadByPagination,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
