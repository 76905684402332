import MyButton from '@components/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCreateB2BRealEstate } from '@utils/hooks/createB2BRealEstate/useCreateB2BRealEstate';
import { useFormContext } from 'react-hook-form';
import CreateRealEstateSuccessModal from '@components/createRealEstateSuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { blankState } from '../configs';
import { useLazyQuery } from '@apollo/client';
import { CHECK_DUPLICATED_REAL_ESTATES } from '@utils/graphql/mutations/realEstateB2B';
import { isEqual } from 'lodash';
import { useUpdateB2BRealEstate } from '@utils/hooks/createB2BRealEstate/useUpdateB2BRealEstate';
import ConfirmModal from '../../../../modals/confirmModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useFilterState } from '@utils/hooks/createB2BRealEstate/useFilterState';
import { useSetFormState } from '@utils/hooks/createB2BRealEstate/useSetFormState';

type Props = {
  isEdit?: boolean,
  id?: string,
};

const checkDuplicatedVariables = state => {
  return {
    homeOwnerPhone: state?.contactPhones[0]?.phone || '',
    province: state.province,
    district: state.district,
    ward: state.ward,
    street: state.street,
    houseNumber: state.houseNumber,
  };
};

export default function RenderBottom({ isEdit = false, id }: Props) {
  const { setValue, handleSubmit, watch, reset } = useFormContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isStep1 = location.pathname.includes('check-product');
  const isCompleted = watch('isCompleted');
  const isChecked = watch('isChecked');
  const province = watch('province');
  const district = watch('district');
  const ward = watch('ward');
  const street = watch('street');
  const houseNumber = watch('houseNumber');
  const phone = watch('contactPhones');
  const total = watch('total');
  const canContinue = watch('canContinue');
  const attachments = watch('attachments');
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { onSubmit, loading } = useCreateB2BRealEstate(setValue, setShowModal);
  const { onSubmit: onUpdate, loading: updateLoading } = useUpdateB2BRealEstate(
    setShowModal,
    id,
    attachments
  );
  const { initialValue } = useSetFormState(id, reset);
  const finalFilterState = useFilterState(watch());
  const finalFilterBlankState = useFilterState(blankState);

  const shouldShowModal = isEdit
    ? !isEqual(finalFilterState, initialValue)
    : !isEqual(finalFilterState, finalFilterBlankState);
  const localVariables = checkDuplicatedVariables(watch());
  const valueCheckDuplicateRef = useRef({
    province: blankState.province,
    district: blankState.district,
    ward: blankState.ward,
    street: blankState.street,
    houseNumber: blankState.houseNumber,
    phone: blankState.contactPhones[0]?.phone || '',
  });
  const [onCheckRemote] = useLazyQuery(CHECK_DUPLICATED_REAL_ESTATES, {
    onCompleted: data => {
      const totalItem = data?.checkDuplicatedRealEstates?.payload?.total;
      const canContinue =
        data?.checkDuplicatedRealEstates?.payload?.canContinue;
      setValue('total', totalItem);
      setValue('isChecked', true);
      setValue('canContinue', canContinue);
      valueCheckDuplicateRef.current = {
        province,
        district,
        ward,
        street,
        houseNumber,
        phone: phone?.[0]?.phone,
      };
    },
    fetchPolicy: 'network-only',
  });

  const onNext = useCallback(() => {
    if (
      (isChecked && total > 0 && canContinue) ||
      (!isChecked && !canContinue && !total)
    ) {
      setValue('isCompleted', true);
      navigate('/createB2BRealEstate/add-product-information');
    }
  }, [isChecked, total, canContinue]);
  const onCheckPressed = useCallback(async () => {
    valueCheckDuplicateRef.current = {
      province,
      district,
      ward,
      street,
      houseNumber,
      phone: phone?.[0]?.phone,
    };
    if ((isStep1 && !isChecked) || (isChecked && total > 0 && !canContinue)) {
      try {
        const { data } = await onCheckRemote({
          variables: {
            ...localVariables,
          },
        });
        const totalItem = data?.checkDuplicatedRealEstates?.payload?.total;
        const canContinue =
          data?.checkDuplicatedRealEstates?.payload?.canContinue;
        if (data) {
          setValue('total', totalItem);
          setValue('isChecked', true);
          setValue('canContinue', canContinue);
        }
        valueCheckDuplicateRef.current = {
          province,
          district,
          ward,
          street,
          houseNumber,
          phone: phone?.[0]?.phone,
        };
      } catch (e) {
        console.log(e);
      }
    } else {
      onNext();
    }
  }, [
    isStep1,
    isChecked,
    canContinue,
    onCheckRemote,
    localVariables,
    province,
    district,
    ward,
    street,
    houseNumber,
    phone,
    valueCheckDuplicateRef,
    setValue,
  ]);
  const onContinuePressed = useCallback(() => {
    reset(blankState);
    onBackPressed();
  }, [reset]);
  const onCancelPressed = useCallback(() => {
    if (shouldShowModal) {
      setShowWarningModal(true);
    } else {
      navigate('/manageProduct/product');
    }
  }, [navigate, shouldShowModal, setShowWarningModal]);
  const onBackPressed = useCallback(() => {
    navigate(-1);
    setValue('isCompleted', false);
    setValue('isChecked', false);
    setValue('total', undefined);
    setValue('canContinue', undefined);
  }, [setValue]);
  const isChangeState = !isEqual(
    {
      province,
      district,
      ward,
      street,
      houseNumber,
      phone: phone?.[0]?.phone,
    },
    valueCheckDuplicateRef.current
  );
  useEffect(() => {
    if (isChecked && total === 0 && canContinue) {
      setValue('isCompleted', true);
      navigate('/createB2BRealEstate/add-product-information');
    }
  }, [isChecked, canContinue, total]);
  useEffect(() => {
    if (isChangeState && canContinue) {
      setValue('total', undefined);
      setValue('isChecked', false);
      setValue('canContinue', undefined);
    }
  }, [isChangeState, canContinue]);
  return (
    <div className="w-full py-2.5 bg-white sticky bottom-0 border-t border-gray-300 flex">
      <div className="flex-1">
        {!isStep1 ? (
          <div className="w-147px">
            <MyButton onPress={onCancelPressed} type="gray" label="Hủy" />
          </div>
        ) : total > 0 ? (
          <div className="w-147px">
            <MyButton
              onPress={onCheckPressed}
              type="gray"
              label="Kiểm tra lại"
            />
          </div>
        ) : null}
      </div>
      <div className="flex-1 flex justify-end space-x-4">
        {isCompleted && !isStep1 && (
          <div className="w-147px">
            <MyButton type="gray" onPress={onBackPressed} label="Trở lại" />
          </div>
        )}
        {!isStep1 && (
          <div className="w-147px">
            <MyButton
              disabled={isEdit && !shouldShowModal}
              onPress={handleSubmit(isEdit ? onUpdate : onSubmit)}
              label={
                loading || updateLoading
                  ? 'Đang xử lý'
                  : isEdit
                  ? 'Cập nhật sản phẩm'
                  : 'Thêm sản phẩm'
              }
            />
          </div>
        )}
        {isStep1 && (
          <div className="w-147px">
            <MyButton
              disabled={total > 0 && !canContinue}
              onPress={handleSubmit(onCheckPressed)}
              label={isStep1 && !isChecked ? 'Kiểm tra' : 'Tiếp tục'}
            />
          </div>
        )}
      </div>
      <CreateRealEstateSuccessModal
        onClose={() => setShowModal(false)}
        open={showModal}
        isEdit={isEdit}
        onContinue={onContinuePressed}
      />
      <MyModalV2
        hideClose
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
      >
        <ConfirmModal
          question="Bạn có chắc muốn thoát?"
          contentTop="Bạn đang soạn dang dở tin rao này, bạn có<br/>
chắc chắn muốn thoát?"
          onBack={() => navigate(-1)}
          onContinue={() => setShowWarningModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
