import UserAvatar from './userAvatar';
import Section from '../../../../../../manageRealEstate/realEstateDetail/components/leftRealEstateDetail/contentDetailRealEstate/section';

import OtherInfo from './otherInfo';
import ItemRowUserDetail from './itemRowUserDetail';
import moment from 'moment/moment';
import RenderIdImage from './renderIdImage';
import AttachmentsTable from './attachments';
import React from 'react';
import AccountInfo from './accountInfo';

type Props = {
  adminData?: Object,
};
export default function RenderAdminInfo({ adminData }: Props) {
  return (
    <div className="flex-1 flex flex-row space-x-4">
      <div className="w-250px md:w-323px flex flex-col space-y-4">
        <UserAvatar
          title="Ảnh đại diện người quản trị"
          titleStyle={{ fontSize: 13 }}
          avatarUrl={adminData?.avatar?.originalUrl || '/svg/male.svg'}
        />
        <AccountInfo data={adminData?.username} />
        <OtherInfo data={adminData?.creator} createdAt={adminData?.createdAt} />
      </div>
      <Section
        source="/svg/book-icon.svg"
        title="Thông tin người quản trị"
        wrapperClass="flex-1 border border-blue-main p-4 rounded-lg"
      >
        <ItemRowUserDetail title="Tên người dùng" value={adminData?.fullName} />
        <ItemRowUserDetail title="Số điện thoại 1" value={adminData?.phone} />
        <ItemRowUserDetail
          title="Số điện thoại 2"
          value={adminData?.secondPhone}
        />
        <ItemRowUserDetail title="Email" value={adminData?.email} />
        <ItemRowUserDetail
          title="Giới tính"
          value={adminData?.gender === 'male' ? 'Nam' : 'Nữ'}
        />
        <ItemRowUserDetail
          title="Ngày sinh"
          value={
            adminData?.dateOfBirth
              ? moment(adminData?.dateOfBirth).format('DD/MM/YYYY')
              : 'Không có'
          }
        />
        <ItemRowUserDetail title="Số CMND" value={adminData?.idNumber} />
        {adminData?.idImages?.length > 0 && (
          <RenderIdImage
            idImages={adminData?.idImages}
            wrapperClassName="mt-2"
          />
        )}
        {adminData?.attachments?.length > 0 && (
          <div className="mt-7 space-y-4 flex-1">
            <div className="text-sm font-medium text-black-main">
              Tài liệu đính kèm
            </div>
            <AttachmentsTable attachments={adminData?.attachments} />
          </div>
        )}
      </Section>
    </div>
  );
}
