import MyDropdown from '@components/myDropdown';
import React from 'react';
import styles from '@components/table/table.module.scss';
import SelectionComponentFilter from '@components/filterDropdownComponentGroup/selectionComponentFilter';
import { useQuery } from '@apollo/client';
import { convertDataSelections } from '@utils/helper';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@utils/graphql';

type Props = {
  value?: string,
  setValue?: Function,
  selections?: Array,
  provinceId?: string,
};
export default function DistrictDropdownWithoutPermission({
  value = '',
  setValue = () => {},
  provinceId,
}: Props) {
  const { data, loading } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
    variables: {
      provinceId,
      limit: 100,
    },
    skip: !provinceId,
  });
  const rawData = [...(data?.getListDistrictsOfProvince?.districts || [])];
  const dataSelections = convertDataSelections(rawData);
  return (
    <MyDropdown
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={SelectionComponentFilter}
      dropdownContext={{
        selections: dataSelections,
        value,
        setValue,
        isShowSearchBox: true,
        loading,
        getObject: true,
      }}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Quận/ Huyện
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
