import React, { useState } from 'react';
import MyButton from '@components/button';
import Radio from '@components/radio';
import ReactSliderComponent from '@components/reactSlider';
import MyDropdown from '@components/myDropdown';
import styles from '@components/table/table.module.scss';

type SelectionProps = {
  close?: Function,
  show?: boolean,
  context?: Object,
};
type Props = {
  listSelections?: Array,
  to?: any,
  from?: any,
  unit?: string,
  min?: number,
  max?: number,
  minDistance?: number,
  onSelectItem?: Function,
  additionalItems?: Array,
  placeholder?: string,
  keyValue?: string,
  step?: number,
  theValueIsShow?: string,
  stepRenderLine?: number,
  sliderTitle?: string,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
  renderToValue?: number,
  dropdownButtonTitle?: string,
};
export default function FromToSlider(props: Props) {
  const {
    listSelections = [],
    onSelectItem,
    keyValue,
    to,
    from,
    unit,
    min,
    max,
    step,
    additionalItems = [],
    theValueIsShow,
    stepRenderLine,
    sliderTitle,
    progress,
    isLong,
    showPlus = true,
    dropdownButtonTitle,
  } = props;
  const selections = [...(additionalItems || []), ...(listSelections || [])],
    selectedValue = selections.find(e => e.from === from && e.to === to);
  return (
    <MyDropdown
      DropdownComponent={SliderComponent}
      dropdownContext={{
        from,
        to,
        key: keyValue,
        unit,
        min,
        max,
        step,
        listSelections: selections,
        onSelectItem: item => onSelectItem(item),
        selectedValue,
        theValueIsShow,
        stepRenderLine,
        title: sliderTitle,
        progress,
        isLong,
        showPlus,
      }}
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              {dropdownButtonTitle}
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
export function SliderComponent(props: SelectionProps) {
  const { close } = props,
    {
      listSelections,
      from,
      to,
      unit,
      onSelectItem,
      min = 0,
      max,
      step,
      theValueIsShow,
      stepRenderLine,
      title,
      progress,
      isLong,
      showPlus,
    } = props.context;
  const [initialFrom, setInitialFrom] = useState(from);
  const [initialTo, setInitialTo] = useState(to);
  return (
    <div
      className="rounded-lg bg-white overflow-hidden border border-blue-main table table-fixed"
      style={{
        minWidth: 300,
        maxHeight: 300,
      }}
    >
      <div
        style={{ fontSize: 11, marginLeft: 16, marginTop: 12 }}
        className="font-medium"
      >
        {title}
      </div>
      <div className="p-4">
        <div className="mt-6">
          <ReactSliderComponent
            showPlus={showPlus}
            isLong={isLong}
            stepRenderLine={stepRenderLine}
            theValueIsShow={theValueIsShow}
            isAdvanceSearch
            setValue={values => {
              setInitialFrom(values[0]);
              setInitialTo(values[1]);
            }}
            max={max}
            min={min}
            step={step}
            unit={unit}
            currentFrom={initialFrom}
            currentTo={initialTo}
            minDistance={step}
            progress={progress}
          />
        </div>
      </div>
      <div
        style={{ maxHeight: 200 }}
        className="flex-1 px-4 space-y-4 overflow-y-auto pb-4"
      >
        {listSelections.map((item, index) => {
          const isActiveFromTo =
            initialFrom === item.from && initialTo === item.to;
          return (
            <Radio
              isChecked={isActiveFromTo}
              title={item.value}
              key={index}
              onPress={() => {
                onSelectItem({ from: item.from, to: item.to, key: item.key });
                close();
              }}
            />
          );
        })}
      </div>
      <div
        style={{
          bottom: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        className=" w-full px-3 py-2 flex flex-row border-t border-gray-300 sticky space-x-2 "
      >
        <MyButton
          wrapperClass="flex-1"
          onPress={close}
          label="Trở về"
          type="gray"
        />
        <MyButton
          wrapperClass="flex-1"
          onPress={() => {
            onSelectItem({
              from: initialFrom,
              to: initialTo,
            });
            close();
          }}
          label="Xác nhận"
          type="blue"
        />
      </div>
    </div>
  );
}
