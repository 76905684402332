import React, { useState } from 'react';
import MyButton from '@components/button';
import MyDropdown from '@components/myDropdown';
import CalendarFromToDropdown from '@components/calendarFromTo';
import SearchBox from '@components/searchBox';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibleFieldOnTableModalProduct from '../../../../modals/visibleFieldOnTableModalProduct';
import MyModal from '@components/modal';
import { useProductContext } from '../product/context';
import moment from 'moment/moment';
import styles from './header.module.scss';
import OptionSelections from '../componentHeaderTable/optionSelections';
import RenderHandleMutationButton from './renderHandleMutationButton';
import { useGetLocation } from '../configs';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';

type Props = {
  title?: string,
  total?: number,
  onShowModalApproval?: Function,
  onShowModalReject?: Function,
  onShowModalDeleted?: Function,
  onShowModalHidden?: Function,
  onShowModalAppear?: Function,
  onShowModalRestore?: Function,
  onShowModalDeletedPostedProduct?: Function,
  isVisibleActionAllButton?: boolean,
};

export default function RenderTopTable({
  title,
  total = 0,
  onShowModalApproval,
  onShowModalReject,
  isVisibleActionAllButton,
  onShowModalDeleted,
  onShowModalHidden,
  onShowModalAppear,
  onShowModalRestore,
  onShowModalDeletedPostedProduct,
}: Props) {
  const { stateFilter, setStateFilterAt, setListSelected, tab } =
    useProductContext();
  const { permissions } = useGetMe();
  const [disableDropdown, setDisableDropdown] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isShowButtonAddProduct = [
      '/manageProduct/product',
      '/manageProduct/my-product',
      '/manageProduct/product-posted',
    ].includes(pathname),
    addProduct = [
      '/manageProduct/product',
      '/manageProduct/my-product',
    ].includes(pathname),
    isShowButtonAddExcel = '/manageProduct/product'.includes(pathname);
  const [showModal, setShowModal] = useState(false);
  const {
    isTabProduct,
    isTabMyProduct,
    isTabHiddenProduct,
    isTabDeleteProduct,
    isTabProductPosted,
    isTabProductPremium,
    isTabProductAuthorize,
    isTabRejectProduct,
  } = useGetLocation();
  if (isVisibleActionAllButton && tab.includes('rejected')) {
    return (
      <RenderHandleMutationButton
        isButtonRejectedProduct={isTabRejectProduct}
      />
    );
  }
  if (isVisibleActionAllButton && tab.includes('waiting-for-approval')) {
    return (
      <RenderHandleMutationButton
        isButtonApproved={tab.includes('waiting-for-approval')}
        onShowModalApproval={onShowModalApproval}
        onShowModalReject={onShowModalReject}
      />
    );
  } else if (isVisibleActionAllButton && (isTabProduct || isTabMyProduct)) {
    return (
      <RenderHandleMutationButton
        isButtonProduct={isTabProduct}
        isButtonMyProduct={isTabMyProduct}
        onShowModalDeleted={onShowModalDeleted}
        onShowModalHidden={onShowModalHidden}
      />
    );
  } else if (isVisibleActionAllButton && isTabHiddenProduct) {
    return (
      <RenderHandleMutationButton
        isButtonHidden={isTabHiddenProduct}
        onShowModalAppear={onShowModalAppear}
      />
    );
  } else if (isVisibleActionAllButton && isTabDeleteProduct) {
    return (
      <RenderHandleMutationButton
        isButtonDeleted={isTabDeleteProduct}
        onShowModalRestore={onShowModalRestore}
      />
    );
  } else if (
    isVisibleActionAllButton &&
    (isTabProductPosted || isTabProductPremium || isTabProductAuthorize)
  ) {
    return (
      <RenderHandleMutationButton
        isButtonDeletedAuthorizeProduct={isTabProductAuthorize}
        isButtonDeletedPremiumProduct={isTabProductPremium}
        isButtonDeletedPostedProduct={isTabProductPosted}
        onShowModalDeletedPostedProduct={onShowModalDeletedPostedProduct}
      />
    );
  }
  return (
    <div className="w-full flex flex-row space-x-10 items-center">
      <div style={{ minWidth: 270 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="smDT:text-18 text-16 font-medium text-black-main">
            {title}
          </div>
          <div className="text-14 text-black-main mt-0.5">
            ({total} sản phẩm)
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 flex-row items-center space-x-4 justify-end">
        {isShowButtonAddProduct &&
          ((permissions?.some(p => p.id === PermissionIds.AddProduct) &&
            addProduct) ||
            (permissions?.some(p => p.id === PermissionIds.PostProduct) &&
              isTabProductPosted) ||
            (permissions?.some(
              p => p.id === PermissionIds.PostPremiumProduct
            ) &&
              isTabProductPremium) ||
            (permissions?.some(
              p => p.id === PermissionIds.PostAuthorizedProduct
            ) &&
              isTabProductAuthorize)) && (
            <div className="smDT:w-fit">
              <MyButton
                labelStyle="pl-2"
                onPress={() =>
                  addProduct
                    ? navigate('/createB2BRealEstate/check-product')
                    : navigate(
                        isTabProductPosted
                          ? '/publish/product'
                          : isTabProductPremium
                          ? '/publish/premium'
                          : '/publish/authorized'
                      )
                }
                rightComponent={() => (
                  <img alt="plus-icon" src="/svg/plus-icon.svg" />
                )}
                type="blue"
                label={
                  addProduct
                    ? 'Thêm SP vào kho'
                    : isTabProductPosted
                    ? 'Đăng tin'
                    : isTabProductPremium
                    ? 'Đăng tin chính chủ'
                    : 'Đăng tin ký gửi'
                }
              />
            </div>
          )}
        <div className="smDT:w-340px justify-end w-240px flex items-center space-x-1">
          <div className="smDT:block hidden text-14 font-medium text-black-main">
            Thời gian đăng
          </div>
          <MyDropdown
            key={Math.random()}
            disabled={disableDropdown}
            DropdownComponent={CalendarFromToDropdown}
            buttonDropdownClassName="px-2.5 border border-blue-main rounded-lg h-40px"
            dropdownContext={{
              startDay: stateFilter?.createdAtFrom,
              endDay: stateFilter?.createdAtTo,
              setValue: nextValue => {
                setStateFilterAt('createdAtFrom')(nextValue.startDay);
                setStateFilterAt('createdAtTo')(nextValue.endDay);
                setListSelected.clear();
              },
              maxDate: moment(),
              disabledAddTo: true,
            }}
          >
            <div className="w-214px flex flex-row items-center justify-between space-x-2">
              <div className="w-full flex flex-row items-center justify-between space-x-1">
                <div className="text-sm text-blue-main text-left truncate">
                  {stateFilter?.createdAtFrom && stateFilter?.createdAtTo
                    ? `${moment
                        .unix(stateFilter?.createdAtFrom)
                        .format('DD/MM/YYYY')} - ${moment
                        .unix(stateFilter?.createdAtTo)
                        .format('DD/MM/YYYY')}`
                    : 'Chọn giá trị'}
                </div>
                {stateFilter?.createdAtFrom && stateFilter?.createdAtTo && (
                  <img
                    onClick={() => {
                      setDisableDropdown(true);
                      setStateFilterAt('createdAtFrom')('');
                      setStateFilterAt('createdAtTo')('');
                      setTimeout(() => {
                        setDisableDropdown(false);
                      }, 1000);
                    }}
                    alt="ico"
                    src="/svg/xIcon.svg"
                    className="w-3 h-3 cursor-pointer"
                  />
                )}
              </div>
              <img alt="ico" src="/svg/calendar-icon-dropdown.svg" />
            </div>
          </MyDropdown>
        </div>
        <div className={`flex ${styles.headerContainer}`}>
          {isShowButtonAddExcel && (
            <div className="w-170px">
              <MyButton
                wrapperClass="text-14 text-sm font-medium"
                onPress={() => {}}
                style={{ border: '1px solid #2459ad' }}
                type="white"
                label="Nhập từ Excel"
                rightComponent={() => (
                  <img alt="ico" src="/svg/icon-excel.svg" />
                )}
              />
            </div>
          )}
          <div className="w-180px">
            <MyButton
              onPress={() => setShowModal(true)}
              wrapperClass="text-14 text-sm font-medium"
              style={{ border: '1px solid #2459ad' }}
              type="white"
              label="Chỉnh sửa hiển thị"
              rightComponent={() => (
                <img alt="ico" src="/svg/filter-icon.svg" />
              )}
            />
          </div>
        </div>
        <div className={`${styles.showDropdown}`}>
          <OptionSelections modalEditDisplay={() => setShowModal(true)} />
        </div>
        <MyModal
          maxWidth={830}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <VisibleFieldOnTableModalProduct
            onClose={() => setShowModal(false)}
          />
        </MyModal>
        <SearchBox
          searchValue={stateFilter.searchString}
          placeholder="Tìm kiếm sản phẩm"
          setSearchValue={value => setStateFilterAt('searchString')(value)}
          onDeleteValue={() => setStateFilterAt('searchString')('')}
        />
      </div>
    </div>
  );
}
