import moment from 'moment';
import React, { useState } from 'react';
import { getNameUrlFromSever } from '@utils/helper';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import { isEmpty } from 'lodash';
import MyModalV2 from '@components/modalV2/myModalV2';
import ViewFileModal from 'modals/viewFileModal/viewFileModal';
import { useGetUserBasicInfo } from '@utils/hooks/common/useGetUserBasicInfo';
type Props = {
  attachments?: Array,
};
const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
function RenderHeader() {
  return (
    <div className="w-full h-50px flex flex-row items-center overflow-x-auto">
      <div className="flex w-40px h-full items-center justify-center text-black-main text-14 font-medium">
        STT
      </div>
      <div className={`flex-1 ${headerClass}`}>Tên tài liệu</div>
      <div className={`w-200px ${headerClass}`}>Người tải lên</div>
      <div className={`w-167px ${headerClass}`}>Thời gian</div>
    </div>
  );
}
type ItemRowProps = {
  index?: number,
  item?: Object,
};

function ItemRow({ item, index }: ItemRowProps) {
  const { userInfo } = useGetUserBasicInfo(item.creator);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="w-full h-50px flex flex-row items-center">
      <div className="flex w-40px h-full items-center justify-center text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => setShowModal(true)}
        className={`flex-1 w-200px space-x-1 cursor-pointer ${headerClass}`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(item?.originalUrl)}
        </div>
      </div>
      <div
        className={`w-200px ${headerClass} ${
          isEmpty(item?.creator) && !userInfo
            ? 'pointer-events-none'
            : undefined
        }`}
      >
        <MyDropdown
          wrapperClass="w-full"
          DropdownComponent={UserInfo}
          dropdownContext={{
            userId: item?.creator,
            isB2B: true,
          }}
        >
          <div className="flex-1 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2.5 overflow-hidden">
              <div
                className={`w-6 h-6 rounded-full overflow-hidden ${
                  !item?.creator && !userInfo?.avatar?.originalUrl
                    ? 'bg-gray-200'
                    : undefined
                }`}
              >
                {userInfo?.avatar?.originalUrl ? (
                  <img
                    alt="avt"
                    src={userInfo?.avatar?.originalUrl}
                    className="flex-1"
                  />
                ) : (
                  <img
                    alt="avt"
                    src="/svg/male.svg"
                    className="flex-1 bg-white"
                  />
                )}
              </div>
              <div
                className={`flex-1 text-14 font-normal truncate ${
                  userInfo?.fullName
                    ? 'underline cursor-pointer text-blue-main'
                    : 'text-black-main'
                }`}
              >
                {userInfo?.fullName}
              </div>
            </div>
            {userInfo?.fullName && (
              <img
                alt="open icon"
                src="/svg/eye.svg"
                className="cursor-pointer ml-2"
              />
            )}
          </div>
        </MyDropdown>
      </div>
      <div className={`w-167px ${headerClass}`}>
        <div className="flex-1 text-sm text-black-main font-normal">
          {moment(item?.createdAt).format('HH:mmA, DD/MM/YYYY')}
        </div>
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ViewFileModal fileId={item?.fileId} />
      </MyModalV2>
    </div>
  );
}

export default function AttachmentsTable(props: Props) {
  const attachments = props.attachments || [];
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="w-full rounded p-2.5 divide-y divide-gray-300 overflow-x-auto"
    >
      <RenderHeader />
      {attachments?.map((item, index) => {
        return <ItemRow item={item} key={index} index={index} />;
      })}
    </div>
  );
}
