import { useMutation } from '@apollo/client';
import { DELETE_LIST_REAL_ESTATES } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';

export const useDeleteListRealEstates = (
  realEstateIds,
  setShowModalProductPosted
) => {
  const { setListSelected } = useProductContext();
  const [onDeleted, { loading, error, client }] = useMutation(
    DELETE_LIST_REAL_ESTATES
  );
  const toast = useToast();
  const onSubmit = useCallback(async () => {
    try {
      await onDeleted({
        variables: {
          realEstateIds: realEstateIds,
        },
      });
      await resetQueries(['getRealEstates'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Xóa sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModalProductPosted(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="error"
            message={e?.message}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [
    onDeleted,
    client,
    realEstateIds,
    setShowModalProductPosted,
    setListSelected,
  ]);
  return { onSubmit, loading, error };
};
