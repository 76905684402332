import React from 'react';

type Props = {
  postTitle?: string,
  fullAddress?: string,
  postStatus?: string,
  isDeleted?: boolean,
  status?: string,
  tab?: string,
};
const getStatus = postStatus => {
  let ico, label, color;
  switch (postStatus) {
    case 'onSale':
      ico = '/svg/projectStatus/dangmoban.svg';
      label = 'Đang mở bán';
      color = '#6356FE';
      break;
    case 'constructingFoundation':
      ico = '/svg/projectStatus/dangkhoicong.svg';
      label = 'Đang khởi công móng';
      color = '#E06565';
      break;
    case 'completedFiftyPercent':
      ico = '/svg/projectStatus/hoanthanh50.svg';
      label = 'Đã hoàn thành 50%';
      color = '#DD992A';
      break;
    case 'roofed':
      ico = '/svg/projectStatus/dacatnoc.svg';
      label = 'Đã cất nóc';
      color = '#3E6CB0';
      break;
    default:
      ico = '';
      label = 'Chưa có trạng thái';
      color = '#80888F';
      break;
  }
  return { ico, label, color };
};

export default function HeadLeftProjectDetail(props: Props) {
  const { postTitle, fullAddress, postStatus, status } = props;
  const { ico, label, color } = getStatus(postStatus, status);
  return (
    <div
      className="flex w-full items-start border-b border-gray-300 space-x-6"
      style={{
        padding: '25px 30px 10px',
      }}
    >
      <div
        className="text-14 text-gray-300 font-medium flex-1"
        dangerouslySetInnerHTML={{
          __html: `<span class="text-black-main">Dự án:</span> <span class="text-black-main">${
            postTitle || ''
          }</span> | <span class="text-black-main">${fullAddress || ''}</span>`,
        }}
      />

      <div className="flex flex-row items-center space-x-1 justify-end">
        <div className="text-14 text-black-main justify-center">
          Trạng thái sàn:
        </div>
        <div className="flex flex-row items-center item-center space-x-1">
          <div className="flex items-center justify-center">
            {postStatus !== 'unknown' && (
              <img alt="ico" className="w-11px h-11px" src={ico} />
            )}
          </div>
          <div
            className={`text-14 font-medium`}
            style={{
              color,
              whiteSpace: 'nowrap',
            }}
          >
            {label}
          </div>
        </div>
      </div>
    </div>
  );
}
