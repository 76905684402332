import styles from '@components/table/table.module.scss';
import React from 'react';
import { useConvertDataProductRenewHistory } from '@utils/hooks/realEstateEditHistory/useConvertDataProductRenewHistory';

type Props = {
  index?: number,
  item?: Object,
  type?: string,
  headerTableConfigs?: Array,
};

export default function TableRow(props: Props) {
  const { index, item, headerTableConfigs = [] } = props;
  const data = useConvertDataProductRenewHistory(item);
  const configs = headerTableConfigs.map(item => {
    const foundConfigs = data.find(e => e.key === item.key);
    if (foundConfigs) {
      return { ...item, ...foundConfigs };
    }
  });

  const dataRender = configs;

  return (
    <>
      <div
        className="flex w-full border-b border-gray-300"
        style={{ minHeight: 40 }}
      >
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        >
          <div className={`text-13 text-black-main ${styles.textHead}`}>
            {index + 1}
          </div>
        </div>
        {dataRender.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles.headContainer} ${item?.className} items-center`}
            >
              <div
                className={`text-13 text-black-main ${styles.textHead} w-full text-center`}
              >
                {typeof item?.value === 'function'
                  ? item?.value()
                  : item?.value || '-'}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
