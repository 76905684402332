export const historyTypesSelection = [
  {
    key: 'addProduct',
    value: 'Thêm sản phẩm',
  },
  {
    key: 'editProduct',
    value: 'Chỉnh sửa sản phẩm',
  },
  {
    key: 'deleteProduct',
    value: 'Xóa sản phẩm',
  },
  {
    key: 'viewProductOnMap',
    value: 'Xem sản phẩm trên bản đồ',
  },
  {
    key: 'viewProductDetail',
    value: 'Xem chi tiết sản phẩm',
  },
  {
    key: 'addDocumentationProduct',
    value: 'Bổ sung sung tài liệu cho sản phẩm',
  },
  {
    key: 'removeDocumentationProduct',
    value: 'Xóa tài liệu',
  },
  {
    key: 'contactPhones',
    value: 'Xem số điện thoại chủ nhà',
  },
  {
    key: 'changeStatus',
    value: 'Chuyển trạng thái',
  },
  {
    key: 'approvedProduct',
    value: 'Duyệt sản phẩm',
  },
  {
    key: 'rejectedProduct',
    value: 'Từ chối sản phẩm',
  },
  {
    key: 'productComment',
    value: 'Ghi chú sản phẩm',
  },
];
