import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATES, GET_ME } from '@utils/graphql';
import { useGlobalContext } from '../../../context';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { cleanObj } from '@utils/helper';
import { isEmpty, pickBy, omit } from 'lodash';
import { useGetLocation } from '../../../scenes/home/manageProduct/configs';
import { PAGINATION_LIMIT } from 'constant';

export const useGetParamVariable = () => {
  const { isLogin } = useGlobalContext();
  const { pathname } = useLocation();
  const { data: myInfo } = useQuery(GET_ME, {
      skip: !isLogin,
    }),
    idMyProduct = myInfo?.b2bGetMe?.payload?.id;
  const myProduct = '/manageProduct/my-product',
    hiddenProduct = '/manageProduct/product/hidden',
    deleteProduct = '/manageProduct/product/deleted',
    waitingProduct = '/manageProduct/product/waiting-for-approval',
    rejectedProduct = '/manageProduct/product/rejected',
    homeProduct = '/manageProduct/product';

  if (pathname === myProduct) {
    return {
      creator: pathname === myProduct ? idMyProduct : '',
    };
  } else if (pathname === hiddenProduct) {
    return {
      isHidden: pathname === hiddenProduct,
      sort: '-approvedAt',
    };
  } else if (pathname === deleteProduct) {
    return {
      isDeleted: pathname === deleteProduct,
      sort: '-approvedAt',
    };
  } else if (pathname === waitingProduct) {
    return {
      sort: 'updatedAt',
    };
  } else if (pathname === rejectedProduct) {
    return {
      sort: '-rejectedAt',
    };
  } else if (pathname === homeProduct) {
    return {
      sort: '-approvedAt',
    };
  }
};

export const pickByFromTo = (from, to) => {
  let floatFrom,
    floatTo,
    t = {};
  if (from !== null && to !== 0) {
    floatFrom = parseFloat(from);
    floatTo = parseFloat(to);

    t = pickBy(
      { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
      e => e
    );
  } else {
    t = { from: null, to: 0 };
  }
  return isEmpty(t) ? null : t;
};
const getPriceFromToValue = (from, to, isForSale) => {
  const amount = isForSale === 'sale' ? 1000000000 : 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};

export const getVariable = state => {
  const { pathname } = useLocation();
  const { isTabWaitingForApproval, isTabProduct } = useGetLocation();
  const product = '/manageProduct/product',
    waitingProduct = '/manageProduct/product/waiting-for-approval',
    rejectProduct = '/manageProduct/product/rejected';
  let approvedStatusPathName;
  if (pathname === waitingProduct) {
    approvedStatusPathName = ['pending'];
  } else if (pathname === product) {
    approvedStatusPathName = ['approved'];
  } else if (pathname === rejectProduct) {
    approvedStatusPathName = ['rejected'];
  } else if (state?.approveStatus?.length) {
    approvedStatusPathName = state?.approveStatus;
  } else {
    approvedStatusPathName = [];
  }
  const isExpired12hours =
    isTabWaitingForApproval && state?.postStatus?.length > 0 ? true : null;
  const type = state?.isForSell === 'ForSale' ? 'sale' : 'rent';
  const isForRent = type === 'rent';
  const approveStatus = state?.postStatus?.filter(
    e => e === 'rejected' || e === 'pending'
  );
  const postStatus = state?.postStatus?.filter(
    e => e !== 'rejected' && e !== 'pending' && e !== 'isExpired12hours'
  );

  const t = pickBy(
    {
      searchString: state?.searchString,
      realEstateId: state?.realEstateId,
      typeOfDemand: [type],
      status:
        state?.postStatus?.length === 10 || state?.postStatus?.length === 9
          ? null
          : state?.postStatus?.length > 0
          ? postStatus?.filter(e => e !== 'isAbandoned')
          : null,
      isAbandoned:
        postStatus?.filter(e => e === 'isAbandoned')?.length > 0 ? true : false,
      approveStatus:
        approveStatus?.length === 2 &&
        (state?.postStatus?.length === 10 || state?.postStatus?.length === 9)
          ? null
          : approveStatus?.length > 0
          ? approveStatus
          : approvedStatusPathName,
      type: [0, isForRent ? 11 : 10].includes(state?.realEstateType?.length)
        ? null
        : state?.realEstateType,
      province: state?.province,
      district: state?.district,
      ward: state?.ward,
      street: state?.street,
      houseNumber: state?.houseNumber,
      createdAt:
        !state.createdAtFrom || !state.createdAtTo
          ? null
          : {
              from: moment.unix(state.createdAtFrom).format(),
              to: moment.unix(state.createdAtTo).endOf('day').format(),
            },
      updatedAt:
        !state.updatedAtFrom || !state.updatedAtTo
          ? null
          : {
              from: moment.unix(state.updatedAtFrom).format(),
              to: moment.unix(state.updatedAtTo).endOf('day').format(),
            },
      price: getPriceFromToValue(
        state?.priceFrom,
        (state?.priceTo > 100 && type) || (state?.priceTo > 200 && !type)
          ? null
          : state?.priceTo,
        type
      ),
      contactName: state?.contactName,
      contactPhone: state?.contactPhone,
      commission: state?.commission,
      floorCount: pickByFromTo(
        state?.floorCountFrom,
        state?.floorCountTo > 168 ? null : state?.floorCountTo
      ),
      bedroomCount: pickByFromTo(
        state?.bedroomCountFrom,
        state?.bedroomCountTo > 168 ? null : state?.bedroomCountTo
      ),
      toiletCount: pickByFromTo(
        state?.toiletCountFrom,
        state?.toiletCountTo > 168 ? null : state?.toiletCountTo
      ),
      width: pickByFromTo(
        state?.widthFrom,
        state?.widthTo > 100 ? null : state?.widthTo
      ),
      length: pickByFromTo(
        state?.lengthFrom,
        state?.lengthTo > 500 ? null : state?.lengthTo
      ),
      rear: pickByFromTo(
        state?.rearFrom,
        state?.rearTo > 100 ? null : state?.rearTo
      ),
      area: pickByFromTo(
        state?.areaFrom,
        state?.areaTo > 20000 ? null : state?.areaTo
      ),
      alleyWidth: pickByFromTo(
        state?.alleyWidthFrom,
        state?.alleyWidthTo > 100 ? null : state?.alleyWidthTo
      ),
      constructionArea: pickByFromTo(
        state?.constructionAreaFrom,
        state?.constructionAreaTo > 20000 ? null : state?.constructionAreaTo
      ),
      approvedAt:
        !state.approvedAtFrom || !state.approvedAtTo
          ? null
          : {
              from: moment.unix(state.approvedAtFrom).format(),
              to: moment.unix(state.approvedAtTo).endOf('day').format(),
            },
      rejectedAt:
        !state.rejectedAtFrom || !state.rejectedAtTo
          ? null
          : {
              from: moment.unix(state.rejectedAtFrom).format(),
              to: moment.unix(state.rejectedAtTo).endOf('day').format(),
            },
      approvedBy: state?.approvedBy ? state?.approvedBy : null,
      rejectedBy: state?.rejectedBy ? state?.rejectedBy : null,
      creatorName: state?.creator ? state?.creator : null,
      editorName: state?.editor ? state?.editor : null,
      isExpired12hours: isTabProduct ? null : isExpired12hours,
    },
    e => e
  );
  t.hasAlley = state?.hasAlley?.length === 2 ? null : state?.hasAlley?.[0];
  t.direction = state?.direction?.length === 8 ? null : state?.direction;
  return { ...cleanObj(t) };
};

export const useGetInternalRealEstates = ({ state }) => {
  const { isLogin } = useGlobalContext();
  const pathnameVars = useGetParamVariable();
  const variable = getVariable(state);
  const [loading, setLoading] = useState(false);
  const finalVariables = {
    data: {
      ...omit(pathnameVars, 'sort'),
      ...variable,
    },
    page: 1,
    limit: PAGINATION_LIMIT,
  };
  if (pathnameVars?.sort && state?.sort === '') {
    finalVariables.sort = pathnameVars?.sort;
  } else finalVariables.sort = state?.sort;
  const { data, fetchMore } = useQuery(GET_INTERNAL_REAL_ESTATES, {
    variables: {
      ...finalVariables,
    },
    skip: !isLogin,
  });
  const realEstates = data?.getInternalRealEstates?.items || [];
  const currentPage = data?.getInternalRealEstates?.page || 1;
  const totalItem = data?.getInternalRealEstates?.total;
  const totalPage = data?.getInternalRealEstates?.totalPages;
  const rowsPerPage = data?.getInternalRealEstates?.limit;
  const currentCountFrom = data?.getInternalRealEstates?.pagingCounter;
  const currentCountTo = currentCountFrom + realEstates.length - 1;
  const hasPrevPage = data?.getInternalRealEstates?.hasPrevPage;
  const hasNextPage = data?.getInternalRealEstates?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  return {
    realEstates,
    loading,
    onLoadByPagination,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
