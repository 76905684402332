import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { makeStyles } from '@material-ui/core';

type Props = {
  isAbandoned?: boolean,
  approvedStatus?: string,
  isExpired12hours?: boolean,
  isTabMyProduct?: boolean,
  isTabProduct?: boolean,
  isTabDeletedProduct?: boolean,
  isTabHiddenProduct?: boolean,
  isTabRejectProduct?: boolean,
};

const getIconAndTitle = ({
  approvedStatus,
  isExpired12hours,
  isTabMyProduct,
  isAbandoned,
}) => {
  let icon, title;
  if (isAbandoned && !isTabMyProduct) {
    icon = '/svg/icon_warning.svg';
    title = 'Sản phẩm bị cảnh báo';
  } else if (approvedStatus === 'approved' && isTabMyProduct) {
    icon = '/svg/active-icon.svg';
    title = 'Đã được duyệt';
  } else if (isExpired12hours && !isTabMyProduct) {
    icon = '/svg/icon_warning.svg';
    title = 'Sản phẩm quá 12h chưa được duyệt';
  } else if (approvedStatus === 'pending') {
    icon = '/svg/icon_pending.svg';
    title = 'Đang chờ duyệt';
  } else if (approvedStatus === 'rejected' || isTabMyProduct) {
    icon = '/svg/icon_warning.svg';
    title = 'Bị từ chối';
  }
  return { icon, title };
};

export default function RenderIconComponent({
  approvedStatus,
  isExpired12hours,
  isTabMyProduct,
  isTabDeletedProduct,
  isTabHiddenProduct,
  isTabRejectProduct,
  isAbandoned,
}: Props) {
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: '#FFFFFF',
      color: '#22313E',
    },
  }));
  const classes = useStyles();

  const { title, icon } = getIconAndTitle({
    approvedStatus,
    isTabMyProduct,
    isExpired12hours,
    isAbandoned,
  });

  if (
    isTabDeletedProduct ||
    isTabHiddenProduct ||
    isTabRejectProduct ||
    !icon?.length
  ) {
    return <></>;
  }
  return (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
      }}
      className="mr-4"
      title={title}
    >
      <img src={icon} alt="icon" />
    </Tooltip>
  );
}
