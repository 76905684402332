import { useQuery } from '@apollo/client';
import { GET_ME, GET_RELATED_INTERNAL_REAL_ESTATES } from '@utils/graphql';
import { useGlobalContext } from '../../../context';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { cleanObj } from '@utils/helper';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { useGetLocation } from '../../../scenes/home/manageProduct/configs';
import { PAGINATION_LIMIT } from 'constant';

export const useGetParamVariable = () => {
  const { isLogin } = useGlobalContext();
  const { pathname } = useLocation();
  const { data: myInfo } = useQuery(GET_ME, {
      skip: !isLogin,
    }),
    idMyProduct = myInfo?.b2bGetMe?.payload?.id;
  const myProduct = '/manageProduct/my-product',
    hiddenProduct = '/manageProduct/product/hidden',
    deleteProduct = '/manageProduct/product/deleted';

  if (pathname === myProduct) {
    return {
      creator: pathname === myProduct ? idMyProduct : '',
    };
  } else if (pathname === hiddenProduct) {
    return {
      isHidden: pathname === hiddenProduct,
    };
  } else if (pathname === deleteProduct) {
    return {
      isDeleted: pathname === deleteProduct,
    };
  }
};

export const pickByFromTo = (from, to) => {
  const floatFrom = parseFloat(from),
    floatTo = parseFloat(to);

  const t = pickBy(
    { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
    e => e
  );
  return isEmpty(t) ? null : t;
};
const getPriceFromToValue = (from, to, isForSale) => {
  const amount = isForSale === 'sale' ? 1000000000 : 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};

export const getVariable = state => {
  const { isTabWaitingForApproval, isTabProduct } = useGetLocation();
  const isExpired12hours =
    isTabWaitingForApproval && state?.postStatus?.length > 0 ? true : null;
  const isAbandoned = state?.postStatus?.length > 0 ? true : null;
  const type = state?.isForSell === 'ForSale' ? 'sale' : 'rent';
  const isForRent = type === 'rent';

  const postStatus = state?.postStatus?.filter(
    e => e !== 'rejected' && e !== 'pending' && e !== 'isExpired12hours'
  );
  const t = pickBy(
    {
      isAbandoned: isTabProduct ? isAbandoned : null,
      searchString: state?.searchString,
      realEstateId: state?.realEstateId,
      status:
        state?.postStatus?.length === 10 || state?.postStatus?.length === 9
          ? null
          : isTabWaitingForApproval
          ? null
          : state?.postStatus?.length > 0
          ? postStatus
          : null,
      type: [0, isForRent ? 11 : 10].includes(state?.realEstateType?.length)
        ? null
        : state?.realEstateType,
      province: state?.province,
      district: state?.district,
      ward: state?.ward,
      street: state?.street,
      houseNumber: state?.houseNumber,
      createdAt:
        !state.createdAtFrom || !state.createdAtTo
          ? null
          : {
              from: moment.unix(state.createdAtFrom).format(),
              to: moment.unix(state.createdAtTo).endOf('day').format(),
            },
      updatedAt:
        !state.updatedAtFrom || !state.updatedAtTo
          ? null
          : {
              from: moment.unix(state.updatedAtFrom).format(),
              to: moment.unix(state.updatedAtTo).endOf('day').format(),
            },
      price: getPriceFromToValue(
        state?.priceFrom,
        (state?.priceTo > 100 && type) || (state?.priceTo > 200 && !type)
          ? null
          : state?.priceTo,
        type
      ),
      contactName: state?.contactName,
      floorCount: pickByFromTo(
        state?.floorCountFrom,
        state?.floorCountTo > 168 ? null : state?.floorCountTo
      ),
      width: pickByFromTo(
        state?.widthFrom,
        state?.widthTo > 100 ? null : state?.widthTo
      ),
      length: pickByFromTo(
        state?.lengthFrom,
        state?.lengthTo > 500 ? null : state?.lengthTo
      ),
      rear: pickByFromTo(
        state?.rearFrom,
        state?.rearTo > 100 ? null : state?.rearTo
      ),
      area: pickByFromTo(
        state?.areaFrom,
        state?.areaTo > 20000 ? null : state?.areaTo
      ),
      approvedAt:
        !state.approvedAtFrom || !state.approvedAtTo
          ? null
          : {
              from: moment.unix(state.approvedAtFrom).format(),
              to: moment.unix(state.approvedAtTo).endOf('day').format(),
            },
      approvedBy: state?.approvedBy ? state?.approvedBy : null,
      creator: state?.creator ? state?.creator : null,
      isExpired12hours: isTabProduct ? null : isExpired12hours,
    },
    e => e
  );
  return { ...cleanObj(t) };
};

export const useGetRelatedInternalRealEstates = ({
  state,
  currentREId,
  info,
}) => {
  const { isLogin } = useGlobalContext();
  const pathnameVars = useGetParamVariable();
  const variable = getVariable(state);
  const [loading, setLoading] = useState(false);
  const finalVariables = {
    currentREId,
    data: {
      ...info,
      ...pathnameVars,
      ...variable,
    },
    page: 1,
    limit: PAGINATION_LIMIT,
  };

  const { data, fetchMore } = useQuery(GET_RELATED_INTERNAL_REAL_ESTATES, {
    variables: {
      ...finalVariables,
    },
    skip: !isLogin,
  });
  const realEstates = data?.getRelatedInternalRealEstates?.items || [];
  const currentPage = data?.getRelatedInternalRealEstates?.page || 1;
  const totalItem = data?.getRelatedInternalRealEstates?.total;
  const totalPage = data?.getRelatedInternalRealEstates?.totalPages;
  const rowsPerPage = data?.getRelatedInternalRealEstates?.limit;
  const currentCountFrom = data?.getRelatedInternalRealEstates?.pagingCounter;
  const currentCountTo = currentCountFrom + realEstates.length - 1;
  const hasPrevPage = data?.getRelatedInternalRealEstates?.hasPrevPage;
  const hasNextPage = data?.getRelatedInternalRealEstates?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  return {
    realEstates,
    loading,
    onLoadByPagination,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
