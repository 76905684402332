import { pickBy, omit } from 'lodash';
import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  PUBLISH_PREMIUM_REAL_ESTATE_PRODUCT,
  PUBLISH_REAL_ESTATE_PRODUCT,
} from '@utils/graphql/mutations/realEstateB2B';
import MyToast from '@components/MyToast';
import { useToast } from '@chakra-ui/react';
import { omitFields } from '../../../constant';
import { useGetPublishRealEstateType } from '@utils/hooks/createB2BRealEstate/useGetPublishRealEstateType';

export const createPublishRealEstateProductVariables = state => {
  const imageUrls = state?.mediaUrls?.map(e => e?.fileId),
    attachments = state?.attachments?.map(e => e?.fileId),
    map =
      state?.long && state?.lat
        ? [parseFloat(state?.long), parseFloat(state?.lat)]
        : null;
  const data = {
    type: state?.typeRealEstate,
    originalProductId: state?.originalProductId,
    province: state.province,
    district: state.district,
    isForSell: state.isForSell === 'forSale',
    ward: state.ward,
    street: state.street,
    houseNumber: state.houseNumber,
    status: state.status,
    commission: state.commission,
    hasAlley: state.hasAlley,
    alleyTurnCount: state.hasAlley ? parseInt(state.alleyTurnCount, 10) : null,
    alleyWidth: state.hasAlley ? parseInt(state.alleyWidth, 10) : null,
    isNewConstruction: state.isNewConstruction,
    width: parseFloat(state.width),
    length: parseFloat(state.length),
    rear: parseFloat(state.rear),
    area: state?.rear
      ? parseFloat(
          (
            ((parseFloat(state?.width) + parseFloat(state?.rear)) / 2) *
            parseFloat(state?.length)
          )?.toFixed(2)
        )
      : parseFloat(
          (parseFloat(state.width) * parseFloat(state.length))?.toFixed(2)
        ),
    floorCount: !state.floorCount ? 0 : parseInt(state.floorCount, 10), // Số tầng
    bedroomCount: parseInt(state.bedroomCount, 10),
    roomCount: parseInt(state.roomCount, 10), // Số lượng phòng
    hotelStar: parseInt(state.hotelStar, 10),
    hasSeaView: state.hasSeaView,
    price: parseFloat(state.price),
    priceUnit: state.isForSell === 'forSale' ? 'VND' : 'VndPerMonth',
    toiletCount: parseInt(state.toiletCount, 10),
    direction: state.direction,
    project: state.project,
    privateNote: state.privateNote,
    legals: state.legal,
    mediaUrls: imageUrls,
    villaView: state.villaView || [],
    buildingType: state.buildingType,
    warehouseType: state.warehouseType, // Chức năng nhà xưởng
    warehouseRoomCount: parseInt(state.warehouseRoomCount, 10), // Số phòng kho
    block: state?.block, // Thuộc block nào
    roomNumber: state.roomNumber?.toString(), // Số thứ tự phòng
    onFloor: parseInt(state.onFloor, 10), // Vị trí tầng
    bedRoomArea: parseInt(state.bedRoomArea, 10), // Diện tích phòng ngủ
    shophouseField: state?.shophouseField, // Thương mại ngành
    // timeForRent: parseInt(state.timeForRent, 10),
    // timeForRentUnit: state.timeForRentUnit,
    hasMezzanine: state.hasMezzanine, // Có gác hay không
    contactName: state.contactName,
    contactPhones:
      state.contactPhones?.length > 0
        ? state.contactPhones?.map(e => e?.phone)
        : null,
    contactEmail: state.contactEmail,
    career: state.career,
    fixedUtilities: state.fixedUtilities,
    utilities: state.utilities,
    localUtilities: state.localUtilities,
    equipmentsAndServices: state.equipmentsAndServices,
    attachments, // Tệp đính kèm
    map: map ? { coordinates: map } : null,
    constructionArea: parseFloat(state?.constructionArea),
    isAuthorizedRe: !!state?.isAuthorizedRe,
    numberOfPostingDay: parseInt(state?.numberOfPostingDay),
    ownerName: state?.ownerName,
    ownerPhone: state?.ownerPhone?.map(p => p.phone),
    ownerEmail: state?.ownerEmail,
    priceOfOwner: parseFloat(state?.priceOfOwner),
    postTitle: state?.postTitle,
    postContent: state?.postContent,
    postType: parseInt(state?.postType),
    location: {
      type: 'Point',
      coordinates: map,
    },
  };
  return omit(data, omitFields[data.type]);
};

export const usePublishB2BRealEstate = (setValue, setShowModal) => {
  const toast = useToast();
  const { premium, authorized } = useGetPublishRealEstateType();
  const [publishRealEstateProductToCustomer, { loading, client }] = useMutation(
    premium ? PUBLISH_PREMIUM_REAL_ESTATE_PRODUCT : PUBLISH_REAL_ESTATE_PRODUCT
  );
  const onSubmit = useCallback(
    async state => {
      const pickObject = createPublishRealEstateProductVariables(state),
        lastTempCreateVariables = pickBy(
          pickObject,
          e => e || e === 0 || e === false
        );
      if (premium) {
        delete lastTempCreateVariables.isAuthorizedRe;
      }
      if (authorized) {
        lastTempCreateVariables.isAuthorizedRe = true;
      }
      try {
        await publishRealEstateProductToCustomer({
          variables: { data: lastTempCreateVariables },
        });
        setShowModal(true);
        setValue('mediaUrls', []);
        setValue('contactPhones', []);
        setValue('attachments', []);
        await client.resetStore();
      } catch (e) {
        setTimeout(() => {
          toast({
            position: 'bottom-right',
            duration: 5000,
            render: props => (
              <MyToast
                id={props.id}
                onClose={props.onClose}
                type="error"
                message={e.message}
                closeButtonPos="center"
              />
            ),
          });
        }, 200);
      }
    },
    [premium, authorized, setValue, client, publishRealEstateProductToCustomer]
  );
  return { onSubmit, loading };
};
