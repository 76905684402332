import { HoChiMinhId } from '../../../constant';
import { useSetState } from 'react-use';
import { useCallback } from 'react';

export const blankStateFilterProject = {
  projectCode: '',
  projectName: '',
  investorName: [],
  projectType: [],
  projectStatus: ['onSale'],
  houseNumber: '',
  street: '',
  streetName: '',
  ward: '',
  wardName: '',
  district: '',
  districtName: '',
  province: HoChiMinhId,
  provinceName: 'Thành phố Hồ Chí Minh',
  startDay: '',
  endDay: '',
  priceFrom: 0,
  priceTo: 101,
  priceKey: '',
};

export const useStateFilterProject = () => {
  const [state, setState] = useSetState({ ...blankStateFilterProject });
  const setStateAt = useCallback(
    field => {
      return value => setState({ [field]: value });
    },
    [setState]
  );
  return { state, setState, setStateAt };
};
