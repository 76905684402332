import MyButton from '@components/button';
import MyInput from '@components/myInput';
import { useCallback, useState } from 'react';
import { entryNumber } from '@utils/helper';

type Props = {
  close?: Function,
  context?: {
    search?: string,
    setSearch?: Function,
    keyboardType?: string,
    onDelete?: Function,
  },
};

export default function EnterFieldForSearch(props: Props) {
  const {
    close,
    context: { search, setSearch, keyboardType },
  } = props;

  const [value, setValue] = useState(search || '');

  const onEnter = useCallback(
    event => {
      if (event.key === 'Enter') {
        setSearch(value);
        close();
      }
    },
    [setSearch, close, value]
  );

  return (
    <div className="w-300px rounded-lg bg-white flex flex-col border border-blue-main space-y-2">
      <MyInput
        onDelete={() => {
          setValue('');
        }}
        inputClassName="mx-4 mt-4"
        onKeyDown={e => onEnter(e)}
        value={value}
        onChange={e => {
          if (keyboardType === 'numeric') {
            entryNumber(e.target.value, setValue, 20);
          } else {
            setValue(e.target.value);
          }
        }}
        placeholder="Nhập để tìm kiếm"
        inputInnerClassName="h-40px mx-4 "
        renderRightComponent={() => {
          return (
            <img
              alt="search"
              src="/svg/searchBoxIcon.svg"
              className="w-6 h-6 mr-1.5"
            />
          );
        }}
      />
      <div className="flex w-full px-4 py-2 border-t border-gray-300">
        <MyButton
          label="Trở về"
          type="gray"
          wrapperClass="mr-2"
          onPress={close}
        />
        <MyButton
          label="Xác nhận"
          onPress={() => {
            setSearch(value);
            close();
          }}
        />
      </div>
    </div>
  );
}
