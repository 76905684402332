import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { apolloClient } from '@utils/wire';
import { READ_MEDIA_URLS } from '@utils/graphql';
import { estate, timeToRentConfigs } from '../configs';
import { GET_USER_BASIC_INFO } from '@utils/graphql';
import Cookies from 'js-cookie';
import _ from 'lodash';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useState } from 'react';
export const getNameUrlFromSever = arr => {
  return arr?.slice(arr.lastIndexOf('/') + 1, arr.length);
};
export const getMedia = fileId => {
  if (typeof fileId === 'object') {
    return Promise.resolve(fileId);
  }
  return apolloClient
    .query({ query: READ_MEDIA_URLS, variables: { fileId } })
    .then(({ data }) => {
      return data.readMediaUrls;
    });
};

export const getMedias = fileIds => {
  if (isEmpty(fileIds)) {
    return [];
  } else {
    return Promise.allSettled(fileIds?.map(getMedia)).then(result =>
      result.filter(e => e.status === 'fulfilled').map(e => e?.value)
    );
  }
};

export const nonAccentVietnamese = str => {
  str = str?.toString().toLowerCase() || '';
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const getUserInfo = userId => {
  if (typeof userId === 'object') {
    return Promise.resolve(userId);
  }
  return apolloClient
    .query({ query: GET_USER_BASIC_INFO, variables: { userId } })
    .then(({ data }) => data.getUserBasicInfo);
};

export const getUserInfos = userIds => {
  if (isEmpty(userIds)) {
    return [];
  } else {
    return Promise.allSettled(userIds?.map(getUserInfo)).then(result =>
      result.filter(e => e.status === 'fulfilled').map(e => e?.value)
    );
  }
};

export function getMediaUrlByType(mediaObject, type: string) {
  const { originalUrl, urls = [] } = mediaObject || {},
    foundUrl = urls?.find(e => e.type === type);
  return foundUrl?.url || originalUrl;
}

export const covertValueFromTo = (item, key) => {
  const tempValue = item[key].to || parseFloat(item[key]).toFixed(2);
  return convertFloatWithDot(tempValue);
};
export function numberWithDots(x) {
  if (!x) {
    return '0';
  }
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const usePrice = item => {
  const price = item?.price?.to || item?.price;
  return convertPrice(price);
};
export function convertPrice(price) {
  let pricePost = '',
    priceUnit = '';
  if (parseFloat(price) >= Math.pow(10, 9)) {
    pricePost = parseFloat(parseFloat(price / 1000000000).toFixed(1));
  }
  if (parseFloat(price) >= 1000000 && parseFloat(price) < Math.pow(10, 9)) {
    pricePost = parseFloat(parseFloat(price / 1000000).toFixed(1));
  }
  if (parseFloat(price) >= 100000 && parseFloat(price) < 1000000) {
    pricePost = parseFloat(parseFloat(price / 1000).toFixed(1));
  }
  if (parseFloat(price) >= 0 && parseFloat(price) < 1000) {
    pricePost = 0;
  }
  if (parseFloat(price) >= Math.pow(10, 9)) {
    priceUnit = 'tỷ';
  }
  if (parseFloat(price) >= 1000000 && parseFloat(price) < Math.pow(10, 9)) {
    priceUnit = 'triệu';
  }
  if (parseFloat(price) >= 1000 && parseFloat(price) < 1000000) {
    priceUnit = 'nghìn';
  }
  if (parseFloat(price) >= 0 && parseFloat(price) < 1000) {
    priceUnit = 'đồng';
  }
  return { price: convertFloatWithDot(pricePost), priceUnit };
}

export const convertFloatWithDot = floatNumber => {
  if (!floatNumber) {
    return '';
  }
  const convertFloatNumber = floatNumber?.toString(),
    splitNumber = convertFloatNumber.split('.');
  if (splitNumber?.length) {
    return `${numberWithDots(splitNumber[0])}${
      parseInt(splitNumber[1], 10) > 0 ? `,${splitNumber[1]}` : ''
    }`;
  }
  return '';
};

const getUnit = index => {
  switch (index) {
    case 0:
      return 'đồng';
    case 1:
      return 'ngàn';
    case 2:
      return 'triệu';
    case 3:
      return 'tỷ';
    case 4:
      return 'ngàn tỷ';
    case 5:
      return 'triệu tỷ';
    case 6:
      return 'tỷ tỷ';
    default:
      return '';
  }
};

export const DocTienBangChuV2 = (money: string) => {
  if (!money) {
    return '';
  }
  const moneyWithDot = money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const splitMoney = moneyWithDot.split('.').reverse();
  let doctien = [];
  splitMoney.forEach((e, index) => {
    const doc =
      parseInt(e, 10) <= 0 && !doctien?.length
        ? ''
        : `${Number(e)} ${getUnit(index)}`.trim();
    if (doc) {
      doctien.push(doc);
    }
  });
  return doctien.reverse().join(' ').trim();
};

export function cleanObj(obj) {
  for (let propName in obj) {
    if (typeof obj[propName] !== 'boolean') {
      if (obj[propName] === null) {
        delete obj[propName];
      }
      if (obj[propName] === undefined) {
        delete obj[propName];
      }
      if (obj[propName]?.length <= 0) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object' && isEmpty(obj[propName])) {
        delete obj[propName];
      }
      if (
        typeof obj[propName] === 'number' &&
        (obj[propName] < 0 || isNaN(obj[propName]))
      ) {
        delete obj[propName];
      }
    }
  }
  return obj;
}
export function convertDataSelections(data) {
  return data?.map(item => ({ key: item.id, value: item.name, ...item }));
}
export const entryNumber = (text, setValue, maxLength = 9999) => {
  if (!/^\d+$/.test(text)) {
    setValue(text.replace(/\D+/g, '').substr(0, maxLength));
  } else {
    setValue(text.substr(0, maxLength));
  }
};
export function mergeDeep(...objects) {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = uniqBy(pVal.concat(...oVal), 'id');
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export function getErrorsFormHook({ error, values }) {
  return {
    values: error ? {} : values,
    errors: error
      ? error.details.reduce((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError,
          };
        }, {})
      : {},
  };
}
export function getListDaysInMonth(month, year) {
  const dateFormat = 'DD-MM-YYYY';
  let daysInMonth = moment(`${month}-${year}`, 'M-YYYY').daysInMonth();
  const daysArray = [],
    listDayOfLastMonth = [],
    listDayOfNextMonth = [];
  while (daysInMonth) {
    const currentDate = moment(`${month}-${year}`, 'M-YYYY').date(daysInMonth);
    daysArray.push(currentDate.format(dateFormat));
    daysInMonth -= 1;
  }
  const daysInCurrentMonth = [...daysArray.reverse()],
    startDayOfMonthIsoWeekday = moment(
      daysInCurrentMonth[0],
      dateFormat
    ).isoWeekday(),
    endDayOfMonthIsoWeekday = moment(
      daysInCurrentMonth[daysInCurrentMonth.length - 1],
      dateFormat
    ).isoWeekday();
  if (startDayOfMonthIsoWeekday !== 1) {
    for (let i = 1; i < startDayOfMonthIsoWeekday; i += 1) {
      const previousDate = moment(daysInCurrentMonth[0], dateFormat)
        .subtract(startDayOfMonthIsoWeekday - i, 'days')
        .format(dateFormat);
      listDayOfLastMonth.push(previousDate);
    }
  }
  if (endDayOfMonthIsoWeekday !== 7) {
    for (let i = 1; i <= 7 - endDayOfMonthIsoWeekday; i += 1) {
      const nextDate = moment(
        daysInCurrentMonth[daysInCurrentMonth.length - 1],
        dateFormat
      )
        .add(i, 'days')
        .format(dateFormat);
      listDayOfNextMonth.push(nextDate);
    }
  }
  const listDays = [
    ...listDayOfLastMonth,
    ...daysInCurrentMonth,
    ...listDayOfNextMonth,
  ];
  if (listDays.length < 42) {
    for (let i = 1; i <= 42 - listDays.length; i += 1) {
      const temporaryArray = isEmpty(listDayOfNextMonth)
        ? [...daysInCurrentMonth]
        : [...listDayOfNextMonth];
      const nextDate = moment(
        temporaryArray[temporaryArray.length - 1],
        dateFormat
      )
        .add(1, 'days')
        .format(dateFormat);
      listDayOfNextMonth.push(nextDate);
    }
  }
  return [...listDayOfLastMonth, ...daysInCurrentMonth, ...listDayOfNextMonth];
}

export const getFromTo2 = (value, isReadValue) => {
  const from = value?.from,
    to = value?.to;
  if (from && to) {
    return `${isReadValue ? DocTienBangChuV2(from) : from} - ${
      isReadValue ? DocTienBangChuV2(to) : to
    }`;
  }
  if (from) {
    return `>= ${isReadValue ? DocTienBangChuV2(from) : from}`;
  }
  if (to) {
    return `<= ${isReadValue ? DocTienBangChuV2(to) : to}`;
  }
  return null;
};

export const getTimeForRent = value => {
  const foundValue = timeToRentConfigs.find(
    e => e.from === value?.from && e.to === value?.to
  )?.value;
  if (foundValue) {
    return foundValue;
  }
  return `${value?.from / 12} năm - ${value?.to / 12} năm`;
};

export const resetQueries = (queries: [string], cache: ApolloCache) => {
  queries.forEach(query => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: query,
    });
  });
};

export function cropImage(src, startPoint, width, height) {
  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  return new Promise((resolve, reject) => {
    const imageToGetSize = new Image();

    imageToGetSize.onload = function () {
      ctx.drawImage(
        imageToGetSize,
        startPoint.x,
        startPoint.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
      canvas.toBlob(resolve);
    };

    imageToGetSize.onerror = function () {
      reject(new Error('Cannot get image'));
    };

    imageToGetSize.src = src;
  });
}
export function validateBirthDate18Age(date) {
  const eightYearsAgo = moment().subtract('years', 18);
  const birthday = moment(date);
  return eightYearsAgo.isAfter(birthday);
}

export function getUnique(arr, comp) {
  return arr
    .map(e => e[comp])

    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
}

export const merge2Array = (arr1, arr2) => {
  let combinedArr = [];
  arr1?.forEach(item => {
    if (!arr2?.map(e => e.key)?.includes(item.key)) {
      combinedArr.push(item);
    }
  });
  return combinedArr.concat(arr2);
};

export const getListPostStatus = status => {
  let color = 'red';
  switch (status) {
    case 'expired': {
      color = '#FF5151';
      break;
    }
    case 'active': {
      color = '#57B8FF';
      break;
    }
    default:
      break;
  }
  return color;
};

export const listPostStatus = [
  {
    key: 'active',
    value: 'Còn hạn',
  },
  {
    key: 'expired',
    value: 'Hết hạn',
  },
];

export const listStatus = [
  {
    key: 'isAbandoned',
    value: 'Sản phẩm bị cảnh báo',
  },
  {
    key: 'rejected',
    value: 'Sản phẩm bị từ chối',
  },
  {
    key: 'pending',
    value: 'Sản phẩm đang chờ duyệt',
  },
  {
    key: 'isExpired12hours',
    value: 'Sản phẩm quá 12h chưa được duyệt',
  },
];

export const getStatusProduct = (status = '') => {
  let text = '',
    color = '';
  switch (status) {
    case 'pause': {
      text = 'Tạm ngưng';
      color = '#e96bff';
      break;
    }
    case 'onSale': {
      text = 'Đang bán';
      color = '#57B8FF';
      break;
    }
    case 'rented': {
      text = 'Đã cho thuê';
      color = '#30A7FF';
      break;
    }
    case 'deposited': {
      text = 'Đã đặt cọc';
      color = '#EC5E31';
      break;
    }
    case 'negotiating': {
      text = 'Đang thương lượng';
      color = '#BEBD7F';
      break;
    }
    case 'renting': {
      text = 'Đang cho thuê';
      color = '#57B8FF';
      break;
    }
    case 'cannotContact': {
      text = 'Không liên lạc được';
      color = '#B1B1B1';
      break;
    }
    case 'waitingForDeposit': {
      text = 'Đang chờ đặt cọc';
      color = '#308446';
      break;
    }
    case 'sold': {
      text = 'Đã bán';
      color = '#8B77FF';
      break;
    }
    case 'trading': {
      text = 'Còn nhu cầu';
      color = '#48FF3E';
      break;
    }
    case 'suspend': {
      text = 'Ngưng nhu cầu';
      color = '#ffcb76';
      break;
    }
    case 'stop': {
      text = 'Hết nhu cầu';
      color = '#ff5151';
      break;
    }
    case 'active': {
      text = 'Còn hạn';
      color = '#57B8FF';
      break;
    }
    case 'expired': {
      text = 'Hết hạn';
      color = '#ff5151';
      break;
    }
    default:
      break;
  }
  return { text, color };
};

export const getProductStatusV2 = status => {
  let color = 'red';
  switch (status) {
    case 'negotiating': {
      color = '#BEBD7F';
      break;
    }
    case 'waitingForDeposit': {
      color = '#308446';
      break;
    }
    case 'deposited': {
      color = '#EC5E31';
      break;
    }
    case 'onSale': {
      color = '#57B8FF';
      break;
    }
    case 'renting': {
      color = '#57B8FF';
      break;
    }
    case 'sold': {
      color = '#8B77FF';
      break;
    }
    case 'rented': {
      color = '#30A7FF';
      break;
    }
    case 'pause': {
      color = '#E96BFF';
      break;
    }
    case 'cannotContact': {
      color = '#B1B1B1';
      break;
    }
    case 'unknown': {
      break;
    }
    case 'rejected': {
      break;
    }
    case 'pending': {
      break;
    }
    default:
      break;
  }
  return color;
};

export function deepDiff(fromObject, toObject, specificPaths) {
  const changes = {};
  console.log('specificPaths:', specificPaths);

  const buildPath = (path, obj, key) =>
    _.isUndefined(path) ? key : `${path}.${key}`;

  let obj1 = {},
    obj2 = {};
  if (specificPaths && specificPaths.length > 0) {
    // only look at specific paths if specified
    for (const path of specificPaths) {
      if (fromObject[path]) obj1[path] = fromObject[path];
      if (toObject[path]) obj2[path] = toObject[path];
    }
  } else {
    obj1 = fromObject;
    obj2 = toObject;
  }

  const walk = (fromObject, toObject, path) => {
    for (const key of _.keys(fromObject)) {
      const currentPath = buildPath(path, fromObject, key);
      if (!_.has(toObject, key)) {
        changes[currentPath] = { from: _.get(fromObject, key) };
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_.has(fromObject, key)) {
        changes[currentPath] = { to };
      } else {
        const from = _.get(fromObject, key);
        if (!_.isEqual(from, to)) {
          if (_.isObjectLike(to) && _.isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes[currentPath] = { from, to };
          }
        }
      }
    }
  };

  walk(obj1, obj2);

  return changes;
}
export function convertValueSort(sort) {
  if (!sort) {
    return null;
  }
  if (sort.includes('createdAt')) {
    if (sort === 'createdAt-1') {
      return { postType: 1, createdAt: -1 };
    }
    return { postType: 1, createdAt: 1 };
  }
  if (sort.includes('price')) {
    if (sort === 'price-1') {
      return { postType: 1, price: -1 };
    }
    return { postType: 1, price: 1 };
  }
}
export function interpolate(inputNumber, { inputRange, outputRange }) {
  const firstInput = inputRange[0],
    lastInput = inputRange[1],
    firstOutput = outputRange[0],
    lastOutput = outputRange[1];
  if (inputNumber <= firstInput) {
    return firstOutput;
  } else if (inputNumber >= lastInput) {
    return lastOutput;
  }
  return (
    (inputNumber - firstInput) *
      ((lastOutput - firstOutput) / (lastInput - firstInput)) +
    firstOutput
  );
}

export const pickByFromTo = (from, to) => {
  const floatFrom = parseFloat(from),
    floatTo = parseFloat(to);

  const t = pickBy(
    { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
    e => e
  );
  return isEmpty(t) ? null : t;
};

export function getTagRealEstate({
  isForSell,
  isNeedToBuy,
  demandEstate,
  typeOfDemand,
}) {
  if (!demandEstate) {
    if (typeOfDemand === 'sale') {
      return '/svg/tagSale.svg';
    }
    return '/svg/tagRent.svg';
  } else if (demandEstate === estate) {
    if (isForSell) {
      return '/svg/tagSale.svg';
    }
    return '/svg/tagRent.svg';
  } else if (isNeedToBuy) {
    return '/svg/tagNeedBuy.svg';
  } else return '/svg/tagNeedRent.svg';
}
export const useRenderPriceStagesRangeProject = item => {
  const [from, setFrom] = useState(''),
    [to, setTo] = useState(''),
    [unitFrom, setUnitFrom] = useState(''),
    [unitTo, setUnitTo] = useState('');
  useEffect(() => {
    if (!item?.from && !item?.to) {
      setFrom(0);
      setTo(0);
    } else {
      if (item?.from >= 100000000) {
        setFrom(parseFloat(parseFloat(item?.from / 1000000000).toFixed(1)));
      }
      if (item?.from >= 100000 && item?.from < 100000000) {
        setFrom(parseFloat(parseFloat(item?.from / 1000000).toFixed(1)));
      }
      if (item?.from >= 1000 && item?.from < 100000) {
        setFrom(parseFloat(parseFloat(item?.from / 1000).toFixed(1)));
      }
      if (item?.from > 0 && item?.from < 1000) {
        setFrom(parseFloat(parseFloat(item?.from).toFixed(1)));
      }
      if (item?.from === 0) {
        setFrom('');
      }
      if (item?.to >= 100000000) {
        setTo(parseFloat(parseFloat(item?.to / 1000000000).toFixed(1)));
      }
      if (item?.to >= 100000 && item?.to < 100000000) {
        setTo(parseFloat(parseFloat(item?.to / 1000000).toFixed(1)));
      }
      if (item?.to >= 1000 && item?.to < 100000) {
        setTo(parseFloat(parseFloat(item?.to / 1000).toFixed(1)));
      }
      if (item?.to > 0 && item?.to < 1000) {
        setTo(parseFloat(parseFloat(item?.to).toFixed(1)));
      }
      if (item?.to === 0) {
        setTo('');
      }
    }
  }, [item]);
  useEffect(() => {
    if (item?.from >= 100000000) {
      setUnitFrom('Tỷ');
    }
    if (item?.from >= 100000 && item?.from < 100000000) {
      setUnitFrom('Triệu');
    }
    if (item?.from >= 1000 && item?.from < 100000) {
      setUnitFrom('Nghìn');
    }
    if (item?.from > 0 && item?.from < 1000) {
      setUnitFrom('Đồng');
    }
    if (item?.from === 0) {
      setUnitFrom('');
    }
    if (item?.to >= 100000000) {
      setUnitTo('Tỷ');
    }
    if (item?.to >= 100000 && item?.to < 100000000) {
      setUnitTo('Triệu');
    }
    if (item?.to >= 1000 && item?.to < 100000) {
      setUnitTo('Nghìn');
    }
    if (item?.to > 0 && item?.to < 1000) {
      setUnitTo('Đồng');
    }
    if (item?.to === 0) {
      setUnitTo('');
    }
  }, [item]);
  return { from, to, unitFrom, unitTo };
};

export const convertFromToPriceValue = (from, to, unitFrom, unitTo) => {
  if ((!from && !to) || (from === 0 && to === 0)) {
    return <span className="italic text-gray-500">Không có</span>;
  } else if (from && !to) {
    return `>=${from} ${unitFrom}`;
  } else if (!from && to) {
    return `<=${to} ${unitTo}`;
  } else {
    return `${from} ${unitFrom} - ${to} ${unitTo}`;
  }
};

export function getChildrenIds({ children = [] } = {}) {
  if (!children) {
    return [];
  }
  let ids = [];
  children.forEach(child => {
    ids.push(child?.id);
    const nestedChildIds = getChildrenIds(child);
    ids = ids.concat(nestedChildIds);
  });
  return ids;
}

export function filterAndMapChildren(arr, selectedPermissions = []) {
  return arr
    .filter(e => {
      if (selectedPermissions.includes(e?.id)) {
        return true;
      }
      return (
        getChildrenIds({ children: e?.children }).findIndex(e =>
          selectedPermissions.includes(e)
        ) >= 0
      );
    })
    .map(e => {
      return {
        ...e,
        children: filterAndMapChildren(e?.children || [], selectedPermissions),
      };
    });
}

export function convertProvinceTree(data, parent = null) {
  return data?.map(item => ({
    ...item,
    parent,
    type: 'District',
  }));
}

export function convertFlatArrayToTree(array) {
  const finalArray = [];
  const provinces = array?.filter(a => a.type === 'Province'),
    districts = array?.filter(a => a.type === 'District');
  if (!districts?.length) {
    return [];
  }
  for (let province of provinces) {
    finalArray.push({
      ...province,
      districts: array?.filter(a => a.parent === province.key),
    });
  }
  return finalArray;
}

export function getChildByKey(nodes, id) {
  let array = [];

  //returns an array of nodes ids: clicked node id and all children node ids
  function getAllChild(nodes) {
    if (nodes === null) return [];
    array.push(nodes);
    if (Array.isArray(nodes?.children)) {
      nodes?.children.forEach(node => {
        array = [...array, ...getAllChild(node)];
        array = array.filter((v, i) => array.indexOf(v) === i);
      });
    }
    return array;
  }

  //returns the node object that was selected
  function getNodeById(nodes, id) {
    if (nodes.key === id) {
      return nodes;
    } else if (Array.isArray(nodes?.children)) {
      let result = null;
      nodes?.children.forEach(node => {
        if (getNodeById(node, id)) {
          result = getNodeById(node, id);
        }
      });
      return result;
    }

    return null;
  }

  return getAllChild(getNodeById(nodes, id));
}
const AUTH_COOKIES_KEY = 'authentication';
export const getAuth = () => {
  const cookiesValue = Cookies.get(AUTH_COOKIES_KEY);
  if (!cookiesValue) {
    return;
  }

  try {
    const auth = JSON.parse(cookiesValue);
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error('AUTH COOKIES PARSE ERROR', error);
  }
};

export const setAuth = auth => {
  try {
    const cookiesValue = JSON.stringify(auth);
    Cookies.set(AUTH_COOKIES_KEY, cookiesValue, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    });
  } catch (error) {
    console.error('AUTH COOKIES SAVE ERROR', error);
  }
};

export const removeAuth = () => {
  try {
    Cookies.remove(AUTH_COOKIES_KEY, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    });
  } catch (error) {
    console.error('AUTH COOKIES REMOVE ERROR', error);
  }
};
