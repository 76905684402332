import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import React from 'react';
import { useGetLocation } from 'scenes/home/manageProduct/configs';

type Props = {
  postTitle?: string,
  fullAddress?: string,
  postStatus?: string,
  isDeleted?: boolean,
  status?: string,
  tab?: string,
};
const getStatus = (postStatus, status) => {
  let ico, label, color, label2, color2;
  switch (postStatus) {
    case 'negotiating':
      ico = '/svg/detailRealEstate/Stt/dangthuongluong.svg';
      label = 'Đang thương lượng';
      color = '#BEBD7F';
      break;
    case 'waitingForDeposit':
      ico = '/svg/detailRealEstate/Stt/dangchodatcoc.svg';
      label = 'Đang chờ đặt cọc';
      color = '#308446';
      break;
    case 'deposited':
      ico = '/svg/detailRealEstate/Stt/dadatcoc.svg';
      label = 'Đã đặt cọc';
      color = '#EC5E31';
      break;
    case 'onSale':
      ico = '/svg/detailRealEstate/Stt/dangban.svg';
      label = 'Đang bán';
      color = '#57B8FF';
      break;
    case 'sold':
      ico = '/svg/detailRealEstate/Stt/daban.svg';
      label = 'Đã bán';
      color = '#8B77FF';
      break;
    case 'pause':
      ico = '/svg/detailRealEstate/Stt/tamngung.svg';
      label = 'Tạm ngưng';
      color = '#E96BFF';
      break;
    case 'cannotContact':
      ico = '/svg/detailRealEstate/Stt/khonglienlacduoc.svg';
      label = 'Không liên lạc được';
      color = '#B1B1B1';
      break;
    case 'waitingForContract':
      ico = '/svg/detailRealEstate/Stt/danglamhd.svg';
      label = 'Đang làm hợp đồng';
      color = '#7CDBF9';
      break;
    case 'signedContract':
      ico = '/svg/detailRealEstate/Stt/dalamhd.svg';
      label = 'Đã làm hợp đồng';
      color = '#7CDBF9';
      break;
    case 'active':
      ico = '/svg/detailRealEstate/Stt/conhan.svg';
      label = 'Còn hạn';
      color = '#57B8FF';
      break;
    case 'expired':
      ico = '/svg/detailRealEstate/Stt/hethan.svg';
      label = 'Hết hạn';
      color = '#FF5151';
      break;
    case 'renting':
      ico = '/svg/detailRealEstate/Stt/dangchothue.svg';
      label = 'Đang cho thuê';
      color = '#57B8FF';
      break;
    case 'rented':
      ico = '/svg/detailRealEstate/Stt/dachothue.svg';
      label = 'Đã cho thuê';
      color = '#30A7FF';
      break;
    default:
      ico = '';
      label = 'Chưa có trạng thái';
      color = '#80888F';
      break;
  }
  switch (status) {
    case 'pending':
      label2 = 'Đang chờ duyệt';
      color2 = '#0368FF';
      break;
    case 'rejected':
      label2 = 'Bị từ chối';
      color2 = '#FF5151';
      break;
    case 'approved':
      label2 = 'Đã được duyệt';
      color2 = '#15D746';
      break;
  }
  return { ico, label, color, label2, color2 };
};

export default function HeadLeftProductDetail(props: Props) {
  const { postTitle, fullAddress, postStatus, status, tab } = props;
  const { ico, label, color, label2, color2 } = getStatus(postStatus, status);
  const { productPosted } = useGetTab();
  const { isTabProductPremium } = useGetLocation();
  return (
    <div
      className="flex w-full items-start border-b border-gray-300 space-x-6"
      style={{
        padding: '25px 30px 10px',
      }}
    >
      <div
        className="text-14 text-gray-300 font-medium flex-1"
        dangerouslySetInnerHTML={{
          __html: `<span class="text-black-main">Sản phẩm:</span> <span class="text-black-main">${
            postTitle || ''
          }</span> | <span class="text-black-main">${fullAddress || ''}</span>`,
        }}
      />
      <div className="flex items-center justify-end space-x-2 w-550px">
        {tab?.includes('my-product') && !isTabProductPremium && (
          <div className="flex flex-row items-center space-x-1 mr-7 justify-end">
            <div className="text-14 text-black-main justify-center">
              Tình trạng duyệt:
            </div>
            <div>
              <div
                className={`text-14 font-medium`}
                style={{
                  color: color2,
                  whiteSpace: 'nowrap',
                }}
              >
                {label2}
              </div>
            </div>
          </div>
        )}
        {!isTabProductPremium && (
          <div className="flex flex-row items-center space-x-1 justify-end">
            <div className="text-14 text-black-main justify-center">
              {productPosted ? 'Trạng thái tin rao:' : 'Trạng thái sàn:'}
            </div>
            <div className="flex flex-row items-center item-center space-x-1">
              <div className="flex items-center justify-center">
                {postStatus !== 'unknown' && (
                  <img alt="ico" className="w-11px h-11px" src={ico} />
                )}
              </div>
              <div
                className={`text-14 font-medium`}
                style={{
                  color,
                  whiteSpace: 'nowrap',
                }}
              >
                {label}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
