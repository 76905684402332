import MyButton from '@components/button';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

PermissionGroupDetailRight.propTypes = {
  permissionGroupId: PropTypes.string,
};
export default function PermissionGroupDetailRight({ permissionGroupId }) {
  const navigate = useNavigate();

  return (
    <Box className="flex flex-col items-center gap-4 max-w-240px rounded-rd8px bg-white px-4 py-8 shadow-md">
      <MyButton
        type="gray"
        label="Chỉnh sửa nhóm quyền mẫu"
        onPress={() => {
          navigate(
            `/manageOrganization/permission-groups/edit?id=${permissionGroupId}isEdit=true`
          );
        }}
      />
      <MyButton type="gray" label="Xoá nhóm quyền mẫu" />
    </Box>
  );
}
