import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ReadImageAndVideo from './readImageAndVideo';
import ModalViewMedia from '../../../../../../../modals/modalViewMedia';
import { getMedias } from '@utils/helper';
import { useDeepCompareEffect } from 'react-use';
import MyModalV2 from '@components/modalV2/myModalV2';

type Props = {
  imgUrls?: Array,
};

export default function ImageAndVideoList(props: Props) {
  const { imgUrls = [] } = props;
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [listUrls, setListUrls] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(imgUrls);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [imgUrls]);
  const renderImagesVideo = () => {
    if (isEmpty(imgUrls)) {
      return <div className="mt-2 text-14">Không có hình ảnh & video</div>;
    } else {
      return (
        <div className="grid grid-cols-3 gap-4">
          {imgUrls?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index < 7 && (
                  <ReadImageAndVideo
                    onPress={() => {
                      setShowModal(true);
                      setActiveIndex(index);
                    }}
                    idUrls={item}
                  />
                )}
                {index === 7 && (
                  <SeeMoreImage
                    seeMore
                    item={item}
                    total={imgUrls?.length - 8}
                    onPress={() => {
                      setShowModal(true);
                      setActiveIndex(7);
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="w-full space-y-2.5">
      <div className="text-base font-semibold text-blue-main">
        Hình ảnh & Video
      </div>
      {renderImagesVideo()}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={activeIndex} listMedia={listUrls} />
      </MyModalV2>
    </div>
  );
}

type SeeMoreImageProps = {
  onPress?: Function,
  item?: Object,
  total?: number,
};
function SeeMoreImage({ onPress, item, total }: SeeMoreImageProps) {
  return (
    <div className="w-full relative flex items-center justify-center cursor-pointer rounded overflow-hidden">
      <div
        className="text-15 font-medium text-white absolute"
        onClick={onPress}
        style={{ zIndex: 1000 }}
      >
        +{total}
      </div>
      <div
        style={{ opacity: 0.4 }}
        className="w-full h-full absolute bg-black"
      />
      <ReadImageAndVideo onPress={() => {}} idUrls={item} />
    </div>
  );
}
