import { useGetListDepartments } from '@utils/hooks/b2bUser/useGetListDepartments';
import ListView from '../components/ListView';
import React from 'react';

type ListDepartmentsModalProps = {
  onClose: Function,
  selectedItems: Array,
  setItems: Function,
  id?: string,
};
export default function ListDepartmentsModal(props: ListDepartmentsModalProps) {
  const { data } = useGetListDepartments(),
    { onClose, selectedItems, setItems, id } = props;
  let data2 = data?.map(e => ({ key: e.id, value: e.name }));
  return (
    <ListView
      id={id}
      selectedItems={selectedItems}
      setItems={setItems}
      onClose={onClose}
      data={data2}
      headerLabel="Chọn phòng ban"
      searchPlaceHolder="Tìm kiếm phòng ban"
    />
  );
}
