import React, { useCallback, useEffect } from 'react';
import useList from 'react-use/lib/useList';
import { isEqual } from 'lodash';
import Checkbox from '@components/checkbox';
import MyButton from '@components/button';
type Props = {
  context?: {
    selections?: Array,
    setValue?: Function,
    value?: Array,
  },
  close?: Function,
};

export default React.memo(MultiCheckDropdownComponent, isEqual);

function MultiCheckDropdownComponent(props: Props) {
  const { context, close } = props,
    { selections = [], value: initialValue, setValue } = context;

  const [value, { filter, push, set }] = useList([]);

  useEffect(() => {
    if (initialValue?.length > 0) {
      set([...initialValue]);
    }
  }, [initialValue]);

  const isCheckedAll = value?.length === selections?.length,
    isPartialCheckedAll =
      value?.length > 0 && value?.length < selections?.length;
  const onCheckedAll = useCallback(() => {
    if (isCheckedAll) {
      set([]);
    } else {
      set([...selections?.map(item => item.key)]);
    }
  }, [isCheckedAll, selections, set]);

  const onCheckedItem = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        filter(e => e !== key);
      } else {
        push(key);
      }
    },
    [filter, push]
  );

  const onConfirm = useCallback(() => {
    setValue([...value]);
    close();
  }, [close, setValue, value]);

  return (
    <div
      style={{
        minWidth: 250,
      }}
      className="w-full flex-1 bg-white rounded-lg border border-blue-main"
    >
      <div className="flex-1 px-4 py-6 space-y-4 h-250px overflow-scroll">
        <Checkbox
          title="Tất cả"
          isChecked={isCheckedAll}
          isPartialChecked={isPartialCheckedAll}
          onPress={onCheckedAll}
        />
        {selections.map((item, index) => {
          const isChecked = value.includes(item.key);
          return (
            <Checkbox
              key={index}
              title={item.value}
              wrapperStyle={{ marginBottom: 16 }}
              isChecked={isChecked}
              onPress={() => onCheckedItem(item.key, isChecked)}
            />
          );
        })}
      </div>
      <div
        style={{
          width: '99%',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        className={`w-full px-3 py-2 flex flex-row border-t border-gray-300 sticky bottom-0 space-x-2`}
      >
        <MyButton
          wrapperClass="flex-1"
          onPress={close}
          label="Trở về"
          type="gray"
        />
        <MyButton
          disabled={!value?.length}
          wrapperClass="flex-1"
          onPress={onConfirm}
          label="Xác nhận"
          type="blue"
        />
      </div>
    </div>
  );
}
