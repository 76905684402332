import React from 'react';
import Section from './section';
import { convertFloatWithDot, DocTienBangChuV2 } from '@utils/helper';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import DmsCoordinates from 'dms-conversion';

type Props = {
  data?: Object,
};

export default function DetailProject({ data }: Props) {
  const { projectForSaleTypes, projectSubTypes } = configs;
  const projectType = data?.projectType;

  return (
    <Section
      source="/svg/notes_1.svg"
      title="Thông tin dự án"
      style={{ flex: 1 }}
      innerClass="mt-4"
    >
      <Item
        src="/svg/detailRealEstate/loai.svg"
        title="Loại hình"
        value={
          'Bán ' +
          projectForSaleTypes
            ?.find(e => e.key === projectType)
            ?.value.toLowerCase()
        }
      />
      <Item
        src="/svg/detailRealEstate/giadukien.svg"
        title="Giá bán"
        value={`${DocTienBangChuV2(
          data?.priceRange?.from
        )} - ${DocTienBangChuV2(data?.priceRange?.to)}`}
        valueStyle={'text-red-600'}
      />
      <Item
        src="/svg/detailRealEstate/diachi.svg"
        title="Địa chỉ"
        value={data?.fullAddress}
      />
      <Item
        src="/svg/detailRealEstate/toado.svg"
        title="Tọa độ"
        valueStyle="flex-col"
        value={
          data?.map?.coordinates?.length
            ? new DmsCoordinates(
                data?.map?.coordinates[1],
                data?.map?.coordinates[0]
              ).toString()
            : null
        }
        linkContent="Xem trên Google map"
        link={`https://www.google.com/maps/place/${data?.map?.coordinates[1]},
        ${data?.map?.coordinates[0]}`}
      />
      <Item
        src="/svg/detailRealEstate/TenDuAn.svg"
        title="Tên dự án"
        value={data?.projectName || data?.project}
      />
      <Item
        src="/svg/donviphanphoi.svg"
        title="Đơn vị phân phối độc quyền chính"
        value={
          data?.exclusiveSupplier?.length > 0 ? data?.exclusiveSupplier : null
        }
      />
      <Item
        src="/svg/donviphanphoi.svg"
        title="Đơn vị phân phối độc quyền chính"
        value={
          data?.exclusiveSupplierSub?.length > 0
            ? data?.exclusiveSupplierSub
            : null
        }
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Tổng diện tích dự án"
        value={convertFloatWithDot(data?.projectArea?.area)}
        unit="m²"
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Tổng diện xây dựng"
        value={convertFloatWithDot(data?.totalAreaBuild?.area)}
        unit="m²"
      />
      <Item
        src="/svg/quymo.svg"
        title="Quy mô dự án"
        value={data?.projectScale}
      />
      <Item
        src="/svg/loaiduan.svg"
        title="Loại dự án"
        value={data?.projectSubType
          ?.map(e => {
            return projectSubTypes.find(a => a.key === e)?.value;
          })
          .join(', ')}
      />
      <Item
        src="/svg/soluongblock.svg"
        title="Số lượng block"
        value={data?.totalBlock}
      />
      {data?.floorCountPerBlockRange?.from && (
        <Item
          src="/svg/detailRealEstate/sotang.svg"
          title="Số lượng block"
          value={`${data?.floorCountPerBlockRange?.from} - ${data?.floorCountPerBlockRange?.to}`}
        />
      )}
    </Section>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  unit?: string,
  src?: string,
  valueStyle?: Object,
  hideField?: boolean,
  linkContent?: string,
  link?: string,
  value2?: string,
};

function Item({
  title,
  value = '',
  unit = '',
  src,
  valueStyle,
  hideField,
  link,
  linkContent = '',
  value2 = '',
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex w-full justify-between py-3 border-b border-gray-300">
      <div className="flex items-center space-x-2">
        <img alt="ico" src={src} className="w-4 h-4" />
        <div className={`text-sm mr-6 font-medium text-black-main w-230px`}>
          {title}
        </div>
      </div>
      <div className={`text-sm text-right flex ${valueStyle}`}>
        {value}
        {unit}
        <div
          className="ml-1"
          dangerouslySetInnerHTML={{
            __html: value2,
          }}
        />
        {linkContent ? (
          <div>
            <br />
            <a
              className="text-blue-main underline"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {linkContent}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
