import React, { useState } from 'react';
import MyButton from '@components/button';
import MyDropdown from '@components/myDropdown';
import CalendarFromToDropdown from '@components/calendarFromTo';
import moment from 'moment/moment';
import styles from '../../../manageProduct/components/header.module.scss';
import SearchBox from '@components/searchBox';
import { useManageProjectContext } from '../context';

type Props = {
  title?: string,
  currentTotal?: number,
  total?: number,
  department?: string,
  setDepartment?: Function,
};

export default function RenderTopTable({
  title,
  currentTotal = 0,
  total = 0,
}: Props) {
  const { stateFilter, setStateFilterAt, setListSelected } =
    useManageProjectContext();
  const [disableDropdown, setDisableDropdown] = useState(false);
  return (
    <div className="w-full flex flex-row py-2.5 overflow-auto space-x-2 items-center">
      <div style={{ minWidth: 300 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="text-18 font-medium text-black-main">{title}</div>
          <div className="text-14 text-black-main">
            ({currentTotal}/{total} dự án)
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 flex-row items-center space-x-4 justify-end">
        <div className="w-fit">
          <MyButton
            labelStyle="pl-2"
            onPress={() => {}}
            type="blue"
            label="Thêm dự án"
            rightComponent={() => (
              <img alt="plus-icon" src="/svg/plus-icon.svg" />
            )}
          />
        </div>
        <div className="smDT:w-340px justify-end w-240px flex items-center space-x-1">
          <div className="smDT:block hidden text-14 font-medium text-black-main">
            Thời gian đăng
          </div>
          <MyDropdown
            key={Math.random()}
            disabled={disableDropdown}
            DropdownComponent={CalendarFromToDropdown}
            buttonDropdownClassName="px-2.5 border border-blue-main rounded-lg h-40px"
            dropdownContext={{
              startDay: stateFilter?.startDay,
              endDay: stateFilter?.endDay,
              setValue: nextValue => {
                setStateFilterAt('startDay')(nextValue.startDay);
                setStateFilterAt('endDay')(nextValue.endDay);
                setListSelected.clear();
              },
              maxDate: moment(),
              disabledAddTo: true,
            }}
          >
            <div className="w-214px flex flex-row items-center justify-between space-x-2">
              <div className="w-full flex flex-row items-center justify-between space-x-1">
                <div className="text-sm text-blue-main text-left truncate">
                  {stateFilter?.startDay && stateFilter?.endDay
                    ? `${moment
                        .unix(stateFilter?.startDay)
                        .format('DD/MM/YYYY')} - ${moment
                        .unix(stateFilter?.endDay)
                        .format('DD/MM/YYYY')}`
                    : 'Chọn giá trị'}
                </div>
                {stateFilter?.startDay && stateFilter?.endDay && (
                  <img
                    onClick={() => {
                      setDisableDropdown(true);
                      setStateFilterAt('startDay')('');
                      setStateFilterAt('endDay')('');
                      setTimeout(() => {
                        setDisableDropdown(false);
                      }, 1000);
                    }}
                    alt="ico"
                    src="/svg/xIcon.svg"
                    className="w-3 h-3 cursor-pointer"
                  />
                )}
              </div>
              <img alt="ico" src="/svg/calendar-icon-dropdown.svg" />
            </div>
          </MyDropdown>
        </div>
        <div className={`flex ${styles.headerContainer}`}>
          <div className="w-170px">
            <MyButton
              wrapperClass="text-14 text-sm font-medium"
              onPress={() => {}}
              style={{ border: '1px solid #2459ad' }}
              type="white"
              label="Nhập từ Excel"
              rightComponent={() => <img alt="ico" src="/svg/icon-excel.svg" />}
            />
          </div>
          <div className="w-180px">
            <MyButton
              onPress={() => {}}
              wrapperClass="text-14 text-sm font-medium"
              style={{ border: '1px solid #2459ad' }}
              type="white"
              label="Chỉnh sửa hiển thị"
              rightComponent={() => (
                <img alt="ico" src="/svg/filter-icon.svg" />
              )}
            />
          </div>
        </div>
        <SearchBox
          searchValue={stateFilter.searchString}
          placeholder="Tìm kiếm sản phẩm"
          setSearchValue={value => setStateFilterAt('searchString')(value)}
          onDeleteValue={() => setStateFilterAt('searchString')('')}
        />
      </div>
    </div>
  );
}
