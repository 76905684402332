import { useQuery } from '@apollo/client';
import { LIST_DEPARTMENTS } from '@utils/graphql/queries/department';

export const useGetDepartments = () => {
  const { data, loading } = useQuery(LIST_DEPARTMENTS);
  const finalData = data?.b2bGetListDepartments;
  return {
    finalData,
    loading: loading,
  };
};
