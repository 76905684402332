import React from 'react';

type Props = {
  currentColor?: string,
  nextColor?: string,
  icon?: string,
  isImg?: boolean,
};

export default function TriangleStatusComponent(props: Props) {
  const { currentColor, nextColor, icon, isImg } = props;
  if (isImg) {
    return (
      <div className="flex flex-col items-end mt-1.5">
        <img src={icon} alt="icon" />
      </div>
    );
  }
  return (
    <div className="flex flex-col items-end mt-1.5">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill={currentColor}
        xmlns={icon}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 14H0L14 0"
          fill={nextColor}
        />
      </svg>
    </div>
  );
}
