import { Controller, useFormContext } from 'react-hook-form';
import PasswordInput from '@components/passwordInput';
import MyButton from '@components/button';
import React from 'react';
import { useUpdateEmployeeLoginInfo } from '@utils/hooks/b2bUser/useUpdateEmployeeLoginInfo';
import MyInput from '@components/myInput';
import Label from '@components/label';
import { regexValidPassword, USERNAME_REGEX } from '@utils/configs/regex';
type Props = {
  onClose?: Function,
  setShowModalSuccess?: Function,
};

export default function Content({ onClose }: Props) {
  const { control, handleSubmit, watch } = useFormContext();
  const { onSubmit, loading, error } = useUpdateEmployeeLoginInfo(onClose);
  const wrapperClass = 'w-230px';
  return (
    <div className="w-full mt-4">
      {error && <div className="text-11 text-red-500">{error?.message}</div>}
      <div className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass={wrapperClass}
            isRequired={true}
            label="Tên đăng nhập"
          />
          <Controller
            rules={{
              required: 'Vui lòng nhập tên đăng nhập',
              validate: {
                checkPass: value =>
                  value?.length === 0
                    ? true
                    : value?.toString()?.match(USERNAME_REGEX)
                    ? true
                    : 'Vui lòng nhập tên đăng nhập hợp lệ',
              },
            }}
            control={control}
            name="username"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errors={errors}
                  inputClassName="w-full"
                  placeholder="Nhập tên đăng nhập"
                  onDelete={() => onChange('')}
                  maxLength={118}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Mật khẩu mới" />
          <Controller
            control={control}
            name="password"
            rules={{
              validate: {
                checkPass: value =>
                  value?.length === 0
                    ? true
                    : value?.toString()?.match(regexValidPassword)
                    ? true
                    : 'Vui lòng nhập mật khẩu 6-32 ký tự, gồm có ít nhất một chữ cái thường và 1 chữ cái in hoa',
              },
            }}
            render={({
              field,
              field: { onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => {
              return (
                <PasswordInput
                  {...field}
                  type="password"
                  invalid={invalid}
                  errors={errors}
                  inputClassName="w-full"
                  placeholder="Mật khẩu mới từ 6 đến 32 ký tự"
                  onDelete={() => onChange('')}
                  maxLength={32}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Xác nhận mật khẩu mới" />
          <Controller
            rules={{
              validate: {
                checkMatchPassword: value =>
                  value === watch('password') ||
                  'Bạn nhập lại mật khẩu không đúng. Vui lòng nhập lại',
              },
            }}
            control={control}
            name="confirmPassword"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid },
              formState: { errors },
            }) => {
              return (
                <PasswordInput
                  {...field}
                  type="password"
                  invalid={invalid}
                  errors={errors}
                  inputClassName="w-full"
                  inputInnerClassName="w-full"
                  placeholder="Xác nhận mật khẩu mới"
                  onDelete={() => onChange('')}
                  maxLength={32}
                  hideMaxLength
                />
              );
            }}
          />
        </div>
        <div className="w-full bg-white h-61px flex flex-row mt-5 items-center justify-end p-x-6 space-x-2 sticky bottom-0 border-t border-gray-300">
          <div className="w-147px">
            <MyButton
              disabled={loading}
              onPress={onClose}
              label="Trở về"
              type="gray"
            />
          </div>
          <div className="w-147px">
            <MyButton
              disabled={loading}
              type="blue"
              label={loading ? 'Đang xử lý' : 'Xác nhận'}
              wrapperClass="w-full"
              onPress={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
