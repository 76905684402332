import { convertFloatWithDot, getMediaUrlByType } from '@utils/helper';

export const useDescriptionRealEstate = ({ item, v1 = true }) => {
  const isNeededRealEstate = item?.demandEstate === 'need_estate';

  const area = !isNeededRealEstate ? item?.area : item?.area?.to,
    getWidth = !isNeededRealEstate ? item?.width : item?.width?.to,
    getLength = !isNeededRealEstate ? item?.length : item?.length?.to,
    floorCount = !isNeededRealEstate ? item?.floorCount : item?.floorCount?.to,
    bedroomCount = !isNeededRealEstate
      ? item?.bedroomCount
      : item?.bedroomCount?.to,
    toiletCount = !isNeededRealEstate
      ? item?.toiletCount
      : item?.toiletCount?.to;

  const width = convertFloatWithDot(getWidth?.toFixed(2));
  const length = convertFloatWithDot(getLength?.toFixed(2));

  const widthLength = `${width || 0}m x ${length || 0}m`;

  const arrV1 = [
    {
      icon: '/svg/areaBlue.svg',
      value: area > 0 && convertFloatWithDot(area?.toFixed(2)) + 'm²',
    },
    {
      icon:
        width > 0 || length > 0
          ? '/svg/rulerBlue.svg'
          : '/svg/cil_bathroomBlue.svg',
      value: width > 0 || length > 0 ? widthLength : toiletCount || 0,
    },
    {
      icon: '/svg/stairsBlue.svg',
      value: `${floorCount || 0} tầng`,
    },
    {
      icon: '/svg/bedBlue.svg',
      value: bedroomCount || 0,
    },
  ];

  const arrV2 = [
    {
      icon:
        width > 0 || length > 0
          ? '/svg/rulerBlue.svg'
          : '/svg/cil_bathroomBlue.svg',
      value: width > 0 || length > 0 ? widthLength : toiletCount || 0,
    },
    {
      icon: '/svg/bedBlue.svg',
      value: bedroomCount || 0,
    },
    {
      icon: '/svg/stairsBlue.svg',
      value: `${floorCount || 0} tầng`,
    },
    {
      icon: '/svg/areaBlue.svg',
      value: area > 0 && convertFloatWithDot(area?.toFixed(2)) + 'm²',
    },
  ];

  return v1 ? arrV1 : arrV2;
};

export function getImage(mediaUrls) {
  const firstImage = mediaUrls?.find(e => e?.type === 'image') || {};
  const firstImageUrl = getMediaUrlByType(firstImage);
  if (firstImageUrl?.length) {
    return firstImageUrl;
  }
  const thumbnailYoutube = mediaUrls?.find(e => e?.type === 'youtube')
    ?.youtubeMetadata?.thumbnailUrl;
  if (thumbnailYoutube?.length) {
    return thumbnailYoutube;
  }

  const thumbnailVideo = mediaUrls?.find(e => e?.type === 'video');

  if (thumbnailVideo) {
    return thumbnailVideo?.urls.find(e => e?.type === 'thumbnailHd')?.url;
  }

  return null;
}
