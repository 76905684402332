import { useQuery } from '@apollo/client';
import { B2B_GET_USER_INFO } from '@utils/graphql';

export const useGetUserInfo = id => {
  const { data, loading } = useQuery(B2B_GET_USER_INFO, {
    variables: {
      id,
    },
    skip: !id,
  });
  const finalData = data?.b2bGetUserDetailsForBO;
  return { data: finalData, loading: loading };
};
