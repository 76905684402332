import { isEqual, isString, lt, map } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { RoutersConfigs } from '.';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import { useEffect, useRef } from 'react';

HorizontalMenu.propTypes = {
  onClickUrl: PropTypes.func,
  onClickSpotlightIcon: PropTypes.func,
};

export default function HorizontalMenu({ onClickUrl, onClickSpotlightIcon }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const handleExternalLinkClick = () => {
    onClickUrl?.();
  };
  const handleClickSpotlightIcon = () => {
    onClickSpotlightIcon?.();
  };
  const routers = RoutersConfigs();

  return (
    <div className="flex items-center bg-white shadow-sm px-2 py-2 space-x-4 w-full">
      <button
        ref={prevRef}
        id="swiper-back-horizontal-menu"
        type="button"
        className="btn h-40px w-60px text-gray-700"
      >
        <img className="w-42px h-42px" alt="image" src="/svg/arrow_nav.svg" />
      </button>

      <Swiper
        freeMode
        slidesPerView="auto"
        modules={[Navigation]}
        // navigation={{
        //   prevEl: `#swiper-back-horizontal-menu`,
        //   nextEl: `#swiper-next-horizontal-menu`,
        // }}
        navigation={{
          prevEl: prevRef.current, // Sử dụng ref.current cho prevEl
          nextEl: nextRef.current, // Sử dụng ref.current cho nextEl
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        className="d-flex user-select-none flex-grow-1"
      >
        {map(routers, router => {
          const isExternalLink = router?.path?.startsWith('https');
          if (isEqual(router?.id, 0)) {
            return (
              <SwiperSlide key={router?.id} className="w-152px">
                <div
                  id="total-button"
                  className="flex flex-col justify-center items-center w-120px min-w-120px h-110px min-h-110px hover:bg-blue-light rounded-lg"
                  onClick={handleClickSpotlightIcon}
                >
                  <img
                    className="w-42px h-42px"
                    alt="image"
                    src="/icons/modules/total.svg"
                  />
                  <span className=" font-semi pt-2 text-12 leading-4 text-black">
                    Tất cả
                  </span>
                </div>
              </SwiperSlide>
            );
          } else {
            if (isExternalLink) {
              return (
                <SwiperSlide key={router?.id} className="w-152px">
                  <button className="rounded-lg whiteButton hover:bg-blue-light">
                    <a
                      href={router?.path}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleExternalLinkClick}
                      className="flex flex-col items-center pt-6 w-120px min-w-120px h-110px min-h-110px"
                    >
                      <img
                        className="w-42px h-42px"
                        alt="image"
                        src={router?.icon}
                      />
                      <span className="font-semi pt-2 text-12 leading-4 text-black text-center">
                        {router?.title}
                      </span>
                    </a>
                  </button>
                </SwiperSlide>
              );
            }
            return (
              <SwiperSlide key={router?.id} className="w-152px">
                <div className="w-120px bg-hover-light-primary rounded-lg d-flex flex-column hover:bg-blue-light">
                  <NavLink
                    to={router.path || ''}
                    className="flex flex-col items-center pt-6 w-120px min-w-120px h-110px min-h-110px"
                  >
                    <img
                      className="w-42px h-42px"
                      alt="image"
                      src={router?.icon}
                    />
                    <span className="font-semi pt-2 text-12 leading-4 text-black text-center">
                      {router?.title}
                    </span>
                  </NavLink>
                </div>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
      <button
        ref={nextRef}
        id="swiper-next-horizontal-menu"
        type="button"
        className="btn h-40px w-60px text-gray-700"
        style={{ rotate: '180deg' }}
      >
        <img className="w-42px h-42px" alt="image" src="/svg/arrow_nav.svg" />
      </button>
    </div>
  );
}
