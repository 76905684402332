import RadioButton from '@components/radio';
import { useFormContext } from 'react-hook-form';
import React from 'react';

export default function SwitchAccountType() {
  const { setValue, watch, clearErrors } = useFormContext();
  const isPersonal = watch('isPersonal');
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="flex flex-row w-full h-40px rounded-lg mt-10 items-center px-3 space-x-7"
    >
      <RadioButton
        title="Doanh nghiệp"
        isChecked={!isPersonal}
        onPress={() => {
          setValue('isPersonal', false);
          clearErrors();
        }}
      />
      <RadioButton
        title="Cá nhân"
        isChecked={isPersonal}
        onPress={() => {
          setValue('isPersonal', true);
          clearErrors();
        }}
      />
    </div>
  );
}
