type Props = {
  isEdit?: boolean,
};

export default function Header({ isEdit }: Props) {
  return (
    <div className="w-full">
      <div style={{ fontSize: 18 }} className="font-medium text-black-main">
        {isEdit ? 'Chỉnh sửa thông tin khách hàng' : 'Tạo tài khoản'}
      </div>
      <div
        className="text-sm text-black-main mt-1"
        dangerouslySetInnerHTML={{
          __html: `<b class="font-medium">Quản lý khách hàng B2B/ </b> ${
            isEdit ? 'Chỉnh sửa thông tin khách hàng' : 'Tạo tài khoản'
          }`,
        }}
      />
    </div>
  );
}
