import MyButton from '@components/button';
import SwitchInner from '@components/switchInner';
import { getMedias } from '@utils/helper';
import { useGetContractDetail } from '@utils/hooks/manageContract/useGetContractDetail';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import ContractInfoDetail from './components/contractInfoDetail';
import RenderRightButton from './components/renderRightButton';
import PermissionDetail from './components/permissionDetail';
import Modules from './components/modules';

const switchTabConfigs = () => {
  return [
    {
      key: '0',
      value: 'Chi tiết hợp đồng',
    },
    {
      key: '1',
      value: 'Cấp quyền',
    },
  ];
};

type Props = {
  contractId?: string,
  onClose?: Function,
  isLocked?: boolean,
  b2bUserData?: Object,
  onSuccess?: Function,
};
export default function ContractDetailModal({
  contractId,
  onClose,
  isLocked,
  b2bUserData,
  onSuccess,
}: Props) {
  const [currentTab, setCurrentTab] = useState('0');
  const [listAttachment, setListAttachment] = useState([]);
  const tabConfigs = switchTabConfigs();
  const { contractDetail } = useGetContractDetail({ id: contractId });
  const { permissions, modules } = contractDetail ?? {};

  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case '0':
        return (
          <ContractInfoDetail
            contractDetail={contractDetail}
            listAttachment={listAttachment}
            contractId={contractId}
            b2bUserData={b2bUserData}
          />
        );
      case '1':
        return (
          <>
            <Modules modules={modules} />
            <PermissionDetail permissions={permissions ?? []} />
          </>
        );
    }
  }, [currentTab, contractId, contractDetail, listAttachment, b2bUserData]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(contractDetail?.attachments || []);
        setListAttachment(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [contractDetail?.attachments]);
  return (
    <div
      className="h-fit bg-white rounded-sm overflow-hidden"
      style={{
        width: 1440,
        maxWidth: 'calc(100vw - 80px)',
        borderRadius: 8,
      }}
    >
      <div className="relative pt-4 pb-6 flex justify-center items-center">
        <h1 className="text-[16px] leading-[20px] font-medium">
          Chi tiết hợp đồng
        </h1>
        <img
          style={{ zIndex: 1000 }}
          onClick={onClose}
          alt="ico"
          src="/svg/ico-close.svg"
          className="w-6 h-6 absolute right-4 top-4 cursor-pointer"
        />
      </div>
      <div
        className="p-4 mb-6"
        style={{
          background: '#f4f4f4',
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        <div className="w-full h-full flex">
          <div
            className="flex flex-col flex-1 bg-white overflow-hidden"
            style={{
              borderRadius: 8,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
            }}
          >
            <div className="sticky top-0 bg-white">
              <div className="border-b border-gray-300 pt-6 pb-2 px-8">
                <span className="font-medium text-14">
                  Mã hợp đồng:{' '}
                  {contractDetail?.contractCode ? (
                    `${contractDetail?.contractCode}`
                  ) : (
                    <span className="text-14 italic text-gray-400">
                      Không có
                    </span>
                  )}
                </span>
              </div>
              <SwitchInner
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                configs={tabConfigs}
              />
            </div>
            {renderContent}
          </div>
          <RenderRightButton
            onCloseDetailModal={onClose}
            onSuccess={onSuccess}
            isLocked={isLocked}
            contractId={contractId}
            contractDetail={contractDetail}
            listAttachment={listAttachment}
            b2bUserData={b2bUserData}
          />
        </div>
      </div>
      <div className="w-full h-60px px-4 flex justify-end items-center border-t border-gray-300 sticky bottom-0 bg-white">
        <div className="w-100px">
          <MyButton
            wrapperClass="border-none"
            onPress={onClose}
            type="gray"
            label="Đóng"
          />
        </div>
      </div>
    </div>
  );
}
