import React from 'react';
import Section from './section';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';

type Props = {
  data?: Object,
};

export default function ContactInfo({ data }: Props) {
  return (
    <Section title="Thông tin liên lạc" source="/svg/ph_address-book.svg">
      <div className="flex w-full mt-4 py-2 border-b border-gray-300 space-x-6">
        <div className="w-120px text-sm font-medium text-black-main">
          Họ tên
        </div>
        <div className="flex-1 text-sm font-medium text-blue-main">
          {data?.contactName}
        </div>
      </div>
      {data?.contactPhones?.map((item, index) => {
        return <Phone phone={item} index={index} key={index} />;
      })}
      {data?.contactEmail && (
        <div className="flex w-full mt-4 py-2 border-b border-gray-300 space-x-6">
          <div className="w-120px text-sm font-medium text-black-main">
            Email
          </div>
          <div className="flex-1 text-sm text-black-main">
            {data?.contactEmail}
          </div>
        </div>
      )}
    </Section>
  );
}

type PhoneProps = {
  index?: string,
  phone?: string,
};

function Phone({ index, phone }: PhoneProps) {
  const toast = useToast();
  return (
    <div className="flex w-full mt-4 py-2 items-center border-b border-gray-300 space-x-6">
      <div className="w-120px text-sm font-medium text-black-main">
        Số điện thoại {index + 1}
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex-1 text-sm">{phone}</div>
        <div
          className="cursor-pointer"
          onClick={async () => {
            await navigator.clipboard.writeText(phone);
            await toast({
              position: 'bottom-right',
              duration: 5000,
              render: ({ id, onClose }) => (
                <MyToast
                  id={id}
                  onClose={onClose}
                  wrapperStyle="w-394px shadow-md"
                  type="success"
                  message="Sao chép số điện thoại thành công'"
                  closeButtonPos="center"
                />
              ),
            });
          }}
        >
          <img alt="ico" src={'/svg/copy.svg'} className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
}
