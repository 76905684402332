export const reasonConfigs = {
  reject: [
    {
      key: 'wrongInfo',
      value: 'Hình ảnh không đúng quy định',
    },
    {
      key: 'duplicated',
      value: 'Thông tin đăng tải không phù hợp với pháp luật Việt Nam',
    },
    {
      key: 'other',
      value: 'Lý do khác',
    },
  ],
};
