import MyButton from '@components/button';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import MyDropdown from '@components/myDropdown';
import { numberWithDots } from '@utils/helper';
import DetailTotalFees from '@components/DetailTotalFees';
import { useGetRealEstateFees } from '@utils/hooks/common/useGetRealEstateFees';
import moment from 'moment';
import { usePublishB2BRealEstate } from '@utils/hooks/createB2BRealEstate/usePublishB2BRealEstate';
import { useNavigate } from 'react-router';
import { isEqual } from 'lodash';
import { useSetFormState } from '@utils/hooks/createB2BRealEstate/useSetFormState';
import { blankState } from '../configs';
import MyModalV2 from '@components/modalV2/myModalV2';
import ConfirmModal from '../../../../modals/confirmModal';
import { useGetPublishRealEstateType } from '@utils/hooks/createB2BRealEstate/useGetPublishRealEstateType';
import PublishRealEstateSuccessModal from '@components/publishRealEstateSuccessModal';
import { useSetPublishProductFormState } from '@utils/hooks/manageProduct/useSetPublishProductFormState';
import { useEditProductPosted } from '@utils/hooks/manageProduct/useEditProductPosted';
import qs from 'query-string';

type Props = {
  isEdit?: boolean,
  id?: string,
  setIsContinue?: Function,
  captchaCode?: string,
  captchaId?: string,
  setCaptchaError?: Function,
};

export default function RenderBottom({
  id,
  isEdit = false,
  setIsContinue,
  setCaptchaError,
  captchaCode,
  captchaId,
}: Props) {
  const { isPremium } = qs.parse(location.search);
  const navigate = useNavigate();
  const { setValue, handleSubmit, watch, reset } = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const initialValue = isEdit
    ? useSetPublishProductFormState(id, reset)
    : useSetFormState(id, reset, true);
  const { premium, authorized } = useGetPublishRealEstateType();
  const shouldShowModal = isEdit
    ? !isEqual(watch(), initialValue)
    : !isEqual(watch(), blankState);
  const { postType, numberOfPostingDay, type } = watch(),
    startDate = moment(new Date());
  const endDate = moment(startDate).add(numberOfPostingDay, 'days');
  const { data } = useGetRealEstateFees({
    startDay: startDate.unix(),
    endDay: endDate.unix(),
    postType,
    id: '',
    actionType: 'createPost',
    type,
  });
  const { onSubmit, loading } = usePublishB2BRealEstate(setValue, setShowModal);
  const { onSubmit: onEditSubmit, loading: editLoading } = useEditProductPosted(
    id,
    setShowModal,
    isPremium
  );
  const handleUpdatePosted = useCallback(
    value => {
      onEditSubmit(value, setCaptchaError, captchaCode, captchaId);
    },
    [setCaptchaError, captchaCode, captchaId]
  );
  const onCancelPressed = useCallback(() => {
    if (shouldShowModal) {
      setShowWarningModal(true);
    } else {
      navigate(-1);
    }
  }, [navigate, shouldShowModal, setShowWarningModal]);
  const onContinuePressed = useCallback(() => {
    reset(blankState);
    setIsContinue(true);
    navigate(
      premium
        ? '/publish/premium'
        : authorized
        ? '/publish/authorized'
        : '/publish/product'
    );
    document.getElementById('publish-intro').scrollIntoView();
  }, [reset]);
  return (
    <div className="w-full py-2.5 bg-white sticky bottom-0 border-t border-gray-300 flex z-10">
      <div className="flex-1">
        <div className="w-147px">
          <MyButton onPress={onCancelPressed} type="gray" label="Hủy" />
        </div>
      </div>
      <div className="flex flex-row items-center space-x-7">
        {!isEdit && (
          <div>
            <div
              className={
                premium ? 'hidden' : 'flex flex-row text-13 items-center'
              }
            >
              Tổng chi phí{' '}
              {(data?.getRealEstateFees?.totalDays ?? 0) + ' ngày:'}
              <div className="text-base text-red-500 font-bold ml-1">
                {data?.getRealEstateFees?.totalFees === 0 ||
                data?.getRealEstateFees?.noChanges
                  ? 'Miễn phí'
                  : `${numberWithDots(
                      data?.getRealEstateFees?.totalFees ?? 0
                    )} VNĐ`}
              </div>
            </div>
            <div className={premium ? 'hidden' : 'block'}>
              <MyDropdown
                tippyProps={{
                  trigger: 'mouseenter',
                  placement: 'top',
                }}
                show={showPrice}
                DropdownComponent={DetailTotalFees}
                dropdownContext={{
                  data,
                  startDay: startDate.unix(),
                  endDay: endDate.unix(),
                  postType,
                }}
              >
                <div
                  className="text-13 font-bold text-blue-main underline cursor-pointer"
                  onClick={() => setShowPrice(true)}
                >
                  Xem chi tiết
                </div>
              </MyDropdown>
            </div>
          </div>
        )}
        <div className="w-147px">
          <MyButton
            onPress={handleSubmit(isEdit ? handleUpdatePosted : onSubmit)}
            label={
              loading || editLoading
                ? 'Đang xử lý'
                : isEdit
                ? 'Chỉnh sửa'
                : 'Đăng tin'
            }
          />
        </div>
      </div>
      <PublishRealEstateSuccessModal
        onClose={() => setShowModal(false)}
        open={showModal}
        isEdit={isEdit}
        onContinue={onContinuePressed}
      />
      <MyModalV2
        hideClose
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
      >
        <ConfirmModal
          question="Bạn có chắc muốn thoát?"
          contentTop="Bạn đang soạn dang dở tin rao này, bạn có<br/>
chắc chắn muốn thoát?"
          onBack={() => navigate(-1)}
          onContinue={() => setShowWarningModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
