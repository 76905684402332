import React, { useState } from 'react';
import { ReactComponentElement } from 'react';
import { Popper, ClickAwayListener } from '@mui/material';

type Props = {
  buttonDropdownClassName?: string,
  disabled?: boolean,
  children?: string,
  DropdownComponent?: ReactComponentElement,
  dropdownContext?: Object,
  appendTo?: Object,
  wrapperClass?: string,
  container?: any,
  popperStyle?: Object,
  popperPlacement?: string,
  zIndex?: number,
};

export default function MyDropdown(props: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    buttonDropdownClassName,
    disabled,
    children,
    DropdownComponent = React.Fragment,
    dropdownContext = {},
    wrapperClass,
    popperStyle = {},
    popperPlacement = 'top',
    container,
    zIndex = 1,
  } = props;
  return (
    <div className={`space-y-1 ${wrapperClass}`}>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div className="w-full flex flex-col ">
          <Popper
            container={container || document.getElementById('modal-root')}
            placement={popperPlacement}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            disabled={disabled}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ]}
            style={{ zIndex, ...popperStyle }}
            open={!!anchorEl}
          >
            <DropdownComponent
              close={() => {
                setAnchorEl && setAnchorEl(null);
              }}
              context={{
                ...dropdownContext,
              }}
            />
          </Popper>
          <button
            // style={{
            //   boxShadow: anchorEl
            //     ? '0px 0px 3px 3px rgba(67,142,255,0.26)'
            //     : undefined,
            // }}
            disabled={disabled}
            className={`w-full ${
              disabled ? 'opacity-50' : undefined
            } ${buttonDropdownClassName}`}
            onClick={e => {
              setAnchorEl(anchorEl ? null : e.currentTarget);
            }}
          >
            {typeof children === 'function' ? children(!!anchorEl) : children}
          </button>
        </div>
      </ClickAwayListener>
    </div>
  );
}
