import React, { useMemo, useState, Suspense } from 'react';
import HeadLeftRealEstateDetail from './headLeftProductDetail';
import SwitchInner from '@components/switchInner';
import ContentProductDetail from './contentProductDetail';
import ProductEditHistory from './productEditHistory';
import ProductRenewHistory from './productRenewHistory';
import SameProduct from './sameProduct';
import { SameProductContext } from 'scenes/home/manageProduct/product/context';
import { useStateFilterProduct } from '@utils/hooks/manageProduct/useStateFilterProduct';
import ProductHistory from './productHistory';
import CommentProduct from './commentProduct';

type Props = {
  data?: Object,
  tab?: string,
  setSameButton?: Function,
  setIdSameProduct?: Function,
  id?: string,
  setTotal?: Function,
  currentTab?: string,
  setCurrentTab?: Function,
  tabConfigs?: Array,
};

export default function LeftProductDetail({
  data,
  tab,
  setSameButton,
  setIdSameProduct,
  setTotal,
  currentTab,
  setCurrentTab,
  tabConfigs,
}: Props) {
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterProduct();
  const [status, setStatus] = useState('approved');
  const renderContent = useMemo(() => {
    switch (currentTab) {
      default:
      case 'productDetail':
        return <ContentProductDetail data={data} />;
      case 'comment':
        return <CommentProduct />;
      case 'productEditHistory':
        return (
          <ProductEditHistory type={data?.type} demand={data?.typeOfDemand} />
        );
      case 'renewProductHistory':
        return <ProductRenewHistory demand={data?.typeOfDemand} />;
      case 'sameProduct':
        return (
          <SameProduct
            setIdSameProduct={setIdSameProduct}
            setSameButton={setSameButton}
            setTotal={setTotal}
            id={data?.id}
            demand={data?.typeOfDemand}
            type={data?.type}
          />
        );
      case 'productHistory':
        return (
          <ProductHistory
            key={JSON.stringify(data?.id)}
            setCurrentTab={setCurrentTab}
          />
        );
    }
  }, [data, currentTab]);

  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="flex-1 bg-white rounded-lg"
    >
      <HeadLeftRealEstateDetail
        tab={tab}
        postTitle={data?.realEstateId}
        fullAddress={data?.fullAddress}
        status={data?.approveStatus?.status}
        postStatus={data?.status || data?.postStatus}
        isDeleted={data?.deletedAt}
      />
      <SwitchInner
        setSameButton={setSameButton}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      {((data?.approveStatus?.reason && tab.includes('my-product')) ||
        (data?.approveStatus?.reason && tab.includes('rejected'))) && (
        <div className="flex-1 py-2 px-10 mx-4 rounded-lg border border-red-600 flex flex-row items-start space-x-2 mt-6">
          <img alt="ico" src="/svg/rejected-icon.svg" className="mt-1" />
          <div
            className="text-base text-justify"
            dangerouslySetInnerHTML={{
              __html: `<b className='text-base'>Lý do từ chối:</b> ${data?.approveStatus?.reason}`,
            }}
          />
        </div>
      )}
      <SameProductContext.Provider
        value={React.useMemo(
          () => ({
            stateFilter,
            setState,
            setStateFilterAt,
            status,
            setStatus,
          }),
          [stateFilter, setState, setStateFilterAt]
        )}
      >
        <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
          <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
        </div>
      </SameProductContext.Provider>
    </div>
  );
}
