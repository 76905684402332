import React, { useState } from 'react';
import { getNameUrlFromSever } from '@utils/helper';
import Viewer from 'react-viewer';

type Props = {
  attachments?: Array,
};

const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
function RenderHeader() {
  return (
    <div>
      <div className="text-14 font-bold font-text">Tài liệu đính kèm</div>
      <div className="w-full h-50px flex flex-row items-center overflow-x-auto mt-4">
        <div className="flex w-40px h-full items-center justify-center text-black-main text-14 font-medium">
          STT
        </div>
        <div className={`flex-1 ${headerClass}`}>Tên tài liệu</div>
      </div>
    </div>
  );
}
type ItemRowProps = {
  index?: number,
  item?: Object,
};

function ItemRow({ item, index }: ItemRowProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="w-full h-50px flex flex-row items-center">
      <div className="flex w-40px h-full items-center justify-center text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => setShowModal(true)}
        className={`flex-1 w-200px space-x-1 cursor-pointer ${headerClass}`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(item?.originalUrl)}
        </div>
      </div>
      <Viewer
        className="viewContainer"
        visible={showModal}
        onClose={() => setShowModal(false)}
        onMaskClick={() => setShowModal(false)}
        images={[{ src: item?.originalUrl }]}
        zoomSpeed={0.3}
        scalable={false}
        showTotal={false}
        noImgDetails={true}
        noNavbar={true}
      />
    </div>
  );
}

export default function AttachmentsTable({ attachments }: Props) {
  return (
    attachments?.length > 0 && (
      <div
        style={{ backgroundColor: '#EDF4FF' }}
        className="w-full rounded-xl p-2.5 divide-y divide-gray-300 overflow-x-auto"
      >
        <RenderHeader />
        {attachments?.map((item, index) => {
          return <ItemRow item={item} key={index} index={index} />;
        })}
      </div>
    )
  );
}
