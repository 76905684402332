import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@components/checkbox';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import Section from '@components/section';
import Label from '@components/label';

type Props = {
  items?: Array,
};
export default function Legal({ items = configs.legals }: Props) {
  const { watch, setValue } = useFormContext();
  const legalCount = watch('legal');
  const onCheck = useCallback(
    nextKey => {
      const isChecked = legalCount?.find(e => e === nextKey);
      if (isChecked) {
        setValue('legal', [...legalCount.filter(e => e !== nextKey)]);
      } else {
        setValue('legal', [...legalCount, nextKey]);
      }
    },
    [setValue, legalCount]
  );
  return (
    <Section>
      <div className="text-base text-blue-main font-medium">
        Pháp lý ({legalCount?.length || 0}/{items?.length})
      </div>
      <div className="w-full flex space-x-2.5 mt-7 items-start">
        <Label wrapperClass="w-152px" label="Pháp lý" />
        <div className="flex-1 grid-cols-4 grid gap-2">
          {items.map((item, index) => {
            const isChecked = legalCount?.find(e => e === item.key);
            return (
              <Checkbox
                key={index}
                title={item.value}
                isChecked={isChecked}
                onPress={() => onCheck(item.key)}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
}
