import React from 'react';
import InputSelector from '@components/inputSelector';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';

type Props = {
  priceKey?: string,
  priceFrom?: number,
  priceTo?: number,
  setPriceKey?: Function,
  setPriceFrom?: Function,
  setPriceTo?: Function,
  isForSell?: boolean,
  onResetSelected?: Function,
};
export default function DropdownPriceRange({
  priceKey,
  priceFrom,
  priceTo,
  setPriceKey,
  setPriceFrom,
  setPriceTo,
  isForSell = true,
  onResetSelected,
}: Props) {
  const { priceToBuyV2 } = useGetInternalRealEstateConfigurations();
  const convertPrice =
    priceToBuyV2?.map(e => {
      return {
        from: e?.from,
        to: !e?.to ? 101 : e?.to,
        value: e?.value,
        key: e?.key,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle="Giá (VNĐ)"
      progress={25}
      sliderTitle={'Giá bán (Tỷ VNĐ)'}
      key={Math.random() + priceFrom + priceTo}
      theValueIsShow={50}
      listSelections={convertPrice}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setPriceKey(key);
        setPriceFrom(from);
        setPriceTo(to);
      }}
      keyValue={priceKey}
      from={priceFrom}
      to={priceTo}
      unit={'tỷ'}
      min={0}
      max={50}
      additionalItems={[
        {
          key: '',
          from: 0,
          to: 101,
          value:
            isForSell === 'ForRent' || isForSell === 'ToRent'
              ? 'Tất cả giá thuê'
              : 'Tất cả giá bán',
        },
      ]}
    />
  );
}
