import MyInput from '@components/myInput';
import RadioButton from '@components/radio';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
type CountProps = {
  permissionName: string,
  inputLabel: string,
  permissionId: string,
  unlimitedText: string,
  limitText: string,
  isEdit?: boolean,
  countSet?: number,
  errors: Object,
  setErrors: Function,
};
export default function CountConfig({
  permissionName,
  permissionId,
  inputLabel,
  unlimitedText,
  limitText,
  isEdit = false,
  countSet,
  errors = {},
  setErrors,
}: CountProps) {
  const { setValue } = useFormContext();
  const [count, setCount] = useState(isEdit ? countSet : undefined);
  useEffect(() => {
    if (count !== Number.MAX_SAFE_INTEGER && !count) {
      setErrors({ ...errors, [permissionId]: 'Vui lòng nhập vào giá trị' });
    } else {
      const newError = { ...errors };
      delete newError[permissionId];
      setErrors(newError);
    }
    setValue(permissionId, { count });
  }, [count]);
  return (
    <div className="w-full flex flex-col space-y-4 rounded-lg shadow-card p-4">
      <span className="font-medium text-blue-main text-16">
        {permissionName}
      </span>
      <span className="font-medium text-blue-main text-14 italic">
        {inputLabel}
      </span>
      <div className="flex flex-col space-y-4">
        <RadioButton
          titleClass={
            count === Number.MAX_SAFE_INTEGER ? 'font-medium' : 'none'
          }
          isChecked={count === Number.MAX_SAFE_INTEGER}
          title={unlimitedText}
          wrapperClass="ml-4"
          onPress={() => {
            setCount(Number.MAX_SAFE_INTEGER);
          }}
        />
        <RadioButton
          titleClass={
            count !== Number.MAX_SAFE_INTEGER ? 'font-medium' : 'none'
          }
          isChecked={count !== Number.MAX_SAFE_INTEGER}
          title={limitText}
          wrapperClass="ml-4"
          onPress={() => {
            count !== Number.MAX_SAFE_INTEGER ? setCount(count) : setCount('');
          }}
        />
      </div>
      {count !== Number.MAX_SAFE_INTEGER && (
        <div className="flex flex-row">
          <div className="flex flex-row items-center space-x-2">
            <MyInput
              value={count}
              type="number"
              maxLength={20}
              hideMaxLength
              placeholder="Nhập số lần"
              inputClassName="w-150px"
              inputInnerClassName="w-full"
              onDelete={() => setCount('')}
              onChange={e => {
                setCount(parseInt(e.target.value));
              }}
              invalid={errors[permissionId]?.length > 0}
            />
            <span className="text-sm">lần/ ngày</span>
          </div>
        </div>
      )}
      {!!errors[permissionId]?.length && (
        <div className="text-sm text-red-400">{errors[permissionId]}</div>
      )}
    </div>
  );
}
