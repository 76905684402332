import React, { useCallback } from 'react';
import Table from '@components/table';
import RenderHeader, { checkVisibleCol } from './renderHeader';
import { View } from 'react-native-web';
import styles from './needApproval.module.scss';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useNeedApprovalContext } from '../context';
import realEstateCommonConfigs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import moment from 'moment/moment';
import { covertValueFromTo, usePrice } from '@utils/helper';
import { isEmpty } from 'lodash';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import RenderAvatarComponent from 'scenes/home/manageProduct/components/renderAvatarComponent';
type Props = {
  loading?: boolean,
  index?: number,
  onSelect?: Function,
  singleOnSelect?: Function,
  isChecked?: boolean,
  item?: Object,
  demand?: string,
  tab?: string,
};
function ItemTableNeedApproval({
  loading,
  index,
  onSelect,
  singleOnSelect,
  isChecked,
  item,
  demand = 'sell',
}: Props) {
  const {
    realEstateId,
    type,
    street,
    ward,
    district,
    province,
    width,
    length,
    rear,
    area,
    constructionArea,
    price,
    priceUnit,
    floorCount,
    isNewConstruction,
    isNewly,
    createdAt,
    updatedAt,
    isDeleted,
    approvedAt,
    approvedBy,
    postStatus,
    houseNumber,
  } = item || {};
  const isForSell = demand === 'sell' ? item.isForSell : item.isNeedToBuy;
  const { forRentRealEstateTypes } = realEstateCommonConfigs;
  const { price: resultPrice, priceUnit: resultPriceUnit } = usePrice(item);
  const srcIcon = isDeleted
    ? 'delete-icon.svg'
    : postStatus === 'active'
    ? 'active-icon.svg'
    : postStatus === 'expired'
    ? 'icon-expired.svg'
    : '';
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className={styles.row}
        >
          <div
            className={`${styles.itemTable} items-center justify-center text-sm w-60px`}
          >
            <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
          </div>
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div
              className={`${styles.itemTable} text-sm w-60px justify-between`}
            >
              {index + 1}
            </div>

            <div className={`${styles.itemTable} w-170px`}>
              <div className="flex-1 text-black-main">{realEstateId}</div>
              {srcIcon && <img alt="ico" src={`/svg/${srcIcon}`} />}
            </div>
            {checkVisibleCol('isForSell') && (
              <div className={`${styles.itemTable} w-140px`}>
                {isForSell && demand === 'sell'
                  ? 'Bán'
                  : !isForSell && demand === 'sell'
                  ? 'Cho thuê'
                  : isForSell
                  ? 'Cần mua'
                  : 'Cần thuê'}
              </div>
            )}
            {checkVisibleCol('type') && (
              <div className={`${styles.itemTable} w-145px`}>
                {forRentRealEstateTypes.find(e => e.key === type)?.value}
              </div>
            )}
            {checkVisibleCol('houseNumber') &&
              !item?.isNeedToBuy &&
              (houseNumber ? (
                <div className={`${styles.itemTable} w-170px`}>
                  {houseNumber}
                </div>
              ) : (
                <div className={`${styles.itemTable} w-170px`}>
                  <span className="italic text-sm opacity-50">Không có</span>
                </div>
              ))}
            {checkVisibleCol('streetName') && (
              <div className={`${styles.itemTable} w-170px`}>
                {street?.name}
              </div>
            )}
            {checkVisibleCol('wardName') && (
              <div className={`${styles.itemTable} w-140px`}>
                {ward?.name ? (
                  ward?.name
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('districtName') && (
              <div className={`${styles.itemTable} w-145px`}>
                {district?.name ? (
                  district?.name
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('provinceName') && (
              <div className={`${styles.itemTable} w-175px`}>
                {province?.name}
              </div>
            )}
            {checkVisibleCol('country') && (
              <div className={`${styles.itemTable} w-124px`}>Việt Nam</div>
            )}
            {checkVisibleCol('width') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.width?.to || width
                  ? `${covertValueFromTo(item, 'width')} m`
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('length') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.length?.to || length
                  ? `${covertValueFromTo(item, 'length')} m`
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('rear') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.rear?.to || rear ? (
                  `${covertValueFromTo(item, 'rear')} m`
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('area') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.area?.to || area
                  ? `${covertValueFromTo(item, 'area')} m²`
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('price') && (
              <div className={`${styles.itemTable} w-154px`}>
                {price
                  ? `${resultPrice} ${resultPriceUnit} ${
                      priceUnit ? priceUnit : ''
                    }`
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('floorCount') && (
              <div className={`${styles.itemTable} w-133px`}>
                {item?.floorCount?.to || floorCount ? (
                  `${covertValueFromTo(item, 'floorCount')}`
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('constructionArea') && (
              <div className={`${styles.itemTable} w-181px`}>
                {item?.constructionArea?.to || constructionArea ? (
                  `${covertValueFromTo(item, 'constructionArea')} (m²)`
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('isNewConstruction') && (
              <div className={`${styles.itemTable} w-166px`}>
                {isNewConstruction || isNewly ? `Xây mới` : 'Xây cũ'}
              </div>
            )}
            {checkVisibleCol('createdAt') && (
              <div className={`${styles.itemTable} w-185px`}>
                {moment(createdAt).format('hh:mmA,DD/MM/YYYY')}
              </div>
            )}
            {checkVisibleCol('updatedAt') && (
              <div className={`${styles.itemTable} w-218px`}>
                {updatedAt
                  ? moment(updatedAt).format('hh:mmA,DD/MM/YYYY')
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('approvedBy') && (
              <div className={`${styles.itemTable} w-218px`}>
                {!isEmpty(approvedBy) ? (
                  <MyDropdown
                    DropdownComponent={UserInfo}
                    dropdownContext={{
                      userId: item.approvedBy?.id,
                      isB2B: true,
                    }}
                  >
                    <div className="flex flex-row items-center space-x-2.5">
                      <RenderAvatarComponent
                        wrapperStyle="w-30px h-30px"
                        avatarId={item?.approvedBy?.avatar}
                      />
                      <div className="text-14 font-text text-blue-main underline truncate">
                        {approvedBy?.fullName}
                      </div>
                      <img src="/svg/eye.svg" alt="icon" />
                    </div>
                  </MyDropdown>
                ) : (
                  'Không có'
                )}
              </div>
            )}
            {checkVisibleCol('approvedAt') && (
              <div className={`${styles.itemTable} w-218px`}>
                {approvedAt
                  ? moment(approvedAt).format('hh:mmA,DD/MM/YYYY')
                  : 'Không có'}
              </div>
            )}
            {checkVisibleCol('department') && (
              <div className={`${styles.itemTable} w-218px`}>
                <span className="italic opacity-50">Không có</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
type RenderTableNeedApprovalProps = {
  data?: Array,
  loading?: boolean,
  canLoadMore?: boolean,
  onLoadMore?: Function,
  isLoadingMore?: boolean,
  neededIsLoadingMore?: boolean,
  neededOnLoadMore?: Function,
};
export default function RenderTableNeedApproval({
  data,
  loading,
  canLoadMore,
  onLoadMore,
  isLoadingMore,
  neededIsLoadingMore,
  neededOnLoadMore,
}: RenderTableNeedApprovalProps) {
  const { listSelected, setListSelected, demand, tab } =
    useNeedApprovalContext();
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleSingleOnSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([key]);
      }
    },
    [setListSelected, listSelected]
  );
  return (
    <View
      style={{
        width: '100%',
        maxHeight: 527,
        backgroundColor: 'white',
        marginTop: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#2459ad',
        overflow: 'hidden',
      }}
    >
      <Table>
        <View style={{ width: '100%' }}>
          <RenderHeader data={data} loading={loading} />
          {data?.map((item, index) => {
            const isChecked = listSelected.find(e => e === item.id);
            return (
              <ItemTableNeedApproval
                item={item}
                loading={loading}
                isChecked={isChecked}
                onSelect={() => handleCheck(item?.id, isChecked)}
                singleOnSelect={() => handleSingleOnSelect(item?.id, isChecked)}
                key={index}
                index={index}
                demand={demand}
                tab={tab}
              />
            );
          })}
          {!loading && !data?.length && (
            <p className="text-base m-3">Không có tin cần duyệt</p>
          )}
        </View>
      </Table>
      {canLoadMore && (
        <div className="w-full">
          <button
            onClick={demand === 'sell' ? onLoadMore : neededOnLoadMore}
            style={{ backgroundColor: '#FDF9E6' }}
            className={`flex items-center justify-center w-full h-35px cursor-pointer rounded`}
          >
            <div className="flex flex-row items-center space-x-1">
              <div className="text-sm text-blue-main font-semibold">
                {isLoadingMore || neededIsLoadingMore
                  ? 'Đang tải thêm'
                  : 'Xem thêm'}
              </div>
              {isLoadingMore || neededIsLoadingMore ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
              ) : (
                <img
                  alt="arrow"
                  src="/svg/arrowDownBlue.svg"
                  className="w-4 h-4"
                />
              )}
            </div>
          </button>
        </div>
      )}
    </View>
  );
}
