import styles from '@components/table/table.module.scss';
import React from 'react';

type Props = {
  type?: string,
  headerTableConfigs?: Array,
};

export default function HeaderTableRealEstateEditHistory(props: Props) {
  const { headerTableConfigs = [], type } = props;
  return (
    <div
      className="w-full h-40px flex border-b border-gray-300"
      style={{
        borderRadius: '9px 9px 0 0',
        backgroundColor: '#EDF4FF',
      }}
    >
      <div
        className={`${styles.headContainer} w-120px items-center justify-center`}
      >
        <div
          className={`text-13 font-medium text-blue-main ${styles.textHead}`}
        >
          Số lần chỉnh sửa
        </div>
      </div>
      {headerTableConfigs.map((item, index) => {
        if (item?.isShow.includes(type) || item?.isShow.includes(true)) {
          return (
            <div
              key={index}
              className={`${styles.headContainer} ${item?.className} items-center justify-center`}
            >
              <div
                className={`text-13 font-medium text-blue-main ${styles.textHead}`}
              >
                {item.title}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
