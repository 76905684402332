import UserInfo from './userInfo';
import React from 'react';

type Props = {
  data?: Array,
  loading?: boolean,
  show?: boolean,
  handleData?: Function,
  showDelBtn?: boolean,
};
export default function ListUser({
  data,
  loading,
  show,
  handleData,
  showDelBtn = false,
}: Props) {
  return (
    <div
      style={{
        height: show ? 200 : 0,
        transition: 'height 0.2s',
      }}
      className={`w-536px flex flex-col bg-white rounded shadow-lg overflow-auto space-y-1 ${
        data?.length > 0 ? 'py-2' : undefined
      }`}
    >
      {data?.map(item => (
        <UserInfo
          handleAddUser={handleData}
          showDeleteBtn={showDelBtn}
          loading={loading}
          data={item}
          key={Math.random()}
        />
      ))}
    </div>
  );
}
