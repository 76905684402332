import React from 'react';
import { useQuery } from '@apollo/client';
import { convertDataSelections } from '@utils/helper';
import { GET_LIST_WARD_OF_DISTRICT } from '@utils/graphql';
import pickBy from 'lodash/pickBy';
import DropdownSelection from '@components/dropdownSelection';

type Props = {
  value?: string,
  setValue?: Function,
  onDelete?: Function,
  invalid?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  districtId?: string,
  streetId?: string,
};
export default function WardDropdownSelection({
  value,
  setValue,
  onDelete,
  invalid,
  errorMessage,
  disabled,
  districtId,
  streetId,
}: Props) {
  const finalVariables = pickBy(
    {
      districtId,
      streetId,
      limit: 100,
    },
    e => e
  );
  const { data, loading } = useQuery(GET_LIST_WARD_OF_DISTRICT, {
    variables: {
      ...finalVariables,
    },
    skip: !districtId,
  });
  const rawData = [...(data?.getListWardsOfDistrict?.wards || [])];
  const dataSelections = convertDataSelections(rawData);
  return (
    <DropdownSelection
      selections={dataSelections}
      value={value}
      setValue={setValue}
      onDelete={onDelete}
      loading={loading}
      errorMessage={errorMessage}
      invalid={invalid}
      disabled={disabled}
      isShowSearchBox
      placeholder="Phường/ Xã"
    />
  );
}
