import styles from './changePasswordSuccessModal.module.scss';
import MyButton from '@components/button';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose?: Function,
};

export default function ChangePasswordSuccessModal({ onClose }: Props) {
  const navigate = useNavigate();
  return (
    <div className={styles.changePasswordSuccessModal}>
      <img className="w-80px h-80px" src="/svg/successful.svg" alt="icon" />
      <div
        style={{ color: '#40D124' }}
        className="text-24 font-medium text-blue-main mt-4"
      >
        Đổi mật khẩu thành công !
      </div>
      <div className="mt-3 text-13">
        Bạn có thể dùng mật khẩu mới để đăng nhập
      </div>
      <div className="flex w-full mt-6">
        <MyButton
          type="blue"
          label="Đăng nhập"
          wrapperClass="w-full"
          onPress={async () => {
            await localStorage.clear();
            await onClose();
            await navigate('/auth/login');
          }}
        />
      </div>
    </div>
  );
}
