import React from 'react';

import useGeolocation from 'react-use/lib/useGeolocation';

let L;
if (process.browser) {
  L = require('leaflet');
}

export const useSyncLocation = options => {
  return useGeolocation(options);
};
export const ActivePathContext = React.createContext({
  activePath: null,
  setActivePath: () => {},
});
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
export function angleFromCoordinate(lat1, lon1, lat2, lon2) {
  const p1 = {
    x: lat1,
    y: lon1,
  };

  const p2 = {
    x: lat2,
    y: lon2,
  };
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export function areCoordinatesDistantEnough(
  coord_one: L.LatLng,
  coord_two: L.LatLng
): boolean {
  return (
    JSON.stringify(coord_one[0].toString().substring(8, 3)) !==
    JSON.stringify(coord_two[0].toString().substring(8, 3))
  );
}

/**
 * Animate a leaflet element marker between two coordinates
 *
 * @param {L} leafletElement The leaflet element from marker
 * @param {L.LatLng} coordFrom The coordinate to go from
 * @param {L.LatLng} coordTo The coordinate to go to
 */
export function animateMarker(
  leafletElement: any,
  coordFrom: L.LatLng,
  coordTo: L.LatLng
): void {
  let position;
  let movingInterval;
  let curLat = coordFrom[0];
  let curLng = coordFrom[1];
  let newLat = coordTo[0];
  let newLng = coordTo[1];
  let threshold = 0.01;
  movingInterval = setInterval(() => {
    if (threshold >= 1) {
      clearInterval(movingInterval);
    }
    position = new L.LatLng(
      curLat + (newLat - curLat) * threshold,
      curLng + (newLng - curLng) * threshold
    );
    leafletElement.setLatLng(position);
    threshold += 0.1;
  }, 1000 / 60);
}
