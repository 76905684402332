import { RoutersConfigs } from '@components/horizontal-menu';
import MyInput from '@components/myInput';
import { debounce, filter, includes, isString, toLower, trim } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import UserProfileDropdown from './userProfileDropdown';
import MyModalV2 from '@components/modalV2/myModalV2';

Spotlight.propTypes = {
  onClickSpotlightIcon: PropTypes.func,
};
function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

export default function Spotlight({ onClickSpotlightIcon }) {
  const renderNavItem = router => {
    const isExternalLink = router?.path?.startsWith('https');

    const itemStyle = {
      height: '140px',
      width: '160px',
      borderRadius: 12,
    };

    const linkProps = isExternalLink
      ? {
          href: router.path || '',
          target: '_blank',
          rel: 'noreferrer',
        }
      : { to: router?.path || '' };

    const content = (
      <Fragment>
        {isString(router.icon) && (
          <img src={router.icon} alt="" className="h-60px" />
        )}
        <span
          className="text-semi text-center text-white"
          style={{ fontSize: '14px', lineHeight: '18px' }}
        >
          {router.title}
        </span>
      </Fragment>
    );

    return (
      <div
        key={router.id}
        className="flex justify-center mb-2"
        onClick={onClickSpotlightIcon}
      >
        <div
          className="flex justify-center bg-hover-primary hover:bg-blue-main"
          style={itemStyle}
        >
          {isExternalLink ? (
            <a
              {...linkProps}
              className="flex flex-col pt-6 items-center space-y-2"
            >
              {content}
            </a>
          ) : (
            <NavLink
              {...linkProps}
              className="flex flex-col pt-6 items-center space-y-2"
            >
              {content}
            </NavLink>
          )}
        </div>
      </div>
    );
  };
  const configRouters = RoutersConfigs();
  const routers = filter(configRouters, router => router.isShow);
  const [searchValue, setSearchValue] = useState('');
  const [navigationObjectsData, setNavigationObjectsData] = useState(routers);
  const handleChangeSearchValue = event => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    debouncedSearch(inputValue);
  };
  const debouncedSearch = debounce(searchTerm => {
    setNavigationObjectsData(
      filter(routers, item =>
        includes(trim(toLower(item.title)), trim(toLower(searchTerm)))
      )
    );
  }, 500);
  debounce(handleChangeSearchValue, 500);
  const handleClearSearchValue = () => {
    setSearchValue('');
    setNavigationObjectsData(routers);
  };
  const groupedNavigationItems = chunkArray(navigationObjectsData, 5);

  return (
    <MyModalV2
      hideClose
      open={true}
      modalClass="modal"
      style={{ width: '100%', height: '100%' }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          zIndex: 1100,
          backgroundImage: 'url(/images/backgroundImage.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="flex py-2 px-3 justify-between">
          <div className="flex">
            <div
              className="cursor-pointer"
              style={{ transform: 'rotate(90deg)' }}
              onClick={onClickSpotlightIcon}
            >
              <img
                className="w-38px h-38px "
                alt="image"
                src="/icons/arrowLeft.svg"
              />
            </div>
            <img
              alt="logo"
              src="/svg/logo_header_Biha.svg"
              className="bg-contain cursor-pointer "
            />
          </div>
          <div className="flex flex-row items-center space-x-5 sm:space-x-3">
            <img
              alt="chat-ico"
              src="/svg/chat-icon.svg"
              className="cursor-pointer"
            />
            <img
              alt="noti-ico"
              src="/svg/noti-icon.svg"
              className="cursor-pointer"
            />
            <UserProfileDropdown />
          </div>
        </div>
        <div
          className="flex justify-center items-center h-100"
          style={{ marginTop: '14vh' }}
        >
          <div
            className="flex flex-col justify-center"
            style={{ width: '65%' }}
          >
            <div
              className="mx-auto text-center w-380px bg-white "
              style={{ borderRadius: '12px' }}
            >
              <MyInput
                inputInnerClassName="w-full"
                value={searchValue}
                placeholder="Tìm kiếm..."
                onDelete={handleClearSearchValue}
                onChange={handleChangeSearchValue}
                maxLength={200}
                type="text"
                hideMaxLength={true}
                onKeyPress={e => {
                  const charCode = e.charCode;
                  if (
                    (charCode < 97 || charCode > 122) &&
                    (charCode < 65 || charCode > 90) &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 47 &&
                    charCode !== 45
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            {/* <div
              className={`grid grid-cols-${
                navigationObjectsData.slice(0, 5)?.length
              }`}
              style={{ marginTop: 60 }}
            >
              {navigationObjectsData.slice(0, 5).map(renderNavItem)}
              <div
                className={` col-span-5 grid grid-cols-${
                  navigationObjectsData.slice(5)?.length
                } w-${`${
                  navigationObjectsData.slice(5)?.length
                }/5`} justify-center`}
              >
                {navigationObjectsData.slice(5).map(renderNavItem)}
              </div>
            </div> */}
            <div>
              {groupedNavigationItems.map((group, index) => (
                <div
                  key={index}
                  className={`grid grid-cols-5`}
                  style={{ marginTop: index === 0 ? 60 : 20 }}
                >
                  {group.map(renderNavItem)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MyModalV2>
  );
}
