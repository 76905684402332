import React, { useCallback, useState } from 'react';
import Table from '@components/table';
import RenderHeader from './renderHeader';
import { View } from 'react-native-web';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useManageEmployeeContext } from '../context';
import moment from 'moment/moment';
import styles from '../../../manageRealEstate/needApproval/components/needApproval.module.scss';
import RenderIconDepartments from '../../componentRenderIconTableEmployee/renderIconDepartments';
import { isEmpty } from 'lodash';
type Props = {
  loading?: boolean,
  index?: number,
  onSelect?: Function,
  singleOnSelect?: Function,
  isChecked?: boolean,
  item?: Object,
};
function ItemUserB2B({
  loading,
  index,
  onSelect,
  singleOnSelect,
  isChecked,
  item,
}: Props) {
  const {
    employeeCode,
    fullName,
    username,
    phone,
    province,
    district,
    createdAt,
    email,
    lockByRtAt,
    isLocked,
    departments,
  } = item || {};
  const [showPhone, setShowPhone] = useState(false);
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className={styles.row}
        >
          <div
            className={`${styles.itemTable} items-center justify-center text-sm w-60px`}
          >
            <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
          </div>
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div
              className={`${styles.itemTable} flex text-sm w-60px items-center justify-center`}
            >
              {index + 1}
            </div>

            <div className={`${styles.itemTable} w-202px space-x-2`}>
              <div className="text-black-main">{employeeCode}</div>
              {lockByRtAt ||
                (isLocked && (
                  <img alt="locked-icon" src={'/svg/block-icon.svg'} />
                ))}
            </div>
            <div className={`${styles.itemTable} w-232px`}>
              <div className="flex-1 text-black-main flex flex-row">
                {fullName}
                <RenderIconDepartments
                  isLeader={departments
                    ?.filter(e => !isEmpty(e.department))
                    ?.find(e => e.positionType === 'leader')}
                />
              </div>
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              <div className="flex-1 text-black-main">{username}</div>
            </div>
            <div className={`${styles.itemTable} w-175px space-x-3`}>
              <div className="w-90px text-black-main">
                {showPhone ? phone : '**********'}
              </div>
              <img
                onClick={() => setShowPhone(!showPhone)}
                alt="ico"
                src={`/svg/${showPhone ? 'eye.svg' : 'eye-close-icon.svg'}`}
              />
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {departments[0]?.department?.name ?? 'Chưa có'}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {province?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {district?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {moment(createdAt).format('hh:mm A, DD/MM/YYYY')}
            </div>
            <div className={`${styles.itemTable} w-250px`}>{email}</div>
          </div>
        </div>
      )}
    </div>
  );
}
type UserB2BTableProps = {
  data?: Array,
  loading?: boolean,
  hasNextPage?: boolean,
  onLoadMore?: Function,
  isLoadingMore?: boolean,
};
export default function EmployeeTable({
  data,
  loading,
  hasNextPage,
  onLoadMore,
  isLoadingMore,
}: UserB2BTableProps) {
  const { listSelected, setListSelected } = useManageEmployeeContext();
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleSingleOnSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([key]);
      }
    },
    [setListSelected, listSelected]
  );
  return (
    <View
      style={{
        width: '100%',
        maxHeight: 527,
        backgroundColor: 'white',
        marginTop: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#2459ad',
        overflow: 'hidden',
      }}
    >
      <Table>
        <View style={{ width: '100%' }}>
          <RenderHeader data={data} loading={loading} />
          {data?.map((item, index) => {
            const isChecked = listSelected.find(e => e === item.id);
            return (
              <ItemUserB2B
                item={item}
                loading={loading}
                isChecked={isChecked}
                onSelect={() => handleCheck(item?.id, isChecked)}
                singleOnSelect={() => handleSingleOnSelect(item?.id, isChecked)}
                key={index}
                index={index}
              />
            );
          })}
          {!loading && !data?.length && (
            <p className="text-base m-3">Không có nhân viên</p>
          )}
        </View>
      </Table>
      {hasNextPage && (
        <div className="w-full">
          <button
            onClick={onLoadMore}
            style={{ backgroundColor: '#FDF9E6' }}
            className={`flex items-center justify-center w-full h-35px cursor-pointer rounded`}
          >
            <div className="flex flex-row items-center space-x-1">
              <div className="text-sm text-blue-main font-semibold">
                {isLoadingMore ? 'Đang tải thêm' : 'Xem thêm'}
              </div>
              {isLoadingMore ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
              ) : (
                <img
                  alt="arrow"
                  src="/svg/arrowDownBlue.svg"
                  className="w-4 h-4"
                />
              )}
            </div>
          </button>
        </div>
      )}
    </View>
  );
}
