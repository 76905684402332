import UploadMedia from '@components/uploadMedia';
import { Controller, useFormContext } from 'react-hook-form';

type MediaSectionProps = {
  isEdit?: boolean,
  isContinue?: boolean,
  setIsContinue?: Function,
};
export default function Media({
  isEdit,
  isContinue,
  setIsContinue,
}: MediaSectionProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="mediaUrls"
      render={({ field: { onChange, value } }) => {
        return (
          <UploadMedia
            isContinue={isContinue}
            setIsContinue={setIsContinue}
            isEdit={isEdit}
            onChange={onChange}
            mediaUrls={value}
          />
        );
      }}
    />
  );
}
