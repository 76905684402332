import React from 'react';
import moment from 'moment/moment';
import { useGetUserBasicInfo } from '@utils/hooks/common/useGetUserBasicInfo';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';

type Props = {
  data?: Object,
};

export default function ContractDetailOtherInfo({ data }: Props) {
  const creator = data?.creator;
  const { userInfo } = useGetUserBasicInfo(creator);

  return (
    <div className="flex flex-col space-y-4">
      {data?.note && (
        <div
          style={{ backgroundColor: '#FFF0B1' }}
          className="w-full p-4 rounded-md space-y-3"
        >
          <div className="text-base text-black-main font-medium">Ghi chú</div>
          <div className="w-full text-14 text-black-main leading-6">
            {data?.note}
          </div>
        </div>
      )}
      <div className="rounded-lg border p-4">
        <div className={`text-blue-main text-14 font-medium flex items-center`}>
          <div
            className="flex justify-center items-center h-30px w-30px"
            style={{ borderRadius: 8, backgroundColor: '#f4f4f4' }}
          >
            <img
              className="w-20px h-20px"
              src="/svg/card-bulleted-outline.svg"
              alt="card-bulleted-outline.svg"
            />
          </div>
          <span
            className="ml-2.5 font-medium"
            style={{
              fontSize: 16,
              lineHeight: '20px',
            }}
          >
            Thông tin người nhập liệu
          </span>
        </div>
        <div className="flex w-full py-4 justify-between border-b border-gray-300 space-x-6">
          <div className="w-120px text-sm font-medium text-black-main">
            Người tạo
          </div>
          <MyDropdown
            zIndex={1500}
            wrapperClass="w-full"
            DropdownComponent={UserInfo}
            dropdownContext={{
              userId: userInfo?.id,
              isB2B: false,
            }}
          >
            <div className="flex-1 flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-2.5 overflow-hidden">
                <div
                  className={`w-6 h-6 rounded-full overflow-hidden ${
                    !userInfo?.avatar?.originalUrl ? 'bg-gray-200' : undefined
                  }`}
                >
                  {userInfo?.avatar?.originalUrl ? (
                    <img
                      alt="avt"
                      src={userInfo?.avatar?.originalUrl}
                      className="flex-1"
                    />
                  ) : (
                    <img
                      alt="avt"
                      src="/svg/male.svg"
                      className="flex-1 bg-white"
                    />
                  )}
                </div>
                <div
                  className={`flex-1 text-14 font-normal truncate ${
                    userInfo?.fullName
                      ? 'underline cursor-pointer text-blue-main'
                      : 'italic opacity-50'
                  }`}
                >
                  {userInfo?.fullName ? userInfo?.fullName : 'Không có'}
                </div>
              </div>
              {userInfo?.fullName && (
                <img
                  alt="open icon"
                  src="/svg/eye.svg"
                  className="cursor-pointer"
                />
              )}
            </div>
          </MyDropdown>
        </div>
        <div className="flex w-full mt-4 justify-between">
          <div className="w-120px text-sm font-medium text-black-main">
            Ngày tạo
          </div>
          <div style={{ color: '#80888F' }} className="text-sm overflow-hidden">
            <span className="truncate">
              {moment(data?.createdAt).format('HH:mm:ss, DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
