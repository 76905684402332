export const headerTableRealEstateRenewHistoryConfigs = [
  {
    key: 'transactionId',
    title: 'Mã giao dịch',
    className: 'w-1/6',
    path: '/transactionId',
  },
  {
    key: 'renewDate',
    title: 'Thời gian đã gia hạn',
    className: 'w-2/6',
    path: '/renewDate',
  },
  {
    key: 'postType',
    title: 'Loại tin đã gia hạn',
    className: 'w-1/6',
    path: '/postType',
  },
  {
    key: 'basicAmount',
    title: 'Phí gia hạn',
    className: 'w-1/6',
    path: '/basicAmount',
  },
  {
    key: 'date',
    title: 'Thời điểm gia hạn',
    className: 'w-1/6',
    path: '/date',
  },
];
