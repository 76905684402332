import { useMutation } from '@apollo/client';
import { RESTORE_DELETED_PRODUCTS } from '@utils/graphql';
import React, { useCallback } from 'react';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';
import { useToast } from '@chakra-ui/react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';

export const useRestoreDeletedProducts = (
  realEstateIds,
  setShowModalRestore
) => {
  const { setListSelected } = useProductContext();
  const [restoreProduct, { loading, client }] = useMutation(
    RESTORE_DELETED_PRODUCTS
  );
  const toast = useToast();
  const onRestore = useCallback(async () => {
    try {
      await restoreProduct({
        variables: {
          realEstateIds: realEstateIds,
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Khôi phục sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
      setShowModalRestore(false);
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Khôi phục sản phẩm thành công'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [
    realEstateIds,
    setListSelected,
    client,
    setShowModalRestore,
    restoreProduct,
  ]);
  return { onRestore, loading };
};
