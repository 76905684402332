import React from 'react';
import Section from './section';
import { DocTienBangChuV2, numberWithDots } from '@utils/helper';
type Props = {
  data?: Object,
};

export default function QuickInfo({ data }: Props) {
  return (
    <Section
      title="Xem thông tin nhanh"
      source="/svg/detailRealEstate/thongtinnhanh.svg"
    >
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Quy mô dự án
        </div>
        <div className="flex-1 text-sm text-black-main">
          {numberWithDots(data?.projectScale)}
        </div>
      </div>
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Giá bán
        </div>
        <div className="flex-1 text-sm text-red-600">
          {`${DocTienBangChuV2(data?.priceRange?.from)} - ${DocTienBangChuV2(
            data?.priceRange?.to
          )}`}
        </div>
      </div>
      {data?.floorCountPerBlockRange?.from && (
        <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-5">
          <div className="w-132px text-sm font-medium text-black-main">
            Số lượng block
          </div>
          <div className="flex-1 text-sm text-black-main">{`${data?.floorCountPerBlockRange?.from} - ${data?.floorCountPerBlockRange?.to}`}</div>
        </div>
      )}
    </Section>
  );
}
