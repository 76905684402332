import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';
import PropTypes from 'prop-types';
import React from 'react';

RenderAvatarComponent.propTypes = {
  avatarId: PropTypes.string,
  wrapperStyle: PropTypes.string,
};
export default function RenderAvatarComponent({ avatarId, wrapperStyle }) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: avatarId,
    },
    skip: !avatarId,
  });

  const avatar = data?.readMediaUrls.originalUrl || avatarId?.originalUrl;

  return (
    <div>
      <div
        className={`${wrapperStyle} flex rounded-rd50 border border-gray-300 bg-no-repeat bg-cover bg-center`}
        style={{
          backgroundImage: `url('${avatar || '/svg/male.svg'}')`,
        }}
      />
    </div>
  );
}
