import { RenderTag } from '@components/tag';
import React from 'react';
import { useGetListBasicDepartments } from '@utils/hooks/b2bUser/useGetListBasicDepartments';

type DepartmentConfigsProps = {
  permissionName: string,
  departments: Array,
  allLabel: string,
  assignedDepartmentLabel: string,
  ownerDepartmentLabel?: string,
  permissionId: string,
};

export default function DepartmentAssignedInConfigs({
  permissionName,
  departments,
  allLabel,
  assignedDepartmentLabel,
  ownerDepartmentLabel,
}: DepartmentConfigsProps) {
  const { data } = useGetListBasicDepartments(departments ?? []);
  return (
    <div className="w-full flex flex-col space-y-2 rounded-lg shadow-normal p-4">
      <span className="font-medium text-blue-main text-16">
        {permissionName}
      </span>
      {departments?.includes('all') ? (
        <div className="text-sm font-medium">{allLabel}</div>
      ) : departments?.includes('owner') ? (
        <span className="text-sm font-medium">{ownerDepartmentLabel}</span>
      ) : (
        <div className="w-full flex flex-wrap space-x-2">
          <span className="text-sm font-medium">{assignedDepartmentLabel}</span>
          <div className="w-full flex flex-wrap space-x-2 mt-1">
            {data?.map(dep => (
              <RenderTag key={dep.id} value={dep?.name} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
