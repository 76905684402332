import { View } from 'react-native-web';
import RenderTopTable from '../components/renderTopTable';
import { realEstate } from '@components/leftMenu/configs';
import { useLocation } from 'react-router-dom';
import RenderTableProduct from '../components/renderTableProduct';
import { useGetInternalRealEstates } from '@utils/hooks/manageProduct/useGetInternalRealEstates';
import { useProductContext } from './context';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useDeletedRealEstateProduct } from '@utils/hooks/manageProduct/useDeletedRealEstateProduct';
import { useHiddenRealEstateProduct } from '@utils/hooks/manageProduct/useHiddenRealEstateProduct';
import ActionRealEstateProductModal from '../../../../modals/actionRealEstateProductModal';
import { useRestoreDeletedProducts } from '@utils/hooks/manageProduct/useRestoreDeletedProducts';
import MyModal from '@components/modal';
import RejectedRealEstateProductModal from '../../../../modals/rejectedRealEstateProductModal';
import { useApprovalRealEstateProduct } from '@utils/hooks/manageProduct/useApprovalRealEstateProduct';

export default function Product() {
  const { pathname, search } = useLocation();
  const title = realEstate.items[0].items?.find(
    e => e.pathname === pathname
  )?.title;
  const { stateFilter, setStateFilterAt, listSelected, status, setStatus } =
    useProductContext();
  const filter = JSON.parse(sessionStorage.getItem('filter'));
  let {
    realEstates,
    onLoadByPagination,
    loading,
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  } = useGetInternalRealEstates({ state: filter ? filter : stateFilter });

  const pageParam = parseInt(queryString.parse(search).page) || 1;
  const pagination = {
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
  const { province, district, ward, street, houseNumber, isDuplicate } =
    queryString.parse(search) || {};
  useEffect(() => {
    setStateFilterAt('province')(province);
    setStateFilterAt('district')(district);
    setStateFilterAt('ward')(ward);
    setStateFilterAt('street')(street);
    setStateFilterAt('houseNumber')(houseNumber);
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [showModalHiddenProduct, setShowModalHiddenProduct] = useState(false);
  const [showModalAppear, setShowModalAppear] = useState(false);
  const [showModalRestore, setShowModalRestore] = useState(false);
  const [showModalApproval, setShowModalApproval] = useState(false);
  const [showModalRejected, setShowModalRejected] = useState(false);
  const { onSubmit, loading: loadingDeleted } = useDeletedRealEstateProduct(
    listSelected,
    setShowModal
  );
  const {
    onHidden,
    onAppear,
    loading: loadingHidden,
  } = useHiddenRealEstateProduct(
    listSelected,
    setShowModalHiddenProduct,
    setShowModalAppear
  );
  const { onRestore, loading: loadingRestoreProduct } =
    useRestoreDeletedProducts(listSelected, setShowModalRestore);
  const { onApproval, loading: loadingApprovalProduct } =
    useApprovalRealEstateProduct(listSelected, setShowModalApproval);

  useEffect(() => {
    onLoadByPagination(pageParam);
    return () => {
      onLoadByPagination = null;
    };
  }, [pageParam]);

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          width: '100%',
          backgroundColor: 'white',
          paddingBottom: 12,
        }}
      >
        <div className="pl-8 pr-4">
          <RenderTopTable
            title={isDuplicate ? 'Sản phẩm bị trùng' : title}
            total={totalItem}
            isVisibleActionAllButton={
              !isEmpty(listSelected) && listSelected?.length > 1
            }
            onShowModalDeleted={() => setShowModal(true)}
            onShowModalHidden={() => setShowModalHiddenProduct(true)}
            onShowModalAppear={() => setShowModalAppear(true)}
            onShowModalRestore={() => setShowModalRestore(true)}
            onShowModalApproval={() => {
              setStatus('approved');
              setShowModalApproval(true);
            }}
            onShowModalReject={() => {
              setStatus('rejected');
              setShowModalRejected(true);
            }}
          />
        </div>
        <div className="w-full pr-4 pl-8">
          <RenderTableProduct
            loading={loading}
            data={realEstates}
            pagination={pagination}
          />
        </div>
        <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
          <ActionRealEstateProductModal
            loading={loadingDeleted}
            onConfirm={onSubmit}
            header={'Xóa sản phẩm'}
            question={`Bạn có chắc muốn xóa sản phẩm`}
            onClose={() => setShowModal(false)}
          />
        </MyModalV2>
        <MyModalV2
          open={showModalHiddenProduct}
          onClose={() => setShowModalHiddenProduct(false)}
        >
          <ActionRealEstateProductModal
            loading={loadingHidden}
            onConfirm={onHidden}
            header={'Ẩn sản phẩm'}
            question={`Bạn có chắc muốn ẩn sản phẩm`}
            onClose={() => setShowModalHiddenProduct(false)}
          />
        </MyModalV2>
        <MyModalV2
          open={showModalAppear}
          onClose={() => setShowModalAppear(false)}
        >
          <ActionRealEstateProductModal
            loading={loadingHidden}
            onConfirm={onAppear}
            header={'Bỏ ẩn sản phẩm'}
            question={`Bạn có chắc muốn bỏ ẩn sản phẩm`}
            onClose={() => setShowModalAppear(false)}
          />
        </MyModalV2>
        <MyModalV2
          open={showModalRestore}
          onClose={() => setShowModalRestore(false)}
        >
          <ActionRealEstateProductModal
            loading={loadingRestoreProduct}
            onConfirm={onRestore}
            header={'Khôi phục sản phẩm'}
            question={`Bạn có chắc muốn khôi phục sản phẩm`}
            onClose={() => setShowModalRestore(false)}
          />
        </MyModalV2>
        <MyModalV2
          open={showModalApproval}
          onClose={() => setShowModalApproval(false)}
        >
          <ActionRealEstateProductModal
            loading={loadingApprovalProduct}
            onConfirm={onApproval}
            header={'Duyệt sản phẩm'}
            question={`Bạn có chắc muốn duyệt sản phẩm`}
            onClose={() => setShowModalApproval(false)}
          />
        </MyModalV2>
        <MyModal
          maxWidth={515}
          isOpen={showModalRejected}
          onClose={() => setShowModalRejected(false)}
        >
          <RejectedRealEstateProductModal
            realEstateIds={listSelected}
            status={status}
            onClose={() => setShowModalRejected(false)}
          />
        </MyModal>
      </View>
    </View>
  );
}
