import { useQuery } from '@apollo/client';
import { GET_PERMISSION_GROUPS } from '@utils/graphql';
import { cleanObj } from '@utils/helper';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { pickBy } from 'lodash';
import moment from 'moment/moment';
import { useState } from 'react';
import { useGlobalContext } from '../../../context';
import { PAGINATION_LIMIT } from 'constant';

export const getVariable = state => {
  const t = pickBy(
    {
      searchString: state?.searchString,
      code: state?.code,
      name: state?.name ? state?.name : null,
      creator: state?.creator ? state?.creator : null,
      createdDate:
        !state.createdAtFrom || !state.createdAtTo
          ? null
          : {
              from: moment.unix(state.createdAtFrom).format('YYYY-MM-DD'),
              to: moment
                .unix(state.createdAtTo)
                .endOf('day')
                .format('YYYY-MM-DD'),
            },
      limit: PAGINATION_LIMIT,
    },
    predicate => predicate
  );
  return { ...cleanObj(t) };
};

export const useGetPermissionGroups = ({ state }) => {
  const [loading, setLoading] = useState(false);

  const { isLogin } = useGlobalContext();

  const variables = getVariable(state);
  const { data, fetchMore } = useQuery(GET_PERMISSION_GROUPS, {
    variables,
    skip: !isLogin,
  });
  const permissionGroups = data?.listPermissionGroups?.items || [];
  const currentPage = data?.listPermissionGroups?.page || 1;
  const totalItem = data?.listPermissionGroups?.total;
  const totalPage = data?.listPermissionGroups?.totalPages;
  const rowsPerPage = data?.listPermissionGroups?.limit;
  const currentCountFrom = data?.listPermissionGroups?.pagingCounter;
  const currentCountTo = currentCountFrom + permissionGroups.length - 1;
  const hasPrevPage = data?.listPermissionGroups?.hasPrevPage;
  const hasNextPage = data?.listPermissionGroups?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  return {
    permissionGroups,
    loading,
    onLoadByPagination,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
