import styles from './realEstate.module.scss';
import TopRealEstateForMap from '@components/itemRealEstate/components/topRealEstateForMap';
import BottomRealEstateForMap from '@components/itemRealEstate/components/bottomRealEstateForMap';

type Props = {
  item?: Object,
};

export default function RealEstateForMap({ item }: Props) {
  return (
    <div className={styles.realEstateForMap}>
      <TopRealEstateForMap item={item} />
      <a
        className="flex-1"
        target="_blank"
        href={`/manageProduct/details/${item.id}`}
        rel="noreferrer"
      >
        <BottomRealEstateForMap item={item} />
      </a>
    </div>
  );
}
