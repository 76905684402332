import ImageAndVideoList from './imageAndVideoList';

type Props = {
  mediaUrlIds?: Array,
};
export default function RenderMedia({ mediaUrlIds }: Props) {
  return (
    <div
      style={{ maxHeight: 363 }}
      className="w-full h-auto rounded-lg p-4 border border-gray-300"
    >
      <ImageAndVideoList imgUrls={mediaUrlIds} />
    </div>
  );
}
