import React from 'react';

type Props = {
  loading?: boolean,
  onLoadMore?: Function,
  hasNextPage?: boolean,
  wrapperClass?: string,
  wrapperStyle?: Object,
};

export default function SeeMore(props: Props) {
  const {
    onLoadMore = () => {},
    loading,
    hasNextPage,
    wrapperStyle,
    wrapperClass,
  } = props;
  if (!hasNextPage) {
    return null;
  }
  return (
    <div className={`w-full ${wrapperClass}`} style={wrapperStyle}>
      <button
        onClick={onLoadMore}
        style={{ backgroundColor: '#FDF9E6' }}
        className={`flex items-center justify-center w-full h-35px cursor-pointer rounded`}
      >
        <div className="flex flex-row items-center space-x-1">
          <div className="text-sm text-blue-main font-semibold">
            {loading ? 'Đang tải thêm' : 'Xem thêm'}
          </div>
          {loading ? (
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
          ) : (
            <img alt="arrow" src="/svg/arrowDownBlue.svg" className="w-4 h-4" />
          )}
        </div>
      </button>
    </div>
  );
}
