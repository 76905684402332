import { View } from 'react-native-web';
import { Outlet } from 'react-router-dom';

export default function ManageProject() {
  return (
    <View style={{ flex: 1 }}>
      <Outlet />
    </View>
  );
}
