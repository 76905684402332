import RenderMedia from './renderMedia';
import RenderUserInfo from './renderUserInfo';

type Props = {
  data?: Object,
  demand?: string,
};
export default function RenderLeft({ data, demand }: Props) {
  return (
    <div className="w-323px h-full space-y-5">
      {demand === 'sell' && <RenderMedia mediaUrlIds={data?.mediaUrlsIds} />}
      <RenderUserInfo data={data} />
    </div>
  );
}
