import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import MyInput from '@components/myInput';

type Props = {
  inputClassName?: string,
  searchValue?: string,
  setSearchValue?: Function,
  placeholder?: string,
  onDeleteValue?: Function,
};
export default function SearchBox({
  inputClassName,
  searchValue,
  setSearchValue,
  placeholder = 'Tìm kiếm người dùng',
  onDeleteValue,
}: Props) {
  const [tempValue, setTempValue] = useState(searchValue);
  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  useDebounce(
    () => {
      setDebounceSearchValue(tempValue);
    },
    300,
    [tempValue]
  );
  useEffect(() => {
    setSearchValue(debounceSearchValue);
  }, [debounceSearchValue]);
  return (
    <MyInput
      onDelete={() => {
        onDeleteValue && onDeleteValue();
        setTempValue('');
      }}
      value={tempValue}
      inputClassName={inputClassName}
      onChange={e => setTempValue(e.target.value)}
      placeholder={placeholder}
      renderRightComponent={() => {
        return (
          <div className="w-6 h-6 flex items-center justify-center border-l border-gray-300">
            <img
              alt="search-icon"
              src="/svg/search-icon.svg"
              className="ml-2"
            />
          </div>
        );
      }}
    />
  );
}
