import React from 'react';
import MyButton from '@components/button';
import { useNeedApprovalContext } from '../../../needApproval/context';

type Props = {
  setStatus?: Function,
  setShowModal?: Function,
  isTabApproved?: boolean,
  demand?: string,
  isDeleted?: boolean,
  realEstateType?: string,
};

export default function RightRealEstateDetail(props: Props) {
  const { setStatus, setShowModal, isTabApproved, demand, isDeleted } = props;
  const { listSelected } = useNeedApprovalContext();
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      {isTabApproved ? (
        <MyButton
          disabled={isDeleted}
          onPress={() =>
            window.open(
              `${process.env.REACT_APP_API_ENDPOINT_B2C}/${demand === 'sell'
                ? 'chi-tiet-tin-rao'
                : 'chi-tiet-can-mua-can-thue'
              }/${listSelected?.[0]}`,
              '_blank'
            )
          }
          type="gray"
          label="Xem tin rao này trên sàn"
        />
      ) : (
        <div className="space-y-4">
          <MyButton
            onPress={() => {
              setStatus('approved');
              setShowModal(true);
            }}
            type="gray"
            label="Duyệt"
          />
          <MyButton
            onPress={() => {
              setStatus('rejected');
              setShowModal(true);
            }}
            type="gray"
            label="Từ chối"
          />
        </div>
      )}
    </div>
  );
}
