import { useLocation } from 'react-router-dom';

type Props = {
  stepConfigs?: Array,
  isCompleted?: boolean,
};

type StepItemProps = {
  lastItem?: boolean,
  isPending?: boolean,
  isCompleted?: boolean,
  label?: string,
  index?: string,
  wrapperStyle?: Object,
};
function StepItem({
  lastItem,
  isPending,
  isCompleted,
  label,
  index,
  wrapperStyle,
}: StepItemProps) {
  return (
    <div style={wrapperStyle} className="space-y-4 flex flex-col items-center">
      <div className="flex items-center relative">
        <div
          style={{
            borderWidth: 2,
            borderColor: isPending
              ? '#007BFF'
              : isCompleted
              ? '#40D124'
              : '#DCDCDC',
          }}
          className="w-44px h-44px rounded-full flex items-center justify-center"
        >
          <div
            style={{
              backgroundColor: isPending
                ? '#007BFF'
                : isCompleted
                ? '#40D124'
                : '#DCDCDC',
            }}
            className={`w-32px h-32px rounded-full flex items-center justify-center ${
              isPending ? 'text-white' : 'text-black-main'
            }`}
          >
            {isCompleted ? <img alt="ico" src="/svg/check.svg" /> : index}
          </div>
        </div>
        <div
          style={{
            left: lastItem ? -109 : 44,
            backgroundColor: isPending
              ? '#007BFF'
              : isCompleted
              ? '#40D124'
              : '#DCDCDC',
          }}
          className="w-109px h-2px absolute"
        />
      </div>
      <div
        className={`text-base text-black-main ${
          isPending ? 'font-medium' : 'font-normal'
        }`}
      >
        {label}
      </div>
    </div>
  );
}
export default function StepComponent({ stepConfigs, isCompleted }: Props) {
  const location = useLocation();
  return (
    <div className="flex items-center">
      {stepConfigs.map((item, index) => {
        const lastItem = index === stepConfigs.length - 1;
        return (
          <StepItem
            key={index}
            index={index + 1}
            label={item.label}
            lastItem={lastItem}
            isPending={location.pathname === item.path}
            isCompleted={isCompleted && location.pathname !== item.path}
            wrapperStyle={{ marginLeft: index !== 0 && 176 }}
          />
        );
      })}
    </div>
  );
}
