import ContactInfo from './contactInfo';
import Utilities from './utilities';
import Legal from './legal';
import LocationRealEstate from './locationRealEstate';
import DetailProduct from './detailProduct';
import QuickInfo from './quickInfo';
import { useGetProductAttachments } from '@utils/hooks/manageProduct/useGetProductAttachments';
import { useEffect, useState } from 'react';
import { getMedias } from '@utils/helper';
import AttachmentsTable from './attachments';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import PostInfo from './postInfo';
import OwnerInfo from './ownerInfo';
import DeadlineAndPostType from './deadlineAndPostType';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type Props = {
  data?: Object,
};
export default function RenderRight({ data }: Props) {
  const { permissions } = useGetMe();
  const [listAttachment, setListAttachment] = useState([]);
  const { attachments, getProductAttachments, error } =
    useGetProductAttachments(data?.originalProductId || data?.id);
  const { productPosted } = useGetTab();
  useEffect(() => {
    (async function () {
      try {
        const data2 = await getMedias(attachments || []);
        setListAttachment(data2);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [attachments]);
  const getAttachments = () => {
    getProductAttachments();
  };

  return (
    <div className="w-full">
      <div className="flex-1 p-4 rounded-lg border border-blue-main">
        <QuickInfo data={data} demand={data?.typeOfDemand || data?.isForSell} />
      </div>
      <div className="flex-1 p-4 rounded-lg border border-blue-main mt-4">
        <div className="flex flex-col flex-1 space-y-8">
          {productPosted && <PostInfo data={data} />}
          {!productPosted ? (
            <ContactInfo data={data} key={JSON.stringify(data)} />
          ) : (
            <OwnerInfo data={data} />
          )}
          <LocationRealEstate data={data} />
          <DetailProduct
            data={data}
            demand={data?.typeOfDemand || data?.isForSell}
          />
          {productPosted && <DeadlineAndPostType data={data} />}
          {[
            ...(data?.fixedUtilities || []),
            ...(data?.utilities || []),
            ...(data?.localUtilities || []),
            ...(data?.equipmentsAndServices || []),
          ]?.length > 0 && <Utilities data={data} />}
          <Legal data={data} />
          {permissions?.some(
            p => p.id === PermissionIds.ViewProductAttachments
          ) &&
            data?.attachments?.length > 0 && (
              <div className="mt-7 space-y-4 flex-1">
                <div className="flex-row flex justify-between">
                  <div className="text-sm font-medium text-black-main flex">
                    Tài liệu đính kèm
                  </div>
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={getAttachments}
                  >
                    <img src="/svg/eye.svg" alt="icon" />
                    <span className="text-sm text-blue-main">
                      Xem tài liệu đính kèm
                    </span>
                  </div>
                </div>
                {listAttachment.length > 0 ? (
                  <AttachmentsTable attachments={listAttachment || []} />
                ) : error ? (
                  <div className="text-sm text-red-300">
                    Hết lượt xem trong ngày
                  </div>
                ) : undefined}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
