import React from 'react';

type Props = {
  key?: string,
  data?: Object,
  showDeleteBtn?: boolean,
  loading?: boolean,
  handleAddUser?: Function,
};
export default function UserInfo({
  key,
  data,
  showDeleteBtn = false,
  loading = false,
  handleAddUser,
}: Props) {
  if (loading) {
    return (
      <div
        style={{ height: 50 }}
        className="flex-1 animate-pulse bg-gray-200 rounded mx-2 mt-1"
      />
    );
  }
  return (
    <div
      style={{ height: 50 }}
      key={key}
      className={`w-full ${
        showDeleteBtn ? 'cursor-default' : 'hover:bg-blue-200 cursor-pointer'
      } flex flex-row space-x-4 items-center p-2`}
      onClick={showDeleteBtn ? () => {} : () => handleAddUser(data)}
    >
      <div
        className="bg-no-repeat bg-contain bg-center rounded-full"
        style={{
          width: 32,
          height: 32,
          backgroundImage: `url("${
            data?.avatar?.originalUrl || '/svg/male.svg'
          }")`,
        }}
      />
      <div className="flex-1 flex flex-col">
        <span className="text-sm ">{`${data?.fullName} (${data?.employeeCode})`}</span>
        <span className="text-xs text-gray-400">
          {`${data?.phone} - ${data?.email}`}
        </span>
      </div>
      {showDeleteBtn && (
        <div className="justify-end">
          <img
            onClick={showDeleteBtn ? () => handleAddUser(data) : () => {}}
            className="w-20px h-20px cursor-pointer"
            src="/svg/recycle-bin-icon.svg"
            alt="Delete"
          />
        </div>
      )}
    </div>
  );
}
