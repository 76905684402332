import MyTreeView from '@components/treeView/treeView1';
import Permission from '@gugotech/tncc-users-permissions/lib';
import { Box } from '@mui/material';
import { filterAndMapChildren } from '@utils/helper';
import PropTypes from 'prop-types';

PermissionTree.propTypes = {
  permissionGroup: PropTypes.object,
};
export default function PermissionTree({ permissionGroup }) {
  const filterPermissions = filterAndMapChildren(
    Permission.getPermissionTree('product'),
    permissionGroup?.permissions.map(permission => permission.id)
  );

  return (
    <Box className="flex-1 bg-white p-4 rounded-rd8px shadow-md">
      <MyTreeView data={filterPermissions} title="Phân quyền" />
    </Box>
  );
}
