import React, { useCallback, useState } from 'react';
import MyButton from '@components/button';
import Radio from '@components/radio';
import MyDropdown from '@components/myDropdown';
import styles from '@components/table/table.module.scss';
import FromToInput from '@components/fromToInput';

type SelectionProps = {
  close?: Function,
  show?: boolean,
  context?: Object,
};
type Props = {
  listSelections?: Array,
  to?: any,
  from?: any,
  unit?: string,
  min?: number,
  max?: number,
  minDistance?: number,
  onSelectItem?: Function,
  additionalItems?: Array,
  placeholder?: string,
  keyValue?: string,
  step?: number,
  theValueIsShow?: string,
  stepRenderLine?: number,
  sliderTitle?: string,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
  renderToValue?: number,
  dropdownButtonTitle?: string,
};
export default function InputSelector(props: Props) {
  const {
    listSelections = [],
    onSelectItem,
    keyValue,
    to,
    from,
    unit,
    min,
    max,
    step,
    additionalItems = [],
    theValueIsShow,
    stepRenderLine,
    sliderTitle,
    progress,
    isLong,
    showPlus = true,
    dropdownButtonTitle,
  } = props;
  const selections = [...(additionalItems || []), ...(listSelections || [])],
    selectedValue = selections.find(e => e.from === from && e.to === to);
  return (
    <MyDropdown
      DropdownComponent={InputComponent}
      dropdownContext={{
        from,
        to,
        key: keyValue,
        unit,
        min,
        max,
        step,
        listSelections: selections,
        onSelectItem: item => onSelectItem(item),
        selectedValue,
        theValueIsShow,
        stepRenderLine,
        title: sliderTitle,
        progress,
        isLong,
        showPlus,
      }}
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              {dropdownButtonTitle}
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
export function InputComponent(props: SelectionProps) {
  const { close } = props,
    {
      listSelections,
      unit,
      onSelectItem,
      title,
      from: initialFrom,
      to: initialTo,
      disableInfinity = true,
    } = props.context,
    [borderError, setBorderError] = useState(false);

  const [from, setFrom] = useState(initialFrom),
    [to, setTo] = useState(initialTo);

  const toDefaultValue = disableInfinity ? 0 : Infinity;
  const fromToInputProps = {},
    parsedInitialFrom = parseFloat(initialFrom || 0),
    parsedInitialTo = parseFloat(initialTo || toDefaultValue),
    selected = listSelections?.find(
      e => e.from === parsedInitialFrom && e.to === parsedInitialTo
    );

  if (!selected) {
    fromToInputProps.from = from;
    fromToInputProps.to = to;
  }
  const onEnterFromToValue = useCallback(() => {
    if (parseFloat(to) < 0 || parseFloat(from) > parseFloat(to) || to === '') {
      setBorderError(true);
    } else {
      setBorderError(false);
      onSelectItem({
        from: parseFloat(from || 0),
        to: parseFloat(to || toDefaultValue),
        unit,
      });
      close();
    }
  }, [close, from, to, unit, onSelectItem, toDefaultValue]);

  const handleKeyPress = async e => {
    if (e.key === 'Enter') {
      onEnterFromToValue();
    }
  };

  return (
    <div
      className="rounded-lg bg-white overflow-hidden border border-blue-main table table-fixed"
      style={{
        minWidth: 300,
        maxHeight: 300,
      }}
    >
      <div
        style={{ fontSize: 11, marginLeft: 16, marginTop: 12 }}
        className="font-medium"
      >
        {title}
      </div>
      <div className="p-4">
        <FromToInput
          from={from === 0 ? '' : from}
          to={to === toDefaultValue ? '' : to}
          unit={unit}
          setFrom={setFrom}
          setTo={setTo}
          handleKeyPress={handleKeyPress}
          borderError={borderError}
        />
      </div>
      <div className="border-b border-gray-200" />
      <div
        style={{ maxHeight: 200 }}
        className="flex-1 px-4 space-y-4 overflow-y-auto pb-4 pt-4"
      >
        {listSelections?.map((item, index) => {
          const isActiveFromTo =
            from !== null
              ? parseFloat(from) === item.from && parseFloat(to) === item.to
              : from === null && parseFloat(to) === item.to;
          return (
            <Radio
              isChecked={isActiveFromTo}
              title={item.value}
              key={index}
              onPress={() => {
                onSelectItem({ from: item.from, to: item.to, key: item.key });
                close();
              }}
            />
          );
        })}
      </div>
      <div
        style={{
          bottom: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        className=" w-full px-3 py-2 flex flex-row border-t border-gray-300 sticky space-x-2 "
      >
        <MyButton
          wrapperClass="flex-1"
          onPress={close}
          label="Trở về"
          type="gray"
        />
        <MyButton
          wrapperClass="flex-1"
          onPress={() => onEnterFromToValue()}
          label="Xác nhận"
          type="blue"
        />
      </div>
    </div>
  );
}
