import MyButton from '@components/button';
import React from 'react';
import { useRenewRealEstate } from '../../utils/hooks/manageProduct/useRenewRealEstate';

type Props = {
  errorMessage?: string,
  date?: string,
  onClose?: Function,
  realEstateId?: string,
  captchaCode?: string,
  captchaId?: string,
  source?: string,
  postType?: number,
  endShowTime?: string,
  startShowTime?: string,
  isActive?: boolean,
  setCaptchaError?: Function,
};
export default function Submit({
  errorMessage,
  date,
  onClose,
  realEstateId,
  captchaCode,
  captchaId,
  source,
  postType,
  endShowTime,
  startShowTime,
  isActive,
  setCaptchaError,
}: Props) {
  const { onSubmit, loading } = useRenewRealEstate(
    realEstateId,
    onClose,
    setCaptchaError
  );
  return (
    <div className="flex w-full justify-end space-x-2 mb-4 mt-4 border-t border-gray-300">
      <div className="w-140px mt-3">
        <MyButton
          onPress={onClose}
          type="white"
          label="Hủy"
          wrapperClass="border border-gray-500 text-blue-main font-bold"
        />
      </div>
      <div className="w-160px mt-3 pr-4">
        <MyButton
          type="blue"
          label={loading ? 'Đang xử lý' : 'Gia hạn'}
          disabled={errorMessage || !date || loading}
          onPress={() => {
            onSubmit(
              captchaCode,
              captchaId,
              source,
              postType,
              endShowTime,
              startShowTime,
              isActive,
              setCaptchaError
            );
          }}
        />
      </div>
    </div>
  );
}
