import MyButton from '@components/button';
import React from 'react';

type Props = {
  onClose?: Function,
};
export default function UploadErrorModal({ onClose }: Props) {
  return (
    <div className="bg-white w-600px h-350px rounded-full p-6">
      <div className="flex flex-col w-full h-full">
        <div
          style={{ color: '#EC6E00' }}
          className="w-full text-center text-xl sm:text-2xl font-medium"
        >
          KHÔNG THỂ ĐĂNG TẢI TỆP!
        </div>
        <div className="w-full flex justify-center">
          <img
            className="w-249px h-155px"
            alt="image"
            src="/images/uploadError.webp"
          />
        </div>
        <div className="w-full flex flex-col space-y-1.5 px-0 sm:px-16">
          <div className="w-full flex flex-row items-center space-x-2">
            <div
              style={{ backgroundColor: '#000000' }}
              className="w-5px h-5px rounded-full"
            />
            <div
              className="text-sm sm:text-18 text-black"
              dangerouslySetInnerHTML={{
                __html: `Tổng số lượng tệp không vượt quá <b>6 tệp</b>`,
              }}
            />
          </div>
          <div className="w-full flex flex-row items-center space-x-2">
            <div
              style={{ backgroundColor: '#000000' }}
              className="w-5px h-5px rounded-full bg-black"
            />
            <div
              className="text-sm sm:text-18 text-black"
              dangerouslySetInnerHTML={{
                __html: `Kích thước tệp không vượt quá <b>1 MB</b>`,
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-1 items-end justify-center">
          <div className="w-148px" onClick={onClose}>
            <MyButton type="gray" label="Trở về" />
          </div>
        </div>
      </div>
    </div>
  );
}
