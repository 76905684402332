export const defaultVariableFilterOnMap = {
  typeOfDemand: 'sale',
  type: ['townhouse'],
};

export const demandConfig = [
  {
    key: 'ForSale',
    value: 'Bán',
  },
  {
    key: 'ForRent',
    value: 'Cho thuê',
  },
];

export const typeOfDemand = [
  {
    key: 'sale',
    value: 'Bán',
  },
  {
    key: 'rent',
    value: 'Cho thuê',
  },
];
