import { View } from 'react-native-web';
import styles from '@components/table/table.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import SearchTextDropdown from '../../manageRealEstate/components/filterButtons/searchTextDropdown';
import DemandDropdown from '../../manageRealEstate/components/filterButtons/demandDropdown';
import RealEstateTypeDropdown from '../../manageRealEstate/components/filterButtons/realEstateTypeDropdown';
import StreetDropdown from '../../manageRealEstate/components/filterButtons/streetDropdown';
import ProvinceDropdown from '../../manageRealEstate/components/filterButtons/provinceDropdown';
import DistrictDropdown from '../../manageRealEstate/components/filterButtons/districtDropdown';
import WardDropdown from '../../manageRealEstate/components/filterButtons/wardDropdown';
import CalendarDropdown from '../../manageRealEstate/components/filterButtons/calendarDropdown';
import { useProductContext } from '../product/context';
import RenderResultFilterHeader from './renderResultFilterHeader';
import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import ProductCodeDropdown from '../../manageRealEstate/components/filterButtons/productCodeDropdown';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import {
  getListPostStatus,
  getProductStatusV2,
  getUnique,
  listPostStatus,
} from '@utils/helper';
import { useGetLocation } from '../configs';
import InputWidthDropdown from '../inputDropdownGroup/inputWidthDropdown';
import InputLengthDropdown from '../inputDropdownGroup/inputLengthDropdown';
import InputRearDropdown from '../inputDropdownGroup/inputRearDropdown';
import InputAreaDropdown from '../inputDropdownGroup/inputAreaDropdown';
import InputFloorCountDropdown from '../inputDropdownGroup/inputFloorCountDropdown';
import InputPriceDropdown from '../inputDropdownGroup/inputPriceDropdown';
import { HoChiMinhId } from 'constant';
import { directionConfigs, hasAlleyConfigs } from '@utils/configs/configsLocal';
import CountryDropdown from 'scenes/home/manageRealEstate/components/filterButtons/countryDropdown';
// import InputPricePerSquareDropdown from '../inputDropdownGroup/inputPricePerSquareDropdown';
import InputAlleyWidthDropdown from '../inputDropdownGroup/inputAlleyWidthDropdown';

export const checkVisibleCol = id => {
  const { listField } = useProductContext();
  return listField.includes(id);
};

type Props = {
  data?: Array,
  loading?: boolean,
};

export default function RenderHeader({ data, loading }: Props) {
  let { listSelected, setListSelected, stateFilter, setStateFilterAt } =
    useProductContext();
  const filter = JSON.parse(sessionStorage.getItem('filter'));
  filter ? Object.assign(stateFilter, filter) : stateFilter;
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { realEstateForRentStatus, realEstateForSaleStatus } =
      realEstateConfigurations;
  const {
    isTabProduct,
    isTabMyProduct,
    isTabWaitingForApproval,
    isTabProductPosted,
    isTabDeleteProduct,
    isTabProductPremium,
    isTabProductAuthorize,
    isTabHiddenProduct,
    isTabRejectProduct,
  } = useGetLocation();
  const isCheckAll = listSelected?.length === data?.length;
  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data?.map(e => e?.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);
  const { forRentRealEstateTypes, forSellRealEstateTypes } = configsRemote;
  const tempStatus = [
      ...(realEstateForRentStatus || []),
      ...(realEstateForSaleStatus || []),
    ],
    statusConfigs = getUnique(tempStatus, 'key');
  const realEstateStatus =
    stateFilter?.isForSell === 'ForSale'
      ? statusConfigs?.filter(
          e =>
            ![
              'rented',
              'renting',
              'waitingForContract',
              'signedContract',
            ].includes(e.key)
        )
      : statusConfigs?.filter(
          e =>
            ![
              'sold',
              'onSale',
              'waitingForContract',
              'signedContract',
            ].includes(e.key)
        );

  const finalRealEstateForSaleAndRentStatus = useMemo(() => {
      return (
        realEstateStatus?.map(item => ({
          ...item,
          icon: '/svg/ic_triangle.svg',
          nextColor: getProductStatusV2(item.key),
          currentColor: '#48FF3E',
        })) || []
      );
    }, [statusConfigs]),
    selectionProductCode = useMemo(() => {
      return [
        ...finalRealEstateForSaleAndRentStatus,
        {
          key: 'isAbandoned',
          value: 'Sản phẩm bị cảnh báo',
          nextColor: getProductStatusV2('rejected'),
          icon: '/svg/icon_warning.svg',
        },
      ];
    }, [finalRealEstateForSaleAndRentStatus]),
    selectionProductCodeMyProduct = useMemo(() => {
      return [
        ...finalRealEstateForSaleAndRentStatus,
        {
          key: 'rejected',
          value: 'Sản phẩm bị từ chối',
          nextColor: getProductStatusV2('rejected'),
          icon: '/svg/icon_warning.svg',
        },
        {
          key: 'pending',
          value: 'Sản phẩm đang chờ duyệt',
          nextColor: getProductStatusV2('pending'),
          icon: '/svg/icon_pending.svg',
        },
      ];
    }, [finalRealEstateForSaleAndRentStatus]),
    selectionProductApproval = useMemo(() => {
      return [
        {
          key: 'isExpired12hours',
          value: 'Sản phẩm quá 12h chưa được duyệt',
          icon: '/svg/icon_warning.svg',
        },
      ];
    }, []);
  const listPostStatusFinal = listPostStatus?.map(item => ({
    ...item,
    icon: '/svg/ic_triangle.svg',
    nextColor: getListPostStatus(item.key),
    currentColor: '#48FF3E',
  }));

  let productSelections;
  if (isTabMyProduct) {
    productSelections = selectionProductCodeMyProduct;
  } else if (isTabProduct) {
    productSelections = selectionProductCode;
  } else if (isTabWaitingForApproval) {
    productSelections = selectionProductApproval;
  } else if (
    (isTabProductPosted || isTabProductPremium || isTabProductAuthorize) &&
    !(isTabHiddenProduct || isTabDeleteProduct || isTabRejectProduct)
  ) {
    productSelections = listPostStatusFinal;
  } else {
    productSelections = finalRealEstateForSaleAndRentStatus;
  }
  const [sort, setSort] = useState({});

  const handleOnSort = field => {
    if (!sort[field] || sort[field] === '') {
      setStateFilterAt('sort')(`-${field}`);
      setSort({ [field]: 'decrease' });
    } else if (sort[field] === 'decrease') {
      setStateFilterAt('sort')(field);
      setSort({ [field]: 'increase' });
    } else {
      setStateFilterAt('sort')('');
      setSort({ [field]: '' });
    }
  };
  return (
    <View
      style={{ borderTopLeftRadius: 9, borderTopRightRadius: 9, width: '100%' }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              isPartialCheck={listSelected?.length > 0}
              onPress={handleCheckAll}
              isChecked={isCheckAll}
              disabled={loading}
            />
          </div>
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </div>
          </div>
          {checkVisibleCol('hasAlley') && (
            <div
              className={`${styles.headContainer} w-85px items-center justify-center`}
            >
              <RealEstateTypeDropdown
                title="Mặt tiền & hẻm"
                selections={hasAlleyConfigs}
                setValue={value => {
                  setListSelected?.clear();
                  setStateFilterAt('hasAlley')(value);
                }}
                value={stateFilter.hasAlley}
              />
            </div>
          )}
          {checkVisibleCol('houseNumber') && (
            <div
              className={`${styles.headContainer} w-100px items-center justify-center`}
            >
              <SearchTextDropdown
                title="Số nhà"
                search={stateFilter.houseNumber}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('houseNumber')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('streetName') && (
            <div
              className={`${styles.headContainer} w-170px items-center justify-center`}
            >
              <StreetDropdown
                value={stateFilter.street}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('street')(item.key);
                  setStateFilterAt('streetName')(item.value);
                }}
                wardId={stateFilter.ward}
                districtId={stateFilter.district}
              />
            </div>
          )}
          {checkVisibleCol('wardName') && (
            <div
              className={`${styles.headContainer} w-140px items-center justify-center`}
            >
              <WardDropdown
                value={stateFilter.ward}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('ward')(item.key);
                  setStateFilterAt('wardName')(item.value);
                  setStateFilterAt('street')('');
                  setStateFilterAt('streetName')('');
                }}
                districtId={stateFilter.district}
                streetId={
                  stateFilter.province === HoChiMinhId
                    ? stateFilter.street
                    : null
                }
              />
            </div>
          )}
          {checkVisibleCol('districtName') && (
            <div
              className={`${styles.headContainer} w-145px items-center justify-center`}
            >
              <DistrictDropdown
                value={stateFilter.district}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('district')(item.key);
                  setStateFilterAt('districtName')(item.value);
                  setStateFilterAt('ward')('');
                  setStateFilterAt('wardName')('');
                  setStateFilterAt('street')('');
                  setStateFilterAt('streetName')('');
                }}
                provinceId={stateFilter.province}
              />
            </div>
          )}
          {checkVisibleCol('provinceName') && (
            <div
              className={`${styles.headContainer} w-140px items-center justify-center`}
            >
              <ProvinceDropdown
                value={stateFilter.province}
                setValue={item => {
                  setListSelected.clear();
                  setStateFilterAt('province')(item.key);
                  setStateFilterAt('provinceName')(item.value);
                  setStateFilterAt('district')('');
                  setStateFilterAt('districtName')('');
                  setStateFilterAt('ward')('');
                  setStateFilterAt('wardName')('');
                  setStateFilterAt('street')('');
                  setStateFilterAt('streetName')('');
                }}
              />
            </div>
          )}
          {checkVisibleCol('width') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center space-x-1`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('width');
                }}
              >
                <img
                  src={
                    !sort['width'] || sort['width'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['width'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputWidthDropdown
                widthFrom={stateFilter.widthFrom}
                widthTo={stateFilter.widthTo}
                widthKey={stateFilter.widthKey}
                setWidthKey={value => setStateFilterAt('widthKey')(value)}
                setWidthFrom={value => setStateFilterAt('widthFrom')(value)}
                setWidthTo={value => setStateFilterAt('widthTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('width')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('length') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center space-x-1`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('length');
                }}
              >
                <img
                  src={
                    !sort['length'] || sort['length'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['length'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputLengthDropdown
                lengthFrom={stateFilter.lengthFrom}
                lengthTo={stateFilter.lengthTo}
                lengthKey={stateFilter.lengthKey}
                setLengthKey={value => setStateFilterAt('lengthKey')(value)}
                setLengthFrom={value => setStateFilterAt('lengthFrom')(value)}
                setLengthTo={value => setStateFilterAt('lengthTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('length')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('price') && (
            <div
              className={`${styles.headContainer} w-154px items-center justify-center`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('price');
                }}
              >
                <img
                  src={
                    !sort['price'] || sort['price'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['price'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputPriceDropdown
                isForSell={stateFilter?.isForSell}
                priceKey={stateFilter.priceKey}
                priceFrom={stateFilter.priceFrom}
                priceTo={stateFilter.priceTo}
                setPriceKey={value => setStateFilterAt('priceKey')(value)}
                setPriceFrom={value => setStateFilterAt('priceFrom')(value)}
                setPriceTo={value => setStateFilterAt('priceTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('pricePerSquare') && (
            <div
              className={`${styles.headContainer} w-154px items-center justify-start`}
            >
              <span className="text-13 font-medium text-blue-main">
                Giá VNĐ/m2
              </span>
              {/* <InputPricePerSquareDropdown
                isForSell={stateFilter?.isForSell}
                priceKey={stateFilter.priceKey}
                priceFrom={stateFilter.priceFrom}
                priceTo={stateFilter.priceTo}
                setPriceKey={value => setStateFilterAt('priceKey')(value)}
                setPriceFrom={value => setStateFilterAt('priceFrom')(value)}
                setPriceTo={value => setStateFilterAt('priceTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              /> */}
            </div>
          )}
          {checkVisibleCol('contactPhones') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <SearchTextDropdown
                title="Số điện thoại chủ nhà"
                search={
                  isTabProductPosted
                    ? stateFilter.ownerPhone
                    : stateFilter.contactPhone
                }
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt(
                    isTabProductPosted ? 'ownerPhone' : 'contactPhone'
                  )(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('contactName') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <SearchTextDropdown
                title="Tên chủ nhà"
                search={
                  isTabProductPosted ||
                  isTabProductPremium ||
                  isTabProductAuthorize
                    ? stateFilter.ownerName
                    : stateFilter.contactName
                }
                setSearch={value => {
                  setListSelected.clear();
                  isTabProductPosted ||
                  isTabProductPremium ||
                  isTabProductAuthorize
                    ? setStateFilterAt('ownerName')(value)
                    : setStateFilterAt('contactName')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('rear') && (
            <div
              className={`${styles.headContainer} w-124px items-center justify-center`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('rear');
                }}
              >
                <img
                  src={
                    !sort['rear'] || sort['rear'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['rear'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputRearDropdown
                rearFrom={stateFilter.rearFrom}
                rearTo={stateFilter.rearTo}
                rearKey={stateFilter.rearKey}
                setRearKey={value => setStateFilterAt('rearKey')(value)}
                setRearFrom={value => setStateFilterAt('rearFrom')(value)}
                setRearTo={value => setStateFilterAt('rearTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('rear')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('area') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('area');
                }}
              >
                <img
                  src={
                    !sort['area'] || sort['area'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['area'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputAreaDropdown
                areaFrom={stateFilter.areaFrom}
                areaTo={stateFilter.areaTo}
                title="Tổng diện tích (m²)"
                setAreaKey={value => setStateFilterAt('areaKey')(value)}
                setAreaFrom={value => setStateFilterAt('areaFrom')(value)}
                setAreaTo={value => setStateFilterAt('areaTo')(value)}
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('area')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('floorCount') && (
            <div
              className={`${styles.headContainer} w-133px items-center justify-center`}
            >
              <InputFloorCountDropdown
                floorCountFrom={stateFilter.floorCountFrom}
                floorCountTo={stateFilter.floorCountTo}
                floorCountKey={stateFilter.floorCountKey}
                setFloorCountFrom={value =>
                  setStateFilterAt('floorCountFrom')(value)
                }
                setFloorCountTo={value =>
                  setStateFilterAt('floorCountTo')(value)
                }
                setFloorCountKey={value =>
                  setStateFilterAt('floorCountKey')(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('constructionArea') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOnSort('constructionArea');
                }}
              >
                <img
                  src={
                    !sort['constructionArea'] || sort['constructionArea'] === ''
                      ? '/svg/nonSort.svg'
                      : sort['constructionArea'] === 'increase'
                      ? '/svg/sortIncrease.svg'
                      : '/svg/sortDecrease.svg'
                  }
                />
              </div>
              <InputAreaDropdown
                areaFrom={stateFilter.constructionAreaFrom}
                areaTo={stateFilter.constructionAreaTo}
                title="Diện tích sàn (m²)"
                setAreaKey={value =>
                  setStateFilterAt('constructionAreaKey')(value)
                }
                setAreaFrom={value =>
                  setStateFilterAt('constructionAreaFrom')(value)
                }
                setAreaTo={value =>
                  setStateFilterAt('constructionAreaTo')(value)
                }
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt('constructionArea')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('alleyWidth') && (
            <div
              className={`${styles.headContainer} w-130px items-center justify-center`}
            >
              <InputAlleyWidthDropdown
                alleyWidthFrom={
                  isTabProductPosted
                    ? stateFilter.frontHomeAlleyWidthFrom
                    : stateFilter.alleyWidthFrom
                }
                alleyWidthTo={
                  isTabProductPosted
                    ? stateFilter.frontHomeAlleyWidthTo
                    : stateFilter.alleyWidthTo
                }
                title="Độ rộng hẻm trước nhà (m)"
                setalleyWidthKey={value =>
                  setStateFilterAt(
                    isTabProductPosted
                      ? 'frontHomeAlleyWidthKey'
                      : 'alleyWidthKey'
                  )(value)
                }
                setalleyWidthFrom={value =>
                  setStateFilterAt(
                    isTabProductPosted
                      ? 'frontHomeAlleyWidthFrom'
                      : 'alleyWidthFrom'
                  )(value)
                }
                setalleyWidthTo={value =>
                  setStateFilterAt(
                    isTabProductPosted
                      ? 'frontHomeAlleyWidthTo'
                      : 'alleyWidthTo'
                  )(value)
                }
                onResetSelected={value => {
                  setListSelected.clear();
                  setStateFilterAt(
                    isTabProductPosted ? 'frontHomeAlleyWidth' : 'alleyWidth'
                  )(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('realEstateId') && (
            <div className={`${styles.headContainer} w-210px items-center`}>
              <SearchTextDropdown
                title="Mã sản phẩm"
                search={stateFilter.realEstateId}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('realEstateId')(value);
                }}
              />
            </div>
          )}
          {!isTabProductPremium && (
            <>
              {checkVisibleCol('status') && (
                <div
                  className={`${styles.headContainer} w-180px items-center justify-center`}
                >
                  <ProductCodeDropdown
                    selections={productSelections}
                    value={stateFilter.postStatus}
                    setValue={value => {
                      setListSelected.clear();
                      setStateFilterAt('postStatus')(value);
                    }}
                  />
                </div>
              )}
            </>
          )}
          {checkVisibleCol('isForSell') && (
            <div
              className={`${styles.headContainer} w-140px items-center justify-center`}
            >
              <DemandDropdown
                demand={'sell'}
                value={stateFilter.isForSell}
                setValue={value => {
                  setListSelected?.clear();
                  setStateFilterAt('isForSell')(value);
                  setStateFilterAt('postStatus')([]);
                  setStateFilterAt('realEstateType')(['townhouse']);
                }}
              />
            </div>
          )}
          {checkVisibleCol('type') && (
            <div
              className={`${styles.headContainer} w-145px items-center justify-center`}
            >
              <RealEstateTypeDropdown
                title="Loại BĐS"
                selections={
                  stateFilter.isForSell === 'ForSale'
                    ? forSellRealEstateTypes
                    : forRentRealEstateTypes
                }
                setValue={value => {
                  setListSelected?.clear();
                  setStateFilterAt('realEstateType')(value);
                }}
                value={stateFilter.realEstateType}
              />
            </div>
          )}
          {checkVisibleCol('country') && (
            <div
              className={`${styles.headContainer} w-100px items-center justify-center`}
            >
              <CountryDropdown value={stateFilter.province} />
            </div>
          )}
          {checkVisibleCol('bedroomCount') && (
            <div
              className={`${styles.headContainer} w-133px items-center justify-center`}
            >
              <InputFloorCountDropdown
                title="Số phòng ngủ"
                unit="phòng"
                floorCountFrom={stateFilter.bedroomCountFrom}
                floorCountTo={stateFilter.bedroomCountTo}
                floorCountKey={stateFilter.bedroomCountKey}
                setFloorCountFrom={value =>
                  setStateFilterAt('bedroomCountFrom')(value)
                }
                setFloorCountTo={value =>
                  setStateFilterAt('bedroomCountTo')(value)
                }
                setFloorCountKey={value =>
                  setStateFilterAt('bedroomCountKey')(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('toiletCount') && (
            <div
              className={`${styles.headContainer} w-133px items-center justify-center`}
            >
              <InputFloorCountDropdown
                title="Số nhà vệ sinh"
                unit="phòng"
                floorCountFrom={stateFilter.toiletCountFrom}
                floorCountTo={stateFilter.toiletCountTo}
                floorCountKey={stateFilter.toiletCountKey}
                setFloorCountFrom={value =>
                  setStateFilterAt('toiletCountFrom')(value)
                }
                setFloorCountTo={value =>
                  setStateFilterAt('toiletCountTo')(value)
                }
                setFloorCountKey={value =>
                  setStateFilterAt('toiletCountKey')(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
          {checkVisibleCol('direction') && (
            <div
              className={`${styles.headContainer} w-133px items-center justify-center`}
            >
              <RealEstateTypeDropdown
                title="Hướng"
                selections={directionConfigs}
                setValue={value => {
                  setListSelected?.clear();
                  setStateFilterAt('direction')(value);
                }}
                value={stateFilter.direction}
              />
            </div>
          )}
          {checkVisibleCol('commission') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <SearchTextDropdown
                title="Phí hoa hồng"
                search={stateFilter.commission}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('commission')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('map') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-start`}
            >
              <span className="text-13 font-medium text-blue-main">Tọa độ</span>
              {/* <SearchTextDropdown
                title="Tọa độ"
                search={stateFilter.commission}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('commission')(value);
                }}
              /> */}
            </div>
          )}
          {checkVisibleCol('approvedBy') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center`}
            >
              <SearchTextDropdown
                search={
                  isTabRejectProduct
                    ? stateFilter.rejectedBy
                    : stateFilter.approvedBy
                }
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt(
                    isTabRejectProduct ? 'rejectedBy' : 'approvedBy'
                  )(value);
                }}
                title={isTabRejectProduct ? 'Người từ chối' : 'Người duyệt'}
              />
            </div>
          )}
          {checkVisibleCol('approvedAt') && (
            <div
              className={`${styles.headContainer} w-175px items-center justify-center`}
            >
              <CalendarDropdown
                start={
                  isTabRejectProduct
                    ? stateFilter.rejectedAtFrom
                    : stateFilter.approvedAtFrom
                }
                end={
                  isTabRejectProduct
                    ? stateFilter.rejectedAtTo
                    : stateFilter.approvedAtTo
                }
                setStart={value =>
                  setStateFilterAt(
                    isTabRejectProduct ? 'rejectedAtFrom' : 'approvedAtFrom'
                  )(value)
                }
                setEnd={value =>
                  setStateFilterAt(
                    isTabRejectProduct ? 'rejectedAtTo' : 'approvedAtTo'
                  )(value)
                }
                onResetSelected={() => {
                  setListSelected.clear();
                }}
                title={isTabRejectProduct ? 'Ngày từ chối' : 'Ngày duyệt'}
              />
            </div>
          )}
          {checkVisibleCol('creator') && (
            <div
              className={`${styles.headContainer} w-218px items-center justify-center`}
            >
              <SearchTextDropdown
                search={stateFilter.creator}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('creator')(value);
                }}
                title="Người tạo"
              />
            </div>
          )}
          {checkVisibleCol('createdAt') && (
            <div
              className={`${styles.headContainer} w-185px items-center justify-center`}
            >
              <CalendarDropdown
                start={stateFilter.createdAtFrom}
                end={stateFilter.createdAtTo}
                setStart={value => setStateFilterAt('createdAtFrom')(value)}
                setEnd={value => setStateFilterAt('createdAtTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
                title="Ngày tạo"
              />
            </div>
          )}
          {checkVisibleCol('updatedAt') && (
            <div
              className={`${styles.headContainer} w-185px items-center justify-center`}
            >
              <CalendarDropdown
                title="Ngày chỉnh sửa"
                start={stateFilter.updatedAtFrom}
                end={stateFilter.updatedAtTo}
                setStart={value => setStateFilterAt('updatedAtFrom')(value)}
                setEnd={value => setStateFilterAt('updatedAtTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
              />
            </div>
          )}
        </View>
      </View>
      <RenderResultFilterHeader productSelections={productSelections} />
    </View>
  );
}
