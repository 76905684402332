import * as React from 'react';
import { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { allConfigs } from './../configs';
import RadioConfig from './RadioConfig';
import { useList } from 'react-use';
import RadioSelectDepartmentConfig from './RadioSelectDepartmentConfig';
import AddProduct from './AddProduct';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import ViewProduct from './ViewProduct';
import CountConfig from './CountConfig';
import { styled } from '@mui/material';

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 40,
  },
  '& > .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
}));
const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  '&.MuiAccordionDetails-root': {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 16,
  },
}));

type ConfigsAccordionProps = {
  selectedPermissions: Array,
  assignedPermissions?: Array,
  isEdit?: boolean,
  errors: Object,
  setErrors: Function,
  modalError?: string,
  maxHeight?: string,
  emptyText?: string,
};
export default function PermissionConfigs({
  selectedPermissions,
  assignedPermissions,
  isEdit = false,
  errors = {},
  setErrors,
  modalError,
  maxHeight,
  emptyText,
}: ConfigsAccordionProps) {
  const [productConfigs, setProductConfigs] = useList([]);
  const [employeeConfigs, setEmployeeConfigs] = useList([]);
  const configs = selectedPermissions?.length
    ? allConfigs.filter(p => selectedPermissions?.includes(p.id))
    : [];
  useEffect(() => {
    const productPermissions = configs?.filter(
        p => p.parent === 'manage-product'
      ),
      empPermissions = configs?.filter(p => p.parent === 'manage-employee');
    // get all assigned production configs from assigned permissions
    const assignedProdConfigs = assignedPermissions?.filter(p =>
        productPermissions.map(o => o.id).includes(p.id)
      ),
      allProductConfigs = assignedProdConfigs?.concat(
        productPermissions?.filter(
          p => !assignedProdConfigs?.map(a => a.id)?.includes(p.id)
        )
      ),
      assignedEmpConfigs = assignedPermissions?.filter(p =>
        empPermissions.map(o => o.id).includes(p.id)
      ),
      allEmpConfigs = assignedEmpConfigs?.concat(
        empPermissions?.filter(
          p => !assignedEmpConfigs?.map(a => a.id)?.includes(p.id)
        )
      );
    setProductConfigs.set(
      isEdit
        ? assignedProdConfigs?.length > 0
          ? allProductConfigs
          : productPermissions
        : productPermissions
    );
    setEmployeeConfigs.set(
      isEdit
        ? assignedEmpConfigs?.length
          ? allEmpConfigs
          : empPermissions
        : empPermissions
    );
  }, [selectedPermissions, assignedPermissions]);
  return (
    <div className="w-full bg-white flex flex-col space-y-4 rounded">
      <span className="text-16 font-bold flex w-full">Cài đặt quyền</span>
      {_.isEmpty(selectedPermissions) ? (
        emptyText && (
          <span className="p-1 text-14 text-black-main">{emptyText}</span>
        )
      ) : (
        <>
          {modalError?.length > 0 && (
            <div className="pl-4 text-sm text-red-400">{modalError}</div>
          )}
          <div style={{ maxHeight }} className="overflow-y-auto ">
            {productConfigs?.length > 0 && (
              <Accordion defaultExpanded>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component="div" variant="subtitle2">
                    {`Cài đặt quyền Quản lý kho sản phẩm (${productConfigs?.length} cấu hình quyền)`}
                  </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails className="flex flex-col space-y-4 mb-1">
                  {productConfigs?.some(
                    p => p.id === PermissionIds.AddProduct
                  ) && (
                    <AddProduct
                      isEdit={isEdit}
                      selectedValue={
                        productConfigs?.find(
                          p => p.id === PermissionIds.AddProduct
                        )?.configs
                      }
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewProductList
                  ) && (
                    <ViewProduct
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      selectedValue={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewProductList
                        )?.configs
                      }
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewOwnerPhoneNumber
                  ) && (
                    <CountConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      countSet={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewOwnerPhoneNumber
                        )?.configs?.count
                      }
                      permissionName="Xem số điện thoại chủ nhà"
                      permissionId={PermissionIds.ViewOwnerPhoneNumber}
                      limitText="Số lần được xem số điện thoại chủ nhà tối đa"
                      unlimitedText="Không giới hạn số lần xem số điện thoại chủ nhà"
                      inputLabel="Số lần được xem số điện thoại chủ nhà"
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewProductAttachments
                  ) && (
                    <CountConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      countSet={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewProductAttachments
                        )?.configs?.count
                      }
                      permissionName="Xem tài liệu đính kèm"
                      permissionId={PermissionIds.ViewProductAttachments}
                      limitText="Số lần được xem tài liệu tối đa"
                      unlimitedText="Không giới hạn số lần xem tài liệu đính kèm"
                      inputLabel="Số lần được xem tài liệu đính kèm"
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewProductLegalAttachments
                  ) && (
                    <CountConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      countSet={
                        productConfigs?.find(
                          p =>
                            p.id === PermissionIds.ViewProductLegalAttachments
                        )?.configs?.count
                      }
                      permissionName="Xem hồ sơ pháp lý"
                      permissionId={PermissionIds.ViewProductLegalAttachments}
                      limitText="Số lần được xem hồ sơ pháp lý tối đa"
                      unlimitedText="Không giới hạn số lần xem hồ sơ pháp lý"
                      inputLabel="Số lần được xem hồ sơ pháp lý"
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewAwaitApprovingProductList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        productConfigs?.find(
                          p =>
                            p.id === PermissionIds.ViewAwaitApprovingProductList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p =>
                          p.id === PermissionIds.ViewAwaitApprovingProductList
                      )}
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewDeletedProductList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewDeletedProductList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.ViewDeletedProductList
                      )}
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewHiddenProductList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewHiddenProductList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.ViewHiddenProductList
                      )}
                    />
                  )}
                  {productConfigs?.some(
                    p => p.id === PermissionIds.ViewPublishedProductList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        productConfigs?.find(
                          p => p.id === PermissionIds.ViewPublishedProductList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.ViewPublishedProductList
                      )}
                    />
                  )}
                </StyledAccordionDetails>
              </Accordion>
            )}
            {employeeConfigs?.length > 0 && (
              <Accordion defaultExpanded>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component="div" variant="subtitle2">
                    {`Cài đặt quyền Quản lý nhân viên (${employeeConfigs?.length} cấu hình quyền)`}
                  </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails className="flex flex-col space-y-4 mb-1">
                  {employeeConfigs?.some(
                    p => p.id === PermissionIds.AddEmployee
                  ) && (
                    <RadioConfig
                      assignedConfig={
                        employeeConfigs?.find(
                          p => p.id === PermissionIds.AddEmployee
                        )?.configs?.isNeedToApprove
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.AddEmployee
                      )}
                    />
                  )}
                  {employeeConfigs?.some(
                    p => p.id === PermissionIds.ViewEmployeeList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        employeeConfigs?.find(
                          p => p.id === PermissionIds.ViewEmployeeList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.ViewEmployeeList
                      )}
                    />
                  )}
                  {employeeConfigs?.some(
                    p => p.id === PermissionIds.ViewDeletedEmployeeList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        employeeConfigs?.find(
                          p => p.id === PermissionIds.ViewDeletedEmployeeList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p => p.id === PermissionIds.ViewDeletedEmployeeList
                      )}
                    />
                  )}
                  {employeeConfigs?.some(
                    p => p.id === PermissionIds.ViewNeedToApproveEmployeeList
                  ) && (
                    <RadioSelectDepartmentConfig
                      errors={errors}
                      setErrors={setErrors}
                      isEdit={isEdit}
                      setConfig={
                        employeeConfigs?.find(
                          p =>
                            p.id === PermissionIds.ViewNeedToApproveEmployeeList
                        )?.configs?.departments
                      }
                      config={configs?.find(
                        p =>
                          p.id === PermissionIds.ViewNeedToApproveEmployeeList
                      )}
                    />
                  )}
                </StyledAccordionDetails>
              </Accordion>
            )}
          </div>
        </>
      )}
    </div>
  );
}
