import React from 'react';

export const CommentContext = React.createContext({
  repCommentId: null,
  setRepCommentId: () => {},
  repComment: () => {},
  loading: false,
  removeComment: () => {},
});

export const useCommentContext = () => React.useContext(CommentContext);
