import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-fetch';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { isEqual, merge } from 'lodash';
import { getAuth } from './helper';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiEndpointV2 = process.env.REACT_APP_API_ENDPOINT_V2;
const apiUserEndpoint = process.env.REACT_APP_AUTH_API_ENDPOINT;
const apiMapEndpoint = process.env.REACT_APP_API_MAP;
const apiBihaEndpoint = process.env.REACT_APP_BIHA_API_ENDPOINT;
const authLink = setContext((_, { headers }) => {
  const token = getAuth();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.accessToken}` : '',
      introspectionToken: 'sometoken',
      ['accept-language']: 'vi',
    },
  };
});
const multiLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      users: `${apiUserEndpoint}/users`,
      map: `${apiMapEndpoint}`,
      image: `${apiEndpoint}/media`,
      realEstate: `${apiEndpointV2}/realEstate`,
      ewallet: `${apiEndpoint}/ewallet`,
      common: `${apiEndpoint}/common`,
      chat: `${apiEndpoint}/chat`,
    },
    createHttpLink,
  }),
]);
let apolloClients;
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(e => e.extensions?.code === 'FORBIDDEN')
    ) {
      console.log(graphQLErrors);
    }
    if (
      graphQLErrors &&
      graphQLErrors.find(e => e.extensions?.code === 'INVALID_PERMISSION') &&
      operation.query.definitions[0]?.operation === 'query' &&
      response
    ) {
      if (Object.entries(response.data)[0][1] === null) {
        // appStore.dispatch(push('/no-permission'));
      } else {
        response.errors = null;
      }
    }
    if (graphQLErrors) {
      console.log(graphQLErrors);
    }
    if (networkError) {
      console.log(networkError);
    }
  }
);
const httpLink = ApolloLink.from([
  errorLink,
  authLink,
  new MultiAPILink({
    endpoints: {
      users: `${apiUserEndpoint}/users`,
      b2bRealEstate: `${apiBihaEndpoint}/biha`,
      map: `${apiMapEndpoint}`,
      image: `${apiEndpoint}/media`,
      realEstate: `${apiEndpointV2}/realEstate`,
      ewallet: `${apiEndpoint}/ewallet`,
      common: `${apiEndpoint}/common`,
    },
    createHttpLink,
  }),
]);

const imageUploadLink = ApolloLink.from([
  errorLink,
  authLink,
  new createUploadLink({
    uri: `${apiEndpoint}/media/graphql`,
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
      ...config,
      onUploadProgress: init.onUploadProgress,
    })),
  }),
]);

const isObject = node => typeof node === 'object' && node !== null;

// rough first draft, could probably be optimised in a loads of different ways.
const hasFiles = (node, found = []) => {
  Object.keys(node).forEach(key => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const finalHttpLink = ApolloLink.split(
  ({ variables }) => hasFiles(variables),
  imageUploadLink,
  httpLink
);

// const link = ApolloLink.split(({ query }) => {
//   const definition = getMainDefinition(query);
//   return (
//     definition.kind === 'OperationDefinition' &&
//     definition.operation === 'subscription'
//   );
// }, finalHttpLink);

export const apolloClient = new ApolloClient({
  link: finalHttpLink,
  fetch,
  cache: new InMemoryCache({
    typePolicies: {
      Message: {
        fields: {
          loading: {
            read: () => {
              return false;
            },
          },
          error: {
            read: () => {
              return false;
            },
          },
        },
      },
      FileWithFullUrls: {
        keyFields: ['originalUrl'],
      },
    },
    possibleTypes: {
      MediaUnionWithFullUrls: ['FileWithFullUrls', 'YoutubeFile'],
      User: ['B2CUser', 'B2BUserType'],
    },
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'PermissionOutputType':
          return `PermissionOutputType:${responseObject.id}_${Math.random()}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }),
});

function createApolloClient() {
  return new ApolloClient({
    link: multiLink,
    fetch,
    cache: new InMemoryCache({
      typePolicies: {
        Message: {
          fields: {
            loading: {
              read: () => {
                return false;
              },
            },
            error: {
              read: () => {
                return false;
              },
            },
          },
        },
      },
      possibleTypes: {
        MediaUnionWithFullUrls: ['FileWithFullUrls', 'YoutubeFile'],
        User: ['B2CUser', 'B2BUserType'],
      },
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'B2BUsers_PermissionOutputType':
            return `B2BUsers_PermissionOutputType:${
              responseObject.id
            }_${Math.random()}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClients ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClients) apolloClients = _apolloClient;

  return _apolloClient;
}
