import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

CustomClickAwayListener.propTypes = {
  onClickAway: PropTypes.func,
  children: PropTypes.any,
};

export default function CustomClickAwayListener({ onClickAway, children }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.target.id !== 'total-button') {
          onClickAway();
        }
      }
    };

    const handleEscapeKey = event => {
      if (event.key === 'Escape') {
        onClickAway();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClickAway]);

  return <div ref={wrapperRef}>{children}</div>;
}
