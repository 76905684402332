import moment from 'moment';
import { numberWithDots } from '@utils/helper';
import { FEES_CONFIG, POST_TYPE_CONFIG } from '../constant';

type Props = {
  context?: Object,
};
export default function DetailTotalFees(props: Props) {
  const { data = {}, startDay, endDay, postType } = props.context;
  const postTypeConfig = POST_TYPE_CONFIG[postType];
  const feesConfig = FEES_CONFIG[postType];
  return (
    <div className="px-6 py-4 rounded-lg bg-white shadow-lg">
      <div className="hidden sm:flex text-base font-bold text-blue-main">
        Chi tiết giá
      </div>
      <div className="w-full mt-3 space-y-6">
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px ">Thời gian đăng tin:</div>
          <div className="flex flex-row items-center space-x-1">
            <div className="text-sm text-blue-main font-bold">
              {moment.unix(startDay).format('DD/MM/YYYY')}
            </div>
            <div className="text-sm">đến</div>
            <div className="text-sm text-blue-main font-bold">
              {moment.unix(endDay).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px ">Giá theo ngày</div>
          <div className="flex flex-row items-center space-x-1 truncate">
            <div className="text-sm font-bold text-blue-main">
              {`${feesConfig.title} VNĐ`}
            </div>
            <div className="flex text-sm">
              đối với {`${postTypeConfig.value} `}
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px ">Khuyến mãi:</div>
          <div className="text-sm text-red-500 font-bold">
            {postType === '3' ? '1 ngày' : 'Không có'}
          </div>
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px ">Tổng giá:</div>
          <div
            className={`text-sm font-bold ${
              data?.getRealEstateFees?.basicFees === 0
                ? 'text-red-500'
                : 'text-blue-main'
            }`}
          >{`${
            data?.getRealEstateFees?.basicFees === 0
              ? 'Miễn phí'
              : numberWithDots(data?.getRealEstateFees?.basicFees) + ' VNĐ'
          }`}</div>
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px ">VAT (10%):</div>
          <div className="text-sm font-bold text-blue-main">
            {data?.getRealEstateFees?.vat ?? 0} VNĐ
          </div>
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-6 items-center">
          <div className="text-sm w-130px font-bold ">Tổng chi phí:</div>
          <div className="text-base text-red-500 font-bold">
            {numberWithDots(data?.getRealEstateFees?.totalFees ?? 0)} VNĐ
          </div>
        </div>
      </div>
      <svg
        className="absolute text-white h-4 w-full left-0 top-full"
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
        xmlSpace="preserve"
      >
        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  );
}
