import { useQuery } from '@apollo/client';
import { GET_EWALLET } from '../../graphql';

export const useGetEwallet = () => {
  const { data, loading, error } = useQuery(GET_EWALLET, {});
  const main = data?.getMyEwalletInfo?.balance;
  const reward = data?.getMyEwalletInfo?.rewardBalance;
  const commission = data?.getMyEwalletInfo?.totalCommission;
  return { data, loading, error, main, reward, commission };
};
