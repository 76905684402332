import Header from '@components/header';
import { useState } from 'react';
import { View } from 'react-native-web';
import LoadingScreen from '../loadingScreen';

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [openSpotlight, setOpenSpotlight] = useState(false);

  const handleClickShow = () => {
    setOpen(false);
  };
  const handleClickIcon = () => {
    setOpen(!open);
  };
  const handleClickSpotlightIcon = () => {
    setOpenSpotlight(!openSpotlight);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#f4f4f4',
      }}
    >
      <Header
        onClickIcon={handleClickIcon}
        onClickShow={handleClickShow}
        onClickSpotlightIcon={handleClickSpotlightIcon}
        onClose={handleClose}
        isShow={open}
        openSpotlight={openSpotlight}
      />

      <LoadingScreen />
    </View>
  );
}
