import AttachmentsTable from './attachments';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';

type Props = {
  data?: Array,
};
export default function AttachmentsModal({ data }: Props) {
  const [listUrls, setListUrls] = useState(data);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(listUrls);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [listUrls]);
  return (
    <div className="w-610px h-full bg-white rounded-lg shadow-md p-4">
      <div className="flex-col mt-8 rounded-lg space-y-8">
        <AttachmentsTable attachments={listUrls} />
      </div>
    </div>
  );
}
