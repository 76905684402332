import React from 'react';
import moment from 'moment/moment';
import { useNeedApprovalContext } from '../../../../needApproval/context';
type Props = {
  data?: Object,
};
export default function RenderUserInfo({ data }: Props) {
  const { tab } = useNeedApprovalContext();
  return (
    <div
      style={{ minHeight: 209 }}
      className="w-full rounded-lg p-4 space-y-2.5 border border-gray-300"
    >
      <div className="text-base font-medium text-blue-main">Thông tin khác</div>
      <div className="flex flex-col space-y-4 mt-4 flex-1">
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Người đăng
          </div>
          <div className="flex-1 flex w-full overflow-hidden items-center">
            <div className="w-6 h-6 rounded-full overflow-hidden mr-1 bg-gray-200">
              {data?.creator?.avatar?.originalUrl && (
                <img
                  alt="avt"
                  src={data?.creator?.avatar?.originalUrl}
                  className="w-6 h-6 rounded-full mr-1"
                />
              )}
            </div>
            <div className="text-14 text-black-main">{data?.contactName}</div>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Ngày đăng
          </div>
          <div className="flex-1 text-14 text-black-main">
            {moment(data?.createdAt).format('hh:mmA, DD/MM/YYYY')}
          </div>
        </div>
        {tab.includes('needApproval') && (
          <div className="flex flex-row w-full justify-between space-x-2">
            <div className="w-120px text-14 font-medium text-black-main">
              Các phòng ban xem tin rao
            </div>
            <div className="flex-1 text-14 text-black-main">Không có</div>
          </div>
        )}
        {!tab.includes('needApproval') && (
          <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
            <div className="w-120px text-14 font-medium text-black-main">
              Ngày chỉnh sửa
            </div>
            <div className="flex-1 text-14 text-black-main">
              {moment(data?.updatedAt).format('hh:mmA, DD/MM/YYYY')}
            </div>
          </div>
        )}
        {!tab.includes('needApproval') && (
          <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
            <div className="w-120px text-14 font-medium text-black-main">
              {tab.includes('approved') ? 'Người duyệt' : 'Người từ chối'}
            </div>
            <div className="flex-1 flex w-full overflow-hidden items-center">
              <div className="w-6 h-6 rounded-full overflow-hidden mr-1 bg-gray-200">
                {data?.approvedBy?.avatar?.originalUrl && (
                  <img
                    alt="avt"
                    src={data?.approvedBy?.avatar?.originalUrl}
                    className="w-6 h-6 rounded-full mr-1"
                  />
                )}
              </div>
              <div className="text-14 text-black-main">
                {data?.approvedBy?.fullName}
              </div>
            </div>
          </div>
        )}
        {!tab.includes('needApproval') && (
          <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
            <div className="w-120px text-14 font-medium text-black-main">
              {tab.includes('approved') ? 'Ngày duyệt' : 'Ngày từ chối'}
            </div>
            <div className="flex-1 text-14 text-black-main">
              {moment(data?.approvedAt).format('HH:mmA, DD/MM/YYYY')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
