import React, { useState } from 'react';
import { View } from 'react-native-web';
import { useManageUserB2BContext } from '../context';
import LeftUserDetail from './components/leftUserDetail';
import RightUserDetail from './components/rightUserDetail';
import { useGetUserInfo } from '@utils/hooks/manageUserB2B/useGetUserDetail';
import { useDeepCompareEffect } from 'react-use';
import { useGetCompanyInfo } from '@utils/hooks/manageUserB2B/useCompanyInfo';

export default function UserDetail() {
  const { listSelected } = useManageUserB2BContext();
  const { data } = useGetUserInfo(listSelected?.[0]);
  const [currentTab, setCurrentTab] = useState('0');
  const [contractId, setContractId] = useState('');
  const [contract, setContract] = useState(null);
  const { data: b2bUserData } = useGetCompanyInfo(data?.company);
  useDeepCompareEffect(() => {
    setContractId('');
    setCurrentTab('0');
  }, [listSelected?.[0], setContractId]);
  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftUserDetail
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        contractId={contractId}
        setContractId={setContractId}
        setContract={setContract}
        data={data}
      />
      <RightUserDetail
        isPersonal={data?.isPersonal}
        contractId={contractId}
        contract={contract}
        tab={currentTab}
        userId={listSelected?.[0]}
        isLocked={data?.lockByRtAt?.length > 0}
        b2bUserData={b2bUserData}
        adminData={data}
      />
    </View>
  );
}
