import MyButton from '@components/button';
import { useProductContext } from '../product/context';
import { useCallback } from 'react';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type Props = {
  onShowModalApproval?: Function,
  onShowModalReject?: Function,
  onShowModalDeleted?: Function,
  onShowModalHidden?: Function,
  onShowModalAppear?: Function,
  onShowModalRestore?: Function,
  onShowModalDeletedPostedProduct?: Function,
  isButtonApproved?: boolean,
  isButtonProduct?: boolean,
  isButtonMyProduct?: boolean,
  isButtonHidden?: boolean,
  isButtonDeleted?: boolean,
  isButtonDeletedPostedProduct?: boolean,
  isButtonDeletedPremiumProduct?: boolean,
  isButtonDeletedAuthorizeProduct?: boolean,
  isButtonRejectedProduct?: boolean,
};
export default function RenderHandleMutationButton({
  onShowModalApproval,
  onShowModalReject,
  onShowModalDeleted,
  onShowModalHidden,
  onShowModalAppear,
  onShowModalRestore,
  onShowModalDeletedPostedProduct,
  isButtonApproved,
  isButtonProduct,
  isButtonMyProduct,
  isButtonHidden,
  isButtonDeleted,
  isButtonDeletedPostedProduct,
  isButtonDeletedAuthorizeProduct,
  isButtonDeletedPremiumProduct,
  isButtonRejectedProduct,
}: Props) {
  const { setListSelected } = useProductContext();
  const unCheckedAll = useCallback(() => {
    setListSelected.set([]);
  }, [setListSelected]);
  const { permissions } = useGetMe();
  if (isButtonApproved) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        <MyButton
          onPress={onShowModalApproval}
          style={{ width: 210 }}
          label="Duyệt tất cả"
          type="gray"
        />
        <MyButton
          onPress={onShowModalReject}
          style={{ width: 210 }}
          label="Từ chối tất cả"
          type="gray"
        />
      </div>
    );
  }
  if (isButtonProduct || isButtonMyProduct) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        {permissions?.some(p => p.id === PermissionIds.DeleteProduct) && (
          <MyButton
            onPress={onShowModalDeleted}
            style={{ width: 210 }}
            label="Xóa"
            type="gray"
          />
        )}
        {permissions?.some(
          p => p.id === PermissionIds.ViewHiddenProductList
        ) && (
          <MyButton
            onPress={onShowModalHidden}
            style={{ width: 210 }}
            label="Ẩn"
            type="gray"
          />
        )}
        <MyButton
          onPress={() => unCheckedAll()}
          style={{ width: 210 }}
          label="Bỏ chọn tất cả"
          type="gray"
        />
      </div>
    );
  }
  if (isButtonHidden) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        <MyButton
          onPress={onShowModalAppear}
          style={{ width: 210 }}
          label="Bỏ ẩn"
          type="gray"
        />
        <MyButton
          onPress={() => unCheckedAll()}
          style={{ width: 210 }}
          label="Bỏ chọn tất cả"
          type="gray"
        />
      </div>
    );
  }
  if (isButtonDeleted) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        <MyButton
          onPress={onShowModalRestore}
          style={{ width: 210 }}
          label="Khôi phục"
          type="gray"
        />
        <MyButton
          onPress={() => unCheckedAll()}
          style={{ width: 210 }}
          label="Bỏ chọn tất cả"
          type="gray"
        />
      </div>
    );
  }
  if (
    isButtonDeletedPostedProduct ||
    isButtonDeletedAuthorizeProduct ||
    isButtonDeletedPremiumProduct
  ) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        <MyButton
          onPress={onShowModalDeletedPostedProduct}
          style={{ width: 210 }}
          label="Xóa"
          type="gray"
        />
        <MyButton
          onPress={() => unCheckedAll()}
          style={{ width: 210 }}
          label="Bỏ chọn tất cả"
          type="gray"
        />
      </div>
    );
  }
  if (isButtonRejectedProduct) {
    return (
      <div className="flex flex-row items-center justify-end space-x-4">
        <MyButton
          onPress={() => unCheckedAll()}
          style={{ width: 210 }}
          label="Bỏ chọn tất cả"
          type="gray"
        />
      </div>
    );
  }
}
