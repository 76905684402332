import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ReadImageAndVideo from './readImageAndVideo';
import { getMedias } from '@utils/helper';
import { useDeepCompareEffect } from 'react-use';
import MyModalV2 from '@components/modalV2/myModalV2';
import ModalViewMedia from 'modals/modalViewMedia';
import Viewer from 'react-viewer';
import '../reactViewer.scss';

type Props = {
  imgUrls?: Array,
};

export default function ImageAndVideoList(props: Props) {
  const { imgUrls = [] } = props;
  const [showModal, setShowModal] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [listUrls, setListUrls] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(imgUrls);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [imgUrls]);
  let imagesList = [];
  let videosList = [];
  imagesList = listUrls?.filter(e => e.type === 'image')?.map(a => a.id);
  videosList = listUrls?.filter(
    e => e.type === 'video' || e.type === 'youtube'
  );
  const renderImages = () => {
    if (isEmpty(imagesList)) {
      return <div className="mt-2 text-14">Không có hình ảnh</div>;
    } else {
      return (
        <div className="grid grid-cols-3 gap-4">
          {imagesList?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ReadImageAndVideo
                  onPress={() => {
                    setShowLightbox(true);
                    setActiveIndex(index);
                  }}
                  idUrls={item}
                />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };
  const renderVideos = () => {
    if (isEmpty(videosList)) {
      return <div className="mt-2 text-14">Không có video</div>;
    } else {
      return (
        <div className="grid grid-cols-3 gap-4">
          {videosList
            ?.map(a => a.fileId)
            ?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ReadImageAndVideo
                    onPress={() => {
                      setShowModal(true);
                      setActiveIndex(index);
                    }}
                    idUrls={item}
                  />
                </React.Fragment>
              );
            })}
        </div>
      );
    }
  };
  return (
    <div className="w-full space-y-2.5">
      <div className="text-base font-semibold text-blue-main">Hình ảnh</div>
      {renderImages()}
      <div className="text-base font-semibold text-blue-main">Video</div>
      {renderVideos()}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={activeIndex} listMedia={videosList} />
      </MyModalV2>
      <Viewer
        className="viewContainer"
        // className={`${styles.test}`}
        visible={showLightbox}
        onClose={() => setShowLightbox(false)}
        onMaskClick={() => setShowLightbox(false)}
        images={listUrls
          .filter(e => e.type === 'image')
          ?.map(a => ({ src: a.originalUrl }))}
        activeIndex={activeIndex}
        zoomSpeed={0.3}
        scalable={false}
        noImgDetails={true}
        showTotal={false}
      />
    </div>
  );
}
