import { RenderTag } from '@components/tag';
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_FROM_PERMISSION } from '@utils/graphql';

type AssignedRegionConfigProps = {
  regionConfig: Array,
};

export default function AssignedRegionConfig({
  regionConfig,
}: AssignedRegionConfigProps) {
  const allProvinces = regionConfig
      ?.filter(p => p.type === 'Province')
      ?.map(c => c.id),
    allDistricts = regionConfig
      ?.filter(p => p.type === 'District')
      ?.map(c => c.id);
  const { data } = useQuery(GET_LIST_DISTRICT_FROM_PERMISSION, {
    variables: { item: regionConfig },
    skip: allProvinces?.includes('all') && allDistricts?.includes('all'),
  });
  return (
    <div className="w-full flex flex-col space-y-2">
      {data?.getDistrictsWithProvince?.map(item => (
        <div key={Math.random()} className="flex flex-col space-y-1 mt-3">
          <div className="text-sm font-medium">
            {`${item?.name} (${item?.districts?.length}/${item?.totalDistricts})`}
          </div>
          <div className="w-full flex flex-wrap space-x-2">
            {item?.districts?.map(dist => (
              <div key={Math.random()} className="w-fit mt-2">
                <RenderTag key={dist.id} value={dist?.name} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
