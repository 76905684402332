import { Image, View } from 'react-native-web';
import React from 'react';
import ImageCarousel from '@components/imageCarousel';
import Image360 from '@components/image360';
import YouTube from 'react-youtube';

type MediaViewerProps = {
  imageList?: [],
  fileId?: string,
  imageUrl?: string,
  type?: 'image' | 'youtube' | 'image360' | 'video',
  videoUrl?: string,
};

export default function MediaViewer(props: MediaViewerProps) {
  const { imageUrl, type, videoUrl, imageList, fileId } = props;
  const renderInnerComponent = () => {
    switch (type) {
      case 'image':
        if (!imageList) {
          return (
            <Image
              key={imageUrl}
              style={{ flex: 1 }}
              resizeMode="center"
              source={imageUrl}
            />
          );
        }
        return (
          <ImageCarousel
            key="image"
            type="image"
            currentImageIndex={imageList.findIndex(e => e.fileId === fileId)}
            imageList={imageList}
          />
        );
      case 'image360':
        if (!imageList) {
          return <Image360 image360={imageUrl} />;
        }
        return <Image360 image360={imageList[0]?.originalUrl} />;
      case 'video':
        return (
          <video style={{ flex: 1, height: '75%' }} controls>
            <source src={videoUrl} />
          </video>
        );
      case 'youtube':
        return (
          <YouTube
            onReady={e => e.target.pauseVideo()}
            videoId={videoUrl}
            opts={{
              width: '100%',
              playerVars: {
                autoplay: 1,
              },
            }}
          />
        );
    }
  };
  return <View style={{ flex: 1 }}>{renderInnerComponent()}</View>;
}
