import React from 'react';
import styled from 'styled-components';
import { useMeasure, usePrevious } from 'react-use';
import { useSpring, animated } from 'react-spring';

const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  overflow: hidden;
  width: 100%;
`;

type Props = {
  expand?: boolean,
  children?: React.ReactChildren,
};

export default function CollapsibleV2({ expand: isOpen, children }: Props) {
  const previous = usePrevious(isOpen);
  const [ref, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <Content
      style={{
        opacity,
        height: isOpen && previous === isOpen ? 'auto' : height,
      }}
    >
      <animated.div style={{ transform }} ref={ref}>
        {children}
      </animated.div>
    </Content>
  );
}
