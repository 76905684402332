import MyDropdown from '@components/myDropdown';
import React, { useCallback } from 'react';
import styles from '@components/table/table.module.scss';
import SelectionComponentFilter from '@components/filterDropdownComponentGroup/selectionComponentFilter';
import { useQuery } from '@apollo/client';
import { convertDataSelections } from '@utils/helper';
import { GET_LIST_STREET_OF_DISTRICT } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';

type Props = {
  value?: string,
  setValue?: Function,
  selections?: Array,
  districtId?: string,
  wardId?: string,
  provinceId?: string,
};
export default function StreetDropdown({
  value = '',
  setValue = () => {},
  districtId,
  wardId,
}: Props) {
  let queryStreet,
    queryVariables = '';
  (queryStreet = GET_LIST_STREET_OF_DISTRICT),
    (queryVariables = { districtId, limit: 500 });
  const { data, loading } = useQuery(queryStreet, {
    variables: {
      ...queryVariables,
    },
    skip: !districtId && !wardId,
  });
  const finalData = data?.getListStreetsOfDistrict?.streets;
  const rawData = [...(finalData || [])];
  const dataSelections = convertDataSelections(rawData);
  const toast = useToast();
  const checkDistrict = useCallback(
    value => {
      if (value) {
        if (!districtId) {
          toast({
            position: 'bottom-right',
            duration: 5000,
            render: ({ id, onClose }) => (
              <MyToast
                id={id}
                onClose={onClose}
                wrapperStyle="w-394px shadow-md"
                type="error"
                message={'Vui lòng chọn Tỉnh/Thành phố và Quận huyện trước'}
                closeButtonPos="center"
              />
            ),
          });
        }
      }
    },
    [districtId]
  );
  return (
    <MyDropdown
      popperPlacement="bottom"
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={SelectionComponentFilter}
      dropdownContext={{
        selections: dataSelections,
        value,
        setValue,
        isShowSearchBox: true,
        loading,
        getObject: true,
      }}
    >
      {showDropdown => {
        checkDistrict(showDropdown);
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Đường
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
