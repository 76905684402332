export const timeToRentConfigs = [
  {
    key: 'less6M',
    from: 0,
    to: 6,
    value: '< 6 tháng',
  },
  {
    key: '6MTo1Y',
    from: 6,
    to: 12,
    value: '6 tháng - 1 năm',
  },
  {
    key: '1YTo2Y',
    from: 12,
    to: 24,
    value: '1 năm - 2 năm',
  },
  {
    key: '2YTo3Y',
    from: 24,
    to: 36,
    value: '2 năm - 3 năm',
  },
  {
    key: '3YTo4Y',
    from: 36,
    to: 48,
    value: '3 năm - 4 năm',
  },
  {
    key: '4YTo5Y',
    from: 48,
    to: 60,
    value: '4 năm - 5 năm',
  },
  {
    key: '5YTo6Y',
    from: 60,
    to: 72,
    value: '5 năm - 6 năm',
  },
  {
    key: '6YTo7Y',
    from: 72,
    to: 84,
    value: '6 năm - 7 năm',
  },
  {
    key: '7YTo8Y',
    from: 84,
    to: 96,
    value: '7 năm - 8 năm',
  },
  {
    key: '8YTo9Y',
    from: 96,
    to: 108,
    value: '8 năm - 9 năm',
  },
  {
    key: '9YTo10Y',
    from: 108,
    to: 120,
    value: '9 năm - 10 năm',
  },
];

export const userTypeConfig = [
  { key: 'yes', value: 'Cá nhân' },
  { key: 'no', value: 'Doanh nghiệp' },
];
export const contractStatusConfigs = [
  {
    key: 'active',
    value: 'Còn hạn',
  },
  {
    key: 'expired',
    value: 'Hết hạn',
  },
];
export const contractTypeConfigs = [
  {
    key: 'product',
    value: 'BĐS riêng lẻ',
  },
  {
    key: 'project',
    value: 'Dự án',
  },
];
export const demandConfig = [
  {
    key: 'forSale',
    value: 'Bán',
  },
  {
    key: 'forRent',
    value: 'Cho thuê',
  },
];
export const fieldGetRealEstate = [
  'realEstateId',
  'demandEstate',
  'realEstateType',
  'type',
  'isForSell',
  'area',
  'width',
  'length',
  'bedroomCount',
  'toiletCount',
  'floorCount',
  'price',
  'priceUnit',
  'postTitle',
  'postType',
  'mediaUrls { ...FileAllFields2 }',
  'mediaUrlsIds',
  'isAuthorizedRe',
  'saveItem { id note belongsToCollection { id } }',
  'createdAt',
  'isSaved',
  'privateNote',
  'fullAddress',
  'postStatus',
];
export const estate = 'estate',
  need_estate = 'need_estate';

export const initialPriceUnitForSale = 'VND',
  initialPriceUnitForRent = 'VndPerMonth',
  initialTimeForRentUnit = 'month';
