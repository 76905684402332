import UserAvatar from './userAvatar';
import React, { useState } from 'react';
import Section from '../../../../../../manageRealEstate/realEstateDetail/components/leftRealEstateDetail/contentDetailRealEstate/section';
import ItemRowUserDetail from './itemRowUserDetail';
import OtherInfo from './otherInfo';
import moment from 'moment';
import RenderIdImage from '../../../../../../manageUser/b2b/userDetail/components/leftUserDetail/contentUserDetail/renderIdImage';
import { rolePermissions } from '../../../../../../../../constant';
import AttachmentsTable from './attachments';

type Props = {
  data?: Object,
};
export default function RenderEmployeeInfo({ data }: Props) {
  const [showPhone, setShowPhone] = useState(false);
  const [showPhoneSeCond, setShowPhoneSeCond] = useState(false);
  return (
    <div className="w-full flex flex-row space-x-4">
      <div className="w-250px md:w-323px flex flex-col space-y-4">
        <UserAvatar
          avatarUrl={data?.avatar?.originalUrl}
          username={data?.username}
        />
        <OtherInfo
          data={data?.creator}
          createdAt={data?.createdAt}
          updatedAt={data?.updatedAt}
        />
      </div>
      <div className="flex-1 p-4 rounded-lg border border-blue-main space-y-4">
        <Section source="/svg/book-icon.svg" title="Thông tin cá nhân">
          <ItemRowUserDetail title="Họ và tên" value={data?.fullName} />
          <ItemRowUserDetail
            title="Tỉnh/ Thành phố"
            value={data?.province?.name}
          />
          <div className="flex flex-row py-2 border-b border-gray-300 space-x-10 items-center">
            <p className="text-sm mr-6 font-medium text-black-main w-120px">
              Số điện thoại 1
            </p>
            <div className="flex flex-row space-x-2 items-center">
              <p className="text-sm w-95px">
                {showPhone ? data?.phone : '**********'}
              </p>
              <img
                onClick={() => setShowPhone(!showPhone)}
                alt="ico"
                src={`/svg/${showPhone ? 'eye.svg' : 'eye-close-icon.svg'}`}
              />
            </div>
          </div>
          <div className="flex flex-row py-2 border-b border-gray-300 space-x-10 items-center">
            <p className="text-sm mr-6 font-medium text-black-main w-120px">
              Số điện thoại 2
            </p>
            <div className="flex flex-row space-x-2 items-center">
              {data?.secondPhone ? (
                <p className="text-sm w-95px">
                  {showPhoneSeCond ? data?.secondPhone : '**********'}
                </p>
              ) : (
                <p className="text-14 font-text italic text-gray-500">
                  Không có
                </p>
              )}
              {data?.secondPhone && (
                <img
                  onClick={() => setShowPhoneSeCond(!showPhoneSeCond)}
                  alt="ico"
                  src={`/svg/${
                    showPhoneSeCond ? 'eye.svg' : 'eye-close-icon.svg'
                  }`}
                />
              )}
            </div>
          </div>
          <ItemRowUserDetail title="Email" value={data?.email} />
          <ItemRowUserDetail
            title="Giới tính"
            value={data?.gender === 'male' ? 'Nam' : 'Nữ'}
          />
          <ItemRowUserDetail
            title="Ngày sinh"
            value={
              data?.dateOfBirth
                ? moment(data?.dateOfBirth).format('DD/MM/YYYY')
                : 'Không có'
            }
          />
          <ItemRowUserDetail title="Số CMND/CCCD" value={data?.idNumber} />
          {data?.idImages?.length > 0 && (
            <RenderIdImage idImages={data?.idImages} wrapperClassName="mt-2" />
          )}
        </Section>
        <Section source="/svg/content-paste.svg" title="Bộ phận">
          <ItemRowUserDetail
            title="Bộ phận"
            value={data?.departments[0]?.department?.name}
          />
          <ItemRowUserDetail
            title="Chức vụ"
            value={data?.departments[0]?.positionName}
          />
          <ItemRowUserDetail
            title="Người quản lý"
            value={data?.creator?.name}
          />
          <ItemRowUserDetail
            title="Bộ quyền"
            value={data?.permissionRoles
              ?.map(r => rolePermissions[r])
              .join(', ')}
          />
          <ItemRowUserDetail title="Bộ quyền" value={data?.permissionRole} />
          {data?.attachments?.length > 0 && (
            <div className="mt-7 space-y-4 flex-1">
              <div className="text-sm font-medium text-black-main">
                Tài liệu đính kèm
              </div>
              <AttachmentsTable attachments={data?.attachments} />
            </div>
          )}
        </Section>
      </div>
    </div>
  );
}
