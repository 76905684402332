import styles from './radioButton.module.scss';

type Props = {
  isChecked?: boolean,
  disabled?: boolean,
  title?: string,
  titleClass?: string,
  value?: any,
  onPress?: Function,
  title2Class?: string,
  title2?: string,
  wrapperClass?: string,
};

export default function RadioButton({
  isChecked,
  title,
  titleClass,
  title2,
  title2Class,
  value,
  onPress = () => {},
  wrapperClass,
  disabled,
}: Props) {
  return (
    <button
      disabled={disabled}
      className={`flex flex-row items-center space-x-2 ${wrapperClass} ${
        disabled ? 'opacity-50' : undefined
      }`}
      onClick={() => onPress(value)}
    >
      <div className={`${styles.radioButton} ${isChecked && styles.checked}`}>
        <div
          className={`${styles.innerChecked} ${isChecked && 'bg-blue-main'}`}
        />
      </div>
      <div className="flex-col">
        <div className={`text-14 text-black-main ${titleClass}`}>{title}</div>
        <div className={`text-14 text-black-main ${title2Class}`}>{title2}</div>
      </div>
    </button>
  );
}
