import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, View } from 'react-native-web';
import { getMediaUrlByType } from '@utils/helper';

type Media = {
  id?: number,
  imageUrl?: string,
  type?: 'image' | 'youtube' | 'image360',
  videoUrl?: string,
};

type ImageCarouselProps = {
  imageList?: Media[],
  currentImageIndex?: number,
};

export default function ImageCarousel(props: ImageCarouselProps) {
  const { imageList, currentImageIndex = 0 } = props,
    carouselRef = useRef(null),
    [carouselWidth, setCarouselWidth] = useState(0);
  useEffect(() => {
    carouselRef.current?.scrollToOffset({
      offset: currentImageIndex * carouselWidth * 0.5 - carouselWidth * 0.25,
    });
  }, [currentImageIndex, carouselWidth]);
  if (imageList.length === 1) {
    return (
      <View
        onLayout={({
          nativeEvent: {
            layout: { width },
          },
        }) => setCarouselWidth(width)}
        style={{ flex: 1, alignItems: 'center' }}
      >
        <Image
          style={{
            width:
              imageList[0].type === 'image360'
                ? carouselWidth
                : carouselWidth * 0.5,
            height: '100%',
            borderWidth: 2,
            borderColor: '#000',
          }}
          source={{ uri: getMediaUrlByType(imageList[0], 'fullHd') }}
          resizeMode="cover"
        />
      </View>
    );
  }

  return (
    <FlatList
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => setCarouselWidth(width)}
      horizontal
      ref={carouselRef}
      keyExtractor={item => item.fileId}
      scrollEnabled={false}
      showsHorizontalScrollIndicator={false}
      style={{ flex: 1 }}
      data={imageList}
      renderItem={({ item }) => {
        if (item.type === 'image360') {
          return (
            <Image
              resizeMode="cover"
              style={{
                width: carouselWidth,
                height: '100%',
                borderWidth: 2,
                borderColor: '#000',
              }}
              source={{ uri: item.originalUrl }}
            />
          );
        }
        return (
          <Image
            resizeMode="cover"
            style={{
              scrollSnapAlign: 'start',
              width: carouselWidth * 0.5,
              height: '100%',
              borderWidth: 2,
              borderColor: '#000',
            }}
            source={{ uri: getMediaUrlByType(item, 'fullHd') }}
          />
        );
      }}
    />
  );
}
