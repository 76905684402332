import moment from 'moment/moment';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import RenderAvatarComponent from 'scenes/home/manageProduct/components/renderAvatarComponent';
import { realEstateStatus } from '../../../../../../../utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useState } from 'react';
import HistoryDetailModal from './historyDetailModal';

type Props = {
  data?: Object,
  setCurrentTab?: Function,
};

export default function ItemHistory(props: Props) {
  const { data, setCurrentTab } = props;
  const [showModal, setShowModal] = useState(false);
  let text = '';
  switch (data?.historyType) {
    case 'viewProductDetail':
      text = 'đã xem chi tiết sản phẩm';
      break;
    case 'addProduct':
      text = 'đã thêm sản phẩm';
      break;
    case 'changeStatus':
      text = `đã chuyển trạng thái sản phẩm từ "${
        realEstateStatus.find(e => e.key === data?.ops[0]?.originalValue)?.value
      }" sang "${
        realEstateStatus.find(e => e.key === data?.ops[0]?.value)?.value
      }"`;
      break;
    case 'contactPhones':
      text = 'đã xem số điện thoại chủ nhà';
      break;
    case 'viewProductOnMap':
      text = 'đã xem sản phẩm trên bản đồ';
      break;
    case 'productComment':
      text = 'đã ghi chú sản phẩm';
      break;
    case 'approvedProduct':
      text = 'đã duyệt sản phẩm';
      break;
    case 'rejectedProduct':
      text = 'đã từ chối sản phẩm';
      break;
    case 'addDocumentationProduct':
      text = 'đã bổ sung tài liệu cho sản phẩm';
      break;
    case 'removeDocumentationProduct':
      text = 'đã xóa tài liệu';
      break;
    case 'deleteProduct':
      text = 'đã xóa sản phẩm';
      break;
    case 'editProduct':
      text = 'đã chỉnh sửa sản phẩm';
      break;
  }
  return (
    <div className="flex w-full space-x-3 bg-white border-b border-gray-history">
      <div className="self-center pl-2">
        <RenderAvatarComponent
          avatarId={
            data?.userId?.avatar ||
            data?.ops?.find(e => e.path === '/rejectedBy')?.value?.avatar
          }
          wrapperStyle="w-40px h-40px"
        />
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="flex flex-1 space-x-0.5 pt-2">
          <MyDropdown
            popperPlacement="right"
            DropdownComponent={UserInfo}
            dropdownContext={{
              userId:
                data?.userId?.id ||
                data?.ops?.find(e => e.path === '/rejectedBy')?.value?.id,
            }}
          >
            <button className="flex items-start space-x-1">
              <span
                className="font-text font-medium underline whitespace-nowrap"
                style={{ color: '#106FFF', fontSize: 14 }}
              >
                {data?.userId?.fullName + ` (${data?.userId?.employeeCode})`}
              </span>
              <img src="/svg/eye.svg" style={{ minWidth: 13, height: 20 }} />
            </button>
          </MyDropdown>
          <div className="flex flex-1 overflow-hidden">
            <span
              className="font-text text-black-main"
              style={{ fontSize: 14 }}
            >
              {text}
            </span>
          </div>
        </div>
        <div
          className="font-text text-gray-date mt-2.5"
          style={{ fontSize: 14 }}
        >
          {moment(data?.date).format('HH:mm A, DD/MM/YYYY')}
        </div>
      </div>
      {[
        'editProduct',
        'addDocumentationProduct',
        'removeDocumentationProduct',
        'rejectedProduct',
      ].includes(data?.historyType) && (
        <div
          className="text-14 font-medium text-blue-main underline pr-2 self-center cursor-pointer"
          onClick={() => {
            data?.historyType === 'editProduct'
              ? setCurrentTab('productEditHistory')
              : setShowModal(true);
          }}
        >
          Xem chi tiết
        </div>
      )}
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <HistoryDetailModal data={data} onClose={() => setShowModal(false)} />
      </MyModalV2>
    </div>
  );
}
