import React from 'react';
import Section from './section';
import { convertFloatWithDot, DocTienBangChuV2 } from '@utils/helper';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { buildingTypeConfigs } from '@utils/configs/configsLocal';
import DmsCoordinates from 'dms-conversion';

type Props = {
  data?: Object,
  demand?: string,
};

export default function DetailProduct({ data, demand }: Props) {
  const {
    forRentRealEstateTypes = [],
    directions = [],
    warehouseType = [],
    // villaViewTypes = [],
  } = configs;
  const realEstateType = data?.type;

  const textArea = ['condotel', 'apartment', 'penthouse'].includes(
    realEstateType
  )
    ? 'Diện tích (tim tường)'
    : !['motel'].includes(realEstateType)
    ? 'Diện tích'
    : 'Diện tích phòng';
  return (
    <Section
      source="/svg/notes_1.svg"
      title="Thông tin sản phẩm"
      style={{ flex: 1 }}
      innerClass="mt-4"
    >
      <Item
        src="/svg/detailRealEstate/loai.svg"
        title="Loại hình"
        value={
          (demand === 'sale' || data?.isForSell ? 'Bán' : 'Cho thuê') +
          ' ' +
          forRentRealEstateTypes
            ?.find(e => e.key === realEstateType)
            ?.value.toLowerCase()
        }
      />
      <Item
        src="/svg/detailRealEstate/NganhHang.svg"
        title="Ngành hàng"
        value={data?.shophouseField}
        hideField={!['shophouse'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/ChucNangNhaXuong.svg"
        title="Chức năng nhà xưởng"
        value={warehouseType.find(e => e.key === data?.warehouseType)?.value}
        hideField={!['warehouse', 'land'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/LoaiCaoOc.svg"
        title="Loại cao ốc"
        value={
          buildingTypeConfigs.find(e => e.key === data?.buildingType)?.value
        }
        hideField={realEstateType !== 'building'}
      />
      <Item
        src="/svg/percent.svg"
        title="Phí hoa hồng"
        value={data?.commission ? data?.commission : null}
      />
      <Item
        src="/svg/detailRealEstate/diachi.svg"
        title="Địa chỉ"
        value={data?.fullAddress}
      />
      <Item
        src="/svg/detailRealEstate/toado.svg"
        title="Tọa độ"
        valueStyle="flex-col"
        value={
          data?.map?.coordinates?.length
            ? new DmsCoordinates(
                data?.map?.coordinates[1],
                data?.map?.coordinates[0]
              ).toString()
            : null
        }
        linkContent="Xem trên Google map"
        link={`https://www.google.com/maps/place/${data?.map?.coordinates[1]},
        ${data?.map?.coordinates[0]}`}
      />
      <Item
        src="/svg/detailRealEstate/mattienhayhem.svg"
        title="Mặt tiền hay hẻm"
        value={data?.hasAlley ? 'Hẻm' : 'Mặt tiền'}
      />
      <Item
        src="/svg/detailRealEstate/solanqueohem.svg"
        title="Số lần quẹo hẻm"
        value={data?.alleyTurnCount}
      />
      <Item
        src="/svg/detailRealEstate/doronghem.svg"
        title="Độ rộng hẻm trước nhà"
        value={data?.alleyWidth || data?.frontHomeAlleyWidth}
        unit={'m'}
      />
      <Item
        src="/svg/detailRealEstate/direction.svg"
        title="Hướng"
        value={directions.find(e => e.key === data?.direction)?.value}
      />
      <Item
        src="/svg/detailRealEstate/CoGacHayKhong.svg"
        title="Có gác hay không"
        value={data?.hasMezzanine ? 'Có' : 'Không'}
        hideField={data?.type !== 'motel'}
      />
      <Item
        src="/svg/detailRealEstate/Block-Khu.svg"
        title="Thuộc Block"
        value={data?.block || data?.blockName}
        hideField={
          !['apartment', 'condotel', 'land', 'shophouse', 'penthouse'].includes(
            realEstateType
          )
        }
      />
      <Item
        src="/svg/detailRealEstate/sothutuphong.svg"
        title="Số thứ tự phòng"
        value={data?.roomNumber}
        hideField={realEstateType !== 'apartment'}
      />
      <Item
        src="/svg/detailRealEstate/sophongngu.svg"
        title="Số phòng ngủ"
        value={convertFloatWithDot(data?.bedroomCount)}
        hideField={[
          'building',
          'land',
          'hotel',
          'warehouse',
          'shophouse',
        ].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/sonhavesinh.svg"
        title="Số nhà vệ sinh"
        value={convertFloatWithDot(data?.toiletCount)}
        hideField={['land', 'hotel'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/sotang.svg"
        title="Vị trí tầng"
        value={convertFloatWithDot(data?.onFloor)}
        hideField={
          !['apartment', 'penthouse', 'condotel', 'motel'].includes(
            realEstateType
          )
        }
      />
      <Item
        src="/svg/detailRealEstate/sotang.svg"
        title="Số tầng"
        value={convertFloatWithDot(data?.floorCount)}
        hideField={['apartment', 'condotel'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/soluongphong.svg"
        title="Số phòng kho"
        value={data?.warehouseRoomCount}
        hideField={realEstateType !== 'warehouse'}
      />
      <Item
        src="/svg/detailRealEstate/SoSao.svg"
        title="Số sao"
        value={data?.hotelStar}
        hideField={realEstateType !== 'hotel'}
      />
      <Item
        src="/svg/detailRealEstate/soluongphong.svg"
        title="Số lượng phòng"
        value={data?.roomCount}
        hideField={realEstateType !== 'hotel'}
      />
      <Item
        src="/svg/detailRealEstate/viewbien.svg"
        title="View biển"
        // eslint-disable-next-line no-extra-boolean-cast
        value={!!data?.hasSeaView ? 'Có' : null}
        hideField={realEstateType !== 'hotel'}
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Diện tích phòng ngủ"
        value={convertFloatWithDot(data?.bedRoomArea)}
        unit="m²"
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title={textArea}
        value={data?.area}
        unit="m²"
        value2={
          '(ngang: ' +
          data?.width +
          'm, dài: ' +
          data?.length +
          'm, mặt hậu: ' +
          (data?.rear
            ? data?.rear + 'm)'
            : '<span class="italic opacity-50">Không có</span>' + ')')
        }
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Diện tích sàn"
        value={convertFloatWithDot(data?.constructionArea)}
        unit="m²"
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Diện tích thông thủy"
        value={convertFloatWithDot(data?.clearanceArea)}
        unit="m²"
        hideField={
          !['condotel', 'penthouse', 'apartment'].includes(realEstateType)
        }
      />
      <Item
        src="/svg/detailRealEstate/HienTrang.svg"
        title="Hiện trạng"
        value={data?.isNewConstruction || data?.isNewly ? 'Xây mới' : 'Xây cũ'}
        hideField={realEstateType === 'land'}
      />
      <Item
        src="/svg/detailRealEstate/TenDuAn.svg"
        title="Tên dự án"
        value={data?.projectName || data?.project}
      />
      {/* <Item
        src="/svg/detailRealEstate/direction.svg"
        title="View"
        value={data?.villaView
          ?.map(item => ' ' + villaViewTypes.find(e => e.key === item)?.value)
          .toString()}
      /> */}
      <Item
        src="/svg/detailRealEstate/giadukien.svg"
        title={
          demand === 'sale' || demand === true ? 'Giá bán' : 'Giá cho thuê'
        }
        value={
          demand === 'sale' || demand === true
            ? DocTienBangChuV2(data?.price)
            : `${DocTienBangChuV2(data?.price)}/tháng`
        }
        valueStyle="text-red-600"
      />
      <Item
        src="/svg/detailRealEstate/giadukien.svg"
        title="Giá diện tích đất"
        value={DocTienBangChuV2(parseInt(data?.price / data?.area))}
        unit="/m²"
      />
      <Item
        src="/svg/detailRealEstate/giadukien.svg"
        title="Định giá của RT247"
        value={DocTienBangChuV2(data?.tnccPrice) || '-'}
      />
      <Item
        src="/svg/detailRealEstate/ChuDauTu.svg"
        title="Chủ đầu tư"
        value={data?.investor}
        hideField={
          !['apartment', 'land', 'shophouse', 'penthouse', 'condotel'].includes(
            realEstateType
          )
        }
      />
      {data?.timeForRent && demand === 'rent' && (
        <Item
          src="/svg/detailRealEstate/calendar.svg"
          title="Thời gian thuê"
          value={data?.timeForRent}
          unit={' ' + (data?.timeForRentUnit === 'month' ? 'tháng' : 'năm')}
        />
      )}
    </Section>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  unit?: string,
  src?: string,
  valueStyle?: Object,
  hideField?: boolean,
  linkContent?: string,
  link?: string,
  value2?: string,
};

function Item({
  title,
  value = '',
  unit = '',
  src,
  valueStyle,
  hideField,
  link,
  linkContent = '',
  value2 = '',
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex w-full justify-between py-3 border-b border-gray-300">
      <div className="flex items-center space-x-2">
        <img alt="ico" src={src} className="w-4 h-4" />
        <div className={`text-sm mr-6 font-medium text-black-main w-154px`}>
          {title}
        </div>
      </div>
      <div className={`text-sm text-right flex ${valueStyle}`}>
        {value}
        {unit}
        <div
          className="ml-1"
          dangerouslySetInnerHTML={{
            __html: value2,
          }}
        />
        {linkContent ? (
          <div>
            <br />
            <a
              className="text-blue-main underline"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {linkContent}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
