import gql from 'graphql-tag';

export const READ_MEDIA_URLS = gql`
  query readMediaUrls($fileId: ObjectID!) @api(name: image) {
    readMediaUrls(fileId: $fileId) {
      ... on File {
        id
        fileId
        type
        urls {
          type
          url
        }
        type
        originalKey
        originalUrl
        createdAt
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          videoId
          title
          description
          thumbnailUrl
        }
      }
    }
  }
`;
export const READ_LIST_MEDIA = gql`
  query ($fileIds: [String]!) @api(name: image) {
    readListMedia(fileIds: $fileIds) {
      ... on File {
        fileId
        originalKey
        type
        urls {
          type
          key
        }
        creator
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          videoId
          title
          description
          thumbnailUrl
        }
      }
    }
  }
`;

export const MEDIA_UNION = gql`
  fragment FileAllFields2 on MediaUnionWithFullUrls {
    ... on YoutubeFile {
      type
      fileId
      youtubeMetadata {
        thumbnailUrl
        videoId
      }
    }
    ... on FileWithFullUrls {
      type
      fileId
      originalUrl
      urls {
        type
        url
      }
    }
  }
`;
