import HeaderTableRealEstateEditHistory from './components/headerTableRealEstateEditHistory';
import Table from '@components/table';
import TableRow from './components/tableRow';
import React from 'react';
import { useProductContext } from 'scenes/home/manageProduct/product/context';
import { useGetProductHistory } from '../../../../../../../utils/hooks/realEstateEditHistory/useGetProductHistory';
import { headerTableRealEstateEditHistoryConfigs } from './configs';
import Pagination from '@components/pagination';
import { PAGINATION_LIMIT } from 'constant';
import { View } from 'react-native-web';

type Props = {
  type?: string,
};

export default function ProductEditHistory(props: Props) {
  const { type } = props;
  const { listSelected } = useProductContext();
  const {
    data,
    onLoadByPagination,
    loading,
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  } = useGetProductHistory({
    realEstateId: listSelected[0],
    params: {
      historyTypes: [
        { historyType: 'addProduct' },
        { historyType: 'editProduct' },
        { historyType: 'addDocumentationProduct' },
        { historyType: 'removeDocumentationProduct' },
      ],
      sort: '-date',
      limit: PAGINATION_LIMIT,
    },
  });
  const pagination = {
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
  const headerTableConfigs = headerTableRealEstateEditHistoryConfigs;

  const handleOnPageChange = event => {
    onLoadByPagination(parseInt(event.selected));
  };
  return (
    <>
      <div
        className="w-full h-full overflow-hidden table table-fixed"
        style={{
          width: '100%',
          backgroundColor: 'white',
          marginTop: 16,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
        }}
      >
        <View style={{ maxHeight: 480 }}>
          <Table>
            <div className="flex-1 flex flex-col">
              <HeaderTableRealEstateEditHistory
                headerTableConfigs={headerTableConfigs}
                type={type}
              />
              {data
                ?.filter(e => e.ops?.path !== '/approveStatus/status')
                .map((item, index) => {
                  return (
                    <TableRow
                      pagination={pagination}
                      type={type}
                      key={`row-${index}`}
                      item={item}
                      index={index}
                      headerTableConfigs={headerTableConfigs}
                      attachments={item?.attachments}
                    />
                  );
                })}
            </div>
          </Table>
        </View>
      </div>
      <div className="w-full flex justify-end py-6">
        <Pagination
          loading={loading}
          pagination={pagination}
          onPageChange={handleOnPageChange}
        />
      </div>
    </>
  );
}
