import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { ClientStyle } from 'react-css-component';
import useMeasure from 'react-use/lib/useMeasure';
import { debounce } from 'lodash';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import {
  MAP_MODE,
  MODE,
  defaultLat,
  defaultLong,
} from '@utils/configs/map/mapConfigs';
import { useSyncLocation } from '@utils/hooks/map/useSyncGeolocation';
import useDebounce from 'react-use/lib/useDebounce';
import sortBy from 'lodash/sortBy';
import useList from 'react-use/lib/useList';
import { convertFloatWithDot } from '@utils/helper';
import shortid from 'shortid';
import {
  angleFromCoordinate,
  getDistanceFromLatLonInKm,
  getVariablesFilterMap,
} from '@components/realEstateOnMap/utils';
import styles from './reslEstateOnMap.module.scss';
import useToggle from 'react-use/lib/useToggle';
import useFullscreen from 'react-use/lib/useFullscreen';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import InternalMapLayer from '@components/realEstateOnMap/components/internalMapLayer';
import RealEstatesOnMap from '@components/realEstateOnMap/components/realEstatesOnMap';
import { useQuery } from '@apollo/client';
import { REAL_ESTATE_PRODUCT_ON_MAP } from '@utils/graphql';
import Spinner from '@components/spinner';
import PopUpRealEstate from '@components/realEstateOnMap/components/popUpRealEstate';
import { defaultVariableFilterOnMap } from '@components/realEstateOnMap/configs';
import PathWithDistance from '@components/map/pathWithDistance';
import SelectedLocationMarker from '@components/map/selectedLocationMarker';
import CustomToolMap from '@components/realEstateOnMap/components/customToolMap';
import LandInfoLayer from '@components/realEstateOnMap/components/landsLayer';
import MeasureLayer from '@components/realEstateOnMap/components/measureLayer';
import MeasuringNotification from '@components/realEstateOnMap/components/measuringNotification';
import Notification from '@components/realEstateOnMap/components/notification';
import HeadMap from '@components/realEstateOnMap/components/headMap';

const css = `
  .leaflet-popup-content-wrapper {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .leaflet-popup-tip-container {
    display: none;
  }
  .leaflet-polygon {
    stroke: rgba(36, 89, 173);
    fill: rgba(135, 180, 252);
    stroke-width: 2;
    fill-opacity: 0.15 !important;
  }
`;

const geoarea = require('geo-area')(/*options*/ { x: 'lng', y: 'lat' });

let ReactLeaflet, TileLayer, Map, DrawingLayer, Polygon, CustomMarker, Tooltip;

if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  TileLayer = ReactLeaflet.TileLayer;
  Map = ReactLeaflet.Map;
  CustomMarker = require('../map/customMarker').CustomMarker;
  Polygon = ReactLeaflet.Polygon;
  Tooltip = ReactLeaflet.Tooltip;
  DrawingLayer = require('./components/drawingLayer').default;
}

const MAX_ZOOM = 22,
  MIN_ZOOM = 13,
  CURRENT_ZOOM = 17;

type Props = {
  radius?: number,
  lat?: number,
  long?: number,
  currentZoom?: number,
  points?: Array,
  disableChangingLocation?: boolean,
  onChange?: Function,
};

export default function MapLayout(props: Props) {
  const {
    lat: initialLat,
    long: initialLong,
    radius = 500,
    points: initialPoints,
    disableChangingLocation,
    onChange,
    currentZoom = CURRENT_ZOOM,
  } = props;

  const location = useLocation();
  const router = qs.parse(location.search);

  const mapType = router?.mapType || MAP_MODE.NORMAL;
  const latQuery = router?.lat;
  const longQuery = router?.long;
  const mapRef = useRef(null);
  const [boundaries, setBoundaries] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [debouncedBoundaries, setDebouncedBoundaries] = useState(null);
  const [mode, setMode] = useState(MODE.NONE);
  const [polygon, setPolygon] = useState([]);
  const [popupOpen] = useState(false);
  const [listRealEstate, setListRealEstate] = useState([]);
  const [closePolygon, setClosePolygon] = useState(false);
  const [landId, setLandId] = useState(null);
  const freedrawRef = useRef(null);
  const [coordinatesRealEstate, setCoordinatesRealEstate] = useState(null);

  const [pinLocation, setPinLocation] = useState(false);

  const [showWorldMap, setShowWorldMap] = useState(true);

  const pointsRef = useRef(null),
    [hasSearchLocation, setHasSearchLocation] = useState(false),
    [atLocation, setAtLocation] = useState(false),
    animatedToCurrentLocation = useRef(false);

  const [currentLat, setCurrentLat] = useState(null),
    [currentLng, setCurrentLng] = useState(null);

  const mapContainerRef = useRef(null),
    [show, toggleFullScreen] = useToggle(false),
    isFullScreen = useFullscreen(mapContainerRef, show, {
      onClose: () => toggleFullScreen(false),
    });

  const [variablesFilter, setVariablesFilter] = useState({
    ...defaultVariableFilterOnMap,
  });
  const variablesFilterMap = getVariablesFilterMap(variablesFilter);

  const onActiveZoom = useCallback(() => {
    toggleFullScreen();
  }, [toggleFullScreen]);

  const [points, setPoints] = useList(initialPoints || []);

  const [measureMap, size] = useMeasure();

  const [{ height: heightOfMeasureNoti }] = useMeasure();

  const debounceInvalidateSize = useCallback(
    debounce(() => {
      mapRef.current?.leafletElement?.invalidateSize();
    }, 500),
    []
  );

  useDeepCompareEffect(() => {
    debounceInvalidateSize();
  }, [size]);

  const { longitude, latitude } = useSyncLocation({
    maximumAge: 60000,
    timeout: 5000,
    enableHighAccuracy: true,
  });

  useEffect(() => {
    if (!currentLat && !currentLng) {
      setCurrentLng(longitude);
      setCurrentLat(latitude);
    }
  }, [currentLat, currentLng, longitude, latitude]);

  const finalLat =
      lat || parseFloat(latQuery) || initialLat || currentLat || defaultLat,
    finalLong =
      long || parseFloat(longQuery) || initialLong || currentLng || defaultLong;
  useDebounce(
    () => {
      if (!popupOpen) {
        setDebouncedBoundaries && setDebouncedBoundaries(boundaries);
      }
    },
    500,
    [boundaries, popupOpen]
  );

  const mapRelatedVariables = useMemo(() => {
    if (!debouncedBoundaries) {
      return {};
    }
    const obj = {
      bottomLeft: {
        coordinates: [
          debouncedBoundaries.getSouthWest().lng,
          debouncedBoundaries.getSouthWest().lat,
        ],
      },
      upperRight: {
        coordinates: [
          debouncedBoundaries.getNorthEast().lng,
          debouncedBoundaries.getNorthEast().lat,
        ],
      },
    };
    if (mode === MODE.NONE) {
      obj.circle = {
        center: {
          coordinates: [finalLong, finalLat],
        },
        radius,
      };
    }
    if (mode === MODE.DRAWING) {
      obj.polygonPoints = polygon.map(e => ({
        type: 'Point',
        coordinates: [e.lng, e.lat],
      }));
    }
    return obj;
  }, [debouncedBoundaries, mode, finalLat, finalLong, radius, polygon]);

  const measurePoints = [...points],
    betweenPoints = [],
    closePolygonPoints =
      closePolygon && measurePoints.length > 2
        ? [...measurePoints, { id: 'close', ...measurePoints[0] }]
        : [...measurePoints];

  closePolygonPoints.forEach((item, index) => {
    if (index === 0) {
      return;
    }
    betweenPoints.push({
      points: [measurePoints[index - 1], item],
      lat: (item.lat + measurePoints[index - 1].lat) / 2,
      lng: (item.lng + measurePoints[index - 1].lng) / 2,
      distance: getDistanceFromLatLonInKm(
        measurePoints[index - 1].lat,
        measurePoints[index - 1].lng,
        item.lat,
        item.lng
      ),
      corner: angleFromCoordinate(
        measurePoints[index - 1].lat,
        measurePoints[index - 1].lng,
        item.lat,
        item.lng
      ),
      shouldRotate: item.lng < measurePoints[index - 1].lng,
    });
  });

  const km = betweenPoints?.map(e => e.distance);
  const totalKm = km.reduce((sum, number) => sum + number, 0);
  let renderTotal;
  if (totalKm < 1) {
    const t = (totalKm * 1000).toFixed(1);
    renderTotal = convertFloatWithDot(t) + ' m';
  } else {
    const t = totalKm.toFixed(1);
    renderTotal = convertFloatWithDot(t) + ' km';
  }

  let area;
  if (closePolygon && measurePoints.length >= 3) {
    area = geoarea(closePolygonPoints);
  }
  let renderArea;
  if (area >= 1000000) {
    const tempArea = (area / 1000000)?.toFixed(1);
    renderArea = convertFloatWithDot(tempArea) + ' km²';
  } else {
    const t = area?.toFixed(1);
    renderArea = convertFloatWithDot(t) + ' m²';
  }

  const variableMap = useMemo(() => {
    return {
      ...variablesFilterMap,
      ...mapRelatedVariables,
    };
  }, [mapRelatedVariables, variablesFilterMap]);

  const isMeasuring = mode === MODE.MEASURING,
    isDrawing = mode === MODE.DRAWING;

  const { loading, data } = useQuery(REAL_ESTATE_PRODUCT_ON_MAP, {
    variables: variableMap,
    skip:
      !debouncedBoundaries ||
      mapType === MAP_MODE.INTERNAL ||
      isMeasuring ||
      (isDrawing && !polygon.length),
  });

  useEffect(() => {
    if (data) {
      setListRealEstate(data?.realEstateProductOnMap);
    }
  }, [data]);

  const unfilteredREOnMap = [...(listRealEstate || [])];

  let realEstateOnMap = [...(unfilteredREOnMap || [])];

  const shouldRenderREMarker = unfilteredREOnMap?.find(
    ({ count, lat: clusterLat, long: clusterLong } = {}) => {
      return (
        count === 1 && initialLat === clusterLat && initialLong === clusterLong
      );
    }
  );

  if (shouldRenderREMarker) {
    realEstateOnMap = sortBy(
      [...unfilteredREOnMap].filter(e => e?.id !== shouldRenderREMarker.id),
      'count'
    );
  }

  const duplicateLocation = finalLat === currentLat && finalLong === currentLng;

  const onMapClicked = useCallback(
    ({ latlng: { lat: nextLat, lng: nextLng } }) => {
      if (pinLocation) {
        setLat(nextLat);
        setLong(nextLng);
        setPinLocation(false);
      }
      if (isDrawing) {
        return;
      }
      if (!isMeasuring) {
        if (!disableChangingLocation) {
          onChange && onChange(nextLat, nextLng);
        }
      } else if (!closePolygon) {
        setPoints.push({ lat: nextLat, lng: nextLng, id: shortid.generate() });
      }
    },
    [
      isMeasuring,
      onChange,
      disableChangingLocation,
      closePolygon,
      isDrawing,
      pinLocation,
    ]
  );

  useEffect(() => {
    setTimeout(() => {
      mapRef?.current?.leafletElement?.flyTo(
        [finalLat, finalLong],
        currentZoom
      );
    }, 600);
  }, [finalLat, finalLong]);

  useEffect(() => {
    if (!animatedToCurrentLocation.current) {
      const flyToBoundsPoints = [];
      if (initialLong && initialLat) {
        flyToBoundsPoints.push([initialLat, initialLong]);
      }
      flyToBoundsPoints.push([
        currentLat || defaultLat,
        currentLng || defaultLong,
      ]);
      if (flyToBoundsPoints.length && mapRef?.current) {
        if (flyToBoundsPoints.length === 1) {
          mapRef?.current?.leafletElement?.flyTo(
            flyToBoundsPoints[0],
            currentZoom
          );
        } else {
          mapRef?.current?.leafletElement?.flyToBounds(flyToBoundsPoints, {
            padding: [50, 50],
          });
        }
        animatedToCurrentLocation.current = true;
      }
    }
  }, [initialLat, initialLong, currentLat, currentLng, currentZoom]);

  const onFly = () => {
    if (!currentLat && !currentLng) {
      setAtLocation(true);
    } else if (mapRef?.current) {
      mapRef?.current?.leafletElement?.flyTo([currentLat, currentLng], 19);
    }
  };

  return (
    <div
      style={{ boxShadow: '0px 3px 8px -5px rgba(0, 0, 0, 0.24)' }}
      className="w-full h-full bg-white rounded-lg p-3"
      ref={mapContainerRef}
    >
      <ClientStyle css={css} />
      <HeadMap
        onExpandPress={() => setLandId(null)}
        findDetailLandByCoordinateCompleted={dataDetails => {
          setLandId(dataDetails?.getLandDetailsByCoordinate?.id);
          const detailCoordinate =
            dataDetails?.getLandDetailsByCoordinate.location.coordinates;
          setLat(detailCoordinate?.[1]);
          setLong(detailCoordinate?.[0]);
        }}
        deleteDraw={() => {
          freedrawRef.current?.reset();
          setListRealEstate([]);
        }}
        showDeleteDraw={polygon.length > 0}
        onPressArea={() => {
          setCoordinatesRealEstate(null);
          setLandId(null);
          setMode(MODE.NONE);
          setShowWorldMap(true);
          setPinLocation(false);
        }}
        variablesFilter={variablesFilter}
        setVariablesFilter={setVariablesFilter}
        onLocationFound={({
          location: { coordinates },
          viewport: nextViewport,
        }) => {
          setHasSearchLocation(true);
          setLat(coordinates[1]);
          setLong(coordinates[0]);
          if (nextViewport) {
            mapRef?.current?.leafletElement?.flyToBounds([
              [nextViewport.northeast.lat, nextViewport.northeast.lng],
              [nextViewport.southwest.lat, nextViewport.southwest.lng],
            ]);
          } else {
            mapRef?.current?.leafletElement?.flyTo(
              [coordinates[1], coordinates[0]],
              13
            );
          }
        }}
        onResetLocation={() => {}}
      />
      <CustomToolMap
        isMeasuring={isMeasuring}
        heightOfMeasureNoti={heightOfMeasureNoti}
        showPinLocation={mapType === MAP_MODE.INTERNAL}
        pinLocation={pinLocation}
        setPinLocation={() => setPinLocation(!pinLocation)}
        onOpenDirect={() => {
          if (!currentLng && !currentLat) {
            setAtLocation(!atLocation);
          } else {
            window.open(
              `https://www.google.com/maps/dir/${currentLat},${currentLng}/${
                lat || initialLat
              },${long || initialLong}`
            );
          }
        }}
        showWorldMap={showWorldMap}
        toggleShowWorldMap={() => setShowWorldMap(!showWorldMap)}
        showButtonWordMap={mapType === MAP_MODE.AREA}
        showDirect={hasSearchLocation || (initialLat && initialLong)}
        isFullScreen={isFullScreen}
        onPressLocation={onFly}
        onPressPlus={() => setZoom(Math.min(zoom + 1, MAX_ZOOM))}
        onPressEx={() => setZoom(Math.max(zoom - 1, 0))}
        mode={mode}
        hideDraw={[MAP_MODE.INTERNAL, MAP_MODE.AREA].includes(mapType)}
        onPressZoom={onActiveZoom}
        onPressMeasure={() => {
          setClosePolygon(false);
          setCoordinatesRealEstate(null);
          setLandId(null);
          if (mode === MODE.MEASURING) {
            setMode(MODE.NONE);
            setPoints.set([]);
          } else {
            setMode(MODE.MEASURING);
          }
        }}
        onPressDraw={() => {
          setCoordinatesRealEstate(null);
          setLandId(null);
          if (mode === MODE.DRAWING) {
            setMode(MODE.NONE);
          } else {
            setPoints.set([]);
            setMode(MODE.DRAWING);
            setListRealEstate([]);
          }
        }}
      />
      <div
        id="map-container"
        style={{
          width: '100%',
          position: 'relative',
        }}
        className={isFullScreen ? styles.containerFullScreen : styles.container}
        ref={measureMap}
      >
        <Map
          tap={false}
          ref={mapRef}
          onClick={onMapClicked}
          onViewportChanged={({ zoom: nextZoom }) => {
            setZoom(nextZoom);
            if (zoom !== nextZoom) {
              setBoundaries(mapRef?.current?.leafletElement?.getBounds());
            }
          }}
          whenReady={() => {
            setBoundaries(mapRef?.current?.leafletElement?.getBounds());
          }}
          scrollWhellZoom={true}
          maxZoom={MAX_ZOOM}
          minZoom={MIN_ZOOM}
          zoomControl={false}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            cursor: pinLocation ? 'url("/svg/pinLocationBlue.svg"), auto' : '',
          }}
          center={[10.7711545, 106.6689676]}
          zoom={zoom}
        >
          {showWorldMap && (
            <TileLayer
              maxZoom={22}
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
          )}
          {!duplicateLocation &&
            lat &&
            long &&
            currentLat &&
            currentLng &&
            !isMeasuring &&
            !isDrawing && (
              <PathWithDistance
                color="#000"
                lat={(lat + currentLat) / 2}
                lng={(long + currentLng) / 2}
                distance={getDistanceFromLatLonInKm(
                  currentLat,
                  currentLng,
                  lat,
                  long
                )}
                corner={angleFromCoordinate(lat, long, currentLat, currentLng)}
                shouldRotate={currentLng < long}
                points={[
                  { lat, lng: long },
                  { lat: currentLat, lng: currentLng },
                ]}
              />
            )}
          {currentLng && currentLat && !isDrawing && !isMeasuring && (
            <CustomMarker position={[currentLat, currentLng]}>
              <div
                style={{
                  backgroundImage: 'url("/svg/currentLocation.svg")',
                }}
                className="w-6 h-6 transform -translate-x-1/2 -translate-y-2/4 bg-cover bg-center bg-no-repeat"
              />
              <Tooltip>
                <div className={styles.tutorial}>
                  <p className="bodyText">Vị trí hiện tại</p>
                </div>
              </Tooltip>
            </CustomMarker>
          )}
          {mapType === MAP_MODE.INTERNAL && !isMeasuring && (
            <LandInfoLayer
              landId={landId}
              onSelectedLand={nextLandId => {
                if (mode === MODE.NONE) {
                  setLandId(nextLandId);
                }
              }}
              lat={finalLat}
              long={finalLong}
            />
          )}
          {mapType === MAP_MODE.AREA && <InternalMapLayer />}
          {isDrawing && (
            <DrawingLayer
              ref={freedrawRef}
              onPolygonCreated={setPolygon}
              setIsDrawing={() => {}}
            />
          )}
          {isMeasuring && (
            <MeasureLayer
              closePolygon={closePolygon}
              setClosePolygon={setClosePolygon}
              points={points}
              setPoints={setPoints}
              pointsRef={pointsRef}
            />
          )}
          {isMeasuring && measurePoints.length > 1 && closePolygon && (
            <Polygon
              color={'#22313E'}
              weight="0"
              positions={closePolygonPoints.map(
                ({ lat: itemLat, lng: itemLng }) => [itemLat, itemLng]
              )}
            />
          )}
          {isMeasuring &&
            measurePoints.length > 1 &&
            betweenPoints.map(e => {
              return (
                <PathWithDistance
                  polygons={measurePoints}
                  key={`${e.lat}_${e.lng}`}
                  {...e}
                />
              );
            })}
          {isMeasuring && (
            <MeasuringNotification
              area={area}
              clear={setPoints.clear}
              measurePoints={measurePoints}
              renderArea={renderArea}
              renderTotal={renderTotal}
              setClosePolygon={setClosePolygon}
              setMode={setMode}
            />
          )}
          {mapType === MAP_MODE.NORMAL && !isMeasuring && (
            <RealEstatesOnMap
              coordinatesRealEstate={coordinatesRealEstate}
              realEstateOnMap={realEstateOnMap}
              zoom={zoom}
              onClickCircle={({
                bottomLeft: nextBottomLeft,
                upperRight: nextUpperRight,
              }) => {
                setCoordinatesRealEstate({
                  bottomLeft: nextBottomLeft,
                  upperRight: nextUpperRight,
                });
              }}
            />
          )}
          {mode === MODE.NONE && (
            <SelectedLocationMarker
              lat={finalLat}
              lng={finalLong}
              duplicateLocation={duplicateLocation}
              radius={radius}
              showCircle={mapType === MAP_MODE.NORMAL}
            />
          )}
          {atLocation && (
            <Notification
              show={atLocation}
              onPress={() => {
                setAtLocation(false);
              }}
            />
          )}
        </Map>
        {coordinatesRealEstate && (
          <PopUpRealEstate
            variablesFilterMap={variablesFilterMap}
            onClose={() => {
              setCoordinatesRealEstate(null);
            }}
            coordinatesRealEstate={coordinatesRealEstate}
            isFullScreen={isFullScreen}
          />
        )}
        {loading && (
          <div
            className="absolute bottom-1 left-1 flex"
            style={{ zIndex: 1000 }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
