import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

type Props = {
  children?: React.ReactChildren,
  isOpen?: boolean,
  onClose?: Function,
  showCloseIcon?: boolean,
  maxWidth?: any,
  maxHeight?: any,
  style?: Object,
};
export default function MyModal({
  isOpen,
  onClose,
  children,
  showCloseIcon = true,
  maxWidth,
  maxHeight,
  style,
}: Props) {
  return (
    <Modal autoFocus={false} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent style={{ ...style }} maxW={maxWidth} maxH={maxHeight}>
        {showCloseIcon && (
          <img
            style={{ zIndex: 100 }}
            onClick={onClose}
            alt="ico"
            src="/svg/ico-close.svg"
            className="w-6 h-6 absolute right-2 top-2 cursor-pointer"
          />
        )}
        {children}
      </ModalContent>
    </Modal>
  );
}
