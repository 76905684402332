import { useQuery } from '@apollo/client';
import { GET_NEED_REAL_ESTATE_MANAGEMENT_BY_FIELD } from '@utils/graphql';
import { useLoadMore } from '../common/useLoadMore';
import { useState } from 'react';
import { getVariables } from '@utils/hooks/manageRealEstate/useGetRealEstateManagement';

export const useGetNeededRealEstateManagement = ({
  state,
  params,
  demand,
  tab,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = getVariables(state);
  const finalVariables = {
    ...variables,
    ...params,
    page: 1,
    limit: 10,
    sort: tab.includes('needApproval') ? { updatedAt: 1 } : { approvedAt: -1 },
  };
  const { data, fetchMore, loading, error } = useQuery(
    GET_NEED_REAL_ESTATE_MANAGEMENT_BY_FIELD([
      'isNeedToBuy',
      'realEstateId',
      'isNeedToBuy',
      'type',
      'street { id name }',
      'ward { id name }',
      'district { id name }',
      'province { id name }',
      'width {key value from to unit}',
      'length {key value from to unit}',
      'rear {key value from to unit}',
      'area {key value from to unit}',
      'constructionArea {key value from to unit}',
      'floorCount {key value from to unit}',
      'price {key value from to unit}',
      'approvalStatus',
      'isDeleted',
      'createdAt',
      'updatedAt',
      'approvedBy { fullName avatar { ...FileAllFields } }',
      'approvedAt',
      'postStatus',
      'isNewly',
    ]),
    {
      variables: {
        ...finalVariables,
      },
      skip: demand === 'sell',
    }
  );

  const neededRealEstates = data?.getNeededRealEstatesManagement?.items || [];
  const hasNextPage = data?.getNeededRealEstatesManagement?.hasNextPage;
  const page = data?.getNeededRealEstatesManagement?.page;
  const total = data?.getNeededRealEstatesManagement?.total;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });

  return {
    realEstates: neededRealEstates || [],
    loading: loading || (!data && !error),
    isLoadingMore,
    onLoadMore,
    hasNextPage,
    page,
    total,
  };
};
