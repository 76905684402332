type Props = {
  isChecked?: boolean,
  disabled?: boolean,
  isPartialChecked?: boolean,
  title?: string,
  titleClass?: string,
  value?: any,
  onPress?: Function,
  wrapperClass?: string,
};

export default function Checkbox(props: Props) {
  const {
    isChecked,
    isPartialChecked,
    title,
    titleClass,
    onPress,
    value,
    disabled,
    wrapperClass,
  } = props;
  const Icon = isChecked
    ? '/svg/checked-table-icon.svg'
    : isPartialChecked
    ? '/svg/ic_partial_check.svg'
    : '/svg/empty-check-table-icon.svg';

  return (
    <button
      type="button"
      disabled={disabled}
      className={`flex flex-row items-center space-x-2 ${wrapperClass}`}
      onClick={() => onPress(value)}
    >
      <img src={Icon} alt="icon" className="w-6 h-6" />
      <div className={`text-13 font-text text-black text-left ${titleClass}`}>
        {title}
      </div>
    </button>
  );
}
