import HeaderTableRealEstateEditHistory from './components/headerTableRealEstateEditHistory';
import Table from '@components/table';
import TableRow from './components/tableRow';
import SeeMore from '@components/seeMore';
import React from 'react';
import { useProductContext } from 'scenes/home/manageProduct/product/context';
import { useGetRealEstateHistory } from '../../../../../../../utils/hooks/realEstateEditHistory/useGetRealEstateHistory';
import { headerTableRealEstateRenewHistoryConfigs } from './configs';

export default function ProductRenewHistory() {
  const { listSelected } = useProductContext();
  const { data, onLoadMore, hasNextPage, loading } = useGetRealEstateHistory({
    realEstateId: listSelected[0],
    params: {
      historyType: 'renewHistory',
      type: 'product',
      sort: '-date',
      limit: 10,
    },
  });
  const headerTableConfigs = headerTableRealEstateRenewHistoryConfigs;
  return (
    <div
      className="w-full h-full overflow-hidden table table-fixed"
      style={{
        width: '100%',
        backgroundColor: 'white',
        marginTop: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#2459ad',
        overflow: 'hidden',
      }}
    >
      <Table>
        <div className="flex-1 flex flex-col w-screen">
          <HeaderTableRealEstateEditHistory
            headerTableConfigs={headerTableConfigs}
          />
          {data.map((item, index) => {
            return (
              <TableRow
                key={`row-${index}`}
                item={item}
                index={index}
                headerTableConfigs={headerTableConfigs}
              />
            );
          })}
          {!loading && !data?.length && (
            <p className="text-black-main text-base m-3">
              Không có lịch sử gia hạn
            </p>
          )}
        </div>
      </Table>
      <SeeMore
        hasNextPage={hasNextPage}
        loading={loading}
        onLoadMore={onLoadMore}
      />
    </div>
  );
}
