import React from 'react';

type Props = {
  title?: string,
  children?: React.ReactChildren,
  style?: Object,
  wrapperClass?: string,
  innerClass?: string,
  innerStyle?: Object,
  renderRightComponent?: Function,
  source?: string,
  onClick?: Function,
};

export default function Section(props: Props) {
  const {
    title,
    children,
    style,
    wrapperClass,
    innerClass,
    innerStyle,
    renderRightComponent,
    source,
    onClick = () => {},
    ...rest
  } = props;
  return (
    <div
      className={`flex flex-col w-full ${wrapperClass}`}
      style={style}
      {...rest}
    >
      <div
        className="flex w-full items-center justify-between"
        onClick={onClick}
      >
        <div className="flex items-center space-x-2">
          <div
            style={{ backgroundColor: '#F4F4F4' }}
            className="w-30px h-30px rounded-lg flex items-center justify-center"
          >
            <img alt="ico" src={source} className="w-7.5 h-7.5" />
          </div>
          <div className="text-base font-medium text-blue-main">{title}</div>
        </div>
        {renderRightComponent && renderRightComponent()}
      </div>
      <div className={`w-full ${innerClass}`} style={innerStyle}>
        {children}
      </div>
    </div>
  );
}
