import moment from 'moment/moment';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

export const convertInteractionHistory = (data = []) => {
  const dataWithMonth = data?.map(item => ({
      ...item,
      ops: item.ops,
      month: moment(item.date).format('YYYY/MM'),
    })),
    groupedResults = groupBy(dataWithMonth, 'month');
  return orderBy(
    Object.entries(groupedResults).map(([month, items]) => ({
      month,
      items,
    })),
    ['month'],
    ['desc']
  );
};
