import { getMedias } from '@utils/helper';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import AttachmentsTable from './attachments';
import Legal from './legal';
import QuickInfo from './quickInfo';
import Utilities from './utilities';
import LocationRealEstate from './locationRealEstate';
import DetailProject from './detailProject';
import RealEstateTypeInProject from './realEstateTypeInProject';
import InvestorInfo from './investorInfo';
import RenderLeft from '../../renderLeft';

type Props = {
  data?: Object,
};
export default function ProjectDetail({ data }: Props) {
  const [listAttachment, setListAttachment] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data2 = await getMedias(data?.attachments || []);
        setListAttachment(data2);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [data?.attachments]);
  return (
    <div className="flex-row flex space-x-4">
      <RenderLeft data={data} />
      <div className="w-full">
        <div className="flex-1 p-4 rounded-lg border border-blue-main">
          <QuickInfo
            data={data}
            demand={data?.typeOfDemand || data?.isForSell}
          />
        </div>
        <div className="flex-1 p-4 rounded-lg border border-blue-main mt-4 space-y-7">
          <InvestorInfo data={data} />
          <LocationRealEstate data={data} />
          <DetailProject data={data} />
          <RealEstateTypeInProject data={data} />
          {[
            ...(data?.fixedUtilities || []),
            ...(data?.utilities || []),
            ...(data?.localUtilities || []),
            ...(data?.equipmentsAndServices || []),
          ]?.length > 0 && <Utilities data={data} />}
          <Legal data={data} />
          {listAttachment?.length > 0 && (
            <div className="mt-7 space-y-4 flex-1">
              <div className="text-sm font-medium text-black-main">
                Tài liệu đính kèm
              </div>
              <AttachmentsTable attachments={listAttachment || []} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
