import MyDropdown from '@components/myDropdown';
import React from 'react';
import styles from '@components/table/table.module.scss';
import MultiCheckDropdownComponent from '@components/filterDropdownComponentGroup/multiCheckDropdownComponent';
import { isNewConstructionConfigs } from '@utils/configs/configsLocal';

type Props = {
  value?: string,
  setValue?: Function,
};
export default function IsNewConstructionDropdown({
  value = '',
  setValue = () => {},
}: Props) {
  return (
    <MyDropdown
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={MultiCheckDropdownComponent}
      dropdownContext={{
        selections: isNewConstructionConfigs,
        value,
        setValue,
      }}
    >
      {showDropdown => {
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Hiện trạng
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
