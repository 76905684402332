import MyModalV2 from '@components/modalV2/myModalV2';
import MyButton from '@components/button';
import { useNavigate } from 'react-router-dom';
import { useGetPublishRealEstateType } from '@utils/hooks/createB2BRealEstate/useGetPublishRealEstateType';

type Props = {
  open?: boolean,
  onClose?: Function,
  isEdit?: boolean,
  onContinue?: Function,
};
export default function PublishRealEstateSuccessModal({
  open,
  onClose,
  isEdit,
  onContinue,
}: Props) {
  const navigate = useNavigate();
  const { premium, authorized } = useGetPublishRealEstateType();
  return (
    <MyModalV2 hideClose open={open} onClose={onClose}>
      <div className="bg-white w-450px max-w-95% rounded-lg p-6 flex flex-col justify-center items-center w-full h-full space-y-6">
        <div className="w-full flex justify-center">
          <img className="w-60px h-60px" alt="tick" src="/svg/success.svg" />
        </div>
        <div className="flex flex-1 flex-col items-center space-y-4">
          <div
            style={{ color: '#40D124' }}
            className="text-2xl font-medium text-center"
          >
            {isEdit
              ? `Chỉnh sửa tin rao thành công !`
              : `Đăng tin rao thành công !`}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `Tin đăng sẽ cần được duyệt trước khi xuất hiện trên trang chủ`,
            }}
            className="text-sm text-black-main text-center"
          />
        </div>
        <div className="w-full flex space-x-4">
          <MyButton
            onPress={() => {
              onClose();
              navigate(
                premium
                  ? '/manageProduct/product-posted?tab=product-premium'
                  : authorized
                  ? '/manageProduct/product-posted?tab=product-authorize'
                  : '/manageProduct/product-posted'
              );
            }}
            type="gray"
            label="Xem kho sản phẩm"
          />
          {!isEdit && (
            <MyButton
              onPress={() => {
                onClose();
                onContinue();
              }}
              type="blue"
              label="Tiếp tục đăng tin khác"
            />
          )}
        </div>
      </div>
    </MyModalV2>
  );
}
