import { Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './pagination.module.scss';

const MIN_TOTAL_PAGE_TO_SHOW_FULL_INFO = 3;
const FIRST_PAGE = 1;

Pagination.propTypes = {
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
};
export default function Pagination({ loading, pagination, onPageChange }) {
  const {
    totalItem = 0,
    totalPage = 0,
    currentPage = 0,
    rowsPerPage = 0,
    currentCountFrom = 0,
    currentCountTo = 0,
    hasPrevPage = false,
    hasNextPage = false,
  } = pagination;

  const [virtualPage, setVirtualPage] = useState(currentPage);

  const isValidPagination =
    rowsPerPage >= 0 &&
    currentPage > 0 &&
    totalPage >= 0 &&
    currentCountFrom >= 0 &&
    currentCountTo >= 0;

  useEffect(() => {
    setVirtualPage(currentPage);
  }, [currentPage]);

  return (
    isValidPagination && (
      <div className={`flex items-center gap-x-4 `}>
        <span className={styles.paginationInfo}>
          Hiển thị từ{' '}
          {
            <strong>
              {virtualPage > totalPage
                ? '0 - 0'
                : `${
                    currentCountFrom > currentCountTo ? 0 : currentCountFrom
                  } - ${currentCountTo}`}
            </strong>
          }{' '}
          trong tổng {<strong>{totalItem}</strong>}
        </span>
        <ul className={styles.pagination}>
          <li
            className={`${styles.prev} ${styles.paginationItem} ${
              !hasPrevPage ? styles.disabled : ''
            }`}
            onClick={() => {
              if (!loading) {
                const prevPage = virtualPage - 1;
                setVirtualPage(prevPage);
                return onPageChange({ selected: prevPage });
              }
            }}
          >
            <img src="/svg/prevPage.svg" alt="prev-page" />
          </li>
          {totalPage > MIN_TOTAL_PAGE_TO_SHOW_FULL_INFO &&
          virtualPage > MIN_TOTAL_PAGE_TO_SHOW_FULL_INFO ? (
            <>
              <li
                className={`${styles.paginationItem} ${
                  virtualPage === FIRST_PAGE ? styles.active : ''
                }`}
                value={FIRST_PAGE}
                onClick={() => {
                  if (!loading) {
                    setVirtualPage(FIRST_PAGE);
                    return onPageChange({ selected: FIRST_PAGE });
                  }
                }}
              >
                <span>{FIRST_PAGE}</span>
              </li>
              <li className={`${styles.paginationItem} ${styles.break}`}>
                <span>...</span>
              </li>
              {virtualPage < totalPage && (
                <li
                  className={`${styles.paginationItem} ${
                    virtualPage !== FIRST_PAGE ? styles.active : ''
                  }`}
                  onClick={() => {
                    if (!loading) {
                      setVirtualPage(virtualPage);
                      return onPageChange({ selected: virtualPage });
                    }
                  }}
                >
                  <span>{virtualPage}</span>
                </li>
              )}
            </>
          ) : (
            <>
              {_.range(
                (virtualPage > totalPage
                  ? totalPage
                  : totalPage > MIN_TOTAL_PAGE_TO_SHOW_FULL_INFO
                  ? 3
                  : totalPage) + 1
              )
                .filter(page => page != 0)
                .map(page => (
                  <li
                    key={page}
                    className={`${styles.paginationItem} ${
                      virtualPage === page ? styles.active : ''
                    }`}
                    onClick={() => {
                      if (!loading) {
                        setVirtualPage(page);
                        return onPageChange({ selected: page });
                      }
                    }}
                  >
                    <span>{page}</span>
                  </li>
                ))}
            </>
          )}
          <Tooltip title='Nhập số trang bạn muốn đến và ấn "Enter"'>
            <input
              type="number"
              placeholder="Nhập số trang"
              className={styles.paginationInput}
              inputMode="numeric"
              onKeyDown={event => {
                if (
                  event.key === '+' ||
                  event.key === '-' ||
                  event.key === 'e' ||
                  event.key === '.' ||
                  event.key === ','
                ) {
                  event.preventDefault();
                }
                if (event.key === 'Enter') {
                  const newPage = event.target.value;
                  event.target.value = '';
                  if (newPage <= totalPage && !loading) {
                    setVirtualPage(parseInt(newPage));
                    onPageChange({ selected: newPage });
                  }
                }
              }}
            />
          </Tooltip>
          {totalPage > MIN_TOTAL_PAGE_TO_SHOW_FULL_INFO && (
            <li
              className={`${styles.paginationItem} ${
                virtualPage === totalPage ? styles.active : ''
              }`}
              onClick={() => {
                if (!loading) {
                  setVirtualPage(totalPage);
                  return onPageChange({ selected: totalPage });
                }
              }}
            >
              <span>{totalPage}</span>
            </li>
          )}
          <li
            className={`${styles.next} ${styles.paginationItem} ${
              !hasNextPage ? styles.disabled : ''
            }`}
            onClick={() => {
              if (!loading) {
                const nextPage = virtualPage + 1;
                setVirtualPage(nextPage);
                return onPageChange({ selected: nextPage });
              }
            }}
          >
            <img src="/svg/nextPage.svg" alt="next-page" />
          </li>
        </ul>
      </div>
    )
  );
}
