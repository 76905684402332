import React from 'react';
import moment from 'moment/moment';
import RenderAvatarComponent from '../../../../components/renderAvatarComponent';

type Props = {
  data?: Object,
};
export default function RenderUserInfo({ data }: Props) {
  return (
    <div
      style={{ minHeight: 209 }}
      className="w-full rounded-lg p-4 space-y-2.5 border border-gray-300"
    >
      <div className="text-base font-medium text-blue-main">Thông tin khác</div>
      <div className="flex flex-col space-y-4 mt-4 flex-1">
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Người đăng
          </div>
          <div className="flex-1 flex w-full overflow-hidden items-center">
            <div className="w-6 h-6 rounded-full overflow-hidden mr-1 bg-gray-200">
              {data?.creator?.avatar ? (
                <RenderAvatarComponent
                  wrapperStyle="w-30px h-30px"
                  avatarId={data?.creator?.avatar}
                />
              ) : (
                <img
                  alt="avt"
                  src="/svg/male.svg"
                  className="w-6 h-6 rounded-full mr-1"
                />
              )}
            </div>
            <div className="text-14 text-black-main">
              {data?.creator?.fullName}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Ngày đăng
          </div>
          <div className="flex-1 text-14 text-black-main opacity-50">
            {moment(data?.createdAt).format('hh:mmA, DD/MM/YYYY')}
          </div>
        </div>
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Người chỉnh sửa
          </div>
          <div className="flex-1 flex w-full overflow-hidden items-center">
            <div className="w-6 h-6 rounded-full overflow-hidden mr-1 bg-gray-200">
              {data?.editor?.avatar ? (
                <RenderAvatarComponent
                  wrapperStyle="w-30px h-30px"
                  avatarId={data?.editor?.avatar}
                />
              ) : (
                <img
                  alt="avt"
                  src="/svg/male.svg"
                  className="w-6 h-6 rounded-full mr-1"
                />
              )}
            </div>
            <div className="text-14 text-black-main">
              {data?.editor?.fullName ? (
                data?.editor?.fullName
              ) : (
                <span className="opacity-50 italic">Không có</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between pb-2 border-b border-gray-300 space-x-2">
          <div className="w-120px text-14 font-medium text-black-main">
            Ngày chỉnh sửa
          </div>
          <div className="flex-1 text-14 text-black-main opacity-50">
            {moment(data?.updatedAt).format('hh:mmA, DD/MM/YYYY')}
          </div>
        </div>
      </div>
    </div>
  );
}
