import React, { useMemo } from 'react';
import { interpolate } from '@utils/helper';
import tinycolor from 'tinycolor2';

const minMarkerSize = 36,
  maxMarkerSize = 67;

const getNodeStyle = (number: number) => {
  return interpolate(number, {
    inputRange: [1, 100],
    outputRange: [minMarkerSize, maxMarkerSize],
  });
};

let CustomMarker;
if (process.browser) {
  CustomMarker = require('../../map/customMarker').CustomMarker;
}

type Props = {
  lat?: number,
  long?: number,
  count?: number,
  firstItem?: Object,
  bottomLeft?: Object,
  upperRight?: Object,
  onClickCircle?: Function,
  coordinatesRealEstate?: Object,
};

export default function MarkerWithPopup({
  lat,
  long,
  count,
  firstItem,
  bottomLeft,
  upperRight,
  onClickCircle = () => {},
  coordinatesRealEstate,
}: Props) {
  const markerSize = getNodeStyle(count);
  const isActive =
    JSON.stringify(bottomLeft?.coordinates) ===
      JSON.stringify(coordinatesRealEstate?.bottomLeft?.coordinates) &&
    JSON.stringify(upperRight?.coordinates) ===
      JSON.stringify(coordinatesRealEstate?.upperRight?.coordinates);

  const renderInner = useMemo(() => {
    return (
      <div
        style={{
          width: markerSize,
          height: markerSize,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          className="w-full h-full rounded-rd50 flex flex-col items-center justify-center"
          style={{
            backgroundColor: isActive
              ? '#ec6e00'
              : tinycolor('#2459ad').setAlpha(0.19),
          }}
        >
          <div className="w-9/12 h-3/4 flex flex-col items-center justify-center rounded-rd50 bg-blue-main">
            <p className="text-13 text-white text-center font-text">{count}</p>
          </div>
        </div>
      </div>
    );
  }, [markerSize, count, isActive]);

  return (
    <CustomMarker
      onClick={() => {
        onClickCircle({
          count,
          firstItem,
          bottomLeft,
          upperRight,
        });
      }}
      position={[lat, long]}
    >
      {renderInner}
    </CustomMarker>
  );
}
