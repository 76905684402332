export const useSwitchTabConfigs = () => {
  return [
    {
      key: 'ContentPermissionGroupDetail',
      value: 'Chi tiết nhóm quyền mẫu',
    },
    {
      key: 'EditPermissionGroupHistory',
      value: 'Lịch sử chỉnh sửa nhóm quyền mẫu',
    },
  ];
};
