import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  priceKey?: string,
  priceFrom?: number,
  priceTo?: number,
  setPriceKey?: Function,
  setPriceFrom?: Function,
  setPriceTo?: Function,
  isForSell?: boolean,
  onResetSelected?: Function,
};
export default function InputPriceDropdown({
  priceKey,
  priceFrom,
  priceTo,
  setPriceKey,
  setPriceFrom,
  setPriceTo,
  isForSell = true,
  onResetSelected,
}: Props) {
  const { townhouse } = configs;
  const { priceToBuy, priceToRent } = townhouse || {};
  const convertPriceToBuy =
    priceToBuy?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToRent =
    priceToRent?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 200.5 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const listPrice =
    isForSell === 'ForRent' || isForSell === 'ToRent'
      ? convertPriceToRent
      : convertPriceToBuy || [];
  return (
    <InputSelector
      dropdownButtonTitle={
        isForSell === 'ForRent' ? 'Giá thuê/ triệu' : 'Giá bán/ tỷ'
      }
      progress={isForSell === 'ForRent' || isForSell === 'ToRent' ? 100 : 25}
      sliderTitle={
        isForSell === 'ForRent' || isForSell === 'ToRent'
          ? 'Giá thuê (Triệu VNĐ)'
          : 'Giá bán (Tỷ VNĐ)'
      }
      key={Math.random() + priceFrom + priceTo}
      theValueIsShow={
        isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50
      }
      listSelections={listPrice}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setPriceKey(key);
        setPriceFrom(from);
        setPriceTo(to);
      }}
      keyValue={priceKey}
      from={priceFrom}
      to={priceTo}
      unit={isForSell === 'ForRent' || isForSell === 'ToRent' ? 'triệu' : 'tỷ'}
      min={0}
      max={isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50}
      step={isForSell === 'ForRent' || isForSell === 'ToRent' ? 0.5 : 1}
      additionalItems={[
        {
          key: '',
          from: 0,
          to: isForSell === 'ForRent' || isForSell === 'ToRent' ? 200.5 : 101,
          value:
            isForSell === 'ForRent' || isForSell === 'ToRent'
              ? 'Tất cả giá thuê'
              : 'Tất cả giá bán',
        },
      ]}
    />
  );
}
