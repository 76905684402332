import { useQuery } from '@apollo/client';
import {
  GET_NEED_REAL_ESTATE_DETAILS,
  GET_REAL_ESTATE_DETAILS,
} from '@utils/graphql';

export const useGetRealEstateDetail = (realEstateId, demand) => {
  const { data: dataDetail, loading } = useQuery(GET_REAL_ESTATE_DETAILS, {
    variables: {
      realEstateId: realEstateId,
    },
    skip: !realEstateId || demand === 'buy',
  });
  const { data: needDataDetail, needLoading } = useQuery(
    GET_NEED_REAL_ESTATE_DETAILS,
    {
      variables: {
        realEstateId: realEstateId,
      },
      skip: !realEstateId || demand === 'sell',
    }
  );
  const finalData =
    demand === 'sell'
      ? dataDetail?.getRealEstateDetails
      : needDataDetail?.getNeededRealEstateDetails;
  return { data: finalData, loading: loading || needLoading };
};
