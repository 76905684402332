import React from 'react';
import moment from 'moment/moment';

type Props = {
  data?: Object,
  createdAt?: string,
};

export default function OtherInfo({ data, createdAt }: Props) {
  return (
    <div className="rounded-lg border p-4">
      <div className={`text-blue-main text-14 font-medium`}>Thông tin khác</div>
      <div className="flex w-full mt-2.5 py-2 border-b border-gray-300 space-x-6">
        <div className="w-120px text-sm font-medium text-black-main">
          Người tạo
        </div>
        <div className="flex flex-row items-center space-x-1">
          <div
            className={`w-6 h-6 rounded-full overflow-hidden border border-blue-main ${
              !data?.avatar?.originalUrl ? 'bg-gray-200' : undefined
            }`}
          >
            {data?.avatar?.originalUrl && (
              <img
                alt="avt"
                src={data?.avatar?.originalUrl}
                className="flex-1"
              />
            )}
          </div>
          <div className="flex-1 text-sm text-black-main">{data?.fullName}</div>
        </div>
      </div>
      <div className="flex w-full mt-2.5 py-2 border-b border-gray-300 space-x-6">
        <div className="w-120px text-sm font-medium text-black-main">
          Thời gian tạo
        </div>
        <div style={{ color: '#80888F' }} className="flex-1 text-sm ">
          {moment(createdAt).format('hh:mm A, DD/MM/YYYY')}
        </div>
      </div>
    </div>
  );
}
