type Props = {
  isEdit?: boolean,
  mainEditHeaderStr?: string,
  mainNormalHeaderStr?: string,
  headerLevel1?: string,
  headerEditLevel2?: string,
  headerNormalLevel2?: string,
};

export default function CommonHeader({
  isEdit,
  mainEditHeaderStr,
  mainNormalHeaderStr,
  headerLevel1,
  headerEditLevel2,
  headerNormalLevel2,
}: Props) {
  return (
    <div className="w-full">
      <div style={{ fontSize: 18 }} className="font-medium text-black-main">
        {isEdit ? `${mainEditHeaderStr}` : `${mainNormalHeaderStr}`}
      </div>
      <div
        className="text-sm text-black-main mt-1"
        dangerouslySetInnerHTML={{
          __html: `<b class="font-medium">${headerLevel1}/ </b> ${
            isEdit ? `${headerEditLevel2}` : `${headerNormalLevel2}`
          }`,
        }}
      />
    </div>
  );
}
