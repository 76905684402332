import { includes, map } from 'lodash';
import React from 'react';

type Props = {
  modules: Array,
};

const Modules = ({ modules }: Props) => {
  const moduleOptions = [
    {
      name: 'Quản lý dự án',
      value: 'PROJECT',
      url: '/icons/modules/manager-project.svg',
    },
    {
      name: 'Quản lý nhà phố',
      value: 'REALESTATE',
      url: '/icons/modules/manager-product.svg',
    },
    {
      name: 'Quản lý dự án thuê',
      value: 'PROJECT_FOR_LEASE',
      url: '/icons/modules/manager-project.svg',
    },
    {
      name: 'Quản lý CRM',
      value: 'CRM',
      url: '/icons/modules/manager-crm.svg',
    },
    {
      name: 'Tổng đài thông minh',
      value: 'CALLCENTER',
      url: '/icons/modules/call-center.svg',
    },
    {
      name: 'Quản lý hồ sơ công chứng',
      value: 'NOTARIZATION',
      url: '/icons/modules/manager-notarization.svg',
    },
    {
      name: 'Quản lý vay vốn ngân hàng',
      value: 'LOAN',
      url: '/icons/modules/manager-loan.svg',
    },
    {
      name: 'Quản lý đăng ký đất đai',
      value: 'REGISTER',
      url: '/icons/modules/manager-register.svg',
    },
    {
      name: 'Quản lý yêu cầu mua tài sản phát mãi',
      value: 'FORECLOSURE_REQUEST',
      url: '/icons/modules/manager-foreclosure.svg',
    },
  ];
  return (
    <div className="py-7 px-4">
      <ul className="flex flex-wrap -mx-2 -my-3">
        {map(moduleOptions, (module, moduleIndex) =>
          includes(modules, module.value) ? (
            <li key={moduleIndex} className="px-2 py-3">
              <div className="relative min-h-87px h-full w-120px flex flex-col items-center justify-center space-y-4 rounded-md select-none">
                <img src={module.url} alt="icon" className="w-40px h-40px" />
                <span
                  className="text-center"
                  style={{ maxWidth: '100%', fontSize: 12 }}
                >
                  {module.name}
                </span>
              </div>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

export default Modules;
