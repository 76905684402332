import MyButton from '@components/button';
import ModalWrapper from '@components/modalwrapper/ModalWrapper';
import CreateContract from 'modals/createContractModal';
import { useState } from 'react';
import AdditionalContractModal from '../../additionalContractModal';

type Props = {
  listAttachment?: Array,
  contractDetail?: Object,
  contractId?: string,
  isLocked?: boolean,
  onSuccess?: Function,
  onCloseDetailModal?: Function,
  b2bUserData?: Object,
};
export default function RenderRightButton({
  listAttachment,
  contractDetail,
  contractId,
  isLocked,
  onSuccess,
  onCloseDetailModal,
  b2bUserData,
}: Props) {
  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  return (
    <div
      style={{
        borderRadius: 8,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
      }}
      className="w-240px h-100vh ml-4 bg-white rounded-lg px-4 py-7 "
    >
      <div className="space-y-4">
        <MyButton
          disabled={isLocked}
          onPress={() => setShowContractModal(true)}
          type="blue"
          label="Hủy - Nâng cấp hợp đồng"
        />
        <MyButton
          disabled={isLocked}
          onPress={() => setShowAdditionalModal(true)}
          type="gray"
          label="Bổ sung hợp đồng"
        />
      </div>
      <ModalWrapper
        isShowing={showAdditionalModal}
        onToggle={() => setShowAdditionalModal(false)}
      >
        <AdditionalContractModal
          listAttachment={listAttachment}
          note={contractDetail?.note}
          contractId={contractId}
          onClose={() => setShowAdditionalModal(false)}
        />
      </ModalWrapper>
      <ModalWrapper
        isShowing={showContractModal}
        onToggle={() => setShowContractModal(false)}
      >
        <CreateContract
          listAttachment={listAttachment}
          b2bUserData={b2bUserData}
          onCloseDetailModal={onCloseDetailModal}
          onSuccess={onSuccess}
          contractId={contractId}
          onClose={() => {
            setShowContractModal(false);
          }}
        />
      </ModalWrapper>
    </div>
  );
}
