import React from 'react';
import Section from './section';

type Props = {
  data?: Object,
};

export default function RealEstateTypeInProject({ data }: Props) {
  return (
    <Section
      source="/svg/soluongbdstrongduan.svg"
      title="Số lượng BĐS trong dự án"
      style={{ flex: 1 }}
      innerClass="mt-4"
    >
      <div className="grid grid-rows-5 grid-flow-col grid-cols-2 border-b border-gray-300">
        <Item
          title="Số lượng căn hộ"
          value={data?.apartmentCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng nhà phố liền kề"
          value={data?.townhouseCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng biệt thự song lập"
          value={data?.doubleVillaCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng biệt thự đơn lập"
          value={data?.independentVillaCount || '0'}
          isBorder={true}
        />
        <Item title="Số lượng Shophouse" value={data?.shopHouseCount || '0'} />
        <Item
          title="Số lượng Condotel"
          value={data?.condotelCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng Offictel"
          value={data?.offictelCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng Penthouse"
          value={data?.penthouseCount || '0'}
          isBorder={true}
        />
        <Item
          title="Số lượng khu thương mại"
          value={data?.commercialAreaCount || '0'}
          isBorder={true}
        />
      </div>
    </Section>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  valueStyle?: Object,
  hideField?: boolean,
  isBorder?: boolean,
};

function Item({
  title,
  value = '',
  valueStyle,
  hideField,
  isBorder,
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div
      className={`flex flex-row w-full justify-between py-3 ${
        isBorder ? 'border-b border-gray-300' : null
      }`}
    >
      <div className="flex space-x-10">
        <div className="flex items-center">
          <div className={`text-sm mr-6 font-medium text-black-main w-180px`}>
            {title}
          </div>
        </div>
        <div className={`text-sm text-right flex ${valueStyle}`}>{value}</div>
      </div>
    </div>
  );
}
