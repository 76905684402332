import { useGetPermissionGroupDetails } from '@utils/hooks/manageOrganization/useGetPermissionGroupDetail';
import { useGetPermissionGroups } from '@utils/hooks/manageOrganization/useGetPermissionGroups';
import queryString from 'query-string';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDeepCompareEffect, useList } from 'react-use';
import { blankState } from '../configs';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import Submit from '../submit';
import BottomLeftPermissionDetail from './bottomLeftPermissionDetail';
import BottomRightPermissionDetail from './bottomRightPermissionSettings';
import TopAddPermissionGroup from './topAddPermissionGroup';

function CreatePermissionGroup() {
  const navigate = useNavigate();
  const permissionGroupInitialValue = useRef({});
  const { isEdit, id } = queryString.parse(location.search);
  const { stateFilter } = usePermissionGroupsContext();
  const { permissionGroups } = useGetPermissionGroups({
    state: { ...stateFilter, limit: 500 },
  });
  const { data: permissionGroupDetail } = useGetPermissionGroupDetails(id);
  const [assignedPermissions, setAssignedPermissions] = useState([
    ...(permissionGroupDetail?.permissions.map(permission => permission.id) ??
      []),
  ]);
  const [permissions, setPermissions] = useList(
    isEdit ? assignedPermissions?.map(p => p.id) : []
  );
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });

  useDeepCompareEffect(() => {
    if (isEdit && permissionGroupDetail) {
      const remoteState = {
        name: permissionGroupDetail?.name ?? '',
        id: permissionGroupDetail?.id ?? '',
      };
      form.reset({ ...remoteState });
      permissionGroupInitialValue.current = remoteState;
    }
  }, [permissionGroupDetail]);

  return (
    <FormProvider {...form}>
      <div className="w-full min-h-screen pl-4">
        <div className="w-full h-full bg-gray-f4f">
          <div>
            <TopAddPermissionGroup
              permissionGroups={permissionGroups}
              setAssignedPermissions={setAssignedPermissions}
            />
            <div className="flex py-5 px-4 gap-x-4">
              <BottomLeftPermissionDetail
                permissions={permissions}
                setPermissions={setPermissions}
                assignedPermissions={assignedPermissions}
              />
              <BottomRightPermissionDetail
                isEdit={isEdit}
                permissions={permissions}
                assignedPermissions={assignedPermissions}
              />
            </div>
          </div>
        </div>
        <Submit
          onBack={() => {
            navigate('/manageOrganization/permission-groups');
          }}
          idGroupPermission={id}
          isUpdate={isEdit}
          form={form}
          permissions={permissions}
        />
      </div>
    </FormProvider>
  );
}

export default CreatePermissionGroup;
