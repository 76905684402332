import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import React from 'react';

type Props = {
  title?: string,
  titleStyle?: Object,
  avatarUrl?: string,
  username?: string,
  imgStyle?: string,
};
export default function UserAvatar(props: Props) {
  const { title = 'Ảnh đại diện', titleStyle, avatarUrl, imgStyle } = props;
  const toast = useToast();
  return (
    <div
      style={{ borderColor: '#B4B8BC' }}
      className="w-250px md:w-323px h-fit rounded-lg border p-4 space-y-2.5"
    >
      <div className="flex flex-row justify-between">
        <div
          style={titleStyle}
          className={`text-blue-main text-14 font-medium`}
        >
          {title}
        </div>
        <div
          onClick={() => {
            toast({
              position: 'bottom-right',
              duration: 5000,
              render: ({ id, onClose }) => (
                <MyToast
                  id={id}
                  onClose={onClose}
                  type="warning"
                  message="Chức năng đang được hoàn thiện"
                  closeButtonPos="center"
                />
              ),
            });
          }}
          className="flex flex-row space-x-1 items-center cursor-pointer"
        >
          <img
            src="/svg/message-icon.svg"
            alt="Send message"
            className="w-4 h-4"
          />
          <div style={{ fontSize: 13 }} className="text-blue-main underline">
            Nhắn tin
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <div
          className={`w-120px md:w-160px h-120px md:h-160px rounded-full overflow-hidden border border-gray-300 ${
            !avatarUrl ? 'bg-white' : undefined
          }`}
        >
          {avatarUrl ? (
            <img alt="avt" src={avatarUrl} className={`${imgStyle}flex-1`} />
          ) : (
            <img alt="avt" className="w-40 h-40" src="/svg/male.svg" />
          )}
        </div>
      </div>
      {props?.username && (
        <div className="flex w-full mt-2.5 py-2 space-x-6 border-b border-gray-300">
          <div className="w-120px text-sm font-medium text-black-main">
            Tên đăng nhập
          </div>
          <div className="flex justify-end flex-1 text-14">
            {props?.username}
          </div>
        </div>
      )}
    </div>
  );
}
