import moment from 'moment';
import React, { useState } from 'react';
import { getNameUrlFromSever } from '@utils/helper';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import { isEmpty } from 'lodash';
import ViewFileModal from '../../../../../../../../modals/viewFileModal/viewFileModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';
import FileSaver from 'file-saver';
import { useGetUserBasicInfo } from '@utils/hooks/common/useGetUserBasicInfo';
import ModalWrapper from '@components/modalwrapper/ModalWrapper';

type Props = {
  attachments?: string,
  creator?: string,
  avatar?: string,
  fullName?: string,
};
const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
function RenderHeader() {
  return (
    <div
      className="w-full h-50px flex flex-row items-center overflow-x-auto"
      style={{ backgroundColor: '#EDF4FF' }}
    >
      <div className="flex w-60px h-full items-center justify-center text-black-main text-14 font-medium">
        STT
      </div>
      <div className={`min-w-200px flex-1 ${headerClass}`}>Tên tài liệu</div>
      <div className={`flex-1 min-w-0 ${headerClass}`}>Người tải lên</div>
      <div className={`w-175px ${headerClass}`}>Thời gian</div>
    </div>
  );
}
type ItemRowProps = {
  index?: number,
  item?: Object,
  creator?: string,
  avatar?: string,
  fullName?: string,
};
function ItemRow({ item, index, creator }: ItemRowProps) {
  const { userInfo } = useGetUserBasicInfo(item?.creator);
  const [showModal, setShowModal] = useState(false);
  const fileId = item?.fileId;
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId,
    },
    skip: !fileId,
  });
  const { originalUrl, type } = data?.readMediaUrls || {};
  return (
    <div className="flex-1 h-50px flex flex-row items-center">
      <div className="flex w-60px h-full items-center justify-center text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => {
          switch (type) {
            case 'sheet':
              FileSaver.saveAs(
                originalUrl,
                getNameUrlFromSever(item?.originalUrl)
              );
              break;
            case 'doc':
              FileSaver.saveAs(
                originalUrl,
                getNameUrlFromSever(item?.originalUrl)
              );
              break;
            default:
              setShowModal(true);
              break;
          }
        }}
        className={`min-w-200px flex-1 overflow-hidden flex flex-row items-center space-x-1 cursor-pointer ${headerClass}`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="flex-1 text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(item?.originalUrl)}
        </div>
      </div>
      <div
        className={`flex-1  min-w-0 ${headerClass} ${isEmpty(item?.creator) && !creator ? 'pointer-events-none' : undefined
          }`}
      >
        <MyDropdown
          zIndex={1500}
          wrapperClass="w-full"
          DropdownComponent={UserInfo}
          dropdownContext={{
            userId: item?.creator,
            isB2B: true,
          }}
        >
          <div className="flex-1 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2.5 overflow-hidden">
              <div
                className={`w-6 h-6 rounded-full overflow-hidden ${!item?.creator?.avatar?.originalUrl
                  ? 'bg-gray-200'
                  : undefined
                  }`}
              >
                {userInfo?.avatar?.originalUrl ? (
                  <img
                    alt="avt"
                    src={userInfo?.avatar?.originalUrl}
                    className="flex-1"
                  />
                ) : (
                  <img
                    alt="avt"
                    src="/svg/male.svg"
                    className="flex-1 bg-white"
                  />
                )}
              </div>
              <div
                className={`flex-1 text-14 font-normal truncate ${userInfo?.fullName
                  ? 'underline cursor-pointer text-blue-main'
                  : 'italic opacity-50'
                  }`}
              >
                {userInfo?.fullName ? userInfo?.fullName : 'Không có'}
              </div>
            </div>
            {userInfo?.fullName && (
              <img
                alt="open icon"
                src="/svg/eye.svg"
                className="cursor-pointer"
              />
            )}
          </div>
        </MyDropdown>
      </div>
      <div className={`w-175px ${headerClass}`}>
        <div className="flex-1 text-sm text-black-main font-normal">
          {moment(item?.createdAt).format('HH:mmA DD/MM/YYYY')}
        </div>
      </div>
      <ModalWrapper isShowing={showModal} onToggle={() => setShowModal(false)}>
        <ViewFileModal
          fileId={item?.fileId}
          onClose={() => setShowModal(false)}
        />
      </ModalWrapper>
    </div>
  );
}

export default function AttachmentsTable(props: Props) {
  const { attachments } = props;
  return (
    <div className="w-full rounded border border-gray-300 divide-y divide-gray-300 overflow-x-auto">
      <RenderHeader />
      {attachments?.map((item, index) => {
        return <ItemRow item={item} key={index} index={index} />;
      })}
    </div>
  );
}
