import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  widthKey?: string,
  widthFrom?: number,
  widthTo?: number,
  setWidthKey?: Function,
  setWidthFrom?: Function,
  setWidthTo?: Function,
  onResetSelected?: Function,
};
export default function InputWidthDropdown({
  widthKey,
  widthFrom,
  widthTo,
  setWidthKey,
  setWidthFrom,
  setWidthTo,
  onResetSelected,
}: Props) {
  const { townhouse } = configs;
  const { width } = townhouse || {};
  const convertWidth =
    width?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle="Ngang (m)"
      sliderTitle={'Ngang (m)'}
      key={Math.random() + widthFrom + widthTo}
      listSelections={convertWidth || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setWidthKey(key);
        setWidthFrom(from);
        setWidthTo(to);
      }}
      keyValue={widthKey}
      from={widthFrom}
      to={widthTo}
      unit={'m'}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 101, value: 'Tất cả' }]}
    />
  );
}
