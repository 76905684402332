import gql from 'graphql-tag';

export const GET_EWALLET = gql`
  query getMyEwalletInfo @api(name: ewallet) {
    getMyEwalletInfo {
      id
      ownerId
      balance
      totalDeposit
      totalWithdraw
      totalCommission
      totalReward
      rewardBalance
      commissionBalance
      totalUsedReward
    }
  }
`;
