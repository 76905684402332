import { useMutation } from '@apollo/client';
import {
  ADD_MORE_ATTACHMENTS_FOR_PRODUCT,
  DELETE_ATTACHMENTS_OF_PRODUCT,
  UPDATE_REAL_ESTATE_PRODUCT,
} from '@utils/graphql';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import MyToast from '@components/MyToast';
import { useToast } from '@chakra-ui/react';
import { useProductContext } from '../../../scenes/home/manageProduct/product/context';
import _ from 'lodash';

export const useUpdateAttachment = (
  realEstateId,
  listAttachmentDeleted,
  close,
  data
) => {
  const toast = useToast();
  const [updateFileProduct, { client, loading }] = useMutation(
      UPDATE_REAL_ESTATE_PRODUCT
    ),
    [addMoreAttachmentsProduct] = useMutation(ADD_MORE_ATTACHMENTS_FOR_PRODUCT),
    [deleteAttachmentsProduct] = useMutation(DELETE_ATTACHMENTS_OF_PRODUCT);
  const { setListSelected } = useProductContext();
  const onUpdateRealEstateProduct = useCallback(
    async state => {
      const newMedia = state?.mediaUrls?.map(e => e.fileId);
      try {
        if (!_.isEqual(newMedia, data?.mediaUrls)) {
          await updateFileProduct({
            variables: {
              realEstateId,
              mediaUrls: state?.mediaUrls?.map(e => e.fileId),
            },
          });
        }
        if (state?.attachments?.length > 0) {
          await addMoreAttachmentsProduct({
            variables: {
              realEstateId,
              attachments: state?.attachments?.map(e => e.fileId),
            },
          });
        }
        if (listAttachmentDeleted?.length > 0) {
          await deleteAttachmentsProduct({
            variables: {
              realEstateId,
              attachments: listAttachmentDeleted,
            },
          });
        }
        setListSelected.clear();
        resetQueries(
          [
            'getInternalRealEstates',
            'getInternalRealEstateDetails',
            'getProductAttachments',
            'getRealEstateHistories',
          ],
          client.cache
        );
        close();
        toast({
          position: 'bottom-right',
          duration: 5000,
          render: ({ id, onClose }) => (
            <MyToast
              id={id}
              onClose={onClose}
              type="success"
              message="Bổ sung tài liệu thành công"
              closeButtonPos="center"
            />
          ),
        });
      } catch (e) {
        toast({
          position: 'bottom-right',
          duration: 5000,
          render: ({ id, onClose }) => (
            <MyToast
              id={id}
              onClose={onClose}
              type="error"
              message={e?.message}
              closeButtonPos="center"
            />
          ),
        });
      }
    },
    [
      updateFileProduct,
      addMoreAttachmentsProduct,
      deleteAttachmentsProduct,
      realEstateId,
      listAttachmentDeleted,
      close,
    ]
  );
  return { onUpdateRealEstateProduct, loading };
};
