import MyInput from '@components/myInput';
import Permission from '@gugotech/tncc-users-permissions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
import { nonAccentVietnamese } from '@utils/helper';
import {
  filter,
  forEach,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
} from 'lodash';
import { useState } from 'react';

type Props = {
  permissions?: Array,
};

export default function PermissionDetail({ permissions }: Props) {
  const selectedPermissionIds = map(permissions, permission => permission?.id);
  const permissionList = Permission.getPermissionTree('all');
  const [search, setSearch] = useState([]);

  const isLeaf = node => {
    return isEmpty(node?.children) || isNil(node?.children);
  };
  const findAllLeafNodes = permission => {
    let leafNodes = [];
    const traverse = node => {
      if (isLeaf(node)) {
        leafNodes.push(node);
        return;
      }
      forEach(node?.children, child => traverse(child));
    };
    traverse(permission);
    return leafNodes;
  };
  const handleSearch = event => {
    setSearch(event.target.value);
  };
  const handleClearSearch = () => {
    setSearch('');
  };

  const renderPermissionTree = (permissions, prefix) =>
    map(permissions, (permission, permissionIndex) => {
      const allLeafNodes = findAllLeafNodes(permission);
      const filteredPermissionWithSelectedIds = filter(
        allLeafNodes,
        allLeafNode => includes(selectedPermissionIds, allLeafNode?.id)
      );
      const filteredPermissionWithSearchString = filter(
        filteredPermissionWithSelectedIds,
        allLeafNode =>
          includes(
            nonAccentVietnamese(allLeafNode?.name),
            nonAccentVietnamese(search)
          ) && includes(selectedPermissionIds, allLeafNode?.id)
      );
      return !isLeaf(permission) ? (
        <Accordion
          key={permissionIndex}
          sx={{
            display: isEqual(size(filteredPermissionWithSearchString), 0)
              ? 'none'
              : 'block',
            marginBottom: '16px',
            '& .MuiAccordionSummary-root': {
              minHeight: '36px !important',
              maxHeight: 36,
              backgroundColor: '#EDF4FF',
              overflow: 'hidden',
            },
            '& .MuiAccordionDetails-root': {
              marginTop: '8px',
              padding: 0,
            },
            '&.MuiAccordion-root:before': {
              display: 'none',
            },
          }}
        >
          <div className="flex items-center space-x-2">
            <AccordionSummary
              expandIcon={<img alt="icon" src="/svg/arrowBlack.svg" />}
              sx={{ flex: 1, borderRadius: '6px' }}
            >
              <span className="text-14 truncate" style={{ lineHeight: 18 }}>
                {`${prefix ? `${prefix}.` : ''}${permissionIndex + 1}. ${
                  permission?.name ?? ''
                } (${size(filteredPermissionWithSelectedIds)} quyền)`}
              </span>
            </AccordionSummary>
          </div>
          <AccordionDetails
            sx={{
              paddingLeft: '8px',
            }}
          >
            <div className="pl-4 mt-4">
              {renderPermissionTree(
                permission?.children,
                prefix
                  ? `${prefix}.${permissionIndex + 1}`
                  : permissionIndex + 1
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : includes(
          nonAccentVietnamese(permission?.name),
          nonAccentVietnamese(search)
        ) && includes(selectedPermissionIds, permission?.id) ? (
        <li
          key={permissionIndex}
          className="flex h-52px items-center -mt-2"
          style={{
            borderLeft: '1px solid #dedede',
          }}
        >
          <div className="h-1px w-17px" style={{ background: '#dedede' }} />
          <div className="ml-10px px-4 flex-1 min-w-0">
            <div className="overflow-hidden">
              <Tooltip title={permission?.tips} arrow>
                <span className="text-14 truncate w-full">
                  {`${prefix}.${permissionIndex + 1} ${permission?.name ?? ''}`}
                </span>
              </Tooltip>
            </div>
          </div>
        </li>
      ) : null;
    });

  return !isEqual(size(permissions), 0) ? (
    <div className="w-full bg-white rounded-lg flex flex-col p-4 space-y-7">
      <div className="text-16 font-medium">Phân quyền</div>
      <MyInput
        value={search}
        placeholder="Nhập để tìm kiếm quyền..."
        onChange={handleSearch}
        onDelete={handleClearSearch}
        renderRightComponent={() => {
          return (
            <div className="w-6 h-6 flex items-center justify-center border-l border-gray-300">
              <img
                alt="search-icon"
                src="/svg/search-icon.svg"
                className="ml-2"
              />
            </div>
          );
        }}
      />
      <div className="flex flex-col space-y-4">
        <div>{renderPermissionTree(permissionList)}</div>
      </div>
    </div>
  ) : (
    <div className="p-4">
      <span className="text-gray-400 text-14 italic">Không có quyền nào</span>
    </div>
  );
}
