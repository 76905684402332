import * as React from 'react';
import { createPortal } from 'react-dom';
import { DivIcon, marker } from 'leaflet';
import * as RL from 'react-leaflet';
import { MapLayer } from 'react-leaflet';
import { difference } from 'lodash';
import { animateMarker, areCoordinatesDistantEnough } from './utils';

type Props = {
  position?: any,
};

export const CustomMarker = RL.withLeaflet(
  class extends MapLayer<any> {
    leafletElement: any;
    contextValue: any;

    createLeafletElement(props: Props) {
      const { position, ...rest } = props;

      // when not providing className, the element's background is a white square
      // when not providing iconSize, the element will be 12x12 pixels
      const icon = new DivIcon({ ...rest, className: '', iconSize: undefined });

      const el = marker(position, { icon: icon, ...rest });
      this.contextValue = { ...props.leaflet, popupContainer: el };
      return el;
    }

    updateLeafletElement(fromProps: any, toProps: any) {
      const {
        position: fromPosition,
        zIndexOffset: fromZIndexOffset,
        opacity: fromOpacity,
        draggable: fromDraggable,
        className: fromClassName,
      } = fromProps;
      const {
        position: toPosition,
        zIndexOffset: toZIndexOffset,
        toOpacity,
        draggable: toDraggable,
        className: toClassName,
        moveAnimation,
      } = toProps;
      if (toZIndexOffset !== fromZIndexOffset) {
        this.leafletElement.setZIndexOffset(toZIndexOffset);
      }
      if (toOpacity !== fromOpacity) {
        this.leafletElement.setOpacity(toOpacity);
      }
      if (toDraggable !== fromDraggable) {
        if (toDraggable) {
          this.leafletElement.dragging.enable();
        } else {
          this.leafletElement.dragging.disable();
        }
      }
      if (toClassName !== fromClassName) {
        const fromClasses = fromClassName.split(' ');
        const toClasses = toClassName.split(' ');
        this.leafletElement._icon.classList.remove(
          ...difference(fromClasses, toClasses)
        );
        this.leafletElement._icon.classList.add(
          ...difference(toClasses, fromClasses)
        );
      }

      if (toPosition !== fromPosition) {
        if (
          fromPosition &&
          toPosition &&
          moveAnimation &&
          areCoordinatesDistantEnough(toPosition, fromPosition)
        ) {
          animateMarker(this.leafletElement, fromPosition, toPosition);
        }
        this.leafletElement.setLatLng(toPosition);
      }
    }

    componentWillMount() {
      if (super.componentWillMount) {
        super.componentWillMount();
      }
      this.leafletElement = this.createLeafletElement(this.props);
      this.leafletElement.on('add', () => this.forceUpdate());
    }

    componentDidUpdate(fromProps: any) {
      this.updateLeafletElement(fromProps, this.props);
    }

    render() {
      const { children } = this.props;
      const container = this.leafletElement._icon;

      if (!container) {
        return null;
      }

      const portal = createPortal(children, container);

      const LeafletProvider = RL.LeafletProvider;

      return children == null ||
        portal == null ||
        this.contextValue == null ? null : (
        <LeafletProvider value={this.contextValue}>{portal}</LeafletProvider>
      );
    }
  }
);
