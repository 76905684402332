import MyButton from '@components/button';
import styles from './confirmLockUnlockModal.module.scss';
type Props = {
  onClose?: Function,
  onConfirm?: Function,
  header?: string,
  question?: string,
  content?: string,
  loading?: boolean,
};

export default function ConfirmLockUnlockAccount({
  onClose,
  header,
  question,
  content,
  loading,
  onConfirm,
}: Props) {
  return (
    <div className={styles.confirmLockUnlockModal}>
      <div className="text-16 font-medium text-blue-main">{header}</div>
      <div className="mt-3 text-sm font-medium">{question}</div>
      <div className="mt-3 text-sm">{content}</div>
      <div className="flex w-full justify-end space-x-2 mt-6">
        <div className="w-140px">
          <MyButton label="Trở về" type="gray" onPress={onClose} />
        </div>
        <div className="w-140px">
          <MyButton
            disabled={loading}
            type="blue"
            label={loading ? 'Đang xử lý' : 'Xác nhận'}
            wrapperClass="w-140px"
            onPress={onConfirm}
          />
        </div>
      </div>
    </div>
  );
}
