import gql from 'graphql-tag';

export const APPROVE_REAL_ESTATE_PRODUCT = gql`
  mutation approveRealEstateProduct(
    $realEstateIds: [ObjectID]!
    $type: ApproveRealEstateEnumType!
    $status: RealEstateStatusEnumType!
    $reason: String
  ) @api(name: realEstate) {
    approveRealEstateProduct(
      realEstateIds: $realEstateIds
      type: $type
      status: $status
      reason: $reason
    ) {
      msg
      success
    }
  }
`;
export const DELETE_LIST_REAL_ESTATES = gql`
  mutation deleteListRealEstates($realEstateIds: [ObjectID!])
  @api(name: realEstate) {
    deleteListRealEstates(realEstateIds: $realEstateIds) {
      msg
      success
    }
  }
`;

export const UPDATE_REAL_ESTATES = gql`
  mutation updateRealEstate(
    $realEstateId: ObjectID!
    $editOnly: Boolean
    $data: RealEstateUpdateInput!
    $captchaId: String!
    $captchaCode: String!
  ) @api(name: realEstate) {
    updateRealEstate(
      realEstateId: $realEstateId
      editOnly: $editOnly
      data: $data
      captchaId: $captchaId
      captchaCode: $captchaCode
    ) {
      id
    }
  }
`;
