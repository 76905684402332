import MyDropdown from '@components/myDropdown';
import React from 'react';

type Props = {
  modalEditDisplay?: Function,
};

export default function OptionSelections({ modalEditDisplay }: Props) {
  return (
    <div className="flex">
      <MyDropdown
        DropdownComponent={Selections}
        dropdownContext={{ modalEditDisplay }}
        buttonDropdownClassName={`w-149px p-2 rounded-lg border border-blue-main`}
      >
        {showDropdown => {
          return (
            <div className="flex flex-row items-center justify-between">
              <span className="text-13 font-medium text-left">
                Thao tác khác
              </span>
              <img
                src="/svg/arrowDown.svg"
                alt="arrow"
                className={`w-12px h-10px ${
                  showDropdown
                    ? 'transform rotate-180 duration-300'
                    : 'duration-300'
                }`}
              />
            </div>
          );
        }}
      </MyDropdown>
    </div>
  );
}

type SelectionProps = {
  context?: {
    modalEditDisplay?: Function,
  },
  close?: Function,
};

function Selections(props: SelectionProps) {
  const { context, close } = props;
  const { modalEditDisplay } = context;
  const buttonClass =
    'text-13 font-medium text-left py-0.5 hover:bg-blue-200 px-4';
  return (
    <div
      className="w-full bg-white rounded-lg space-y-2 flex flex-col"
      style={{
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
        padding: '15px 0',
      }}
    >
      <button className={buttonClass}>
        <span>Nhập từ Excel</span>
      </button>
      <button
        onClick={() => {
          modalEditDisplay();
          close();
        }}
        className={buttonClass}
      >
        <span>Chỉnh sửa hiển thị</span>
      </button>
    </div>
  );
}
