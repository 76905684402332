import styles from '../reslEstateOnMap.module.scss';
import { useQuery } from '@apollo/client';
import { GET_INTERNAL_REAL_ESTATES } from '@utils/graphql';
import React, { useMemo, useState } from 'react';
import pickBy from 'lodash/pickBy';
import { useLoadMore } from '@utils/hooks/common/useLoadMore';
import { convertValueSort } from '@utils/helper';
import RealEstateForMap from '@components/itemRealEstate/realEstateForMap';
import RealEstateLoading from '@components/itemRealEstate/realEstateLoading';
import FilterListRealEstateOnMap from '@components/realEstateOnMap/components/filterListRealEstateOnMap';

type Props = {
  coordinatesRealEstate?: Object,
  onClose?: Function,
  variablesFilterMap?: Object,
  isFullScreen?: boolean,
};

export default function PopUpRealEstate(props: Props) {
  const {
      coordinatesRealEstate = {},
      variablesFilterMap,
      onClose = () => {},
      isFullScreen,
    } = props,
    { bottomLeft, upperRight } = coordinatesRealEstate,
    [isLoadingMore, setIsLoadingMore] = useState(false);

  const [sort] = useState('createdAt-1');

  const finalSort = {
    ...convertValueSort(sort),
    approvalStatus: 1,
    postStatus: 1,
  };

  const [demand, setDemand] = useState('sale');
  const [type, setType] = useState(['townhouse']);

  const finalVariables = useMemo(() => {
    return {
      bottomLeft: {
        coordinates: [bottomLeft.coordinates?.[0], bottomLeft.coordinates?.[1]],
      },
      upperRight: {
        coordinates: [upperRight.coordinates?.[0], upperRight.coordinates?.[1]],
      },
      data: {
        typeOfDemand: demand,
        type: type,
      },
      sort: '-approvedAt',
      limit: 20,
    };
  }, [finalSort, upperRight, bottomLeft]);

  const arrLoading = new Array(8).fill('loading');

  const {
    data: dataSearchList,
    loading,
    fetchMore,
  } = useQuery(GET_INTERNAL_REAL_ESTATES, {
    variables: {
      ...variablesFilterMap,
      ...pickBy(finalVariables, e => e),
    },
  });

  const data = dataSearchList?.getInternalRealEstates?.items || [],
    hasNextPage = dataSearchList?.getInternalRealEstates?.hasNextPage,
    page = dataSearchList?.getInternalRealEstates?.page;

  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    hasNextPage,
    currentPage: page,
  });

  return (
    <div
      className={`${styles.wrapper} w-438px absolute overflow-hidden flex flex-col pb-6 ${styles.popup}`}
      style={{ left: 16, bottom: 24 }}
    >
      <p className="text-16 font-textBold text-black-main font-bold m-4 text-center">
        Danh sách bất động sản
      </p>
      <div className="px-6 pt-4 pb-2">
        <div className="w-full flex-row items-center flex justify-between border-b border-gray-200 pb-7">
          <FilterListRealEstateOnMap
            demand={demand}
            setDemand={setDemand}
            type={type}
            setType={setType}
            container={isFullScreen && document.getElementById('map-container')}
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-auto pt-6">
        <div className="w-full grid grid-cols-2 gap-6 px-6">
          {data?.map(item => {
            return <RealEstateForMap item={item} key={item.id} />;
          })}
          {(loading || isLoadingMore) &&
            arrLoading.map((_, index) => {
              return (
                <RealEstateLoading
                  topClass={styles.topItemLoading}
                  bottomClass={styles.bottomItemLoading}
                  key={`loading-${index}`}
                />
              );
            })}
        </div>
        {hasNextPage && (
          <div className="hidden lg:flex flex-col w-full items-center mt-24px">
            <button
              onClick={onLoadMore}
              disabled={loading || isLoadingMore}
              className={`flex flex-row items-center text-14 font-text text-blue-main space-x-1 ${
                (loading || isLoadingMore) && 'opacity-50 cursor-not-allowed'
              }`}
            >
              Xem thêm
              <img
                src="/svg/arrowDownBlue.svg"
                className="w-16px h-16px"
                alt="icon"
              />
            </button>
          </div>
        )}
      </div>
      <button className="absolute right-4" onClick={onClose}>
        <img
          src="/svg/closeIcon.svg"
          className="w-28px h-28px"
          onClick={onClose}
          alt="icon"
        />
      </button>
    </div>
  );
}
