import MyButton from '@components/button';
import { useCreateB2BContract } from '@utils/hooks/manageContract/useCreateB2BContract';
import { useUpgradeB2BContract } from '@utils/hooks/manageContract/useUpgradeB2BContract';
import { useFormContext } from 'react-hook-form';

type Props = {
  onClose?: Function,
  idAdmin?: String,
  onSuccess?: Function,
  tab?: String,
  isUpgrade?: Boolean,
  contractId?: String,
  onCloseUpgrade?: Function,
  onCloseDetailModal?: Function,
};
export default function Submit({
  onClose,
  idAdmin,
  onSuccess,
  tab,
  isUpgrade,
  contractId,
  onCloseUpgrade,
  onCloseDetailModal,
}: Props) {
  const { handleSubmit } = useFormContext();
  const { onSubmit, loading } = useCreateB2BContract(
    idAdmin,
    onClose,
    onSuccess
  );
  const { onSubmit: onUpgradeSubmit, loading: loadingUpgrade } =
    useUpgradeB2BContract(
      onSuccess,
      tab,
      contractId,
      onCloseUpgrade,
      onCloseDetailModal
    );
  return (
    <div className="w-full bg-white h-61px flex flex-row mt-5 items-center justify-end p-x-6 space-x-2 sticky bottom-0 border-t border-gray-300">
      <div className="w-147px">
        <MyButton
          disabled={loading}
          onPress={onClose}
          label="Trở về"
          type="gray"
        />
      </div>
      <div className="w-147px">
        <MyButton
          disabled={isUpgrade ? loadingUpgrade : loading}
          onPress={handleSubmit(isUpgrade ? onUpgradeSubmit : onSubmit)}
          label="Xác nhận"
          type="blue"
        />
      </div>
    </div>
  );
}
