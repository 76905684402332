import React, { useCallback } from 'react';
import { useChangeRealEstateProductStatus } from '@utils/hooks/manageProduct/useChangeRealEstateProductStatus';
import { useProductContext } from '../scenes/home/manageProduct/product/context';

type Props = {
  context?: Object,
};
export default function ChangeStatusPanel(props: Props) {
  const { id, status, currentStatus, setShowChangeStatusModal } =
    props?.context;
  const { productStatus, setProductStatus, setCurrentId } = useProductContext();
  const { onSubmit } = useChangeRealEstateProductStatus(
    id,
    setShowChangeStatusModal
  );
  const handleChangeStatus = useCallback(
    value => {
      if (['sold', 'waitingForDeposit'].includes(value)) {
        setProductStatus(value);
        setCurrentId(id);
        setShowChangeStatusModal(true);
      } else {
        onSubmit(value);
      }
    },
    [onSubmit, productStatus, setShowChangeStatusModal, setProductStatus]
  );
  return (
    <div className="p-4 rounded-lg bg-white shadow-lg">
      <div className="text-sm font-bold text-blue-main">Chuyển trạng thái</div>
      <div className="w-full mt-3 flex flex-col text-sm">
        {status?.map((item, key) => {
          return (
            <span
              onClick={() => {
                if (item.key !== currentStatus) {
                  handleChangeStatus(item.key);
                }
              }}
              className={`cursor-pointer hover:bg-blue-100 p-2 flex-1 flex flex-row ${
                currentStatus === item?.key ? 'bg-green-100' : ''
              }`}
              key={key}
            >
              {item?.value}
            </span>
          );
        })}
      </div>
    </div>
  );
}
