import { useQuery } from '@apollo/client';
import { GET_INFO_DETAIL_CONTRACT } from '@utils/graphql';

export const useGetContractDetail = ({ id }) => {
  const { data, loading } = useQuery(GET_INFO_DETAIL_CONTRACT, {
    variables: {
      id,
    },
    skip: !id,
  });

  const contractDetail = data?.getContractDetail;
  return { data, loading, contractDetail };
};
