import React, { useState } from 'react';
import Section from './section';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import { useGetContactPhones } from '@utils/hooks/manageProduct/useGetContactPhones';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type Props = {
  data?: Object,
};

export default function ContactInfo({ data }: Props) {
  const [showPhone, setShowPhone] = useState(false);
  const { getPhones, phoneNumber, error } = useGetContactPhones(
    data?.id,
    showPhone,
    setShowPhone
  );
  const { permissions } = useGetMe();
  return (
    <Section title="Thông tin chủ nhà" source="/svg/ph_address-book.svg">
      <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Họ tên
        </div>
        <div className="flex-1 text-sm ">{data?.contactName}</div>
      </div>
      {permissions?.some(p => p.id === PermissionIds.ViewOwnerPhoneNumber) && (
        <div className="border-b">
          {phoneNumber?.length === 0 && !showPhone && !error && (
            <div className="flex w-full mt-1 py-2 items-center space-x-8">
              <div className="w-120px text-sm font-medium text-black-main">
                Số điện thoại
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex-1 text-sm">*************</div>
                <img
                  className="cursor-pointer"
                  onClick={getPhones}
                  alt="ico"
                  src={'/svg/eye-close-icon.svg'}
                />
              </div>
            </div>
          )}
          {error ? (
            <div className="flex w-full mt-1 py-2 items-center space-x-8">
              <div className="w-120px text-sm font-medium text-black-main">
                Số điện thoại
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-red-400">
                  Bạn đã hết lượt xem SĐT trong ngày
                </span>
              </div>
            </div>
          ) : (
            phoneNumber?.length !== 0 &&
            phoneNumber?.map((item, index) => {
              return <Phone phone={item} index={index} key={index} />;
            })
          )}
        </div>
      )}
      {data?.contactEmail && (
        <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
          <div className="w-120px text-sm font-medium text-black-main">
            Email
          </div>
          <div className="flex-1 text-sm text-black-main">
            {data?.contactEmail}
          </div>
        </div>
      )}
      {data?.career && (
        <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
          <div className="w-120px text-sm font-medium text-black-main">
            Ngành nghề
          </div>
          <div className="flex-1 text-sm text-black-main">{data?.career}</div>
        </div>
      )}
    </Section>
  );
}

type PhoneProps = {
  index?: string,
  phone?: string,
};

function Phone({ index, phone }: PhoneProps) {
  const toast = useToast();
  return (
    <div>
      <div className="flex w-full mt-1 py-2 items-center space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Số điện thoại {index + 1}
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex-1 text-sm">{phone}</div>
          <div
            className="cursor-pointer"
            onClick={async () => {
              await navigator.clipboard.writeText(phone);
              await toast({
                position: 'bottom-right',
                duration: 5000,
                render: ({ id, onClose }) => (
                  <MyToast
                    id={id}
                    onClose={onClose}
                    wrapperStyle="w-394px shadow-md"
                    type="success"
                    message="Sao chép số điện thoại thành công"
                    closeButtonPos="center"
                  />
                ),
              });
            }}
          >
            <img alt="ico" src={'/svg/copy.svg'} className="w-6 h-6" />
          </div>
        </div>
      </div>
    </div>
  );
}
