import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  areaKey?: string,
  areaFrom?: number,
  areaTo?: number,
  setAreaKey?: Function,
  setAreaFrom?: Function,
  setAreaTo?: Function,
  onResetSelected?: Function,
  title?: string,
};
export default function InputAreaDropdown({
  areaKey,
  areaFrom,
  areaTo,
  setAreaKey,
  setAreaFrom,
  setAreaTo,
  onResetSelected,
  title = 'Diện tích (m²)',
}: Props) {
  const { townhouse } = configs;
  const { area } = townhouse || {};
  const convertArea =
    area?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 20001 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle={title}
      renderToValue={20000}
      isLong
      progress={2500.5}
      sliderTitle={title}
      stepRenderLine={800}
      key={Math.random() + areaFrom + areaTo}
      theValueIsShow={5000}
      listSelections={convertArea || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setAreaKey(key);
        setAreaFrom(from);
        setAreaTo(to);
      }}
      keyValue={areaKey}
      from={areaFrom}
      to={areaTo}
      unit={'m²'}
      min={0}
      max={5000}
      step={1}
      additionalItems={[{ key: '', from: 0, to: 20001, value: 'Tất cả' }]}
    />
  );
}
