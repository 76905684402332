import React from 'react';
import { StyleSheet, View } from 'react-native-web';
import { isEqual } from 'lodash';
import ReactPannellum from 'react-pannellum';

export default React.memo(Image360, isEqual);

type Props = {
  image360?: String,
};

const config = {
  autoRotate: -2,
  autoLoad: true,
  showFullscreenCtrl: false,
  showZoomCtrl: false,
};

function Image360(props: Props) {
  const { image360 } = props;
  return (
    <View style={styles.container}>
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={image360}
        config={config}
        style={{
          width: '100%',
          height: '100%',
          background: '#000000',
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  viewer: {
    height: 230,
  },
});
