import { useCallback } from 'react';
import { mergeDeep } from '../../helper';
import isEmpty from 'lodash/isEmpty';

export const useLoadMore = ({
  isLoadingMore,
  hasNextPage,
  currentPage,
  setIsLoadingMore,
  fetchMore,
  variables = {},
  prev: initialPrev = {},
}) => {
  return useCallback(async () => {
    if (isLoadingMore || !hasNextPage) {
      return;
    }
    await setIsLoadingMore(true);
    await fetchMore({
      variables: {
        ...variables,
        page: (currentPage || 1) + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const finalPrev = isEmpty(prev) ? initialPrev : prev;
        if (!fetchMoreResult) {
          return finalPrev;
        }
        return mergeDeep(finalPrev, fetchMoreResult);
      },
    });
    await setIsLoadingMore(false);
  }, [
    isLoadingMore,
    hasNextPage,
    setIsLoadingMore,
    fetchMore,
    variables,
    currentPage,
  ]);
};
