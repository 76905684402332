import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import InputSelector from '@components/inputSelector';

type Props = {
  floorCountKey?: string,
  floorCountFrom?: number,
  floorCountTo?: number,
  setFloorCountKey?: Function,
  setFloorCountFrom?: Function,
  setFloorCountTo?: Function,
  onResetSelected?: Function,
  title?: string,
  unit?: string,
};
export default function InputFloorCountDropdown({
  floorCountKey,
  floorCountFrom,
  floorCountTo,
  setFloorCountKey,
  setFloorCountFrom,
  setFloorCountTo,
  onResetSelected,
  title = 'Số tầng',
  unit = 'phòng',
}: Props) {
  const { townhouse } = configs;
  const { floorCount, bedroomCount, toiletCount } = townhouse || {};
  const convertFloorCount =
    (title === 'Số phòng ngủ'
      ? bedroomCount
      : title === 'Số nhà vệ sinh'
      ? toiletCount
      : floorCount
    )?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <InputSelector
      dropdownButtonTitle={title}
      renderToValue={168}
      progress={84.5}
      sliderTitle={title}
      key={Math.random() + floorCountFrom + floorCountTo}
      listSelections={convertFloorCount || []}
      onSelectItem={({ from, to, key }) => {
        onResetSelected && onResetSelected();
        setFloorCountKey(key);
        setFloorCountFrom(from);
        setFloorCountTo(to);
      }}
      keyValue={floorCountKey}
      from={floorCountFrom}
      to={floorCountTo}
      unit={unit}
      min={0}
      max={168}
      step={1}
      additionalItems={[
        { key: '', from: 0, to: 169, value: 'Tất cả' },
        { key: '', from: null, to: 0, value: 'Không có' },
      ]}
    />
  );
}
