import React, { useState, InputHTMLAttributes } from 'react';
import styles from './input.module.scss';
import ErrorForFormHook from '@components/errorForFormHook';

export type InputProps = {
  label?: string,
  name?: string,
  inputClassName?: string,
  renderComponent?: Function,
  invalid?: boolean,
  errorMessage?: string,
  validationText?: string,
  inputOuterClassName?: string,
  maxLength?: number,
  onDelete?: Function,
  hideDelete?: boolean,
  inputInnerClassName?: string,
  disabled?: boolean,
  hideMaxLength?: boolean,
  isRequired?: boolean,
  renderRightComponent?: Function,
  errors?: Object,
  max?: Number,
  min?: Number,
} & InputHTMLAttributes;

export default React.forwardRef(MyInput);

function MyInput(props: InputProps, ref) {
  const {
    label,
    errorMessage,
    renderComponent,
    inputClassName,
    inputOuterClassName,
    invalid,
    maxLength,
    onDelete,
    hideDelete = false,
    inputInnerClassName,
    disabled,
    hideMaxLength = false,
    isRequired,
    renderRightComponent,
    errors,
    name,
    onKeyDown,
    type,
    max,
    min,
    ...inputProps
  } = props;
  const [focused, setOnFocus] = useState(false);
  const isInputNumber = type === 'number';
  return (
    <div
      className={`relative space-y-2 ${inputClassName} ${
        invalid ? 'errorInput' : ''
      }`}
    >
      {label && (
        <div className="w-full flex flex-row items-center space-x-1">
          <div className={styles.label}>{label}</div>
          {isRequired && (
            <div className="tex-sm font-medium text-red-500">*</div>
          )}
        </div>
      )}
      <div
        style={{
          boxShadow: focused
            ? '0px 0px 3px 3px rgba(67,142,255,0.26)'
            : undefined,
        }}
        className={`flex flex-row items-center ${styles.inputContainer}  ${
          disabled ? 'opacity-50' : undefined
        } ${
          (invalid || errorMessage?.length > 0) && !focused
            ? styles.inputError
            : inputOuterClassName
        }`}
      >
        <input
          {...inputProps}
          autoComplete="new-password"
          onWheel={event => event.currentTarget.blur()}
          ref={ref}
          disabled={disabled}
          maxLength={maxLength}
          max={max}
          min={min}
          onFocus={e => {
            inputProps.onFocus && inputProps.onFocus(e);
            setOnFocus(true);
          }}
          onBlur={e => {
            inputProps.onBlur && inputProps.onBlur(e);
            setOnFocus(false);
          }}
          className={`${styles.innerInput}  ${
            disabled ? 'opacity-50' : undefined
          } ${inputInnerClassName}`}
          onKeyDown={evt => {
            if (
              isInputNumber &&
              ['e', 'E', '-', 'ArrowDown'].includes(evt.key)
            ) {
              evt.preventDefault();
            } else {
              onKeyDown && onKeyDown(evt);
            }
          }}
          onKeyPress={e => {
            if (isInputNumber && e.target.value?.length === maxLength) {
              e.preventDefault();
            }
          }}
          type={type}
        />
        <div
          className={`flex flex-col items-end mr-3 ${
            maxLength && !hideMaxLength ? 'justify-between' : 'justify-center'
          }`}
        >
          {inputProps?.value && !hideDelete ? (
            <div
              onClick={!disabled ? onDelete : () => {}}
              className="w-20px h-20px cursor-pointer"
            >
              <img
                className="w-full h-full ml-1"
                alt="close"
                src="/svg/ico-close.svg"
              />
            </div>
          ) : (
            <div className="w-20px h-20px cursor-pointer" />
          )}
          <div style={{ fontSize: 10 }} className="text-gray-400">
            {maxLength &&
              !hideMaxLength &&
              maxLength - inputProps?.value?.length}
          </div>
        </div>
        {renderRightComponent && renderRightComponent()}
      </div>
      {renderComponent && renderComponent({ focused })}
      <ErrorForFormHook errors={errors} name={name} />
      {!!errorMessage?.length && (
        <div className="text-sm text-red-400">{errorMessage}</div>
      )}
    </div>
  );
}
