import React from 'react';
import styles from './realEstate.module.scss';

type ItemDescriptionProps = {
  icon?: any,
  value?: string,
  wrapperClass?: string,
  iconClass?: string,
  valueClass?: string,
};
export default function ItemDescription({
  icon,
  value,
  wrapperClass,
  iconClass,
  valueClass,
}: ItemDescriptionProps) {
  return (
    <div className={`flex-row flex items-center ${wrapperClass}`}>
      {icon && (
        <img
          src={icon}
          alt="icon"
          className={`${styles.iconQuickInfo} ${iconClass}`}
        />
      )}
      <div className={`${styles.txtQuickInfo} ${valueClass}`}>{value}</div>
    </div>
  );
}
