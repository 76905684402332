import styles from '@components/table/table.module.scss';
import React, { useCallback } from 'react';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useManageUserB2BContext } from '../context';
import { View } from 'react-native-web';

type Props = {
  data?: Array,
  loading?: boolean,
};
export default function RenderHeader({ data, loading }: Props) {
  const { listSelected, setListSelected } = useManageUserB2BContext();
  const isCheckAll = listSelected?.length === data?.length;
  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data.map(e => e.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);
  return (
    <View
      style={{
        borderTopLeftRadius: 9,
        borderTopRightRadius: 9,
        width: '100%',
      }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              disabled={loading}
              isChecked={isCheckAll}
              onPress={handleCheckAll}
              isPartialCheck={listSelected[0]?.length >= 1}
            />
          </div>

          <div className={`${styles.headContainer} w-175px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Đường
            </div>
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Phường/ Xã
            </div>
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Quận/ Huyện
            </div>
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Tỉnh/ Thành phố
            </div>
          </div>
          <div className={`${styles.headContainer} w-200px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Giá tốt
            </div>
          </div>
          <div className={`${styles.headContainer} w-200px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Giá thị trường
            </div>
          </div>
          <div className={`${styles.headContainer} w-200px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Giá cao
            </div>
          </div>
        </View>
      </View>
    </View>
  );
}
