import { useToast } from '@chakra-ui/react';
import ImageAndVideoList from '@components/imageAndVideoList';
import MyToast from '@components/MyToast';

type Props = {
  mediaUrlIds?: Array,
};
export default function RenderMedia({ mediaUrlIds }: Props) {
  const toast = useToast();
  return (
    <div className="w-323px h-auto rounded-lg p-4 border border-gray-300">
      <ImageAndVideoList imgUrls={mediaUrlIds} />
      <div className="mt-2.5 border-t border-gray-300"></div>
      <div className="text-16 font-medium text-blue-main mt-2.5">
        Hình ảnh VR 360
      </div>
      <div
        className="cursor-pointer flex-row flex underline text-blue-main text-14 justify-end"
        onClick={async () => {
          await toast({
            position: 'bottom-right',
            duration: 5000,
            render: ({ id, onClose }) => (
              <MyToast
                id={id}
                onClose={onClose}
                type="warning"
                message="Chức năng đang được hoàn thiện"
                closeButtonPos="center"
              />
            ),
          });
        }}
      >
        Xem hình ảnh VR 360
        <img src="/svg/eye.svg" alt="icon" />
      </div>
    </div>
  );
}
