import gql from 'graphql-tag';

export const GET_PERMISSION_GROUPS = gql`
  query listPermissionGroups(
    $searchString: String
    $code: String
    $name: String
    $creator: String
    $createdDate: InternalFromToDateStringInputType
    $page: Int
    $limit: Int
  ) @api(name: users) {
    listPermissionGroups(
      searchString: $searchString
      code: $code
      name: $name
      creator: $creator
      createdDate: $createdDate
      page: $page
      limit: $limit
    ) {
      items {
        id
        name
        code
        createdAt
        creator {
          id
          fullName
          avatar {
            ... on FileWithFullUrls {
              originalUrl
              fileId
            }
          }
        }
        permissions {
          id
          configs
        }
      }
      page
      total
      totalPages
      limit
      pagingCounter
      hasPrevPage
      hasNextPage
    }
  }
`;

export const GET_PERMISSION_GROUP_DETAILS = gql`
  query permissionGroupDetails($permissionGroupId: ObjectID!)
  @api(name: users) {
    permissionGroupDetails(permissionGroupId: $permissionGroupId) {
      id
      code
      name
      permissions {
        id
        name
        configs
      }
    }
  }
`;
