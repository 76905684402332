/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import MyButton from '@components/button';
import Checkbox from '@components/checkbox';
import SearchBox from '@components/searchBox';
import { makeStyles } from '@material-ui/core';
import TreeItem from '@mui/lab/TreeItem';
import { TreeView } from '@mui/lab';
import React, { useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useList } from 'react-use';
import { filterAndMapChildren, getChildByKey } from '@utils/helper';
import Fuse from 'fuse.js';
import CheckboxUsingTable from '@components/checkboxUsingTable';

const useTreeItemStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTreeItem-content.Mui-selected,.MuiTreeItem-content.Mui-selected.Mui-focused':
      {
        backgroundColor: '#FFE6B4',
      },
    '.MuiTreeItem-content:hover': {
      backgroundColor: '#FFE6B450',
    },
  },
  content: {
    marginTop: '10px',
    flexDirection: 'row-reverse',
    borderRadius: '8px',
    backgroundColor: '#EDF4FF',
    height: 40,
  },
  root: {
    position: 'relative',
    width: '100%',
    '&:before': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 14,
      left: -16,
      top: 21,
      borderBottom: props => (!props.rootNode ? `1px solid #B4B8BC` : 'none'),
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid #B4B8BC`,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles(props);
  const {
    labelText,
    rootNode,
    indexText,
    info,
    onSelect,
    node,
    isCheck,
    ...other
  } = props;

  return (
    <div className="w-full flex flex-row space-y-2">
      <TreeItem
        label={
          <div className="flex flex-row text-14 items-center space-x-2">
            <div className="flex">{indexText}</div>
            <div className={info ? 'underline text-blue-main' : 'none'}>
              {labelText}
            </div>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          group: classes.group,
          iconContainer: classes.iconContainer,
        }}
        {...other}
      />
      <Checkbox
        isChecked={isCheck}
        onPress={() => onSelect(node)}
        wrapperClass="flex-row-reverse"
      />
    </div>
  );
}
const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 'auto',
  },
}));

type Props = {
  headerLabel?: String,
  searchPlaceHolder?: String,
  data?: Object,
  labelText?: String,
  onClose: Function,
  selectedItems: Array,
  setItems: Function,
  id?: string,
  isTree?: boolean,
};

export default function ListView(props: Props) {
  const {
    headerLabel,
    data = [],
    searchPlaceHolder,
    onClose,
    selectedItems,
    setItems,
    id,
    isTree = false,
  } = props;
  const [localList, setLocalList] = useList(selectedItems);
  const [checkAll, setCheckAll] = useState(false);
  const [search, setSearch] = useState('');
  const fuse = useMemo(() => {
    return new Fuse(data, {
      keys: ['value'],
      includeScore: false,
    });
  }, [data]);
  const filteredResult = useMemo(() => {
    return search?.length ? fuse.search(search)?.map(e => e.item) : data;
  }, [search, data, fuse]);
  const permissionTreeFiltered = isTree
    ? filterAndMapChildren(
        data,
        filteredResult.map(e => e.id)
      )
    : filteredResult;
  const getOnChange = item => {
    const allItems = getChildByKey(item, item?.key);
    const checked = localList?.some(e => e?.key === item?.key);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = !checked
      ? [...localList, ...allItems]
      : localList.filter(
          ({ key }) => !allItems?.map(p => p.key)?.includes(key)
        );
    if (item?.type && !array?.some(p => p?.key === item?.parent)) {
      const pro = data?.find(d => d?.key === item?.parent);
      if (pro) array.push(pro);
    }
    if (array?.length > 0 && array?.length < data?.length) {
      setCheckAll(false);
    }
    setLocalList.set(array);
  };
  const handleCheckAll = () => {
    if (checkAll) {
      setLocalList.clear();
    } else {
      setLocalList.set(data);
    }
    setCheckAll(!checkAll);
  };
  const classes = useStyles();
  return (
    <div>
      <div className="p-4 flex flex-col">
        <span className="text-16 font-medium text-blue-main justify-center flex w-full">
          {headerLabel}
        </span>
        <span className="text-14 font-medium text-black-main mt-4">
          Đã chọn: {selectedItems?.length}/{data?.length}
        </span>
        <div className="mt-4">
          <SearchBox
            searchValue={search}
            setSearchValue={setSearch}
            placeholder={searchPlaceHolder ? searchPlaceHolder : ''}
          />
        </div>
        <div className="justify-end flex w-full mt-4">
          <CheckboxUsingTable
            isChecked={checkAll || localList?.length === data?.length}
            onPress={() => handleCheckAll()}
            isPartialCheck={
              localList?.length > 0 && localList?.length < data?.length
            }
            title="Chọn tất cả"
            wrapperClass="flex-row-reverse space-x-reverse"
            titleClass="text-14 text-black-main"
          />
        </div>
      </div>
      <div
        className="w-640px flex flex-col pb-4 pl-4 pr-4 overflow-y-auto"
        style={{ minHeight: 200, maxHeight: 350 }}
      >
        <div>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandLessIcon />}
            defaultExpandIcon={<ExpandMoreIcon />}
          >
            {permissionTreeFiltered?.map((item, index) => {
              return (
                <div key={index} className="flex flex-row space-y-2">
                  <StyledTreeItem
                    key={item?.id}
                    rootNode={true}
                    nodeId={index + 1}
                    indexText={`${index + 1}.`}
                    labelText={item?.value}
                    node={item}
                    onSelect={getOnChange}
                    isCheck={localList?.some(p => p?.key === item?.key)}
                  >
                    {item?.children?.map((childNode, childIndex) => (
                      <StyledTreeItem
                        key={childNode?.id}
                        nodeId={childNode?.key}
                        indexText={`${index + 1}.${childIndex + 1}`}
                        labelText={childNode?.value}
                        onSelect={getOnChange}
                        node={childNode}
                        isCheck={localList?.some(
                          p => p?.key === childNode?.key
                        )}
                      />
                    ))}
                  </StyledTreeItem>
                </div>
              );
            })}
          </TreeView>
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
      <div className="flex flex-row w-full justify-end space-x-4 p-4">
        <div className="w-147px">
          <MyButton onPress={onClose} type="gray" label="Trở về" />
        </div>
        <div className="w-147px">
          <MyButton
            onPress={() => {
              if (checkAll || (!isTree && localList?.length === data?.length)) {
                setItems.set(['all']);
              } else {
                setItems.set(localList);
              }
              onClose();
            }}
            type="blue"
            label="Xác nhận"
          />
        </div>
      </div>
    </div>
  );
}
