import React from 'react';
import Section from './section';
import {
  convertFloatWithDot,
  DocTienBangChuV2,
  getFromTo2,
  getTimeForRent,
} from '@utils/helper';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { buildingTypeConfigs } from '@utils/configs/configsLocal';

type Props = {
  data?: Object,
  demand?: string,
};

export default function DetailRealEstate({ data, demand }: Props) {
  const {
    forRentRealEstateTypes = [],
    directions = [],
    warehouseType = [],
  } = configs;
  const realEstateType = data?.type;

  const textArea = ['condotel', 'apartment', 'penthouse'].includes(
    realEstateType
  )
    ? 'Diện tích (tim tường)'
    : 'Diện tích';
  return (
    <Section
      source="/svg/notes_1.svg"
      title="Chi tiết tin rao"
      style={{ flex: 1 }}
      innerClass="space-y-4 mt-4"
    >
      <Item
        src="/svg/detailRealEstate/typeOfDemand.svg"
        title="Nhu cầu"
        value={
          data?.isForSell && demand === 'sell'
            ? 'Bán'
            : !data?.isForSell && demand === 'sell'
            ? 'Cho thuê'
            : data?.isNeedToBuy
            ? 'Cần mua'
            : 'Cần thuê'
        }
      />
      <Item
        src="/svg/detailRealEstate/loai.svg"
        title="Loại"
        value={
          forRentRealEstateTypes?.find(e => e.key === realEstateType)?.value
        }
      />
      <Item
        src="/svg/detailRealEstate/quocgia.svg"
        title="Quốc gia"
        value="Việt Nam"
      />
      <Item
        src="/svg/detailRealEstate/diachi.svg"
        title="Địa chỉ"
        value={data?.fullAddress}
      />
      <Item
        src="/svg/detailRealEstate/vitri.svg"
        title="Vị trí"
        value={data?.hasAlley ? 'Trong hẻm' : 'Mặt tiền'}
      />
      <Item
        src="/svg/detailRealEstate/ngang.svg"
        title="Ngang"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.width)
            : getFromTo2(data?.width)
        }
        unit="m"
      />
      <Item
        src="/svg/detailRealEstate/dai.svg"
        title="Dài"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.length)
            : getFromTo2(data?.length)
        }
        unit="m"
      />
      <Item
        src="/svg/detailRealEstate/MatHau.svg"
        title="Mặt hậu"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.rear)
            : getFromTo2(data?.rear)
        }
        unit="m"
        hideField={realEstateType === 'motel'}
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title={textArea}
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.area)
            : getFromTo2(data?.area)
        }
        unit="m²"
        hideField={realEstateType === 'motel'}
      />
      <Item
        src="/svg/detailRealEstate/giadukien.svg"
        title="Giá"
        value={
          demand === 'sell'
            ? DocTienBangChuV2(data?.price)
            : getFromTo2(data?.price, true)
        }
        valueStyle="text-red-600"
      />
      <Item
        src="/svg/detailRealEstate/sotang.svg"
        title="Số tầng"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.floorCount)
            : getFromTo2(data?.floorCount)
        }
        hideField={['apartment', 'condotel'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/sotang.svg"
        title="Vị trí tầng"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.onFloor)
            : getFromTo2(data?.onFloor)
        }
        hideField={
          !['apartment', 'penthouse', 'condotel', 'motel'].includes(
            realEstateType
          )
        }
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Diện tích thông thủy"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.clearanceArea)
            : getFromTo2(data?.clearanceArea)
        }
        unit="m²"
        hideField={
          !['apartment', 'penthouse', 'condotel'].includes(realEstateType)
        }
      />
      <Item
        src="/svg/detailRealEstate/dientich.svg"
        title="Diện tích sàn"
        value={
          demand === 'sell' && data?.constructionArea
            ? convertFloatWithDot(parseFloat(data?.constructionArea).toFixed(2))
            : getFromTo2(parseFloat(data?.constructionArea).toFixed(2))
        }
        unit="m²"
      />
      <Item
        src="/svg/detailRealEstate/sophongngu.svg"
        title="Số phòng ngủ"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.bedroomCount)
            : getFromTo2(data?.bedroomCount)
        }
        hideField={['building', 'land'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/sonhavesinh.svg"
        title="Số nhà vệ sinh"
        value={
          demand === 'sell'
            ? convertFloatWithDot(data?.toiletCount)
            : getFromTo2(data?.toiletCount)
        }
        hideField={realEstateType === 'land'}
      />
      <Item
        src="/svg/detailRealEstate/direction.svg"
        title="Hướng"
        value={
          demand === 'sell'
            ? directions.find(e => e.key === data?.direction)?.value
            : directions
                ?.filter(e => data?.direction?.includes(e.key))
                ?.map(item => item.value)
                ?.join(', ')
        }
      />
      <Item
        src="/svg/detailRealEstate/HienTrang.svg"
        title="Hiện trạng"
        value={data?.isNewConstruction || data?.isNewly ? 'Xây mới' : 'Xây cũ'}
        hideField={realEstateType === 'land'}
      />
      <Item
        src="/svg/detailRealEstate/TenDuAn.svg"
        title="Tên dự án"
        value={data?.projectName || data?.project}
        hideField={
          ![
            'apartment',
            'villa',
            'land',
            'shophouse',
            'penthouse',
            'condotel',
          ].includes(realEstateType)
        }
      />
      <Item
        src="/svg/detailRealEstate/Block-Khu.svg"
        title="Block/ Khu"
        value={data?.block || data?.blockName}
        hideField={
          !['apartment', 'condotel', 'land', 'shophouse', 'penthouse'].includes(
            realEstateType
          )
        }
      />
      <Item
        src="/svg/detailRealEstate/ChuDauTu.svg"
        title="Chủ đầu tư"
        value={data?.investor}
        hideField={
          !['apartment', 'land', 'shophouse', 'penthouse', 'condotel'].includes(
            realEstateType
          )
        }
      />
      <Item
        src="/svg/detailRealEstate/LoaiCaoOc.svg"
        title="Loại cao ốc"
        value={
          buildingTypeConfigs.find(e => e.key === data?.buildingType)?.value
        }
        hideField={realEstateType !== 'building'}
      />
      <Item
        src="/svg/detailRealEstate/ChucNangNhaXuong.svg"
        title="Chức năng nhà xưởng"
        value={warehouseType.find(e => e.key === data?.warehouseType)?.value}
        hideField={!['warehouse', 'land'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/NganhHang.svg"
        title="Ngành hàng"
        value={data?.industryTrade}
        hideField={!['townhouse', 'shophouse'].includes(realEstateType)}
      />
      <Item
        src="/svg/detailRealEstate/SoSao.svg"
        title="Số sao"
        value={data?.hotelStar}
        hideField={realEstateType !== 'hotel'}
      />
      <Item
        src="/svg/detailRealEstate/CoGacHayKhong.svg"
        title="Có gác hay không"
        value={data?.hasMezzanine ? 'Có' : 'Không'}
        hideField={data?.type !== 'motel'}
      />
      {data?.timeForRent && demand === 'buy' && (
        <Item
          src="/svg/detailRealEstate/calendar.svg"
          title="Thời gian thuê"
          value={getTimeForRent(data?.timeForRent)}
        />
      )}
    </Section>
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  unit?: string,
  src?: string,
  valueStyle?: Object,
  hideField?: boolean,
};

function Item({
  title,
  value = '',
  unit = '',
  src,
  valueStyle,
  hideField,
}: ItemProps) {
  if (!value || hideField) {
    return null;
  }
  return (
    <div className="flex w-full justify-between py-2 border-b border-gray-300">
      <div className="flex items-center space-x-2">
        <img alt="ico" src={src} className="w-4 h-4" />
        <div className="text-sm mr-6 font-medium text-black-main">{title}</div>
      </div>
      <div className={`text-sm text-right ${valueStyle}`}>
        {value} {unit}
      </div>
    </div>
  );
}
