import Table from '@components/table';
import { View } from 'react-native-web';
import RenderHeaderProject from './renderHeaderProject';
import { useManageProjectContext } from '../context';
import React, { useCallback } from 'react';
import styles from '../../../manageRealEstate/needApproval/components/needApproval.module.scss';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import TriangleComponent from '../../../manageProduct/components/triangleComponent';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import { merge2Array } from '@utils/helper';
import moment from 'moment';
import { isEmpty } from 'lodash';
import MyDropdown from '@components/myDropdown';
import UserInfo from '@components/userInfo';
import RenderAvatarComponent from '../../../manageProduct/components/renderAvatarComponent';
import RenderPriceRangeProject from './renderPriceRangeProject';

type Props = {
  loading?: boolean,
  index?: number,
  onSelect?: Function,
  singleOnSelect?: Function,
  isChecked?: boolean,
  item?: Object,
};

function ItemTableProject({
  loading,
  index,
  onSelect,
  singleOnSelect,
  isChecked,
  item,
}: Props) {
  const {
    projectCode,
    projectStatus,
    projectName,
    projectType,
    street,
    ward,
    district,
    province,
    houseNumber,
    createdAt,
    creator,
    priceRange,
  } = item || {};
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { projectForRentTypes, projectForSaleTypes, projectStatusType } =
      realEstateConfigurations,
    finalTypeProject = merge2Array(projectForSaleTypes, projectForRentTypes);
  const configStatusName = projectStatusType
      ?.filter(e => projectStatus?.includes(e?.key))
      ?.map(e => e.value),
    configProjectTypeName = finalTypeProject
      ?.filter(e => projectType?.includes(e?.key))
      ?.map(e => e.value);
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className={styles.row}
        >
          <div
            className={`${styles.itemTable} items-center justify-center text-sm w-60px`}
          >
            <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
          </div>
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div
              className={`${styles.itemTable} text-sm w-60px flex items-center justify-center`}
            >
              {index + 1}
            </div>
            <div className={`${styles.itemTable} relative w-202px`}>
              <div className="flex flex-1 text-black-main">{projectCode}</div>
            </div>
            <div className={`${styles.itemTable} relative w-180px`}>
              {configStatusName}
              <TriangleComponent status={item?.projectStatus} />
            </div>
            <div className={`${styles.itemTable} relative w-250px`}>
              {projectName}
            </div>
            <div className={`${styles.itemTable} relative w-210px`}>
              {configProjectTypeName}
            </div>
            <div className={`${styles.itemTable} w-175px truncate`}>
              {houseNumber}
            </div>
            <div className={`${styles.itemTable} w-175px`}>{street?.name}</div>
            <div className={`${styles.itemTable} w-175px`}>
              {ward?.name ? ward?.name : 'Không có'}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {district?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {province?.name}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {moment(createdAt).format('hh:mmA,DD/MM/YYYY')}
            </div>
            <div className={`${styles.itemTable} w-200px`}>
              {!isEmpty(creator) ? (
                <MyDropdown
                  DropdownComponent={UserInfo}
                  dropdownContext={{ userId: item.creator.id }}
                >
                  <div className="flex space-x-2 items-center">
                    <RenderAvatarComponent
                      wrapperStyle="w-30px h-30px"
                      avatarId={item?.creator?.avatar}
                    />
                    <span className="text-14 font-text text-blue-main underline">
                      {item?.creator?.fullName}
                    </span>
                    <img src="/svg/eye.svg" alt="icon" />
                  </div>
                </MyDropdown>
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
            <div className={`${styles.itemTable} w-175px`}>
              {priceRange ? (
                <RenderPriceRangeProject data={priceRange} />
              ) : (
                <span className="italic text-gray-500">Không có</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type RenderTableProjectProps = {
  data?: Array,
  loading?: boolean,
  canLoadMore?: boolean,
  onLoadMore?: Function,
  isLoadingMore?: boolean,
};

export default function RenderTableProject({
  data,
  loading,
  canLoadMore,
  onLoadMore,
  isLoadingMore,
}: RenderTableProjectProps) {
  const { listSelected, setListSelected, previousId, setPreviousId } =
    useManageProjectContext();
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected?.set(listSelected?.filter(e => e !== key));
      } else {
        setListSelected?.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleSingOnSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([key]);
        if (listSelected[0] !== key) {
          setPreviousId(listSelected[0]);
        }
      }
    },
    [setListSelected, listSelected, setPreviousId, previousId]
  );
  return (
    <View
      style={{
        width: '100%',
        maxHeight: 300,
        backgroundColor: 'white',
        marginTop: 16,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#2459ad',
        overflow: 'hidden',
      }}
    >
      <Table>
        <View style={{ width: '100%' }}>
          <RenderHeaderProject data={data} loading={loading} />
          {data?.map((item, index) => {
            const isChecked = listSelected.find(e => e === item?.id);
            return (
              <ItemTableProject
                item={item}
                loading={loading}
                isChecked={isChecked}
                onSelect={() => handleCheck(item?.id, isChecked)}
                singleOnSelect={() => handleSingOnSelect(item?.id, isChecked)}
                key={index}
                index={index}
              />
            );
          })}
          {!loading && !data?.length && (
            <p className="text-base m-3">Không có dự án</p>
          )}
        </View>
      </Table>
      {canLoadMore && (
        <div className="w-full">
          <button
            onClick={onLoadMore}
            style={{ backgroundColor: '#FDF9E6' }}
            className={`flex items-center justify-center w-full h-35px cursor-pointer rounded`}
          >
            <div className="flex flex-row items-center space-x-1">
              <div className="text-sm text-blue-main font-semibold">
                {isLoadingMore ? 'Đang tải thêm' : 'Xem thêm'}
              </div>
              {isLoadingMore ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
              ) : (
                <img
                  alt="arrow"
                  src="/svg/arrowDownBlue.svg"
                  className="w-4 h-4"
                />
              )}
            </div>
          </button>
        </div>
      )}
    </View>
  );
}
