import React, { useState } from 'react';
import Section from './section';

import MyModalV2 from '@components/modalV2/myModalV2';
import RealEstateLocationOnMapModal from '@components/realEstateLocationOnMapModal';

type Props = {
  data?: Object,
};

export default function LocationRealEstate({ data }: Props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Section
        title="Bản đồ vị trí bất động sản"
        source="/svg/locationDetail.svg"
        renderRightComponent={() => {
          return (
            <div
              className="flex items-center space-x-1 cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img
                alt="ico"
                src="/svg/eye_fill.svg"
                className="w-22px h-22px"
              />
              <div className="text-sm text-blue-main">Xem trên bản đồ</div>
            </div>
          );
        }}
      />
      <MyModalV2 open={showModal}>
        <RealEstateLocationOnMapModal
          lat={data?.map?.coordinates[1]}
          long={data?.map?.coordinates[0]}
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
    </>
  );
}
