import styles from './realEstate.module.scss';
import stylesBottom from './bottomRealEstate.module.scss';

type Props = {
  topClass?: string,
  bottomClass?: string,
};

export default function RealEstateLoading({ topClass, bottomClass }: Props) {
  return (
    <div className={`${styles.realEstate} overflow-hidden`}>
      <div
        className={`${styles.imageRealEstate} animated-gradient-lighter ${topClass}`}
      />
      <div className={`${stylesBottom.description} ${bottomClass}`}>
        <div className="animated-gradient-lighter flex-1" />
        <div className="animated-gradient-lighter mt-4px flex-1" />
      </div>
    </div>
  );
}
