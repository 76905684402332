import styles from '@components/table/table.module.scss';
import React, { useState } from 'react';
import { useConvertDataRealEstateEditHistory } from '@utils/hooks/realEstateEditHistory/useConvertDataRealEstateEditHistory';
import { useConvertDataRenewRealEstate } from '@utils/hooks/renewRealEstate/useConvertDataRenewRealEstate';
import { useDeepCompareEffect } from 'react-use';
import { getMedias } from '@utils/helper';
import ModalViewMedia from '../../../../../modals/modalViewMedia';
import MyModalV2 from '@components/modalV2/myModalV2';

type Props = {
  index?: number,
  item?: Object,
  type?: string,
  headerTableConfigs?: Array,
  total?: number,
};

export default function TableRow(props: Props) {
  const { index, item, type, headerTableConfigs = [], total } = props;
  const [showModal, setShowModal] = useState(false);
  const data1 = useConvertDataRenewRealEstate(item, type);
  const data2 = useConvertDataRealEstateEditHistory(item, type, setShowModal);
  const data = [...data1, ...data2];
  const isNeedRealEstate = type === 'demand';
  const configs = headerTableConfigs.map(item => {
    const foundConfigs = data.find(e => e.key === item.key);
    if (foundConfigs) {
      return { ...item, ...foundConfigs };
    }
  });
  const dataRender = isNeedRealEstate
    ? configs.filter(e => e.key !== 'mediaUrls')
    : configs;

  const renderValue = itemData => {
    if (typeof itemData?.value === 'function') {
      if (itemData?.value() !== 'Không có') {
        return itemData?.value();
      } else {
        return '-';
      }
    } else if (itemData.value && itemData.value !== 'Không có') {
      return itemData.value;
    } else {
      return '-';
    }
  };
  const mediaIds = item?.ops
    ?.filter(e => e.path.includes('mediaUrls'))
    ?.map(e => e.value);

  const [listUrls, setListUrls] = useState([]);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(
          !Array.isArray(mediaIds) ? [mediaIds] : mediaIds || []
        );
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [mediaIds]);
  return (
    <>
      <div
        className="flex w-full border-b border-l border-r border-gray-300"
        style={{ minHeight: 40 }}
      >
        <div
          className={`${styles.headContainer} w-120px items-center justify-center`}
        >
          <div className={`text-13 text-black-main ${styles.textHead}`}>
            {total - index}
          </div>
        </div>
        {dataRender.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles.headContainer} ${item?.className} items-center`}
            >
              <div
                className={`text-13 text-black-main ${styles.textHead} w-full ${
                  (typeof item?.value === 'function' &&
                    item?.value() === 'Không có') ||
                  item.value === 'Không có' ||
                  !item.value
                    ? 'text-center'
                    : 'text-left'
                }`}
              >
                {renderValue(item)}
              </div>
            </div>
          );
        })}
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ModalViewMedia activeIndex={0} listMedia={listUrls || []} />
      </MyModalV2>
    </>
  );
}
