import gql from 'graphql-tag';

export const LEAVE_COMMENT = gql`
  mutation leaveComment(
    $realEstateId: String!
    $content: String!
    $parentComment: String
  ) @api(name: b2bRealEstate) {
    leaveComment(
      realEstateId: $realEstateId
      content: $content
      parentComment: $parentComment
    ) {
      success
      msg
      payload
    }
  }
`;
