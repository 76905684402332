import MyButton from '@components/button';
import { useDropzone } from 'react-dropzone';
import UploadYoutubeMedia from '@components/uploadMedia/components/uploadYoutubeMedia';
import React from 'react';

type UploadLocalMediaProps = {
  option?: number,
  doUploadImage?: Function,
  push?: Function,
  mediaUrls?: Array,
};
export default function UploadLocalMedia({
  option,
  doUploadImage,
  push,
  mediaUrls,
}: UploadLocalMediaProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'video/*'],
    multiple: true,
    onDrop: doUploadImage,
    disabled: mediaUrls?.length > 8,
  });
  return option === 0 ? (
    <div className="flex flex-col h-full px-8 py-5 justify-between items-center ">
      <img alt="upload" src="/svg/upload.svg" className="w-24px h-26px" />
      <div className="leading-5">
        <div style={{ fontSize: 13 }} className="text-gray-400 text-center">
          Kéo và thả hình ảnh hoặc video BĐS từ thiết bị của <br></br> bạn vào
          đây hoặc “Chọn tập tin tải lên”
        </div>
        <div style={{ fontSize: 13 }} className="text-gray-400 text-center">
          Lưu ý: Tổng số lượng hình ảnh không vượt quá <b>6</b> và <br></br>
          video không vượt quá <b>3.</b>
        </div>
        <div style={{ fontSize: 13 }} className="text-gray-400 text-center">
          Hình ảnh không vượt quá 1MB. Video không vượt <br></br> quá 10MB.
        </div>
      </div>
      <div {...getRootProps({ className: 'dropzone' })} className="w-160px">
        <input {...getInputProps()} style={{ display: 'none' }} />
        <MyButton type="gray" label="Chọn tập tin tải lên" />
      </div>
    </div>
  ) : (
    <UploadYoutubeMedia mediaUrls={mediaUrls} setMediaUrls={push} />
  );
}
