import CheckboxUsingTable from '@components/checkboxUsingTable';
import styles from '@components/table/table.module.scss';
import React, { useCallback } from 'react';
import { View } from 'react-native-web';
import CalendarDropdown from '../../manageRealEstate/components/filterButtons/calendarDropdown';
import SearchTextDropdown from '../../manageRealEstate/components/filterButtons/searchTextDropdown';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import RenderResultFilterHeader from './renderResultFilterHeader';
import PropTypes from 'prop-types';

export const checkVisibleCol = id => {
  const { listField } = usePermissionGroupsContext();
  return listField.includes(id);
};

RenderHeader.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
export default function RenderHeader({ data, loading }) {
  const { listSelected, setListSelected, stateFilter, setStateFilterAt } =
    usePermissionGroupsContext();

  const isCheckAll = listSelected?.length === data?.length;

  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data?.map(item => item?.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);

  return (
    <View
      style={{
        borderTopLeftRadius: 9,
        borderTopRightRadius: 9,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#FFFFFF',
        zIndex: 50,
      }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              isPartialCheck={listSelected?.length > 0}
              onPress={handleCheckAll}
              isChecked={isCheckAll}
              disabled={loading}
            />
          </div>
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <span
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </span>
          </div>
          {checkVisibleCol('code') && (
            <div className={`${styles.headContainer} flex-1 items-center`}>
              <SearchTextDropdown
                title="Mã nhóm quyền"
                search={stateFilter.code}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('code')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('name') && (
            <div className={`${styles.headContainer} flex-1 items-center`}>
              <SearchTextDropdown
                title="Tên nhóm quyền"
                search={stateFilter.name}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('name')(value);
                }}
              />
            </div>
          )}
          {checkVisibleCol('creator') && (
            <div
              className={`${styles.headContainer} flex-1 items-center justify-center`}
            >
              <SearchTextDropdown
                search={stateFilter.creator}
                setSearch={value => {
                  setListSelected.clear();
                  setStateFilterAt('creator')(value);
                }}
                title="Người tạo"
              />
            </div>
          )}
          {checkVisibleCol('createdAt') && (
            <div
              className={`${styles.headContainer} flex-1 items-center justify-center`}
            >
              <CalendarDropdown
                start={stateFilter.createdAtFrom}
                end={stateFilter.createdAtTo}
                setStart={value => setStateFilterAt('createdAtFrom')(value)}
                setEnd={value => setStateFilterAt('createdAtTo')(value)}
                onResetSelected={() => {
                  setListSelected.clear();
                }}
                title="Ngày tạo"
              />
            </div>
          )}
        </View>
      </View>
      <RenderResultFilterHeader />
    </View>
  );
}
