import Table from '@components/table';
import moment from 'moment/moment';
import { useCallback } from 'react';
import { contractStatusConfigs } from '../../../../../../../../configs';
import RenderHeader from './renderHeader';
type Props = {
  loading?: boolean,
  index?: number,
  singleOnSelect?: Function,
  isChecked?: boolean,
  item?: Object,
};
function ItemContract({
  loading,
  index,
  singleOnSelect,
  isChecked,
  item,
}: Props) {
  const { contractCode, name, endDate, status, totalMember } = item || {};
  return (
    <div className="w-full h-50px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className="row"
        >
          <div
            onClick={singleOnSelect}
            className="flex-1 flex flex-row items-center"
          >
            <div
              className={`itemTable flex text-sm w-60px items-center justify-center`}
            >
              {index + 1}
            </div>

            <div className={`itemTable flex-1`}>
              <div className="text-black-main">{contractCode}</div>
            </div>
            <div style={{ maxWidth: 300 }} className={`itemTable w-212px`}>
              <div className="flex-1 text-black-main">{name}</div>
            </div>
            <div className={`itemTable w-212px`}>
              {moment(endDate).format('DD/MM/YYYY')}
            </div>
            <div className={`itemTable w-212px`}>
              <div
                style={{ color: status === 'expired' ? '#ff0000' : '#22313E' }}
                className={`flex-1`}
              >
                {contractStatusConfigs.find(e => e.key === status)?.value}
              </div>
            </div>
            <div className={`itemTable w-212px`}>{totalMember}</div>
          </div>
        </div>
      )}
    </div>
  );
}
type ListContractTableProps = {
  data?: Array,
  loading?: boolean,
  hasNextPage?: boolean,
  onLoadMore?: Function,
  isLoadingMore?: boolean,
  contractId?: string,
  setContractId?: Function,
  setContract?: Function,
  stateFilter?: Object,
  setStateFilter?: Function,
};
export default function ContractTable({
  data,
  loading,
  hasNextPage,
  onLoadMore,
  isLoadingMore,
  contractId,
  setContractId,
  setContract,
  stateFilter,
  setStateFilter,
}: ListContractTableProps) {
  const handleSingleOnSelect = useCallback(contract => {
    setContractId(contract?.id);
    setContract(contract);
  }, []);
  return (
    <div
      style={{ maxWidth: 1123 }}
      className="w-full h-full border border-blue-main rounded overflow-hidden table table-fixed"
    >
      <Table>
        <RenderHeader
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          data={data}
          loading={loading}
        />
        {data?.map((item, index) => {
          const isChecked = contractId === item.id;
          return (
            <ItemContract
              item={item}
              loading={loading}
              isChecked={isChecked}
              singleOnSelect={() => handleSingleOnSelect(item)}
              key={index}
              index={index}
            />
          );
        })}
        {!loading && !data?.length && (
          <p className="text-base m-3">Không có hợp đồng nào</p>
        )}
      </Table>
      {hasNextPage && (
        <div className="w-full">
          <button
            onClick={onLoadMore}
            style={{ backgroundColor: '#FDF9E6' }}
            className={`flex items-center justify-center w-full h-35px cursor-pointer rounded`}
          >
            <div className="flex flex-row items-center space-x-1">
              <div className="text-sm text-blue-main font-semibold">
                {isLoadingMore ? 'Đang tải thêm' : 'Xem thêm'}
              </div>
              {isLoadingMore ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-main" />
              ) : (
                <img
                  alt="arrow"
                  src="/svg/arrowDownBlue.svg"
                  className="w-4 h-4"
                />
              )}
            </div>
          </button>
        </div>
      )}
    </div>
  );
}
