import gql from 'graphql-tag';

export const CREATE_EMPLOYEE_ACCOUNT = gql`
  mutation b2bCreateEmployeeV2($inputData: CreateEmployeeNewInputType)
  @api(name: users) {
    b2bCreateEmployeeV2(inputData: $inputData) {
      success
      msg
    }
  }
`;

export const UPDATE_EMPLOYEE_LOGIN_INFO = gql`
  mutation b2bUpdateInfoLoginOfEmployee(
    $employeeId: String!
    $username: String
    $password: String
    $confirmPassword: String
  ) @api(name: users) {
    b2bUpdateInfoLoginOfEmployee(
      employeeId: $employeeId
      username: $username
      password: $password
      confirmPassword: $confirmPassword
    ) {
      success
      msg
    }
  }
`;

export const UPDATE_EMPLOYEE_ACCOUNT = gql`
  mutation b2bUpdateEmployee($updateData: UpdateUserInputType, $id: String!)
  @api(name: users) {
    b2bUpdateEmployee(id: $id, updateData: $updateData) {
      success
      msg
    }
  }
`;

export const REMOVE_EMPLOYEE = gql`
  mutation b2bRemoveEmployees($userId: String!) @api(name: users) {
    b2bRemoveEmployees(userId: $userId) {
      msg
      success
    }
  }
`;
export const LOCK_EMPLOYEE = gql`
  mutation b2bLockUserAccounts($userIds: [String]) @api(name: users) {
    b2bLockUserAccounts(userIds: $userIds) {
      msg
      success
    }
  }
`;
export const UNLOCK_EMPLOYEE = gql`
  mutation b2bUnlockUserAccounts($userIds: [String]) @api(name: users) {
    b2bUnlockUserAccounts(userIds: $userIds) {
      msg
      success
    }
  }
`;
