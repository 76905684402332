import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CONTRACT_NOTE_AND_ATTACHMENTS } from '@utils/graphql';
import { resetQueries } from '@utils/helper';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';

const updateVariable = toUpdateFields => {
  return {
    note: toUpdateFields.note,
    attachments: toUpdateFields.attachments?.map(e => e.fileId),
  };
};

export const useUpdateContractNoteAndAttachments = ({
  contractId,
  state,
  onClose,
}) => {
  const toast = useToast();
  const [updateFile, { loading, client }] = useMutation(
    UPDATE_CONTRACT_NOTE_AND_ATTACHMENTS
  );

  const onUpdate = useCallback(async () => {
    const variable = updateVariable(state);
    try {
      await updateFile({
        variables: {
          contractId: contractId,
          ...variable,
        },
      });
      resetQueries(['getContractDetail'], client.cache);
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message="Bổ sung tài liệu thành công"
            closeButtonPos="center"
          />
        ),
      });
      onClose();
    } catch (e) {
      await toast({
        position: 'bottom-right',
        duration: 5000,
        render: props => (
          <MyToast
            id={props.id}
            onClose={props.onClose}
            type="error"
            message="Bổ sung tài liệu thất bại"
            closeButtonPos="center"
          />
        ),
      });
      onClose();
    }
  }, [client, contractId, state, updateFile, onClose]);

  return {
    onUpdate,
    loading,
  };
};
