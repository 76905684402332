import moment from 'moment';
import React, { useState } from 'react';
import { getNameUrlFromSever } from '@utils/helper';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import { isEmpty } from 'lodash';
import ViewFileModal from '../../../../../../../../modals/viewFileModal/viewFileModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@utils/graphql';
import FileSaver from 'file-saver';
type Props = {
  attachments?: string,
  creator?: string,
  avatar?: string,
  fullName?: string,
};
const headerClass =
  'flex h-full px-2.5 text-black-main text-14 font-medium border-l border-gray-300 items-center truncate';
function RenderHeader() {
  return (
    <div className="w-full h-50px flex flex-row items-center overflow-x-auto">
      <div className="flex w-40px h-full items-center justify-center text-black-main text-14 font-medium">
        STT
      </div>
      <div className={`w-200px ${headerClass}`}>Tên tài liệu</div>
      <div className={`flex-1 ${headerClass}`}>Người tải lên</div>
      <div className={`w-167px ${headerClass}`}>Thời gian</div>
    </div>
  );
}
type ItemRowProps = {
  index?: number,
  item?: Object,
  creator?: string,
  avatar?: string,
  fullName?: string,
};

function ItemRow({ item, index, creator, avatar, fullName }: ItemRowProps) {
  const [showModal, setShowModal] = useState(false);
  const avatarUrl = item?.creator?.avatar?.originalUrl || avatar;
  const fullNameText = item?.creator?.fullName || fullName;
  const fileId = item?.fileId;
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId,
    },
    skip: !fileId,
  });

  const { originalUrl, type } = data?.readMediaUrls || {};
  return (
    <div className="flex-1 h-50px flex flex-row items-center">
      <div className="flex w-40px h-full items-center justify-center text-black-main text-14">
        {index + 1}
      </div>
      <div
        onClick={() => {
          switch (type) {
            case 'sheet':
              FileSaver.saveAs(
                originalUrl,
                getNameUrlFromSever(item?.originalUrl)
              );
              break;
            case 'doc':
              FileSaver.saveAs(
                originalUrl,
                getNameUrlFromSever(item?.originalUrl)
              );
              break;
            default:
              setShowModal(true);
              break;
          }
        }}
        className={`w-200px overflow-hidden flex flex-row items-center space-x-1 cursor-pointer ${headerClass}`}
      >
        <img src="/svg/paperclip-icon.svg" alt="paperclip icon" />
        <div
          style={{
            color: '#106FFF',
          }}
          className="flex-1 text-14 font-medium underline cursor-pointer truncate"
        >
          {getNameUrlFromSever(item?.originalUrl)}
        </div>
      </div>
      <div
        className={`flex-1 ${headerClass} ${
          isEmpty(item?.creator) && !creator ? 'pointer-events-none' : undefined
        }`}
      >
        <MyDropdown
          wrapperClass="w-full"
          DropdownComponent={UserInfo}
          dropdownContext={{
            userId: item?.creator?.id || creator,
            isB2B: true,
          }}
        >
          <div className="flex-1 flex flex-row items-center justify-between">
            <div className="w-120px flex flex-row items-center space-x-2.5 overflow-hidden">
              <div
                className={`w-6 h-6 rounded-full overflow-hidden ${
                  !item?.creator?.avatar?.originalUrl && !avatar
                    ? 'bg-gray-200'
                    : undefined
                }`}
              >
                {avatarUrl && (
                  <img alt="avt" src={avatarUrl} className="flex-1" />
                )}
              </div>
              <div
                className={`flex-1 text-14 font-normal truncate ${
                  item?.creator?.fullName
                    ? 'underline cursor-pointer text-blue-main'
                    : 'text-black-main'
                }`}
              >
                {fullNameText ? fullNameText : 'Không có'}
              </div>
            </div>
            {fullNameText && (
              <img
                alt="open icon"
                src="/svg/eye.svg"
                className="cursor-pointer"
              />
            )}
          </div>
        </MyDropdown>
      </div>
      <div className={`w-167px ${headerClass}`}>
        <div className="flex-1 text-sm text-black-main font-normal">
          {moment(item?.createdAt).format('HH:mmA DD/MM/YYYY')}
        </div>
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ViewFileModal fileId={item?.fileId} />
      </MyModalV2>
    </div>
  );
}

export default function AttachmentsTable(props: Props) {
  const { attachments, creator, avatar, fullName } = props;
  return (
    <div
      style={{ backgroundColor: '#EDF4FF' }}
      className="w-full rounded p-2.5 divide-y divide-gray-300 overflow-x-auto"
    >
      <RenderHeader />
      {attachments?.map((item, index) => {
        return (
          <ItemRow
            avatar={avatar}
            fullName={fullName}
            creator={creator}
            item={item}
            key={index}
            index={index}
          />
        );
      })}
    </div>
  );
}
