export const isNewConstructionConfigs = [
  {
    key: 'yes',
    value: 'Xây mới',
  },
  {
    key: 'no',
    value: 'Cũ',
  },
];
export const demandConfigs = [
  {
    key: 'ForSale',
    value: 'Bán',
  },
  {
    key: 'ForRent',
    value: 'Cho thuê',
  },
];
export const needDemandConfigs = [
  {
    key: 'ToBuy',
    value: 'Cần mua',
  },
  {
    key: 'ToRent',
    value: 'Cần thuê',
  },
];

export const buildingTypeConfigs = [
  {
    key: 'typeA',
    value: 'Loại A',
  },
  {
    key: 'typeB',
    value: 'Loại B',
  },
  {
    key: 'typeC',
    value: 'Loại C',
  },
];

export const hasAlleyConfigs = [
  {
    key: true,
    value: 'Hẻm',
  },
  {
    key: false,
    value: 'Mặt tiền',
  },
];
export const alleyWidthConfigs = [
  {
    key: '',
    value: '1 - 3 m',
    from: 1,
    to: 3,
  },
  {
    key: '',
    value: '3 - 5 m',
    from: 3,
    to: 5,
  },
  {
    key: '',
    value: '5 - 7 m',
    from: 5,
    to: 7,
  },
  {
    key: '',
    value: '7 - 10 m',
    from: 7,
    to: 10,
  },
  {
    key: '',
    value: '10 - 12 m',
    from: 10,
    to: 12,
  },
  {
    key: '',
    value: '12 - 15 m',
    from: 12,
    to: 15,
  },
  {
    key: '',
    value: '15 - 20 m',
    from: 15,
    to: 20,
  },
  {
    key: '',
    value: '25 - 30 m',
    from: 25,
    to: 30,
  },
  {
    key: '',
    value: '30 - 35 m',
    from: 30,
    to: 35,
  },
  {
    key: '',
    value: '35 - 70 m',
    from: 35,
    to: 70,
  },
  {
    key: '',
    value: '70 - 100 m',
    from: 70,
    to: 100,
  },
];
export const directionConfigs = [
  {
    key: 'Dong',
    value: 'Đông',
  },
  {
    key: 'Bac',
    value: 'Bắc',
  },
  {
    key: 'Nam',
    value: 'Nam',
  },
  {
    key: 'DongNam',
    value: 'Đông Nam',
  },
  {
    key: 'Tay',
    value: 'Tây',
  },
  {
    key: 'TayBac',
    value: 'Tây Bắc',
  },
  {
    key: 'TayNam',
    value: 'Tây Nam',
  },
  {
    key: 'DongBac',
    value: 'Đông Bắc',
  },
];
