export const useSwitchTabConfigs = () => {
  const projectDetail = 'projectDetail',
    blockDetail = 'blockDetail';
  return [
    {
      key: projectDetail,
      value: 'Tổng quan dự án',
    },
    {
      key: blockDetail,
      value: 'Thông tin Block/khu',
    },
  ];
};
