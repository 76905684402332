import { View } from 'react-native-web';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import RenderTableProduct from './components/renderTableProduct';
import { useGetRelatedInternalRealEstates } from '@utils/hooks/manageProduct/useGetRelatedInternalRealEstates';
import { useSameProductContext } from 'scenes/home/manageProduct/product/context';
import { useEffect } from 'react';

type Props = {
  id?: string,
  type?: string,
  demand?: string,
  setTotal?: Function,
  setSameButton?: Function,
  setIdSameProduct?: Function,
};

export default function SameProduct({
  setIdSameProduct,
  id,
  demand,
  type,
  setTotal,
  setSameButton,
}: Props) {
  const { search } = useLocation();
  const { stateFilter, setStateFilterAt } = useSameProductContext();

  const {
    realEstates,
    loading,
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  } = useGetRelatedInternalRealEstates({
    state: stateFilter,
    info: { typeOfDemand: demand, type },
    currentREId: id,
  });
  const pagination = {
    totalItem,
    totalPage,
    currentPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
  setTotal(totalItem);
  const { province, district, ward, street, houseNumber } =
    qs.parse(search) || {};
  useEffect(() => {
    setStateFilterAt('province')(province);
    setStateFilterAt('district')(district);
    setStateFilterAt('ward')(ward);
    setStateFilterAt('street')(street);
    setStateFilterAt('houseNumber')(houseNumber);
  }, []);
  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          width: '100%',
          backgroundColor: 'white',
          paddingBottom: 12,
        }}
      >
        <div className="w-full px-4">
          <RenderTableProduct
            id={id}
            type={type}
            demand={demand}
            setIdSameProduct={setIdSameProduct}
            setSameButton={setSameButton}
            loading={loading}
            data={realEstates}
            pagination={pagination}
          />
        </div>
      </View>
    </View>
  );
}
