import React from 'react';

export const GlobalContext = React.createContext({
  isLogin: false,
  setIsLogin: () => {},
  permissions: [],
  setPermissions: () => {},
});

export const useGlobalContext = () => React.useContext(GlobalContext);
