import React from 'react';
import Section from './section';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';

type Props = {
  data?: Object,
};

export default function Legal({ data }: Props) {
  const { projectLegals } = configs;

  if (!data?.legals?.length) {
    return null;
  }

  const legalsName = projectLegals
    ?.filter(item => data?.legals?.includes(item.key))
    ?.map(item => item.value)
    ?.join(', ');
  return (
    <Section
      title="Pháp lý"
      style={{ width: '100%' }}
      innerClass="mt-2"
      source="/svg/certificate.svg"
    >
      <div className="text-sm text-black-main">{legalsName}</div>
    </Section>
  );
}
