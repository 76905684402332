export const headerTableRealEstateEditHistoryConfigs = [
  {
    key: 'width',
    title: 'Ngang (m)',
    className: 'w-124px',
    path: '/width',
  },
  {
    key: 'length',
    title: 'Chiều dài (m)',
    className: 'w-124px',
    path: '/length',
  },
  {
    key: 'rear',
    title: 'Mặt hậu (m)',
    className: 'w-124px',
    path: '/rear',
  },
  {
    key: 'area',
    title: 'Diện tích (m²)',
    className: 'w-124px',
    path: '/area',
  },
  {
    key: 'price',
    title: 'Giá',
    className: 'w-154px',
    path: '/price',
  },
  {
    key: 'floorCount',
    title: 'Số tầng',
    className: 'w-133px',
    path: '/floorCount',
  },
  {
    key: 'constructionArea',
    title: 'Diện tích sàn (m²)',
    className: 'w-181px',
    path: '/constructionArea',
  },
  {
    key: 'bedroomCount',
    title: 'Số phòng ngủ',
    className: 'w-181px',
    path: '/bedroomCount',
  },
  {
    key: 'toiletCount',
    title: 'Số nhà vệ sinh',
    className: 'w-181px',
    path: '/toiletCount',
  },
  {
    key: 'direction',
    title: 'Hướng',
    className: 'w-181px',
    path: '/direction',
  },
  {
    key: 'isNewConstruction',
    title: 'Hiện trạng',
    className: 'w-181px',
    path: '/isNewConstruction',
  },
  {
    key: 'buildingType',
    title: 'Loại cao ốc',
    className: 'w-181px',
    path: '/buildingType',
  },
  {
    key: 'warehouseType',
    title: 'Chức năng nhà xưởng',
    className: 'w-181px',
    path: '/warehouseType',
  },
  {
    key: 'hotelStar',
    title: 'Số sao',
    className: 'w-181px',
    path: '/hotelStar',
  },
  {
    key: 'hasMezzanine',
    title: 'Có gác hay không',
    className: 'w-181px',
    path: '/hasMezzanine',
  },
  {
    key: 'postTitle',
    title: 'Tiêu đề tin rao',
    className: 'w-300px',
  },
  {
    key: 'postContent',
    title: 'Nội dung tin rao',
    className: 'w-300px',
  },
  {
    key: 'privateNote',
    title: 'Ghi chú cá nhân',
    className: 'w-300px',
    path: '/privateNote',
  },
  {
    key: 'fixedUtilities',
    title: 'Tiện ích cố định',
    className: 'w-181px',
    path: '/fixedUtilities',
  },
  {
    key: 'utilities',
    title: 'Tiện ích',
    className: 'w-181px',
    path: '/utilities',
  },
  {
    key: 'localUtilities',
    title: 'Tiện ích khu vực',
    className: 'w-181px',
    path: '/localUtilities',
  },
  {
    key: 'villaView',
    title: 'View',
    className: 'w-181px',
    path: '/villaView',
  },
  {
    key: 'equipmentsAndServices',
    title: 'Thiết bị, dịch vụ',
    className: 'w-181px',
    path: '/equipmentsAndServices',
  },
  {
    key: 'mediaUrls',
    title: 'Hình ảnh & video',
    className: 'w-181px',
    path: '/media',
  },
  {
    key: 'postType',
    title: 'Loại tin đăng',
    className: 'w-181px',
    path: '/postType',
  },
  {
    key: 'basicAmount',
    title: 'Số tiền phải trả',
    className: 'w-181px',
  },
  {
    key: 'dueDate',
    title: 'Thời hạn tin',
    className: 'w-181px',
  },
  {
    key: 'updatedAt',
    title: 'Ngày chỉnh sửa',
    className: 'w-181px',
  },
  {
    key: 'logCreator',
    title: 'Người chỉnh sửa',
    className: 'w-181px',
  },
];
