import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_MANAGEMENT_BY_FIELD } from '../../graphql/queries/realEstateB2c';
import { useLoadMore } from '../common/useLoadMore';
import { useState } from 'react';
import { cleanObj } from '../../helper';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';

export const pickByFromTo = (from, to) => {
  const floatFrom = parseFloat(from),
    floatTo = parseFloat(to);

  const t = pickBy(
    { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
    e => e
  );
  return isEmpty(t) ? null : t;
};
const getPriceFromToValue = (from, to, isForSale) => {
  const amount = isForSale === 'sale' ? 1000000000 : 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};
const excludedFields = [
  'country',
  'department',
  'pricePerMet',
  'provinceName',
  'districtName',
  'wardName',
  'houseNumber',
  'streetName',
  'approvedBy',
  'approvedAt',
];

export const getVariables = state => {
  const isForSale =
    state?.isForSell === 'ForSale' || state?.isForSell === 'ToBuy';
  const t = {
    realEstateId: state?.realEstateId,
    typeOfDemand: state?.isForSell,
    realEstateType: [0, isForSale ? 10 : 11].includes(
      state?.realEstateType?.length
    )
      ? null
      : state?.realEstateType,
    province: state?.province,
    district: state?.district,
    ward: state?.ward,
    street: state?.street,
    houseNumber: state?.houseNumber,
    width: pickByFromTo(
      state?.widthFrom,
      state?.widthTo > 100 ? null : state?.widthTo
    ),
    length: pickByFromTo(
      state?.lengthFrom,
      state?.lengthTo > 500 ? null : state?.lengthTo
    ),
    rear: pickByFromTo(
      state?.rearFrom,
      state?.rearTo > 100 ? null : state?.rearTo
    ),
    area: pickByFromTo(
      state?.areaFrom,
      state?.areaTo > 20000 ? null : state?.areaTo
    ),
    price: getPriceFromToValue(
      state?.priceFrom,
      (state?.priceTo > 100 && isForSale) ||
        (state?.priceTo > 200 && !isForSale)
        ? null
        : state?.priceTo,
      isForSale
    ),
    floorCount: pickByFromTo(
      state?.floorCountFrom,
      state?.floorCountTo > 168 ? null : state?.floorCountTo
    ),
    constructionArea: pickByFromTo(
      state?.constructionAreaFrom,
      state?.constructionAreaTo > 500 ? null : state?.constructionAreaTo
    ),
    isNewConstruction:
      state?.isNewConstruction?.length === 0 ||
      state?.isNewConstruction?.length === 2
        ? null
        : state?.isNewConstruction?.[0] === 'yes',
    createdAt:
      !state.createdAtFrom || !state.createdAtTo
        ? null
        : {
            from: moment.unix(state.createdAtFrom).format(),
            to: moment.unix(state.createdAtTo).endOf('day').format(),
          },
    updatedAt:
      !state.updatedAtFrom || !state.updatedAtTo
        ? null
        : {
            from: moment.unix(state.updatedAtFrom).format(),
            to: moment.unix(state.updatedAtTo).endOf('day').format(),
          },
    approvedAt:
      !state.approvedAtFrom || !state.approvedAtTo
        ? null
        : {
            from: moment.unix(state.approvedAtFrom).format(),
            to: moment.unix(state.approvedAtTo).endOf('day').format(),
          },
    approvedBy: state?.approvedBy ? state?.approvedBy : null,
  };
  return { ...cleanObj(t) };
};

export const useGetRealEstateManagement = ({
  fieldShowTable,
  state,
  params,
  tab,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const variables = getVariables(state);
  const finalVariables = {
    ...variables,
    ...params,
    page: 1,
    sort: tab.includes('needApproval') ? { updatedAt: 1 } : { approvedAt: -1 },
    limit: 10,
  };
  const { data, fetchMore, loading } = useQuery(
    GET_REAL_ESTATE_MANAGEMENT_BY_FIELD([
      ...(fieldShowTable.filter(e => !excludedFields.includes(e)) || []),
      'houseNumber',
      'street { id name }',
      'ward { id name }',
      'district { id name }',
      'province { id name }',
      'approvalStatus',
      'priceUnit',
      'isDeleted',
      'approvedBy { id fullName avatar { ...FileAllFields } }',
      'approvedAt',
      'postStatus',
    ]),
    {
      variables: {
        ...finalVariables,
      },
    }
  );
  const realEstates = data?.getRealEstateManagement?.items || [];
  const hasNextPage = data?.getRealEstateManagement?.hasNextPage;
  const page = data?.getRealEstateManagement?.page || 1;
  const total = data?.getRealEstateManagement?.total;
  const onLoadMore = useLoadMore({
    isLoadingMore,
    setIsLoadingMore,
    variables: finalVariables,
    fetchMore,
    currentPage: page,
    hasNextPage,
  });

  return {
    realEstates: loading ? [1, 2, 3] : realEstates || [],
    loading: loading,
    isLoadingMore,
    onLoadMore,
    hasNextPage,
    page,
    total,
  };
};
