import { headerTableRealEstateEditHistoryConfigs } from '../realEstateEditHistory/configs';
import HeaderTableRealEstateEditHistory from '../realEstateEditHistory/components/headerTableRealEstateEditHistory';
import React from 'react';
import { headerRenewRealEstateConfigs } from './configs';
import Table from '@components/table';
import { useGetRealEstateHistory } from '@utils/hooks/realEstateEditHistory/useGetRealEstateHistory';
import TableRow from './tableRow';
import SeeMore from '@components/seeMore';
import { useNeedApprovalContext } from '../../needApproval/context';

type Props = {
  demand?: string,
  isPremium?: string,
};

export default function RenewRealEstateHistory(props: Props) {
  const { demand, isPremium } = props;
  const { listSelected } = useNeedApprovalContext();
  let type = demand === 'buy' ? 'demand' : 'product';
  isPremium === 'PremiumRealEstate' ? (type = 'premium') : type;
  const isNeedRealEstate = type === 'demand';
  const headerRealEstateConfigs = isNeedRealEstate
    ? headerTableRealEstateEditHistoryConfigs.filter(e => e.key !== 'mediaUrls')
    : headerTableRealEstateEditHistoryConfigs;

  const headerTableConfigs = [
    ...headerRenewRealEstateConfigs,
    ...headerRealEstateConfigs.filter(e => e.key !== 'logCreator'),
    {
      key: 'logCreator',
      title: 'Người gia hạn',
      className: 'w-181px',
    },
  ];

  const { data, onLoadMore, hasNextPage, loading } = useGetRealEstateHistory({
    realEstateId: listSelected[0],
    params: {
      type,
      historyType: 'renewHistory',
    },
  });

  return (
    <div className="w-full h-full overflow-hidden table table-fixed">
      <Table>
        <div className="flex flex-col flex-1">
          <HeaderTableRealEstateEditHistory
            headerTableConfigs={headerTableConfigs}
          />
          {data.map((item, index) => {
            return (
              <TableRow
                total={data?.length}
                type={type}
                key={`row-${index}`}
                item={item}
                index={index}
                headerTableConfigs={headerTableConfigs}
              />
            );
          })}
          {!loading && !data?.length && (
            <p className="text-black-main text-base m-3">
              Không có lịch sử gia hạn
            </p>
          )}
        </div>
      </Table>
      <SeeMore
        hasNextPage={hasNextPage}
        loading={loading}
        onLoadMore={onLoadMore}
      />
    </div>
  );
}
