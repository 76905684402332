import MyModalV2 from '@components/modalV2/myModalV2';
import MyButton from '@components/button';
import { useNavigate } from 'react-router-dom';

type Props = {
  open?: boolean,
  onClose?: Function,
  isEdit?: boolean,
  onContinue?: Function,
};
export default function CreateRealEstateSuccessModal({
  open,
  onClose,
  isEdit,
  onContinue,
}: Props) {
  const navigate = useNavigate();
  return (
    <MyModalV2 hideClose open={open} onClose={onClose}>
      <div className="bg-white w-450px max-w-95% rounded-lg p-6 flex flex-col justify-center items-center w-full h-full space-y-6">
        <div className="w-full flex justify-center">
          <img className="w-60px h-60px" alt="tick" src="/svg/success.svg" />
        </div>
        <div className="flex flex-1 flex-col items-center space-y-4">
          <div
            style={{ color: '#40D124' }}
            className="text-2xl font-medium text-center"
          >
            {isEdit
              ? `Cập nhật sản phẩm thành công !`
              : `Thêm sản phẩm thành công !`}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `Sản phẩm sẽ cần quản lý của bạn duyệt trước khi xuất hiện <br/>
                 trong kho sản phẩm`,
            }}
            className="text-sm text-black-main text-center"
          />
        </div>
        <div className="w-full flex space-x-4">
          <MyButton
            onPress={() => {
              onClose();
              navigate('/manageProduct/product');
            }}
            type="gray"
            label="Xem kho sản phẩm"
          />
          {!isEdit && (
            <MyButton
              onPress={() => {
                onClose();
                onContinue();
              }}
              type="blue"
              label="Tiếp tục đăng tin khác"
            />
          )}
        </div>
      </div>
    </MyModalV2>
  );
}
