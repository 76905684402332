import { createContext, useContext } from 'react';

export const PermissionGroupsContext = createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  showModal: false,
  setShowModal: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
  id: '',
  setId: () => {},
  input: '',
  setInput: () => {},
});

export const usePermissionGroupsContext = () =>
  useContext(PermissionGroupsContext);
