import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import React from 'react';
type Props = {
  data?: Object,
};
export default function RenderNote({ data }: Props) {
  const { productPosted } = useGetTab();
  return data?.privateNote ? (
    <div>
      <div className="w-full rounded-lg p-4 space-y-2.5 border border-gray-300 bg-yellow-note">
        <div className="text-base font-medium text-black-main">Ghi chú</div>
        <div className="flex flex-col mt-4 flex-1">{data?.privateNote}</div>
      </div>
      {productPosted && (
        <div className="mt-4 text-14 flex">
          <img src="/svg/privateNote.svg" className="mr-2" />
          Ghi chú cá nhân sẽ không được đăng lên sàn
        </div>
      )}
    </div>
  ) : null;
}
