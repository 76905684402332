import styles from '@components/table/table.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import permissionGroupsStyles from './permissionGroups.module.scss';
import { checkVisibleCol } from './renderHeader';

RenderTag.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  removeValue: PropTypes.func,
};
export function RenderTag({ value, removeValue }) {
  const { setListSelected } = usePermissionGroupsContext();

  return value && value.length ? (
    <div className={permissionGroupsStyles.tagFilterResult}>
      <div className="text-13 truncate text-black-main">
        {Array.isArray(value) ? value.join(', ') : value}
      </div>
      {removeValue && (
        <img
          onClick={() => {
            setListSelected?.clear();
            removeValue();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  ) : null;
}

export default function RenderResultFilterHeader() {
  const { stateFilter, setStateFilterAt } = usePermissionGroupsContext();

  const { code, name, createdAtFrom, createdAtTo, creator } = stateFilter;

  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        {checkVisibleCol('code') && (
          <div
            className={`${styles.headContainer} flex-1 items-center truncate`}
          >
            <RenderTag
              value={code}
              removeValue={() => setStateFilterAt('code')('')}
            />
          </div>
        )}
        {checkVisibleCol('name') && (
          <div
            className={`${styles.headContainer} flex-1 items-center truncate`}
          >
            <RenderTag
              value={name}
              removeValue={() => setStateFilterAt('name')('')}
            />
          </div>
        )}
        {checkVisibleCol('creator') && (
          <div
            className={`${styles.headContainer} flex-1 items-center truncate`}
          >
            <RenderTag
              value={creator}
              removeValue={() => setStateFilterAt('creator')('')}
            />
          </div>
        )}
        {checkVisibleCol('createdAt') && (
          <div
            className={`${styles.headContainer} flex-1 items-center truncate`}
          >
            <RenderTag
              value={
                createdAtFrom && createdAtTo
                  ? `${moment
                      .unix(createdAtFrom)
                      .format('DD/MM/YYYY')} - ${moment
                      .unix(createdAtTo)
                      .format('DD/MM/YYYY')}`
                  : 'Tất cả'
              }
              removeValue={() => {
                setStateFilterAt('createdAtFrom')('');
                setStateFilterAt('createdAtTo')('');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
