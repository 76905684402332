import styles from './confirmModal.module.scss';
import MyButton from '@components/button';

type Props = {
  onContinue?: Function,
  onBack?: Function,
  loading?: boolean,
  question?: string,
  contentTop?: string,
  contentBottom?: string,
};

export default function ConfirmModal({
  onContinue,
  onBack,
  loading,
  question,
  contentTop,
  contentBottom,
}: Props) {
  return (
    <div className={styles.confirmModal}>
      <img
        className="w-54px h-54px mt-5"
        src="/svg/questions-light.svg"
        alt="icon"
      />
      <div className="text-24 font-bold text-blue-main mt-7">{question}</div>
      <div
        dangerouslySetInnerHTML={{ __html: contentTop }}
        className="mt-3 text-sm text-center"
      />
      <div className="text-sm">{contentBottom}</div>
      <div className="flex w-full space-x-4 mt-6">
        <MyButton
          label="Thoát"
          type="gray"
          wrapperClass="w-full"
          onPress={onBack}
        />
        <MyButton
          disabled={loading}
          type="blue"
          label="Tiếp tục"
          wrapperClass="w-full"
          onPress={onContinue}
        />
      </div>
    </div>
  );
}
