import React from 'react';
import { Outlet } from 'react-router-dom';
import { View } from 'react-native-web';

export default function ManageEmployee() {
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Outlet />
    </View>
  );
}
