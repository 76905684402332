import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/label';
import MyInput from '@components/myInput';
import ProvinceDropdownSelection from '@components/addressDropdownGroup/provinceDropdownSelection';
import DistrictDropdownSelection from '@components/addressDropdownGroup/districtDropdownSelection';
import React, { useCallback, useState } from 'react';
import RadioButton from '@components/radio';
import { genders } from '../configs';
import SingleCalendar from '@components/singleCalendar';
import UploadPanel from '@components/uploadPanel';
import ChangeAvatar from '@components/changeAvatar';
import {
  EMAIL_PATTERN,
  regexValidateIdNumber,
  regexValidateName,
} from '@utils/configs/regex';
import { validateBirthDate18Age } from '@utils/helper';
import moment from 'moment/moment';
import UploadErrorModal from '../../../../modals/uploadError';
import Checkbox from '@components/checkbox';
const fieldConditionConfigs = {
  fullName: {
    dataVisible: state => state?.fullName?.length > 0,
  },
  idNumber: {
    dataVisible: state => state.idNumber?.length > 0,
  },
  province: {
    dataVisible: state => state?.province?.length > 0,
  },
  district: {
    dataVisible: state => state?.district?.length > 0,
  },
  avatar: {
    dataVisible: state => state?.avatar,
  },
  phone: {
    dataVisible: state => state?.phone?.length > 0,
  },
  secondPhone: {
    dataVisible: state => state?.secondPhone?.length > 0,
  },
  email: {
    dataVisible: state => state?.email?.length > 0,
  },
  gender: {
    dataVisible: state => state?.gender?.length > 0,
  },
  dateOfBirth: {
    dataVisible: state => state?.dateOfBirth,
  },
  idImages: {
    dataVisible: state => state?.idImages?.length > 0,
  },
  isLeader: {
    dataVisible: state => state?.isLeader,
  },
};
const getTotalFieldCount = props => {
  const field = [
    'fullName',
    'idNumber',
    'province',
    'district',
    'phone',
    'secondPhone',
    'email',
    'gender',
    'dateOfBirth',
    'idImages',
    'avatar',
    'isLeader',
  ];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
type Props = {
  isEdit?: boolean,
};
export default function LeftEmployeeInfo({ isEdit }: Props) {
  const { control, watch, setValue } = useFormContext();
  const province = watch('province');
  const district = watch('district');
  const fullName = watch('fullName');
  const avatar = watch('avatar');
  const idNumber = watch('idNumber');
  const gender = watch('gender');
  const phone = watch('phone');
  const secondPhone = watch('secondPhone');
  const dateOfBirth = watch('dateOfBirth');
  const idImages = watch('idImages');
  const email = watch('email');
  const isLeader = watch('isLeader');
  const wrapperClass = 'w-230px',
    inputClass = 'w-full flex-1',
    data = {
      province,
      district,
      avatar,
      gender,
      dateOfBirth,
      fullName,
      idImages,
      idNumber,
      phone,
      secondPhone,
      email,
      isLeader,
    };
  const [showErrModal, setShowErrModal] = useState(false);
  const onCheck = useCallback(() => {
    setValue('isLeader', !isLeader);
  }, [isLeader]);
  return (
    <div className="flex flex-1 rounded-lg px-4 py-5 border border-gray-300 space-x-6">
      <div className="w-full space-y-6 ">
        <div className="font-medium text-base text-blue-main">
          Thông tin cá nhân ({getTotalFieldCount(data).fieldVisibleCount}/
          {Object.keys(data)?.length})
        </div>
        <UploadErrorModal
          isOpen={showErrModal}
          onClose={() => setShowErrModal(false)}
          type="img"
          singleUpload={true}
        />
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Ảnh đại diện" />
          <div className="flex-1 flex items-center justify-center">
            <Controller
              control={control}
              name="avatar"
              render={({ field: { value, onChange } }) => {
                return (
                  <ChangeAvatar
                    setShowErrModal={setShowErrModal}
                    avatarId={value?.fileId}
                    setAvatar={onChange}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass={wrapperClass}
            label="Họ và tên"
            isRequired={true}
          />
          <Controller
            rules={{
              required: 'Vui lòng nhập họ và tên',
              validate: {
                checkName: value =>
                  value?.trim()?.match(regexValidateName)
                    ? true
                    : 'Họ và tên không hợp lệ',
              },
            }}
            control={control}
            name="fullName"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  maxLength={118}
                  hideMaxLength={true}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập họ tên"
                  onDelete={() => onChange('')}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass="w-230px" label="" />
          <Controller
            control={control}
            name="isLeader"
            render={() => {
              return (
                <Checkbox
                  title="Người đứng đầu"
                  isChecked={isLeader}
                  onPress={() => onCheck()}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass="w-230px"
            label="Tỉnh/thành phố"
            isRequired={true}
          />
          <Controller
            rules={{
              required: 'Vui lòng chọn Tỉnh/thành phố',
            }}
            control={control}
            name="province"
            render={({ field: { value, onChange } }) => {
              return (
                <div className={inputClass}>
                  <ProvinceDropdownSelection
                    value={value}
                    setValue={value => {
                      onChange(value);
                      setValue('district', '');
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass="w-230px" label="Quận/huyện" isRequired={true} />
          <Controller
            rules={{
              required: 'Vui lòng chọn quận/huyện',
            }}
            control={control}
            name="district"
            render={({
              field: { value, onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <div className={inputClass}>
                  <DistrictDropdownSelection
                    key={Math.random()}
                    onDelete={() => {
                      onChange('');
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                    value={value}
                    setValue={value => {
                      onChange(value);
                      setValue('ward', '');
                      setValue('street', '');
                    }}
                    errorMessage={error?.message}
                    invalid={invalid}
                    provinceId={province}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass={wrapperClass}
            label="Số điện thoại 1"
            isRequired={true}
          />
          <Controller
            rules={{
              required: 'Vui lòng nhập số số điện thoại 1',
              validate: {
                checkPhone: value =>
                  value?.toString()?.match(/^0([35789])[0-9]{8}$/)
                    ? true
                    : 'Vui lòng nhập số điện thoại đúng định dạng',
                checkDuplicate: value =>
                  value === secondPhone
                    ? 'Số điện thoại không được trùng nhau'
                    : true,
              },
            }}
            control={control}
            name="phone"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập số điện thoại 1"
                  onDelete={() => onChange('')}
                  maxLength={10}
                  hideMaxLength
                  type="number"
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Số điện thoại 2" />
          <Controller
            rules={{
              validate: {
                checkPhone: value =>
                  value?.toString()?.match(/^0([35789])[0-9]{8}$/)
                    ? true
                    : value?.length > 0
                    ? 'Vui lòng nhập số điện thoại đúng định dạng'
                    : true,
                checkDuplicate: value =>
                  value?.length > 0 && phone?.length > 0 && value === phone
                    ? 'Số điện thoại không được trùng nhau'
                    : true,
              },
            }}
            control={control}
            name="secondPhone"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập số điện thoại 2"
                  onDelete={() => onChange('')}
                  maxLength={10}
                  hideMaxLength
                  type="number"
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Email" isRequired={true} />
          <Controller
            rules={{
              required: 'Vui lòng nhập email',
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Vui lòng nhập email đúng định dạng',
              },
            }}
            control={control}
            name="email"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  maxLength={68}
                  hideMaxLength={true}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập email (ví dụ: info@realtech247.com)"
                  onDelete={() => onChange('')}
                />
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass={wrapperClass}
            label="Giới tính"
            isRequired={true}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field: { value, onChange } }) => {
              return (
                <div className={`${inputClass} space-y-2`}>
                  <div className="flex flex-row space-x-8">
                    {genders.map((sex, index) => {
                      return (
                        <RadioButton
                          key={index}
                          title={sex.value}
                          isChecked={sex.key === value}
                          onPress={() => onChange(sex.key)}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Ngày sinh" />
          <Controller
            rules={{
              validate: {
                checkAge: value =>
                  validateBirthDate18Age(moment.unix(value))
                    ? true
                    : 'Vui lòng chọn ngày sinh trên 18 tuổi',
              },
            }}
            control={control}
            name="dateOfBirth"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className={inputClass}>
                  <SingleCalendar
                    onDelete={() => onChange('')}
                    value={value}
                    setValue={nextValue => onChange(nextValue)}
                    errorMessage={error?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass={wrapperClass} label="Số CMND/CCCD" />
          <Controller
            rules={{
              validate: {
                checkIdNumber: value => {
                  return value?.length === 0
                    ? true
                    : value?.toString()?.match(regexValidateIdNumber)
                    ? true
                    : 'Vui lòng nhập số CMND/CCCD hợp lệ';
                },
              },
            }}
            control={control}
            name={'idNumber'}
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập số CMND/CCCD"
                  onDelete={() => onChange('')}
                  maxLength={12}
                  hideMaxLength
                  type="number"
                />
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="idImages"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full space-y-4">
                <Label
                  wrapperClass={wrapperClass}
                  label="Hình ảnh 2 mặt CMND"
                />
                <UploadPanel
                  isEdit={isEdit}
                  onChange={onChange}
                  mediaUrls={value}
                />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
