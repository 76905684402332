import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/label';
import DropdownSelection from '@components/dropdownSelection';
import MyInput from '@components/myInput';
import { useQuery } from '@apollo/client';
import { LIST_DEPARTMENTS } from '@utils/graphql/queries/department';
const fieldConditionConfigs = {
  departmentId: {
    dataVisible: state => state?.departmentId?.length > 0,
  },
  position: {
    dataVisible: state => state?.position?.length > 0,
  },
};
const getTotalFieldCount = props => {
  const field = ['departmentId', 'position', 'approveDepartment'];
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};

export default function DepartmentInfo() {
  const { control, watch, setValue } = useFormContext();
  const departmentId = watch('departmentId');
  const position = watch('position'),
    data = {
      departmentId,
      position,
    };
  const { data: departments } = useQuery(LIST_DEPARTMENTS);

  return (
    <div className="flex flex-1 rounded-lg px-4 py-5 border border-gray-300 space-x-6">
      <div className="w-full space-y-6 ">
        <div className="font-medium text-base text-blue-main">
          Thông tin phòng ban ({getTotalFieldCount(data).fieldVisibleCount}/
          {Object.keys(data)?.length})
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label
            wrapperClass="w-230px"
            label="Nhân viên thuộc phòng ban"
            isRequired={true}
          />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              rules={{
                required: 'Vui lòng chọn phòng ban',
              }}
              name="departmentId"
              render={({
                field: { value, onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <div className="w-full">
                    <DropdownSelection
                      hasItemRadio={false}
                      placeholder="Chọn phòng ban"
                      selections={departments?.b2bGetListDepartments?.map(e => {
                        return { key: e.id, value: e.name };
                      })}
                      value={value}
                      setValue={value => {
                        onChange(value);
                      }}
                      onDelete={() => {
                        onChange('');
                        setValue('departmentId', '');
                      }}
                      errorMessage={error?.message}
                      invalid={invalid}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass="w-230px" label="Chức vụ nhân viên" />
          <div className="w-full flex flex-1 space-x-4">
            <Controller
              control={control}
              name="position"
              render={({
                field,
                field: { onChange },
                fieldState: { invalid, error },
              }) => {
                return (
                  <MyInput
                    {...field}
                    maxLength={62}
                    hideMaxLength={true}
                    inputClassName="w-full"
                    invalid={invalid}
                    errorMessage={error?.message}
                    placeholder="Nhập chức vụ"
                    onDelete={() => onChange('')}
                    hideDelete={false}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
