import React from 'react';
import MyInput from '@components/myInput';
import useToggle from 'react-use/lib/useToggle';
import type { InputProps } from '@components/myInput';

type Props = {} & InputProps;

export default function PasswordInput({ ...rest }: Props) {
  const [hide, setHide] = useToggle(true);

  const src = hide ? '/svg/eye-close-icon.svg' : '/svg/eye.svg';
  return (
    <MyInput
      {...rest}
      type={hide ? 'password' : 'string'}
      renderRightComponent={() => (
        <button
          onClick={setHide}
          className="border-l border-gray-300"
          style={{ paddingLeft: 5 }}
        >
          <img alt="ico" src={src} className="w-24px h-24px" />
        </button>
      )}
    />
  );
}
