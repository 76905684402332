import { useLocation } from 'react-router-dom';
import { RouterLocation } from 'typeDefinition';
export const useGetTab = () => {
  const { pathname }: RouterLocation = useLocation();
  const product = pathname === '/manageProduct/product';
  const myProduct = pathname.includes('my-product');
  const productPosted = pathname.includes('roduct-posted');
  const waitingApproval = pathname.includes('waiting-for-approval');
  const productHidden = pathname.includes('hidden');
  const productDeleted = pathname.includes('deleted');
  const productDetails = pathname.includes('/manageProduct/details/');
  const rejectedDetails = pathname.includes('/manageProduct/product/rejected');
  return {
    rejectedDetails,
    product,
    myProduct,
    productPosted,
    waitingApproval,
    productHidden,
    productDeleted,
    productDetails,
  };
};
