export const regexValidationPassword =
  /^(?=.*[a-zA-Z])([a-zA-Z0-9!#$%'()+,.\-/:?@[\\\]^_`{}~]+){6,32}$/;
export const regexValidateName =
  /^[a-zA-Z_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\s]+$/;
export const regexValidationCompanyName =
  /^[a-zA-Z0-9_àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\s]+$/;
export const regexValidateIdNumber = /^(\d{9}|\d{12})$/;
export const regexValidateTaxId = /^(\d{10,15})$/;
export const regexValidatePhoneNumber = /^0([35789])[0-9]{8}$/;
export const regexValidateFacebook =
  /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)$/;
export const regexValidatePostTitle = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
export const regexHouseNumber = /^([A-Z0-9]+\/{0,1}){0,10}[A-Z0-9]+$/;
export const regexCoordinate =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,32}$/;
export const regexValidPassword = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{6,32}$/;
export const EMAIL_PATTERN = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const LATITUDE_PATTERN = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
export const LONGITUDE_PATTERN =
  /[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9]+$/;
