import React from 'react';
import useMeasure from 'react-use/lib/useMeasure';

type Props = {
  header?: string,
  message?: string | Function,
  type: 'success' | 'error' | 'warning' | 'notification',
  wrapperStyle?: string,
  isShowClose?: boolean,
  closeButtonPos: 'top' | 'center',
  onClose?: Function,
  id?: string,
};

export default function MyToast(props: Props) {
  let { header } = props;
  const {
    message,
    type = 'success',
    wrapperStyle,
    isShowClose = true,
    closeButtonPos,
    onClose,
    id,
  } = props;
  let icon,
    color = '',
    closePost = '',
    bgColor = '';
  switch (closeButtonPos) {
    case 'top':
      closePost = 'top-1 right-1';
      break;
    case 'center':
    default:
      closePost = 'right-1';
  }
  switch (type) {
    case 'success':
      icon = 'successful.svg';
      color = '#40d124';
      header = 'Thành công!';
      bgColor = '#E6FEE1';
      break;
    case 'error':
      icon = 'infoRed.svg';
      color = '#ff0000';
      bgColor = '#fcb1bc';
      header = 'Thất bại!';
      break;
    case 'warning':
      icon = 'warning.svg';
      color = '#ffcc00';
      bgColor = '#fcd9b1';
      header = 'Cảnh báo!';
      break;
  }
  const renderMessage =
      typeof message === 'function' ? (
        message()
      ) : (
        <p style={{ color: '#474747', maxWidth: 240 }} className="text-sm">
          {message}
        </p>
      ),
    renderHeader = (
      <p className="w-full text-black font-medium text-sm">{header}</p>
    );
  const [measureRef, { height }] = useMeasure();

  return (
    <div
      ref={measureRef}
      className={`w-full flex items-center space-x-2 rounded-md ${wrapperStyle}`}
      style={{ minHeight: 69, backgroundColor: bgColor, width: 320 }}
    >
      <div
        className={`w-5px rounded-l-md overflow-hidden`}
        style={{ backgroundColor: color, height, border: '5px 0 0 5px' }}
      />
      <div
        className="flex flex-1 items-center justify-between pr-2"
        style={{
          minHeight: height,
        }}
      >
        <div className="flex flex-row flex-1 items-center space-x-2">
          <img
            alt="icon"
            className="w-6 h-6 space-x-2 object-cover"
            src={`/svg/${icon}`}
          />
          <div style={{ maxWidth: 240 }} className="flex flex-col items-center">
            {renderHeader}
            {renderMessage}
          </div>
        </div>
        {isShowClose && (
          <svg
            onClick={() => onClose(id)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className={`h-5 w-5 text-gray-400 hover:text-gray-500 ml-4 cursor-pointer ${closePost}`}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
      </div>
    </div>
  );
}
