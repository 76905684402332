import { PAGINATION_MAX_PAGE_VALID } from 'constant';

export const useLoadByPagination = async ({
  loading,
  setLoading,
  currentPage,
  fetchMore,
  variables = {},
}) => {
  if (loading) {
    return;
  }
  await setLoading(true);
  await fetchMore({
    variables: {
      ...variables,
      page: currentPage <= PAGINATION_MAX_PAGE_VALID ? currentPage : 1,
    },
    updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
  });
  await setLoading(false);
};
