import Section from './section';

type Props = {
  data?: Object,
};

export default function PostInfo({ data }: Props) {
  return (
    <div className="space-y-8">
      <Section
        source="/svg/title.svg"
        title="Tiêu đề tin rao"
        innerClass="py-2 border-b border-gray-300"
      >
        <div className="text-sm text-black-main">{data?.postTitle}</div>
      </Section>
      <Section
        source="/svg/mdi-light_content-paste.svg"
        title="Nội dung tin rao"
        innerClass="py-2 border-b border-gray-300"
      >
        <div className="text-sm text-black-main">{data?.postContent}</div>
      </Section>
    </div>
  );
}
