import Label from '@components/label';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyInput from '@components/myInput';
import ProvinceDropdownSelection from '@components/addressDropdownGroup/provinceDropdownSelection';
import DistrictDropdownSelection from '@components/addressDropdownGroup/districtDropdownSelection';
import WardDropdownSelection from '@components/addressDropdownGroup/wardDropdownSelection';
import StreetDropdownSelection from '@components/addressDropdownGroup/streetDropdownSelection';
import ChangeAvatar from '@components/changeAvatar';
import { omit } from 'lodash';
import {
  regexValidateName,
  regexValidateTaxId,
  regexValidationCompanyName,
} from '@utils/configs/regex';
import UploadErrorModal from '../../../../../modals/uploadError';
export const fieldConditionConfigs = {
  companyName: {
    dataVisible: state => state?.companyName?.length > 0,
  },
  taxIdOrIdNumber: {
    dataVisible: state =>
      !state?.isPersonal && state.taxIdOrIdNumber?.length > 0,
  },
  companyProvince: {
    dataVisible: state => state?.companyProvince?.length > 0,
  },
  companyDistrict: {
    dataVisible: state => state?.companyDistrict?.length > 0,
  },
  companyWard: {
    dataVisible: state => state?.companyWard?.length > 0,
  },
  companyStreet: {
    dataVisible: state => state?.companyStreet?.length > 0,
  },
  companyHouseNumber: {
    dataVisible: state => state?.companyHouseNumber?.length > 0,
  },
  logo: {
    dataVisible: state => state?.logo,
  },
};
let field = [
  'companyName',
  'taxIdOrIdNumber',
  'companyProvince',
  'companyDistrict',
  'companyWard',
  'companyStreet',
  'companyHouseNumber',
  'logo',
];
export const getTotalFieldCount = props => {
  let fieldCount = 0,
    fieldVisibleCount = 0;
  field.forEach(item => {
    if (
      fieldConditionConfigs[item]?.dataVisible &&
      fieldConditionConfigs[item]?.dataVisible(props)
    ) {
      fieldVisibleCount += 1;
    }
  });
  return { fieldCount, fieldVisibleCount };
};
export default function CustomerInfoInput() {
  const { control, watch, setValue } = useFormContext();
  const isPersonal = watch('isPersonal');
  const companyProvince = watch('companyProvince');
  const companyDistrict = watch('companyDistrict');
  const companyWard = watch('companyWard');
  const companyStreet = watch('companyStreet');
  const companyHouseNumber = watch('companyHouseNumber');
  const logo = watch('logo');
  const taxIdOrIdNumber = watch('taxIdOrIdNumber');
  const companyName = watch('companyName');
  const wrapperClass = 'w-230px',
    data = {
      companyName,
      companyProvince,
      companyDistrict,
      companyWard,
      companyStreet,
      companyHouseNumber,
      logo,
      taxIdOrIdNumber,
    },
    inputClass = 'w-full flex-1';
  const [showErrModal, setShowErrModal] = useState(false);
  return (
    <div className="w-full space-y-6 ">
      <div className="font-medium text-base text-blue-main">
        Thông tin khách hàng (
        {
          getTotalFieldCount(isPersonal ? omit(data, 'taxIdOrIdNumber') : data)
            .fieldVisibleCount
        }
        /{isPersonal ? 7 : 8})
      </div>
      <UploadErrorModal
        isOpen={showErrModal}
        onClose={() => setShowErrModal(false)}
        type="img"
        singleUpload={true}
      />
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass={wrapperClass} label="Ảnh đại diện" />
        <div className="w-full flex-1 flex items-center justify-center">
          <Controller
            control={control}
            name="logo"
            render={({ field: { value, onChange } }) => {
              return (
                <ChangeAvatar
                  setShowErrModal={setShowErrModal}
                  avatarId={value?.fileId}
                  setAvatar={onChange}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass={wrapperClass}
          label={isPersonal ? 'Tên khách hàng' : 'Tên doanh nghiệp'}
          isRequired={true}
        />
        <Controller
          rules={{
            required: isPersonal
              ? 'Vui lòng nhập tên khách hàng'
              : 'Vui lòng nhập tên doanh nghiệp',
            validate: {
              checkCompanyName: value =>
                isPersonal
                  ? value?.trim()?.match(regexValidateName)
                    ? true
                    : 'Tên khách hàng không hợp lệ'
                  : value?.trim()?.match(regexValidationCompanyName)
                  ? true
                  : 'Tên doanh nghiệp không hợp lệ',
            },
          }}
          control={control}
          name="companyName"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder={
                  isPersonal ? 'Nhập tên khách hàng' : 'Nhập tên doanh nghiệp'
                }
                onDelete={() => onChange('')}
                maxLength={118}
                hideMaxLength
              />
            );
          }}
        />
      </div>
      {!isPersonal && (
        <div className="w-full flex flex-row items-start space-x-2.5">
          <Label wrapperClass="w-230px" label="Mã số thuế" isRequired={true} />
          <Controller
            rules={{
              validate: {
                checkTaxId: value =>
                  value?.toString()?.match(regexValidateTaxId)
                    ? true
                    : 'Mã số thuế không hợp lệ',
                require: value =>
                  !isPersonal && value?.length === 0
                    ? 'Vui lòng nhập mã số thuế'
                    : true,
              },
            }}
            control={control}
            name="taxIdOrIdNumber"
            render={({
              field,
              field: { onChange },
              fieldState: { invalid, error },
            }) => {
              return (
                <MyInput
                  {...field}
                  invalid={invalid}
                  errorMessage={error?.message}
                  inputClassName={inputClass}
                  placeholder="Nhập mã số thuế"
                  onDelete={() => onChange('')}
                  maxLength={15}
                  minLength={10}
                  hideMinLength
                  hideMaxLength
                  type="number"
                />
              );
            }}
          />
        </div>
      )}
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label
          wrapperClass="w-230px"
          label="Tỉnh/thành phố"
          isRequired={true}
        />
        <Controller
          rules={{
            required: 'Vui lòng chọn Tỉnh/thành phố',
          }}
          control={control}
          name="companyProvince"
          render={({ field: { value, onChange } }) => {
            return (
              <div className={inputClass}>
                <ProvinceDropdownSelection
                  key={Math.random()}
                  value={value}
                  setValue={value => {
                    onChange(value);
                    setValue('companyDistrict', '');
                    setValue('companyWard', '');
                    setValue('companyStreet', '');
                  }}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass="w-230px" label="Quận/huyện" isRequired={true} />
        <Controller
          rules={{
            required: 'Vui lòng chọn quận/huyện',
          }}
          control={control}
          name="companyDistrict"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <div className={inputClass}>
                <DistrictDropdownSelection
                  key={Math.random()}
                  onDelete={() => {
                    onChange('');
                    setValue('companyWard', '');
                    setValue('companyStreet', '');
                  }}
                  value={value}
                  setValue={value => {
                    onChange(value);
                    setValue('companyWard', '');
                    setValue('companyStreet', '');
                  }}
                  errorMessage={error?.message}
                  invalid={invalid}
                  provinceId={companyProvince}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass="w-230px" label="Phường/xã" isRequired={true} />
        <Controller
          rules={{
            required: 'Vui lòng chọn phường/xã',
          }}
          control={control}
          name="companyWard"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <div className={inputClass}>
                <WardDropdownSelection
                  key={Math.random()}
                  disabled={!companyDistrict}
                  onDelete={() => {
                    onChange('');
                    setValue('companyStreet', '');
                  }}
                  value={value}
                  setValue={value => {
                    onChange(value);
                  }}
                  errorMessage={error?.message}
                  invalid={invalid}
                  districtId={companyDistrict}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass="w-230px" label="Đường" isRequired={true} />
        <Controller
          rules={{
            required: 'Vui lòng chọn đường',
          }}
          control={control}
          name="companyStreet"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <div className={inputClass}>
                <StreetDropdownSelection
                  key={Math.random()}
                  disabled={!companyDistrict}
                  onDelete={() => {
                    onChange('');
                  }}
                  value={value}
                  setValue={value => {
                    onChange(value);
                  }}
                  errorMessage={error?.message}
                  invalid={invalid}
                  districtId={companyDistrict}
                  wardId={companyWard}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="w-full flex flex-row items-start space-x-2.5">
        <Label wrapperClass={wrapperClass} label="Số nhà" isRequired={true} />
        <Controller
          rules={{
            required: 'Vui lòng nhập số nhà',
          }}
          control={control}
          name="companyHouseNumber"
          render={({
            field,
            field: { onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <MyInput
                {...field}
                invalid={invalid}
                errorMessage={error?.message}
                inputClassName={inputClass}
                placeholder="Nhập số nhà"
                onDelete={() => onChange('')}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
