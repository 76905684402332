import styles from '../approvalRealEstateModal/approvalRealEstateModal.module.scss';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native-web';
import { reasonConfigs } from './config';
import MyButton from '@components/button';
import RadioButton from '@components/radio';
import MyTextAreaInput from '@components/textAreaInput';
import { SlideFade } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { APPROVE_OR_REJECT_REAL_ESTATE_PRODUCT } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';
import { resetQueries } from '@utils/helper';
import { useProductContext } from '../../scenes/home/manageProduct/product/context';
type Props = {
  onClose?: Function,
  status?: string,
  realEstateIds?: Array,
  type?: string,
};
export default function RejectedRealEstateProductModal({
  onClose: close,
  status,
  realEstateIds,
  type = 'product',
}: Props) {
  const { setListSelected } = useProductContext();
  const toast = useToast();
  const configs = reasonConfigs.reject;
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [approvalMutation, { loading, client }] = useMutation(
    APPROVE_OR_REJECT_REAL_ESTATE_PRODUCT
  );

  const onSubmit = useCallback(async () => {
    try {
      await approvalMutation({
        variables: {
          productsIds: realEstateIds,
          approveStatus: {
            status: 'rejected',
            reason:
              reason === 'other'
                ? otherReason
                : configs?.find(e => e.key === reason)?.value,
            reasonType: reason,
          },
        },
      });
      await resetQueries(['getInternalRealEstates'], client.cache);
      await close();
      toast({
        position: 'bottom-right',
        duration: 5000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            wrapperStyle="w-394px shadow-md"
            type="success"
            message={'Từ chối tin rao thành công'}
            closeButtonPos="center"
          />
        ),
      });
      await setListSelected.set([]);
    } catch (e) {
      toast({
        position: 'bottom-right',
        duration: 5000,
        render: ({ id, onClose }) => (
          <MyToast
            id={id}
            onClose={onClose}
            type="error"
            message={'Từ chối tin rao thất bại'}
            closeButtonPos="center"
          />
        ),
      });
    }
  }, [
    approvalMutation,
    otherReason,
    realEstateIds,
    type,
    status,
    reason,
    setListSelected,
  ]);
  return (
    <View className={styles.modalContainer}>
      <div className="w-full text-base font-medium text-blue-main pt-6 px-5">
        {status === 'approved'
          ? 'Lý do đồng ý duyệt tin đăng'
          : 'Lý do từ chối'}
      </div>
      <div className="px-6 pb-7 mt-3">
        <div className="flex flex-col space-y-3.5">
          <div className="text-sm">
            {status === 'approved'
              ? 'Vui vòng nhập lý do bạn đồng ý duyệt BĐS'
              : 'Vui vòng nhập lý do bạn từ chối để người dùng có thể cập nhật lại thông tin cho phù hợp'}
          </div>
          <div className="flex flex-col space-y-4">
            {configs?.map((item, index) => {
              const isChecked = item.key === reason;
              return (
                <RadioButton
                  key={index}
                  wrapperClass="space-x-2"
                  title={item.value}
                  isChecked={isChecked}
                  value={item}
                  onPress={item => {
                    setReason(item.key);
                    if (item.key !== 'other') {
                      setOtherReason('');
                    }
                  }}
                />
              );
            })}
            {reason === 'other' && (
              <SlideFade in={reason === 'other'} offsetY="30px">
                <MyTextAreaInput
                  value={otherReason}
                  onChange={e => setOtherReason(e.target.value)}
                  onDelete={() => setOtherReason('')}
                  rows={6}
                  maxlength={3168}
                  placeholder="Nhập giá trị"
                  inputClassName="flex-1"
                />
              </SlideFade>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end px-6 py-2.5 border-t border-gray-300 space-x-2">
        <div className="w-147px">
          <MyButton onPress={close} label="Trở về" type="gray" />
        </div>
        <div className="w-147px">
          <MyButton
            disabled={!reason || loading}
            onPress={onSubmit}
            label={loading ? 'Đang xử lý' : 'Xác nhận'}
            type="blue"
          />
        </div>
      </div>
    </View>
  );
}
