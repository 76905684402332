import { useGetListB2BContract } from '@utils/hooks/manageContract/useGetListB2BContract';
import { useSetState } from 'react-use';
import { useMemo } from 'react';
import ContractTable from './contractTable';

type Props = {
  userId?: string,
  contractId?: string,
  setContractId?: Function,
  setContract?: Function,
};

const blankStateFilter = {
  contractCode: '',
  status: ['active', 'expired'],
  endDateFrom: '',
  endDateTo: '',
  system: ['product', 'project'],
};

export default function ManageContact({
  userId,
  contractId,
  setContractId,
  setContract,
}: Props) {
  const [state, setState] = useSetState({
    ...blankStateFilter,
  });
  const setStateAt = useMemo(() => {
    return field => value => {
      setState({ [field]: value });
    };
  }, [setState]);
  const { listContract, loading, isLoadingMore, onLoadMore, hasNextPage } =
    useGetListB2BContract({ userId, state });
  return (
    <ContractTable
      contractId={contractId}
      setContractId={setContractId}
      setContract={setContract}
      hasNextPage={hasNextPage}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      stateFilter={state}
      setStateFilter={setStateAt}
      data={listContract}
      loading={loading}
    />
  );
}
