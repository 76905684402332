import { useGetLocation } from '../configs';
import { useGetTab } from '@utils/hooks/manageProduct/useGetTab';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export const useSwitchTabConfigs = (data, total) => {
  const { isTabProductPremium } = useGetLocation();
  const { permissions } = useGetMe();
  const status = data?.approveStatus?.status;
  const {
    product,
    productPosted,
    productHidden,
    productDeleted,
    myProduct,
    productDetails,
  } = useGetTab();
  const productDetail = 'productDetail',
    comment = 'comment',
    sameProduct = 'sameProduct',
    productHistory = 'productHistory',
    productEditHistory = 'productEditHistory',
    renewProductHistory = 'renewProductHistory';
  if (
    (product || productHidden || myProduct) &&
    status !== 'rejected' &&
    status !== 'pending'
  ) {
    return [
      {
        key: productDetail,
        value: 'Thông tin sản phẩm',
      },
      {
        key: comment,
        value:
          data?.totalComments > 0
            ? `Ghi chú (${data?.totalComments})`
            : 'Ghi chú',
        hasNewUpdate: data?.hasNewComment,
      },
      {
        permissionId: PermissionIds.ViewRelatedProducts,
        key: sameProduct,
        value: total ? `Sản phẩm tương tự (${total})` : 'Sản phẩm tương tự',
      },
      {
        permissionId: PermissionIds.ViewProductHistory,
        key: productHistory,
        value: 'Lịch sử sản phẩm',
      },
      {
        permissionId: PermissionIds.ViewProductEditHistory,
        key: productEditHistory,
        value: 'Lịch sử chỉnh sửa',
      },
    ]?.filter(
      p =>
        permissions?.map(e => e.id)?.includes(p.permissionId) || !p.permissionId
    );
  } else if (productPosted && !isTabProductPremium) {
    return [
      {
        key: productDetail,
        value: 'Thông tin bài đăng',
      },
      {
        key: renewProductHistory,
        value: 'Lịch sử gia hạn',
      },
    ];
  } else if (productDeleted) {
    return [
      {
        key: productDetail,
        value: 'Thông tin sản phẩm',
      },
      {
        key: comment,
        value: 'Ghi chú',
      },
      {
        key: productHistory,
        value: 'Lịch sử sản phẩm',
      },
      {
        key: productEditHistory,
        value: 'Lịch sử chỉnh sửa',
      },
    ];
  } else if (productDetails && !isTabProductPremium) {
    return [
      {
        key: productDetail,
        value: 'Thông tin bài đăng',
      },
      {
        key: comment,
        value:
          data?.totalComments > 0
            ? `Ghi chú (${data?.totalComments})`
            : 'Ghi chú',
        hasNewUpdate: data?.hasNewComment,
      },
    ];
  } else
    return [
      {
        key: productDetail,
        value: 'Thông tin bài đăng',
      },
    ];
};
