import React, { useState } from 'react';
import { View } from 'react-native-web';
import { unionBy } from 'lodash';
import { useList } from 'react-use';
import { ManageEmployeeContext } from './context';
import Switch from '@components/switch';
import { useStateFilterManageUserB2B } from '@utils/hooks/manageUserB2B/useStateFilterManageUserB2B';
import RenderTopTable from './components/renderTopTable';
import EmployeeTable from './components/employeeTable';
import UserDetail from './userDetail';
import { useGetListEmployee } from '@utils/hooks/b2bUser/useGetListEmployee';

export default function ManageCompanyEmployee() {
  const [tab, setTab] = useState('/employee/company');
  const [listSelected, setListSelected] = useList([]);
  const [department, setDepartment] = useState('');
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterManageUserB2B();
  const { listUser, onLoadMore, loading, isLoadingMore, hasNextPage, total } =
    useGetListEmployee({ state: stateFilter });
  return (
    <ManageEmployeeContext.Provider
      value={{
        stateFilter,
        setStateFilterAt,
        setState,
        listSelected: unionBy(listSelected, e => e),
        setListSelected,
        tab,
        setTab,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            marginTop: 64,
            backgroundColor: 'white',
            paddingHorizontal: 16,
            paddingTop: 20,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <Switch
            value={tab}
            setValue={value => {
              setTab(value);
            }}
            configs={[
              { title: 'Danh sách nhân viên', pathname: '/employee/company' },
            ]}
          />
        </View>
        <View style={{ width: '100%', height: '100%' }}>
          <View
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingBottom: 12,
            }}
          >
            <div className="px-4 pt-2">
              <RenderTopTable
                title="Danh sách nhân viên"
                total={total}
                currentTotal={listUser?.length}
                department={department}
                setDepartment={setDepartment}
              />
            </div>
            <div className="w-full px-4">
              <EmployeeTable
                data={listUser}
                onLoadMore={onLoadMore}
                isLoadingMore={isLoadingMore}
                loading={loading}
                hasNextPage={hasNextPage}
              />
            </div>
          </View>
          {listSelected?.length === 1 ? (
            <View
              style={{
                width: '100%',
                height: '100%',
                marginTop: 8,
              }}
            >
              <UserDetail />
            </View>
          ) : (
            <div className="p-4 text-black-main">
              Vui lòng chọn 1 nhân viên để xem chi tiết
            </div>
          )}
        </View>
      </View>
    </ManageEmployeeContext.Provider>
  );
}
