import React from 'react';

type Props = {
  data?: Object,
};

export default function AccountInfo({ data }: Props) {
  return (
    <div className="rounded-lg border p-4">
      <div className={`text-blue-main text-14 font-medium`}>
        Thông tin tài khoản
      </div>
      <div className="flex w-full mt-2.5 py-2 space-x-6">
        <div className="w-120px text-sm font-medium text-black-main">
          Tên đăng nhập
        </div>
        <div className="flex justify-end flex-1 text-14">{data}</div>
      </div>
    </div>
  );
}
