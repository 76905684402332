import { View } from 'react-native-web';
import Table from '@components/table';
import RenderHeader from './renderHeader';
import React, { useCallback } from 'react';
import { useProductContext } from '../product/context';
import styles from '../../manageRealEstate/needApproval/components/needApproval.module.scss';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import realEstateCommonConfigs, {
  directions,
} from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import moment from 'moment';
import {
  covertValueFromTo,
  listPostStatus,
  merge2Array,
  usePrice,
} from '@utils/helper';
import { isEmpty } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core';
import TriangleComponent from './triangleComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetInternalRealEstateConfigurations } from '@utils/hooks/manageProduct/useGetInternalRealEstateConfiguarations';
import UserInfo from '@components/userInfo';
import MyDropdown from '@components/myDropdown';
import RenderAvatarComponent from './renderAvatarComponent';
import RenderIconComponent from '../componentIconTable/renderIconComponent';
import { useGetLocation } from '../configs';
import { useState } from 'react';
import { useGetContactPhones } from '@utils/hooks/manageProduct/useGetContactPhones';
import { convertPrice } from '@utils/helper';
import Pagination from '@components/pagination';
import PropTypes from 'prop-types';
import queryString from 'query-string';

export const checkVisibleCol = id => {
  const { listField } = useProductContext();
  return listField?.includes(id);
};

ItemTableProduct.propTypes = {
  loading: PropTypes.bool,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  singleOnSelect: PropTypes.func,
  isChecked: PropTypes.bool,
  item: PropTypes.object,
  pagination: PropTypes.object,
};
function ItemTableProduct({
  loading,
  index,
  onSelect,
  singleOnSelect,
  isChecked,
  item,
  pagination,
}) {
  const {
    realEstateId,
    type,
    street,
    ward,
    district,
    province,
    houseNumber,
    createdAt,
    updatedAt,
    price,
    priceUnit,
    floorCount,
    width,
    length,
    rear,
    area,
    approvedAt,
    approvedBy,
    rejectedBy,
    rejectedAt,
    creator,
    status,
    postStatus,
    isExpired12hours,
    ownerName,
    contactName,
    commission,
    hasAlley,
    alleyWidth,
    constructionArea,
    bedroomCount,
    toiletCount,
    direction,
    map,
    ownerPhone,
    frontHomeAlleyWidth,
  } = item || {};
  const { pathname } = useLocation();
  const typeOfDemandProduct = [
    '/manageProduct/product',
    '/manageProduct/my-product',
    '/manageProduct/product/waiting-for-approval',
    '/manageProduct/product/hidden',
    '/manageProduct/product/deleted',
    '/manageProduct/product/rejected',
  ].includes(pathname);
  const {
    isTabMyProduct,
    isTabProduct,
    isTabDeleteProduct,
    isTabHiddenProduct,
    isTabProductPremium,
    isTabRejectProduct,
    isTabProductPosted,
  } = useGetLocation();
  const realEstateConfigurations = useGetInternalRealEstateConfigurations(),
    { realEstateForRentStatus, realEstateForSaleStatus } =
      realEstateConfigurations,
    finalStatus = merge2Array(realEstateForRentStatus, realEstateForSaleStatus);
  const configStatusName = finalStatus
      ?.filter(e => status?.includes(e?.key))
      ?.map(e => e.value),
    configStatusPosted = listPostStatus
      ?.filter(e => postStatus?.includes(e?.key))
      ?.map(e => e.value);
  const isForSell = item?.typeOfDemand === 'sale' ? 'Bán' : 'Cho thuê';
  const typeOfDemand = item?.isForSell ? 'Bán' : 'Cho thuê';
  const { forRentRealEstateTypes } = realEstateCommonConfigs;
  const { price: resultPrice, priceUnit: resultPriceUnit } = usePrice(item);
  const { price: resultPricePQ, priceUnit: resultPricePQUnit } = convertPrice(
    item?.price / item?.area
  );
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: '#FFFFFF',
      color: '#22313E',
    },
  }));
  const classes = useStyles();
  const [showPhone, setShowPhone] = useState(false);
  const { getPhones, phoneNumber, error } = useGetContactPhones(
    item?.id,
    showPhone,
    setShowPhone
  );
  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className={styles.row}
        >
          <div
            className={`${styles.itemTable} items-center justify-center text-sm w-60px`}
          >
            <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
          </div>
          <div onClick={singleOnSelect} className="flex flex-row items-center">
            <div
              className={`${styles.itemTable} text-sm w-60px flex items-center justify-center`}
            >
              {(pagination.currentPage - 1) * pagination.rowsPerPage +
                index +
                1}
            </div>
            {checkVisibleCol('hasAlley') && (
              <div className={`${styles.itemTable} w-85px`}>
                {hasAlley ? 'Hẻm' : 'Mặt tiền'}
              </div>
            )}
            {checkVisibleCol('houseNumber') && (
              <div className={`${styles.itemTable} w-100px truncate`}>
                {houseNumber}
              </div>
            )}
            {checkVisibleCol('streetName') && (
              <div className={`${styles.itemTable} w-170px`}>
                {street?.name}
              </div>
            )}
            {checkVisibleCol('wardName') && (
              <div className={`${styles.itemTable} w-140px`}>
                {ward?.name ? ward?.name : 'Không có'}
              </div>
            )}
            {checkVisibleCol('districtName') && (
              <div className={`${styles.itemTable} w-145px`}>
                {district?.name}
              </div>
            )}
            {checkVisibleCol('provinceName') && (
              <div className={`${styles.itemTable} w-140px`}>
                {province?.name}
              </div>
            )}
            {checkVisibleCol('width') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.width?.to || width ? (
                  `${covertValueFromTo(item, 'width')} m`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('length') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.length?.to || length ? (
                  `${covertValueFromTo(item, 'length')} m`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('price') && (
              <div className={`${styles.itemTable} w-154px`}>
                {price ? (
                  `${resultPrice} ${resultPriceUnit}${
                    priceUnit
                      ? priceUnit === 'VndPerMonth'
                        ? '/tháng'
                        : ` VNĐ`
                      : ''
                  }`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('pricePerSquare') && (
              <div className={`${styles.itemTable} w-154px`}>
                {resultPricePQ} {resultPricePQUnit}/m2
              </div>
            )}
            {checkVisibleCol('contactPhones') &&
              (isTabProductPosted ? (
                <div className={`${styles.itemTable} w-175px`}>
                  <div className="w-90px text-black-main">
                    {ownerPhone?.[0] || (
                      <span className="italic opacity-50">Không có</span>
                    )}
                  </div>
                </div>
              ) : (
                <div className={`${styles.itemTable} w-175px`}>
                  <div className="w-fit text-black-main mr-1">
                    {phoneNumber?.length !== 0
                      ? phoneNumber?.[0]
                      : error
                      ? 'Hết lượt xem SĐT'
                      : '**********'}
                  </div>
                  {phoneNumber?.length === 0 ? (
                    error ? (
                      ''
                    ) : (
                      <img
                        onClick={() => {
                          getPhones();
                        }}
                        alt="ico"
                        src="/svg/eye-close-icon.svg"
                      />
                    )
                  ) : null}
                </div>
              ))}
            {checkVisibleCol('contactName') && (
              <div className={`${styles.itemTable} w-175px truncate`}>
                {ownerName || contactName}
              </div>
            )}
            {checkVisibleCol('rear') && (
              <div className={`${styles.itemTable} w-124px`}>
                {item?.rear?.to || rear ? (
                  `${covertValueFromTo(item, 'rear')} m`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('area') && (
              <div className={`${styles.itemTable} w-175px`}>
                {item?.area?.to || area ? (
                  `${covertValueFromTo(item, 'area')} m²`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('floorCount') && (
              <div className={`${styles.itemTable} w-133px`}>
                {floorCount ? (
                  floorCount
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('constructionArea') && (
              <div className={`${styles.itemTable} w-175px`}>
                {constructionArea ? (
                  `${constructionArea} m²`
                ) : (
                  <span className="italic opacity-50">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('alleyWidth') && (
              <div className={`${styles.itemTable} w-130px`}>
                {alleyWidth || frontHomeAlleyWidth ? (
                  `${isTabProductPosted ? frontHomeAlleyWidth : alleyWidth} m`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('realEstateId') && (
              <div className={`${styles.itemTable} relative w-210px`}>
                <div className="flex flex-1 text-black-main">
                  {realEstateId}
                </div>
                <RenderIconComponent
                  isAbandoned={item?.isAbandoned}
                  approvedStatus={item?.approveStatus?.status}
                  isExpired12hours={isExpired12hours}
                  isTabMyProduct={isTabMyProduct}
                  isTabProduct={isTabProduct}
                  isTabDeletedProduct={isTabDeleteProduct}
                  isTabHiddenProduct={isTabHiddenProduct}
                  isTabRejectProduct={isTabRejectProduct}
                />
                {item?.isRead ? (
                  <Tooltip
                    className="mr-2"
                    classes={{
                      tooltip: classes.customTooltip,
                    }}
                    title="Có cập nhật mới về sản phẩm"
                  >
                    <img src="/svg/rectangle.svg" alt="icon" />
                  </Tooltip>
                ) : (
                  <div className="mr-4" />
                )}
              </div>
            )}
            {!isTabProductPremium && (
              <>
                {checkVisibleCol('status') && (
                  <div className={`${styles.itemTable} relative w-180px`}>
                    {typeOfDemandProduct
                      ? configStatusName
                      : configStatusPosted}
                    <TriangleComponent
                      status={
                        typeOfDemandProduct ? item?.status : item?.postStatus
                      }
                    />
                  </div>
                )}
              </>
            )}
            {checkVisibleCol('isForSell') && (
              <div className={`${styles.itemTable} w-140px`}>
                {typeOfDemandProduct ? isForSell : typeOfDemand}
              </div>
            )}
            {checkVisibleCol('type') && (
              <div className={`${styles.itemTable} w-145px`}>
                {forRentRealEstateTypes?.find(e => e.key === type)?.value}
              </div>
            )}
            {checkVisibleCol('country') && (
              <div className={`${styles.itemTable} w-100px`}>Việt Nam</div>
            )}
            {checkVisibleCol('bedroomCount') && (
              <div className={`${styles.itemTable} w-133px`}>
                {bedroomCount ? (
                  `${bedroomCount}`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('toiletCount') && (
              <div className={`${styles.itemTable} w-133px`}>
                {toiletCount ? (
                  `${toiletCount}`
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('direction') && (
              <div className={`${styles.itemTable} w-133px`}>
                {direction ? (
                  directions.find(e => e.key === direction)?.value
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('commission') && (
              <div className={`${styles.itemTable} w-175px truncate`}>
                {commission ? (
                  commission
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('map') && (
              <div className={`${styles.itemTable} w-175px`}>
                {map ? (
                  map?.coordinates?.map(e => e).join(', ')
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('approvedBy') && (
              <div className={`${styles.itemTable} w-218px`}>
                {!isEmpty(approvedBy) || !isEmpty(rejectedBy) ? (
                  <MyDropdown
                    DropdownComponent={UserInfo}
                    dropdownContext={{
                      userId: !isTabRejectProduct
                        ? item?.approvedBy?.id
                        : item?.rejectedBy?.id,
                    }}
                  >
                    <div className="w-200px flex space-x-2 items-center">
                      <RenderAvatarComponent
                        wrapperStyle="w-30px h-30px"
                        avatarId={
                          !isTabRejectProduct
                            ? item?.approvedBy?.avatar
                            : item?.rejectedBy?.avatar
                        }
                      />
                      <span className="text-14 font-text text-blue-main underline truncate">
                        {!isTabRejectProduct
                          ? item?.approvedBy?.fullName
                          : item?.rejectedBy?.fullName}
                      </span>
                      <img src="/svg/eye.svg" alt="icon" />
                    </div>
                  </MyDropdown>
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('approvedAt') && (
              <div className={`${styles.itemTable} w-175px`}>
                {approvedAt || rejectedAt ? (
                  moment(!isTabRejectProduct ? approvedAt : rejectedAt).format(
                    'hh:mmA,DD/MM/YYYY'
                  )
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('creator') && (
              <div className={`${styles.itemTable} w-218px`}>
                {!isEmpty(creator) ? (
                  <MyDropdown
                    DropdownComponent={UserInfo}
                    dropdownContext={{ userId: item.creator.id }}
                  >
                    <div className="w-200px flex space-x-2 items-center">
                      <RenderAvatarComponent
                        wrapperStyle="w-30px h-30px"
                        avatarId={item?.creator?.avatar}
                      />
                      <span className="text-14 font-text text-blue-main underline truncate">
                        {item?.creator?.fullName}
                      </span>
                      <img src="/svg/eye.svg" alt="icon" />
                    </div>
                  </MyDropdown>
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('createdAt') && (
              <div className={`${styles.itemTable} w-185px`}>
                {moment(createdAt).format('hh:mmA,DD/MM/YYYY')}
              </div>
            )}
            {checkVisibleCol('updatedAt') && (
              <div className={`${styles.itemTable} w-185px`}>
                {updatedAt ? (
                  moment(updatedAt).format('hh:mmA,DD/MM/YYYY')
                ) : (
                  <span className="italic">Không có</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

RenderTableProduct.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
};
export default function RenderTableProduct({ data, loading, pagination }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { listSelected, setListSelected, previousId, setPreviousId } =
    useProductContext();
  const idRE = window.sessionStorage.getItem('idRE');
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected?.set(listSelected?.filter(e => e !== key));
      } else {
        setListSelected?.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleSingOnSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected.set(listSelected.filter(e => e !== key));
      } else {
        setListSelected.set([key]);
        if (listSelected[0] !== key) {
          setPreviousId(listSelected[0]);
        }
      }
    },
    [setListSelected, listSelected, setPreviousId, previousId]
  );
  if (idRE !== null) {
    setListSelected.set([idRE]);
    window.sessionStorage.removeItem('idRE');
  }
  const handleOnPageChange = event => {
    setListSelected.clear();
    navigate(
      `.?${queryString.stringify({
        ...queryString.parse(search),
        page: event.selected,
      })}`
    );
  };
  return (
    <>
      <View
        style={{
          width: '100%',
          maxHeight: 'calc(100vh - 305px)',
          backgroundColor: 'white',
          marginTop: 12,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
        }}
      >
        <Table>
          <View style={{ width: '100%' }}>
            <RenderHeader data={data} loading={loading} />
            {data?.map((item, index) => {
              const isChecked = listSelected.find(e => e === item?.id);
              return (
                <ItemTableProduct
                  item={item}
                  loading={loading}
                  isChecked={isChecked}
                  onSelect={() => handleCheck(item?.id, isChecked)}
                  singleOnSelect={() => handleSingOnSelect(item?.id, isChecked)}
                  key={index}
                  index={index}
                  pagination={pagination}
                />
              );
            })}
            {!loading && !data?.length && (
              <p className="text-base m-3">Không có sản phẩm</p>
            )}
          </View>
        </Table>
      </View>
      <div className="w-full flex justify-end mt-3">
        <Pagination
          loading={loading}
          pagination={pagination}
          onPageChange={handleOnPageChange}
        />
      </div>
    </>
  );
}
