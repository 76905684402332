import SwitchInner from '@components/switchInner';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSwitchTabConfigs } from '../config';
import PermissionConfigs from './PermissionConfigs';
import PermissionTree from './PermissionTree';

PermissionGroupDetailLeft.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  permissionGroup: PropTypes.object,
};
export default function PermissionGroupDetailLeft({
  currentTab,
  setCurrentTab,
  permissionGroup,
}) {
  const tabConfigs = useSwitchTabConfigs();

  return (
    <Box className="flex flex-col justify-center flex-1 rounded-rd8px bg-white shadow-md">
      <Box
        className="border-b border-gray-300 text-black-main"
        style={{
          padding: '25px 30px 10px',
        }}
      >
        {`Mã nhóm quyền: ${permissionGroup?.code} | ${permissionGroup?.name}`}
      </Box>
      <SwitchInner
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      <Box
        className="flex flex-1 gap-4 border border-blue-main rounded-rd16px bg-gray-f4f"
        style={{
          margin: '30px 15px',
          padding: '15px',
        }}
      >
        <PermissionTree permissionGroup={permissionGroup} />
        <PermissionConfigs permissionGroup={permissionGroup} />
      </Box>
    </Box>
  );
}
