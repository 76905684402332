import { useState } from 'react';
import RenderRight from './components/renderRight';
import { useSwitchTabConfigs } from './configs';

type Props = {
  data?: Object,
};

export default function LeftProjectDetail(props: Props) {
  const { data } = props;
  const tabConfigs = useSwitchTabConfigs();
  const [currentTab, setCurrentTab] = useState(tabConfigs[0].key);
  return (
    <div
      className="w-full flex flex-row space-x-4 bg-white rounded-lg"
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
    >
      <RenderRight
        data={data}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabConfigs={tabConfigs}
      />
    </div>
  );
}
