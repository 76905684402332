import { useQuery } from '@apollo/client';
import { GET_COMPANY_INFO } from '@utils/graphql';

export const useGetCompanyInfo = id => {
  const { data, loading } = useQuery(GET_COMPANY_INFO, {
    variables: {
      id,
    },
    skip: !id,
  });
  const finalData = data?.companyInfo;
  return { data: finalData, loading: loading };
};
