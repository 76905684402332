// import { useGlobalContext } from '../../../context';
import { useQuery } from '@apollo/client';
import { GET_ME } from '../../graphql';

export const useGetMe = () => {
  // const { isLogin } = useGlobalContext();
  const { data, loading, error } = useQuery(GET_ME, {
    // skip: !isLogin,
  });
  const avatarUrl = data?.b2bGetMe?.payload?.avatar?.originalUrl;
  const fullName = data?.b2bGetMe?.payload?.fullName;
  const company = data?.b2bGetMe?.payload?.company;
  const permissions = data?.b2bGetMe?.payload?.permissions;
  const modules = data?.b2bGetMe?.payload?.modules;
  return {
    company,
    avatarUrl,
    fullName,
    permissions,
    modules,
    loading,
    error,
  };
};
