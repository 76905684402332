import { omit } from 'lodash';
import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_REAL_ESTATE_PRODUCT } from '@utils/graphql/mutations/realEstateB2B';
import MyToast from '@components/MyToast';
import { useToast } from '@chakra-ui/react';
import { omitFields } from '../../../constant';
export const updateRealEstateProductVariables = state => {
  const imageUrls = state?.mediaUrls?.map(e => e?.fileId),
    attachments = state?.attachments?.map(e => e?.fileId),
    map =
      state?.long && state?.lat
        ? [parseFloat(state?.long), parseFloat(state?.lat)]
        : null;
  const data = {
    clearanceArea: state?.clearanceArea,
    investor: state?.investor,
    type: state?.typeRealEstate,
    province: state.province,
    district: state.district,
    typeOfDemand: state.isForSell === 'forSale' ? 'sale' : 'rent',
    ward: state.ward,
    street: state.street,
    houseNumber: state.houseNumber,
    status: state.status,
    commission: state.commission,
    hasAlley: state.hasAlley,
    alleyTurnCount: state.hasAlley ? parseInt(state.alleyTurnCount, 10) : null,
    alleyWidth: state.hasAlley ? parseInt(state.alleyWidth, 10) : null,
    isNewConstruction: state.isNewConstruction,
    width: parseFloat(state.width),
    length: parseFloat(state.length),
    rear: parseFloat(state.rear),
    area: state?.rear
      ? parseFloat(
          (
            ((parseFloat(state?.width) + parseFloat(state?.rear)) / 2) *
            parseFloat(state?.length)
          )?.toFixed(2)
        )
      : parseFloat(
          (parseFloat(state.width) * parseFloat(state.length))?.toFixed(2)
        ),
    floorCount: !state.floorCount ? 0 : parseInt(state.floorCount, 10), // Số tầng
    bedroomCount: parseInt(state.bedroomCount, 10),
    roomCount: parseInt(state.roomCount, 10), // Số lượng phòng
    hotelStar: parseInt(state.hotelStar, 10),
    hasSeaView: state.hasSeaView,
    price: parseFloat(state.price),
    priceUnit: state.isForSell === 'forSale' ? 'VND' : 'VndPerMonth',
    toiletCount: parseInt(state.toiletCount, 10),
    direction: state.direction,
    project: state.project,
    privateNote: state.note,
    legals: state.legal,
    mediaUrls: imageUrls,
    villaView: state.villaView,
    buildingType: state.buildingType,
    warehouseType: state.warehouseType, // Chức năng nhà xưởng
    warehouseRoomCount: parseInt(state.warehouseRoomCount, 10), // Số phòng kho
    block: state?.block, // Thuộc block nào
    roomNumber: state.roomNumber?.toString(), // Số thứ tự phòng
    onFloor: parseInt(state.onFloor, 10), // Vị trí tầng
    bedRoomArea: parseInt(state.bedRoomArea, 10), // Diện tích phòng ngủ
    shophouseField: state?.shophouseField, // Thương mại ngành
    hasMezzanine: state.hasMezzanine, // Có gác hay không
    contactName: state.contactName,
    contactPhones:
      state.contactPhones?.length > 0
        ? state.contactPhones?.map(e => e?.phone)
        : '',
    contactEmail: state.contactEmail,
    career: state.career,
    fixedUtilities: state.fixedUtilities,
    utilities: state.utilities,
    localUtilities: state.localUtilities,
    equipmentsAndServices: state.equipmentsAndServices,
    attachments, // Tệp đính kèm
    constructionArea: parseFloat(state?.constructionArea),
  };
  if (data?.map) {
    data.map = { coordinates: map };
  }
  return omit(data, omitFields[data.type]);
};
export const useUpdateB2BRealEstate = (setShowModal, id, attachments) => {
  const toast = useToast();
  const [updateRealEstateProduct, { loading, client }] = useMutation(
    UPDATE_REAL_ESTATE_PRODUCT
  );
  const onSubmit = useCallback(
    async state => {
      const pickObject = updateRealEstateProductVariables(state);
      try {
        await updateRealEstateProduct({
          variables: { realEstateId: id, ...pickObject },
        });
        setShowModal(true);
        await window.sessionStorage.removeItem('idRE');
        await client.resetStore();
      } catch (e) {
        setTimeout(() => {
          toast({
            position: 'bottom-right',
            duration: 5000,
            render: props => (
              <MyToast
                id={props.id}
                onClose={props.onClose}
                type="error"
                message={e.message}
                closeButtonPos="center"
              />
            ),
          });
        }, 200);
      }
    },
    [updateRealEstateProduct, attachments, id]
  );
  return { onSubmit, loading };
};
