export default function Intro() {
  return (
    <div
      style={{ backgroundColor: '#FDF9E6' }}
      id="publish-intro"
      className="w-full flex flex-row space-x-7 rounded-md p-4 font14x shadow"
    >
      <img className="self-start" src="/svg/tips.svg" alt="tips" />
      <p>
        Thông tin hiển thị lên sàn https://www.realtech247.com sẽ không bao gồm:
        <br />- Ghi chú nội bộ <br />
        - Tọa độ của sản phẩm (gồm kinh độ và vĩ độ) <br />
        - Thông tin liên lạc chủ nhà <br />
        - Địa chỉ của sản phẩm (chỉ hiện Tỉnh, Quận/Huyện, Phường/Xã, tên đường,
        không hiện số nhà) <br />
        - Phí hoa hồng <br /> <br />
        Vui lòng hoàn tất các thông tin phía dưới để tiến hành đăng sản phẩm
      </p>
    </div>
  );
}
