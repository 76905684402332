import LeftProjectDetail from './components/leftProjectDetail';
import RightProjectDetail from './components/rightProjectDetail';
import { View } from 'react-native-web';
import { useManageProjectContext } from '../listProject/context';
import { useGetProjectDetails } from '../../../../utils/hooks/manageProject/useGetProjectDetails';

export default function ProjectDetail() {
  const { listSelected } = useManageProjectContext();
  const { data } = useGetProjectDetails(listSelected?.[0]);
  return (
    <View
      style={{
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftProjectDetail data={data} />
      <RightProjectDetail />
    </View>
  );
}
