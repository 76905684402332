import { getDistanceFromLatLonInKm } from '@components/map/utils';
import { useCallback, useEffect } from 'react';
import MeasureMarker from '@components/realEstateOnMap/components/measureMarker';

type Props = {
  points?: Array,
  setPoints?: Object,
  pointsRef?: Object,
  setClosePolygon?: Function,
  closePolygon?: boolean,
};

let CustomMarker, Tooltip, ReactLeaflet;

if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  Tooltip = ReactLeaflet.Tooltip;
  CustomMarker = require('../../map/customMarker').CustomMarker;
}

export default function MeasureLayer(props: Props) {
  const {
    points,
    setPoints: { updateAt, filter },
    pointsRef,
    setClosePolygon,
    closePolygon,
  } = props;

  const measurePoints = [...points];

  useEffect(() => {
    pointsRef.current = [...points];
  }, [points]);

  const onMarkerMove = (e, id) => {
    const { lat: nextLat, lng: nextLng } = e.target._latlng;
    const newPoints = [...pointsRef.current],
      updateIndex = newPoints.findIndex(item => item.id === id);
    if (updateIndex >= 0) {
      updateAt(updateIndex, {
        ...newPoints[updateIndex],
        lat: nextLat,
        lng: nextLng,
      });
    }
  };

  const closePolygonPoints =
    closePolygon && measurePoints.length > 2
      ? [...measurePoints, { id: 'close', ...measurePoints[0] }]
      : [...measurePoints];

  const removeMarker = useCallback(
    id => {
      filter(e => e.id !== id);
    },
    [filter]
  );

  const onMarkerClick = useCallback(
    ({ id, itemLat, itemLng }) => {
      const currentPoints = [...(pointsRef.current || [])];
      if (
        currentPoints.length >= 3 &&
        currentPoints?.findIndex(
          e => e.lat === itemLat && e.lng === itemLng
        ) === 0
      ) {
        setClosePolygon(true);
      } else {
        removeMarker(id);
        setClosePolygon(false);
      }
    },
    [removeMarker]
  );

  return measurePoints.map(({ lat: itemLat, lng: itemLng, id }, index) => {
    const previousPoints = measurePoints.filter(
      (e, itemIndex) => itemIndex <= index
    );
    let sumDistance = 0;
    previousPoints.forEach((item, itemIndex) => {
      if (itemIndex > 0) {
        sumDistance += getDistanceFromLatLonInKm(
          item.lat,
          item.lng,
          previousPoints[itemIndex - 1].lat,
          previousPoints[itemIndex - 1].lng
        );
      }
    });
    return (
      <CustomMarker
        zIndexOffset={2000}
        riseOnHover
        draggable
        onMoveEnd={e => onMarkerMove(e, id)}
        onClick={() => onMarkerClick({ id, itemLat, itemLng })}
        key={id || `${itemLat}-${itemLng}`}
        position={[itemLat, itemLng]}
      >
        <MeasureMarker
          sumDistance={sumDistance}
          measurePoints={closePolygon ? closePolygonPoints : measurePoints}
          index={index}
        />
        <Tooltip sticky>
          <div className="flex flex-col p-8px bg-white justify-center rounded-rd8px">
            <p className="bodyText">
              <b>Nắm kéo</b> để thay đổi vị trí, <b>Nhấn</b> để xóa điểm này
            </p>
          </div>
        </Tooltip>
      </CustomMarker>
    );
  });
}
