import MyButton from '@components/button';

type Props = {
  onShowModalApproval?: Function,
  onShowModalReject?: Function,
};
export default function RenderHandleMutationButton({
  onShowModalApproval,
  onShowModalReject,
}: Props) {
  return (
    <div className="flex flex-row items-center space-x-4">
      <MyButton
        onPress={onShowModalApproval}
        style={{ width: 210 }}
        label="Duyệt tất cả"
        type="gray"
      />
      <MyButton
        onPress={onShowModalReject}
        style={{ width: 210 }}
        label="Từ chối tất cả"
        type="gray"
      />
    </div>
  );
}
