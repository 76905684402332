import RadioButton from '@components/radio';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

type AddProductProps = {
  viewOnly?: boolean,
  selectedValue?: string,
  isEdit?: boolean,
};
export default function AddProduct(props: AddProductProps) {
  const { viewOnly = false, selectedValue, isEdit } = props;
  const form = useFormContext();
  const [needToApprove, setNeedToApprove] = useState(
    isEdit ? selectedValue?.isNeedToApprove : false
  );
  useEffect(() => {
    if (!viewOnly) {
      form.setValue(PermissionIds.AddProduct, {
        isNeedToApprove: needToApprove,
      });
    }
  }, [needToApprove]);
  return (
    <div className="w-full flex flex-col space-y-4 rounded-lg shadow-card p-4">
      <span className="font-medium text-blue-main text-16">Thêm sản phẩm</span>
      {viewOnly ? (
        <div className="text-sm font-medium">
          {selectedValue?.isNeedToApprove === true
            ? 'Phòng ban duyệt bằng tay'
            : 'Hệ thống duyệt tự động'}
        </div>
      ) : (
        <div className="flex flex-col space-y-4">
          <RadioButton
            titleClass={needToApprove ? 'font-medium' : 'none'}
            isChecked={needToApprove}
            title="Phòng ban duyệt bằng tay"
            wrapperClass="ml-4"
            onPress={() => {
              setNeedToApprove(true);
            }}
          />
          <RadioButton
            titleClass={!needToApprove ? 'font-medium' : 'none'}
            isChecked={!needToApprove}
            title="Hệ thống duyệt tự động"
            wrapperClass="ml-4"
            onPress={() => {
              setNeedToApprove(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
