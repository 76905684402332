import Radio from '@components/radio';
import styles from './radioWidthInput.module.scss';
import React from 'react';
const configs = [
  {
    key: 0,
  },
  {
    key: 1,
  },
  {
    key: 2,
  },
  {
    key: 3,
  },
];
const configsWithZero = [
  {
    key: 0,
  },
  {
    key: 1,
  },
  {
    key: 2,
  },
  {
    key: 3,
  },
];
type RadioWidthInputProps = {
  value: number,
  setValue: Function,
  hasZero?: boolean,
  errorMessage?: string,
  disabled?: boolean,
};
export default function RadioWithInput({
  value,
  setValue,
  hasZero,
  errorMessage,
  disabled,
}: RadioWidthInputProps) {
  const commonConfigs = hasZero ? configsWithZero : configs;
  return (
    <div className="flex-1 space-y-2">
      <div className="flex-1 flex flex-row items-center space-x-5">
        {commonConfigs.map((item, index) => {
          const isActive = item.key === parseInt(value, 10);
          return (
            <Radio
              disabled={disabled}
              onPress={() => setValue(item.key)}
              isChecked={isActive}
              key={index}
              title={item.key}
            />
          );
        })}
        <input
          onKeyDown={evt =>
            (evt.key === 'e' || evt.key === '-' || evt.key === 'ArrowDown') &&
            evt.preventDefault()
          }
          type="number"
          disabled={disabled}
          className={`${styles.radioWidthInput} ${
            value >= 4 ? styles.radioWidthInputActive : undefined
          }`}
          placeholder="Khác"
          value={value}
          onChange={e => setValue(e.target.value)}
          onKeyPress={e => {
            if (e.target.value?.length === 3) {
              e.preventDefault();
            }
          }}
        />
      </div>
      {errorMessage && (
        <div className="text-sm text-red-400">{errorMessage}</div>
      )}
    </div>
  );
}
