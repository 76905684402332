import { CHANGE_PASSWORD } from '../../graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const useChangePassword = (onClose, setShowModalSuccess) => {
  const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD);
  const onChangePassword = useCallback(async variables => {
    try {
      await changePassword({
        variables: {
          oldPassword: variables.oldPassword,
          newPassword: variables.newPassword,
          confirmNewPassword: variables.confirmNewPassword,
        },
      });
      onClose();
      setShowModalSuccess();
    } catch (e) {
      console.log(e);
    }
  }, []);
  return { onChangePassword, loading, error };
};
