import React, {
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
} from 'react';
import { useMutation } from '@apollo/client';
import { REQUEST_CAPTCHA } from '@utils/graphql';
import MyInput from '@components/myInput';
import MyButton from '@components/button';

export default React.forwardRef(Captcha);

type Props = {
  wrapperStyle?: string,
  errorServer?: string,
  setCaptchaId?: Function,
  setCaptchaCode?: Function,
  captchaError?: string,
  setCaptchaError?: Function,
};

export function Captcha(props: Props, ref) {
  const {
      wrapperStyle,
      errorServer,
      setCaptchaId,
      setCaptchaCode,
      captchaError,
      setCaptchaError,
    } = props,
    [requestCaptcha, { data: rawData }] = useMutation(REQUEST_CAPTCHA, {
      onCompleted: data => {
        if (data) {
          setCaptchaId(data?.requestCaptcha.payload.id);
        }
      },
    }),
    data = rawData?.requestCaptcha.payload,
    svgText = data?.image;

  useImperativeHandle(ref, () => ({
    onRequestCaptcha,
  }));

  const onRequestCaptcha = useCallback(async () => {
    try {
      await requestCaptcha();
      await setCaptchaError(null);
    } catch (e) {
      console.log(e);
    }
  }, [requestCaptcha, captchaError]);

  useEffect(async () => {
    await onRequestCaptcha();
  }, []);
  return useMemo(() => {
    return (
      <div className={`w-full flex-col pb-2 ${wrapperStyle}`}>
        <div className="w-full flex flex-row space-x-2">
          <div
            dangerouslySetInnerHTML={{ __html: `${svgText ? svgText : ''}` }}
            style={{ borderRadius: 6 }}
            className="flex w-full sm:w-240px h-80px items-center justify-center border border-gray-300"
          />
          <div
            dangerouslySetInnerHTML={{
              __html: `(Trong trường hợp hiện ra thông báo "Mã an toàn bạn nhập vào chưa
              đúng”,<br> bạn vui lòng nhấn vào nút đổi mã để tạo mã mới và thử lại)`,
            }}
            style={{ fontSize: 13 }}
            className="text-black hidden sm:block"
          />
        </div>
        <div className="flex flex-row space-x-2 mt-2">
          <div className="w-full rounded" style={{ maxWidth: 250 }}>
            <MyInput
              errorMessage={captchaError}
              placeholder="Nhập mã an toàn"
              onKeyUp={e => setCaptchaCode(e.target.value)}
            />
          </div>
          <div className="w-120px">
            <MyButton
              onPress={onRequestCaptcha}
              leftComponent={() => {
                return (
                  <img
                    className="w-16px h-16px"
                    alt="refresh"
                    src="/svg/refresh.svg"
                  />
                );
              }}
              wrapperClass="space-x-2 border border-blue-main text-14"
              type="white"
              label="Đổi mã"
            />
          </div>
        </div>
      </div>
    );
  }, [errorServer, onRequestCaptcha, svgText, wrapperStyle]);
}
