import React from 'react';
import LoginDetailsInput from './loginDetailsInput';
import Image from 'react-native-web/dist/vendor/react-native/Animated/components/AnimatedImage';

type Props = {
  isUpdate?: boolean,
};
export default function LoginDetails({ isUpdate }: Props) {
  return (
    <div className="flex flex-row w-full rounded-lg mt-4 px-4 py-5 border border-gray-300 space-x-6">
      <div className="flex-1 mr-6">
        <LoginDetailsInput isUpdate={isUpdate} />
      </div>
      <div className="flex-1">
        <Image
          resizeMode={'stretch'}
          source={'/svg/createB2B-rightBanner.svg'}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
}
