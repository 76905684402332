import UploadLocalMedia from '@components/uploadMedia/components/uploadLocalMedia';
import styles from '../uploadMedia.module.scss';
type RightUploadMediaProps = {
  option?: number,
  doUploadImage?: Function,
  push?: Function,
  mediaUrls?: Array,
};
export default function RightUploadMedia({
  option,
  doUploadImage,
  push,
  mediaUrls,
}: RightUploadMediaProps) {
  return (
    <div className={styles.rightContainer}>
      <UploadLocalMedia
        mediaUrls={mediaUrls}
        push={push}
        doUploadImage={doUploadImage}
        option={option}
      />
    </div>
  );
}
