import React from 'react';

export const ManageUserB2BContext = React.createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
});

export const useManageUserB2BContext = () =>
  React.useContext(ManageUserB2BContext);
