import { useLocation } from 'react-router-dom';
import { productPosted } from '@components/leftMenu/configs';
import qs from 'query-string';
import type { RouterLocation } from '../../../typeDefinition';

export const useGetLocation = () => {
  const { pathname, search }: RouterLocation = useLocation();
  const { tab = productPosted } = qs.parse(search);
  const isTabProduct = '/manageProduct/product'.includes(pathname),
    isTabMyProduct = '/manageProduct/my-product'.includes(pathname),
    isTabWaitingForApproval =
      '/manageProduct/product/waiting-for-approval'.includes(pathname),
    isTabProductPosted = pathname === '/manageProduct/product-posted',
    isTabHiddenProduct = pathname === '/manageProduct/product/hidden',
    isTabDeleteProduct = pathname === '/manageProduct/product/deleted',
    isTabPublish = '/publish/product'.includes(pathname),
    isTabRejectProduct = pathname.includes('/rejected'),
    isTabProductPremium = tab.includes('product-premium'),
    isTabProductAuthorize = tab.includes('product-authorize');
  return {
    isTabProduct,
    isTabMyProduct,
    isTabProductPosted,
    isTabWaitingForApproval,
    isTabHiddenProduct,
    isTabDeleteProduct,
    isTabRejectProduct,
    isTabProductPremium,
    isTabProductAuthorize,
    isTabPublish,
  };
};
