import moment from 'moment';

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return moment(date).format('HH:mm A, DD/MM/YYYY');
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return moment(date).format('HH:mm A, DD/MM/YYYY');
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return moment(date).format('HH:mm A, DD/MM/YYYY');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' giờ trước';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' phút trước';
  }
  return Math.floor(seconds < 0 ? 0 : seconds) + ' giây trước';
}
