import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import MyInput from '@components/myInput';
import { maxLength } from '../constant';
import { regexValidatePhoneNumber } from '@utils/configs/regex';
import MyButton from '@components/button';
import { useEffectOnce } from 'react-use';
import isEmpty from 'lodash/isEmpty';

type Props = {
  name?: string,
  isStep1?: boolean,
  isDisable?: boolean,
};
type ButtonAddPhoneProps = {
  onAdd?: Function,
};
function ButtonAddPhone({ onAdd }: ButtonAddPhoneProps) {
  return (
    <MyButton
      style={{ borderRadius: 9, borderWidth: 2, height: 40 }}
      onPress={onAdd}
      type="other"
      leftComponent={() => {
        return <img alt="phone" src="/svg/plus-blue-icon.svg" />;
      }}
      label="Thêm số điện thoại"
    />
  );
}

export default function PhoneInput({
  name = 'contactPhones',
  isStep1 = false,
}: // isDisable = true,
Props) {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const contactPhones = watch(name);
  useEffectOnce(() => {
    if (isEmpty(contactPhones)) {
      setValue(`${name}`, [{ phone: '' }]);
    }
  });
  return (
    <div className="flex-1 space-y-7">
      {fields.map((field, index) => {
        return (
          <Controller
            rules={{
              validate: {
                checkPhone: value =>
                  value?.toString()?.match(regexValidatePhoneNumber)
                    ? true
                    : value?.length > 0
                    ? 'Vui lòng nhập số điện thoại đúng định dạng'
                    : true,
                checkDuplicatePhone: value => {
                  let findDuplicates = () =>
                    contactPhones
                      .map(e => e.phone)
                      .filter(
                        (item, index) =>
                          contactPhones?.map(e => e.phone).indexOf(item) !==
                          index
                      );
                  let arrDuplicate = findDuplicates();
                  if (arrDuplicate.includes(value)) {
                    return 'Số điện thoại không được trùng nhau';
                  } else {
                    return true;
                  }
                },
              },
              required: index === 0 ? 'Vui lòng nhập số điện thoại' : false,
            }}
            key={field.id}
            control={control}
            name={`${name}.${index}.phone`}
            render={({ field, field: { onChange }, fieldState: { error } }) => {
              return (
                <div
                  key={index}
                  className="flex-1 flex flex-row items-center space-x-2"
                >
                  <MyInput
                    {...field}
                    inputClassName="flex-1"
                    onKeyDown={evt =>
                      (evt.key === 'e' ||
                        evt.key === '-' ||
                        evt.key === 'ArrowDown') &&
                      evt.preventDefault()
                    }
                    hideMaxLength
                    errorMessage={error?.message}
                    maxLength={maxLength.phoneNumber}
                    onChange={nextValue => onChange(nextValue)}
                    onDelete={() => onChange('')}
                    placeholder={`Nhập số điện thoại ${
                      !isStep1 ? index + 1 : ''
                    }`}
                    pattern="[0-9]*"
                    // disabled={isDisable && index === 0 && !isStep1}
                  />
                  {index > 0 && (
                    <button
                      style={{ backgroundColor: '#f4f4f4' }}
                      className="flex w-10 h-10 rounded-lg items-center justify-center"
                      onClick={() => remove(index)}
                    >
                      <img alt="delete" src="/svg/delete-red-icon.svg" />
                    </button>
                  )}
                </div>
              );
            }}
          />
        );
      })}
      {fields?.length < 5 && !isStep1 && (
        <ButtonAddPhone onAdd={() => append({ phone: '' })} />
      )}
    </div>
  );
}
