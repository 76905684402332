import React from 'react';
import SearchBox from '@components/searchBox';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import MyButton from '@components/button';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

RenderTopTable.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
};
export default function RenderTopTable({ title, total = 0 }) {
  const navigate = useNavigate();
  const { stateFilter, setStateFilterAt } = usePermissionGroupsContext();

  return (
    <div className="w-full flex flex-row py-2.5 space-x-10 items-center">
      <div style={{ minWidth: 270 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="smDT:text-18 text-16 font-medium text-black-main">
            {title}
          </div>
          <div className="text-14 text-black-main mt-0.5">
            ({total} nhóm quyền)
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 flex-row items-center space-x-4 justify-end">
        <div>
          <MyButton
            labelStyle="pl-2"
            onPress={() => {
              navigate('/manageOrganization/permission-groups/create');
            }}
            rightComponent={() => (
              <img alt="plus-icon" src="/svg/plus-icon.svg" />
            )}
            type="blue"
            label={'Thêm nhóm quyền mẫu'}
          />
        </div>
        <SearchBox
          searchValue={stateFilter.searchString}
          placeholder="Tìm kiếm nhóm quyền mẫu"
          inputClassName="w-260px"
          setSearchValue={value => setStateFilterAt('searchString')(value)}
          onDeleteValue={() => setStateFilterAt('searchString')('')}
        />
      </div>
    </div>
  );
}
