import React from 'react';

export const ProductContext = React.createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  demand: 'sale',
  setDemand: () => {},
  showModal: false,
  setShowModal: () => {},
  status: 'approved',
  setStatus: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
  productStatus: '',
  setProductStatus: () => {},
  id: '',
  setId: () => {},
  previousId: '',
  setPreviousId: () => {},
  input: '',
  setInput: () => {},
});

export const useProductContext = () => React.useContext(ProductContext);

export const SameProductContext = React.createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  demand: 'sale',
  setDemand: () => {},
  showModal: false,
  setShowModal: () => {},
  status: 'approved',
  setStatus: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
  showChangeStatusModal: false,
  setShowChangeStatusModal: () => {},
});

export const useSameProductContext = () => React.useContext(SameProductContext);
