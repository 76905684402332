import RadioButton from '@components/radio';
import { typeOfDemand } from '../configs';
import React from 'react';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import DropdownMultiSelectionOnMap from '@components/realEstateOnMap/components/dropdownMultiSelectionOnMap';

type Props = {
  demand?: string,
  setDemand?: Function,
  type?: string,
  setType?: Function,
  container?: any,
};
const { forSellRealEstateTypes, forRentRealEstateTypes } = configs;
export default function FilterListRealEstateOnMap({
  demand,
  setDemand,
  type,
  setType,
  container,
}: Props) {
  return (
    <div className="flex flex-col space-y-7">
      <div className="flex flex-row items-center space-x-2.5">
        <span className="w-152px text-sm font-medium">Nhu cầu</span>
        {typeOfDemand.map((item, index) => {
          return (
            <RadioButton
              onPress={() => setDemand(item.key)}
              isChecked={item.key === demand}
              title={item.value}
              key={index}
            />
          );
        })}
      </div>
      <div className="flex flex-row items-center space-x-2.5">
        <span className="w-152px text-sm font-medium">Loại bất động sản</span>
        <div className="flex-1">
          <DropdownMultiSelectionOnMap
            container={container}
            wrapperStyle="self-center w-240px h-40px flex px-2 border rounded-lg border-blue-main"
            selections={
              demand === 'sale'
                ? forSellRealEstateTypes
                : forRentRealEstateTypes
            }
            value={type}
            setValue={setType}
            title={type?.length === 11 ? 'Tất cả' : 'Chọn loại BĐS'}
            onDelete={() => {
              setType(
                typeOfDemand === 'sale'
                  ? [
                      'townhouse',
                      'villa',
                      'building',
                      'hotel',
                      'warehouse',
                      'land',
                      'apartment',
                      'shophouse',
                      'penthouse',
                      'condotel',
                    ]
                  : [
                      'townhouse',
                      'villa',
                      'building',
                      'hotel',
                      'warehouse',
                      'land',
                      'apartment',
                      'shophouse',
                      'penthouse',
                      'motel',
                      'condotel',
                    ]
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
