import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { blankState } from '../../manageEmployee/configs';
import LoginDetails from '../../createB2BUserAccount/components/loginDetails';
import LeftEmployeeInfo from './leftEmployeeInfo';
import ContractDocuments from '../../createB2BUserAccount/components/contractDocuments';
import { HoChiMinhId } from '../../../../constant';
import isEqual from 'lodash/isEqual';
import { useNavigate } from 'react-router';
import { useGetEmployeeDetails } from '@utils/hooks/b2bUser/useGetEmployeeDetail';
import { useDeepCompareEffect } from 'react-use';
import moment from 'moment';
import ConfirmRegister from '../../../../modals/confirmRegisterAccountModal';
import MyModalV2 from '@components/modalV2/myModalV2';
import CommonHeader from '@components/commonHeader';
import DepartmentInfo from './rightDepartment';
import Submit from '../submit';
import qs from 'query-string';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export default function CreateEmployeeAccount() {
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    mode: 'onChange',
  });

  const { permissions: loggedInPermissions } = useGetMe();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { watch } = form;
  const { ...state } = watch();
  const userInitialValue = useRef({});
  const { isEdit, id } = qs.parse(location.search);
  const shouldRenderPrompt = !isEqual(
    state,
    isEdit ? userInitialValue.current : blankState
  );
  const { data: userData } = useGetEmployeeDetails(id);

  useDeepCompareEffect(() => {
    if (isEdit && userData) {
      const remoteState = {
        username: userData?.username ?? '',
        password: userData?.password ?? '',
        confirmPassword: userData?.confirmPassword ?? '',
        avatar: userData?.avatar ?? '',
        companyName: userData?.companyName ?? '',
        fullName: userData?.fullName ?? '',
        gender: userData?.gender ?? '',
        dateOfBirth: userData?.dateOfBirth
          ? moment(userData?.dateOfBirth).unix()
          : '',
        idImages: userData?.idImages ?? [],
        attachments: userData?.attachments ?? [],
        idNumber: userData?.idNumber ?? '',
        phone: userData?.phone ?? '',
        secondPhone: userData?.secondPhone ?? '',
        email: userData?.email ?? '',
        province: userData?.province?.id ?? HoChiMinhId,
        district: userData?.district?.id ?? '',
        ward: userData?.ward?.id ?? '',
        street: userData?.street?.id ?? '',
        houseNumber: userData?.houseNumber ?? '',
        mediaUrls: userData?.mediaUrls,
        departmentId: userData?.departments?.[0]?.department?.id ?? '',
        position: userData?.departments?.[0]?.positionName ?? '',
      };
      form.reset({ ...remoteState });
      userInitialValue.current = remoteState;
    }
  }, [userData]);

  return (
    <FormProvider {...form}>
      <div className="w-full h-screen">
        <div
          className="w-full h-full mt-1 bg-white px-4 pt-5"
          style={{
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <CommonHeader
            mainNormalHeaderStr="Tạo tài khoản"
            mainEditHeaderStr="Chỉnh sửa tài khoản"
            headerLevel1="Quản lý nhân viên"
            headerNormalLevel2="Tạo tài khoản nhân viên"
            headerEditLevel2="Chỉnh sửa thông tin nhân viên"
            isEdit={isEdit}
          />
          {!isEdit && <LoginDetails isUpdate={isEdit} />}
          <div className="w-full flex flex-row mt-4 space-x-4">
            <LeftEmployeeInfo isEdit={isEdit} />
            <DepartmentInfo isEdit={isEdit} />
          </div>

          {loggedInPermissions?.some(
            p => p.id === PermissionIds.AddEmployeeAttachments
          ) && (
            <ContractDocuments
              isEdit={isEdit}
              wrapperClass="border border-gray-300 px-4"
            />
          )}
          <Submit
            onBack={
              shouldRenderPrompt ? () => setShowModal(true) : () => navigate(-1)
            }
            idUser={id}
            isUpdate={isEdit}
          />
        </div>
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ConfirmRegister
          isEdit={isEdit}
          onBack={() => navigate(-1)}
          onContinue={() => setShowModal(false)}
        />
      </MyModalV2>
    </FormProvider>
  );
}
