import { useLazyQuery } from '@apollo/client';
import { GET_CONTACT_PHONES } from '@utils/graphql';
import { useState } from 'react';

export const useGetContactPhones = (realEstateId, showPhone, setShowPhone) => {
  const [phones, setPhones] = useState([]);
  const [getPhones, { data, loading, called, error }] = useLazyQuery(
    GET_CONTACT_PHONES,
    {
      variables: {
        realEstateId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setPhones(data?.getContactPhoneRealEstate?.contactPhones);
        setShowPhone(false);
      },
    }
  );
  return {
    called,
    getPhones,
    data,
    phoneNumber: phones,
    loading,
    error,
  };
};
