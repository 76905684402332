import React, { useState } from 'react';
import Section from './section';
import { useToast } from '@chakra-ui/react';
import MyToast from '@components/MyToast';

type Props = {
  data?: Object,
};

export default function InvestorInfo({ data }: Props) {
  return (
    <div className="space-y-8">
      <Section
        title="Thông tin liên lạc chủ đầu tư"
        source="/svg/ph_address-book.svg"
      >
        {data?.investorName && (
          <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
            <div className="w-120px text-sm font-medium text-black-main">
              Chủ đầu tư
            </div>
            <div className="flex-1 text-sm ">{data?.investorName}</div>
          </div>
        )}
        <div className="border-b">
          {data?.contactPhones?.map((item, index) => {
            return <Phone phone={item} index={index} key={index} />;
          })}
        </div>
        {data?.contactEmail && (
          <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
            <div className="w-120px text-sm font-medium text-black-main">
              Email
            </div>
            <div className="flex-1 text-sm text-black-main">
              {data?.contactEmail}
            </div>
          </div>
        )}
        {data?.taxId && (
          <div className="flex w-full mt-1 py-2 border-b border-gray-300 space-x-8">
            <div className="w-120px text-sm font-medium text-black-main">
              Mã số thuế
            </div>
            <div className="flex-1 text-sm text-black-main">
              {data?.taxId || (
                <span className="opacity-50 italic">Không có</span>
              )}
            </div>
          </div>
        )}
      </Section>
    </div>
  );
}

type PhoneProps = {
  index?: string,
  phone?: string,
};

function Phone({ index, phone }: PhoneProps) {
  const [showPhone, setShowPhone] = useState(false);
  const toast = useToast();
  return (
    <div>
      <div className="flex w-full mt-1 py-2 items-center space-x-8">
        <div className="w-120px text-sm font-medium text-black-main">
          Hotline {index + 1}
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex-1 text-sm">
            {showPhone ? phone : '***********'}
          </div>
          {!showPhone ? (
            <img
              alt="ico"
              src={'/svg/eye-close-icon.svg'}
              className="w-6 h-6"
              onClick={() => setShowPhone(!showPhone)}
            />
          ) : (
            <div
              className="cursor-pointer"
              onClick={async () => {
                await navigator.clipboard.writeText(phone);
                await toast({
                  position: 'bottom-right',
                  duration: 5000,
                  render: ({ id, onClose }) => (
                    <MyToast
                      id={id}
                      onClose={onClose}
                      wrapperStyle="w-394px shadow-md"
                      type="success"
                      message="Sao chép số điện thoại thành công"
                      closeButtonPos="center"
                    />
                  ),
                });
              }}
            >
              <img alt="ico" src={'/svg/copy.svg'} className="w-6 h-6" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
