import { useSetState } from 'react-use';
import { useCallback } from 'react';
import { HoChiMinhId } from '../../../constant';
export const blankStateFilter = {
  createdAtFrom: '',
  createdAtTo: '',
  updatedAtFrom: '',
  updatedAtTo: '',
  realEstateId: '',
  isForSell: 'ForSale',
  realEstateType: ['townhouse'],
  houseNumber: '',
  street: '',
  streetName: '',
  ward: '',
  wardName: '',
  district: '',
  districtName: '',
  province: HoChiMinhId,
  provinceName: 'Thành phố Hồ Chí Minh',
  country: 'vietnam',
  widthFrom: 0,
  widthTo: 101,
  widthKey: '',

  lengthFrom: 0,
  lengthTo: 501,
  lengthKey: '',

  rearFrom: 0,
  rearTo: 101,
  rearKey: '',

  areaFrom: 0,
  areaTo: 20001,
  areaKey: '',

  priceFrom: 0,
  priceTo: 101,
  priceKey: '',

  floorCountFrom: 0,
  floorCountTo: 169,
  floorCountKey: '',

  constructionAreaFrom: 0,
  constructionAreaTo: 501,
  constructionAreaKey: '',
  isNewConstruction: ['yes', 'no'],
  approvedBy: '',
  approvedAtFrom: '',
  approvedAtTo: '',
};
export const blankSameStateFilter = {
  createdAtFrom: '',
  createdAtTo: '',
  updatedAtFrom: '',
  updatedAtTo: '',
  realEstateId: '',
  isForSell: 'ForSale',
  realEstateType: ['townhouse'],
  street: '',
  streetName: '',
  ward: '',
  wardName: '',
  district: '',
  districtName: '',
  province: HoChiMinhId,
  provinceName: 'Thành phố Hồ Chí Minh',
  country: 'vietnam',
  widthFrom: 0,
  widthTo: 101,
  widthKey: '',

  lengthFrom: 0,
  lengthTo: 501,
  lengthKey: '',

  rearFrom: 0,
  rearTo: 101,
  rearKey: '',

  areaFrom: 0,
  areaTo: 20001,
  areaKey: '',

  priceFrom: 0,
  priceTo: 101,
  priceKey: '',

  floorCountFrom: 0,
  floorCountTo: 169,
  floorCountKey: '',

  constructionAreaFrom: 0,
  constructionAreaTo: 501,
  constructionAreaKey: '',
  isNewConstruction: ['yes', 'no'],
  approvedBy: '',
  approvedAtFrom: '',
  approvedAtTo: '',
};
export const useStateFilterNeedApproval = () => {
  const [state, setState] = useSetState({
    ...blankStateFilter,
  });
  const setStateAt = useCallback(
    field => {
      return value => setState({ [field]: value });
    },
    [setState]
  );
  return { state, setStateAt, setState };
};
