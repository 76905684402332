import MyDropdown from '@components/myDropdown';
import React, { useCallback } from 'react';
import styles from '@components/table/table.module.scss';
import SelectionComponentFilter from '@components/filterDropdownComponentGroup/selectionComponentFilter';
import { useQuery } from '@apollo/client';
import { convertDataSelections } from '@utils/helper';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@utils/graphql';
import { useToast } from '@chakra-ui/react';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import MyToast from '@components/MyToast';

type Props = {
  value?: string,
  setValue?: Function,
  selections?: Array,
  provinceId?: string,
};
export default function DistrictDropdown({
  value = '',
  setValue = () => {},
  provinceId,
}: Props) {
  const { permissions } = useGetMe();
  const userPermission = permissions
    ?.find(e => e.id === 'view-product-list')
    ?.configs?.region?.filter(a => a.type === 'District')
    ?.map(q => q.id);
  const { data, loading } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
    variables: {
      provinceId,
      limit: 100,
    },
    skip: !provinceId,
  });
  const rawData = [...(data?.getListDistrictsOfProvince?.districts || [])];
  let finalData = [];
  if (!userPermission?.includes('all')) {
    finalData = rawData?.filter(e => userPermission?.includes(e.id));
  } else finalData = rawData;
  const dataSelections = convertDataSelections(finalData);
  const toast = useToast();
  const checkProvince = useCallback(
    value => {
      if (value) {
        if (!provinceId) {
          toast({
            position: 'bottom-right',
            duration: 5000,
            render: ({ id, onClose }) => (
              <MyToast
                id={id}
                onClose={onClose}
                wrapperStyle="w-394px shadow-md"
                type="error"
                message={'Vui lòng chọn Tỉnh/Thành phố trước'}
                closeButtonPos="center"
              />
            ),
          });
        }
      }
    },
    [provinceId]
  );
  return (
    <MyDropdown
      wrapperClass="w-full"
      buttonDropdownClassName={`w-full flex flex-1 justify-between items-center`}
      DropdownComponent={SelectionComponentFilter}
      dropdownContext={{
        selections: dataSelections,
        value,
        setValue,
        isShowSearchBox: true,
        loading,
        getObject: true,
      }}
    >
      {showDropdown => {
        checkProvince(showDropdown);
        return (
          <>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Quận/ Huyện
            </div>
            <img
              className={`w-12px h-10px ${
                showDropdown
                  ? 'transform rotate-180 duration-300'
                  : 'duration-300'
              }`}
              src="/svg/arrow-blue-filter.svg"
              alt="arrow"
            />
          </>
        );
      }}
    </MyDropdown>
  );
}
