import React from 'react';
import { getStatusProduct } from '@utils/helper';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core';
type Props = {
  status?: string,
};

export default function TriangleComponent({ status }: Props) {
  const { color, text } = getStatusProduct(status);
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: '#FFFFFF',
      color: '#22313E',
      boxShadow: '0px 1px 1px rgba(34, 34, 34, 0.25)',
    },
  }));
  const classes = useStyles();
  return (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
      }}
      className="absolute bottom-0 right-0"
      title={text}
    >
      <div className="flex flex-col items-end">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14H0L14 0"
            fill={color}
          />
        </svg>
      </div>
    </Tooltip>
  );
}
