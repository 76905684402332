import SearchBox from '@components/searchBox';
import { makeStyles } from '@material-ui/core';
import TreeItem from '@mui/lab/TreeItem';
import { TreeView } from '@mui/lab';
import React, { useCallback, useState, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDeepCompareEffect, useList } from 'react-use';
import Checkbox from '@components/checkbox';
import Fuse from 'fuse.js';
import { filterAndMapChildren } from '@utils/helper';
import MyButton from '@components/button';

const useTreeItemStyles = makeStyles(() => ({
  '@global': {
    '.MuiTreeItem-content.Mui-selected,.MuiTreeItem-content.Mui-selected.Mui-focused':
      {
        backgroundColor: '#FFE6B4',
      },
    '.MuiTreeItem-content:hover': {
      backgroundColor: '#FFE6B450',
    },
  },
  content: {
    flexDirection: 'row-reverse',
    marginTop: '15px',
    borderRadius: '8px',
    backgroundColor: '#EDF4FF',
    height: 40,
  },
  root: {
    position: 'relative',
    width: '100%',
    '&:before': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 14,
      left: -16,
      top: 35,
      borderBottom: props => (!props.rootNode ? `1px solid #B4B8BC` : 'none'),
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid #B4B8BC`,
  },
}));

type TreeItemProps = {
  labelText: string,
  rootNode?: boolean,
  indexText: string,
  info?: string,
  node: Object,
  onCheck: Function,
  push: Function,
  selectedItems: Array,
  disableCheckbox?: boolean,
  hideCheckbox?: boolean,
};

function getChildById(nodes, id) {
  let array = [];

  //returns an array of nodes ids: clicked node id and all children node ids
  function getAllChild(nodes) {
    if (nodes === null) return [];
    array.push(nodes.id);
    if (Array.isArray(nodes?.children)) {
      nodes?.children?.forEach(node => {
        array = [...array, ...getAllChild(node)];
        array = array.filter((v, i) => array.indexOf(v) === i);
      });
    }
    return array;
  }

  //returns the node object that was selected
  function getNodeById(nodes, id) {
    if (nodes.id === id) {
      return nodes;
    } else if (Array.isArray(nodes?.children)) {
      let result = null;
      nodes?.children.forEach(node => {
        if (getNodeById(node, id)) {
          result = getNodeById(node, id);
        }
      });
      return result;
    }

    return null;
  }

  return getAllChild(getNodeById(nodes, id));
}

function CustomTreeItem(props: TreeItemProps) {
  const classes = useTreeItemStyles(props);
  const {
    labelText,
    indexText,
    info,
    node,
    onCheck,
    selectedItems,
    push,
    disableCheckbox,
    hideCheckbox = false,
    ...other
  } = props;
  const children = node?.children;

  const isArrayPartialParent = useCallback(
    (arr, target) => {
      return target?.some(v => arr.includes(v));
    },
    [selectedItems]
  );
  const isArrayParent = useCallback(
    (arr, target) => {
      return target?.every(v => arr.includes(v));
    },
    [selectedItems]
  );
  return (
    <div className="flex flex-row">
      <TreeItem
        label={
          <div className="flex flex-row text-14 items-center space-x-2">
            <div className="flex">{indexText}</div>
            <div
              className={
                info ? 'underline text-blue-main' : 'flex flex-row items-center'
              }
            >
              <span>{labelText}</span>
            </div>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          group: classes.group,
          iconContainer: classes.iconContainer,
        }}
        {...other}
      >
        {Array.isArray(children)
          ? children?.map((childNode, index) => (
              <CustomTreeItem
                hideCheckbox={hideCheckbox}
                push={push}
                selectedItems={selectedItems}
                onCheck={onCheck}
                nodeId={childNode.id}
                key={childNode?.id}
                labelText={childNode?.name}
                node={childNode}
                indexText={`${indexText}.${index + 1}`}
                disableCheckbox={disableCheckbox}
              />
            ))
          : null}
      </TreeItem>
      {!hideCheckbox && (
        <Checkbox
          disabled={disableCheckbox}
          isPartialChecked={
            children?.length &&
            !isArrayParent(
              selectedItems,
              children?.map(p => p.id)
            ) &&
            isArrayPartialParent(
              selectedItems,
              children?.map(p => p.id)
            )
          }
          isChecked={
            (children?.length &&
              isArrayParent(
                selectedItems,
                children?.map(p => p.id)
              )) ||
            selectedItems.some(item => item === node?.id)
          }
          onPress={() => onCheck(node)}
          wrapperClass={`self-start mt-6 ml-2 ${
            disableCheckbox ? 'cursor-not-allowed' : ''
          }}`}
        />
      )}
    </div>
  );
}

type Props = {
  headerLabel?: String,
  searchPlaceHolder?: String,
  data?: Object,
  labelText?: String,
  selectedItems: Array,
  setItems: Object,
  allFlatItems: Array,
  disableCheckbox?: boolean,
  hideCheckbox?: boolean,
  onClose: Function,
};

export default function ProvinceTreeView(props: Props) {
  const {
    headerLabel,
    data = [],
    allFlatItems = [],
    searchPlaceHolder,
    selectedItems,
    setItems: { set, push },
    disableCheckbox = false,
    hideCheckbox = false,
    onClose,
  } = props;
  const [checkAll, setCheckAll] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [search, setSearch] = useState('');
  const [localList, { set: setLocalList, clear: clearLocalList }] = useList(
    selectedItems?.map(p => p.id)
  );
  const getOnChange = nodes => {
    //gets all freshly selected or unselected nodes
    const allNode = getChildById(nodes, nodes.id);
    const checked = localList?.some(e => e === nodes?.id);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = !checked
      ? [...localList, ...allNode]
      : localList.filter(value => !allNode?.includes(value));
    if (nodes?.parent && !array?.includes(nodes?.parent)) {
      array.push(nodes.parent);
    }
    setLocalList(array);
  };
  const handleSubmit = () => {
    if (checkAll) {
      set(['all']);
    } else {
      set(allFlatItems?.filter(p => localList?.includes(p.id)));
    }
    onClose();
  };
  useDeepCompareEffect(() => {
    if (localList?.length === allFlatItems?.length) {
      setCheckAll(true);
      setPartialCheck(false);
    } else if (
      localList?.length > 0 &&
      localList?.length < allFlatItems?.length
    ) {
      setPartialCheck(true);
      setCheckAll(false);
    } else {
      setCheckAll(false);
      setPartialCheck(false);
    }
  }, [localList, checkAll, partialCheck, setCheckAll, setPartialCheck]);
  const handleCheckAll = useCallback(() => {
    if (checkAll) {
      setCheckAll(false);
      setLocalList([]);
    } else {
      clearLocalList();
      setLocalList(allFlatItems?.map(p => p.id));
      setCheckAll(true);
      setPartialCheck(false);
    }
  }, [
    setLocalList,
    checkAll,
    setCheckAll,
    setPartialCheck,
    setLocalList,
    clearLocalList,
  ]);
  const fuse = useMemo(() => {
    return new Fuse(allFlatItems, {
      keys: ['name'],
      includeScore: false,
    });
  }, [allFlatItems]);

  const filteredResult = useMemo(() => {
    return search ? fuse.search(search)?.map(e => e.item) : allFlatItems;
  }, [search, allFlatItems, fuse]);

  const permissionTreeFiltered = filterAndMapChildren(
    data,
    filteredResult.map(e => e.id)
  );

  // const permissionSelections = filterAndMapChildren(
  //   permissionTreeFiltered,
  //   selectedItems
  // );
  return (
    <div className="w-full">
      <div className="p-4 flex flex-col">
        <span className="text-14 font-medium flex w-full">{headerLabel}</span>
        <div className="mt-4">
          <SearchBox
            setSearchValue={setSearch}
            placeholder={searchPlaceHolder ? searchPlaceHolder : ''}
            searchValue={search}
          />
        </div>
        <div className="justify-end flex w-full mt-4">
          {!disableCheckbox && (
            <Checkbox
              disabled={disableCheckbox}
              isChecked={checkAll}
              onPress={() => handleCheckAll()}
              title="Chọn tất cả"
              wrapperClass="flex-row-reverse space-x-reverse"
              titleClass="text-14 text-black-main"
              isPartialChecked={partialCheck}
            />
          )}
        </div>
      </div>
      <div
        className="w-600px flex flex-col p-4 overflow-y-auto"
        style={{ maxHeight: 362 }}
      >
        <TreeView
          className="w-full flex flex-col"
          defaultCollapseIcon={<ExpandLessIcon />}
          defaultExpandIcon={<ExpandMoreIcon />}
        >
          {permissionTreeFiltered?.map((node, index) => {
            return (
              <CustomTreeItem
                hideCheckbox={hideCheckbox}
                disableCheckbox={disableCheckbox}
                push={push}
                selectedItems={localList}
                onCheck={getOnChange}
                key={node?.id}
                rootNode={true}
                nodeId={node?.id}
                indexText={index + 1}
                labelText={node?.name}
                node={node}
              />
            );
          })}
        </TreeView>
      </div>
      <div className="border-b border-gray-300"></div>
      <div className="flex flex-row w-full justify-end space-x-4 p-4">
        <div className="w-147px">
          <MyButton onPress={onClose} type="gray" label="Trở về" />
        </div>
        <div className="w-147px">
          <MyButton
            onPress={() => handleSubmit()}
            type="blue"
            label="Xác nhận"
          />
        </div>
      </div>
    </div>
  );
}
