import { useCallback, useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useMutation } from '@apollo/client';
import { ADD_YOUTUBE_VIDEO } from '@utils/graphql';
import { isEmpty } from 'lodash';

export const useUploadMediaYoutube = ({ mediaUrls, setMediaUrls }) => {
  const [link, setLink] = useState(''),
    [youtubeFile, setFileYoutube] = useState(null),
    [debounceLink, setDebounceLink] = useState('');
  const [doUploadVideoYoutube, { loading, client }] =
    useMutation(ADD_YOUTUBE_VIDEO);

  const addVideoYoutube = useCallback(() => {
    const isExists = mediaUrls.find(
      e => e.fileId === youtubeFile?.payload.fileId
    );
    if (!isExists) {
      setMediaUrls(youtubeFile?.payload);
      setLink('');
      setFileYoutube(null);
    }
  }, [setMediaUrls, setLink, setFileYoutube, youtubeFile]);
  useDebounce(
    () => {
      setDebounceLink(link);
    },
    100,
    [link]
  );
  useEffect(() => {
    if (debounceLink.length > 0) {
      (async function () {
        try {
          const { data } = await doUploadVideoYoutube({
            variables: {
              youtubeUrl: debounceLink,
            },
          });
          await setFileYoutube(data?.addYoutubeVideo);
        } catch (e) {
          setFileYoutube(null);
        }
      })();
    }
  }, [debounceLink, client, doUploadVideoYoutube]);
  useEffect(() => {
    if (debounceLink.length === 0) {
      setFileYoutube(null);
    }
  }, [debounceLink]);

  const fileNotFound = debounceLink.length === 0 || isEmpty(youtubeFile);
  return { addVideoYoutube, loading, fileNotFound, youtubeFile, link, setLink };
};
