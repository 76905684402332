import RadioButton from '@components/radio';

type Props = {
  close?: Function,
  context?: {
    selections?: Array,
    value?: number,
    setValue?: Function,
  },
};
export default function Selector(props: Props) {
  const { close, context } = props,
    { selections, value, setValue } = context;
  return (
    <div
      className="flex flex-col border border-blue-main p-3 w-100px bg-white rounded overflow-y-auto space-y-2"
      style={{ maxHeight: 200 }}
    >
      {selections.map(item => {
        return (
          <RadioButton
            key={item}
            isChecked={value === item}
            onPress={() => {
              setValue(item);
              close();
            }}
            title={item}
            value={item}
          />
        );
      })}
    </div>
  );
}
