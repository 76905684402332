import React from 'react';
import Section from './section';
import moment from 'moment/moment';
import configs from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import { useGetLocation } from 'scenes/home/manageProduct/configs';

type Props = {
  data?: Object,
};

export default function DeadlineAndPostType({ data }: Props) {
  const { postTypes } = configs;
  const { isTabProductPremium } = useGetLocation();
  return (
    !isTabProductPremium && (
      <Section
        source="/svg/dueDate.svg"
        title="Thời hạn & Loại tin đăng"
        style={{ width: '100%' }}
        innerClass="space-y-4 mt-4"
      >
        <Item
          title="Loại tin đăng"
          value={
            postTypes?.find(
              item => item.key === data?.latestRenewInfo?.postType?.toString()
            )?.value
          }
        />
        <Item
          title="Thời hạn"
          value={`${moment
            .unix(parseInt(data?.latestRenewInfo?.startTime, 10))
            .format('DD/MM/YYYY')} - ${moment
            .unix(parseInt(data?.latestRenewInfo?.endTime, 10))
            .format('DD/MM/YYYY')}`}
          value2={`(${
            data?.remainingActivePost.includes('hết hạn')
              ? data?.remainingActivePost
              : `còn lại ${data?.remainingActivePost}`
          })`}
        />
      </Section>
    )
  );
}

type ItemProps = {
  title?: string,
  value?: string,
  valueStyle?: Object,
  value2?: string,
};

function Item({ title, value, valueStyle, value2 }: ItemProps) {
  return (
    <div className="flex w-full py-2 border-b border-gray-300 space-x-10">
      <div className="w-150px text-sm font-medium text-black-main">{title}</div>
      <div className={`flex-1 text-sm ${valueStyle}`}>
        {value} <span className="opacity-50">{value2}</span>
      </div>
    </div>
  );
}
