export const useSwitchTabConfigs = () => {
  return [
    {
      key: 'ContentUserDetail',
      value: 'Thông tin nhân viên',
    },
    {
      key: 'UserPermission',
      value: 'Quyền của nhân viên',
    },
    {
      key: 'EditUserHistory',
      value: 'Lịch sử chỉnh sửa thông tin',
    },
    {
      key: 'PermissionHistory',
      value: 'Lịch sử bộ quyền',
    },
    {
      key: 'InteractionHistory',
      value: 'Lịch sử tương tác',
    },
  ];
};
