import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE } from '@utils/graphql';
import { useGlobalContext } from '../../../context';
import { useState } from 'react';
import { useLoadByPagination } from '@utils/hooks/common/useLoadByPagination';
import { cleanObj } from '@utils/helper';
import moment from 'moment/moment';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { useGetLocation } from '../../../scenes/home/manageProduct/configs';
import { useGetMe } from '../../hooks/common/useGetMe';
import { PAGINATION_LIMIT } from 'constant';

export const pickByFromTo = (from, to) => {
  let floatFrom,
    floatTo,
    t = {};
  if (from !== null && to !== 0) {
    floatFrom = parseFloat(from);
    floatTo = parseFloat(to);

    t = pickBy(
      { from: floatFrom > 0 && floatFrom, to: floatTo > 0 && floatTo },
      e => e
    );
  } else {
    t = { from: null, to: 0 };
  }
  return isEmpty(t) ? null : t;
};
const getPriceFromToValue = (from, to, isForSale) => {
  const amount = isForSale === 'sale' ? 1000000000 : 1000000;
  if (!from && !to) {
    return null;
  }
  if (from && to) {
    return { from: from * amount, to: to * amount };
  }
  if (from) {
    return { from: from * amount };
  }
  if (to) {
    return { to: to * amount };
  }
};

export const getVariable = state => {
  const { isTabProductAuthorize } = useGetLocation();
  const finalVariables = {
    isAuthorizedRe: isTabProductAuthorize,
    searchString: state?.searchString,
    realEstateId: state?.realEstateId,
    typeOfDemand: state?.isForSell,
    realEstateType: state?.realEstateType,
    postStatus: state?.postStatus ? state?.postStatus : null,
    province: state?.province ? state?.province : null,
    district: state?.district ? state?.district : null,
    ward: state?.ward ? state?.ward : null,
    street: state?.street ? state?.street : null,
    houseNumber: state?.houseNumber,
    createdAt:
      !state.createdAtFrom || !state.createdAtTo
        ? null
        : {
            from: moment.unix(state.createdAtFrom).format(),
            to: moment.unix(state.createdAtTo).endOf('day').format(),
          },
    updatedAt:
      !state.updatedAtFrom || !state.updatedAtTo
        ? null
        : {
            from: moment.unix(state.updatedAtFrom).format(),
            to: moment.unix(state.updatedAtTo).endOf('day').format(),
          },
    price: getPriceFromToValue(
      state?.priceFrom,
      (state?.priceTo > 100 && state?.isForSell) ||
        (state?.priceTo > 200 && !state?.isForSell)
        ? null
        : state?.priceTo,
      state?.isForSell
    ),
    contactName: state?.contactName,
    ownerName: state?.ownerName,
    ownerPhone: state?.ownerPhone,
    commission: state?.commission,
    floorCount: pickByFromTo(
      state?.floorCountFrom,
      state?.floorCountTo > 168 ? null : state?.floorCountTo
    ),
    bedroomCount: pickByFromTo(
      state?.bedroomCountFrom,
      state?.bedroomCountTo > 168 ? null : state?.bedroomCountTo
    ),
    toiletCount: pickByFromTo(
      state?.toiletCountFrom,
      state?.toiletCountTo > 168 ? null : state?.toiletCountTo
    ),
    constructionArea: pickByFromTo(
      state?.constructionAreaFrom,
      state?.constructionAreaTo > 20000 ? null : state?.constructionAreaTo
    ),
    frontHomeAlleyWidth: pickByFromTo(
      state?.frontHomeAlleyWidthFrom,
      state?.frontHomeAlleyWidthTo > 100 ? null : state?.frontHomeAlleyWidthTo
    ),
    width: pickByFromTo(
      state?.widthFrom,
      state?.widthTo > 100 ? null : state?.widthTo
    ),
    length: pickByFromTo(
      state?.lengthFrom,
      state?.lengthTo > 500 ? null : state?.lengthTo
    ),
    rear: pickByFromTo(
      state?.rearFrom,
      state?.rearTo > 100 ? null : state?.rearTo
    ),
    area: pickByFromTo(
      state?.areaFrom,
      state?.areaTo > 20000 ? null : state?.areaTo
    ),
    approvedAt:
      !state.approvedAtFrom || !state.approvedAtTo
        ? null
        : {
            from: moment.unix(state.approvedAtFrom).format(),
            to: moment.unix(state.approvedAtTo).endOf('day').format(),
          },
    approvedBy: state?.approvedBy ? state?.approvedBy : null,
    creatorName: state?.creator ? state?.creator : null,
    sort: { approvedAt: -1 },
    page: 1,
    limit: PAGINATION_LIMIT,
  };
  finalVariables.direction =
    state?.direction?.length === 8 ? null : state?.direction;
  return { ...cleanObj(finalVariables) };
};

export const useGetRealEstatePosted = ({ state }) => {
  const { company } = useGetMe();
  const [loading, setLoading] = useState(false);
  const variable = getVariable(state);
  const { isLogin } = useGlobalContext();
  const { data, fetchMore } = useQuery(GET_REAL_ESTATE, {
    variables: {
      ...variable,
      company,
    },
    skip: !isLogin,
  });
  const dataRealEstatePosted = data?.getRealEstates?.items || [];
  const currentPage = data?.getRealEstates?.page || 1;
  const totalItem = data?.getRealEstates?.total;
  const totalPage = data?.getRealEstates?.totalPages;
  const rowsPerPage = data?.getRealEstates?.limit;
  const currentCountFrom = data?.getRealEstates?.pagingCounter;
  const currentCountTo = currentCountFrom + dataRealEstatePosted.length - 1;
  const hasPrevPage = data?.getRealEstates?.hasPrevPage;
  const hasNextPage = data?.getRealEstates?.hasNextPage;
  const onLoadByPagination = currentPage =>
    useLoadByPagination({
      loading,
      setLoading,
      fetchMore,
      currentPage,
    });
  return {
    dataRealEstatePosted,
    loading,
    onLoadByPagination,
    currentPage,
    totalItem,
    totalPage,
    rowsPerPage,
    currentCountFrom,
    currentCountTo,
    hasPrevPage,
    hasNextPage,
  };
};
