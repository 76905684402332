import CheckboxUsingTable from '@components/checkboxUsingTable';
import MyDropdown from '@components/myDropdown';
import Pagination from '@components/pagination';
import Table from '@components/table';
import UserInfo from '@components/userInfo';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { View } from 'react-native-web';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermissionGroupsContext } from '../permissionGroups/context';
import permissionGroupsStyles from './permissionGroups.module.scss';
import RenderAvatarComponent from './renderAvatarComponent';
import RenderHeader from './renderHeader';
import queryString from 'query-string';

export const checkVisibleCol = id => {
  const { listField } = usePermissionGroupsContext();
  return listField?.includes(id);
};

ItemTablePermissionGroup.propTypes = {
  loading: PropTypes.bool,
  index: PropTypes.number,
  onSelect: PropTypes.func,
  onSingleSelect: PropTypes.func,
  isChecked: PropTypes.string,
  item: PropTypes.object,
  pagination: PropTypes.object,
};
function ItemTablePermissionGroup({
  loading,
  index,
  onSelect,
  onSingleSelect,
  isChecked,
  item,
  pagination,
}) {
  const { code, name, creator, createdAt } = item || {};

  return (
    <div className="w-full h-40px flex flex-row items-center border-b border-gray-300 cursor-pointer">
      {loading ? (
        <div className="flex-1 h-6 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div
          style={{ backgroundColor: isChecked ? '#FFE6B4' : undefined }}
          className={permissionGroupsStyles.row}
        >
          <div
            onClick={onSingleSelect}
            className="flex flex-row items-center flex-1"
          >
            <div
              className={`${permissionGroupsStyles.itemTable} items-center justify-center text-sm w-60px`}
            >
              <CheckboxUsingTable isChecked={isChecked} onPress={onSelect} />
            </div>
            <div
              className={`${permissionGroupsStyles.itemTable} text-sm w-60px flex items-center justify-center`}
            >
              {(pagination.currentPage - 1) * pagination.rowsPerPage +
                index +
                1}
            </div>
            <div
              className={`${permissionGroupsStyles.itemTable} relative flex-1`}
            >
              {code}
            </div>
            <div
              className={`${permissionGroupsStyles.itemTable} relative flex-1`}
            >
              {name}
            </div>
            {checkVisibleCol('creator') && (
              <div
                className={`${permissionGroupsStyles.itemTable} relative flex-1 truncate`}
              >
                {!isEmpty(creator) ? (
                  <MyDropdown
                    DropdownComponent={UserInfo}
                    dropdownContext={{ userId: item?.creator?.id }}
                  >
                    <div className="flex space-x-2 items-center">
                      <RenderAvatarComponent
                        wrapperStyle="w-30px h-30px"
                        avatarId={item?.creator?.avatar?.fileId}
                      />
                      <span className="text-14 font-text text-blue-main underline truncate">
                        {item?.creator?.fullName}
                      </span>
                      <img src="/svg/eye.svg" alt="icon" />
                    </div>
                  </MyDropdown>
                ) : (
                  <span className="italic text-gray-500">Không có</span>
                )}
              </div>
            )}
            {checkVisibleCol('createdAt') && (
              <div
                className={`${permissionGroupsStyles.itemTable} relative flex-1 truncate`}
              >
                {moment(createdAt).format('hh:mmA,DD/MM/YYYY')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

RenderTablePermissionGroups.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
};
export default function RenderTablePermissionGroups({
  data,
  loading,
  pagination,
}) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { listSelected, setListSelected } = usePermissionGroupsContext();
  const handleCheck = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected?.set(listSelected?.filter(item => item !== key));
      } else {
        setListSelected?.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );
  const handleOnSingleSelect = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        setListSelected?.set(listSelected.filter(item => item !== key));
      } else {
        setListSelected?.set([...(listSelected || []), key]);
      }
    },
    [setListSelected, listSelected]
  );

  const handleOnPageChange = event => {
    setListSelected.clear();
    navigate(
      `.?${queryString.stringify({
        ...queryString.parse(search),
        page: event.selected,
      })}`
    );
  };

  return (
    <>
      <View
        style={{
          width: '100%',
          maxHeight: 440,
          backgroundColor: 'white',
          marginTop: 16,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#2459ad',
          overflow: 'hidden',
        }}
      >
        <Table>
          <View style={{ width: '100%' }}>
            <RenderHeader data={data} loading={loading} />
            {data?.map((item, index) => {
              const isChecked = listSelected.find(
                selectedId => selectedId === item?.id
              );
              return (
                <ItemTablePermissionGroup
                  item={item}
                  loading={loading}
                  isChecked={isChecked}
                  onSelect={() => handleCheck(item?.id, isChecked)}
                  onSingleSelect={() =>
                    handleOnSingleSelect(item?.id, isChecked)
                  }
                  key={index}
                  index={index}
                  pagination={pagination}
                />
              );
            })}
            {!loading && !data?.length && (
              <p className="text-base m-3">Không có nhóm quyền</p>
            )}
          </View>
        </Table>
      </View>
      <div className="w-full flex justify-end py-6">
        <Pagination
          loading={loading}
          pagination={pagination}
          onPageChange={handleOnPageChange}
        />
      </div>
    </>
  );
}
