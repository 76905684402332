import { useGetInternalRealEstateDetail } from '@utils/hooks/manageProduct/useGetInternalRealEstateDetails';
import { useDeepCompareEffect } from 'react-use';
import { isEmpty } from 'lodash';
import { getMedias, getNameUrlFromSever } from '@utils/helper';
import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_ATTACHMENTS } from '@utils/graphql';

export const useSetFormState = (id, reset, isPublished = false) => {
  const { finalData, loading } = useGetInternalRealEstateDetail(id);
  const { data: dataAttachments } = useQuery(GET_PRODUCT_ATTACHMENTS, {
    variables: {
      realEstateId: id,
    },
    skip: !id,
  });
  const attachmentMedia = dataAttachments?.getProductAttachments?.attachments;
  const initialValue = useRef({});
  useDeepCompareEffect(() => {
    if (!isEmpty(finalData) && !loading) {
      (async function () {
        const mediaUrls = [...(finalData?.mediaUrls || [])];
        const medias = await getMedias(mediaUrls || []);
        const attachmentsRemote = await getMedias(attachmentMedia || []);
        const newArray = attachmentsRemote?.map(
          ({ fileId, originalUrl, type, urls }) => ({
            fileId: fileId,
            name: getNameUrlFromSever(originalUrl),
            type: type,
            urls: urls,
          })
        );
        const initialStateProduct = {
          isForSell: finalData.typeOfDemand === 'sale' ? 'forSale' : 'forRent',
          typeRealEstate: finalData?.type,
          contactName: finalData?.contactName,
          contactEmail: finalData.contactEmail || '',
          note: finalData.privateNote || '',
          province: finalData?.province?.id || '',
          district: finalData?.district?.id || '',
          ward: finalData?.ward?.id || '',
          houseNumber: finalData?.houseNumber || '',
          street: finalData?.street?.id || '',
          lat: finalData?.map?.coordinates[1] || '',
          long: finalData?.map?.coordinates[0] || '',
          buildingType: finalData?.buildingType || '',
          warehouseType: finalData?.warehouseType || '',
          warehouseRoomCount: finalData?.warehouseRoomCount || 1,
          hasAlley: finalData.hasAlley,
          alleyTurnCount: finalData?.alleyTurnCount || 1,
          alleyWidth: finalData?.alleyWidth?.toString() || '',
          timeForRent: '',
          timeForRentUnit: '',
          width: finalData.width?.toString() || '',
          length: finalData.length?.toString() || '',
          rear: finalData.rear?.toString() || '',
          floorCount: finalData.floorCount?.toString() || 1,
          block: finalData?.block?.toString() || '',
          roomNumber: finalData?.roomNumber?.toString() || '',
          roomCount: finalData.roomCount?.toString() || 1,
          hotelStar: finalData.hotelStar || 1,
          onFloor: finalData?.onFloor?.toString() || 1,
          shophouseField: finalData?.shophouseField || '',
          hasMezzanine: finalData.hasMezzanine || false,
          hasSeaView: finalData?.hasSeaView === 1,
          villaView: finalData?.villaView,
          isNewConstruction: finalData.isNewConstruction,
          price: finalData.price?.toString() || '',
          commission: finalData.commission || '',
          bedRoomArea: finalData.bedRoomArea?.toString() || '',
          constructionArea: finalData.constructionArea?.toString() || '',
          bedroomCount: finalData.bedroomCount?.toString() || 1,
          toiletCount: finalData.toiletCount?.toString() || 1,
          direction: finalData.direction || '',
          project: finalData.project || '',
          status: finalData.status || '',
          career: finalData.career || '',
          legal: finalData.legals || [],
          mediaUrls: [...(medias || [])],
          attachments: newArray,
          contactPhones: finalData?.contactPhones?.map(e => ({ phone: e })),
          utilities: finalData.utilities || [],
          fixedUtilities: finalData.fixedUtilities || [],
          localUtilities: finalData.localUtilities || [],
          equipmentsAndServices: finalData.equipmentsAndServices || [],
        };
        if (isPublished) {
          initialStateProduct.originalProductId = id;
          initialStateProduct.privateNote = '';
          initialStateProduct.postTitle = '';
          initialStateProduct.postContent = '';
          initialStateProduct.postType = '3';
          initialStateProduct.contactName = '';
          initialStateProduct.contactPhones = [];
          initialStateProduct.contactEmail = '';
          initialStateProduct.ownerName = finalData?.contactName || '';
          initialStateProduct.ownerPhone = finalData?.contactPhones?.map(e => ({
            phone: e,
          }));
          initialStateProduct.ownerEmail = finalData?.contactEmail || '';
          initialStateProduct.priceOfOwner = finalData?.price;
          initialStateProduct.price = '';
          delete initialStateProduct.status;
        }
        initialValue.current = { ...initialStateProduct };
        reset(initialStateProduct);
      })();
    }
  }, [finalData, initialValue, reset, loading]);
  return { initialValue: initialValue.current };
};
