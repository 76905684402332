import Label from '@components/label';
import { Controller, useFormContext } from 'react-hook-form';
import MyTextAreaInput from '@components/textAreaInput';
import React from 'react';
import ContractDocuments from '../../../scenes/home/createB2BUserAccount/components/contractDocuments';

export default function Content() {
  const { control } = useFormContext();
  return (
    <div className="w-full flex flex-col mb-2.5 mt-8">
      <div className="flex flex-row items-start space-x-2">
        <Label wrapperClass="w-230px" label="Ghi chú" />
        <Controller
          control={control}
          name="note"
          render={({ field, field: { value, onChange } }) => {
            return (
              <MyTextAreaInput
                {...field}
                value={value || ''}
                onChange={onChange}
                onDelete={() => onChange('')}
                rows={3}
                maxlength={888}
                placeholder="Nhập giá trị"
                inputClassName="flex-1"
              />
            );
          }}
        />
      </div>
      <ContractDocuments
        isEdit={true}
        isHideTopLabel
        isShowCountFile
        label="Tài liệu hợp đồng đính kèm"
      />
    </div>
  );
}
