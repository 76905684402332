import styles from '@components/table/table.module.scss';
import moment from 'moment/moment';
import { contractStatusConfigs } from '../../../../../../../../configs';

type RenderTagProps = {
  value?: any,
  removeValue?: Function,
};
export function RenderTag({ value, removeValue }: RenderTagProps) {
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div className="tagFilterResult">
      <div className="text-13 truncate text-black-main">{value}</div>
      {removeValue && (
        <img
          onClick={() => {
            removeValue();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  );
}

type Props = {
  stateFilter?: Object,
  setStateFilterAt?: Function,
};
export default function RenderResultFilterHeader({
  stateFilter,
  setStateFilterAt,
}: Props) {
  const { contractCode, status, endDateFrom, endDateTo } = stateFilter;
  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div className={`${styles.headContainer} w-213px items-center `}>
          <RenderTag
            value={contractCode}
            removeValue={() => setStateFilterAt('contractCode')('')}
          />
        </div>
        <div
          style={{ maxWidth: 300 }}
          className={`${styles.headContainer} w-212px items-center `}
        />
        <div className={`${styles.headContainer} w-212px items-center `}>
          <RenderTag
            value={
              endDateFrom && endDateTo
                ? `${moment.unix(endDateFrom).format('DD/MM/YYYY')} - ${moment
                    .unix(endDateTo)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('endDateFrom')('');
              setStateFilterAt('endDateTo')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-212px items-center `}>
          <RenderTag
            value={
              status?.length === 2
                ? 'Tất cả'
                : status?.length === 1
                ? contractStatusConfigs.find(e => e.key === status?.[0])?.value
                : ''
            }
            removeValue={() => setStateFilterAt('status')([])}
          />
        </div>
        <div className={`${styles.headContainer} w-212px items-center `} />
      </div>
    </div>
  );
}
