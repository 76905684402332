import Checkbox from '@components/checkbox';
import SearchBox from '@components/searchBox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Box, styled, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const StyledTreeView = styled(TreeView)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));
const StyledTreeItemText = styled(TreeItem)(({ rootnode, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  '& > .MuiTreeItem-content': {
    backgroundColor: `${selected ? '#ffe6b4' : '#edf4ff'} !important`,
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 40,
    borderRadius: 8,
    paddingLeft: 15,
    paddingRight: 15,
    position: 'relative',
    zIndex: 2,
    '&:before': {
      display: rootnode ? 'none' : 'flex',
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 17,
      height: 1,
      left: -22,
      bottom: '50%',
      transform: 'translateY(50%)',
      backgroundColor: '#b4b8bc',
      zIndex: 1,
    },
    '&:hover': {
      backgroundColor: '#ffe6b450',
    },
    '& >.MuiTreeItem-label': {
      fontSize: 14,
    },
  },
  '& > .MuiCollapse-root': {
    marginLeft: 16,
    paddingLeft: 22,
    borderLeft: '1px solid #b4b8bc',
    '& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
  },
}));
const StyledTreeItemCheckbox = styled(TreeItem)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  '& > .MuiTreeItem-content': {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 40,
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '& > .MuiTreeItem-iconContainer': {
      display: 'none',
    },
    '& >.MuiTreeItem-label': {
      paddingLeft: 0,
    },
  },
  '& > .MuiCollapse-root': {
    marginLeft: 0,
    '& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
  },
}));

function mapId(data) {
  return data?.map(nodeItem => nodeItem.id);
}
function recursiveGetFullChildren(node, result = []) {
  result.push(...(node.children ?? []));
  node.children?.forEach(child => recursiveGetFullChildren(child, result));
  return result;
}
function recursiveFlatten(node, result = []) {
  result.push(node);
  node.fullChildren = recursiveGetFullChildren(node);
  node.children?.forEach(child => recursiveFlatten(child, result));
  return result;
}
function flattenData(data, result = []) {
  data?.forEach(nodeItem => {
    result.push(...recursiveFlatten(nodeItem));
  });
  return result;
}

MyTreeView.propTypes = {
  title: PropTypes.string,
  checkable: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceHolder: PropTypes.string,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  data: PropTypes.array,
  setStorage: PropTypes.object,
  defaultCheckedIds: PropTypes.array,
};
function MyTreeView({
  title,
  checkable,
  searchable,
  searchPlaceHolder,
  minHeight,
  maxHeight,
  data,
  setStorage,
  defaultCheckedIds,
}) {
  const [flattenedNodes, setFlattenedNodes] = useState([]);
  const [search, setSearch] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [checkedIds, setCheckedIds] = useState(defaultCheckedIds);

  const isEmptyChildren = id =>
    _.isEmpty(
      mapId(flattenedNodes.find(nodeItem => nodeItem.id === id)?.fullChildren)
    );
  const isCheckedEveryChildren = id =>
    _.every(
      mapId(flattenedNodes.find(nodeItem => nodeItem.id === id)?.fullChildren),
      item => checkedIds?.includes(item)
    );
  const isCheckedSomeChildren = id =>
    _.some(
      mapId(flattenedNodes.find(nodeItem => nodeItem.id === id)?.fullChildren),
      item => checkedIds?.includes(item)
    );
  const isPartialChecked = id =>
    isCheckedSomeChildren(id) && !isCheckedEveryChildren(id);
  const isChecked = id =>
    _.includes(checkedIds, id) ||
    (isCheckedEveryChildren(id) && !isEmptyChildren(id));
  const isCheckedAll = _.size(checkedIds) === _.size(flattenedNodes);
  const isPartialCheckedAll =
    _.size(checkedIds) < _.size(flattenedNodes) && !_.isEmpty(checkedIds);

  function getAllPartialCheckedIds() {
    let result = [];
    flattenedNodes.filter(nodeItem => {
      if (isPartialChecked(nodeItem.id) || isChecked(nodeItem.id)) {
        result.push(nodeItem.id);
      }
    });
    return result;
  }
  function handleOnClickCheckedAllCheckbox() {
    if (isCheckedAll) {
      setCheckedIds([]);
    } else {
      setCheckedIds(mapId(flattenedNodes));
    }
  }
  function handleOnClickCheckbox(selectedId) {
    const selectedNode = flattenData(data).find(
      nodeItem => nodeItem.id === selectedId
    );
    const selectedNodeAndChildrenIds = mapId([
      ...(selectedNode.fullChildren ?? []),
      selectedNode,
    ]);
    if (isChecked(selectedId)) {
      const checkedNodeIds = _.difference(
        checkedIds,
        selectedNodeAndChildrenIds
      );
      setCheckedIds(checkedNodeIds);
    } else {
      const checkedNodeIds = _.union(checkedIds, selectedNodeAndChildrenIds);
      setCheckedIds(checkedNodeIds);
    }
  }
  useEffect(() => {
    setStorage?.set(_.union(checkedIds, getAllPartialCheckedIds()));
  }, [checkedIds]);
  useEffect(() => {
    setFlattenedNodes(flattenData(data));
  }, [data]);

  function renderTreeViewText(data) {
    return data?.map((nodeItem, i) => {
      return (
        <StyledTreeItemText
          rootnode={nodeItem.level === 1 ? 1 : 0}
          key={nodeItem.id}
          nodeId={nodeItem.id}
          selected={isChecked(nodeItem.id) || isPartialChecked(nodeItem.id)}
          label={
            <Box display="flex" alignItems="center" gap="10px">
              {i + 1}. {nodeItem.name}
              {!_.isEmpty(nodeItem.children) &&
                ` (${_.size(nodeItem.children)} quyền)`}
              {!_.isEmpty(nodeItem.configs) && (
                <Box
                  component="img"
                  srcSet="/svg/setting_new.svg"
                  alt="config"
                  className="w-4 h-4"
                />
              )}
            </Box>
          }
        >
          {renderTreeViewText(nodeItem.children)}
        </StyledTreeItemText>
      );
    });
  }
  function renderTreeViewCheckbox(data) {
    return data?.map(nodeItem => (
      <StyledTreeItemCheckbox
        key={nodeItem.id}
        nodeId={nodeItem.id}
        label={
          <Checkbox
            titleClass="hidden"
            isChecked={isChecked(nodeItem.id)}
            isPartialChecked={isPartialChecked(nodeItem.id)}
            onPress={() => handleOnClickCheckbox(nodeItem.id)}
          />
        }
      >
        {renderTreeViewCheckbox(nodeItem.children)}
      </StyledTreeItemCheckbox>
    ));
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="30px"
      minHeight={minHeight}
      maxHeight={maxHeight}
      sx={{
        overflow: 'hidden',
      }}
    >
      {title && <Typography fontWeight="bold">{title}</Typography>}
      {searchable && (
        <SearchBox
          placeholder={searchPlaceHolder}
          searchValue={search}
          setSearchValue={setSearch}
          inputClassName="px-2"
        />
      )}
      {checkable && (
        <Checkbox
          title="Chọn tất cả"
          titleClass="text-14 text-black-main"
          wrapperClass="flex-row-reverse space-x-reverse space-x-3 px-2"
          isChecked={isCheckedAll}
          isPartialChecked={isPartialCheckedAll}
          onPress={handleOnClickCheckedAllCheckbox}
        />
      )}
      <Box
        display="flex"
        flexDirecion="column"
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="12px"
          width="100%"
          height="100%"
          paddingX="8px"
        >
          <StyledTreeView
            disableSelection
            defaultCollapseIcon={<ExpandLessIcon />}
            defaultExpandIcon={<ExpandMoreIcon />}
            sx={{ flex: 1 }}
            onNodeToggle={(event, nodeIds) => {
              setExpandedIds(nodeIds);
            }}
          >
            {renderTreeViewText(data)}
          </StyledTreeView>
          {checkable && (
            <StyledTreeView
              disableSelection
              disabledItemsFocusable
              expanded={expandedIds}
            >
              {renderTreeViewCheckbox(data)}
            </StyledTreeView>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default MyTreeView;
