import { useQuery } from '@apollo/client';
import { GET_PERMISSION_GROUP_DETAILS } from '@utils/graphql';

export const useGetPermissionGroupDetails = permissionGroupId => {
  const { data, loading } = useQuery(GET_PERMISSION_GROUP_DETAILS, {
    variables: {
      permissionGroupId,
    },
    skip: !permissionGroupId,
  });
  const finalData = data?.permissionGroupDetails;
  return { data: finalData, loading: loading };
};
