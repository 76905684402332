import styles from '@components/table/table.module.scss';
import React, { useCallback } from 'react';
import RenderResultFilterHeader from './renderResultFilterHeader';
import CheckboxUsingTable from '@components/checkboxUsingTable';
import { useManageUserB2BContext } from '../context';
import { View } from 'react-native-web';
import SearchTextDropdown from '../../../manageRealEstate/components/filterButtons/searchTextDropdown';
import StreetDropdown from '../../../manageRealEstate/components/filterButtons/streetDropdown';
import WardDropdown from '../../../manageRealEstate/components/filterButtons/wardDropdown';
import DistrictDropdown from '../../../manageRealEstate/components/filterButtons/districtDropdown';
import ProvinceDropdown from '../../../manageRealEstate/components/filterButtons/provinceDropdown';
import CalendarDropdown from '../../../manageRealEstate/components/filterButtons/calendarDropdown';
import DropdownMultiSelection from '@components/dropdownMultiSelection';
import { userTypeConfig } from '../../../../../configs';

type Props = {
  data?: Array,
  loading?: boolean,
};
export default function RenderHeader({ data, loading }: Props) {
  const { listSelected, setListSelected, stateFilter, setStateFilterAt } =
    useManageUserB2BContext();
  const isCheckAll = listSelected?.length === data?.length;
  const handleCheckAll = useCallback(() => {
    if (isCheckAll) {
      setListSelected.set([]);
    } else {
      setListSelected.set([
        ...(listSelected || []),
        ...(data.map(e => e.id) || []),
      ]);
    }
  }, [setListSelected, isCheckAll, data]);
  return (
    <View
      style={{
        borderTopLeftRadius: 9,
        borderTopRightRadius: 9,
        width: '100%',
      }}
    >
      <View style={{ width: '100%', backgroundColor: '#EDF4FF' }}>
        <View
          style={{
            width: '100%',
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <CheckboxUsingTable
              disabled={loading}
              isChecked={isCheckAll}
              onPress={handleCheckAll}
              isPartialCheck={listSelected[0]?.length >= 1}
            />
          </div>
          <div
            className={`${styles.headContainer} w-60px items-center justify-center`}
          >
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              STT
            </div>
          </div>
          <div className={`${styles.headContainer} w-202px items-center`}>
            <SearchTextDropdown
              title="Mã người dùng"
              search={stateFilter.employeeCode}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('employeeCode')(value);
              }}
            />
          </div>
          <div className={`${styles.headContainer} w-232px items-center`}>
            <SearchTextDropdown
              title="Tên người dùng"
              search={stateFilter.fullName}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('fullName')(value);
              }}
            />
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <DropdownMultiSelection
              selections={userTypeConfig}
              value={stateFilter?.userType}
              setValue={value => setStateFilterAt('userType')(value)}
              title="Loại người dùng"
            />
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <div
              className={`text-13 font-medium text-blue-main ${styles.textHead}`}
            >
              Số điện thoại
            </div>
          </div>

          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <ProvinceDropdown
              value={stateFilter.province}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('province')(item.key);
                setStateFilterAt('provinceName')(item.value);
                if (item.key !== stateFilter?.province) {
                  [
                    'district',
                    'districtName',
                    'ward',
                    'wardName',
                    'street',
                    'streetName',
                  ].forEach(item => {
                    setStateFilterAt(item)('');
                  });
                }
              }}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <DistrictDropdown
              value={stateFilter.district}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('district')(item.key);
                setStateFilterAt('districtName')(item.value);
                if (item.key !== stateFilter?.district) {
                  ['ward', 'wardName', 'street', 'streetName'].forEach(item => {
                    setStateFilterAt(item)('');
                  });
                }
              }}
              provinceId={stateFilter?.province}
            />
          </div>
          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <WardDropdown
              value={stateFilter.ward}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('ward')(item.key);
                setStateFilterAt('wardName')(item.value);
              }}
              districtId={stateFilter.district}
              streetId={stateFilter.street}
            />
          </div>

          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <StreetDropdown
              value={stateFilter.street}
              setValue={item => {
                setListSelected.clear();
                setStateFilterAt('street')(item.key);
                setStateFilterAt('streetName')(item.value);
              }}
              wardId={stateFilter.ward}
              districtId={stateFilter.district}
            />
          </div>

          <div className={`${styles.headContainer} w-175px items-center`}>
            <SearchTextDropdown
              title="Số nhà"
              search={stateFilter.houseNumber}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('houseNumber')(value);
              }}
            />
          </div>

          <div
            className={`${styles.headContainer} w-175px items-center justify-center`}
          >
            <CalendarDropdown
              start={stateFilter.createdAtFrom}
              end={stateFilter.createdAtTo}
              setStart={value => setStateFilterAt('createdAtFrom')(value)}
              setEnd={value => setStateFilterAt('createdAtTo')(value)}
              title="Ngày đăng kí"
              onResetSelected={() => {
                setListSelected.clear();
              }}
            />
          </div>
          <div className={`${styles.headContainer} w-175px items-center`}>
            <SearchTextDropdown
              title="Email"
              search={stateFilter.email}
              setSearch={value => {
                setListSelected.clear();
                setStateFilterAt('email')(value);
              }}
            />
          </div>
        </View>
      </View>
      <RenderResultFilterHeader
        stateFilter={stateFilter}
        setStateFilterAt={setStateFilterAt}
      />
    </View>
  );
}
