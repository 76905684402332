import React from 'react';

export const ManageEmployeeContext = React.createContext({
  listField: [],
  setListField: {},
  listSelected: [],
  setListSelected: {},
  stateFilter: {},
  setStateFilterAt: () => {},
  tab: '',
  setTab: () => {},
  setState: () => {},
});

export const useManageEmployeeContext = () =>
  React.useContext(ManageEmployeeContext);
