import MyButton from '@components/button';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useCreateB2BAdminUser } from '@utils/hooks/manageUserB2B/useCreateB2BAdminUser';
import { useUpdateB2BAdminUser } from '@utils/hooks/manageUserB2B/useUpdateB2BAdminUser';

type Props = {
  isUpdate?: boolean,
  idAdmin?: string,
  onBack?: Function,
};
export default function Submit({ isUpdate, idAdmin, onBack }: Props) {
  const { handleSubmit } = useFormContext();
  const { onSubmit, loading } = useCreateB2BAdminUser();
  const { onSubmit: onSubmitUpdate, loading: loadingUpdate } =
    useUpdateB2BAdminUser(idAdmin);
  return (
    <div className="w-full bg-white h-61px flex flex-row mt-5 items-center justify-end p-x-6 space-x-2 sticky bottom-0 border-t border-gray-300">
      <div className="w-147px">
        <MyButton
          disabled={loading}
          onPress={onBack}
          label="Trở về"
          type="gray"
        />
      </div>
      <div className="w-147px">
        <MyButton
          disabled={isUpdate ? loadingUpdate : loading}
          onPress={handleSubmit(isUpdate ? onSubmitUpdate : onSubmit)}
          label={isUpdate ? 'Cập nhật' : 'Lưu'}
          type="blue"
        />
      </div>
    </div>
  );
}
