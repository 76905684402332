import gql from 'graphql-tag';

export const GET_INTERNAL_REAL_ESTATES = gql`
  query getInternalRealEstates(
    $bottomLeft: MapInput
    $upperRight: MapInput
    $realEstateIds: [String]
    $page: Int
    $limit: Int
    $sort: String
    $data: InternalRealEstateType
  ) @api(name: b2bRealEstate) {
    getInternalRealEstates(
      bottomLeft: $bottomLeft
      upperRight: $upperRight
      realEstateIds: $realEstateIds
      page: $page
      limit: $limit
      sort: $sort
      data: $data
    ) {
      total
      totalPages
      items {
        pricePerSqMeter
        isAbandoned
        id
        type
        typeOfDemand
        realEstateId
        contactPhones
        hasAlley
        alleyTurnCount
        alleyWidth
        constructionArea
        direction
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        map {
          type
          coordinates
        }
        typeOfDemand
        status
        contactName
        commission
        houseNumber
        width
        length
        rear
        area
        price
        tnccPrice
        priceUnit
        floorCount
        createdAt
        updatedAt
        approvedAt
        approveStatus {
          status
        }
        approvedBy {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        rejectedAt
        rejectedBy {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        rejectReason
        editor {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        creator {
          id
          fullName
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        mediaUrls
        isRead
        alleyWidth
        bedroomCount
        toiletCount
        isExpired12hours
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;

export const GET_LIST_REAL_ESTATE_PROJECTS = gql`
  query getListRealEstateProjects(
    $inputData: filterRealEstateProjectInput
    $searchString: String
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: b2bRealEstate) {
    getListRealEstateProjects(
      inputData: $inputData
      searchString: $searchString
      page: $page
      limit: $limit
      sort: $sort
    ) {
      total
      limit
      page
      hasNextPage
      hasPrevPage
      totalPages
      items {
        id
        projectType
        projectName
        investorName
        projectCode
        projectStatus
        province {
          name
        }
        district {
          name
        }
        ward {
          name
        }
        street {
          name
        }
        houseNumber
        createdAt
        creator {
          id
          fullName
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
          phones
        }
        priceRange {
          key
          value
          unit
          from
          to
        }
      }
    }
  }
`;

export const GET_RELATED_INTERNAL_REAL_ESTATES = gql`
  query getRelatedInternalRealEstates(
    $currentREId: String
    $page: Int
    $limit: Int
    $data: InternalRealEstateType
  ) @api(name: b2bRealEstate) {
    getRelatedInternalRealEstates(
      currentREId: $currentREId
      page: $page
      limit: $limit
      data: $data
    ) {
      items {
        id
        realEstateId
        type
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        fullAddress
        typeOfDemand
        status
        houseNumber
        hasAlley
        alleyTurnCount
        timeForRent
        timeForRentUnit
        alleyWidth
        isNewConstruction
        width
        length
        rear
        area
        constructionArea
        floorCount
        bedRoomArea
        villaView
        price
        tnccPrice
        priceUnit
        bedroomCount
        bathroomCount
        roomCount
        hasSeaView
        hotelStar
        block
        onFloor
        motelName
        shophouseField
        investor
        buildingType
        roomArea
        roomNumber
        toiletCount
        direction
        project
        privateNote
        legals
        contactName
        contactEmail
        mediaUrls
        tradingStatus
        creator {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        editor {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        map {
          type
          coordinates
        }
        fixedUtilities
        utilities
        localUtilities
        equipmentsAndServices
        warehouseType
        warehouseRoomCount
        hasMezzanine
        createdAt
        updatedAt
        deletedAt
        approveStatus {
          status
          reasonType
          reason
        }
        approvedBy {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        approvedAt
        rejectedAt
        rejectedBy {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        rejectReason
        commission
        isHidden
        contactPhones
        career
        isAbandoned
        isRead
        isExpired12hours
      }
      total
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;
export const GET_INTERNAL_REAL_ESTATES_DETAILS = gql`
  query getInternalRealEstateDetails($realEstateId: String!)
  @api(name: b2bRealEstate) {
    getInternalRealEstateDetails(realEstateId: $realEstateId) {
      id
      realEstateId
      type
      pricePerSqMeter
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      street {
        id
        name
      }
      fullAddress
      typeOfDemand
      status
      houseNumber
      hasAlley
      alleyTurnCount
      timeForRent
      timeForRentUnit
      alleyWidth
      isNewConstruction
      width
      length
      rear
      area
      constructionArea
      floorCount
      bedRoomArea
      villaView
      price
      tnccPrice
      priceUnit
      bedroomCount
      bathroomCount
      roomCount
      hasSeaView
      hotelStar
      block
      onFloor
      motelName
      shophouseField
      investor
      buildingType
      roomArea
      roomNumber
      toiletCount
      direction
      project
      privateNote
      legals
      clearanceArea
      contactName
      contactEmail
      mediaUrls
      tradingStatus
      creator {
        id
        fullName
        phones
        avatar {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
      }
      editor {
        id
        fullName
        phones
        avatar {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
      }
      map {
        type
        coordinates
      }
      fixedUtilities
      utilities
      localUtilities
      equipmentsAndServices
      warehouseType
      warehouseRoomCount
      hasMezzanine
      createdAt
      updatedAt
      deletedAt
      approveStatus {
        status
        reasonType
        reason
      }
      approvedBy {
        id
        fullName
        phones
        avatar {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
      }
      approvedAt
      rejectedAt
      rejectReason
      commission
      isHidden
      contactPhones
      career
      isAbandoned
      isRead
      isExpired12hours
      hasNewComment
      totalComments
      attachments
    }
  }
`;
export const GET_INTERNAL_REAL_ESTATES_ATTACHMENTS = gql`
  query getProductAttachments($realEstateId: String!)
  @api(name: b2bRealEstate) {
    getProductAttachments(realEstateId: $realEstateId) {
      id
      legalMediaUrls
      attachments
      count
      seen
    }
  }
`;
export const GET_INTERNAL_REAL_ESTATES_HISTORIES = gql`
  query getRealEstateHistories(
    $realEstateId: String
    $historyTypes: [HistoryTypeObjectInput]
    $interval: InternalFromToDateStringInputType
    $creator: String
    $page: Int
    $limit: Int
    $sort: String
    $hasVideo: Boolean
  ) @api(name: b2bRealEstate) {
    getRealEstateHistories(
      realEstateId: $realEstateId
      historyTypes: $historyTypes
      interval: $interval
      creator: $creator
      page: $page
      limit: $limit
      sort: $sort
      hasVideo: $hasVideo
    ) {
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
      items {
        id
        ops {
          op
          path
          value
          originalValue
        }
        ref
        realEstateId
        fullAddress
        creator {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
          employeeCode
        }
        historyType
        date
        content
        attachments
        mediaUrls
        legalMediaUrls
        totalAmount
        dueDate
        renewDate
        postType
        transactionId
        hasVideo
        mediaType
        userId {
          id
          fullName
          phones
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
          employeeCode
        }
      }
    }
  }
`;
export const GET_PRODUCT_ATTACHMENTS = gql`
  query getProductAttachments($realEstateId: String!)
  @api(name: b2bRealEstate) {
    getProductAttachments(realEstateId: $realEstateId) {
      id
      attachments
    }
  }
`;
export const ADD_MORE_ATTACHMENTS_FOR_PRODUCT = gql`
  mutation addMoreAttachmentsForProduct(
    $realEstateId: String!
    $attachments: [String]
    $legalMediaUrls: [String]
    $mediaType: String
  ) @api(name: b2bRealEstate) {
    addMoreAttachmentsForProduct(
      realEstateId: $realEstateId
      attachments: $attachments
      legalMediaUrls: $legalMediaUrls
      mediaType: $mediaType
    ) {
      success
      msg
    }
  }
`;
export const DELETE_ATTACHMENTS_OF_PRODUCT = gql`
  mutation deleteAttachmentsOfProduct(
    $realEstateId: String!
    $attachments: [String]
    $legalMediaUrls: [String]
  ) @api(name: b2bRealEstate) {
    deleteAttachmentsOfProduct(
      realEstateId: $realEstateId
      attachments: $attachments
      legalMediaUrls: $legalMediaUrls
    ) {
      success
      msg
    }
  }
`;
export const GET_CONTACT_PHONES = gql`
  query getContactPhoneRealEstate($realEstateId: String!)
  @api(name: b2bRealEstate) {
    getContactPhoneRealEstate(realEstateId: $realEstateId) {
      contactPhones
    }
  }
`;

export const GET_INTERNAL_REAL_ESTATE_COMMENTS = gql`
  query getInternalRealEstateComments(
    $realEstateId: String!
    $limit: Int
    $page: Int
  ) @api(name: b2bRealEstate) {
    getInternalRealEstateComments(
      realEstateId: $realEstateId
      limit: $limit
      page: $page
    ) {
      total
      page
      hasNextPage
      items {
        id
        content
        realEstateId {
          id
          realEstateId
          type
        }
        user {
          id
          fullName
          employeeCode
          avatar {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
        }
        lowerComment {
          id
          content
          realEstateId {
            id
            realEstateId
            type
          }
          user {
            id
            fullName
            employeeCode
            avatar {
              fileId
              originalUrl
              type
              urls {
                type
                url
              }
            }
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const REAL_ESTATE_PRODUCT_ON_MAP = gql`
  query realEstateProductOnMap(
    $data: InternalRealEstateType
    $bottomLeft: MapInput!
    $upperRight: MapInput!
    $polygonPoints: [MapInput]
    $circle: CircleFilterInputType
  ) @api(name: b2bRealEstate) {
    realEstateProductOnMap(
      data: $data
      bottomLeft: $bottomLeft
      upperRight: $upperRight
      polygonPoints: $polygonPoints
      circle: $circle
    ) {
      id
      bottomLeft {
        coordinates
      }
      count
      upperRight {
        coordinates
      }
      firstItem {
        id
        realEstateId
        type
        length
        width
        area
        rear
        bedRoomArea
        bedroomCount
        roomArea
        roomCount
        roomNumber
        mediaUrls
        price
        priceUnit
        privateNote
        fullAddress
        typeOfDemand
      }
      point {
        type
        coordinates
      }
    }
  }
`;
export const GET_REAL_ESTATE_PROJECT_DETAILS = gql`
  query getRealEstateProjectDetails(
    $id: ObjectID!
    $isBasic: Boolean
    $isDeleted: Boolean
  ) @api(name: b2bRealEstate) {
    getRealEstateProjectDetails(
      id: $id
      isBasic: $isBasic
      isDeleted: $isDeleted
    ) {
      id
      isAlley
      projectType
      isSharing
      investorName
      contactPhones
      contactEmail
      taxId
      projectStatus
      projectName
      projectCode
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      houseNumber
      fullAddress
      street {
        id
        name
      }
      map {
        coordinates
      }
      internalMap {
        coordinates
      }
      exclusiveSupplier
      exclusiveSupplierSub
      companyNote
      projectScale
      attachments
      fixedUtilities
      utilities
      localUtilities
      equipmentsAndServices
      legals
      legalMediaUrls
      mediaUrls
      mapPositionImages
      mapPositionDescription
      landImages
      landDescription
      projectArea {
        area
        unit
      }
      constructionProjectArea {
        area
        unit
      }
      projectSubType
      projectViews
      floorCountPerBlockRange {
        from
        to
        unit
      }
      standardStar
      townhouseCount
      apartmentCount
      independentVillaCount
      doubleVillaCount
      shopHouseCount
      penthouseCount
      condotelCount
      offictelCount
      commercialAreaCount
      typeAreas {
        area
        unit
      }
      totalLandArea {
        width
        length
        rear
        unit
      }
      totalZone
      totalBackground
      floorCanBuild
      directions
      priceRange {
        from
        to
        unit
      }
      totalWarehouse
      totalWorkshops
      floorRange {
        from
        to
        unit
      }
      totalAreaOfLand {
        area
        unit
      }
      totalAreaBuild {
        area
        unit
      }
      priceByGap {
        stageName
        price
        unitPrice
      }

      totalBlock

      totalFloorShopping
      totalAreaFloor {
        from
        to
        unit
      }
      totalBasements
      services {
        serviceName
        from
        to
      }
      apartment {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        toiletCount {
          from
          to
          unit
        }
        toiletArea {
          from
          to
          unit
        }
        views
      }
      townhouse {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        floorCount
        areaList {
          length
          width
          rear
          unit
        }
        views
      }
      independentVilla {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        floorCount {
          from
          to
          unit
        }
        areaList {
          length
          width
          rear
          unit
        }
        views
      }
      doubleVilla {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        floorCount {
          from
          to
          unit
        }
        areaList {
          length
          width
          rear
          unit
        }
        views
      }
      condotel {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        toiletCount {
          from
          to
          unit
        }
        toiletArea {
          from
          to
          unit
        }
        views
      }
      penthouse {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        toiletCount {
          from
          to
          unit
        }
        toiletArea {
          from
          to
          unit
        }
        views
      }
      shophouse {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        areaList {
          length
          width
          rear
          unit
        }
        toiletCount
        views
      }
      offictel {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        bedroomCount {
          from
          to
          unit
        }
        bedroomArea {
          from
          to
          unit
        }
        toiletCount {
          from
          to
          unit
        }
        toiletArea {
          from
          to
          unit
        }
        views
      }
      commercialArea {
        priceByStages {
          typeArea {
            area
            unit
          }
          name
          priceByStage {
            from
            to
            unit
          }
        }
        kioskCount
        commercialAreaCount
        views
      }
      totalCinema {
        from
        to
        unit
      }
      totalFoodTown {
        from
        to
        unit
      }
      creator {
        id
        fullName
        phones
        avatar {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
        employeeCode
      }
      editor {
        id
        fullName
        phones
        avatar {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
        employeeCode
      }
      tags
      hasVideo
      hasImage
      has360Image
      company
      createdAt
      updatedAt
      deletedAt
      saleRequestStatus {
        id
        status
        rejectReason
      }
      blocks {
        id
        name
        otherName
        projectId
        floors {
          id
          name
          products {
            id
            roomNumber
            productCode
            status
            formatName
            approveStatus {
              status
              reasonType
              reason
            }
          }
        }
        products {
          id
          roomNumber
          productCode
          status
          formatName
          approveStatus {
            status
            reasonType
            reason
          }
        }
      }
    }
  }
`;
