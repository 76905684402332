import React, { Suspense, useMemo } from 'react';
import ContentUserDetail from './contentUserDetail';
import HeadLeftUserDetail from './headLeftUserDetail';
import SwitchInner from '@components/switchInner';
import { useDeepCompareEffect } from 'react-use';
import UserPermission from './userPermission';

type Props = {
  data?: Object,
  currentTab?: string,
  setCurrentTab?: Function,
  contractId?: string,
  setContractId?: Function,
  tabConfigs?: Array,
};
export default function LeftUserDetail({
  data,
  currentTab,
  setCurrentTab,
  contractId,
  setContractId,
  tabConfigs,
}: Props) {
  const renderContent = useMemo(() => {
    switch (currentTab) {
      case 'ContentUserDetail':
        return <ContentUserDetail data={data} />;
      case 'UserPermission':
        return <UserPermission data={data} />;
      default:
    }
  }, [data, currentTab, setContractId, contractId]);
  useDeepCompareEffect(() => {
    setContractId('');
  }, [currentTab, setContractId]);
  return (
    <div
      style={{ boxShadow: '0px 2px 20px -13px rgba(0,0,0,0.85)' }}
      className="flex-1 bg-white rounded-lg"
    >
      <HeadLeftUserDetail data={data} />
      <SwitchInner
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        configs={tabConfigs}
      />
      <div className="flex flex-1 flex-col py-6 px-4 overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>{renderContent}</Suspense>
      </div>
    </div>
  );
}
