import React from 'react';

type ItemProps = {
  title?: string,
  value?: string,
  valueClassName?: string,
  hideField?: boolean,
};
export default function ItemRowContractDetail({
  title,
  value = '',
  valueClassName,
  hideField,
}: ItemProps) {
  if (hideField) {
    return null;
  }
  return (
    <div
      className="flex-1 flex w-full border-b space-x-10 mt-2.5 overflow-hidden"
      style={{ marginBottom: 1, borderColor: '#e9e9e9' }}
    >
      <span
        className="text-sm font-medium text-black-main w-100px lg:w-200px"
        style={{
          lineHeight: '32px',
        }}
      >
        {title}
      </span>
      <div
        style={{ color: value ? '#22313E' : '#80888F', lineHeight: '32px' }}
        dangerouslySetInnerHTML={{ __html: value ? value : 'Không có' }}
        className={`text-sm flex-1 ${valueClassName} ${
          !value ? 'italic' : undefined
        }`}
      />
    </div>
  );
}
