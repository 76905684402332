import React from 'react';
import { useNeedApprovalContext } from '../../../needApproval/context';

type Props = {
  postTitle?: string,
  fullAddress?: string,
  postStatus?: string,
  isDeleted?: boolean,
};

const getStatus = (tab, postStatus, isDeleted) => {
  let ico, label, color;
  if (isDeleted) {
    ico = '/svg/delete-icon.svg';
    label = 'Đã xóa';
    color = 'text-red-600';
  } else if (tab.includes('approved')) {
    if (postStatus === 'expired') {
      ico = '/svg/icon-expired.svg';
      label = 'Hết hạn';
      color = 'text-red-600';
    } else {
      ico = '/svg/active-icon.svg';
      label = 'Còn hạn';
      color = 'text-green-600';
    }
  } else if (tab.includes('denied')) {
    ico = '/svg/rejected-icon.svg';
    label = 'Bị từ chối';
    color = 'text-red-600';
  } else {
    return {};
  }
  return { ico, label, color };
};

export default function HeadLeftRealEstateDetail(props: Props) {
  const { postTitle, fullAddress, postStatus, isDeleted } = props;
  const { tab } = useNeedApprovalContext();
  const { ico, label, color } = getStatus(tab, postStatus, isDeleted);
  return (
    <div
      className="flex w-full items-start border-b border-gray-300 space-x-6"
      style={{
        padding: '25px 30px 10px',
      }}
    >
      <div
        className="text-14 text-gray-300 font-medium"
        dangerouslySetInnerHTML={{
          __html: `<span class="text-black-main">Tin rao:</span> <span class="text-black-main">${
            postTitle || ''
          }</span> | <span class="text-black-main">${fullAddress || ''}</span>`,
        }}
      />
      {!tab.includes('needApproval') && (
        <div
          style={{ minWidth: 300 }}
          className="flex flex-1 items-center justify-end space-x-2"
        >
          <div className="text-14 text-black-main">Trạng thái tin rao</div>
          <div className="flex flex-row items-center space-x-1">
            <div className="w-6 h-6 flex items-center justify-center">
              <img alt="ico" src={ico} className="w-4 h-4" />
            </div>
            <div
              className={`text-13 font-medium ${color}`}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
