import React from 'react';
import { View } from 'react-native-web';
import LeftRealEstateDetail from './components/leftRealEstateDetail';
import RightRealEstateDetail from './components/rightRealEstateDetail';
import { useNeedApprovalContext } from '../needApproval/context';
import { useGetRealEstateDetail } from '@utils/hooks/manageRealEstate/useGetRealEstateDetail';

type NeedApprovalDetailProps = {
  setShowModal?: Function,
  setStatus?: Function,
  demand?: string,
  isDeleted?: boolean,
};
export default function RealEstateDetail({
  setShowModal,
  setStatus,
  demand,
}: NeedApprovalDetailProps) {
  const { tab, listSelected } = useNeedApprovalContext();
  const { data } = useGetRealEstateDetail(listSelected?.[0], demand);
  const realEstateType = data?.realEstateType;
  return (
    <View
      style={{
        height: '100%',
        flexDirection: 'row',
        marginHorizontal: 16,
        paddingVertical: 12,
      }}
    >
      <LeftRealEstateDetail demand={demand} data={data} />
      {!tab.includes('denied') && (
        <RightRealEstateDetail
          realEstateType={realEstateType}
          isDeleted={data?.isDeleted}
          demand={demand}
          isTabApproved={tab.includes('approved')}
          setStatus={setStatus}
          setShowModal={setShowModal}
        />
      )}
    </View>
  );
}
