import MyButton from '@components/button';
import RadioButton from '@components/radio';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RenderTag } from '@components/tag';
import MyModalV2 from '@components/modalV2/myModalV2';
import ListDepartmentsModal from '../modal/ListDepartmentsModal';
import { useList } from 'react-use';
import { useGetListBasicDepartments } from '@utils/hooks/b2bUser/useGetListBasicDepartments';
import { omit } from 'lodash';

type RadioConfigProps = {
  config: Object,
  multiRadio?: boolean,
  isEdit?: boolean,
  setConfig?: Array,
  errors: Object,
  setErrors: Function,
};

export default function RadioSelectDepartmentConfig({
  config = {},
  multiRadio = false,
  isEdit = false,
  setConfig = [],
  errors = {},
  setErrors,
}: RadioConfigProps) {
  const { name, options, id, btnLabel, configKey } = config;
  const fieldKey = `${id}.${configKey}`;
  const { setValue, watch } = useFormContext();
  const [departmentIds, setDepartmentIds] = useList(() => {
    if (isEdit) {
      if (setConfig.includes('all')) {
        return ['all'];
      } else if (setConfig.includes('owner')) {
        return ['owner'];
      } else {
        return useGetListBasicDepartments(setConfig)?.data?.map(d => ({
          key: d.id,
          value: d.name,
        }));
      }
    } else {
      return ['owner'];
    }
  });
  const [showModal, setShowModal] = useState(false);
  const check = watch(fieldKey);
  const [isCheck, setCheck] = useState(check);
  useEffect(() => {
    if (departmentIds?.includes('all')) {
      setCheck('all');
      setValue(id, { departments: ['all'] });
      setErrors(omit(errors, id));
    } else if (departmentIds?.includes('owner')) {
      setCheck('owner');
      setValue(id, { departments: ['owner'] });
      setErrors(omit(errors, id));
    } else {
      setCheck('other');
      departmentIds?.length > 0
        ? setErrors(omit(errors, id))
        : setErrors({ ...errors, [id]: 'Vui lòng chọn phòng ban' });
      setValue(id, { departments: departmentIds?.map(d => d.key || d.id) });
    }
  }, [departmentIds]);

  const onRemoveItem = item => {
    const newValues = [...departmentIds];
    const remainValues = newValues?.filter(p => p.key !== item.key);
    setDepartmentIds.set(remainValues);
    setValue(fieldKey, remainValues);
  };
  const onSelectItem = item => {
    if (item === 'all') {
      setDepartmentIds.set(['all']);
    } else if (item === 'other') {
      setDepartmentIds.set([]);
    } else if (item === 'owner') {
      setDepartmentIds.set(['owner']);
    } else {
      setDepartmentIds.set(item);
    }
    setCheck(item);
  };
  return (
    <div className="w-full flex flex-col space-y-4 rounded-lg shadow-card p-4">
      <span
        className={
          multiRadio
            ? `font-medium text-black-main text-14`
            : `font-medium text-blue-main text-16`
        }
      >
        {name}
      </span>
      <div className="space-y-4">
        {options?.map(item => (
          <div key={Math.random()}>
            <RadioButton
              titleClass={isCheck === item.key ? 'font-medium' : 'none'}
              isChecked={isCheck === item.key}
              title={item?.value}
              wrapperClass="ml-4"
              onPress={() => {
                onSelectItem(item.key);
              }}
            />
            {isCheck === 'other' && item?.hasSelection && (
              <div className="mt-2">
                <MyButton
                  onPress={() => setShowModal(true)}
                  type="gray"
                  label={btnLabel}
                  leftComponent={() => (
                    <img src="/svg/plus.svg" alt="Thêm icon" />
                  )}
                />
                <div className="w-full flex flex-wrap space-x-2">
                  {departmentIds?.map(item => (
                    <div key={Math.random()} className="w-fit mt-3">
                      <RenderTag
                        value={item?.name || item?.value}
                        removeValue={() => onRemoveItem(item)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
        {!!errors[id]?.length && (
          <div className="text-sm text-red-400">{errors[id] ?? ''}</div>
        )}
      </div>
      <MyModalV2 open={showModal} onClose={() => setShowModal(false)}>
        <ListDepartmentsModal
          id={id}
          setItems={setDepartmentIds}
          selectedItems={departmentIds}
          onClose={() => setShowModal(false)}
        />
      </MyModalV2>
    </div>
  );
}
