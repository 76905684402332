import configsRemote from '@utils/configs/remoteConfigs/realEstateCommonConfigs.json';
import configs from '@utils/configs/remoteConfigs/realEstateFilterConfigs.json';
import { useSameProductContext } from '../product/context';
import styles from '@components/table/table.module.scss';
import React from 'react';
import { demandConfigs } from '@utils/configs/configsLocal';
import needApprovedStyle from '../../manageRealEstate/needApproval/components/needApproval.module.scss';
import moment from 'moment';

type RenderTagProps = {
  value?: any,
  removeValue?: Function,
};

const getValueSlider = ({
  keyValue,
  from,
  to,
  min,
  max,
  listSelections,
  showPlus,
  renderToValue: toValue,
  exceptValue,
  unit,
}) => {
  let finalValue = '';
  const tempUnit = listSelections?.find(e => e.key === keyValue)?.unit || unit;
  const renderUnit =
    to > max && showPlus ? '+ ' + ' ' + tempUnit : ' ' + tempUnit;
  const renderToValue = to > toValue ? toValue : to;
  const renderValue = keyValue
    ? listSelections?.find(e => e.key === keyValue)?.value
    : from + ' - ' + renderToValue + renderUnit;
  if ((!from && !to) || (from === min && to >= max)) {
    finalValue = 'Tất cả';
  } else {
    finalValue = exceptValue?.length > 0 ? exceptValue : renderValue;
  }
  return finalValue;
};

export function RenderTag({ value, removeValue }: RenderTagProps) {
  if (!value || value.length === 0) {
    return <></>;
  }
  return (
    <div className={needApprovedStyle.tagFilterResult}>
      <div className="text-13 truncate text-black-main">
        {Array.isArray(value) ? value.join(', ') : value}
      </div>
      {removeValue && (
        <img
          onClick={() => {
            removeValue();
          }}
          alt="ico"
          src="/svg/xIcon.svg"
          className="w-2.5 h-2.5 cursor-pointer"
        />
      )}
    </div>
  );
}

type Props = {
  productSelections?: Array,
};

export default function RenderResultFilterHeader({ productSelections }: Props) {
  const { forSellRealEstateTypes, forRentRealEstateTypes } = configsRemote;
  const { townhouse } = configs;
  const { width, length, area, priceToBuy, priceToRent, floorCount } =
    townhouse || {};
  const convertWidth =
    width?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertLength =
    length?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertArea =
    area?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 20001 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToBuy =
    priceToBuy?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertPriceToRent =
    priceToRent?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 200.5 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const convertFloorCount =
    floorCount?.map(e => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  const { stateFilter, setStateFilterAt } = useSameProductContext();
  const {
    realEstateId,
    isForSell,
    realEstateType,
    provinceName,
    districtName,
    wardName,
    streetName,
    houseNumber,
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    priceFrom,
    priceTo,
    priceKey,
    contactName,
    floorCountFrom,
    floorCountTo,
    floorCountKey,
    widthFrom,
    widthTo,
    widthKey,
    lengthFrom,
    lengthTo,
    lengthKey,
    rearFrom,
    rearTo,
    rearKey,
    areaFrom,
    areaTo,
    areaKey,
    approvedBy,
    approvedAtFrom,
    approvedAtTo,
    creator,
    postStatus,
  } = stateFilter;
  const configStatus = postStatus?.map(e => {
    return productSelections?.find(item => item.key === e)?.value;
  });

  const realEstateTypeConfigs =
    isForSell === 'ForSale' ? forSellRealEstateTypes : forRentRealEstateTypes;
  const listPrice =
    isForSell === 'ForRent' || isForSell === 'ToRent'
      ? convertPriceToRent
      : convertPriceToBuy || [];
  return (
    <div className="w-full overflow-auto border-t border-b border-gray-300">
      <div className="flex h-40px flex-row items-center ">
        <div
          className={`${styles.headContainer} w-60px items-center justify-center`}
        />
        <div className={`${styles.headContainer} w-210px items-center`}>
          <RenderTag
            value={realEstateId}
            removeValue={() => setStateFilterAt('realEstateId')('')}
          />
        </div>

        <div className={`${styles.headContainer} w-180px items-center`}>
          <RenderTag
            value={configStatus}
            removeValue={() => setStateFilterAt('postStatus')([])}
          />
        </div>

        <div className={`${styles.headContainer} w-140px items-center`}>
          <RenderTag
            value={demandConfigs?.find(e => e.key === isForSell)?.value}
            removeValue={() => {
              setStateFilterAt('isForSell')('ForSale');
              setStateFilterAt('realEstateType')(['townhouse']);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-145px items-center`}>
          <RenderTag
            value={
              realEstateType?.length === 1
                ? realEstateTypeConfigs.find(e => e.key === realEstateType?.[0])
                    ?.value
                : realEstateType?.length
                ? `${realEstateType?.length} loại`
                : ''
            }
            removeValue={() => setStateFilterAt('realEstateType')([])}
          />
        </div>

        <div className={`${styles.headContainer} w-140px items-center`}>
          <RenderTag
            value={houseNumber}
            removeValue={() => setStateFilterAt('houseNumber')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-170px items-center`}>
          <RenderTag
            value={streetName}
            removeValue={() => {
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-140px items-center`}>
          <RenderTag
            value={wardName}
            removeValue={() => {
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-145px items-center`}>
          <RenderTag
            value={districtName}
            removeValue={() => {
              setStateFilterAt('district')('');
              setStateFilterAt('districtName')('');
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={provinceName}
            removeValue={() => {
              setStateFilterAt('province')('');
              setStateFilterAt('provinceName')('');
              setStateFilterAt('district')('');
              setStateFilterAt('districtName')('');
              setStateFilterAt('ward')('');
              setStateFilterAt('wardName')('');
              setStateFilterAt('street')('');
              setStateFilterAt('streetName')('');
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={contactName}
            removeValue={() => setStateFilterAt('contactName')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-154px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: priceKey,
              from: priceFrom,
              to: priceTo,
              min: 0,
              max: isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 50,
              renderToValue:
                isForSell === 'ForRent' || isForSell === 'ToRent' ? 200 : 100,
              exceptValue:
                (priceFrom === 0 || !priceFrom) &&
                priceTo === 1 &&
                (isForSell === 'ForRent' || isForSell === 'ToRent')
                  ? '<= 1 triệu'
                  : priceFrom === 200 &&
                    priceTo === 200.5 &&
                    (isForSell === 'ForRent' || isForSell === 'ToRent')
                  ? '>= 200 triệu'
                  : priceFrom === 100 && priceTo === 101
                  ? '> 100 tỷ'
                  : '',
              listSelections: listPrice,
              unit:
                isForSell === 'ForRent' || isForSell === 'ToRent'
                  ? 'triệu'
                  : 'tỷ',
            })}
            removeValue={() => {
              setStateFilterAt('priceKey')('');
              setStateFilterAt('priceFrom')(0);
              setStateFilterAt('priceTo')(
                isForSell === 'ForRent' || isForSell === 'ToRent' ? 200.5 : 101
              );
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-124px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: widthKey,
              from: widthFrom,
              to: widthTo,
              min: 0,
              max: 100,
              renderToValue: 100,
              exceptValue:
                (widthFrom === 0 || !widthFrom) && widthTo === 3
                  ? '< 3 m'
                  : widthFrom === 100 && widthTo === 101
                  ? '> 100 m'
                  : '',
              listSelections: convertWidth,
              unit: 'm',
            })}
            removeValue={() => {
              setStateFilterAt('widthKey')('');
              setStateFilterAt('widthFrom')(0);
              setStateFilterAt('widthTo')(101);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-124px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: lengthKey,
              from: lengthFrom,
              to: lengthTo,
              min: 0,
              max: 500,
              renderToValue: 500,
              exceptValue:
                (lengthFrom === 0 || !lengthFrom) && lengthTo === 10
                  ? '< 10 m'
                  : lengthFrom === 500 && lengthTo === 501
                  ? '> 500 m'
                  : '',
              listSelections: convertLength,
              unit: 'm',
            })}
            removeValue={() => {
              setStateFilterAt('lengthKey')('');
              setStateFilterAt('lengthFrom')(0);
              setStateFilterAt('lengthTo')(501);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-124px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: rearKey,
              from: rearFrom,
              to: rearTo,
              min: 0,
              max: 100,
              renderToValue: 100,
              exceptValue:
                (rearFrom === 0 || !rearFrom) && rearTo === 3
                  ? '< 3 m'
                  : rearFrom === 100 && rearTo === 101
                  ? '> 100 m'
                  : '',
              listSelections: convertWidth,
              unit: 'm',
            })}
            removeValue={() => {
              setStateFilterAt('rearKey')('');
              setStateFilterAt('rearFrom')(0);
              setStateFilterAt('rearTo')(101);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: areaKey,
              from: areaFrom,
              to: areaTo,
              min: 0,
              max: 5000,
              renderToValue: 20000,
              exceptValue:
                (areaFrom === 0 || !areaFrom) && areaTo === 10 ? '< 10 m²' : '',
              listSelections: convertArea,
              unit: 'm²',
            })}
            removeValue={() => {
              setStateFilterAt('areaKey')('');
              setStateFilterAt('areaFrom')(0);
              setStateFilterAt('areaTo')(20001);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-133px items-center`}>
          <RenderTag
            value={getValueSlider({
              keyValue: floorCountKey,
              from: floorCountFrom,
              to: floorCountTo,
              min: 0,
              max: 168,
              renderToValue: 168,
              exceptValue:
                floorCountFrom === 0 && floorCountTo === 3 ? '<= 3 tầng' : '',
              listSelections: convertFloorCount,
              unit: 'tầng',
            })}
            removeValue={() => {
              setStateFilterAt('floorCountKey')('');
              setStateFilterAt('floorCountFrom')(0);
              setStateFilterAt('floorCountTo')(169);
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-218px items-center`}>
          <RenderTag
            value={creator}
            removeValue={() => setStateFilterAt('creator')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-185px items-center`}>
          <RenderTag
            value={
              createdAtFrom && createdAtTo
                ? `${moment.unix(createdAtFrom).format('DD/MM/YYYY')} - ${moment
                    .unix(createdAtTo)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('createdAtFrom')('');
              setStateFilterAt('createdAtTo')('');
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-185px items-center`}>
          <RenderTag
            value={
              updatedAtFrom && updatedAtTo
                ? `${moment.unix(updatedAtFrom).format('DD/MM/YYYY')} - ${moment
                    .unix(updatedAtTo)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('updatedAtFrom')('');
              setStateFilterAt('updatedAtTo')('');
            }}
          />
        </div>

        <div className={`${styles.headContainer} w-218px items-center`}>
          <RenderTag
            value={approvedBy}
            removeValue={() => setStateFilterAt('approvedBy')('')}
          />
        </div>
        <div className={`${styles.headContainer} w-175px items-center`}>
          <RenderTag
            value={
              approvedAtFrom && approvedAtTo
                ? `${moment
                    .unix(approvedAtFrom)
                    .format('DD/MM/YYYY')} - ${moment
                    .unix(approvedAtTo)
                    .format('DD/MM/YYYY')}`
                : 'Tất cả'
            }
            removeValue={() => {
              setStateFilterAt('approvedAtFrom')('');
              setStateFilterAt('approvedAtTo')('');
            }}
          />
        </div>
      </div>
    </div>
  );
}
