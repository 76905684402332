import React, { useEffect, useRef, useState } from 'react';
import {
  angleFromCoordinate,
  getDistanceFromLatLonInKm,
  useSyncLocation,
} from './utils';
import { defaultLat, defaultLong } from '@utils/configs/map/mapConfigs';
import PathWithDistance from './pathWithDistance';
import SelectedLocationMarker from './selectedLocationMarker';
import { ZoomControl } from 'react-leaflet';
let ReactLeaflet, TileLayer, Map, CustomMarker, Tooltip;

if (process.browser) {
  ReactLeaflet = require('react-leaflet');
  TileLayer = ReactLeaflet.TileLayer;
  Map = ReactLeaflet.Map;
  CustomMarker = require('./customMarker').CustomMarker;
  Tooltip = ReactLeaflet.Tooltip;
}

const MAX_ZOOM = 22,
  MIN_ZOOM = 13;

type MapProps = {
  lat?: number,
  long?: number,
};
export default function MapComponent({
  lat = defaultLat,
  long = defaultLong,
}: MapProps) {
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(10);
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const finalLat = lat || currentLat,
    finalLong = long || currentLng;
  const { longitude, latitude } = useSyncLocation({
      maximumAge: 60000,
      timeout: 5000,
      enableHighAccuracy: true,
    }),
    animatedToCurrentLocation = useRef(false);
  useEffect(() => {
    if (!currentLat && !currentLng) {
      setCurrentLng(longitude);
      setCurrentLat(latitude);
    }
  }, [currentLat, currentLng, longitude, latitude]);
  const duplicateLocation = finalLat === currentLat && finalLong === currentLng;
  useEffect(() => {
    if (!animatedToCurrentLocation.current) {
      const flyToBoundsPoints = [];
      if (lat && long) {
        flyToBoundsPoints.push([lat, long]);
      }
      flyToBoundsPoints.push([
        currentLat || defaultLat,
        currentLng || defaultLong,
      ]);
      if (flyToBoundsPoints.length && mapRef.current) {
        if (flyToBoundsPoints.length === 1) {
          mapRef.current.leafletElement?.flyTo(flyToBoundsPoints[0], 16);
        } else {
          mapRef.current.leafletElement?.flyToBounds(flyToBoundsPoints, {
            padding: [50, 50],
          });
        }
        animatedToCurrentLocation.current = true;
      }
    }
  }, [lat, long, currentLat, currentLng]);

  useEffect(() => {
    if (mapRef.current && lat && long) {
      mapRef.current.leafletElement?.flyTo([lat, long], 19);
    }
  }, [lat, long]);
  return (
    <Map
      ref={mapRef}
      onViewportChanged={({ zoom: nextZoom }) => {
        setZoom(nextZoom);
      }}
      scrollWhellZoom={true}
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      style={{
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}
      center={[10.7711545, 106.6689676]}
      zoom={zoom}
      zoomControl={false}
    >
      <ZoomControl position="bottomright" />
      <TileLayer
        maxZoom={22}
        // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
      />
      {!duplicateLocation && lat && long && currentLat && currentLng && (
        <PathWithDistance
          color="#000"
          lat={(lat + currentLat) / 2}
          lng={(long + currentLng) / 2}
          distance={getDistanceFromLatLonInKm(
            currentLat,
            currentLng,
            lat,
            long
          )}
          corner={angleFromCoordinate(lat, long, currentLat, currentLng)}
          shouldRotate={currentLng < long}
          points={[
            { lat, lng: long },
            { lat: currentLat, lng: currentLng },
          ]}
        />
      )}
      <SelectedLocationMarker
        lat={finalLat}
        lng={finalLong}
        duplicateLocation={duplicateLocation}
        radius={500}
      />
      {currentLat && currentLng && (
        <CustomMarker position={[currentLat, currentLng]}>
          <div className="rounded-full transform -translate-x-1/2 -translate-y-2/4">
            <img alt="ico" src="/svg/myLocation-icon.svg" />
          </div>
          <Tooltip>
            <div className="bg-white py-1 px-2 flex flex-col justify-center">
              <p className="text-sm text-black-main">Vị trí hiện tại của bạn</p>
            </div>
          </Tooltip>
        </CustomMarker>
      )}
    </Map>
  );
}
