import { productPosted } from '@components/leftMenu/configs';
import qs from 'query-string';
import type { RouterLocation } from '../../typeDefinition';
import { useLocation } from 'react-router-dom';
import { useGetLocation } from 'scenes/home/manageProduct/configs';

export const useFieldConfig = () => {
  const { search }: RouterLocation = useLocation();
  const { tab = productPosted } = qs.parse(search);
  const { isTabRejectProduct } = useGetLocation();
  return [
    {
      key: 'realEstateId',
      value: 'Mã sản phẩm',
      isVisible: true,
    },
    {
      key: 'isForSell',
      value: 'Nhu cầu',
      isVisible: true,
    },
    {
      key: 'status',
      value: 'Trạng thái sản phẩm',
      isVisible: true,
      hide: tab?.includes('product-premium'),
    },
    {
      key: 'type',
      value: 'Loại BĐS',
      isVisible: true,
    },
    {
      key: 'hasAlley',
      value: 'Mặt tiền hay hẻm',
      isVisible: true,
    },
    {
      key: 'alleyWidth',
      value: 'Độ rộng hẻm trước nhà',
      isVisible: true,
    },
    {
      key: 'streetName',
      value: 'Đường',
      isVisible: true,
    },
    {
      key: 'wardName',
      value: 'Phường/ Xã',
      isVisible: true,
    },
    {
      key: 'districtName',
      value: 'Quận/ Huyện',
      isVisible: true,
    },
    {
      key: 'provinceName',
      value: 'Tỉnh/ Thành phố',
      isVisible: true,
    },
    {
      key: 'country',
      value: 'Quốc gia',
      isVisible: true,
    },
    {
      key: 'houseNumber',
      value: 'Số nhà',
      isVisible: true,
    },
    {
      key: 'createdAt',
      value: 'Ngày tạo',
      isVisible: true,
    },
    {
      key: 'updatedAt',
      value: 'Ngày chỉnh sửa',
      isVisible: true,
    },
    {
      key: 'price',
      value: 'Giá (VNĐ)',
      isVisible: true,
    },
    {
      key: 'pricePerSquare',
      value: 'Giá (VNĐ/m2)',
      isVisible: true,
    },
    {
      key: 'contactName',
      value: 'Tên chủ nhà',
      isVisible: true,
    },
    {
      key: 'contactPhones',
      value: 'Số điện thoại chủ nhà',
      isVisible: true,
    },
    {
      key: 'commission',
      value: 'Phí hoa hồng',
      isVisible: true,
    },
    {
      key: 'floorCount',
      value: 'Số tầng',
      isVisible: true,
    },
    // {
    //   key: 'toiletCount',
    //   value: 'Số nhà vệ sinh',
    //   isVisible: true,
    // },
    // {
    //   key: 'bedroomCount',
    //   value: 'Số phòng ngủ',
    //   isVisible: true,
    // },
    {
      key: 'toiletCount',
      value: 'Số nhà vệ sinh',
      isVisible: true,
    },
    {
      key: 'bedroomCount',
      value: 'Số phòng ngủ',
      isVisible: true,
    },
    {
      key: 'direction',
      value: 'Hướng',
      isVisible: true,
    },
    {
      key: 'map',
      value: 'Tọa độ',
      isVisible: true,
    },
    {
      key: 'width',
      value: 'Ngang (m)',
      isVisible: true,
    },
    {
      key: 'length',
      value: 'Dài (m)',
      isVisible: true,
    },
    {
      key: 'rear',
      value: 'Mặt hậu (m)',
      isVisible: true,
    },
    {
      key: 'area',
      value: 'Tổng diện tích',
      isVisible: true,
    },
    // {
    //   key: 'constructionArea',
    //   value: 'Diện tích sàn',
    //   isVisible: true,
    // },
    {
      key: 'constructionArea',
      value: 'Diện tích sàn (m2)',
      isVisible: true,
    },
    {
      key: 'approvedAt',
      value: isTabRejectProduct ? 'Ngày từ chối' : 'Ngày duyệt',
      isVisible: true,
    },
    {
      key: 'approvedBy',
      value: isTabRejectProduct ? 'Người từ chối' : 'Người duyệt',
      isVisible: true,
    },
    {
      key: 'creator',
      value: 'Người tạo',
      isVisible: true,
    },
    {
      key: 'editor',
      value: 'Người chỉnh sửa',
      isVisible: true,
    },
  ];
};
