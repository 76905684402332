import gql from 'graphql-tag';

export const LOCK_UNLOCK_B2B_USER = gql`
  mutation boLockUnlockB2BUserAccount($userId: ObjectID!) @api(name: users) {
    boLockUnlockB2BUserAccount(userId: $userId) {
      msg
      success
    }
  }
`;
export const UPDATE_CONTRACT_NOTE_AND_ATTACHMENTS = gql`
  mutation updateContractNoteAndAttachments(
    $contractId: String!
    $attachments: [String]
    $note: String
  ) @api(name: users) {
    updateContractNoteAndAttachments(
      contractId: $contractId
      attachments: $attachments
      note: $note
    ) {
      success
      msg
      payload
    }
  }
`;
