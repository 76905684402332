import gql from 'graphql-tag';

export const GET_ME = gql`
  query b2bGetMe @api(name: users) {
    b2bGetMe {
      payload {
        id
        fullName
        company
        avatar {
          __typename
          ... on FileWithFullUrls {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              title
              description
              thumbnailUrl
              videoId
            }
          }
        }
        productPermissions {
          id
          configs
        }
        projectPermissions {
          id
          configs
        }
        permissions {
          id
          configs
        }
        modules
      }
    }
  }
`;

export const GET_USER_BASIC_INFO = gql`
  query b2bGetUserBasicInfo($userId: String!) @api(name: users) {
    getUserBasicInfo(userId: $userId) {
      ... on B2CUser {
        id
        fullName
        phone
        secondPhone
        email
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
      }
      ... on B2BUserType {
        id
        fullName
        phone
        secondPhone
        email
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
      }
    }
  }
`;

export const GET_USER_B2B = gql`
  query boGetListB2BAdmin(
    $employeeCode: String
    $fullName: String
    $phone: String
    $province: String
    $district: String
    $ward: String
    $street: String
    $houseNumber: String
    $email: String
    $createdAt: InternalFromToDateStringInputType
    $page: Int
    $limit: Int
    $isPersonal: Boolean
  ) @api(name: users) {
    boGetListB2BAdmin(
      employeeCode: $employeeCode
      fullName: $fullName
      phone: $phone
      province: $province
      district: $district
      ward: $ward
      street: $street
      houseNumber: $houseNumber
      registerDate: $createdAt
      email: $email
      page: $page
      limit: $limit
      isPersonal: $isPersonal
    ) {
      total
      page
      hasNextPage
      items {
        id
        employeeCode
        fullName
        phone
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        houseNumber
        createdAt
        email
        lockByRtAt
        isPersonal
      }
    }
  }
`;
export const B2B_GET_USER_INFO = gql`
  query b2bGetUserDetailsForBO($id: String!) @api(name: users) {
    b2bGetUserDetailsForBO(userId: $id) {
      id
      username
      isPersonal
      fullName
      employeeCode
      phone
      secondPhone
      idImages {
        ... on FileWithFullUrls {
          fileId
          originalUrl
        }
      }
      email
      gender
      avatar {
        ... on FileWithFullUrls {
          fileId
          originalUrl
        }
      }
      idImages {
        ... on FileWithFullUrls {
          fileId
          type
          urls {
            type
            url
          }
          originalUrl
        }
      }
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      street {
        id
        name
      }
      houseNumber
      company
      creator {
        fullName
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
      }
      createdAt
      dateOfBirth
      lockByRtAt
      attachments {
        ... on FileWithFullUrls {
          fileId
          originalUrl
          creator {
            id
            fullName
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
          }
          createdAt
        }
      }
      idNumber
      isPersonal
      productPermissions {
        id
        configs
      }
      projectPermissions {
        id
        configs
      }
    }
  }
`;
export const GET_COMPANY_INFO = gql`
  query companyInfo($id: String!) @api(name: users) {
    companyInfo(id: $id) {
      id
      name
      companyCode
      logo {
        ... on FileWithFullUrls {
          fileId
          originalUrl
        }
      }
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      street {
        id
        name
      }
      houseNumber
      taxId
      isPersonal
    }
  }
`;
export const GET_USER_HISTORIES = gql`
  query getUserHistories($userId: String, $limit: Int, $page: Int)
  @api(name: users) {
    getUserHistories(userId: $userId, limit: $limit, page: $page) {
      total
      limit
      hasNextPage
      page
      items {
        id
        ops {
          op
          path
          changes
        }
        ref
        employeeCode
        fullName
        creatorHistory {
          id
          userType
          fullName
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
        date
        attachments
      }
    }
  }
`;

export const GET_LIST_OF_USERS_BASIC_INFO = gql`
  query b2bGetListOfUsersBasicInfo(
    $userIds: [String]!
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: users) {
    b2bGetListOfUsersBasicInfo(
      userIds: $userIds
      page: $page
      limit: $limit
      sort: $sort
    ) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        userType
        fullName
      }
    }
  }
`;

export const LIST_EMPLOYEES = gql`
  query b2bGetEmployees(
    $data: EmployeeInputType
    $searchString: String
    $excludedIds: [String]
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: users) {
    b2bGetEmployees(
      data: $data
      searchString: $searchString
      excludedIds: $excludedIds
      page: $page
      limit: $limit
      sort: $sort
    ) {
      payload {
        total
        limit
        totalPages
        page
        pagingCounter
        hasPrevPage
        hasNextPage
        items {
          id
          fullName
          employeeCode
          username
          createdAt
          province {
            id
            name
          }
          district {
            id
            name
          }
          departments {
            positionType
            department {
              name
            }
          }
          email
          phone
          isLocked
          avatar {
            ... on FileWithFullUrls {
              originalUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_DETAILS = gql`
  query b2bGetUserInfo($id: String!, $isDeleted: Boolean) @api(name: users) {
    b2bGetUserInfo(id: $id, isDeleted: $isDeleted) {
      id
      fullName
      employeeCode
      approveStatus {
        reason
        reasonType
        status
      }
      attachments {
        ... on FileWithFullUrls {
          originalUrl
          fileId
          type
          urls {
            type
            url
          }
          creator {
            id
            fullName
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
          }
        }
      }
      idImages {
        ... on FileWithFullUrls {
          fileId
          originalUrl
          type
        }
      }
      company
      isLocked
      creator {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
          }
        }
      }
      dateOfBirth
      gender
      username
      createdAt
      updatedAt
      province {
        id
        name
      }
      district {
        id
        name
      }
      departments {
        department {
          name
          id
        }
        positionType
        positionName
      }
      email
      phone
      secondPhone
      idNumber
      # permissionRoles
      productPermissions {
        id
        name
        configs
      }
      projectPermissions {
        id
        name
        configs
      }
      avatar {
        ... on FileWithFullUrls {
          originalUrl
          fileId
        }
      }
    }
  }
`;

export const GET_LIST_DEPARTMENTS = gql`
  query b2bGetListDepartments(
    $searchString: String
    $departmentIds: [String]
    $excludedDepartmentIds: [String]
  ) @api(name: users) {
    b2bGetListDepartments(
      searchString: $searchString
      departmentIds: $departmentIds
      excludedDepartmentIds: $excludedDepartmentIds
    ) {
      id
      name
    }
  }
`;

export const GET_LIST_BASIC_DEPARTMENTS = gql`
  query b2bGetListBasicDepartments(
    $includedIds: [String]
    $excludedIds: [String]
  ) @api(name: users) {
    b2bGetListBasicDepartments(
      includedIds: $includedIds
      excludedIds: $excludedIds
    ) {
      id
      name
    }
  }
`;
