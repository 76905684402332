import { HoChiMinhId } from '../../../constant';

export const blankState = {
  isForSell: 'forSale',
  typeRealEstate: 'townhouse',
  province: HoChiMinhId,
  district: '',
  ward: '',
  street: '',
  houseNumber: '',
  lat: '',
  long: '',
  postTitle: '',
  postContent: '',
  privateNote: '',
  price: '',
  contactName: '',
  contactPhones: [{ phone: '' }],
  contactEmail: '',
  postType: '3',
  numberOfPostingDay: '',
  ownerName: '',
  ownerEmail: '',
  ownerPhone: [{ phone: '' }],
  career: '',
  priceOfOwner: '',
  fixedUtilities: '',
  utilities: '',
  localUtilities: '',
  equipmentsAndServices: '',
  attachments: [],
  legal: [],
  mediaUrls: [],
  isNewConstruction: false,
  hasAlley: false,
  width: '',
  length: '',
  rear: '',
  floorCount: 0,
  bedroomCount: 0,
  toiletCount: 0,
  direction: '',
  project: '',
  status: '',
  alleyTurnCount: '',
  alleyWidth: '',
  isCompleted: false,
  commission: '',
  villaView: [],
  buildingType: '',
  roomCount: '',
  hotelStar: 0,
  hasSeaView: false,
  warehouseType: '',
  warehouseRoomCount: 0,
  roomNumber: '',
  onFloor: 0,
  bedRoomArea: '',
  block: '',
  shophouseField: '',
  hasMezzanine: false,
  timeForRent: '',
  timeForRentUnit: '',
  constructionArea: '',
};

export const hasAlleyConfigs = [
  {
    key: false,
    value: 'BĐS mặt tiền',
  },
  {
    key: true,
    value: 'BĐS trong hẻm',
  },
];

export const isNewConstructionConfigs = [
  {
    key: true,
    value: 'Xây mới',
  },
  {
    key: false,
    value: 'Xây cũ',
  },
];

export const hasSeaViewConfigs = [
  {
    key: true,
    value: 'Có',
  },
  {
    key: false,
    value: 'Không',
  },
];

export const stepConfigs = [
  {
    label: 'Kiểm tra',
    path: '/createB2BRealEstate/check-product',
  },
  {
    label: 'Nhập thông tin',
    path: '/createB2BRealEstate/add-product-information',
  },
];
