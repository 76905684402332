import RenderMedia from './renderMedia';
import RenderNote from './renderNote';
import RenderUserInfo from './renderUserInfo';

type Props = {
  data?: Object,
};
export default function RenderLeft({ data }: Props) {
  return (
    <div className="w-323px h-full space-y-5">
      <RenderMedia mediaUrlIds={data?.mediaUrls} />
      <RenderNote data={data} />
      <RenderUserInfo data={data} />
    </div>
  );
}
