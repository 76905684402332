import gql from 'graphql-tag';

export const APPROVE_FEEDBACK = gql`
  mutation approveFeedback($feedbackId: ObjectID!) @api(name: map) {
    approveFeedback(feedbackId: $feedbackId) {
      id
    }
  }
`;
export const DELETE_FEEDBACK = gql`
  mutation deleteFeedbackStreetValuation($feedbackId: ObjectID!)
  @api(name: map) {
    deleteFeedbackStreetValuation(feedbackId: $feedbackId) {
      id
    }
  }
`;
