import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MyToast from '@components/MyToast';
import { pickBy } from 'lodash';
import { UPDATE_EMPLOYEE_LOGIN_INFO } from '@utils/graphql/mutations/employee';
export const useUpdateEmployeeLoginInfo = onClose => {
  const [updateEmployee, { loading: onLoading, error, client }] = useMutation(
    UPDATE_EMPLOYEE_LOGIN_INFO
  );
  let loading = onLoading;
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async state => {
      try {
        await updateEmployee({
          variables: pickBy(state, e => e !== ''),
        });
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              wrapperStyle="w-394px shadow-md"
              type="success"
              message="Cập nhật thông tin đăng nhập nhân viên thành công!"
              closeButtonPos="center"
            />
          ),
        });
        await resetQueries(['b2bGetEmployees', 'b2bGetUserInfo'], client.cache);
        onClose();
      } catch (e) {
        await toast({
          position: 'bottom-right',
          duration: 5000,
          render: props => (
            <MyToast
              id={props.id}
              onClose={props.onClose}
              type="error"
              message={e.message}
              closeButtonPos="center"
            />
          ),
        });
        loading = false;
      }
    },
    [updateEmployee, client, navigate]
  );
  return { onSubmit, loading, error };
};
