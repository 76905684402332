import styles from '../realEstate.module.scss';
import { getMedias, getTagRealEstate } from '@utils/helper';
import React, { useState } from 'react';
import { getImage } from '@components/itemRealEstate/utils';
import { useDeepCompareEffect } from 'react-use';

type Props = {
  item?: Object,
};

type MediaCountItemProps = {
  mediaCount?: string,
  icon?: any,
};
function MediaCountItem({ mediaCount, icon }: MediaCountItemProps) {
  if (mediaCount === 0) {
    return <></>;
  }
  return (
    <div className={`${styles.countMediaFileForMap}`}>
      <div className={`${styles.textCountMediaForMap} mr-1 truncate`}>
        {mediaCount}
      </div>
      <img src={icon} alt="icon" className={styles.iconCountMediaFileForMap} />
    </div>
  );
}

export default function TopRealEstateForMap({ item }: Props) {
  const { mediaUrls, realEstateId } = item;
  const [listUrls, setListUrls] = useState(mediaUrls);
  useDeepCompareEffect(() => {
    (async function () {
      try {
        const data = await getMedias(mediaUrls);
        setListUrls(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [listUrls]);
  const video = listUrls?.filter(e => ['video', 'youtube'].includes(e?.type)),
    image360 = listUrls?.filter(e => e?.type === '360image'),
    image = listUrls?.filter(e => e?.type === 'image');
  return (
    <div className={styles.imageRealEstateForMap}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url('${getImage(listUrls)}')`,
        }}
      />
      <img
        src={getTagRealEstate({ ...item })}
        alt="tag"
        className={styles.tagRealEstateForMap}
      />
      <div className={styles.mediaCountContainerForMap}>
        <MediaCountItem icon="/svg/ico-360.svg" mediaCount={image360?.length} />
        <MediaCountItem icon="/svg/ico-camera.svg" mediaCount={video?.length} />
        <MediaCountItem
          icon="/svg/ico-picture.svg"
          mediaCount={image?.length}
        />
      </div>
      <div className={styles.realEstateIdForMap}>
        <img src="/svg/code.svg" alt="icon" className={styles.codeIconForMap} />
        <div className={styles.txtRealEstateIdForMap}>{realEstateId}</div>
      </div>
    </div>
  );
}
