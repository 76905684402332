import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { View } from 'react-native-web';
import { unionBy } from 'lodash';
import { useFieldConfigs } from '../../../modals/visibleFieldOnTableModal/config';
import { useDeepCompareEffect, useList } from 'react-use';
import {
  blankStateFilter,
  useStateFilterNeedApproval,
} from '@utils/hooks/manageRealEstate/useStateFilterNeedApproval';
import { NeedApprovalContext } from './needApproval/context';
import {
  ApprovedLocalStorageKey,
  DeniedLocalStorageKey,
  NeedApprovalLocalStorageKey,
} from '../../../constant';
import { managePost } from '@components/leftMenu/configs';
import Switch from '@components/switch';

export default function ManageRealEstate() {
  const location = useLocation();
  const [tab, setTab] = useState(location.pathname);
  const fields = useFieldConfigs(tab);
  const listFieldId = fields?.filter(e => !e?.hide)?.map(e => e.key);
  const localStorageKey = tab.includes('needApproval')
    ? NeedApprovalLocalStorageKey
    : tab.includes('approved')
    ? ApprovedLocalStorageKey
    : DeniedLocalStorageKey;
  const listFieldLocalStorage = JSON.parse(
    localStorage.getItem(localStorageKey)
  );
  const [listField, setListField] = useList(
    listFieldLocalStorage || listFieldId || []
  );
  const [listSelected, setListSelected] = useList([]);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('approved');
  const [demand, setDemand] = useState('sell');
  const {
    state: stateFilter,
    setStateAt: setStateFilterAt,
    setState,
  } = useStateFilterNeedApproval();
  const navigate = useNavigate();
  useDeepCompareEffect(() => {
    setListField.set(listFieldLocalStorage || listFieldId);
  }, [listFieldId]);
  useDeepCompareEffect(() => {
    setTab(location.pathname);
  }, [location.pathname]);
  return (
    <NeedApprovalContext.Provider
      value={React.useMemo(
        () => ({
          listField,
          setListField,
          listSelected: unionBy(listSelected, e => e),
          setListSelected,
          stateFilter,
          setStateFilterAt,
          demand,
          setDemand,
          showModal,
          setShowModal,
          status,
          setStatus,
          tab,
          setTab,
          setState,
        }),
        [
          listField,
          listSelected,
          setListField,
          setListSelected,
          stateFilter,
          setStateFilterAt,
          demand,
          setDemand,
          showModal,
          setShowModal,
          status,
          setStatus,
          tab,
          setTab,
          setState,
        ]
      )}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            marginTop: 64,
            backgroundColor: 'white',
            paddingHorizontal: 16,
            paddingTop: 20,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <Switch
            value={tab}
            setValue={value => {
              setTab(value);
              navigate(value);
              setListSelected.clear();
              setDemand('sell');
              setStateFilterAt('isForSell')('ForSale');
              setState(blankStateFilter);
            }}
            configs={managePost.items[0].items}
          />
        </View>
        <Outlet />
      </View>
    </NeedApprovalContext.Provider>
  );
}
