import { getAuth, setAuth } from '@utils/helper';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function Authentication() {
  const navigate = useNavigate();
  const token = getAuth();

  useEffect(() => {
    if (token?.length) {
      setAuth(token);
      navigate('/manageRealEstate/needApproval');
    } else {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`;
    }
  }, [token]);
  return (
    <div className="flex-1 flex flex-row h-screen">
      <Outlet />
      <div className="flex-1">
        <img
          style={{ backgroundSize: 'cover' }}
          alt="auth-banner"
          src="/svg/authBanner.svg"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
}
