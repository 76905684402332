import RenderHandleMutationButton from './renderHandleMutationButton';
import MyDropdown from '@components/myDropdown';
import moment from 'moment/moment';
import CalendarFromToDropdown from '@components/calendarFromTo';
import React, { useState } from 'react';
import MyButton from '@components/button';
import MyModal from '@components/modal';
import VisibleFieldOnTableModal from '../../../../../modals/visibleFieldOnTableModal';
import { useNeedApprovalContext } from '../context';
import RadioButton from '@components/radio';
import { blankStateFilter } from '@utils/hooks/manageRealEstate/useStateFilterNeedApproval';
type Props = {
  title?: string,
  currentTotal?: number,
  total?: number,
  onShowModalApproval?: Function,
  onShowModalReject?: Function,
  isVisibleActionAllButton?: boolean,
  demand?: string,
  setDemand?: Function,
};

export default function RenderTopTable({
  title,
  currentTotal = 0,
  total = 0,
  onShowModalApproval,
  onShowModalReject,
  isVisibleActionAllButton,
}: Props) {
  const {
    stateFilter,
    setStateFilterAt,
    demand,
    setDemand,
    setListSelected,
    setState,
  } = useNeedApprovalContext();
  const [showModal, setShowModal] = useState(false);
  const [disableDropdown, setDisableDropdown] = useState(false);
  return (
    <div className="w-full flex flex-row py-2.5 overflow-auto space-x-2 items-end">
      <div style={{ minWidth: 300 }} className="flex flex-col space-y-6">
        <div className="w-full flex flex-row items-center space-x-1">
          <div className="text-18 font-medium text-black-main">{title}</div>
          <div className="text-14 text-black-main">
            ({currentTotal}/{total} tin rao)
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <RadioButton
            onPress={() => {
              setDemand('sell');
              setStateFilterAt('isForSell')('ForSale');
              setListSelected.clear();
              setState(blankStateFilter);
            }}
            isChecked={demand === 'sell'}
            title="Tin bán/ cho thuê"
          />
          <RadioButton
            onPress={() => {
              setDemand('buy');
              setStateFilterAt('isForSell')('ToBuy');
              setListSelected.clear();
              setState(blankStateFilter);
            }}
            isChecked={demand === 'buy'}
            title="Tin cần mua/ cần thuê"
          />
        </div>
      </div>
      <div className="flex flex-1 justify-end flex-row items-end space-x-4">
        {isVisibleActionAllButton && (
          <RenderHandleMutationButton
            onShowModalApproval={onShowModalApproval}
            onShowModalReject={onShowModalReject}
          />
        )}
        <div className="flex flex-col space-y-1">
          <div className="text-sm font-medium text-black-main">
            Thời gian đăng
          </div>
          <MyDropdown
            key={Math.random()}
            disabled={disableDropdown}
            DropdownComponent={CalendarFromToDropdown}
            buttonDropdownClassName="px-2.5 border border-blue-main rounded-lg h-40px"
            dropdownContext={{
              startDay: stateFilter?.createdAtFrom,
              endDay: stateFilter?.createdAtTo,
              setValue: nextValue => {
                setStateFilterAt('createdAtFrom')(nextValue.startDay);
                setStateFilterAt('createdAtTo')(nextValue.endDay);
                setListSelected.clear();
              },
              maxDate: moment(),
              disabledAddTo: true,
            }}
          >
            <div className="w-214px flex flex-row items-center justify-between space-x-2">
              <div className="w-full flex flex-row items-center justify-between space-x-1">
                <div className="text-sm text-blue-main text-left ">
                  {stateFilter?.createdAtFrom && stateFilter?.createdAtTo
                    ? `${moment
                        .unix(stateFilter?.createdAtFrom)
                        .format('DD/MM/YYYY')} - ${moment
                        .unix(stateFilter?.createdAtTo)
                        .format('DD/MM/YYYY')}`
                    : 'Chọn giá trị'}
                </div>
                {stateFilter?.createdAtFrom && stateFilter?.createdAtTo && (
                  <img
                    onClick={() => {
                      setDisableDropdown(true);
                      setStateFilterAt('createdAtFrom')('');
                      setStateFilterAt('createdAtTo')('');
                      setTimeout(() => {
                        setDisableDropdown(false);
                      }, 1000);
                    }}
                    alt="ico"
                    src="/svg/xIcon.svg"
                    className="w-3 h-3 cursor-pointer"
                  />
                )}
              </div>
              <img alt="ico" src="/svg/calendar-icon-dropdown.svg" />
            </div>
          </MyDropdown>
        </div>
        <MyButton
          onPress={() => setShowModal(true)}
          wrapperClass="text-14 text-sm font-medium"
          style={{ border: '1px solid #2459ad', maxWidth: 180 }}
          type="white"
          label="Chỉnh sửa hiển thị"
          rightComponent={() => <img alt="ico" src="/svg/filter-icon.svg" />}
        />
      </div>
      <MyModal
        maxWidth={830}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <VisibleFieldOnTableModal onClose={() => setShowModal(false)} />
      </MyModal>
    </div>
  );
}
