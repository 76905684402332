/* eslint-disable prettier/prettier */
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import Layout from 'layout';
import { includes, isEqual } from 'lodash';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Page403 from 'scenes/403/403';
import Page404 from 'scenes/404/404';
import ManageFeedback from 'scenes/home/manageFeedback/feedback';
import './global.scss';
import Authentication from './scenes/authentication';
import LoginForm from './scenes/authentication/login';
import Dashboard from './scenes/dashboard/dashboard';
import Home from './scenes/home';
import CreateB2BRealEstate from './scenes/home/createB2BRealEstate';
import AddProductInformation from './scenes/home/createB2BRealEstate/addProductInformation';
import CheckProduct from './scenes/home/createB2BRealEstate/checkProduct';
import CreateB2BUserAccount from './scenes/home/createB2BUserAccount';
import ManageEmployee from './scenes/home/manageEmployee';
import CreateEmployeeAccount from './scenes/home/manageEmployee/addEmployee';
import ManageCompanyEmployee from './scenes/home/manageEmployee/listEmployee';
import ManageOrganization from './scenes/home/manageOrganization';
import CreatePermissionGroup from './scenes/home/manageOrganization/createPermissionGroup';
import PermissionGroups from './scenes/home/manageOrganization/permissionGroups';
import ManageProduct from './scenes/home/manageProduct';
import Product from './scenes/home/manageProduct/product';
import ProductDetail from './scenes/home/manageProduct/productDetail';
import ProductPosted from './scenes/home/manageProduct/productPosted';
import ManageProject from './scenes/home/manageProject';
import Project from './scenes/home/manageProject/listProject';
import ManageRealEstate from './scenes/home/manageRealEstate';
import NeedApproval from './scenes/home/manageRealEstate/needApproval';
import ManageUser from './scenes/home/manageUser';
import ManageUserB2B from './scenes/home/manageUser/b2b';
import PublishProduct from './scenes/home/publishProduct';

const AccessDeniedPage = () => {
  const [open, setOpen] = useState(false);
  const [openSpotlight, setOpenSpotlight] = useState(false);
  return (
    <Page403
      open={open}
      setOpen={setOpen}
      openSpotlight={openSpotlight}
      setOpenSpotlight={setOpenSpotlight}
    />
  );
};

const App = () => {
  const { permissions, modules } = useGetMe();

  const routes = [
    {
      element: <Layout />,
      children: [
        {
          path: '/auth',
          element: <Authentication />,
          children: [
            {
              path: '/auth/login',
              element: <LoginForm />,
            },
          ],
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/',
          element: <Home />,
          children: [
            {
              path: '/manageOrganization',
              element: <ManageOrganization />,
              children: [
                {
                  path: '/manageOrganization/organization-chart',
                  element: <></>,
                },
                {
                  path: '/manageOrganization/permission-groups',
                  element: <PermissionGroups />,
                },
                {
                  path: '/manageOrganization/permission-groups/create',
                  element: <CreatePermissionGroup />,
                },
                {
                  path: '/manageOrganization/permission-groups/edit',
                  element: <CreatePermissionGroup />,
                },
              ],
            },
            {
              path: '/manageRealEstate',
              element: <ManageRealEstate />,
              isShow: includes(modules, 'SALE_MANAGEMENT'),
              children: [
                {
                  path: '/manageRealEstate/needApproval',
                  element: <NeedApproval />,
                },
                {
                  path: '/manageRealEstate/approved',
                  element: <NeedApproval />,
                },
                {
                  path: '/manageRealEstate/denied',
                  element: <NeedApproval />,
                },
                {
                  path: '/manageRealEstate/feedbackValuation',
                  element: <ManageFeedback />,
                },
              ],
            },
            {
              path: '/manageUser',
              element: <ManageUser />,
              isShow: includes(modules, 'BUSINESS_ACCOUNT'),
              children: [
                {
                  path: '/manageUser/b2b',
                  element: <ManageUserB2B />,
                },
                {
                  path: '/manageUser/b2c',
                  element: <ManageUserB2B />,
                },
              ],
            },
            {
              path: '/createB2BUserAccount',
              element: <CreateB2BUserAccount />,
            },
            {
              path: '/editB2BUserAccount/:id',
              element: <CreateB2BUserAccount />,
            },
            {
              path: '/createB2BRealEstate',
              element: <CreateB2BRealEstate />,
              children: [
                {
                  path: '/createB2BRealEstate/check-product',
                  element: <CheckProduct />,
                },
                {
                  path: '/createB2BRealEstate/add-product-information',
                  element: <AddProductInformation />,
                },
              ],
            },
            {
              path: '/manageProduct',
              element: <ManageProduct />,
              isShow:
                includes(modules, 'REALESTATE') &&
                permissions?.some(p =>
                  [
                    PermissionIds.ManageProducts,
                    PermissionIds.ViewProductList,
                    PermissionIds.ViewProductGroup,
                  ].includes(p.id)
                ),
              children: [
                {
                  path: 'product',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/my-product',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/waiting-for-approval',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/hidden',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/deleted',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/product/rejected',
                  element: <Product />,
                },
                {
                  path: '/manageProduct/details/:id',
                  element: <ProductDetail />,
                },
                {
                  path: '/manageProduct/product-posted',
                  element: <ProductPosted />,
                },
                {
                  path: '/manageProduct/product/premium',
                  element: <ProductPosted />,
                },
                {
                  path: '/manageProduct/product/authorize',
                  element: <ProductPosted />,
                },
              ],
            },
            {
              path: '/publish',
              element: <PublishProduct />,
              children: [
                {
                  path: '/publish/product',
                  element: <PublishProduct />,
                },
                {
                  path: '/publish/authorized',
                  element: <PublishProduct />,
                },
                {
                  path: '/publish/premium',
                  element: <PublishProduct />,
                },
              ],
            },
            {
              path: '/employee',
              element: <ManageEmployee />,
              children: [
                {
                  path: '/employee/company',
                  element: <ManageCompanyEmployee />,
                },
                {
                  path: '/employee/create',
                  element: <CreateEmployeeAccount />,
                },
                {
                  path: '/employee/edit',
                  element: <CreateEmployeeAccount />,
                },
              ],
            },
            {
              path: '/project',
              element: <ManageProject />,
              isShow: includes(modules, 'PROJECT'),
              children: [
                {
                  path: '/project/company',
                  element: <Project />,
                },
              ],
            },
            {
              path: '404',
              element: <Page404 />,
            },
          ],
        },
      ],
    },
  ];

  const renderRoutes = routeConfigs => {
    return routeConfigs.map(route => {
      return (
        <Route
          key={`route-${route.path}`}
          path={route.path}
          element={
            isEqual(route.isShow, false) ? <AccessDeniedPage /> : route.element
          }
          exact
        >
          {route.children && renderRoutes(route.children)}
        </Route>
      );
    });
  };
  return <Routes>{renderRoutes(routes)}</Routes>;
};

export default App;
