import React, { useEffect } from 'react';
import Content from './components/content';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../context';
import { useGetMe } from '@utils/hooks/common/useGetMe';
import { PermissionIds } from '@gugotech/tncc-users-permissions/lib';

export default function LoginForm() {
  const navigate = useNavigate();
  const { isLogin } = useGlobalContext();
  const { permissions } = useGetMe();
  useEffect(() => {
    if (isLogin) {
      if (permissions?.some(p => p.id === PermissionIds.ViewEmployeeList)) {
        navigate('/employee/company');
      } else if (
        permissions?.some(p => p.id === PermissionIds.ViewProductList)
      ) {
        navigate('/manageProduct/product');
      } else {
        navigate('/');
      }
    }
  }, [isLogin, permissions]);
  const form = useForm({
    defaultValues: {
      username: '',
      password: '',
      isSavedToken: false,
    },
    mode: 'onChange',
  });
  return (
    <FormProvider {...form}>
      <div className="flex w-495px h-full relative">
        <div
          style={{ marginTop: 80 }}
          className="w-full flex justify-center absolute"
        >
          <img alt="logo" src="/svg/logo.svg" />
        </div>
        <Content />
        <div className="w-full flex justify-center absolute bottom-0">
          <img alt="vector" src="/svg/vector.svg" />
        </div>
      </div>
    </FormProvider>
  );
}
